import axios from 'axios'

export class TwilioService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/twilio/${botName}`
  }

  async getKeys () {
    const res = await axios.get(`${this.url}/keys`)
    return res.data
  }

  async updateKeys (keys: any) {
    return axios.put(`${this.url}/keys`, keys)
  }

  async generateWebhook (type: string) {
    const res = await axios.post(`${this.url}/${type}/webhook`)
    return res.data
  }

  async deleteWebhook (type: string) {
    return axios.delete(`${this.url}/${type}/webhook`)
  }
}
