import { IBlock } from '../../../../../../../../../common/blocks/block.interface'
import DialogBlockComponent from '../DialogBlockComponent'
import flowComponentService from '../../../flow-component.service'

export default class AskQuestionBlockComponent<T extends IBlock> extends DialogBlockComponent<T> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions () {
    this.updateSuggestionFieldCapabilities('field-question')
    flowComponentService.updateRestrictions(this.id, this.customData)
  }
}
