<template>
  <div class="form-group form-group--options">
    <div>
      <div class="label">Answer contains</div>
      <input
        v-model="currentBlock.customData.contains"
        type="text"
        :placeholder="'Word that should be contained'"
        class="form-control"
        @input="updateLabel"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IContainsBlock } from '../../../../../../../../../common/blocks/connector/contains-block.interface'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component
export default class Contains extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<IContainsBlock>;

  @Emit()
  updateLabel () {}

  mounted () {
    this.updateLabel()
  }
}
</script>
