import { Component, Vue } from 'vue-property-decorator'
import popupService from '@/services/popup.service'
import { NuanceService } from '@/services/bots/settings/configuration/nuance.service'

@Component
export default class NuanceManagementMixin extends Vue {
  nuanceService: NuanceService;
  keys: any;

  initNuance (botName: string) {
    this.nuanceService = new NuanceService(botName)
    this.getNuanceKeys()
  }

  async getNuanceKeys () {
    this.keys = await this.nuanceService.getKeys()
  }

  async updateNuanceKeys () {
    try {
      await this.nuanceService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }
}
