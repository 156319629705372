<template>
  <Modal title="Amazon Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <div v-if="!keys.skillId">
        <form @submit.prevent="$emit('create-skill')">
          <h4>Amazon Alexa Settings</h4>
          <label for="skillName">Skill Name</label>
          <input id="skillName" placeholder="Enter your skill name" v-model="keys.skillName" />
          <div class="section--footer static">
            <button id="LoginWithAmazon" class="btn btn--purple gradient" type="submit">
              Create Skill  <i class="icon icon-add"></i>
            </button>
          </div>
        </form>
      </div>
      <div v-else>
        <form>
          <h1>Amazon Alexa configuration</h1>

          <div class="form-section">
            <h4>Skill Id</h4>
            <p>{{ keys.skillId }}</p>
          </div>

          <div class="form-section">
            <h4>Dialog settings</h4>

            <FormGroup
              label="Options message"
              tooltip="Message when displaying options from an options block"
            >
              <Autocomplete v-model="keys.optionsMessage" :items="optionsMessageItems"/>
            </FormGroup>
          </div>

          <button class="btn btn--white red" type="button" @click="$emit('delete-skill')">
            Delete skill
          </button>
          <button class="btn btn--purple gradient" type="button" @click="$emit('update-keys')">
            Save settings <i class="icon icon-save"></i>
          </button>
        </form>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'
import Modal from '../../../../../helpers/Modal.vue'

@Component({
  components: {
    FormGroup,
    Autocomplete,
    Modal
  }
})
export default class Alexa extends Vue {
  readonly optionsMessageItems = ['option'];

  @Prop() keys: any;
}
</script>
