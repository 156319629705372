export const functions = {
  defaultFunction: {
    key: 'defaultFunction',
    functionName: 'Select a function',
    variables: [],
    category: 'Function',
    hide: true
  },
  defaultSub: {
    key: 'defaultSub',
    functionName: 'Select a sub',
    variables: [],
    category: 'Subs',
    hide: true
  }
}
