import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    currentLanguage: 'English',
    supportedLanguages: ['English']
  },
  mutations: {
    setCurrentLanguage (state, language) {
      state.currentLanguage = language
    },
    setSupportedLanguages (state, languages) {
      state.supportedLanguages = languages
    }
  }
})
