import FlowComponent from './FlowComponent'
import { possibleFunctions } from './block.config'
import { IBlock } from '../../../../../../common/blocks/block.interface'
import flowComponentService from '../modules/flow-component.service'
import { append, create } from 'tiny-svg'
import store from '@/store'

export default class BlockStorage {
  private components: { [id: string]: FlowComponent<IBlock> } = {}

  private customFunctions: any[] = []

  constructor(public bpmn: any = null, functions: any[]) {
    this.buildCustomFunctions(functions)
  }

  private makeFields(inputs: any[]) {
    return inputs.map((e) => ({
      name: typeof e === 'object' ? e.name : e,
      type: 'text',
      id: typeof e === 'object' ? e.name : e
    }))
  }

  buildCustomFunctions(functions: any[]) {
    const categoryMap = {
      flow: 'Subs',
      rest: 'Function',
      sql: 'Function',
      code: 'Function',
      adaptiveCard: 'Function',
      gpt3: 'Function'
    }

    this.customFunctions = functions
      // .filter((item) => item.type !== 'llm')
      .map((f) => ({
        key: f.name,
        functionName: f.name,
        fields: this.makeFields(f.inputs),
        variables: [f.type],
        category: categoryMap[f.type] || f.category || f.type,
        outputs: f.outputs
      }))

    // console.log('this.customFunctions', this.customFunctions)
  }

  drawSubBlockPlus(g: SVGGElement, element: any) {
    const text = create('text', {
      x: 50,
      y: 69,
      'dominant-baseline': 'middle',
      'text-anchor': 'middle',
      'font-size': 26,
      cursor: 'pointer',
      'function-id': '+'
    }) as SVGElement
    text.textContent = '+'
    text.onclick = () => this.bpmn.get('eventBus').fire('open.sub', element)
    append(g, text)

    // const width = 100;
    // const height = 80;
    // const rectWidth = 20;
    // const rectHeight = 20;
    // const rect = create('rect', {
    //   x: width / 2 - rectWidth / 2,
    //   y: height - rectHeight - 1,
    //   width: rectWidth,
    //   height: rectHeight,
    //   fill: 'white',
    //   cursor: 'pointer'
    // });
    // rect.textContent = '+';
    // rect.onclick = () => this._eventBus.fire('open.sub', element);
    // append(g, rect);
  }

  /** Add a component for tracking - it can be either a block or connection (sequence flow) */
  addComponent(element: any, gfx: SVGGElement) {
    if (element.type === 'label') return

    if (element.businessObject.$type === 'bpmn:SequenceFlow') {
      element.businessObject.customData = element.businessObject.$attrs['qa:customData']
    }
    if (element.oldBusinessObject) {
      element.businessObject.customData = element.oldBusinessObject.customData
    }

    if (element.businessObject.customData) this.addExistingComponent(element, gfx)
    else this.initializeNewComponent(element, gfx)

    const block = this.findById(element.id)

    this.addAttributes(block)

    if (block.category == 'Subs') {
      this.drawSubBlockPlus(gfx, element)
    }
  }

  removeComponent(element: any) {
    const object = element.businessObject.customData ? JSON.parse(element.businessObject.customData) : { fc: '' }
    flowComponentService.deleteRestrictions(element.businessObject.id, object.fc)
    delete this.components[element.businessObject.id]
  }

  initializeNewComponent(element: any, gfx: SVGGElement) {
    const category = element.businessObject.category || 'Dialog'
    const functionData = Object.values(possibleFunctions)
      .concat(this.customFunctions)
      .find((f) => f.category == category)
    const customData = JSON.stringify(functionData ? { fc: functionData.key, variables: [] } : { fc: 'default', variables: [] })
    this.components[element.id] = flowComponentService.getBlockInstance(element, gfx, functionData, customData)
  }

  public updateBlock(block: FlowComponent<IBlock>): FlowComponent<IBlock> {
    if (block.functionSettings) {
      const currentFunction = this.currentFunction(block.customData.fc)
      block.functionSettings.outputs = currentFunction ? currentFunction.outputs : block.functionSettings.outputs
    }

    this.addAttributes(block)

    this.components[block.element.id] = flowComponentService.getBlockInstance(block.element, block.gfx, block.functionSettings, block._customData)

    return this.components[block.element.id]
  }

  currentFunction(fc: string) {
    return this.customFunctions.find((f) => f.key == fc)
  }

  public loadAllRestrictions() {
    for (const key in this.components) {
      this.components[key].updateRestrictions()
    }
  }

  public loadAllFunctionsRestrictions(functions: any) {
    flowComponentService.resetAllRestrictions()
    for (const key in this.components) {
      const existingFunction = functions.find((f) => f.name == this.components[key].customData.fc)
      if (existingFunction) {
        flowComponentService.updateFunctionsRestrictions({ type: existingFunction.type, data: this.components[key] })
      }
    }
  }

  serializeElements() {
    Object.values(this.components).forEach((c) => c.serializeData())
  }

  addExistingComponent(el: any, gfx: SVGGElement) {
    if (el.businessObject.customData) {
      let customData = JSON.parse(el.businessObject.customData)

      if (Object.keys(customData).filter((k) => store.state.supportedLanguages.includes(k)).length == 0) {
        const keys = Object.keys(customData)
        if (keys.some((k) => store.state.supportedLanguages.includes(k))) {
          const newCustomData = {}
          store.state.supportedLanguages.forEach((l) => {
            newCustomData[l] = { ...(customData[l] || customData[Object.keys(customData)[0]]) }
          })
          customData = newCustomData
        } else if (Object.keys(customData).filter((k) => !store.state.supportedLanguages.includes(k)).length > 0) {
          const oldCustomData = { ...customData }
          const newCustomData = {}
          store.state.supportedLanguages.forEach((l) => {
            newCustomData[l] = { ...oldCustomData }
          })
          customData = newCustomData
        }
        el.businessObject.customData = JSON.stringify(customData)
      }
      const functionData =
        possibleFunctions[customData[store.state.currentLanguage].fc as keyof typeof possibleFunctions] ||
        this.customFunctions.find((f) => f.key === customData[store.state.currentLanguage].fc)
      this.components[el.id] = flowComponentService.getBlockInstance(el, gfx, functionData, customData)
    }
  }

  allBlocks() {
    return this.bpmn.get('elementRegistry')
  }

  allComponents() {
    return Object.values(this.components)
  }

  findById(id: string) {
    const component = this.components[id]
    if (!component) throw new Error(`Could not find a component with id ${id} in the tracked flow components`)
    return component
  }

  /**
   * Does a depth-first-search backwards to find all elements that can reach the current through connectors
   */
  getPrecedingElements(currentId: string) {
    return this._getPrecedingElements(currentId, {})
  }

  /**
   * Does a depth-first-search forward to find all elements that can be reach from the current through connectors
   */
  getElementsAfter(currentId: string) {
    return this._getElementsAfter(currentId, {})
  }

  static isConditionalConnector(element: any) {
    return element.type === 'bpmn:SequenceFlow' && element.source.type === 'bpmn:ExclusiveGateway'
  }

  static isLabelOfConditionalConnector(element: any) {
    return element.type === 'label' && this.isConditionalConnector(element.labelTarget)
  }

  private _getPrecedingElements(currentId: string, visited: { [id: string]: FlowComponent<IBlock> }) {
    visited[currentId] = this.findById(currentId)

    // All edges/arrows that point to the given element
    const edges = this.bpmn.get('elementRegistry').filter((e: any) => e.businessObject.targetRef && e.businessObject.targetRef.id === currentId)

    for (const e of edges) {
      if (!visited[e.businessObject.sourceRef.id] && e.businessObject.sourceRef.$type !== 'bpmn:StartEvent') {
        this._getPrecedingElements(e.businessObject.sourceRef.id, visited)
      }
    }

    return Object.values(visited)
  }

  private _getElementsAfter(currentId: string, visited: { [id: string]: FlowComponent<IBlock> }) {
    visited[currentId] = this.findById(currentId)

    // All edges/arrows that point to the given element
    const edges = this.bpmn.get('elementRegistry').filter((e: any) => e.businessObject.sourceRef && e.businessObject.sourceRef.id === currentId)

    for (const e of edges) {
      if (!visited[e.businessObject.targetRef.id] && e.businessObject.targetRef.$type !== 'bpmn:StartEvent') {
        this._getElementsAfter(e.businessObject.targetRef.id, visited)
      }
    }

    return Object.values(visited)
  }

  private static readonly FUNCTION_ID_ATTR_NAME = 'function-id'
  private addAttributes(block: any) {
    if (block.type === 'bpmn:SequenceFlow') block.gfx.setAttribute(BlockStorage.FUNCTION_ID_ATTR_NAME, 'connector')
    else if (block.type === 'bpmn:EndEvent') block.gfx.setAttribute(BlockStorage.FUNCTION_ID_ATTR_NAME, 'end')
    else if (block.type === 'bpmn:StartEvent') block.gfx.setAttribute(BlockStorage.FUNCTION_ID_ATTR_NAME, 'start')
    else if (block.type === 'bpmn:ExclusiveGateway') block.gfx.setAttribute(BlockStorage.FUNCTION_ID_ATTR_NAME, 'condition')
    else block.gfx.setAttribute(BlockStorage.FUNCTION_ID_ATTR_NAME, block.customData.fc)
  }
}
