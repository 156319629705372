import { SttMap } from "../types/stt-map.type"
import { ExternalProvider } from "../enums/external-provider.enum"

export const sttNames: SttMap<string> = {
  [ExternalProvider.Microsoft]: "Microsoft Speech-to-Text",
  [ExternalProvider.Google]: "Google Speech-to-Text",
  [ExternalProvider.OpenAI]: "Open AI Speech-to-Text",
  /**
   * Note: if we ever include Amazon, make sure it differs from ttsNames[ExternalProvider.Amazon] which is "Amazon Polly"
   * Otherwise Configuration.vue can't tell if we're configuring TTS or STT.
   * This would work:
   * [ExternalProvider.Amazon]: "Amazon Polly Speech-to-Text",
    */
}
