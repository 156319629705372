<template>
  <div class="auto-validation">
    <div class="show-examples__example">
      Auto-validation
      <label
        aria-haspopup="true"
        class="show-examples--toggle"
        :class="{ 'toggle-active': autoValidation }"
      >
        <input
          id="statisticsMenuLink4"
          type="checkbox"
          :disabled="isAutoValidationDisabled"
          @change="$emit('update:autoValidation', !autoValidation)"
        />
        <i></i>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AutoValidaiton extends Vue {
  @Prop() autoValidation: boolean;
  @Prop() isAutoValidationDisabled: boolean;
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables";

.auto-validation {
  position: absolute;
  bottom: 55px;
  left: 260px;
  overflow: hidden;

  .show-examples__example {
    color: $dark-grey;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 12.5px;

    &:last-child {
      margin-right: 0;
    }

    .refresh-statistics-label {
      text-decoration: underline;
    }
  }

  .show-examples--toggle {
    position: relative;
    top: 5px;
    margin: 0 0 0 10px;
    width: 51px;
    height: 26px;
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &.toggle-active {
      i {
        background-color: $main;
        border-color: $main;
        &:after {
          transform: translate3d(27px, 2px, 0);
          border-color: $main;
        }
      }
    }

    input {
      display: none;
    }
    i {
      position: relative;
      display: inline-block;
      width: 51px;
      height: 26px;
      background-color: $default;
      border: 1px solid $border;
      border-radius: 13px;
      vertical-align: text-bottom;
      transition: all 0.3s linear;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background: linear-gradient(to bottom, white 3%, #f5f5f5 100%);
        border: 1px solid $border;
        border-radius: 50px;
        transform: translate3d(3px, 2px, 0);
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
</style>
