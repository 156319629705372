export enum FunctionType {
  SQL = "sql",
  REST = "rest",
  AdaptiveCard = "adaptiveCard",
  Flow = "flow",
  Code = "code",
  CaptureStorage = "captureStorage",
  SendEvent = "sendEvent",
  "GPT-3" = "gpt3",
  LLM = "llm",
}
