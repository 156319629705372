<template>
  <Modal title="IBM Watson Assistant Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form v-if="keys.weHostWatson" @submit.prevent="$emit('delete-watson')">
        <h3>Currently, we host your Watson Assistant resource and workspaces.</h3>

        <div v-if="customEngineContracts" class="bottom-right-fix">
          <SmallLoading v-show="isWatsonRunningTask"></SmallLoading>
          <button
            class="btn btn--white red"
            type="submit"
            :disabled="isWatsonRunningTask"
          >Delete Watson Assistant Configuration</button>
        </div>
      </form>

      <form v-else @submit.prevent="$emit('update-keys')">
        <div v-if="customEngineContracts" class="form-section">
          <FormGroup label="API Key">
            <input
              placeholder="Watson Assistant API Key"
              v-model="keys.apiKey"
              type="text"
              class="form-control"
              :disabled="isWatsonRunningTask"
            />
          </FormGroup>

          <FormGroup label="Service Region">
            <Select
              :elements="regions"
              :key="keys.region"
              keyAttr="0"
              valueAttr="1"
              v-model="keys.region"
              :disabled="isWatsonRunningTask"
              class="white-select"
            ></Select>
          </FormGroup>
        </div>

        <div class="bottom-right-fix">
          <SmallLoading v-show="isWatsonRunningTask"></SmallLoading>

          <button
            :disabled="isWatsonRunningTask"
            class="btn btn--white"
            type="button"
            @click="$emit('configure-watson')"
          >Let us configure Watson Assistant
          </button>

          <button v-if="customEngineContracts" class="btn btn--purple gradient" type="submit" :disabled="isWatsonRunningTask">
            <i class="icon icon-save" ></i>
            Save keys
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'
import SmallLoading from '../../../../../helpers/SmallLoading.vue'

@Component({
  components: { FormGroup, Select, SmallLoading, Modal }
})
export default class WatsonAssistant extends Vue {
  @Prop() keys: any;
  @Prop() regions: string[][];
  @Prop({ default: false }) isWatsonRunningTask: boolean;
  @Prop() readonly customEngineContracts: boolean;
}
</script>

<style lang="scss" scoped>
.inv-main-button {
  margin-left: 15px;
}
</style>
