import axios from 'axios'

declare const amazon: any

export class AlexaService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/amazon/${botName}`
  }

  async getKeys () {
    const res = await axios.get(`${this.url}/keys`)
    return res.data
  }

  updateKeys (keys: any) {
    return axios.put(`${this.url}/keys`, keys)
  }

  async getAmazonClientId () {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/amazon/clientId`)
    return res.data
  }

  /** @returns the access token for the scope */
  loginWithAmazon (scope: 'alexa::ask:skills:readwrite' | 'alexa::ask:models:readwrite') {
    return new Promise<any>((resolve, reject) => {
      amazon.Login.authorize({ scope }, (response: any) => {
        if (response.error) {
          reject(response.error)
          return
        }
        resolve(response.access_token)
      })
    })
  }

  async createSkill (skillToken: string, modelToken: string, keys: any) {
    const res = await axios.post(`${this.url}/create_skill`, {
      skillToken,
      modelToken,
      skillName: keys.skillName
    })
    return res.data
  }

  deleteSkill (skillToken: string) {
    return axios.post(`${this.url}/delete_skill`, { skillToken: skillToken })
  }
}
