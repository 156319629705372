<template>
<div class="top-bar">
  <div class="configuration-tabs">
    <div class="configuration-tabs--inner">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        type="button"
        class="tab"
        :class="{ 'tab--active': value == tab }"
        @click="$emit('input', tab)"
      >{{tab}}</button>
    </div>
  </div>
  <div class="buttons">
    <button type="button" class="btn btn--purple gradient" @click="$parent.$emit('create-user')" v-if="mode === 'admin' || mode === 'master-admin'">
      <i class="icon icon-add"></i>
      Add user
    </button>
    <button type="button" class="btn btn--purple gradient" @click="$parent.$emit('create-tenant')" v-if="mode === 'master-admin'">
      <i class="icon icon-add"></i>
      Add tenant
    </button>
  </div>
</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConfigurationMode } from './configuration-mode.type'

@Component({ name: 'ConfigurationPageNavTabs' })
export default class ConfigurationPageNavTabs extends Vue {
  @Prop({ default: () => [] }) readonly tabs: Array<string>;
  @Prop({ default: () => '' }) readonly value: string;
  @Prop({ default: () => false }) readonly mode: ConfigurationMode;
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  float: right;
}
</style>
