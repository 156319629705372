import rolesService from '../roles.service'
import botHelper from '@/helpers/bot.helper'
import { Permissions } from '../../../../common/enums/tenant/user-permissions.enum'
import { ITenant } from '../../../../common/interfaces/tenant/tenant.interface'
import { isMasterTenantAdmin, isTenantAdmin } from '../../../../common/helpers/tenant-role.helper'
import { UserTenantRole } from '../../../../common/types/users/user-tenant-role.type'

export class PermissionsService {
  tenant: Pick<ITenant, 'id' | 'displayName'>
  masterTenant: Pick<ITenant, 'id' | 'displayName'> | undefined

  private userTenantRoles: { [tenantId: string]: UserTenantRole } = {};
  private tenantRoles: { [roleId: string]: { permissions: Permissions[] } } = {};
  private botsRoles: { [botId: string]: { roleId: string; botId: string; role: string } } = {};

  private loadPromise: Promise<any>;

  setup () {
    if (!this.loadPromise) {
      this.loadPromise = rolesService.loadUserPermissions()
        .then(res => {
          this.tenant = res.tenant
          this.masterTenant = res.masterTenant
          this.userTenantRoles = res.userTenantRoles
          this.botsRoles = res.botRoles
          this.tenantRoles = res.tenantRoles
        })
    }
    return this.loadPromise
  }

  private get isTenantAdmin () {
    return isTenantAdmin({ tenantRoles: this.userTenantRoles }, this.tenant, this.masterTenant)
  }

  // Ensure that the setup() method is awaited before using this
  hasPermissionSync (botName: string, permission: Permissions) {
    if (this.isTenantAdmin) return true

    const botId = this.tenant.id + '-' + botHelper.assembleBotIdFragment(botName)
    const roleId = this.botsRoles[botId]?.roleId

    return (roleId ? this.tenantRoles[roleId]
      .permissions
      .includes(permission) : false)
  }

  async hasPermission (botName: string, permission: Permissions): Promise<boolean> {
    await this.setup()
    if (this.isTenantAdmin) return true

    const botId = this.tenant.id + '-' + botHelper.assembleBotIdFragment(botName)
    await this.checkIfBotLoaded(botId)
    const roleId = this.botsRoles[botId]?.roleId

    return (roleId ? this.tenantRoles[roleId]
      .permissions
      .includes(permission) : false)
  }

  async hasPermissions (botName: string, ...permissions: Permissions[]): Promise<boolean> {
    await this.setup()
    if (this.isTenantAdmin) return true

    const botId = this.tenant.id + '-' + botHelper.assembleBotIdFragment(botName)
    await this.checkIfBotLoaded(botId)
    const roleId = this.botsRoles[botId]?.roleId

    return (roleId ? permissions
      .every(p => this.tenantRoles[roleId]
        .permissions
        .includes(p)) : false)
  }

  async hasAllPermissions (botName: string, ...permissions: Permissions[]): Promise<boolean[]> {
    await this.setup()

    const permissionsArray: boolean[] = []

    for (const permission of permissions) {
      permissionsArray.push(await this.hasPermission(botName, permission))
    }

    return permissionsArray
  }

  async checkIfBotLoaded (botId: string) {
    if (!this.botsRoles[botId]) {
      await this.setup()
    }
  }

  async isUserAdmin () {
    await this.setup()
    return this.isTenantAdmin
  }

  async isMasterTenantAdmin () {
    await this.setup()
    return this.masterTenant ? isMasterTenantAdmin({ tenantRoles: this.userTenantRoles }, this.masterTenant) : true
  }

  async isMultiTenant () {
    await this.setup()
    return !!this.masterTenant
  }
}

export default new PermissionsService()
