<template>
  <ConfigurationContainer>
    <div class="col-12">
      <div class="contents__main">
        <div class="main-header">
          <div class="main-header__inner">
            <h1>Subscription Settings</h1>
          </div>
          <p>Manage your subscription & payment methods from here</p>
        </div>
        <SmallLoading v-if="isDataLoading"></SmallLoading>
        <div v-if="!isDataLoading">
          <div v-if="isTenantAdmin && !isDataLoading" class="form--wrapper">
            <div class="wrapper-inside general-settings active">
              <div class="form-section">
                <div v-if="!isMultiTenant">
                  <h4>Payments & Invoices</h4>
                  <StripeBillingButton></StripeBillingButton>
                </div>

                <h4>Subscription Plan</h4>
                <p>
                  Your current plan: <b> {{ getPlanName(plan) }} </b>
                </p>
                <p>
                  Total interactions
                  {{ plan.hasAdditionalInteractionsCost ? 'incl.' : undefined }}
                  this month:
                  <b>{{ getUsageAmount(tenantUsage.interactions, plan.maxFreeInteractions, plan.hasAdditionalInteractionsCost) }}</b>
                </p>
                <p>
                  Total seats:
                  <b>{{ getUsageAmount(tenantUsage.users, plan.maxFreeUsers, plan.hasAdditionalUsersCost) }}</b>
                </p>
                <p>
                  Total bots:
                  <b>{{ getUsageAmount(tenantUsage.bots, plan.maxFreeBots, plan.hasAdditionalBotsCost) }}</b>
                </p>
                <p v-if="isMultiTenant" v-html="multiTenantSubscriptionMessage"></p>

                <div v-if="isBasic(plan)" class="form-toggle--inside">
                  <Toggle :disabled="disableNluToggle" label="NLU Performance" v-model="hasNluPerformance" @input="enableNLUPerformance()"></Toggle>
                </div>

                <div v-if="isAppSumo(plan)" class="form-toggle--inside">
                  <Toggle
                    :disabled="disablePremiumChannelsToggle"
                    label="Enable premium channels"
                    v-model="hasPremiumChannels"
                    :tooltip="getAppsumoPremiumTooltipText()"
                    @input="enablePremiumChannels()"
                  ></Toggle>
                </div>

                <div v-if="isAppSumo(plan)" class="form-toggle--inside">
                  <Toggle
                    :disabled="disablePremiumEnginesToggle"
                    label="Enable premium engines"
                    v-model="hasPremiumEngines"
                    tooltip="If you switch on this toggle, you are then able to select Premium Engines like Microsoft Luis, Google DialogFlow and more in your bot's Settings -> Configuration page. <br>
Please note that each premium Engine you select will be charged separately at Euro 29 per month per bot. <br>
For example, if you select Google as Intent Recognizer and Microsoft as Entity Extractor for a bot, you will be charged Euro 2*29 = 58 per month for that bot. <br>
If on the other hand you select Google as Intent Recognizer and as Entity Extractor for a bot, you will be charged Euro 1*29 = 29 per month for that bot."
                    @input="enablePremiumEngines()"
                  ></Toggle>
                </div>

                <br /><br />
                <div v-if="!isMultiTenant" class="row subscription-plans">
                  <div :key="index" v-for="(p, index) in visiblePlans" class="subscription" :class="{ active: p.id === plan.id }">
                    <div class="name">{{ getPlanName(p) }}</div>
                    <div class="price">{{ getPlanCost(p) }}</div>
                    <div class="sub">per month</div>

                    <button
                      v-if="!isChangingToPlan(p.id)"
                      :disabled="!isMasterTenantAdmin || p.id === plan.id || isEnterprise(plan) || isCustom(plan) || isMasterTenant(plan)"
                      class="btn btn--purple gradient btn-centered"
                      type="submit"
                      @click="selectPlan(p.id)"
                    >
                      <i class=""></i>
                      {{ p.id === plan.id ? 'Your current plan' : isEnterprise(p) || isCustom(p) ? 'Contact us' : 'Select this plan' }}
                    </button>
                    <SmallLoading v-else class="center-loading"></SmallLoading>

                    <div class="includes">This includes:</div>
                    <div class="features" v-for="planFeature of getBulletsFromMetadata(p)" :key="planFeature">
                      <i class="icon-ok"></i>{{ p.metadata[`${planFeature}`] }}
                    </div>
                  </div>
                </div>
                <div class="sub">
                  Visit the
                  <a href="https://www.blits.ai/pricing" target="_blank" rel="noopener noreferrer">blits pricing page</a>
                  to view all prices and features
                </div>
              </div>
            </div>
          </div>
          <h4 v-else>
            You don't have the right permission for this action. Please contact your administrator.
          </h4>
        </div>
      </div>
    </div>
  </ConfigurationContainer>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import subscriptionMixin from '../_mixins/subscription.mixin'
import FormGroup from '../../../helpers/FormGroup.vue'
import ConfigurationContainer from '../../../helpers/ConfigurationContainer.vue'
import { TenantPlan } from '../../../../../../common/interfaces/plans/tenant-plan.interface'
import SmallLoading from '../../../helpers/SmallLoading.vue'
import StripeBillingButton from '../components/StripeBillingButton.vue'
import { ITenant } from '../../../../../../common/interfaces/tenant/tenant.interface'
import { IStripeCustomerDto } from '../../../../../../common/interfaces/stripe-customer-dto.interface'
import Toggle from '../../../../components/helpers/Toggle.vue'
import { availableSettingsChannels } from '../../../../../../common/constants/availableSettingsChannels.constant'
import tenantService from '../../../../services/tenant.service'
import { premiumChannels } from '../../../../../../common/constants/premiumChannels.constant'

@Component({
  name: 'General',
  components: {
    FormGroup,
    ConfigurationContainer,
    SmallLoading,
    StripeBillingButton,
    Toggle
  }
})
export default class Subscription extends Mixins(subscriptionMixin) {
  @Prop() isTenantAdmin: boolean
  @Prop() isMasterTenantAdmin: boolean
  @Prop() isMultiTenant: boolean
  @Prop() declare plan: TenantPlan
  @Prop() plans: TenantPlan[] = []
  @Prop() tenantUsage: any
  @Prop() isDataLoading: boolean
  @Prop() masterTenant: Pick<ITenant, 'id' | 'displayName'> | undefined
  @Prop() stripeCustomer: IStripeCustomerDto

  hasNluPerformance = false
  disableNluToggle = false
  hasPremiumChannels = true
  hasPremiumEngines = true
  disablePremiumChannelsToggle = true
  disablePremiumEnginesToggle = true

  async mounted() {
    const tenant = await tenantService.getTenant()
    this.hasNluPerformance = this.plan.metadata.hasNluPerformance
    this.hasPremiumChannels = tenant.hasPremiumChannels
    this.hasPremiumEngines = tenant.hasPremiumEngines
    this.disablePremiumChannelsToggle = false
    this.disablePremiumEnginesToggle = false
  }

  get multiTenantSubscriptionMessage() {
    return this.isMasterTenantAdmin
      ? 'This subscription is managed from the <b>Master Admin</b> configuration'
      : `Your subscription is managed by: <b>${this.stripeCustomer.customerName || this.masterTenant?.displayName}</b>`
  }

  async selectPlan(id: string) {
    if (this.isAppSumo(this.plan)) {
      window.open('https://appsumo.com/account/products/', '_blank')
    } else {
      const newPlan = await this.changePlan(id, undefined, this.stripeCustomer)
      if (newPlan) {
        this.plan = newPlan
      }
    }
  }

  async enableNLUPerformance() {
    this.disableNluToggle = true
    const result = await this.changeNLU(this.plan, this.stripeCustomer, this.hasNluPerformance)
    this.hasNluPerformance = result ? this.hasNluPerformance : !this.hasNluPerformance
    this.disableNluToggle = false
  }

  async enablePremiumChannels() {
    this.disablePremiumChannelsToggle = true
    const result = await this.changePremiumChannels(this.stripeCustomer, this.hasPremiumChannels)
    this.hasPremiumChannels = result ? this.hasPremiumChannels : !this.hasPremiumChannels
    this.disablePremiumChannelsToggle = false
  }

  async enablePremiumEngines() {
    this.disablePremiumEnginesToggle = true
    const result = await this.changePremiumEngines(this.stripeCustomer, this.hasPremiumEngines)
    this.hasPremiumEngines = result ? this.hasPremiumEngines : !this.hasPremiumEngines
    this.disablePremiumEnginesToggle = false
  }

  getAppsumoPremiumTooltipText() {
    return `If you switch on this toggle, you will be charged Euro 10 per month immediately for the premium channel bundle. <br>
            Once enabled, you can then select the following Premium Channels in your bot's Settings -> Configuration page:
            ${availableSettingsChannels.filter(ch => premiumChannels?.includes(ch.name)).map(ch => ` ${ch.name}`)}`
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';
th,
td {
  padding: 0 20px 10px 0;
  font-size: 15px;
  color: $default-dark;
  text-align: left;
}

h3 {
  text-align: center;
}

h4 {
  margin-top: 40px;
}

th {
  padding: 0 100px 10px 0;
}

.subscription-plans {
  display: flex;
  justify-content: space-evenly;
  background: transparent;
  padding: 30px;
  padding-top: 10px;

  .btn-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 175px;
  }

  .center-loading {
    margin-left: 33%;
  }

  .features {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    padding-top: 10px;
    padding: 10px 0px 0px 10px;

    span {
      font-weight: 400;
    }
  }

  .name {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin-top: 15px;
  }

  .price {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    margin-top: 5px;
  }

  .includes {
    text-align: center;
    font-size: 14px;
    color: $default-dark;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .sub {
    text-align: center;
    font-size: 12px;
    color: $grey;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .subscription {
    background: $white;
    margin: 10px;
    min-width: 240px;
    min-height: 150px;
    border-radius: 6px;
    border: 1px solid $light-blue;
    box-shadow: 0px 6px 30px #00000014;
    -webkit-box-shadow: 0px 6px 30px #00000014;
    -ms-box-shadow: 0px 6px 30px #00000014;
    overflow: hidden;
    padding: 0px 10px 25px 10px;

    &.active {
      border: 2px solid $main;

      .name {
        color: $main;
      }
    }
  }
}
</style>
