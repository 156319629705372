import { Component, Vue } from 'vue-property-decorator'
import { GdprService } from '@/services/bots/settings/gdpr.service'

@Component
export default class GdprManagementMixin extends Vue {
  gdprService: GdprService;
  gdprSettings: any = {};

  async initGdpr (botName: string) {
    this.gdprService = new GdprService(botName)
    this.gdprSettings = await this.gdprService.getGdpr()
  }

  updateGdpr () {
    this.validateRegexes()
    return this.gdprService.updateGdpr(this.gdprSettings)
  }

  validateRegexes () {
    for (const regex of this.gdprSettings.maskingPatterns) {
      try {
        new RegExp(regex)
      } catch (_) {
        throw new Error('Invalid regex:' + regex)
      }
    }
  }
}
