<template lang="html">
  <div class="rect rect--costs" v-if="answered">
    <h1 style="text-align: left">
      User Ratings
    </h1>
    <div class="row" v-if="all > 0">
      <div class="col-8">
        <h3>People presented with the rating</h3>
      </div>
      <div class="col-4 text-right">
        <p>{{all | format}}</p>
      </div>
      <div class="col-8">
        <h3>People left rating</h3>
      </div>
      <div class="col-4 text-right">
        <p>{{all - answered | format}}</p>
      </div>
      <div v-if="sortedScores.length !== 0" class="rect--costs__border"></div>
      <div v-for="key of sortedScores" :key="key"  style="width: 100%; display: flex">
        <template v-if="getNumRating(key) != ''">
          <div class="col-8">
            <h3>{{getNumRating(key)}}</h3>
          </div>
          <div class="col-4 text-right">
            <p>{{results[key] | format}}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from '../filters/number.filters'

@Component({
  filters: {
    format: format
  }
})
export default class Rating extends Vue {
  @Prop({ default: 0 }) readonly answered: number;
  @Prop({ default: 0 }) readonly all: number;
  @Prop({ default: () => { return [] } }) readonly results: { [key: number]: number };

  get sortedScores () {
    return Object.keys(this.results)
      .filter(key => this.results[key] > 0)
      .sort((a: any, b: any) => b - a)
  }

  getNumRating (key) {
    // 'Very satisfied', 'Satisfied', 'Neutral', 'Unsatisfied', 'Very unsatisfied'
    switch (key) {
      case '5': return 'Very satisfied'
      case '4': return 'Satisfied'
      case '3': return 'Neutral'
      case '2': return 'Unsatisfied'
      case '1': return 'Very unsatisfied'
      default: return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "analytics.style";

.line {
  margin-bottom: 15px;
}

.results {
  width: 100%;
  border-top: 1px solid #eeecec;
  padding-top: 10px;
}

.rect {
  &--costs {
    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2,
    h3,
    p {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: $secondary-text;
      line-height: 15px;
      text-transform: uppercase;
    }
    p {
      position: relative;
      top: 1px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
    &__border {
      display: inline-block;
      border-top: 1px solid #d8d8d8;
      width: 100%;
      margin: 8px 15px 12px;
    }
  }
}
</style>
