import FlowComponent from '../../FlowComponent'
import { IBlock } from '../../../../../../../../common/blocks/block.interface'
import { Restrictions } from '../../../../../../../../common/blocks/restrictions'
import flowComponentService from '../../flow-component.service'

export default class DialogBlockComponent<T extends IBlock> extends FlowComponent<T> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions () {
    return null
  }

  protected updateSuggestionFieldCapabilities (fieldName: string) {
    this.checkSuggestionFieldCapabilities(fieldName, 'allBlocks')
    this.checkSuggestionFieldCapabilities(fieldName, this.customData.fc)
  }

  protected updateOptionalFieldsCapabilities (fieldsKey: string) {
    this.checkOptionalFieldsCapabilities(fieldsKey, 'allBlocks')
    this.checkOptionalFieldsCapabilities(fieldsKey, this.customData.fc)
  }

  private checkSuggestionFieldCapabilities (fieldName: string, fc: string) {
    const restrictions = Restrictions.channelResctrictions
    if (restrictions[fc]) {
      for (const channel of Object.keys(restrictions[fc])) {
        for (const restriction of restrictions[fc][channel]) {
          const messageLenght: number = this.customData[fieldName] ? this.customData[fieldName].length : 0

          if (Number(restriction.condition) <= messageLenght) {
            flowComponentService.remotelyAddRestriction(this.id, this.customData.fc, channel, restriction.type, restriction.message + this.customData.fc)
          } else {
            flowComponentService.remotelyDeleteRestriction(this.id, this.customData.fc, channel, restriction.type, restriction.message + this.customData.fc)
          }
        }
      }
    }
  }

  private checkOptionalFieldsCapabilities (fieldsKey: string, fc: string) {
    const restrictions = Restrictions.channelResctrictions
    if (restrictions[fc]) {
      for (const channel of Object.keys(restrictions[fc])) {
        try {
          for (const restriction of restrictions[fc][channel]) {
            const fieldsValues: string[] = this.customData[fieldsKey] ? this.customData[fieldsKey] : 0
            let index = 0
            for (const fiedlsValue of fieldsValues) {
              if (Number(restriction.condition) <= fiedlsValue.length) {
                flowComponentService.remotelyAddRestriction(this.id + index, this.customData.fc, channel, restriction.type, restriction.message + this.customData.fc)
              } else {
                flowComponentService.remotelyDeleteRestriction(this.id + index, this.customData.fc, channel, restriction.type, restriction.message + this.customData.fc)
              }
              index++
            }

            if (fieldsValues.length == 0) {
              flowComponentService.deleteAllOptionalFieldsCapabilities(this.id, this.customData.fc)
            }
          }
        } catch (_) {
          continue
        }
      }
    }
  }
}
