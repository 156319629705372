<template>
  <div class="form-group">
    <label for="function">
      Object&Property
      <Tooltip title="These dropdowns define which object and property to set." placement="bottom"></Tooltip>
    </label>

    <div class="form-group--inside">
      <Select
        id="function"
        :elements="globalObjects"
        :key="currentBlock.customData['field-object']"
        keyAttr="name"
        v-model="currentBlock.customData['field-object']"
        @input="changeObject($event)"
        :disabled="!hasSufficientPermission"
      ></Select>
    </div>

    <div class="form-group--inside" style="margin-top: 15px">
      <Select
        id="condition"
        :elements="properties"
        :key="currentBlock.customData['field-object-property']"
        v-model="currentBlock.customData['field-object-property']"
        keyAttr
        :disabled="!hasSufficientPermission"
      ></Select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Select from '../../../../../../helpers/Select.vue'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import { ISetObjectBlock } from '../../../../../../../../../common/blocks/action/set-object-block.interface'
import SetObjectBlockComponent from '../../../../modules/blocks/action/components/SetObjectBlockComponent'

@Component({
  components: {
    Select,
    Tooltip
  }
})
export default class SetObjectExtension extends Vue {
  private properties = [];

  @Prop({ default: () => { return {} } }) readonly currentBlock!: SetObjectBlockComponent<
    ISetObjectBlock
  >;

  @Prop({ default: () => { return {} } }) readonly objects!: {};
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  get globalObjects () {
    return this.objects ? Object.keys(this.objects) : []
  }

  mounted () {
    this.properties = this.objects
      ? Object.keys(this.objects[this.currentBlock.customData['field-object']].variables) || []
      : []
  }

  changeObject (event) {
    if (event) {
      this.properties = Object.keys(this.objects[event].variables)
      this.currentBlock.customData[
        'field-object-property'
      ] = this.properties[0]
    } else {
      this.properties = []
      this.currentBlock.customData['field-object-property'] = undefined
    }
  }
}
</script>
