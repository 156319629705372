export const taxIdTypes = [
  "au_abn Australia",
  "au_arn Australia",
  "eu_vat Austria",
  "eu_vat Belgium",
  "br_cnpj Brazil",
  "br_cpf Brazil",
  "bg_uic Bulgaria",
  "eu_vat Bulgaria",
  "ca_bn Canada",
  "ca_gst_hst Canada",
  "ca_pst_bc Canada",
  "ca_pst_mb Canada",
  "ca_pst_sk Canada",
  "ca_qst Canada",
  "cl_tin Chile",
  "eu_vat Croatia",
  "eu_vat Cyprus",
  "eu_vat Czech Republic",
  "eu_vat Denmark",
  "eg_tin Egypt",
  "eu_vat Estonia",
  "eu_oss_vat European Union",
  "eu_vat Finland",
  "eu_vat France",
  "ge_vat Georgia",
  "eu_vat Germany",
  "eu_vat Greece",
  "hk_br Hong Kong",
  "hu_tin Hungary",
  "eu_vat Hungary",
  "is_vat Iceland",
  "in_gst India",
  "id_npwp Indonesia",
  "eu_vat Ireland",
  "il_vat Israel",
  "eu_vat Italy",
  "jp_cn Japan",
  "jp_rn Japan",
  "jp_trn Japan",
  "ke_pin Kenya",
  "eu_vat Latvia",
  "li_uid Liechtenstein",
  "eu_vat Lithuania",
  "eu_vat Luxembourg",
  "my_frp Malaysia",
  "my_itn Malaysia",
  "my_sst Malaysia",
  "eu_vat Malta",
  "mx_rfc Mexico",
  "eu_vat Netherlands",
  "nz_gst New Zealand",
  "no_vat Norway",
  "ph_tin Philippines",
  "eu_vat Poland",
  "eu_vat Portugal",
  "eu_vat Romania",
  "ru_inn Russia",
  "ru_kpp Russia",
  "sa_vat Saudi Arabia",
  "sg_gst Singapore",
  "sg_uen Singapore",
  "eu_vat Slovakia",
  "si_tin Slovenia",
  "eu_vat Slovenia",
  "za_vat South Africa",
  "kr_brn South Korea",
  "es_cif Spain",
  "eu_vat Spain",
  "eu_vat Sweden",
  "ch_vat Switzerland",
  "tw_vat Taiwan",
  "th_vat Thailand",
  "tr_tin Turkey",
  "ua_vat Ukraine",
  "ae_trn United Arab Emirates",
  "gb_vat United Kingdom",
  "eu_vat United Kingdom",
  "us_ein United States",
];
