<template>
  <div class="form-section">
    <FormGroup label="Company name (Customer name)">
      <input type="text" v-model="stripeCustomer.customerName" />
    </FormGroup>
    <FormGroup label="Billing address">
      <input type="text" v-model="stripeCustomer.address" />
    </FormGroup>
    <FormGroup label="Country">
      <Select :elements="countries" v-model="stripeCustomer.country" class="white-select"></Select>
    </FormGroup>
    <FormGroup label="Zip code">
      <input type="text" v-model="stripeCustomer.zipCode" />
    </FormGroup>
    <FormGroup label="City">
      <input type="text" v-model="stripeCustomer.city" />
    </FormGroup>
    <FormGroup label="Phone number">
      <input type="text" v-model="stripeCustomer.phoneNumber" />
    </FormGroup>
    <FormGroup label="Tax Id">
      <div class="tax-id"></div>
      <Select :elements="taxTypes" v-model="stripeCustomer.taxIdType" class="white-select"></Select><input type="text" v-model="stripeCustomer.taxIdValue" />
    </FormGroup>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { countryNames, countryNamesByCode } from '../../../../../../common/constants/countries.constant'
import { IStripeCustomerDto } from '../../../../../../common/interfaces/stripe-customer-dto.interface'
import FormGroup from '../../../helpers/FormGroup.vue'
import Select from '../../../helpers/Select.vue'
import { taxIdTypes } from '../../../../../../common/constants/taxIdTypes.constant'

@Component({
  components: { FormGroup, Select }
})
export default class StripeCustomerFormSection extends Vue {
  @Prop({ required: true, default: () => ({}) })
  stripeCustomer: IStripeCustomerDto
  countries = countryNames
  taxTypes = taxIdTypes

  @Watch('stripeCustomer.country')
  filterTaxTypes() {
    this.taxTypes = taxIdTypes.filter((taxType) => taxType.split(' ')[1].toLowerCase() === this.stripeCustomer.country.toLocaleLowerCase())
  }

  mounted() {
    this.stripeCustomer.country = countryNamesByCode[this.stripeCustomer.country]
  }

  destroyed() {
    this.stripeCustomer.country = Object.keys(countryNamesByCode).find((key) => countryNamesByCode[key] === this.stripeCustomer.country)
  }
}
</script>

<style lang="scss" scoped>
.tax-id {
  display: flex;
  gap: 10px;
}
</style>
