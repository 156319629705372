import { Component, Vue } from 'vue-property-decorator'
import { SalesforceService } from '@/services/bots/settings/configuration/salesforce.service'
import popupService from '@/services/popup.service'
import { SalesforceDetailEntityMapping } from '../../../../../../../../common/interfaces/salesforce-detail-entity-mapping'

@Component
export default class SalesforceManagementMixin extends Vue {
  salesforceService: SalesforceService;
  keys: any;
  privateKeyFile?: File;

  initSalesforce (botName: string) {
    this.salesforceService = new SalesforceService(botName)
    this.getSalesforceKeys()
  }

  async getSalesforceKeys () {
    this.keys = await this.salesforceService.getKeys()
  }

  async updateSalesforceKeys () {
    try {
      this.validateCaseMappings()
      await this.salesforceService.updateKeys(this.keys)
      if (this.privateKeyFile) {
        await this.salesforceService.updatePrivateKey(this.privateKeyFile)
        this.keys.privateKeyFileName = this.privateKeyFile.name
      }
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status === 403) {
        throw err
      }
      popupService.showError('Error!', err.response ? err.response.data.message : err.message)
    }
  }

  validateCaseMappings () {
    if (!(this.keys.caseMappings as SalesforceDetailEntityMapping[]).every(m => !!m.label && !!m.value)) { throw new Error('Case mappings must have a label and a value') }
  }

  changePrivateKeyFile (file: File) {
    this.privateKeyFile = file
  }
}
