<template>
  <div class="container">
    <div class="main-card">
      <div class="form-content">
        <div class="heading">
          <img src="/assets/img/Blits-logo-black.png" />
          <h4>Forgot password?</h4>
        </div>
        <form class="form" @submit.prevent="submit">
          <div class="login">
            <div class="input-container">
              <div class="icon-box"><i class="icon icon-user"></i></div>
              <input class="input-field" type="email" v-model="userId" placeholder="Email" />
            </div>
            <p class="description-text">You will receive a reset link via email</p>
            <div class="button-group">
              <button type="submit" v-if="!isInProgress" class="btn btn--purple gradient narrow reset-password-button">Reset password</button>
              <SmallLoading v-else class="loading"></SmallLoading><br />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import popupService from '../../services/popup.service'
import usersService from '../../services/users.service'
import SmallLoading from '../helpers/SmallLoading.vue'
import authManagmentMixin from './auth-managment.mixin'

@Component({ components: { SmallLoading } })
export default class ForgotPassword extends Mixins(authManagmentMixin) {
  private userId = ''
  private isInProgress = false

  async beforeMount() {
    await this.checkAuth()
    this.routerCheck()
  }

  async submit() {
    this.isInProgress = true
    if (!this.userId || !this.matchEmail(this.userId)) {
      popupService.showError('Invalid email', 'Please provide a valid email')
    } else {
      try {
        const result = await usersService.requestPasswordReset(this.userId)
        if (result.status == 204) {
          this.userId = ''
        }
      } catch (_) {
        /* Empty block */
      } finally {
        popupService.showInfo('Important', 'If your email is known to us, you will receive a mail with a link to reset your password.')
      }
    }
    this.isInProgress = false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import './auth';

.heading {
  margin-bottom: 40px;
}

.container .main-card {
  min-height: 600px;
}

.form-content {
  background-color: transparent;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.description-text {
  padding-top: 20px;
  font-weight: bold;
  font-size: 14px;
  color: $grey;
}

.loading {
  margin-left: 37% !important;
}
</style>
