<template>
  <Modal title="Rasa Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form v-if="!hasServer" @submit.prevent="$emit('create-server')">
        <div class="form-section">
          <h4>This bot doesn't have Rasa server</h4>
        </div>
        <div class="bottom-right-fix">
          <SmallLoading v-show="isOperationActive" placement="right"></SmallLoading>

          <button :disabled="isOperationActive" class="btn btn--purple gradient" type="submit">
             <i class="icon icon-save" ></i>
            Create Rasa Server
          </button>

        </div>
      </form>
      <form v-else @submit.prevent="$emit('delete-server')">
        <div class="form-section">
          <h4>Rasa server is active for this bot</h4>
        </div>
        <div class="bottom-right-fix">
          <SmallLoading v-show="isOperationActive" placement="right"></SmallLoading>
          <button :disabled="isOperationActive" class="btn btn--white red" type="submit">
                  Delete Rasa Server
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'
import SmallLoading from '../../../../../helpers/SmallLoading.vue'

@Component({
  components: { FormGroup, Select, SmallLoading, Modal }
})
export default class WatsonAssistant extends Vue {
  @Prop() hasServer: boolean;
  @Prop() isOperationActive: boolean;
}
</script>
