import { IUser } from '../interfaces/tenant/user.interface';
import { ITenant } from '../interfaces/tenant/tenant.interface';

export function isTenantAdmin(user: Pick<IUser, 'tenantRoles'>, tenant: Pick<ITenant, 'id'>, masterTenant: Pick<ITenant, 'id'> | undefined) {
    return isMasterTenantAdmin(user, masterTenant) || user.tenantRoles[tenant.id] === 'admin';
}

export function isMasterTenantAdmin(user: Pick<IUser, 'tenantRoles'>, masterTenant: Pick<ITenant, 'id'> | undefined) {
    return masterTenant ? user.tenantRoles[masterTenant.id] === 'admin' : false;
}

export function toggleTenantRole(user: Pick<IUser, 'tenantRoles'>, tenant: Pick<ITenant, 'id'>) {
    if (!user.tenantRoles[tenant.id]) user.tenantRoles[tenant.id] = 'admin'
    else if (user.tenantRoles[tenant.id] === 'user') user.tenantRoles[tenant.id] = 'admin'
    else if (user.tenantRoles[tenant.id] === 'admin') user.tenantRoles[tenant.id] = 'user'
}

export function getVisibleTenantsIds(user: Pick<IUser, 'tenantRoles'>, masterTenantId: string) {
    return Object.keys(user.tenantRoles).filter(id => id != masterTenantId);
}