
<template>
  <div class="form-group image-dropdown">
    <label for="function">
      Question / Text
      <Tooltip
        title="This field gives the possibility to add Question / Text to the rating-card."
        placement="bottom"
      ></Tooltip>
    </label>

    <editor
      :items="variableNames"
      v-model="currentBlock.customData['field-suggestion']"
      :placeholder="'Question / Text'"
      @change="changeQuestion($event)"
      :disabled="!hasSufficientPermission"
    />

    <br />
    <label for="function">
      Answers
      <Tooltip
        title="This fields gives the possibility to change the language of the default rating block answers."
        placement="bottom"
      ></Tooltip>
    </label>

    <div v-for="(answer, i) in answers" :key="i">
      <autocomplete
        style="margin-bottom: 10px"
        :items="variableNames"
        v-model="answers[i]"
        :placeholder="'Enter answer ' + (i + 1)"
        :rows="1"
        :disabled="!hasSufficientPermission"
        class="show-options"
      />
    </div>

    <div class="form-group form-group--options">
      <input
        type="checkbox"
        class="form-check-input"
        id="sync-handover-check"
        v-model="currentBlock.customData['sync-handover']"
        :disabled="!hasSufficientPermission"
      />
      <label class="form-check-label" for="sync-handover-check">Sync with current handover provider</label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { defaultRatingText } from '../../../../../../../../../common/constants/rating-text.constant'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import editor from '../../../../Editor.vue'
import { IRatingBlock } from '../../../../../../../../../common/blocks/dialog/rating-block.interface'
import Autocomplete from '../../../../../../helpers/Autocomplete.vue'
import ShowRatingBlockComponent from '../../../../modules/blocks/dialog/blocks/ShowRatingBlockComponent'

@Component({ components: { Tooltip, editor, Autocomplete } })
export default class ShowRatingExtension extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: ShowRatingBlockComponent<
    IRatingBlock
  >;

  @Prop({ default: () => [] }) readonly variableNames!: any[];
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  private currenFielsData = this.currentBlock.customData[
    'field-possible-answers'
  ];

  private answers: string[] = this.currenFielsData && this.currenFielsData.length != 0
    ? Object.values(this.currenFielsData)
    : Object.keys(defaultRatingText)
      .sort(k => -k)
      .map(k => defaultRatingText[k]);

  @Watch('answers')
  changeAnswers () {
    this.currentBlock.customData['field-possible-answers'] = this.answers
  }

  changeQuestion (field: { content: string }) {
    this.currentBlock.customData['field-suggestion'] = field.content
  }
}
</script>

<style lang="scss" scoped>

</style>
