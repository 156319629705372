
import { RestrictionsTypes } from '../enums/restrictions-types.enum';
export module Restrictions {

    export const channelCapabilities: { [blockName: string]: { [channel: string]: { message: string, type: string }[] } } = {
        "sendGif": {
            "Google Assistant": [
                {
                    "message": "Not all Google Assistant devices support images/gifs",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Twilio": [
                {
                    "message": "Twilio Voice doesn't support images/gifs",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Cortana": [
                {
                    "message": "Not supported",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "GroupMe": [
                {
                    "message": "Media may not be playable",
                    "type": RestrictionsTypes.WARNING
                }
            ]

        },
        "sendAttachment": {
            "Google Assistant": [
                {
                    "message": "Cannot show attachments",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Twilio": [
                {
                    "message": "Cannot show attachments",
                    "type": RestrictionsTypes.WARNING
                }
            ]
        },
        "adaptiveCard": {
            "Google Assistant": [
                {
                    "message": "Cannot show adaptive cards",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Twilio": [
                {
                    "message": "Adaptive cards are converted to images",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Skype": [
                {
                    "message": "Adaptive cards are not suported by this channel",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "Facebook": [
                {
                    "message": "Partial Support - may experience diffs in design. Adaptive cards are converted to images",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "GroupMe": [
                {
                    "message": "Adaptive cards are converted to images",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Kik": [
                {
                    "message": "Adaptive cards are converted to images",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Line": [
                {
                    "message": "Partial Support - may experience diffs in design. Adaptive cards are converted to images",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Slack": [
                {
                    "message": "Adaptive cards are converted to images",
                    "type": RestrictionsTypes.WARNING
                }
            ],
            "Telegram": [
                {
                    "message": "Partial Support - may experience diffs in design. Adaptive cards are converted to images",
                    "type": RestrictionsTypes.WARNING
                }
            ]
        }

    };


    export const channelResctrictions: { [blockName: string]: { [channel: string]: { condition: any, message: string, type: string }[] } } = {
        "showOptions": {
            "Twilio": [
                {
                    "condition": 10,
                    "message": "Must have maximum of 10 options",
                    "type": RestrictionsTypes.ERROR
                }
            ]
        },
        "allBlocks": {
            "Skype": [
                {
                    "condition": 25000,
                    "message": "Must have maximum of 25000 characters",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "Discord": [
                {
                    "condition": 2000,
                    "message": "Must have maximum of 2000 characters",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "Slack": [
                {
                    "condition": 45000,
                    "message": "Must have maximum of 45000 characters",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "Twilio": [
                {
                    "condition": 1600,
                    "message": "Twilio SMS maximum message length is 1600 characters ",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "Apple Business Chat": [
                {
                    "condition": 160,
                    "message": "If the message is send as iMessage the limit is 20000. If the user receive it as sms, it will be truncated at 160 characters",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "WhatsApp": [
                {
                    "condition": 65536,
                    "message": "Must have maximum of 65536 characters",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "Facebook": [
                {
                    "condition": 63206,
                    "message": "Must have maximum of 63206 characters",
                    "type": RestrictionsTypes.ERROR
                }
            ],
            "Google Assistant": [
                {
                    "condition": 200,
                    "message": "200 is тhe recommended message size",
                    "type": RestrictionsTypes.WARNING
                }
            ]
        }

    };



}

