import { Component, Vue } from 'vue-property-decorator'
import { AlexaService } from '@/services/bots/settings/configuration/alexa.service'
import popupService from '@/services/popup.service'

declare const amazon: any

@Component
export default class AlexaManagementMixin extends Vue {
  amazonService: AlexaService;
  keys: any;
  bot: any

  initAlexa (botName: string) {
    this.amazonService = new AlexaService(botName)
    this.getAlexaKeys()
    this.checkAmazonSdk()
  }

  async getAlexaKeys () {
    this.keys = await this.amazonService.getKeys()
  }

  async updateAlexaKeys () {
    try {
      await this.amazonService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      popupService.showError('Error!', err.message)
    }
  }

  async checkAmazonSdk () {
    if (document.getElementById('amazon-root').children.length === 0) {
      const clientId = await this.amazonService.getAmazonClientId()
      /* eslint-disable */
      // @ts-ignore
      window.onAmazonLoginReady = function () {
        amazon.Login.setClientId(clientId)
      };
      /* eslint-enable */
      (function (d) {
        const a = d.createElement('script')
        a.type = 'text/javascript'
        a.async = true
        a.id = 'amazon-login-sdk'
        a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js'
        d.getElementById('amazon-root').appendChild(a)
      })(document)
    }
  }

  async createAlexaSkill () {
    try {
      const skillToken = await this.amazonService.loginWithAmazon('alexa::ask:skills:readwrite')
      const modelToken = await this.amazonService.loginWithAmazon('alexa::ask:models:readwrite')
      const keys = await this.amazonService.createSkill(skillToken, modelToken, this.keys)
      this.keys = keys
      popupService.showInfo('Success!')
    } catch (err) {
      popupService.showError('Error!', err.message)
    }
  }

  async deleteAlexaSkill () {
    try {
      const token = await this.amazonService.loginWithAmazon('alexa::ask:skills:readwrite')
      await this.amazonService.deleteSkill(token)
      popupService.showInfo('Success!')
    } catch (err) {
      if (err.response && err.response.status === 403) {
        throw err
      }
      popupService.showError('Error!', err.message)
    }
  }
}
