<template>
  <div class="rect rect--top-intents">
    <div>
      <h1>{{ config.name }}</h1>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th
              v-for="(value, index) in config.headerCells"
              :key="index"
              scope="col"
              :class="getThClass(index)"
              @click="sortCol(index)"
            >
              {{ value | uppercase }}
              <span class="sort-arrow" v-if="getSortSymbol(index)" v-html="getSortSymbol(index)"></span>
            </th>
          </tr>
        </thead>
        <tbody :key="lastSortedCol">
          <tr
            v-for="(values, rowIndex) in paginatedItems"
            :key="rowIndex"
          >
            <td
              v-for="(value, colIndex) in values"
              :key="colIndex"
            >

              <img :src="'/assets/icons/icon-logo-ms.png'" class="support-logo small" v-if="value === 'Microsoft'" alt="Logo Microsoft">
              <img :src="'/assets/icons/icon-logo-ms.png'" class="support-logo small" v-if="value === 'MicrosoftCLU'" alt="Logo Microsoft">
              <img :src="'/assets/img/logo-ibm.png'" class="support-logo small" v-if="value === 'IBM'" alt="Logo IBM">
              <img :src="'/assets/img/logo-google.png'" class="support-logo small" v-if="value === 'Google'" alt="Logo Google">
              <img :src="'/assets/img/logo-rasa.png'" class="support-logo small" v-if="value === 'Rasa'" alt="Logo Rasa">
              <img :src="'/assets/img/logo-amazon.png'" class="support-logo small" v-if="value === 'Amazon'" alt="Logo Amazon">
              <img :src="'/assets/img/logo-wit.png'" class="support-logo small" v-if="value === 'Wit'" alt="Logo Wit AI">
              <img :src="'/assets/img/logo-nuance.png'" class="support-logo small" v-if="value === 'Nuance'" alt="Logo Nuance">
              <img :src="'/assets/img/logo-neuralspace.svg'" class="support-logo small" v-if="value === 'NeuralSpace'" alt="Logo Nuance">
             <span :class="getTdClass(colIndex)">{{ formatValue(value, colIndex) }}</span>
              <span class="special-label" v-if="getSpecialLabel(value, colIndex)">{{ getSpecialLabel(value, colIndex) }}</span>
            </td>
          </tr>
          <!-- Ghost rows so the table doesn't resize -->
          <div v-if="config.paginationSettings && isLastPage">
            <tr
              v-for="rowNumber in perPage - lastPageItems"
              :key="rowNumber + 'gr'"
            >
              <td
                v-for="colNumber in paginatedItems[0].length"
                :key="colNumber + 'gc'"
              >
                <span class="pill-ghost" :class="getTdClass(colNumber - 1, rowNumber)">&nbsp;</span>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-if="config.paginationSettings"
      @change="onPageChanged"
      :total-rows="data.length"
      :per-page="perPage"
      v-model="currPageNumber"
      aria-controls="my-table"
      class="page-controls"
    >
      <span class="dark-text" slot="first-text">First</span>
      <span class="dark-text" slot="prev-text">Prev</span>
      <span class="dark-text" slot="next-text">Next</span>
      <span class="dark-text" slot="last-text">Last</span>
    </b-pagination>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AnalyticsTableConfig } from '../../models/analytics-table.interface'
import { uppercase } from '../filters/string.filters'
import { fix } from '../filters/number.filters'

@Component({
  filters: {
    uppercase
  }
})
export default class AnalyticsTable extends Vue {
  @Prop({ required: true }) config: AnalyticsTableConfig;
  @Prop({ default: [[]] }) cells: any[][];

  @Watch('cells', { deep: true, immediate: true })
  onCellsChanged (val) {
    this.data = val || [[]]
    this.paginate(this.currPageNumber - 1)
  }

  private data: any[][] = [[]];

  mounted () {
    this.data = this.cells || [[]]
    this.paginate(0)
  }

  get perPage () {
    return this.config.paginationSettings?.perPage || Number.MAX_SAFE_INTEGER
  }

  get isLastPage () {
    return this.currPageNumber - 1 == Math.floor(this.data.length / this.perPage)
  }

  get lastPageItems () {
    return this.data.length % this.perPage
  }

  currPageNumber = 1;
  paginatedItems: any[][] = [[]];
  paginate (pageIndex: number) {
    const start = pageIndex * this.perPage
    this.paginatedItems = this.data.slice(start, start + this.perPage)
  }

  onPageChanged (pageNumber: number) {
    this.paginate(pageNumber - 1)
  }

  getColSettings (index: number) {
    return (this.config.columnSettings || [])[index] || {}
  }

  lastSortedCol = 0;
  sortCol (colIndex: number) {
    if (this.getThClass(colIndex)) {
      if (this.lastSortedCol == colIndex + 1) {
        this.sortData(colIndex, true)
        this.lastSortedCol = -this.lastSortedCol
      } else {
        this.sortData(colIndex, false)
        this.lastSortedCol = colIndex + 1
      }
      this.paginate(this.currPageNumber - 1)
    }
  }

  sortData (colIndex: number, isReverse: boolean) {
    this.data.sort((row1, row2) => {
      const v1 = row1[colIndex]
      const v2 = row2[colIndex]

      const compareValue = this.getColSettings(colIndex).sortType == 'string'
        ? String(v1).toLowerCase().localeCompare(String(v2).toLowerCase())
        : Number(v1) - Number(v2)
      return isReverse ? -compareValue : compareValue
    })
  }

  getSortSymbol (colIndex: number) {
    if (this.lastSortedCol == colIndex + 1) return '&uarr;'
    else if (this.lastSortedCol == -(colIndex + 1)) return '&darr;'
  }

  getThClass (colIndex: number) {
    return this.getColSettings(colIndex).sortType ? 'cursor-pointer' : undefined
  }

  getTdClass (colIndex: number) {
    return this.getColSettings(colIndex).isStrong ? 'pill' : undefined
  }

  formatValue (value: any, colIndex: number) {
    if (value === 'Microsoft') {
      return 'Microsoft LUIS'
    } else if (value === 'MicrosoftCLU') {
      return 'Microsoft CLU'
    }
    const settings = this.getColSettings(colIndex)
    return settings.isPercent ? fix(value, 1) + '%' : value
  }

  getSpecialLabel (value: any, colIndex: number) {
    const settings = this.getColSettings(colIndex)
    for (const rule of settings.specialLabelRules || []) {
      if (rule.textToFind == String(value) || (rule.condition && rule.condition(value))) { return rule.label }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "analytics.style";

.rect {
  &--top-intents {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .table {
      color: #979797;
      thead {
        tr {
          border-bottom: 1px solid;
          th {
            font-size: 12px;
            font-weight: 600;

            .sort-arrow {
              position: absolute;
              margin: -5px 0 0 5px;
            }
          }
        }
      }
      tbody {
        td {
          font-size: 14px;
          color: black
        }
      }
    }
    h1 {
      color: #8743FF;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2, .pill, p {
      font-family: "Raleway", sans-serif;
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
    .top-intent + .top-intent {
      margin-top: 10px;
    }
    .page-controls {
      align-self: flex-end;
    }
    .pill {
      position: relative;
      display: inline-block;
      background: $white;
      border-radius: 3px;
      font-size: 10px;
      font-weight: bold;
      color: $default-dark;
      line-height: 18px;
      margin-bottom: 4px;
      padding: 0 7px;
      text-transform: uppercase;

      span {
        font-size: 10px;
        color: #00338d;
      }
    }
    .pill-ghost {
      background: white;
    }
    .special-label {
      background: transparent linear-gradient(283deg, #8743FF 0%, #5516C6 100%) 0% 0% no-repeat padding-box;
      padding: 3px 5px 3px 5px;
      margin-top: 10px;
      color: #fff;
      border-radius: 6px;
      font-size: 8px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #000;
      line-height: 18px;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 54px;
    }
  }
}
</style>
