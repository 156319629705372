/**
 * These models have been replaced on January 2024. https://openai.com/index/gpt-4-api-general-availability/#deprecation-of-older-models-in-the-completions-api
 * Older model      New model
 *  ada              babbage-002
 *  babbage          babbage-002
 *  curie            davinci-002
 *  davinci          davinci-002
 *
 */

export enum OpenAiEngine3_0 {
    Davinci = 'davinci',
    Curie = 'curie',
    Babbage = 'babbage',
    Ada = 'ada'
};