<template>
  <Modal title="Edit user permissions" :show="true" @close="$emit('close')">
      <div class="form--wrapper">
      <div class="permission-text margin-bottom">
        Edit the permissions for <b>{{user.id}}</b> here.
      </div>
        <table v-if="Object.values(user.botRoles).length > 0 || isTenantAdmin">
          <thead v-if="!isTenantAdmin">
            <tr>
              <td>
                <h4>Bot name</h4>
              </td>
              <td>
                <h4>Role</h4>
              </td>
            </tr>
          </thead>
          <tbody v-if="!isTenantAdmin">
            <tr v-for="(botRole) in Object.values(user.botRoles)" :key="botRole.botId">
              <td><div class="botname">{{returnBotNameById(botRole.botId)}}</div></td>

              <td>
                 <Select
                    :showDefault="false"
                    :showToggle="false"
                    :elements="tenantRoles"
                    keyAttr="roleName"
                    valueAttr="roleId"
                    :value="botRole.roleId"
                    @input="editBotRole(botRole, $event)"
                  ></Select>
              </td>
            </tr>
          </tbody>
          <div class="permission-text" v-else>
            <b>{{user.id}} </b>is an account administrator. Account administrators have all roles & permissions and don't need a specific role.</div>
        </table>

        <div class="permission-text" v-else>
          <b>{{user.id}}</b> doesn't have any roles assigned for any bots in your account. You can assign roles for this user account in the Bots tab.
        </div>

        <div class="fluid-container">
          <div class="admin-toggle">
            <Toggle
              :value="isTenantAdmin"
              label="Account Administrator"
              tooltip="Account Administrators have all rights and permissions for bots and users."
              @input="toggleTenantAdmin"
            ></Toggle>
          </div>
          <br />
          <br />
          <div style="display: flex; float: right;">
            <button
              class="btn btn--purple gradient"
              type="button"
              @click="saveChanges()"
            >
              <i class="icon icon-save"></i>Save changes
            </button>
          </div>
        </div>
      </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Toggle from '../../../helpers/Toggle.vue'
import Modal from '../../../helpers/Modal.vue'
import { BotViewModel } from '../models/bot.view.model'
import { RoleViewModel } from '../models/role.view.model'
import permissionsService from '../../../../services/tenants/permissions.service'
import { IUser } from '../../../../../../common/interfaces/tenant/user.interface'
import { isTenantAdmin, toggleTenantRole } from '../../../../../../common/helpers/tenant-role.helper'
import Select from '../../../helpers/Select.vue'
import { IUserBotRole } from '../../../../../../common/interfaces/tenant/user-bot-role'

@Component({ components: { Toggle, Modal, Select } })
export default class EditUser extends Vue {
  @Prop({ required: true }) readonly userData: IUser;
  @Prop({ default: [] }) readonly tenantRoles: RoleViewModel[];
  @Prop({ default: [] }) readonly tenantBots: BotViewModel[];

  user: IUser = null
  created () {
    this.user = JSON.parse(JSON.stringify(this.userData))
  }

  editBotRole (botRole: IUserBotRole, newRoleId: number) {
    botRole.roleId = newRoleId
    botRole.role = this.tenantRoles.find(r => r?.roleId == newRoleId)?.roleName
  }

  private returnBotNameById (botId: string) {
    return this.tenantBots.find(tb => tb.id == botId).displayName
  }

  get isTenantAdmin () {
    return isTenantAdmin(this.user, permissionsService.tenant, permissionsService.masterTenant)
  }

  private checkIfBotRolesContains (botRoles: any[], role: string) {
    return botRoles.some(br => br.role == role)
  }

  toggleTenantAdmin () {
    toggleTenantRole(this.user, permissionsService.tenant)
  }

  private saveChanges () {
    this.$emit('update-user', this.user)
  }
}
</script>

<style lang="scss" scoped>
@import "../configuration-page-style.scss";

.admin-toggle {
  display: flex;
  margin-left: 0px;

  .tooltip-icon {
    margin-left: 5px;
  }
}

.permission-text {
  font-size: 16px;
  margin-left: 0px;

  &.margin-bottom {
    margin-bottom: 20px;

  }
}

.botname {
  font-weight: bold;
  font-size: 15px;
}

td {
  text-align: left;
}

h4 {
  font-size: 18px;
  margin-bottom: 0px;
}

table {
  width: 100%;
}

span {
  font-size: 14px;
  color: $default-dark;
}

.fluid-container {
  margin-top: 20px;
}

.select {
  width: 100%;
}

.modal {
  min-height: 50vh;
  height: unset;
  .form--wrapper {
    padding-left: 20px;
  }

}

</style>
