<template>
  <div>
    <ConfigurationTable
      :type="'tenant'"
      :data="tenantsData"
      :title="'Manage tenants'"
      :description="'Manage the tenants here'"
      :isDeleting="isDeleting"
      @edit="$emit('edit', $event)"
      @delete="deleteValue"
    ></ConfigurationTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfigurationTable from '../../../helpers/ConfigurationTable.vue'

@Component({ components: { ConfigurationTable } })
export default class Tenants extends Vue {
  @Prop({ default: [] }) tenantsData: [];
  @Prop() isDeleting: boolean;
  @Prop() isDataLoading: boolean;

  deleteValue (eventArgs) {
    this.$emit('delete', eventArgs)
  }
}
</script>
