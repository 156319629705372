import { BlitsError } from "./blits.error";

export class SynchronizationError extends BlitsError {

    static CODE = "sync_error";

    constructor(message: string, public provider: string, internalMessage: string) {
        super(SynchronizationError.CODE, message, internalMessage);
    }

    toJSON(defaultMessage?: string) {
        return { ...super.toJSON(defaultMessage), internalMessage: this.internalMessage }
    }
}