import { Component, Vue } from 'vue-property-decorator'

import entitiesService, { EntitiesService } from '../../../../services/bots/entities.service'
import descriptionService from '../../../../services/bots/description.service'
import popupService from '../../../../services/popup.service'
import { saveAs } from 'file-saver'
import { CohesionError, ValidationError } from '../../../../../../common/errors'

@Component
export default class EntityManagementMixin extends Vue {
    name = ''
    currentTab = ''
    entities = []
    customEntities = []
    prebuildEntities = []
    activeEntity = 0
    entitiesService = undefined
    originalEntities: any
    bot: any
    luisKeys: any

    setEntities (data) {
      this.originalEntities = data.entities
      this.entities = Object.values(data.entities || {})
      this.customEntities = this.entities.filter(e => e.type !== 'prebuilts' && e.type !== 'customprebuiltentities')
      this.prebuildEntities = this.entities.filter(e => e.type === 'prebuilts' || e.type === 'customprebuiltentities')
      this.entitiesService = new EntitiesService()
    }

    getEntityValues (entity: any) {
      return entity.examples?.length
        ? entity.examples
        : entity.sublists?.length
          ? entity.sublists.map(sl => sl.canonicalForm)
          : entity.regexes?.length
            ? entity.regexes
            : []
    }

    validateEntity (entity) {
      if (entity.type == 'entities') {
        const nonEmptyExamples = entity.examples.filter(e => !!e && e.trim())
        if (nonEmptyExamples.length == 0) {
          popupService.showWarning('Please provide at least 1 valid example')
          return
        }
        if (nonEmptyExamples.length >= 5) {
          throw new ValidationError('Maximum Simple Entity examples limit is 5')
        }
        entity.examples = nonEmptyExamples
      } else if (entity.type == 'closedlists') {
        const nonEmptySublists = entity.sublists.filter(sl => !!sl.canonicalForm)
        if (nonEmptySublists.length == 0) {
          popupService.showWarning('Please provide at least 1 valid sublist')
          return
        }
        entity.sublists = nonEmptySublists
      } else if (entity.type == 'regexentities') {
        const nonEmptyRegexes = entity.regexes.filter(e => !!e && e.trim())
        if (nonEmptyRegexes.length == 0) {
          popupService.showWarning('Please provide at least 1 regex')
          return
        }
        try {
          nonEmptyRegexes.forEach(r => new RegExp(r))
        } catch (_) {
          popupService.showWarning('Invalid regex')
          return
        }
        entity.regexes = nonEmptyRegexes
      }

      // Unique values validation
      const values = this.getEntityValues(entity)
      for (const otherEntity of this.entities) {
        if (otherEntity.name === entity.name) {
          continue
        }
        const otherValues = this.getEntityValues(otherEntity)
        const repeating = values.find(v => otherValues.some(ov => v === ov))
        if (repeating) {
          return popupService.showError(`Value ${repeating} is already used in entity ${otherEntity.name}`)
        }
      }

      // Unique roles validation
      for (const role of (entity.roles || [])) {
        for (const otherEntity of this.entities) {
          if (otherEntity.name === entity.name) {
            continue
          }
          for (const otherRole of (otherEntity.roles || [])) {
            if (role.name === otherRole.name) {
              popupService.showError('The names of the roles must be unique across the application version.')
              return
            }
          }
        }
      }

      return true
    }

    async createEntity (entity) {
      if (this.validateEntity(entity)) {
        this.currentTab = 'entities'
        const newEntity = await this.entitiesService.createEntity(this.name, entity)
        this.customEntities.push(newEntity)
        this.entities.push(newEntity)
        this.originalEntities[newEntity.name] = newEntity
        this.bot.isPublished = false
        this.$router.currentRoute.params.element = entity.name
      }
    }

    async updateEntity (entity): Promise<boolean> {
      if (this.validateEntity(entity)) {
        await this.entitiesService.updateEntity(this.name, entity)
        this.bot.isPublished = false

        return true
      }

      return false
    }

    private findEntity (name: string) {
      const entity = this.entities.find(e => e.name == name)
      if (!entity) throw new CohesionError(`Could not find entity with name '${name}'`)
      return entity
    }

    async updateEntityResolution ({ name, value }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.resolveWithCanonical = value

      await this.updateEntity(entity)
    }

    async addEntityExample ({ name, example }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      if (!toUpdate.examples) toUpdate.examples = []
      toUpdate.examples.unshift(example)

      await this.updateEntity(toUpdate) ? entity.examples = toUpdate.examples : false
    }

    async updateEntityExample ({ name, index, example }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.examples[index] = example

      const tempExamples = entity.examples
      entity.examples = toUpdate.examples

      await this.updateEntity(toUpdate) ? false : entity.examples = tempExamples
    }

    async deleteEntityExample ({ name, index }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.examples.splice(index, 1)

      const tempExamples = entity.examples
      entity.examples = toUpdate.examples

      await this.updateEntity(toUpdate) ? false : entity.examples = tempExamples
    }

    async addEntitySublist ({ name, sublist }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      if (!toUpdate.sublists) toUpdate.sublists = []

      if (toUpdate.sublists.some(s => s.canonicalForm === sublist.canonicalForm)) {
        return popupService.showError(`Canonical form "${sublist.canonicalForm}" already exists`)
      }
      toUpdate.sublists.unshift(sublist)

      await this.updateEntity(toUpdate) ? entity.sublists = toUpdate.sublists : false
    }

    async updateEntitySublist ({ name, index, sublist }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))

      toUpdate.sublists[index] = sublist
      if (toUpdate.sublists.filter(s => s.canonicalForm === sublist.canonicalForm).length > 1) {
        return popupService.showError(`Canonical form "${sublist.canonicalForm}" already exists`)
      }

      const tempSubList = entity.sublists
      entity.sublists = toUpdate.sublists

      await this.updateEntity(toUpdate) ? false : entity.sublists = tempSubList
    }

    async deleteEntitySublist ({ name, index }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.sublists.splice(index, 1)

      const tempSubList = entity.sublists
      entity.sublists = toUpdate.sublists

      await this.updateEntity(toUpdate) ? false : entity.sublists = tempSubList
    }

    async addEntityRegex ({ name, regex }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      if (!toUpdate.regexes) toUpdate.regexes = []
      toUpdate.regexes.unshift(regex)

      await this.updateEntity(toUpdate) ? entity.regexes = toUpdate.regexes : false
    }

    async updateEntityRegex ({ name, index, regex }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.regexes[index] = regex

      const tempRegex = entity.regexes
      entity.regexes = toUpdate.regexes

      await this.updateEntity(toUpdate) ? false : entity.regexes = tempRegex
    }

    async deleteEntityRegex ({ name, index }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.regexes.splice(index, 1)

      const tempRegex = entity.regexes
      entity.regexes = toUpdate.regexes

      await this.updateEntity(toUpdate) ? false : entity.regexes = tempRegex
    }

    async addEntityRole ({ name, role }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      if (!toUpdate.roles) toUpdate.roles = []
      toUpdate.roles.unshift(role)

      await this.updateEntity(toUpdate) ? entity.roles = toUpdate.roles : false
    }

    async updateEntityRole ({ name, index, role }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.roles[index] = role

      await this.updateEntity(toUpdate) ? entity.roles = toUpdate.roles : false
    }

    async deleteEntityRole ({ name, index }) {
      const entity = this.findEntity(name)

      const toUpdate = JSON.parse(JSON.stringify(entity))
      toUpdate.roles.splice(index, 1)

      await this.updateEntity(toUpdate) ? entity.roles = toUpdate.roles : false
    }

    async exportEntity (entity: any) {
      try {
        const blob = await entitiesService.export(this.name, entity.name)
        saveAs(blob, `${this.name}-${entity.name}.enc`)
      } catch (err) {
        if (err.response && err.response.status === 403) {
          throw err
        }
        const response = err.response ? await err.response.data.text() : '{}'
        popupService.showError(JSON.parse(response).message || err.message)
      }
    }

    async importEntity (data) {
      try {
        const { file, entityName } = data

        const entity = await entitiesService.import(file, this.name, entityName)
        this.customEntities.push(entity)
        this.currentTab = 'entities'
      } catch (err) {
        if (err.response && err.response.status === 403) {
          throw err
        }
        const message = err.response ? err.response.data.message : err.message
        popupService.showError(message)
      }
    }

    async deleteEntity (entity) {
      try {
        if (this.prebuildEntities.includes(entity)) {
          popupService.showError('You cannot delete prebuilt entities!')
        } else {
          await this.entitiesService.deleteEntity(this.name, entity.name)
          this.activeEntity = this.customEntities.indexOf(entity) ? this.customEntities.indexOf(entity) - 1 : this.customEntities.indexOf(entity)
          this.customEntities = this.customEntities.filter(item => item !== entity)
          this.entities = this.entities.filter(item => item !== entity)
          delete this.originalEntities[entity.name]
          this.bot.isPublished = false
        }
      } catch (err) {
        if (err.response && err.response.status === 403) {
          throw err
        }
        popupService.showError(`Entity ${entity.name} is used in utterances/patterns`)
      }
    }

    async updateEntityDescription (data) {
      try {
        await descriptionService.updateEntity(this.name, data.entityName, data.description)
      } catch (error) {
        if (error.code == 'permission_error') { throw error }
        popupService.showError('Failed to save entity description!')
      }
    }

    async updateEntityName (data) {
      try {
        if (this.entities.some(e => e.name.trim() === data.newName.trim())) {
          popupService.showError('Entity with the same name already exists!')
          return
        }
        const entity = await this.entitiesService.editName(this.name, data)

        const customIndex = this.customEntities.findIndex(x => x.name === data.name)
        this.$set(this.customEntities, customIndex, entity.data)

        const allIndex = this.entities.findIndex(x => x.name === data.name)
        this.$set(this.entities, allIndex, entity.data)
        this.bot.isPublished = false
        popupService.showInfo('Entity name saved')
      } catch (error) {
        if (error.code == 'permission_error') { throw error }
        popupService.showError('Failed to save entity name!')
      }
    }

    private getValuesFromSublist (sublist: any) {
      let values = []
      sublist.sublists.forEach(e => {
        values = values.concat(e.list)
      })

      return values
    }
}
