<template>
  <div v-if="namesMap && provider" class="form-group">
    <label>Engine: {{ namesMap[provider] }}</label>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { entityExtractorNames } from '../../../../../../../../../common/constants/entity-extractor-names.constant'
import { languageDetectorNames } from '../../../../../../../../../common/constants/language-detector-names.constant'
import { languageTranslatorNames } from '../../../../../../../../../common/constants/language-translator-names.constant'
import { nluNames } from '../../../../../../../../../common/constants/nlu-names.constant'

@Component
export default class EngineProviderExtension extends Vue {
  @Prop({ default: () => '' }) readonly fc: string;
  @Prop({ default: () => ({}) }) readonly settings: any;

  get namesMap () {
    switch (this.fc) {
      case 'triggerRecognizer': return nluNames
      case 'multipleEntityCheck': return entityExtractorNames
      case 'languageDetection': return languageDetectorNames
      case 'languageTranslation': return languageTranslatorNames
      default: return null
    }
  }

  get provider () {
    switch (this.fc) {
      case 'triggerRecognizer': return this.settings.intentRecognizerProvider
      case 'multipleEntityCheck': return this.settings.entityExtractorProvider
      case 'languageDetection': return this.settings.languageDetectionProvider
      case 'languageTranslation': return this.settings.languageTranslationProvider
      default: return null
    }
  }
}
</script>
