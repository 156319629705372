import { PopupState } from '../models/popup.state'
import { EventDispatcher } from './EventDispatcher'

export class PopupService extends EventDispatcher {
  public async deletePopupEvent (item: string, callback: () => void) {
    if (await this.showDialog('Delete', `Are you sure you want to delete ${item}?`, 'Yes', 'Cancel', true, false, false, false)) {
      callback()
    }
  }

  showDialog (heading: string, description: string, actionBtnText = 'Continue', cancelBtnText = 'Cancel', hasCloseButton = true, disableClose = false, removeOnConfirm = false, isLarge = false) {
    const state = new PopupState('warning')
    state.heading = heading
    state.description = description
    state.actionBtnText = actionBtnText
    state.cancelBtnText = cancelBtnText
    state.hasCloseButton = hasCloseButton
    state.disableClose = disableClose
    state.removeOnConfirm = removeOnConfirm
    state.isLarge = isLarge

    this.dispatchEvent(new CustomEvent<PopupState>('popup', { detail: state }))

    return new Promise<boolean>((res, _) => {
      this.addEventListener('finish', (event: CustomEvent<{ id: number; isSubmitted: boolean }>) => {
        if (Number(event.detail.id) === state.id) {
          res(event.detail.isSubmitted)
        }
      })
    })
  }

  popupFinished (id: number, isSubmitted: boolean) {
    this.dispatchEvent(new CustomEvent<{ id: number; isSubmitted: boolean }>('finish', { detail: { id, isSubmitted } }))
  }

  showPopup (detail: PopupState, delay = 10) {
    setTimeout(() => this.dispatchEvent(new CustomEvent<PopupState>('popup', { detail })), delay)
  }

  showInfo (heading: string, description?: string, delay = 10) {
    const state = new PopupState('info')
    state.heading = heading
    state.description = description
    this.showPopup(state, delay)
  }

  showWarning (heading: string, description?: string, delay = 10) {
    const state = new PopupState('warning')
    state.heading = heading
    state.description = description
    this.showPopup(state, delay)
  }

  showError (heading: string, description?: string, delay = 10) {
    const state = new PopupState('error')
    state.heading = heading
    state.description = description
    this.showPopup(state, delay)
  }
}

export default new PopupService()
