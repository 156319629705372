<template>
  <div
    class="background"
    v-if="Object.keys(this.popupMap).length"
    @click.self="popupMap[eventId].disableClose ? undefined : close(eventId)"
  >
    <div class="popup" v-for="(state, key) in popupMap" :key="key">
      <div
        class="header"
        :class="state.type"
        :style="{ minHeight: state.isLarge ? '370px' : undefined }"
      >
        <div class="closepopup" v-if="state.hasCloseButton">
          <a class="close" href="#" @click.prevent="close(key)">&times;</a>
        </div>
        <div v-if="state.description">
          <h2>{{ headingText(state) }}</h2>
          <p v-html="descriptionText(state)"></p>
        </div>
        <div v-else>
          <h2>{{ headingText(state) }}</h2>
          <!-- <p>Lorum ipsum testing testing</p> -->
        </div>
      </div>
      <div class="footer">
        <button
          class="btn btn--white red narrow gradient"
          style="margin-left: 10px"
          v-if="state.cancelBtnText"
          @click="answer(key, false)"
        >
          {{ cancelBtnText(state) }}
        </button>
        <button
          class="btn btn--purple gradient narrow"
          v-if="actionBtnText(state)"
          @click="answer(key, true)"
        >
          {{ actionBtnText(state) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import popupService from "../../services/popup.service";
import { PopupState } from "../../models/popup.state";
import preventBodyScrollMixin from "../_mixins/prevent-body-scroll";

@Component
export default class Popup extends Mixins(preventBodyScrollMixin) {
  private popupMap: {
    [key: number]: PopupState;
  } = {};

  public eventId: number = undefined;

  mounted() {
    // Vue.config.errorHandler = error => popupService.showError(error.message);
    this.createEscapeEvent();
    popupService.addEventListener("popup", (event: CustomEvent<PopupState>) => {
      if (
        !Object.values(this.popupMap).find(
          (e) =>
            e.heading == event.detail.heading &&
            e.description == event.detail.description
        )
      ) {
        this.popupMap = { ...this.popupMap, [event.detail.id]: event.detail };
        this.toggleScroll(true);
        this.eventId = event.detail.id;

        if (this.popupMap[event.detail.id].disableClose) {
          this.removeEscapeEvent();
        }
      }
    });
  }

  answer(id: number, isSubmited: boolean) {
    popupService.popupFinished(id, isSubmited);
    const state = this.popupMap[this.eventId];
    if (state.removeOnConfirm || !state.disableClose) {
      this.close(id);
    }
  }

  close(key: number) {
    this.toggleScroll(false);
    this.$delete(this.popupMap, key);
  }

  cancelBtnText(state: PopupState) {
    return state.cancelBtnText;
  }

  actionBtnText(state: PopupState) {
    return state.actionBtnText;
  }

  headingText(state: PopupState) {
    if (state.heading) {
      return state.heading;
    }
    switch (state.type) {
      case "warning":
        return "Warning! ";
      case "error":
        return "Error! ";
    }
  }

  descriptionText(state: PopupState) {
    return state.description;
  }

  escapeEvent(evt) {
    if (evt.keyCode === 27) {
      this.close(Number(Object.keys(this.popupMap)[0]));
    }
  }

  createEscapeEvent() {
    document.addEventListener("keyup", this.escapeEvent);
  }
  removeEscapeEvent() {
    document.removeEventListener("keyup", this.escapeEvent);
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/setup/_mixins.scss";
$infoColor: $default-dark;
$warningColor: $red;
$errorColor: rgb(202, 0, 67);
.background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;

  .popup {
    @include center-absolute(400px, 150px, true);
    z-index: 9999;
    h2 {
      font-size: 20px;
      font-weight: 800;
      margin-top: 0px;
      color: $default-dark;
      margin-bottom: 0px;
      padding: 10px 13px;
      text-align: left;
    }
    .header {
      position: relative;
      color: rgb(255, 255, 255);
      border-radius: 10px 10px 0px 0px;
      padding: 22px;
      width: 100%;
      max-width: 450px;
      height: 100%;
      min-height: 200px;
      p {
        margin: 0;
        font-weight: 500;
        text-align: left;
        color: $grey;
        padding: 0px 13px 0px 13px;
        margin-top: 10px;
        font-size: 14px;
      }
      &.info {
        background-color: $white;
        z-index: 10;
      }
      &.warning {
        background-color: $white;
        z-index: 20;
      }
      &.error {
        background-color: $white;
        z-index: 30;
        h2 {
          color: $red;
        }
      }
    }
    .footer {
      position: absolute;
      background-color: white;
      width: 100%;
      max-width: 450px;
      height: 100%;
      max-height: 80px;
      padding: 20px 22px 20px 22px;
      border-radius: 0px 0px 10px 10px;

      button {
        float: right;
      }
    }
    .closepopup {
      position: relative;
      .close {
        float: right;
        font-size: 25px;
        font-weight: 400;
        text-decoration: none;
        color: $default-dark;
        margin-top: -10px;
        opacity: 1;
      }
      .content {
        max-height: 30%;
        overflow: auto;
        color: #fff;
      }
    }
  }
}
</style>
