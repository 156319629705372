<template>
  <div class="details-panel">
    <div class="details-panel--header">
      <h5>Details</h5>
      <i
        v-tippy="{
          placement: 'bottom',
          arrow: true,
          theme: 'light',
          maxWidth: '260px',
          delay: [1500, 0]
        }"
        title="Back to the bot"
        class="close-icon"
        @click="$emit('close')"
      ></i>
    </div>

    <div class="details-panel--widgets">
      <div v-if="!currentBlock" class="details-panel--default">
        <div class="select-element-icon">
          <i class="icon element-1"></i>
          <i class="icon element-2"></i>
          <i class="icon element-3"></i>
        </div>
        <h3>Select an element</h3>
        <p>If you select an element, you can edit it here.</p>
      </div>
      <div v-else :key="currentBlock.id">
        <TaskBlock
          v-if="currentBlock.type == 'bpmn:Task'"
          :customFunctions="customFunctions"
          :currentBlock="currentBlock"
          :bpmnManagement="bpmnManagement"
          :variables="variables"
          :variablesAndEntities="variablesAndEntities"
          :variablesAndEntitiesRoles="allVariableNames"
          :entitiesFromExamples="entitiesFromExamples"
          :entities="Object.values(container.entities)"
          :objects="objects"
          :channels="channels"
          :snippets="snippets"
          :containerName="container.botName"
          :settings="settings"
          :isWelcomeIntent="isWelcomeIntent"
          :intents="container.intents"
          @blockFieldChange="updateCurrentBlock()"
          @update-label="updateElementLabel"
        ></TaskBlock>

        <ConnectorBlock
          v-if="currentBlock.type == 'bpmn:SequenceFlow' && currentBlock.element.businessObject.sourceRef.$type == 'bpmn:ExclusiveGateway'"
          :currentBlock="currentBlock"
          :bpmnManagement="bpmnManagement"
          :allVariables="variablesAndEntities"
          :entities="Object.values(container.entities)"
          :botName="container.botName"
          :channels="channels"
          :snippets="snippets"
        ></ConnectorBlock>

        <ConditionalBlock
          v-if="currentBlock.type === 'bpmn:ExclusiveGateway'"
          :currentBlock="currentBlock"
          :bpmnManagement="bpmnManagement"
          :variables="variables"
          :variablesAndEntities="variablesAndEntities"
          :variablesAndEntitiesRoles="allVariableNames"
          :botName="container.botName"
          @update-label="updateElementLabel"
        ></ConditionalBlock>

        <EndBlock
          v-if="currentBlock.type == 'bpmn:EndEvent'"
          :currentBlock="currentBlock"
          :intents="container.intents"
          :variables="variables"
          :bots="bots"
          :botName="container.botName"
          :mode="mode"
          :outputs="outputs"
        ></EndBlock>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import EndBlock from './block-types/EndBlock.vue'
import ConditionalBlock from './block-types/ConditionalBlock.vue'
import TaskBlock from './block-types/task-block/TaskBlock.vue'
import ConnectorBlock from './block-types/connector-block/ConnectorBlock.vue'

import { BpmnManagementService } from '../modules/bpmn-management.service'
import FlowComponent from '../modules/FlowComponent'
import { IBlock } from '../../../../../../common/blocks/block.interface'
import { BotConnection } from '../../../../../../common/connections/bot-connection.model'

@Component({
  components: {
    EndBlock,
    ConditionalBlock,
    TaskBlock,
    ConnectorBlock
  }
})
export default class BlockConfiguration extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  })
  readonly currentBlock!: FlowComponent<IBlock>
  @Prop({
    default: () => {
      return {}
    }
  })
  readonly bpmnManagement!: BpmnManagementService
  @Prop({
    default: () => {
      return {}
    }
  })
  readonly currentItem!: any
  @Prop({
    default: () => {
      return {}
    }
  })
  readonly container!: any
  @Prop({ default: {} }) readonly objects: any
  @Prop({ default: () => [] }) readonly bots: any[]
  @Prop({ default: () => [] }) readonly functions: any[]
  @Prop({ default: () => false }) readonly isWelcomeIntent: boolean
  @Prop({ default: 'intents' }) readonly mode: 'intents' | 'functions'
  @Prop({ default: () => [] }) readonly channels: BotConnection[]
  @Prop({ default: () => [] }) readonly snippets: any[]
  @Prop({ default: () => ({}) }) readonly settings: any

  // Searches for change in the field-choices for
  @Watch('currentBlock.customData', { deep: true })
  private currentBlockCustomDataChange() {
    if (this.currentBlock != null) {
      this.currentBlock.updateRestrictions()
      this.$emit('updateRestrictions')
    }
  }

  @Watch('currentBlock.element.id')
  onCurrentBlockIdChanged() {
    this.updateCurrentBlock()
  }

  @Watch('currentBlock.customData.fc')
  onCurrentBlockFcChanged() {
    this.updateCurrentBlock()
  }

  @Emit('blockChanged')
  private updateCurrentBlock() {
    if (this.currentBlock) {
      return this.bpmnManagement.blockStorage.updateBlock(this.currentBlock)
    }
  }

  get examples() {
    return (this.currentItem.patterns || []).concat(this.currentItem.utterances || [])
  }

  get outputs() {
    return this.currentItem.outputs || []
  }

  // Returns entity:role pairs from examples
  get entitiesFromExamples() {
    if (!this.examples) return []

    const usedEntities = {}
    this.examples.forEach(e => {
      const regex = /(?:[^\/\\][\[]|^\[)(.*?)(?=\])/gm
      let m

      while ((m = regex.exec(e.text)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        usedEntities[m[1]] = true
      }
    })

    const pairs = Object.keys(usedEntities)
    // TODO: maybe include sorting function, to sort custom entities before prebuilts
    pairs.sort()

    return pairs
  }

  get usedEntities() {
    return this.entitiesFromExamples.map(p => this.container.entities[p.split(':')[0]]).filter(x => x)
  }

  // Variables { name: varName }
  get variables() {
    return this.bpmnManagement.variableService.getVariablesForElement(this.currentBlock)
  }

  // Variables + entity names { name: varName }
  get variablesAndEntities() {
    const vars = this.variables.concat(this.usedEntities.map(e => ({ name: e.name })))
    return vars.filter((v, i, a) => a.findIndex(t => t.name === v.name) === i)
  }

  // Variables + entities + entity:role pairs Array<string>
  get allVariableNames() {
    return [...new Set(this.variables.map(v => v.name).concat(this.entitiesFromExamples))]
  }

  get customFunctions() {
    return this.functions.map(f => ({
      key: f.name,
      functionName: f.name,
      group: f.group,
      fields: this.makeFields(f.inputs),
      variables: [f.type],
      type: f.type,
      outputs: f.outputs
    }))
  }

  makeFields(inputs) {
    // Prevent breaking old function models
    return inputs.map(e => ({
      name: typeof e === 'object' ? e.name : e,
      type: 'text',
      id: typeof e === 'object' ? e.name : e,
      rows: 1
    }))
  }

  updateElementLabel({ id, text }) {
    const elementRegistry = this.bpmnManagement.bpmn.get('elementRegistry')
    const modeling = this.bpmnManagement.bpmn.get('modeling')

    const shape = elementRegistry.get(id)
    modeling.updateProperties(shape, {
      name: text || this.currentBlock.functionSettings.functionName
    })
  }
}
</script>

<style lang="scss">
@import '../../../../assets/scss/variables';
$default-background: #e8e8e8;

.label {
  font-size: 14px;
  font-weight: 400;
  color: $dark-grey;
  line-height: 18px;
  margin: 0 0 8px;

  &.margin {
    margin-top: 15px;
  }
}

.details-panel {
  height: 100%;
  border-left: 1px solid $light-blue;

  hr {
    border: none;
    border-top: 1px solid $light-blue;
    background-color: transparent;
  }

  .details-panel--header {
    height: 50px;
    border-bottom: none;
    background-color: $white;
    margin-bottom: 0px;

    h5 {
      padding: 15px;
      color: $default-dark;
      font-weight: bold;
    }
  }

  .details-panel--default {
    position: relative;
    transform: translateY(50%);
    width: 100%;
    padding: 0 20px;
    text-align: center;

    h3 {
      font-size: 26px;
      font-weight: 700;
      color: black;
      line-height: 33px;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: black;
      line-height: 21px;
      margin-bottom: 0;
    }
  }
  .close-icon {
    position: absolute;
    display: block;
    top: 47px;
    right: 45px;
    background: url('/public/assets/icons/close-black.svg') no-repeat;
    background-size: 14px 14px;
    background-position: center;
    width: 14px;
    height: 14px;
    cursor: pointer;
    z-index: 2;
  }
  .details-panel--widgets {
    height: 100%;
    padding-top: 10px;
  }
}

.variables-panel {
  display: block;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  border: none;
  padding: 5px 15px;

  input[type='radio'] {
    margin-right: 4px;
  }
  .form-group {
    position: relative;
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
    &.form-group--options {
      label {
        margin-bottom: 0;
        vertical-align: top;
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 28px;
        &:before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 0;
          bottom: -1px;
          background-color: $white;
          border-radius: 6px;
          border: 1px solid $light-blue;
        }
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          position: absolute;
          left: 5px;
          top: 5px;
          background-color: $main;
          border-radius: 3px;
          display: none;
        }
      }
    }
    .margin-bot {
      font-size: 14px;
      color: $secondary-text;
    }
    .form-group--inside {
      position: relative;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: $secondary-text;
      line-height: 18px;
      margin: 0 0 8px;

      .tooltip-icon {
        position: relative;
        vertical-align: top;
        top: 2px;
        margin-left: 2px;
        width: 14px;
        height: 14px;
      }
    }
    .form-control {
      background-color: $white;
      &::placeholder {
        color: $grey;
      }
    }
    input[type='text'] input[type='number'] {
      background-color: green;
    }
    select {
      position: relative;
      text-align: left;
      border-radius: 6px;
      padding: 0 40px 0 20px;
      background-color: $white;
      color: $main;
      border: none;
      height: 43px;
      width: 100%;
      font-size: 14px;
      font-weight: 700;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:disabled {
        opacity: 0.5;
      }
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
      }
      &::-ms-expand {
        display: none;
      }
      + i {
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        color: $main;
        margin-top: 2px;
      }
    }
    input,
    textarea {
      padding: 0 20px;
      margin: 0px 0px 10px 0px;
      background: $white;
      color: $default-dark;
      font-size: 14px;
      min-height: 43px;
      border: 1px solid $light-blue;
      &:focus {
        border: 2px solid $main;
        outline: 0;
        box-shadow: none;
      }
    }
    input[type='checkbox'],
    input[type='radio'] {
      display: none;
      &:checked + label:after {
        display: block;
      }
    }
    input[type='checkbox'].active {
      display: inline !important;
      margin-right: 5px;
    }
    input[type='radio'] {
      + label:before,
      + label:after {
        border-radius: 50px;
      }
    }
    textarea {
      //resize: none;
      height: auto;
      max-height: 50vh;
      line-height: 22px;
      padding-top: 15px;
      padding-bottom: 15px;
      // @media screen and (min-height: 901px) {
      //   height: 47vh;
      // }

      &.textarea-with-highlighter {
        /* !!!!!!!! Please don't change or remove the background color !!!! It should be transparent */
        background-color: transparent !important;
      }
    }
  }
}
</style>
