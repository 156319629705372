import { BlockType } from '../../../../../../common/enums/block/block-type.enum'
import MessageBlockComponent from './blocks/dialog/blocks/MessageBlockComponent'
import UndefinedBlockComponent from './blocks/UndefinedBlockComponent'
import ActionBlockComponent from './blocks/action/ActionBlockComponent'
import DialogBlockComponent from './blocks/dialog/DialogBlockComponent'
import AskQuestionBlockComponent from './blocks/dialog/blocks/AskQuestionBlockComponent'
import DictionaryBlockComponent from './blocks/dialog/blocks/DictionaryBlockComponent'
import RandomAnswerBlockComponent from './blocks/dialog/blocks/RandomAnswerBlockComponent'
import ReceiveUserAttachmentBlockComponent from './blocks/dialog/blocks/ReceiveUserAttachmentBlockComponent'
import SendAttachmentBlockComponent from './blocks/dialog/blocks/SendAttachmentBlockComponent'
import SendGifBlockComponent from './blocks/dialog/blocks/SendGifBlockComponent'
import ShowOptionsBlockComponent from './blocks/dialog/blocks/ShowOptionsBlockComponent'
import MultipleEntityCheckComponent from './blocks/dialog/blocks/MultipleEntityCheckComponent'
import ShowRatingBlockComponent from './blocks/dialog/blocks/ShowRatingBlockComponent'
import UnexpectedAnswerBlockComponent from './blocks/dialog/blocks/UnexpectedAnswerBlockComponent'
import HandoverBlockComponent from './blocks/action/components/HandoverBlockComponent'
import RedirectCallBlockComponent from './blocks/action/components/RedirectCallBlockComponent'
import ReplaceInVariableBlockComponent from './blocks/action/components/ReplaceInVariableBlockComponent'
import SendConversationHistoryBlockComponent from './blocks/action/components/SendConversationHistoryBlockComponent'
import SendEmailBlockComponent from './blocks/action/components/SendEmailBlockComponent'
import SetObjectBlockComponent from './blocks/action/components/SetObjectBlockComponent'
import SetVariableBlockComponent from './blocks/action/components/SetVariableBlockComponent'
import SimpleCalculationBlockBlockComponents from './blocks/action/components/SimpleCalculationBlockComponent'
import LanguageTranslationBlockComponents from './blocks/action/components/LanguageTranslationBlockComponent'
import LanguageDetectionBlockComponents from './blocks/action/components/LanguageDetectionBlockComponent'
import TriggerRecognizerBlockComponent from './blocks/action/components/TriggerRecognizerBlockComponent'
import UserStatisticsBlockComponent from './blocks/action/components/UserStatisticsBlockComponent'
import FoodlBlockComponent from './blocks/foodl/FoodlBlockComponent'
import FunctionBlockComponent from './blocks/function/FunctionBlockComponent'
import SystemBlockComponent from './blocks/system/SystemBlockComponent'
import StartEventBlockComponent from './blocks/StartEventBlockComponent'
import EndEventBlockComponent from './blocks/EndEventBlockComponent'
import ConditionalComponent from './blocks/ConditionalComponent'
import FlowComponent from './FlowComponent'
import { IBlock } from '../../../../../../common/blocks/block.interface'
import UserAssetsSystemComponent from './blocks/system/components/UserAssetsSystemComponent'
import CreateTicketSystemComponent from './blocks/system/components/CreateTicketSystemComponent'
import EditTicketSystemComponent from './blocks/system/components/EditTicketSystemComponent'
import TicketSystemComponent from './blocks/system/components/TicketSystemComponent'
import UserTicketsSystemComponent from './blocks/system/components/UserTicketsSystemComponent'
import { IMessageBlock } from '../../../../../../common/blocks/dialog/message-block.interface'
import { IQuestionBlock } from '../../../../../../common/blocks/dialog/question-block.interface'
import { IDictionaryBlock } from '../../../../../../common/blocks/dialog/dictionary-block.interface'
import { IRandomAnswerBlock } from '../../../../../../common/blocks/dialog/random-answer-block.interface'
import { IReceiveUserAttachmentBlock } from '../../../../../../common/blocks/dialog/receive-user-attachment-block.interface'
import { ISendAttachmentBlock } from '../../../../../../common/blocks/dialog/send-attachment-block.interface'
import { IGifBlock } from '../../../../../../common/blocks/dialog/gif-block.interface'
import { IShowOptionsBlock } from '../../../../../../common/blocks/dialog/show-options-block.interface'
import { IRatingBlock } from '../../../../../../common/blocks/dialog/rating-block.interface'
import { IUnexpectedAnswerBlock } from '../../../../../../common/blocks/dialog/unexpected-answer-block.interface'
import { IRedirectCallBlock } from '../../../../../../common/blocks/action/redirect-call-block.interface'
import { IReplaceInVariableBlock } from '../../../../../../common/blocks/action/replace-in-variable-block.interface'
import { ISendConversationHistoryBlock } from '../../../../../../common/blocks/action/send-conversation-history-block.interface'
import { IGetTicketBlock } from '../../../../../../common/blocks/system/get-ticket-block.interface'
import { IGetAssetsOfUserBlock } from '../../../../../../common/blocks/system/get-assets-of-user-block.interface'
import { IEditTicketBlock } from '../../../../../../common/blocks/system/edit-ticket-block.interface'
import { ICreateTicketBlock } from '../../../../../../common/blocks/system/create-ticket-block.interface'
import PassCounterBlockComponent from './blocks/action/components/PassCounterBlockComponent'
import { IGetAssetDetailsBlock } from '../../../../../../common/blocks/system/get-asset-details-block.interface'
import { IStatisticsBlock } from '../../../../../../common/blocks/action/statistics-block.interface'
import { IRecognizerBlock } from '../../../../../../common/blocks/action/recognizer-block.interface'
import { ISendEmailBlock } from '../../../../../../common/blocks/action/send-email-block.interface'
import { ISetObjectBlock } from '../../../../../../common/blocks/action/set-object-block.interface'
import { ISetVariableBlock } from '../../../../../../common/blocks/action/set-variable-block.interface'
import { ISimpleCalculationBlock } from '../../../../../../common/blocks/action/simple-calculation-block.interface'
import { IFunctionExecutionBlock } from '../../../../../../common/blocks/action/function-execution-block.interface'
import { IBlockConnector } from '../../../../../../common/blocks/block-connector.interface'
import { Restrictions } from '../../../../../../common/blocks/restrictions'
import { BlockChannelRestrictions } from '../../../../../../common/types/restrictions.type'
import { RestrictionsTypes } from '../../../../../../common/enums/restrictions-types.enum'
import { ConfigurationError } from '../../../../../../common/errors'
import { IMultipleEntityCheck } from '../../../../../../common/blocks/dialog/multiple-entity-check-block.interface'
import { ILanguageTranslationBlock } from '../../../../../../common/blocks/action/language-translation-block.interface'
import { ILanguageDetectionBlock } from '../../../../../../common/blocks/action/language-detection-block.interface'
import ValidationBlockComponent from './blocks/action/components/ValidationBlockComponent'
import LLMBlockComponent from './blocks/llm/LLMBlockComponent'

export class FlowComponentService {
  private static restrictions: BlockChannelRestrictions = {}
  private blocks: { [blockId: string]: { fc: string } } = {}

  // Create block instance
  getBlockInstance(element: any, gfx: any, functionData: any = null, customData: any) {
    let block: FlowComponent<IBlock> = null

    switch (String(customData.fc)) {
      case BlockType.MessageBlock:
        block = new MessageBlockComponent<IMessageBlock>(element, gfx, functionData, customData)
        break

      case BlockType.AskQuestion:
        block = new AskQuestionBlockComponent<IQuestionBlock>(element, gfx, functionData, customData)
        break

      case BlockType.DictionaryBlock:
        block = new DictionaryBlockComponent<IDictionaryBlock>(element, gfx, functionData, customData)
        break

      case BlockType.RandomAnswer:
        block = new RandomAnswerBlockComponent<IRandomAnswerBlock>(element, gfx, functionData, customData)
        break

      case BlockType.ReceiveUserAttachmentBlock:
        block = new ReceiveUserAttachmentBlockComponent<IReceiveUserAttachmentBlock>(element, gfx, functionData, customData)
        break

      case BlockType.SendAttachment:
        block = new SendAttachmentBlockComponent<ISendAttachmentBlock>(element, gfx, functionData, customData)
        break

      case BlockType.SendGIF:
        block = new SendGifBlockComponent<IGifBlock>(element, gfx, functionData, customData)
        break

      case BlockType.ShowOptions:
        block = new ShowOptionsBlockComponent<IShowOptionsBlock>(element, gfx, functionData, customData)
        break

      case BlockType.MultipleEntityCheck:
        block = new MultipleEntityCheckComponent<IMultipleEntityCheck>(element, gfx, functionData, customData)
        break

      case BlockType.Rating:
        block = new ShowRatingBlockComponent<IRatingBlock>(element, gfx, functionData, customData)
        break

      case BlockType.UnexpectedAnswer:
        block = new UnexpectedAnswerBlockComponent<IUnexpectedAnswerBlock>(element, gfx, functionData, customData)
        break

      case BlockType.Handover:
        block = new HandoverBlockComponent<IBlock>(element, gfx, functionData, customData)
        break

      case BlockType.Counter:
        block = new PassCounterBlockComponent<IBlock>(element, gfx, functionData, customData)
        break

      case BlockType.RedirectCall:
        block = new RedirectCallBlockComponent<IRedirectCallBlock>(element, gfx, functionData, customData)
        break

      case BlockType.ReplaceInVariable:
        block = new ReplaceInVariableBlockComponent<IReplaceInVariableBlock>(element, gfx, functionData, customData)
        break

      case BlockType.SendConversationHistory:
        block = new SendConversationHistoryBlockComponent<ISendConversationHistoryBlock>(element, gfx, functionData, customData)
        break

      case BlockType.SendEmail:
        block = new SendEmailBlockComponent<ISendEmailBlock>(element, gfx, functionData, customData)
        break

      case BlockType.SetObject:
        block = new SetObjectBlockComponent<ISetObjectBlock>(element, gfx, functionData, customData)
        break

      case BlockType.SetVariable:
        block = new SetVariableBlockComponent<ISetVariableBlock>(element, gfx, functionData, customData)
        break

      case BlockType.SimpleCalculationBlock:
        block = new SimpleCalculationBlockBlockComponents<ISimpleCalculationBlock>(element, gfx, functionData, customData)
        break

      case BlockType.LanguageTranslation:
        block = new LanguageTranslationBlockComponents<ILanguageTranslationBlock>(element, gfx, functionData, customData)
        break
      case BlockType.LanguageDetection:
        block = new LanguageDetectionBlockComponents<ILanguageDetectionBlock>(element, gfx, functionData, customData)
        break

      case BlockType.ValidationBlock:
        block = new ValidationBlockComponent(element, gfx, functionData, customData)
        break

      case BlockType.RecognizeIntent:
        block = new TriggerRecognizerBlockComponent<IRecognizerBlock>(element, gfx, functionData, customData)
        break

      case BlockType.StatisticsBlock:
        block = new UserStatisticsBlockComponent<IStatisticsBlock>(element, gfx, functionData, customData)
        break

      case BlockType.GetAssetDetails:
        block = new UserAssetsSystemComponent<IGetAssetDetailsBlock>(element, gfx, functionData, customData)
        break

      case BlockType.CreateTicket:
        block = new CreateTicketSystemComponent<ICreateTicketBlock>(element, gfx, functionData, customData)
        break

      case BlockType.EditTicket:
        block = new EditTicketSystemComponent<IEditTicketBlock>(element, gfx, functionData, customData)
        break

      case BlockType.GetTicket:
        block = new TicketSystemComponent<IGetTicketBlock>(element, gfx, functionData, customData)
        break

      case BlockType.GetAssetsOfUser:
        block = new UserAssetsSystemComponent<IGetAssetsOfUserBlock>(element, gfx, functionData, customData)
        break

      case BlockType.GetTicketsOfUser:
        block = new UserTicketsSystemComponent<IGetTicketBlock>(element, gfx, functionData, customData)
        break

      case BlockType.LLM:
        block = new LLMBlockComponent<IBlock>(element, gfx, functionData, customData)
        break

      default:
        if (element && element.type) {
          switch (element.type) {
            case 'bpmn:StartEvent':
              block = new StartEventBlockComponent<IBlock>(element, gfx, functionData, customData)
              break
            case 'bpmn:EndEvent':
              block = new EndEventBlockComponent(element, gfx, functionData, customData)
              break
            case 'bpmn:ExclusiveGateway':
              block = new ConditionalComponent<IBlockConnector>(element, gfx, functionData, customData)
              break
            default:
              if (functionData && functionData.category) {
                const category = functionData.category
                switch (category) {
                  case 'Dialog':
                    block = new DialogBlockComponent<IBlock>(element, gfx, functionData, customData)
                    break

                  case 'Action':
                    block = new ActionBlockComponent<IBlock>(element, gfx, functionData, customData)
                    break

                  case 'Foodl':
                    block = new FoodlBlockComponent<IBlock>(element, gfx, functionData, customData)
                    break

                  case BlockType.LLM:
                    block = new LLMBlockComponent<IBlock>(element, gfx, functionData, customData)
                    break

                  case 'Function':

                  case 'Subs':
                    block = new FunctionBlockComponent<IFunctionExecutionBlock>(element, gfx, functionData, customData)
                    break

                  case 'System':
                    block = new SystemBlockComponent<IBlock>(element, gfx, functionData, customData)
                    break

                  default:
                    block = new UndefinedBlockComponent<IBlock>(element, gfx, functionData, customData)
                    break
                }
              } else {
                block = new UndefinedBlockComponent<IBlock>(element, gfx, functionData, customData)
                break
              }
          }
        } else {
          block = new UndefinedBlockComponent<IBlock>(element, gfx, functionData, customData)
          break
        }
    }
    return block
  }

  resetAllRestrictions() {
    FlowComponentService.restrictions = {}
  }

  updateRestrictions(blockId: string, customData: any) {
    const channelCapabilities = Restrictions.channelCapabilities
    try {
      for (const channel in channelCapabilities[customData.fc]) {
        channelCapabilities[customData.fc][channel].forEach(element => this.saveRestriction(blockId, channel, element.type, element.message, customData.fc))
      }

      this.saveBlockCred(blockId, customData.fc)
    } catch (e) {
      throw new ConfigurationError('Unable the update block restriction or capability!')
    }
  }

  updateFunctionsRestrictions(functionData: any) {
    const channelCapabilities = Restrictions.channelCapabilities
    try {
      for (const channel in channelCapabilities[functionData.type]) {
        channelCapabilities[functionData.type][channel].forEach(element =>
          this.saveRestriction(functionData.data.id, channel, element.type, element.message, functionData.data.customData.fc)
        )
      }

      this.saveBlockCred(functionData.data.id, functionData.data.customData.fc)
    } catch (e) {
      throw new ConfigurationError('Unable the update block restriction or capability!')
    }
  }

  private saveBlockCred(blockId: string, fc: string) {
    if (blockId != '' && fc != '') {
      this.blocks[String(blockId + '' + fc)] = { fc }
    }
  }

  private saveRestriction(blockId: string, channel: string, type: string, message: string, fc: string) {
    if (!this.getRestrictions[channel]) {
      this.getRestrictions[channel] = { warnings: [], errors: [] }
    }

    if (type == RestrictionsTypes.WARNING) {
      if (!this.getRestrictions[channel].warnings.some(w => w.value == message && w.fc == fc && w.id == blockId)) {
        FlowComponentService.restrictions[channel].warnings.push({ value: message, fc: fc, id: blockId })
      }
    } else if (type == RestrictionsTypes.ERROR) {
      if (!this.getRestrictions[channel].errors.some(e => e.value == message && e.fc == fc && e.id == blockId)) {
        FlowComponentService.restrictions[channel].errors.push({ value: message, fc: fc, id: blockId })
      }
    }
  }

  private ensureBlockExist(blockId: string, fc: string): boolean {
    const id = blockId + '' + fc
    delete this.blocks[id]
    let couter = 0

    for (const block in this.blocks) {
      if (this.blocks[block].fc === fc) {
        couter++
      }
    }
    return couter === 0
  }

  remotelyAddRestriction(blockId: string, fc: string, channel: string, type: string, message: string) {
    this.saveBlockCred(blockId, fc)
    this.saveRestriction(blockId, channel, type, message, fc)
  }

  remotelyDeleteRestriction(blockId: string, fc: string, channel: string, type: string, message: string) {
    try {
      if (FlowComponentService.restrictions[channel]) {
        if (type == RestrictionsTypes.WARNING) {
          FlowComponentService.restrictions[channel].warnings = FlowComponentService.restrictions[channel].warnings.filter(
            w => w.value != message && w.fc != fc && w.id != blockId
          )
        } else if (type == RestrictionsTypes.ERROR) {
          FlowComponentService.restrictions[channel].errors = FlowComponentService.restrictions[channel].errors.filter(
            e => e.value != message && e.fc != fc && e.id != blockId
          )
        }
      }
    } catch (e) {
      throw new ConfigurationError('Unable the remove block capability!')
    }
  }

  get getRestrictions() {
    return FlowComponentService.restrictions
  }

  public getRestrictionsArray() {
    const result: { [channel: string]: { warnings: string[]; errors: string[] } } = {}

    try {
      for (const channel in this.getRestrictions) {
        const values = this.getRestrictions[channel]
        if (!result[channel]) {
          result[channel] = { warnings: [], errors: [] }
        }

        result[channel].warnings = result[channel].warnings.concat(values.warnings.map(w => w.value))
        result[channel].errors = result[channel].errors.concat(values.errors.map(e => e.value))
      }
    } catch (e) {
      throw new ConfigurationError('Unable get the channel restrictions and capabilities!')
    }

    return result
  }

  deleteRestrictions(blockId: string, fc: string) {
    try {
      if (this.ensureBlockExist(blockId, fc)) {
        for (const channel in this.getRestrictions) {
          FlowComponentService.restrictions[channel].errors = FlowComponentService.restrictions[channel].errors.filter(e => e.fc != fc && e.id != blockId)
          FlowComponentService.restrictions[channel].warnings = FlowComponentService.restrictions[channel].warnings.filter(w => w.fc != fc && w.id != blockId)

          if (FlowComponentService.restrictions[channel].errors.length == 0 && FlowComponentService.restrictions[channel].warnings.length == 0) {
            delete FlowComponentService.restrictions[channel]
          }
        }
      }
    } catch (e) {
      throw new ConfigurationError('Unable the remove block restriction!')
    }
  }

  deleteAllOptionalFieldsCapabilities(blockId: string, fc: string) {
    try {
      for (const channel in this.getRestrictions) {
        FlowComponentService.restrictions[channel].errors = FlowComponentService.restrictions[channel].errors.filter(
          e => e.fc != fc && e.id != blockId && e.id.includes(blockId)
        )
        FlowComponentService.restrictions[channel].warnings = FlowComponentService.restrictions[channel].warnings.filter(
          w => w.fc != fc && w.id != blockId && w.id.includes(blockId)
        )

        if (FlowComponentService.restrictions[channel].errors.length == 0 && FlowComponentService.restrictions[channel].warnings.length == 0) {
          delete FlowComponentService.restrictions[channel]
        }
      }
    } catch (e) {
      throw new ConfigurationError('Unable the remove block restriction!')
    }
  }
}

export default new FlowComponentService()
