<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getTextWidth } from '../../helpers/text.helper'

@Component
export default class Autocomplete extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  items

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  isLoading

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  disabled

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  placeholder

  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  rows

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  value

  @Prop({
    type: String,
    required: false,
    default: '0 20px'
  })
  padding

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  opened

  @Prop({ default: false }) autoSizing: boolean

  private isOpen = false
  private results = []
  private search = ''
  private arrowCounter = 0
  // Does not match /[ ... ] or \[ ... ]
  private unclosedBracketRegex = /(?:^|[^\/\\])\[([\w\s-]*)($|\[)/
  get style() {
    return {
      padding: this.opened ? '0 40px 0 20px' : this.padding === '0 60px 0 20px' ? this.padding : '0 24px 0 20px',
      width: this.autoSizing ? 'auto' : undefined
    }
  }

  // JS hax to calculate characters width
  private font = ''
  get inputSize() {
    return getTextWidth(this.value, this.font) / 7 // Magic number 7 :-D
  }

  emitSelectionStartAndEnd(val) {
    this.$emit('selections', {
      start: val.target.selectionStart,
      end: val.target.selectionEnd
    })
  }

  onChange(val) {
    this.$emit('input', this.search)
    this.emitSelectionStartAndEnd(val)
    this.filterResults()
  }

  moveWithArrows(val) {
    if (val.key == 'ArrowLeft' || val.key == 'ArrowRight') {
      this.$emit('selections', {
        start: val.target.selectionStart,
        end: val.target.selectionEnd
      })
    }
  }

  filterResults() {
    // [Entity][index]
    // [Entity][property]
    // [click](link)
    // first uncapitalize all the things
    const match = this.unclosedBracketRegex.exec(this.search)
    this.isOpen = !!match

    if (this.isOpen) {
      const [, keyword] = match

      this.results = this.items.filter(item => item.toLowerCase().includes(keyword.toLowerCase()))
    }
  }

  setResult(result) {
    const match = this.unclosedBracketRegex.exec(this.search)
    let replacePattern = `[${result}]`

    const fullMatch = match[0]
    const ch = fullMatch[0]
    /**
     * Adds the character before [ (the opening bracket)
     *
     * fullMatch will either be "[..." or "<some_character(ch)>[..." when the index == 0
     * and fullMatch will be "<some_character(ch)>[..." when index > 0
     *
     * this means, when index == 0 && ch == '[' we have "[...", so we won't have a character before [
     * else, we have "<some_character>[..."
     *
     * and all of this work, because browsers don't support regex lookbehind :DDD
     */
    if (match.index == 0 && ch == '[') {
      // . . .
    } else {
      const ch = this.search[match.index]
      replacePattern = ch + replacePattern
    }

    this.search = this.search.replace(this.unclosedBracketRegex, replacePattern)
    this.isOpen = false
    this.$emit('input', this.search)
  }

  onArrowDown() {
    if (this.arrowCounter < this.results.length) {
      this.arrowCounter++
    }
  }

  onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter--
    }
  }

  onEnter() {
    if (this.isOpen) {
      this.setResult(this.results[this.arrowCounter])
      this.isOpen = false
      this.arrowCounter = -1
    } else {
      this.$emit('enter')
    }
  }

  handleClickOutside(evt) {
    if (!this.$el.contains(evt.target)) {
      this.isOpen = false
      this.arrowCounter = -1
    }
  }

  @Watch('items')
  newResultsValue(val, oldValue) {
    if (val.length !== oldValue.length) {
      this.results = val
    }
  }

  @Watch('value')
  newSearchValue(_) {
    this.search = this.value
  }

  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    this.search = this.value
    // JS hax to get input font style

    const element = this.$refs.input
    if (element) {
      this.font =
        window.getComputedStyle(this.$refs.input as HTMLElement, null).getPropertyValue('font-size') +
        ' ' +
        window.getComputedStyle(this.$refs.input as HTMLElement, null).getPropertyValue('font-family')
    }
  }

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<template>
  <div class="autocomplete">
    <input
      ref="input"
      @select="emitSelectionStartAndEnd"
      @click="emitSelectionStartAndEnd"
      @keyup="moveWithArrows"
      v-if="rows == 1"
      type="text"
      @input="onChange"
      v-model="search"
      class="form-control"
      :placeholder="placeholder"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      :style="style"
      :disabled="disabled"
      :size="inputSize"
    />
    <textarea
      v-else-if="rows > 1"
      type="text"
      :rows="rows"
      @input="onChange"
      v-model="search"
      class="form-control"
      :placeholder="placeholder"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      :disabled="disabled"
      @keydown.tab.prevent="$tabber($event, 'search')"
    ></textarea>
    <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results" :disabled="disabled">
      <li class="loading" v-if="isLoading">Loading results...</li>
      <li v-else-if="results.length == 0">No items found</li>
      <li v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">
        {{ result }}
      </li>
    </ul>
  </div>
</template>
