import { Component, Vue } from 'vue-property-decorator'
import { TwilioService } from '@/services/bots/settings/configuration/twilio.service'
import { TwilioTextToSpeech } from '../../../../../../../../common/enums/twilio/twilio-text-to-speech-language.enum'
import { TwilioSpeechToText } from '../../../../../../../../common/enums/twilio/twilio-speech-to-text-language.enum'
import { TwilioVoice } from '../../../../../../../../common/enums/twilio/twilio-voice.enum'
import { TwilioGatherType } from '../../../../../../../../common/enums/twilio/twilio-gather-type.enum'
import popupService from '@/services/popup.service'

@Component
export default class TwilioManagementMixin extends Vue {
  twilioService: TwilioService;
  keys: any;
  textToSpeechLanguages = Object.entries(TwilioTextToSpeech);
  speechToTextLanguages = Object.entries(TwilioSpeechToText);
  voices = Object.entries(TwilioVoice);
  gatherTypes = Object.entries(TwilioGatherType);

  initTwilio (botName: string) {
    this.twilioService = new TwilioService(botName)
    this.getTwilioKeys()
  }

  async getTwilioKeys () {
    this.keys = await this.twilioService.getKeys()
  }

  async updateTwilioKeys () {
    try {
      await this.twilioService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }

  async generateTwilioWebhook (type: string, overrideKeys: boolean) {
    const data = await this.twilioService.generateWebhook(type)
    if (overrideKeys) {
      this.keys = data
    } else {
      this.keys.webhookUrl = data.webhookUrl
      this.keys.messagesWebhookUrl = data.messagesWebhookUrl
    }
  }

  async deleteTwilioWebhook (type: string) {
    await this.twilioService.deleteWebhook(type)
    this.keys = {}
  }
}
