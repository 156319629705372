import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class PreventBodyScrollMixin extends Vue {
  // mounted() {
  //     document.querySelector('body').classList.toggle('no-scroll');
  // }

  toggleScroll (on: boolean) {
    on ? document.querySelector('body').classList.add('no-scroll') : document.querySelector('body').classList.remove('no-scroll')
  }

  // beforeDestroy() {
  //     document.querySelector('body').classList.toggle('no-scroll');
  // }
}
