import { ExternalProvider } from '../../enums/external-provider.enum';
import { MicrosoftTTSLanguage } from '../../enums/microsoft/microsoft-tts.language.enum';
import { GoogleTTSLanguage } from '../../enums/google/google-tts.language.enum';
import { IbmTTSLanguage } from '../../enums/ibm/ibm-tts-languages.enum';
import { AmazonTTSLanguage } from '../../enums/amazon/amazon-tts.language.enum';
import { TTSMap } from '../../types/engines/tts-map.type';
import {OpenAITTSLanguage} from "../../enums/openai/openai-tts.language.enum";

export const ttsLanguages: TTSMap<any> = {
    [ExternalProvider.Microsoft]: MicrosoftTTSLanguage,
    [ExternalProvider.IBM]: IbmTTSLanguage,
    [ExternalProvider.Google]: GoogleTTSLanguage,
    [ExternalProvider.Amazon]: AmazonTTSLanguage,
    [ExternalProvider.OpenAI]: OpenAITTSLanguage,
}