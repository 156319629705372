import { createDefaultEntry } from './create-action'

/** @description manages the left menu elements */

export default class CustomPalette {
  static $inject = ['create', 'elementFactory', 'palette']

  constructor(create, elementFactory, palette) {
    this._create = create
    this._elementFactory = elementFactory
    this._palette = palette

    palette.registerProvider(this)

    this._actions = {}
    this.setDefaultActions()
  }

  setDefaultActions() {
    const create = createDefaultEntry.bind(this)

    this._actions['custom-message'] = create('bpmn:Task', 'activity', 'icon icon-custom-message', 'Dialog')
    this._actions['custom-action'] = create('bpmn:Task', 'activity', 'icon icon-custom-action', 'Action')
    this._actions['create.exclusive-gateway'] = create('bpmn:ExclusiveGateway', 'activity', 'icon bpmn-icon-gateway-xor', 'Condition')
    this._actions['custom-function'] = create('bpmn:Task', 'activity', 'icon icon-custom-function', 'Function')
    this._actions['custom-llm'] = create('bpmn:Task', 'activity', 'icon icon-custom-llm', 'llm')
    this._actions['custom-sub'] = create('bpmn:Task', 'activity', 'icon icon-custom-block', 'Subs')
    this._actions['create.start-event'] = create('bpmn:StartEvent', 'activity', 'bpmn-icon-start-event-none', 'Start')
    this._actions['create.end-event'] = create('bpmn:EndEvent', 'activity', 'icon icon-custom-end', 'End')
  }

  refresh() {
    // Adds/Removes divs
    this._palette._update()

    // Remove .two-column from the palette (so it's padding doesn't change)
    document.querySelectorAll('.djs-palette').forEach(item => item.classList.remove('two-column'))

    // Add/Edit paragraphs (labels) after the divs
    document.querySelectorAll('.djs-palette-entries .entry').forEach(item => {
      if (item.nextSibling && item.nextSibling.tagName === 'P') {
        item.nextSibling.textContent = item.title
      } else {
        item.insertAdjacentHTML('afterend', `<p class="icon-text"> ${item.title} </p>`)
      }
    })
  }

  /** @description adds the action before the Start and End event actions */
  addAction(key, type, group, className, category, title, refresh = false) {
    const actionKeys = Object.keys(this._actions)
    actionKeys.splice(actionKeys.length - 2, 0, key)

    const actions = {}
    for (const key of actionKeys) {
      actions[key] = this._actions[key]
    }
    actions[key] = createDefaultEntry.call(this, type, group, className, category, title)

    this._actions = actions
    if (refresh) this.refresh()
  }

  removeAction(key, refresh = false) {
    delete this._actions[key]
    if (refresh) this.refresh()
  }

  // Don't delete
  getPaletteEntries() {
    return this._actions
  }
}
