<template>
  <div>
    <div class="container">
      <div class="container--content">
        <div class="container loading-div">
          <div class="container-small"></div>

          <div class="step-content">
            <h1>The Chat & Voicebot Business Case Calculator</h1>
            <h5>
              Find out now how much your department can save by implementing the
              Blits platform. Please answer the following questions about your
              organization. The provided information will remain anonymously and
              will not be saved.
            </h5>
            <hr />

            <div class="panel">
              <div class="subtitle">
                <h6>Input Metrics</h6>
              </div>

              <b-container fluid>
                <b-col>
                  <b-form-group
                    label="In which department are you considering to implement chatbots?"
                  >
                    <b-form-radio-group
                      id="radio-department"
                      name="flavour2"
                      v-model="department"
                    >
                      <b-form-radio
                        class="margin-right-15"
                        value="Customer Service"
                        >Customer Service</b-form-radio
                      >
                      <b-form-radio class="margin-right-15" value="IT Support"
                        >IT Support</b-form-radio
                      >
                      <b-form-radio class="margin-right-15" value="HR"
                        >HR</b-form-radio
                      >
                      <b-form-radio class="margin-right-15" value="Marketing"
                        >Marketing & Sales</b-form-radio
                      >
                      <b-form-radio class="margin-right-15" value="Finance"
                        >Finance</b-form-radio
                      >
                      <b-form-radio class="margin-right-15" value="Facilities"
                        >Facilities</b-form-radio
                      >
                      <b-form-radio
                        class="margin-right-15"
                        value="Risk & Regulatory"
                        >Risk & Regulatory</b-form-radio
                      >
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-container>

              <br />

              <b-container fluid>
                <b-col>
                  <label :for="fte"
                    >How many FTEs daily interact with clients?</label
                  >
                </b-col>
                <b-col class="w-50">
                  <b-form-input
                    :id="fte"
                    v-model="fte"
                    type="number"
                    placeholder="25"
                  />
                </b-col>
              </b-container>

              <br />

              <b-container fluid>
                <b-col>
                  <label :for="interactions"
                    >On average, how many daily interactions does one FTE
                    have?</label
                  >
                </b-col>
                <b-col class="w-50">
                  <b-form-input
                    :id="interactions"
                    v-model="interactions"
                    type="number"
                    placeholder="45"
                  />
                </b-col>
              </b-container>

              <br />

              <b-container fluid>
                <b-col>
                  <label :for="paygrade"
                    >What is the average annual salary of a single FTE?</label
                  >
                </b-col>
                <b-col class="w-50">
                  <b-form-input
                    :id="paygrade"
                    v-model="paygrade"
                    type="number"
                    placeholder="€36000"
                  />
                </b-col>
              </b-container>

              <br />

              <b-container fluid>
                <b-col>
                  <label :for="complexity"
                    >Please, indicate the average complexity of each
                    interaction</label
                  >
                </b-col>
                <b-col>
                  <button
                    v-for="option in options"
                    :key="option.text"
                    class="back-button complexity"
                    style="margin-right: 15px"
                    @click="complexity = option"
                    :class="{
                      'selected-button': complexity.text === option.text
                    }"
                  >
                    {{ option.text }}
                  </button>
                </b-col>
              </b-container>

              <br />

              <b-container fluid>
                <b-col>
                  <label :for="maturity"
                    >Please, indicate your department's maturity level regarding
                    chatbot technology</label
                  >
                </b-col>
                <b-col class="w-50">
                  <b-form-input
                    type="range"
                    id="range-1"
                    v-model="maturity"
                    min="0"
                    max="100"
                    step="20"
                  />
                  <div style="display: flex; justify-content: space-between">
                    <p>Low maturity</p>
                    <p>High maturity</p>
                  </div>
                  <div class="mt-2">
                    Maturity:
                    <b>{{ maturity }}</b>
                  </div>
                </b-col>
              </b-container>
            </div>
            <b-container fluid>
              <b-col>
                <button
                  v-if="!done"
                  class="back-button"
                  v-on:click="Calculate()"
                >
                  Calculate
                </button>
                <button
                  v-if="done"
                  class="back-button"
                  v-on:click="Calculate()"
                >
                  Recalculate
                </button>
              </b-col>
              <b-col>
                <span v-if="error" class="error"
                  >Please fill in all the fields</span
                >
              </b-col>
            </b-container>
            <br />
            <div v-if="done">
              <div class="box">
                <div class="subtitle">
                  <h6 class="center-2">Results</h6>
                </div>
                <div class="center">
                  Total employee costs: €
                  <b>{{ formatPrice(totalCosts, 0) }}</b>
                </div>
                <div class="center">
                  Interactions per year:
                  <b>{{ interactionsPerAnum }}</b>
                </div>
                <div class="center">
                  Costs per interaction: €
                  <b>{{ formatPrice(costPerInteraction, 2) }}</b>
                </div>
                <br />
                <div class="center">
                  <b
                    >Savings potential:
                    {{ parseFloat(savingsPotential).toFixed(1) }}%</b
                  >
                </div>
                <div class="center">
                  <b>Savings per year: €{{ formatPrice(savings, 0) }}</b>
                </div>

                <b-container fluid class="boxtext">
                  <b-row>
                    <b-col>
                      <h5
                        style="text-align: center; width: 70%; margin: 0 auto;"
                      >
                        <i
                          >Want to discuss how you can implement these savings
                          in your organisation? Get in touch with our team now
                          and find out how the Blits platform can support you
                          with this.</i
                        >
                      </h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col style="display: flex; justify-content: center">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.blits.ai/contact"
                      >
                        <button
                          v-if="done"
                          class="next-button back-button"
                          v-on:click="Calculate()"
                        >
                          Get in touch with us now!
                        </button>
                      </a>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <br />
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Loading from "../helpers/Loading.vue";

@Component({
  components: {
    Loading
  }
})
export default class Businesscase extends Vue {
  private count = 0;
  private done = false;
  private error = false;
  private department = "Customer Service";
  private useCasePotential = 1.0;
  private complexity = {
    value: 0.9,
    text: "Medium"
  };

  private paygrade: number;
  private maturity = 40;
  private apicosts = 0.1;
  private fte: number;
  private interactions: number;
  private implementationcosts = 0;
  private totalCosts = 0;
  private interactionsPerAnum = 0;
  private costPerInteraction = 0;
  private savingsPotential = 0;
  private savings = 0;
  private options = [
    {
      value: "1.0",
      text: "Low"
    },
    {
      value: "0.9",
      text: "Medium"
    },
    {
      value: "0.8",
      text: "High"
    },
    {
      value: "0.7",
      text: "Very High"
    }
  ];

  formatPrice(value, decimals) {
    const val = (value / 1).toFixed(decimals).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  Calculate() {
    this.error = false;

    // set value per usecase
    switch (this.department) {
      case "Customer Service":
        this.useCasePotential = 0.8;
        break;
      case "IT Support":
        this.useCasePotential = 0.8;
        break;
      case "HR":
        this.useCasePotential = 0.7;
        break;
      case "Marketing":
        this.useCasePotential = 0.7;
        break;
      case "Finance":
        this.useCasePotential = 0.5;
        break;
      case "Facilities":
        this.useCasePotential = 0.7;
        break;
      case "Risk & Regulatory":
        this.useCasePotential = 0.6;
        break;
      default:
        this.useCasePotential = 0.5;
        break;
    }

    // checking for all variables
    if (!this.paygrade) {
      this.error = true;
      return;
    }

    if (!this.fte) {
      this.error = true;
      return;
    }

    if (!this.interactions) {
      this.error = true;
      return;
    }

    if (!this.complexity) {
      this.error = true;
      return;
    }

    if (this.maturity === 0) {
      this.error = true;
      return;
    }

    if (!this.apicosts) {
      this.error = true;
      return;
    }

    // setting generic variables
    const businessDays = 260;
    const averageAPICall = 10;

    // calulating the business case
    this.totalCosts = this.fte * this.paygrade;
    this.interactionsPerAnum = this.interactions * this.fte * businessDays;
    this.costPerInteraction = this.totalCosts / this.interactionsPerAnum;
    this.savingsPotential =
      this.useCasePotential * this.complexity.value * this.maturity;
    const interactionSaved =
      this.interactionsPerAnum * (this.savingsPotential / 100);
    const totalCostsPerInteraction =
      this.apicosts * averageAPICall * (2 - this.complexity.value);
    this.savings =
      interactionSaved * this.costPerInteraction -
      totalCostsPerInteraction * interactionSaved;

    // finishing button
    this.done = true;
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #00338d !important;
}

.box {
  background-color: $disabled;
  height: auto;
  margin: 10px 0% 0% 0%;
  padding-top: 1px;
  padding-bottom: 20px;
}

.boxtext {
  margin-top: 25px;
}

.error {
  font-size: 14px;
  color: red;
  margin-top: 9px;
}

h1 {
  font-size: 2rem;
  color: #00338d;
  font-weight: 500;
  line-height: 75px;
  margin: 0 0 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

h2 {
  color: #333333;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 60px 0px 20px 0px;
}

.large-font {
  font-size: 20px;
}

h5 {
  color: #333333;
  font-size: 1rem;
  line-height: 1.5rem;
}

h6 {
  margin: 40px 0px 20px 00px;
  font-size: 2rem;
  color: #00338d;
}

.center {
  text-align: center;
  color: #333333;
}

.center-2 {
  text-align: center;
}

.disclaimer {
  color: #333333;
  font-size: 12px;
}

.tooltip-icon {
  position: relative;
  top: -3px;
  max-height: 24px;
}

.general {
  display: block !important;
}

.quest {
  margin: 20px 0px 20px 0px;
  font-size: 20px;
  color: #333333;
}

.step-content {
  position: relative;
  background-color: #fff;
  text-align: left;
  margin: 30px 0 0;
  padding: 70px 70px 50px 70px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  .btn.btn-close {
    position: absolute;
    top: 22px;
    right: 22px;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    i {
      position: relative;
      display: inline-block;
      top: 1px;
      background-image: url("/public/assets/icons/gray-close-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 14px;
      height: 14px;
    }
  }
}

.back-button {
  position: relative;
  margin-top: 40px;
  padding: 15px 40px 15px 40px;
  border: 1px solid #00338d;
  bottom: 0px;
  color: #00338d;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  background-color: white;
}
.complexity {
  margin-top: 0;
  cursor: pointer;
  padding: 15px 23px 15px;
  &:hover {
    color: white;
    background-color: #00338d;
  }
  &:focus {
    outline: 0;
  }
}

.selected-button {
  color: white;
  background-color: #00338d;
}

.next-button {
  background-color: #00338d;
  color: white;
}

.back-button-last {
  margin-left: 0;
  margin-top: 15px;
}

.questions {
  width: 100%;
  border-radius: 10px;
}

input.add {
  width: 200px;
  margin-left: 25px;
}

// input.sub {
//   margin-top: 15px;
//   float: right;
//   opacity: 0.5;
// }
.tippy-tooltip.advisor-theme {
  background-color: $default;
  border: 1px solid #f3f3f3;
  // box-shadow: 0 2px 20px 9px rgba(31,31,31,0.05);
  // font-size: 0.9rem;
  color: black;
  // line-height: 22px;
}

.container-small {
  width: calc(100% - 200px);
  margin: 0 auto;
}

.btn-primary {
  background-color: #43b02a;
  border-color: #43b02a;
  font-weight: 700;
  font-size: 1rem;
}
.form-group {
  position: relative;
  margin: 0;
  .alert-danger {
    border: 1px solid #bc204b;
    background: rgba(#bc204b, 0.2);
    font-size: 14px;
    font-weight: 400;
    color: #bc204b;
    line-height: 18px;
    position: absolute;
    bottom: -59px;
    left: 0;
    display: inline-block;
    margin: 0;
    padding: 12px 20px;
    border-radius: 6px;
  }
}

.step-3 {
  .form-control {
    margin-bottom: 15px;
  }
}

.form-control {
  max-width: 420px;
  background-color: $input;
  height: 50px;
  border: 1px solid $border;
  box-shadow: inset 0 1px 5px 0px rgba(0, 0, 0, 0.1);
  color: black;
  display: inline-block;
  padding: 0 20px;
  line-height: 50px;
  @media only screen and (min-width: 1200px) {
    max-width: 520px;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $button;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $button;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $button;
}

// .semi-width {
//   width: 60%;
//   float: left;
//   margin-right: 20px;
// }
.step-0 {
  input {
    margin-bottom: 20px;
  }
  button.btn {
    margin: 0;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 2rem !important;
    line-height: 1.5;
  }
  h6 {
    font-size: 2rem;
  }
  .quest {
    font-size: 16px;
  }
  .pretty {
    white-space: inherit;
  }
  .pretty.p-bigger label {
    text-indent: 1.7em;
    word-break: inherit;
    line-height: 17px;
  }
  .container {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
  }
  .step-content {
    padding: 20px 10px 20px 10px;
  }
}
</style>
