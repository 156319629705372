export const prebuildEntitiesExamples: {[entity: string]: string[]} = {
    'Age': ['I am 18', '45 years old', '45 old'],
    'Date': ['5 august 2020', '2019-08-05', 'August 5 2020'],
    'Dimension': ['20 centimetres', 'twenty centimetres', '1 cubic meter'],
    'Email': ['example@exaple.com', 'exampl2e@exaple.com', 'example3@example.com'],
    'Location': ['Amsterdam', 'Paris', 'Sofia'],
    'Currency': ['Euro' ,'Bulgarian lev', 'Brazilian real'],
    'Number': ['One thousand', 'Twenty', '1 / 1'],
    'Ordinal': ['first', 'second', 'third'],
    'Percentage': ['50%', '3 1/2', '75 1/3'],
    'PersonalName': ['John', 'Ivan', 'Pedro'],
    'PhoneNumber': ['1 (800) 642-7676', '1 (900) 622-4343'],
    'Temperature': ['30 degrees', '-10 degrees', '156 degrees fahrenheit'],
    'Url': ['https://blits.ai', 'https://example.com', 'https://google.com']
}