export enum CluAppLanguage {
    "English" = "en",
    "English (US)" = "en-us",
    "English (UK)" = "en-gb",
    "French" = "fr",
    "Italian" = "it",
    "Spanish" = "es",
    "German" = "de",
    "Portuguese (Brazil)" = "pt-br",
    "Portuguese (Portugal)" = "pt-pt",
    "Chinese (Simplified)" = "zh-hans",
    "Japanese" = "ja",
    "Korean" = "ko",
    "Dutch" = "nl",
    "Hindi" = "hi",
    "Turkish" = "tr",
    "Gujarati" = "gu",
    "Marathi" = "mr",
    "Tamil" = "ta",
    "Telugu" = "te",
    "Zulu" = "zu",
    "Afrikaans" = "af",
    "Amharic" = "am",
    "Arabic" = "ar",
    "Assamese" = "as",
    "Azerbaijani" = "az",
    "Belarusian" = "be",
    "Bulgarian" = "bg",
    "Bengali" = "bn",
    "Breton" = "br",
    "Bosnian" = "bs",
    "Catalan" = "ca",
    "Czech" = "cs",
    "Welsh" = "cy",
    "Danish" = "da",
    "Greek" = "el",
    "Esperanto" = "eo",
    "Estonian" = "et",
    "Basque" = "eu",
    "Persian" = "fa",
    "Finnish" = "fi",
    "Western Frisian" = "fy",
    "Irish" = "ga",
    "Scottish Gaelic" = "gd",
    "Galician" = "gl",
    "Hausa" = "ha",
    "Hebrew" = "he",
    "Croatian" = "hr",
    "Hungarian" = "hu",
    "Armenian" = "hy",
    "Indonesian" = "id",
    "Javanese" = "jv",
    "Georgian" = "ka",
    "Kazakh" = "kk",
    "Khmer" = "km",
    "Kannada" = "kn",
    "Kurdish (Kurmanji)" = "ku",
    "Kyrgyz" = "ky",
    "Latin" = "la",
    "Lao" = "lo",
    "Lithuanian" = "lt",
    "Latvian" = "lv",
    "Malagasy" = "mg",
    "Macedonian" = "mk",
    "Malayalam" = "ml",
    "Mongolian" = "mn",
    "Malay" = "ms",
    "Burmese" = "my",
    "Nepali" = "ne",
    "Norwegian (Bokmal)" = "nb",
    "Odia" = "or",
    "Punjabi" = "pa",
    "Polish" = "pl",
    "Pashto" = "ps",
    "Romanian" = "ro",
    "Russian" = "ru",
    "Sanskrit" = "sa",
    "Sindhi" = "sd",
    "Sinhala" = "si",
    "Slovak" = "sk",
    "Slovenian" = "sl",
    "Somali" = "so",
    "Albanian" = "sq",
    "Serbian" = "sr",
    "Sundanese" = "su",
    "Swedish" = "sv",
    "Swahili" = "sw",
    "Thai" = "th",
    "Filipino" = "tl",
    "Uyghur" = "ug",
    "Ukrainian" = "uk",
    "Urdu" = "ur",
    "Uzbek" = "uz",
    "Vietnamese" = "vi",
    "Xhosa" = "xh",
    "Yiddish" = "yi",
    "Chinese (Traditional)" = "zh-hant"
}
