<template>
  <div>
    <div class="form--wrapper settings">
      <div class="settings-title">
        <div class="title">{{ currentTab }}</div>
      </div>

      <div class="settings-nav">
        <AdminPannel :tabs="tabs" :bot-name="botName" :settings-tab="settingsTab" :channels="channels" @change="onTabChanged"></AdminPannel>
      </div>

      <form @submit.prevent="onFormSubmit" v-if="!isCustomTab && hasLoaded">
        <div class="settings-button settings-button-bots" v-if="hasSavePermission">
          <button class="btn btn--purple gradient" type="submit">
            <i class="icon icon-save"></i>
            Save settings
          </button>
        </div>
        <GeneralSettings
          v-if="currentTab == 'General Settings'"
          :settings="settings"
          :displayName="displayName"
          :botName="botName"
          :botAvatar="botAvatar"
          @update-avatar="updateAvatar"
          @update-display-name="updateDisplayName"
        ></GeneralSettings>

        <Configuration
          v-if="currentTab == 'Configuration' && isConfigurationLoaded"
          :botName="botName"
          :isTenantAdmin="isTenantAdmin"
          :language="settings.language"
          :sttProvider="sttProvider"
          :sttLanguageCode="settings.speechToTextLanguageCode"
          :ttsProvider="ttsProvider"
          :settings="settings"
          :engines="engines"
          :channels="channels"
          :systems="systems"
          :isRunningTask="isRunningTaskInConfiguration"
          :customEngineContracts="customEngineContracts"
          :tenantPlan="tenantPlan"
          @update="updateConfiguration, (isRunningTaskInConfiguration = false)"
          @save="saveSettings"
          @startTaskRunnig="isRunningTaskInConfiguration = true"
        ></Configuration>

        <CustomWidgets
          v-if="currentTab == 'Custom Widgets'"
          :widgetLists="widgetLists"
          :customWidgets="customWidgets"
          :variables="variables"
          @get-widgets="getWidgets"
        ></CustomWidgets>

        <Data
          v-if="currentTab == 'NLU Performance'"
          :isDeletionInProgress="isDeletionInProgress"
          :botName="botName"
          :settings="settings"
          :hasNluPerformance="hasNluPerformance"
          @export-bot="exportBot"
          @delete-bot="deleteBot"
          @run-nlp="
            onFormSubmit()
            runNlp()
          "
          @upload-nlp-dataset="uploadNlpDataset"
          @download-nlp-dataset="downloadNlpDataset"
          @download-nlp-results="downloadNlpResults"
          :isNlpTestRunning="isNlpTestRunning"
        ></Data>

        <GDPR
          v-if="currentTab == 'GDPR'"
          :gdprSettings="gdprSettings"
          :isTenantAdmin="isTenantAdmin"
          :botName="botName"
          @download-consent-csv="downloadConsentCsv"
        ></GDPR>

        <HandoverSettings v-if="currentTab == 'Handover'" :handoverSettings="handoverSettings" :botName="botName"></HandoverSettings>

        <MultiLanguageSettings v-if="currentTab == 'Multi-Language'" :settings="settings"></MultiLanguageSettings>
      </form>

      <template v-if="hasLoaded">
        <ChatBubble
          v-if="currentTab == 'Chat Bubble'"
          :snippets="snippets"
          :snippetUrl="snippetUrl"
          :snippetForm="snippetForm"
          :botName="botName"
          :toggleBranding="toggleBranding"
          @get-snippets="getSnippets"
          @create-snippet="createSnippet"
          @update-snippet="updateSnippet"
          @delete-snippet="deleteSnippet"
          @rename-snippet="renameSnipet"
        ></ChatBubble>

        <NluStatus v-if="currentTab === 'NLU Status'" :botName="botName" :tenantPlan="tenantPlan"></NluStatus>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import AdminPannel from './AdminPannel.vue'
import GeneralSettings from './tabs/GeneralSettings.vue'
import Configuration from './tabs/Configuration.vue'
import CustomWidgets from './tabs/CustomWidgets.vue'
import GDPR from './tabs/GDPR.vue'
import ChatBubble from './tabs/ChatBubble.vue'
import Data from './tabs/Data.vue'
import HandoverSettings from './tabs/HandoverSettings.vue'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import settingsManagementMixin from './_mixins/settings-management.mixin'
import permissionsService from '../../../../services/tenants/permissions.service'
import NluStatus from './tabs/NluStatus.vue'
import tenantService from '../../../../services/tenant.service'
import { TenantPlan } from '../../../../../../common/interfaces/plans/tenant-plan.interface'
import MultiLanguageSettings from '@/components/admin/bots/settings/tabs/MultiLanguageSettings.vue'
import { IBotSettings } from '../../../../../../common/interfaces/bot-settings.interface'

@Component({
  components: {
    MultiLanguageSettings,
    AdminPannel,
    GeneralSettings,
    Configuration,
    CustomWidgets,
    GDPR,
    ChatBubble,
    Data,
    HandoverSettings,
    NluStatus
  }
})
export default class Settings extends Mixins(settingsManagementMixin) {
  @Prop() readonly botName: string = ''
  @Prop() settingsTab: string
  @Prop() readonly toggleBranding: boolean
  @Prop() readonly hasNluPerformance: boolean
  @Prop() readonly customEngineContracts: boolean

  private hasSavePermission = false
  private isRunningTaskInConfiguration = false

  hasLoaded = false
  tenantPlan?: TenantPlan = null

  async beforeMount() {
    // eslint-disable-next-line no-console
    this.tenantPlan = await tenantService.getPlan()
    await this.initSettings(this.botName)
    await this.getData()
    await this.getSavePermission()
    this.hasLoaded = true
  }

  @Watch('currentTab')
  async getSavePermission() {
    switch (this.currentTab) {
      case 'General Settings':
        this.hasSavePermission = await permissionsService.hasPermission(this.botName, Permissions.ManageGeneralSettings)
        break
      case 'Custom Widgets':
        this.hasSavePermission = await permissionsService.hasPermission(this.botName, Permissions.ManageDashboardWidgets)
        break
      case 'NLU Performance':
        this.hasSavePermission = (
          await Promise.all([
            permissionsService.hasPermission(this.botName, Permissions.NlpPerformanceMeasuring),
            permissionsService.hasPermission(this.botName, Permissions.NlpPerformanceTesting)
          ])
        ).some((p) => p == true)
        break
      case 'GDPR':
        this.hasSavePermission = await permissionsService.hasPermission(this.botName, Permissions.ManageGDPRSettings)
        break
      case 'Handover':
        this.hasSavePermission = await permissionsService.hasPermission(this.botName, Permissions.ManageHandoverSettings)
        break
      case 'Configuration':
        this.hasSavePermission = await permissionsService.hasPermission(this.botName, Permissions.ManageEngines)
        break
      case 'Multi-Language':
        this.hasSavePermission = await permissionsService.hasPermission(this.botName, Permissions.ManageGeneralSettings)
        break
      default:
        return false
    }
  }

  async saveSettings(data: IBotSettings) {
    // eslint-disable-next-line no-console
    this.settings = { ...this.settings, ...data }
    // eslint-disable-next-line no-console
    await this.onFormSubmit()
  }
}
</script>
