import axios from 'axios'
import { BotCreateForm } from '../../../../common/interfaces/bot-create-form.interface'

export class BotsService {
  async exportBot (botName: string) {
    const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/export`,
            { responseType: 'blob' }
    )

    return response.data as Blob
  }

  validateImport (botFile: File) {
    const formData = new FormData()
    formData.append('bot-file', botFile, botFile.name)

    return axios
      .post(`${process.env.VUE_APP_BACKEND_URL}/bots/import/staging`, formData)
      .then(res => res.data)
  }

  async getAllBots () {
    return axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots`).then(res => res.data)
  }

  async importBot (botFile: File, botData: BotCreateForm) {
    const formData = new FormData()
    formData.append('bot-file', botFile, botFile.name)
    const res = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/bots/import`, formData, { params: botData })
    return res.data.url
  }

  async getBotNameAndId () {
    const result = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/id-name`).then(r => r.data)
    return result || []
  }

  async getBotsIds (tenantId: string | undefined) {
    const result = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/ids`, { params: { tenantId } }).then(r => r.data)
    return result || []
  }

  deleteBot (botName: string) {
    return axios.delete(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}`)
  }

  async getBotTemplates () {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/templates`).then(r => r.data)
  }

  async hideSnippetMigrationBanner (botName: string) {
    await axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/hide-snippet-migration-banner`)
  }
}

export default new BotsService()
