export enum IbmTTSLanguage {
    'Arabic (Beta)' = 'ar-AR',
    'Brazilian' = 'pt-BR',
    'Chinese (Mandarin, Beta)' = 'zh-CN',
    'Dutch (Beta)' = 'nl-NL',
    'English (United Kingdom)' = 'en-GB',
    'English  (United States)' = 'en-US',
    'French' = 'fr-FR',
    'German' = 'de-DE',
    'Italian' = 'it-IT',
    'Japanese' = 'ja-JP',
    'Korean (Beta)' = 'ko-KR',
    'Spanish (Castilian)' = 'es-ES',
    'Spanish (Latin American)' = 'es-LA',
    'Spanish (North American)' = 'es-US'
}