<template>
  <div class="top-bar">
    <div class="configuration-tabs main">
      <div class="configuration-tabs--inner">
        <button v-for="(tab, index) in tabs" :key="index" type="button" class="tab" :class="{ 'tab--active': currentTab == tab }" @click="$emit('input', tab)">
          {{ tab | capitalize }}
        </button>
      </div>
    </div>
    <div class="top-bar-button" v-if="!hideDialogButton">
      <button :disabled="disableDialogButton" type="button" class="btn btn--purple gradient" @click="$emit('show-dialog-manager')">
        <i class="icon icon-dialog-manager"></i>
        Dialog Manager
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { capitalize } from '../../../filters/string.filters'

@Component({
  filters: {
    capitalize: capitalize
  }
})
export default class NavTabs extends Vue {
  @Prop({ default: '' }) readonly value: string
  @Prop({ default: '' }) readonly currentTab: string
  @Prop({
    default: () => {
      return []
    }
  })
  readonly tabs: Array<string>
  @Prop({ default: true }) readonly disableDialogButton: boolean
  @Prop({ default: true }) readonly showDialogButton: boolean
  @Prop({ default: false }) readonly hideDialogButton: boolean
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';
</style>
