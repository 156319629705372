<template>
  <div>
    <div class="form-group">
      <label for="variableToModify">Variable to modify</label>
      <Select
        id="variableToModify"
        :elements="variables"
        :key="currentBlock.customData['field-replace-variable']"
        keyAttr="name"
        v-model="currentBlock.customData['field-replace-variable']"
        :disabled="!hasSufficientPermission"
      ></Select>
    </div>
    <div class="form-group">
      <label for="function">
        Replacing rules
        <Tooltip
          title="These are the proposed options which are shown to the user as a card."
          placement="bottom"
        ></Tooltip>
      </label>

      <div v-for="(v, index) in replaceRules" :key="index" style="position: relative;">
        <input
          v-model="currentBlock.customData['field-replace-rules'][index].find"
          type="text"
          :placeholder="'Find'"
          class="form-control"
          :disabled="!hasSufficientPermission"
        />
        <input
          v-model="currentBlock.customData['field-replace-rules'][index].replace"
          type="text"
          :placeholder="'Replace with'"
          class="form-control"
          :disabled="!hasSufficientPermission"
        />
        <i class="icon-delete" @click="removeReplacingRule(index)" v-show="hasSufficientPermission"></i>
      </div>
      <div class="option-button">
      <div class="btn btn--white dialogmanager" @click="addReplacingRule" v-if="hasSufficientPermission">Add new rule  <i class="icon icon-add"></i></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Select from '../../../../../../helpers/Select.vue'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import { IReplaceInVariableBlock } from '../../../../../../../../../common/blocks/action/replace-in-variable-block.interface'
import ReplaceInVariableBlockComponent
  from '../../../../modules/blocks/action/components/ReplaceInVariableBlockComponent'

@Component({
  components: {
    Select,
    Tooltip
  }
})
export default class ReplaceInVariableExtension extends Vue {
  private replaceRules: Array<null> = null;

  @Prop({ default: () => { return {} } })
  readonly currentBlock!: ReplaceInVariableBlockComponent<
    IReplaceInVariableBlock
  >;

  @Prop({ default: () => [] }) readonly variables!: any[];
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  created () {
    if (!this.currentBlock.customData['field-replace-rules']) {
      this.currentBlock.customData['field-replace-rules'] = []
    }
    this.replaceRules = new Array(
      this.currentBlock.customData['field-replace-rules'].length
    )
  }

  addReplacingRule () {
    this.replaceRules.push(null)
    this.currentBlock.customData['field-replace-rules'].push({
      find: null,
      replace: null
    })
  }

  removeReplacingRule (index) {
    this.replaceRules.splice(index, 1)
    this.currentBlock.customData['field-replace-rules'].splice(index, 1)
  }
}
</script>

<style lang="scss" scoped>
.icon-delete {
  position: absolute;
  top: 17px;
  right: 14px;
}
</style>
