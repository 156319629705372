<template>
  <div class="form-group form-group--options">
    <div class="label">Select channel</div>
    <Select
      :elements="channelOptions"
      :key="currentBlock.customData.channelId"
      keyAttr="name"
      valueAttr="value"
      v-model="currentBlock.customData.channelId"
      @input="updateChannelName($event); updateLabel()"
    ></Select>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Select from '../../../../../../helpers/Select.vue'
import FlowComponent from '../../../../modules/FlowComponent'
import { IWhichChannel } from '../../../../../../../../../common/blocks/connector/which-channel-block.interface'
import { BotConnection } from '../../../../../../../../../common/connections/bot-connection.model'

@Component({
  components: { Select }
})
export default class WhichChannel extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: FlowComponent<IWhichChannel>;
  @Prop({ default: () => [] }) readonly siblingConnectors!: FlowComponent<IWhichChannel>[];
  @Prop({ default: () => [] }) readonly channels!: BotConnection[];
  @Prop({ default: () => [] }) readonly snippets: any[];

  beforeMount () {
    if (!this.currentBlock.customData.channelId) {
      this.currentBlock.customData.channelId = this.currentBlock.customData.channelName
    }
  }

  mounted () {
    this.updateLabel()
  }

  get channelOptions () {
    const channels = this.channels.map(ch => ({ name: ch.name, value: ch.name }))
    const snippets = this.snippets.map(s => ({ name: s.name, value: s.code }))
    return channels.concat(snippets).filter(x => !this.siblingConnectors.some(y => x.value == y.customData.channelId))
  }

  @Emit()
  updateLabel () {
  }

  updateChannelName (value: string) {
    this.currentBlock.customData.channelName = this.channelOptions.find(ch => ch.value === value)?.name || value
  }
}
</script>
