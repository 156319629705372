<template>
  <div :class="!!condBlock ? undefined : 'variables-panel variables-panel-2'">
    <div
      v-if="!condBlock && hasDefault"
      style="margin-bottom: 15px"
      class="form-group form-group--options"
    >
      <input
        type="checkbox"
        id="mark-as-default-arrow"
        v-model="currentBlock.customData.isDefault"
        @change="updateLabel"
        :disabled="!hasSufficientPermission"
        style="margin-right: 5px"
      />
      <label for="mark-as-default-arrow">Mark as default (Catches all other cases)</label>
    </div>

    <div v-if="!isDefault && hasSufficientPermission">
      <Equals
        v-if="conditionalBlockOperator == 1"
        :currentBlock="currentBlock"
        :condBlock="condBlock"
        :blockStorage="bpmnManagement.blockStorage"
        :variableService="bpmnManagement.variableService"
        :variables="variables"
        @update-label="updateLabel"
      />

      <Exists
        v-if="conditionalBlockOperator == 2"
        :currentBlock="currentBlock"
        @update-label="updateLabel"
      />

      <MathComparator
        v-if="conditionalBlockOperator == 3"
        :currentBlock="currentBlock"
        :variables="variables.map(v => v.name)"
        @update-label="updateLabel"
      />

      <InSet
        v-if="conditionalBlockOperator == 4"
        :currentBlock="currentBlock"
        :variables="variables"
        @update-label="updateLabel"
      />

      <Contains
        v-if="conditionalBlockOperator == 5"
        :currentBlock="currentBlock"
        @update-label="updateLabel"
      />

      <IsOfType
        v-if="conditionalBlockOperator == 6"
        :currentBlock="currentBlock"
        @update-label="updateLabel"
      />

      <IsAnEntity
        v-if="conditionalBlockOperator == 7"
        :currentBlock="currentBlock"
        :entities="entities"
        @update-label="updateLabel"
      />

      <IsCanonical
        v-if="conditionalBlockOperator == 8"
        :currentBlock="currentBlock"
        :entities="entities"
        @update-label="updateLabel"
      />

      <IsSimilar
        v-if="conditionalBlockOperator == 9"
        :currentBlock="currentBlock"
        :variables="allVariables"
        @update-label="updateLabel"
      />

      <WhichChannel
        v-if="conditionalBlockOperator == 10"
        :currentBlock="currentBlock"
        :siblingConnectors="siblingConnectors"
        :channels="channels"
        :snippets="snippets"
        @update-label="updateLabel"
      />

      <CheckUser
        v-if="conditionalBlockOperator == 11"
        :currentBlock="currentBlock"
        @update-label="updateLabel"
      />

      <Regex
        v-if="conditionalBlockOperator == 12"
        :currentBlock="currentBlock"
        @update-label="updateLabel"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Select from '../../../../../helpers/Select.vue'

import { BpmnManagementService } from '../../../modules/bpmn-management.service'
import labelService from '../../../modules/label.service'

import Equals from './operations/Equals.vue'
import Exists from './operations/Exists.vue'
import MathComparator from './operations/MathComparator.vue'
import InSet from './operations/InSet.vue'
import Contains from './operations/Contains.vue'
import IsOfType from './operations/IsOfType.vue'
import IsAnEntity from './operations/IsAnEntity.vue'
import IsCanonical from './operations/IsCanonical.vue'
import IsSimilar from './operations/IsSimilar.vue'
import WhichChannel from './operations/WhichChannel.vue'
import CheckUser from './operations/CheckUser.vue'
import Regex from './operations/Regex.vue'
import { IConditionalBlock } from '../../../../../../../../common/blocks/conditional-block.interface'
import { IBlockConnector } from '../../../../../../../../common/blocks/block-connector.interface'
import ConditionalComponent from '../../../modules/blocks/ConditionalComponent'
import permissionsService from '../../../../../../services/tenants/permissions.service'
import { Permissions } from '../../../../../../../../common/enums/tenant/user-permissions.enum'
import { BotConnection } from '../../../../../../../../common/connections/bot-connection.model'
import FlowComponent from '../../../modules/FlowComponent'
import { availableOperations } from '../../../modules/operations.config'

@Component({
  components: {
    Select,

    Equals,
    Exists,
    MathComparator,
    InSet,
    Contains,
    IsOfType,
    IsAnEntity,
    IsCanonical,
    IsSimilar,
    WhichChannel,
    CheckUser,
    Regex
  }
})
export default class ConnectorBlock extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: FlowComponent<IBlockConnector>;
  // Passed only when connector is part of the validation block
  @Prop({ default: () => null }) readonly condBlock: ConditionalComponent<IConditionalBlock>;
  @Prop({ default: () => { return {} } }) readonly bpmnManagement!: BpmnManagementService;
  @Prop({ default: () => [] }) readonly allVariables!: any[];
  @Prop({ default: () => [] }) readonly entities!: any[];
  @Prop({ default: () => [] }) readonly botName!: string;
  @Prop({ default: () => [] }) readonly channels: BotConnection[];
  @Prop({ default: () => [] }) readonly snippets: any[];

  private hasSufficientPermission = false;

  get conditionalBlock (): ConditionalComponent<IConditionalBlock> {
    if (this.condBlock) return this.condBlock
    const element = this.currentBlock.element
    if (element.businessObject.sourceRef) {
      return this.bpmnManagement.blockStorage.findById(
        element.businessObject.sourceRef.id
      ) as ConditionalComponent<IConditionalBlock>
    } else return undefined
  }

  get conditionalBlockOperator (): number {
    return this.conditionalBlock
      ? this.conditionalBlock.customData.operation || 0
      : 0
  }

  get conditionalBlockCondition (): string {
    return this.conditionalBlock
      ? this.conditionalBlock.customData.condition
      : ''
  }

  get siblingConnectors (): FlowComponent<IBlockConnector>[] {
    if (this.condBlock) return []
    return this.conditionalBlock.element.outgoing
      .filter(e => e.id != this.currentBlock.id)
      .map(e => this.bpmnManagement.blockStorage.findById(e.id))
  }

  get variables (): any[] {
    return this.allVariables.filter(v => v.name != this.conditionalBlockCondition)
  }

  get hasDefault () {
    const operation = availableOperations.find(o => o.id === this.conditionalBlockOperator) || {} as any
    return operation.hasDefault === undefined ? true : operation.hasDefault
  }

  get isDefault (): boolean {
    return this.currentBlock.customData.isDefault
  }

  async mounted () {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageFlowsInDialogManager
    )
    this.updateLabel()
  }

  updateLabel () {
    if (!this.condBlock) {
      const text = labelService.generateLabelText(this.conditionalBlockOperator, this.currentBlock)
      labelService.updateArrowLabel(this.bpmnManagement.bpmn, this.currentBlock, text)
    }
  }
}
</script>
