export enum NeuralspaceLanguage {
    "Arabic (Mesopotamian)" = "acm",
    "Afrikaans" = "af",
    "Arabic (Gulf)" = "afb",
    "Arabic (Kuwaiti)" = "akw",
    "Arabic (Maghrebi)" = "ama",
    "Aragonese" = "an",
    "Arabic (Levantine)" = "apc",
    "Arabic (Sudanese)" = "apd",
    "Arabic" = "ar",
    "Arabic (Egyptian)" = "arz",
    "Assamese" = "as",
    "Azerbaijani" = "az",
    "Bashkir" = "ba",
    "Belarusian" = "be",
    "Bari" = "bfa",
    "Bulgarian" = "bg",
    "Bengali" = "bn",
    "Breton" = "br",
    "Bosnian" = "bs",
    "Catalan" = "ca",
    "Chechen" = "ce",
    "Czech" = "cs",
    "Chuvash" = "cv",
    "Welsh" = "cy",
    "Danish" = "da",
    "German" = "de",
    "Greek" = "el",
    "English" = "en",
    "Spanish" = "es",
    "Estonian" = "et",
    "Basque" = "eu",
    "Persian" = "fa",
    "Finnish" = "fi",
    "French" = "fr",
    "Irish" = "ga",
    "Galician" = "gl",
    "Gujarati" = "gu",
    "Hebrew" = "he",
    "Hindi" = "hi",
    "Croatian" = "hr",
    "Haitian" = "ht",
    "Hungarian" = "hu",
    "Armenian" = "hy",
    "Indonesian" = "id",
    "Ido" = "io",
    "Icelandic" = "is",
    "Italian" = "it",
    "Japanese" = "ja",
    "Javanese" = "jv",
    "Georgian" = "ka",
    "Kazakh" = "kk",
    "Kannada" = "kn",
    "Korean" = "ko",
    "Kirghiz" = "ky",
    "Latin" = "la",
    "Lahnda" = "lah",
    "Luxembourgish" = "lb",
    "Lithuanian" = "lt",
    "Latvian" = "lv",
    "Malagasy" = "mg",
    "Macedonian" = "mk",
    "Malayalam" = "ml",
    "Marathi" = "mr",
    "Malay" = "ms",
    "Maltese" = "mt",
    "Multilingual/Code-Mixed" = "multilingual",
    "Burmese" = "my",
    "Norwegian Bokmål" = "nb",
    "Nepali" = "ne",
    "Dutch" = "nl",
    "Occitan" = "oc",
    "Punjabi" = "pa",
    "Polish" = "pl",
    "Portuguese" = "pt",
    "Romanian" = "ro",
    "Russian" = "ru",
    "Slovak" = "sk",
    "Slovenian" = "sl",
    "Albanian" = "sq",
    "Serbian" = "sr",
    "Sundanese" = "su",
    "Swedish" = "sv",
    "Swahili" = "sw",
    "Tamil" = "ta",
    "Telugu" = "te",
    "Tajik" = "tg",
    "Tagalog" = "tl",
    "Turkish" = "tr",
    "Tatar" = "tt",
    "Uighur" = "ug",
    "Ukrainian" = "uk",
    "Urdu" = "ur",
    "Uzbek" = "uz",
    "Vietnamese" = "vi",
    "Volapük" = "vo",
    "Yoruba" = "yo",
    "Chinese" = "zh"
}