import { IBlock } from '../../../../../../../../../common/blocks/block.interface'
import DialogBlockComponent from '../DialogBlockComponent'
import flowComponentService from '../../../flow-component.service'
import { Restrictions } from '../../../../../../../../../common/blocks/restrictions'

export default class ShowOptionsBlockComponent<T extends IBlock> extends DialogBlockComponent<T> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions () {
    this.updateOptionalFieldsCapabilities('field-choices')
    this.updateSuggestionFieldCapabilities('field-suggestion')
    this.updateNumberOfOptions()
    flowComponentService.updateRestrictions(this.id, this.customData)
  }

  private updateNumberOfOptions () {
    const restrictions = Restrictions.channelResctrictions

    for (const channel of Object.keys(restrictions[this.customData.fc])) {
      for (const restriction of restrictions[this.customData.fc][channel]) {
        const choices: string[] = this.customData['field-choices'] ? this.customData['field-choices'] : []

        if (restriction.condition > choices.length) {
          flowComponentService.remotelyDeleteRestriction(this.id + '-options', this.customData.fc, channel, restriction.type, restriction.message)
        } else {
          flowComponentService.remotelyAddRestriction(this.id + '-options', this.customData.fc, channel, restriction.type, restriction.message)
        }
      }
    }
  }
}
