<template>
  <div class="form-group form-group--options">
    <input
      type="radio"
      name="x-exists-radio"
      v-model="currentBlock.customData.checkExistance"
      value="yes"
      id="x-exists-radio-yes"
      @change="updateLabel"
    />
    <label for="x-exists-radio-yes">Exists</label>
    <span style="margin: 0 6px"></span>

    <input
      type="radio"
      name="x-exists-radio"
      v-model="currentBlock.customData.checkExistance"
      value="no"
      id="x-exists-radio-no"
      @change="updateLabel"
    />
    <label for="x-exists-radio-no">Does Not Exist</label>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IExistsBlock } from '../../../../../../../../../common/blocks/connector/exists-block.interface'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component
export default class Exists extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<IExistsBlock>;

  @Emit()
  updateLabel () {
  }

  mounted () {
    this.updateLabel()
  }
}
</script>
