
<template>
  <div class="form-group image-dropdown">
    <label for="function">
      Question / Text
      <Tooltip
        title="This field gives the possibility to add title to the unexpected answer. Please keep it close to 'Does this answer your question?'. Because this is the default question for this block."
        placement="bottom"
      ></Tooltip>
    </label>

    <editor
      :items="variableNames"
      v-model="currentBlock.customData['field-suggestion']"
      :placeholder="'Question / Text'"
      @change="changeQuestion($event)"
      :disabled="!hasSufficientPermission"
    />
    <br />
    <label for="function">
      Answers. If you change the text the first one will be taken as possitive and the second as negative
      <Tooltip
        title="This fields gives the possibility to change the language of the answers Yes and No."
        placement="bottom"
      ></Tooltip>
    </label>
    <div v-for="(answer, i) in possibleAnswers" :key="answer">
      <autocomplete
        style="margin-bottom: 10px"
        :items="variableNames"
        v-model="answers[i]"
        :placeholder="getFieldPlaceholder(i)"
        :disabled="!hasSufficientPermission"
        :rows="1"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import editor from '../../../../Editor.vue'
import { IUnexpectedAnswerBlock } from '../../../../../../../../../common/blocks/dialog/unexpected-answer-block.interface'
import { UnexpectedAnswer } from '../../../../../../../../../common/enums/bot/unexpected-answer.enum'
import Autocomplete from '../../../../../../helpers/Autocomplete.vue'
import UnexpectedAnswerBlockComponent from '../../../../modules/blocks/dialog/blocks/UnexpectedAnswerBlockComponent'

@Component({ components: { Tooltip, editor, Autocomplete } })
export default class UnexpectedAnswerExtension extends Vue {
  @Prop({ default: () => { return {} } })
  readonly currentBlock!: UnexpectedAnswerBlockComponent<
    IUnexpectedAnswerBlock
  >;

  @Prop({ default: () => [] }) readonly variableNames!: any[];
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  private possibleAnswers: string[] = !this.currentBlock.customData[
    'field-choices'
  ]
    ? [UnexpectedAnswer.positive, UnexpectedAnswer.negative]
    : this.currentBlock.customData['field-choices'];

  private answers: string[] = Object.values(this.possibleAnswers);

  created () {
    !this.currentBlock.customData['field-suggestion']
      ? (this.currentBlock.customData['field-suggestion'] =
          'Does this answer your question?')
      : null
  }

  changeQuestion (text) {
    this.currentBlock.customData['field-suggestion'] = text.content
  }

  private getFieldPlaceholder (i: number) {
    return i == 0 ? 'Possitive answer' : 'Negative answer'
  }

  @Watch('answers')
  changeAnswers () {
    this.currentBlock.customData['field-choices'] = this.answers
  }
}
</script>
