<template>
  <div class="form-section">
    <h4>Bot Settings</h4>

    <FormGroup label="Bot Avatar">
      <div class="bot-avatar">
        <img :src="botAvatar"/>
        <input
          :accept="acceptedFiles"
          type="file"
          class="file-input"
          @change="$parent.$emit('update-avatar', $event.target.files[0])"
        />
      </div>
    </FormGroup>

    <FormGroup label="Bot's display name">
      <input
        type="text"
        :value="displayName"
        @change="$parent.$emit('update-display-name', $event.target.value)"
        :disabled="!hasSufficientPermission"
      />
    </FormGroup>

    <FormGroup
      label="Bot response delay"
      tooltip="This option defines the standard delay applied to the bot between multiple replies."
    >
      <select v-model="settings.delay" :disabled="!hasSufficientPermission" class="white-select">
        <option
          v-for="sec in seconds"
          :value="sec * 1000"
          id="sec"
          :key="sec"
          class="form-control"
        >{{ sec }}
        </option>
      </select>
      <i class="dropdown-toggle x"></i>
    </FormGroup>

    <FormGroup
      label="Message when the chat crashes"
      tooltip="This text will be sent when an unexpected error occurs during a conversation."
    >
      <textarea
        rows="3"
        type="text"
        placeholder="Some things to say"
        v-model="settings.unexpectedErrorMessage"
        :disabled="!hasSufficientPermission"
      />
    </FormGroup>

    <FormGroup label="Bot behaviour">
      <Toggle
        label="Continue previous conversation"
        tooltip="When the same user starts a new conversation, continue its previous conversation."
        v-model="settings.continueOldConversations"
      ></Toggle>
    </FormGroup>

  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import { Permissions } from '../../../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../../../services/tenants/permissions.service'
import { BCR_ALLOWED_AVATAR_EXTENSIONS } from '../../../../../../../../common/constants/file.constant'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import { CluAppLanguage } from '../../../../../../../../common/enums/microsoft/clu-app.language.enum'
import Dropdown from '@/components/helpers/Dropdown.vue'
import DropdownEditable from '@/components/helpers/DropdownEditable.vue'
import Replacer from '@/components/helpers/Replacer.vue'
import TextPill from '@/components/helpers/TextPill.vue'

@Component({
  computed: {
    CluAppLanguage () {
      return CluAppLanguage
    }
  },
  components: { TextPill, Replacer, DropdownEditable, Dropdown, Autocomplete, FormGroup, Toggle }
})
export default class GeneralBotSettings extends Vue {
  @Prop() readonly settings: any;
  @Prop() readonly displayName: string;
  @Prop() readonly botName: string;
  @Prop() readonly botAvatar: string;

  acceptedFiles = `.${BCR_ALLOWED_AVATAR_EXTENSIONS.join(',.')}`;

  private hasSufficientPermission = false;

  readonly seconds = [0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0];

  get permissions () {
    return Permissions
  }

  async beforeMount () {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      this.permissions.ManageGeneralSettings
    )
  }
}
</script>

<style lang="scss" scoped>
.bot-avatar {
  margin-left: 15px;

  img {
    max-width: 50px;
    max-height: 50px;
  }

  .file-input {
    padding: 0;
    margin-top: 15px;
    color: black;
    font-size: 11px;
  }
}
</style>
