<template>
  <div class="form-group">
    <ConditionalBlock
      :isInner="true"
      :currentBlock="currentBlock"
      :bpmnManagement="bpmnManagement"
      :variables="variables"
      :variablesAndEntities="variablesAndEntities"
      :variablesAndEntitiesRoles="variablesAndEntitiesRoles"
      :botName="botName"
      @update-operation="operationKey++"
    ></ConditionalBlock>
    <ConnectorBlock
      :key="operationKey"
      :currentBlock="currentBlock.connector"
      :condBlock="currentBlock"
      :bpmnManagement="bpmnManagement"
      :allVariables="variablesAndEntities"
      :entities="entities"
      :botName="botName"
      :channels="channels"
      :snippets="snippets"
    ></ConnectorBlock>
    <div class="form-group">
      <label for="on-fail-action">If validation fails</label>
      <Select
        :elements="onFailActions"
        keyAttr="1"
        valueAttr="0"
        v-model="currentBlock.customData.onFailAction"
        :key="currentBlock.customData.onFailAction"
      ></Select>
    </div>
    <div
      v-if="currentBlock.customData.onFailAction == ValidationBlockAction.SendMessage
        || currentBlock.customData.onFailAction == ValidationBlockAction.InputVariable"
      class="form-group"
    >
      <input
        class="form-control"
        placeholder="Message"
        v-model="currentBlock.customData.message"
      />
    </div>
    <div v-if="currentBlock.customData.onFailAction == ValidationBlockAction.RedirectFlow">
      <label for="new-flow">New flow</label>
      <Select
        :elements="intents"
        keyAttr="name"
        valueAttr="name"
        v-model="currentBlock.customData.newFlow"
        :key="currentBlock.customData.newFlow"
      ></Select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConditionalBlock from '../../ConditionalBlock.vue'
import ConnectorBlock from '../../connector-block/ConnectorBlock.vue'
import Select from '../../../../../../helpers/Select.vue'
import { BotConnection } from '../../../../../../../../../common/connections/bot-connection.model'
import ValidationBlockComponent from '../../../../modules/blocks/action/components/ValidationBlockComponent'
import { BpmnManagementService } from '../../../../modules/bpmn-management.service'
import { ValidationBlockAction } from '../../../../../../../../../common/enums/block/validation-block-action.enum'

@Component({
  components: {
    ConditionalBlock,
    ConnectorBlock,
    Select
  }
})
export default class RandomAnswerExtension extends Vue {
  @Prop({ default: () => ({}) }) readonly currentBlock!: ValidationBlockComponent;
  @Prop({ default: () => ({}) }) readonly bpmnManagement: BpmnManagementService;
  @Prop({ default: '' }) readonly botName: string;
  @Prop({ default: [] }) readonly entities: any[];
  @Prop({ default: () => [] }) readonly channels: BotConnection[];
  @Prop({ default: () => [] }) readonly snippets: any[];
  @Prop({ default: () => [] }) readonly variables!: any[];
  @Prop({ default: () => [] }) readonly variablesAndEntities!: any[];
  @Prop({ default: () => [] }) readonly variablesAndEntitiesRoles!: any[];
  @Prop({ default: () => [] }) readonly intents!: any[];

  operationKey = 0

  onFailActions = [
    [ValidationBlockAction.Continue, 'Continue the flow'],
    [ValidationBlockAction.RepeatPreviousBlock, 'Repeat previous block'],
    [ValidationBlockAction.SendMessage, 'Send message'],
    [ValidationBlockAction.InputVariable, 'Input variable'],
    [ValidationBlockAction.RestartFlow, 'Restart flow'],
    [ValidationBlockAction.RedirectFlow, 'Redirect to flow']
  ]

  ValidationBlockAction = ValidationBlockAction
}
</script>

<style lang="scss" scoped>

</style>
