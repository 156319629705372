<template>
  <div class="container">
    <div class="main-card">
      <div class="form-content">
        <div class="heading">
          <img src="/assets/img/Blits-logo-black.png" />
          <h4>Change your password</h4>
        </div>
        <form class="form" @submit.prevent="submit">
          <div class="login">
            <p class="description-text">Enter a new password</p>

            <div class="submit-container">
            <div class="input-container">
              <div class="icon-box"><i class="icon icon-password"></i></div>
              <input class="input-field" id="password" type="password" v-model="password" placeholder="Password">
              <i class="icon-eye" id="togglePassword" v-on:mouseup="toggleShowPassword()" v-on:mousedown="toggleShowPassword()"></i>
            </div>

            <div class="input-container">
              <div class="icon-box"><i class="icon icon-password"></i></div>
              <input class="input-field" id="repPassword" type="password" v-model="repPassword" placeholder="Re-enter Password">
              <i class="icon-eye" id="toggleRepPassword" v-on:mouseup="toggleShowPassword(true)" v-on:mousedown="toggleShowPassword(true)"></i>
            </div>

            <div class="button-group">
              <button v-if="!isInProgress" type="submit" class="btn btn--purple gradient narrow reset-password-button">Change password</button>
              <SmallLoading v-else class="loading"></SmallLoading><br>
            </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import authService from '../../services/auth.service'
import popupService from '../../services/popup.service'
import usersService from '../../services/users.service'
import SmallLoading from '../helpers/SmallLoading.vue'
import authManagmentMixin from './auth-managment.mixin'

@Component({ components: { SmallLoading } })
export default class PasswordReset extends Mixins(authManagmentMixin) {
  private password = ''
  private repPassword = ''
  private isInProgress = false
  private resetRequestId = ''

  async beforeMount () {
    this.routerCheck()
    this.resetRequestId = this.$route.params.id
    await this.checkResetLink()
  }

  async submit () {
    this.isInProgress = true
    if (this.password.trim().length < 8) {
      popupService.showError('', 'Password must at least be 8 characters long')
    } else if (this.password != this.repPassword) {
      popupService.showError('', 'Both passwords must match')
    } else {
      try {
        const success = await usersService.resetPassword(this.resetRequestId, this.password)
        if (success) {
          popupService.showDialog('Password changed', 'Your password have been changed successfully', null, 'Login', false)
            .then(() => this.moveBack())
        } else {
          popupService.showError('Error', 'Invalid reset password link!')
        }
      } catch (e) {
        popupService.showError('Error', 'Please check the passwords fields or try again later')
      }
    }
    this.isInProgress = false
  }

  async beforeDestroy () {
    if (await authService.isAuthenticated()) {
      this.removeBackground()
    }
  }

  async checkResetLink () {
    if (!await usersService.isPasswordRestLinkValid(this.resetRequestId)) {
      popupService.showDialog('Invalid reset link', 'This reset password link alredy expired or have been used', null, 'Leave page', false)
        .then(() => this.moveBack())
    }
  }

  private moveBack () {
    this.$router.push('/login')
  }

  toggleShowPassword (isRepPass = false) {
    const password = document.getElementById(isRepPass ? 'repPassword' : 'password')
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password'
    password.setAttribute('type', type)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./auth";

.heading {
  margin-bottom: 40px;
}

.container .main-card {
    min-height: 600px;
}

.form-content {
  background-color: transparent;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.submit-container {
    margin-bottom: -60px;
}

.description-text {
    padding-bottom: 20px;
    margin-top: -20px;
    font-weight: bold;
    font-size: 14px;
    color: $grey;
}

.loading {
    margin-left: 37% !important;
}
</style>
