<template>
  <Modal title="API Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <div class="api-settings">
        <label>API Endpoint</label>
        <input :value="backendUrl + '/api/external/'" name="Endpoint" type="text" class="code" readonly />
        <div type="”button”" class="copybutton" v-clipboard:copy="APIendpoint">COPY</div>

        <label v-if="externalApiConversationIdLifeTime" for="conversationIdLifeTime" class="label">Conversation Timeout (in minutes)</label>
        <input v-if="externalApiConversationIdLifeTime" v-model="conversationIdLifeTime" name="conversationIdLifeTime" type="text" />

        <label class="label">API Authentication</label>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">JSON Web Token</th>
              <th scope="col">Disabled</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="k in Object.keys(keys.externalApiKeys || {})" :key="keys.externalApiKeys[k].keyId">
              <th scope="row">
                <textarea v-model="keys.externalApiKeys[k].apiKey" readonly class="code big" />
                <div type="”button”" class="copybutton jwt" v-clipboard:copy="keys.externalApiKeys[k].apiKey">COPY</div>
              </th>

              <th scope="row">
                <div>
                  <Toggle class="toogle toogle-div" v-model="keys.externalApiKeys[k].isLocked"></Toggle>
                </div>
              </th>
              <th>
                <i class="icon-delete" @click="$emit('deleteExternalApiKey', keys.externalApiKeys[k].keyId)"></i>
              </th>
            </tr>
          </tbody>
        </table>
        <div class="btn btn--white" @click="$emit('generateNewExternalApiKey')">Generate new key<i class="icon icon-add"></i></div>
      </div>

      <div class="direct-line" v-if="toggleDirectLine">
        <div v-if="!keys.bcrDirectline && !keys.appId && !keys.appPassword && !keys.bcrEndpoint">You do not have permission to view the content</div>
        <label class="label small" v-if="keys.bcrDirectline" for="directline ID">Directline</label>
        <input v-if="keys.bcrDirectline" v-model="keys.bcrDirectline" name="directline" type="text" readonly class="code" />
        <div type="”button”" class="copybutton" v-clipboard:copy="keys.bcrDirectline">COPY</div>

        <label class="label small" v-if="keys.appId" for="endpoint">App id</label>
        <input v-if="keys.appId" v-model="keys.appId" name="endpoint" type="text" readonly class="code" />
        <div type="”button”" class="copybutton" v-clipboard:copy="keys.appId">COPY</div>

        <label class="label small" v-if="keys.appPassword" for="endpoint">App password</label>
        <input v-if="keys.appPassword" v-model="keys.appPassword" name="endpoint" type="text" readonly class="code" />
        <div type="”button”" class="copybutton" v-clipboard:copy="keys.appPassword">COPY</div>

        <!-- <label class="label small" v-if="keys.bcrEndpoint" for="endpoint">Messaging Endpoint</label>
        <input v-if="keys.bcrEndpoint" v-model="keys.bcrEndpoint" name="endpoint" type="text" readonly class="code"/>
        <div type=”button” class="copybutton" v-clipboard:copy="keys.bcrEndpoint">COPY</div> -->
      </div>

      <div class="toggle">
        <Toggle
          label="Show BCR endpoint"
          v-model="toggleDirectLine"
          :disabled="!keys.bcrDirectline && !keys.appId && !keys.appPassword && !keys.bcrEndpoint"
        ></Toggle>
      </div>

      <div>
        <button id="save-btn" class="btn btn--purple gradient" type="button" @click="prepareEditObject">
          <i class="icon icon-save"></i> Save API settings
        </button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'
import Select from '../../../../../helpers/Select.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import Modal from '../../../../../helpers/Modal.vue'

@Component({
  components: { FormGroup, Autocomplete, Select, Toggle, Modal }
})
export default class Api extends Vue {
  @Prop() backendUrl: string
  @Prop() keys: any
  @Prop() externalApiConversationIdLifeTime: number

  private conversationIdLifeTime: number = 0
  private toggleDirectLine = false

  created() {
    this.conversationIdLifeTime = this.externalApiConversationIdLifeTime
  }

  @Watch('externalApiConversationIdLifeTime')
  onMessageTimeLifeUpdate() {
    this.conversationIdLifeTime = this.externalApiConversationIdLifeTime
  }

  private prepareEditObject() {
    const editObject: { [keyId: string]: { isLocked: boolean } } = {}

    Object.keys(this.keys.externalApiKeys).forEach(
      k =>
        (editObject[this.keys.externalApiKeys[k].keyId] = {
          isLocked: this.keys.externalApiKeys[k].isLocked
        })
    )

    this.$emit('saveExternalApiKeysChages', {
      editObject: editObject,
      conversationIdLifeTime: this.conversationIdLifeTime * 60
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/variables';

.no-margin-top {
  margin-top: 0;
}

.direct-line {
  margin-top: 20px;
}

.toogle-div {
  margin-top: 50px;
  padding-left: 20px;
}

.toggle {
  margin-top: 30px;
}

table {
  color: $default-dark;

  th {
    border-bottom: 1px solid $light-blue;
    border-top: 0px;
    font-size: 14px;
  }
}

.label {
  margin-top: 20px;
  &.small {
    margin-top: 10px;
  }
}

.code {
  word-break: break-all;
  &.big {
    height: 120px;
    width: 450px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
  }
}

.icon-delete {
  margin-top: 55px;
}

#save-btn {
  display: block;
  margin-left: auto;
}
</style>
