import { Component, Vue } from 'vue-property-decorator'

import { VariablesService } from '../../../../services/bots/variables.service'
import popupService from '../../../../services/popup.service'

@Component
export default class VariableManagementMixin extends Vue {
  name = ''
  variables = {}
  variablesService = undefined
  objects = {}
  currentTab: any
  objectName: any
  objectProperties: any

  async setVariables () {
    this.variablesService = new VariablesService()
    const data = await this.variablesService.getAll(this.name)
    this.variables = data.variables || {}
    this.objects = data.objects || {}
  }

  async saveVariables (variables) {
    try {
      await this.variablesService.saveVariables(this.name, variables)
      await this.setVariables()
    } catch (error) {
      if (error.code === 'permission_error') {
        throw error
      }
      popupService.showError('Failed to create, delete or update variables!')
    }
  }

  async createObject (properties, name) {
    try {
      await this.variablesService.saveObject(this.name, properties, name)
      this.objects = Object.assign({ [name]: properties }, this.objects)
      this.currentTab = 'variables'
      await this.setVariables()
    } catch (error) {
      if (error.code === 'permission_error') {
        throw error
      }
      popupService.showError('Failed to create, delete or update object!')
    }
  }

  async deleteObject (key) {
    try {
      await this.variablesService.deleteObject(this.name, key, this.objects[key].isGlobal)
      this.$delete(this.objects[key], key)
      await this.setVariables()
    } catch (error) {
      if (error.code === 'permission_error') {
        throw error
      }
      popupService.showError('Failed to create, delete or update object!')
    }
  }

  async editVariable (key, value) {
    await this.variablesService.saveVariables(this.name, this.variables)
    this.variables[key] = value
    await this.setVariables()
  }

  editObject (key) {
    this.objectProperties = this.objects[key]
    this.objectName = key
    this.currentTab = 'create-object'
  }

  addObject (name: string, isGlobal: boolean) {
    this.objectName = name
    this.objectProperties = { variables: {}, isGlobal: isGlobal }
    this.currentTab = 'create-object'
  }
}
