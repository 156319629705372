<template>
  <div>
    <div class="container">
      <div class="container--content">
        <div class="container loading-div">
          <div class="container-small">
            <Loading v-if="!loaded"></Loading>
          </div>

          <div class="step-content">
            <h1>The Chat & Voicebot Business Readiness Checklist</h1>
            <h5>This short checklist will help you (within 2 minutes) to evaluate if you should consider implementing chatbots or virtual assistants. Please answer the following 20 questions about your company.</h5>
            <hr />
            <h5>The answers to this checklist are not stored or tracked by Blits.</h5>

            <div class="panel">
              <div class="subtitle">
                <h6>Digital Readiness</h6>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="1" v-model="checked1" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label class="question">1. Your company has a high adaptation of new technology</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="2" v-model="checked2" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >2. Your company has a modern IT architecture, that can support SaaS services</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="3" v-model="checked3" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >3. Your company has a strategy to automate a large part of processes</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="4" v-model="checked4" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >4. Your company has a data strategy to utilise user or client data</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="5" v-model="checked5" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >5. Your company has experience with robot processing automation (RPA)</label>
                </div>
              </div>
            </div>

            <div class="panel">
              <div class="subtitle">
                <h6>Client Interactions</h6>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="6" v-model="checked6" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >6. Your company has a high amount (digital) interaction with humans</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="7" v-model="checked7" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >7. Your company has a high amount client (support) questions</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="8" v-model="checked8" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >8. Your company is a business-to-consumer (B2C) organisation</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="9" v-model="checked9" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >9. Your company provides products or services in multiple counties</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="10" v-model="checked10" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >10. Your company has web-care via Facebook, Twitter and/or WhatsApp</label>
                </div>
              </div>
            </div>

            <div class="panel">
              <div class="subtitle">
                <h6>IT Support</h6>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="11" v-model="checked11" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label class="question">11. Your company has a high amount of employees (>1000)</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="12" v-model="checked12" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >12. Your company has a high amount of IT related support questions</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="13" v-model="checked13" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >13. Your company has more than 15 people working in IT Support</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="14" v-model="checked14" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label class="question">14. Your company has multi-language IT suppor</label>
                </div>
              </div>
            </div>

            <div class="panel">
              <div class="subtitle">
                <h6>Sales & Marketing</h6>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="15" v-model="checked15" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label class="question">15. Your company has a high amount of online traffic</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="16" v-model="checked16" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >16. Your company has a large online sales & marketing team (>25 people)</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="17" v-model="checked17" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >17. Your company has (multiple) branded website(s) in different languages for customers</label>
                </div>
              </div>
            </div>

            <div class="panel">
              <div class="subtitle">
                <h6>Human Resources (HR)</h6>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="18" v-model="checked18" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >18. Your company has employed (or are actively scouting for) a high percentage of people under 30</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="19" v-model="checked19" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label
                    class="question"
                  >19. Your company has a high amount of HR related support questions</label>
                </div>
              </div>

              <div class="pretty p-icon p-round p-smooth p-bigger quest">
                <input type="checkbox" id="20" v-model="checked20" />
                <div class="state p-success">
                  <i class="icon mdi mdi-check"></i>
                  <label class="question">20. Your company has multiple eLearnings for employees</label>
                </div>
              </div>
            </div>

            <button v-if="!done" class="back-button" v-on:click="resultsToCount()">Done</button>
            <br />
            <div v-if="done">
              <h2 class="center">
                All Done. You checked
                <b>{{count}}</b> boxes
              </h2>
              <br />

              <h5>
                Thank you for taking the time to answer this checklist. If you check
                <b>5</b> boxes or more it might be worth exploring how chatbots and virtual assistants can add value to your business. If you want to know more about how this technology
                can help your organisation, please contact us via
                <a
                  href="https://blits.ai"
                >this link</a>.
              </h5>
            </div>
            <br />
            <br />
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Loading from '../helpers/Loading.vue'

@Component({
  components: {
    Loading
  }
})
export default class Checklist extends Vue {
  private count = 0;
  private checked: [];
  private done = false;
  private checked1 = false;
  private checked2 = false;
  private checked3 = false;
  private checked4 = false;
  private checked5 = false;
  private checked6 = false;
  private checked7 = false;
  private checked8 = false;
  private checked9 = false;
  private checked10 = false;
  private checked11 = false;
  private checked12 = false;
  private checked13 = false;
  private checked14 = false;
  private checked15 = false;
  private checked16 = false;
  private checked17 = false;
  private checked18 = false;
  private checked19 = false;
  private checked20 = false;

  private loaded = false;

  mounted () {
    this.loaded = true
  }

  resultsToCount () {
    this.done = true
    this.count = [
      this.checked1,
      this.checked2,
      this.checked3,
      this.checked4,
      this.checked5,
      this.checked6,
      this.checked7,
      this.checked8,
      this.checked9,
      this.checked10,
      this.checked11,
      this.checked12,
      this.checked13,
      this.checked14,
      this.checked15,
      this.checked16,
      this.checked17,
      this.checked18,
      this.checked19,
      this.checked20
    ].filter(Boolean).length
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
@import "~pretty-checkbox/src/pretty-checkbox.scss";

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #00338d !important;
}

h1 {
  font-size: 2rem;
  color: #00338d;
  font-weight: 500;
  line-height: 75px;
  margin: 0 0 20px;
}

h2 {
  color: #333333;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 60px 0px 20px 0px;
}

h5 {
  color: #333333;
  font-size: 1rem;
  line-height: 1.5rem;
}

h6 {
  margin: 40px 0px 20px 00px;
  font-size: 2rem;
  color: #00338d;
}

.center {
  text-align: center;
  color: #333333;
}

.disclaimer {
  color: #333333;
  font-size: 12px;
}

.tooltip-icon {
  position: relative;
  top: -3px;
  max-height: 24px;
}

.general {
  display: block !important;
}

.quest {
  margin: 20px 0px 20px 0px;
  font-size: 20px;
  color: #333333;
}

.step-content {
  position: relative;
  background-color: #fff;
  text-align: left;
  margin: 30px 0 0;
  padding: 70px 70px 50px 70px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
}

.back-button {
  position: relative;
  margin-top: 40px;
  padding: 15px 40px 15px 40px;
  border: 1px solid #00338d;
  bottom: 0px;
  color: #00338d;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  background-color: white;
}

.back-button-last {
  margin-left: 0;
  margin-top: 15px;
}

.questions {
  width: 100%;
  border-radius: 10px;
}

input.add {
  width: 200px;
  margin-left: 25px;
}

// input.sub {
//   margin-top: 15px;
//   float: right;
//   opacity: 0.5;
// }
.tippy-tooltip.advisor-theme {
  background-color: $default;
  border: 1px solid #f3f3f3;
  // box-shadow: 0 2px 20px 9px rgba(31,31,31,0.05);
  // font-size: 0.9rem;
  color: black;
  // line-height: 22px;
}

.container-small {
  width: calc(100% - 200px);
  margin: 0 auto;
}

.btn-primary {
  background-color: #43b02a;
  border-color: #43b02a;
  font-weight: 700;
  font-size: 1rem;
}
.form-group {
  position: relative;
  margin: 0;
  .alert-danger {
    border: 1px solid #bc204b;
    background: rgba(#bc204b, 0.2);
    font-size: 14px;
    font-weight: 400;
    color: #bc204b;
    line-height: 18px;
    position: absolute;
    bottom: -59px;
    left: 0;
    display: inline-block;
    margin: 0;
    padding: 12px 20px;
    border-radius: 6px;
  }
}

.step-3 {
  .form-control {
    margin-bottom: 15px;
  }
}

.form-control {
  max-width: 420px;
  background-color: $input;
  height: 50px;
  border: 1px solid $border;
  box-shadow: inset 0 1px 5px 0px rgba(0, 0, 0, 0.1);
  color: black;
  display: inline-block;
  padding: 0 20px;
  line-height: 50px;
  @media only screen and (min-width: 1200px) {
    max-width: 520px;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $button;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $button;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $button;
}

// .semi-width {
//   width: 60%;
//   float: left;
//   margin-right: 20px;
// }
.step-0 {
  input {
    margin-bottom: 20px;
  }
  button.btn {
    margin: 0;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 2rem !important;
    line-height: 1.5;
  }

  h6 {
    font-size: 2rem;
  }

  .quest {
    font-size: 16px;
  }

  .pretty {
    white-space: inherit;
  }

  .pretty.p-bigger label {
    text-indent: 1.7em;
    word-break: inherit;
    line-height: 17px;
  }

  .container {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
  }
  .step-content {
    padding: 20px 10px 20px 10px;
  }
}
</style>
