<template>
  <ConfigurationContainer>
    <div class="col-12">
      <div class="contents__main">
        <div class="main-header" v-if="!deleteInProgress">
          <div class="main-header__inner">
            <h1>Account Settings</h1>
          </div>
          <p>Manage account settings for your environment here</p>
        </div>
        <SmallLoading v-if="isDataLoading"></SmallLoading>
        <!-- Loading indicator for delete account -->
        <Loading v-if="deleteInProgress" title="Deleting your account..."></Loading>
        <div v-if="!isDataLoading && !deleteInProgress">
          <div v-if="isTenantAdmin" class="form--wrapper">
            <div class="wrapper-inside general-settings active">
              <div class="settings-wrapper">
                <h3>Environment settings</h3>
                <div class="form-section">
                  <FormGroup label="Account display name">
                    <input type="text" v-model="displayName" />
                  </FormGroup>
                  <FormGroup label="Account primary email">
                    <input type="text" v-model="email" />
                  </FormGroup>
                </div>
              </div>

              <div class="settings-wrapper">
                <h3>Secret Key</h3>
                <div class="secret-key">
                  <button class="btn btn--purple gradient" @click="generateApiKey()">
                    Generate secret key
                  </button>
                  <p>{{ secretKey }}</p>
                </div>
              </div>

              <div v-if="!isMultiTenant">
                <h3>Billing Details</h3>
                <StripeCustomerFormSection :stripeCustomer="stripeCustomer"></StripeCustomerFormSection>
              </div>
            </div>
            <div class="settings-button">
              <button class="btn btn--purple gradient" type="submit" @click="submitChanges">
                <i class="icon icon-save"></i>
                Save settings
              </button>
            </div>
            <div class="delete-account-button" v-if="isMasterTenantAdmin">
              <button class="btn btn--white red" type="submit" @click="deleteAccount">
                <i class="icon icon-delete"></i>
                Delete account
              </button>
            </div>
          </div>
          <h4 v-else>
            You don't have the right permission for this action. Please contact your administrator.
          </h4>
        </div>
      </div>
    </div>
  </ConfigurationContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormGroup from '../../../helpers/FormGroup.vue'
import ConfigurationContainer from '../../../helpers/ConfigurationContainer.vue'
import SmallLoading from '../../../helpers/SmallLoading.vue'
import Loading from '../../../helpers/Loading.vue'
import { IStripeCustomerDto } from '../../../../../../common/interfaces/stripe-customer-dto.interface'
import popupService from '../../../../services/popup.service'
import tenantService from '../../../../services/tenant.service'
import Select from '../../../helpers/Select.vue'
import authService from '../../../../services/auth.service'
import StripeCustomerFormSection from '../components/StripeCustomerFormSection.vue'
import { countryNamesByCode } from '../../../../../../common/constants/countries.constant'
import { isTenantAdmin, isMasterTenantAdmin } from '../../../../../../common/helpers/tenant-role.helper'

@Component({
  name: 'General',
  components: {
    FormGroup,
    ConfigurationContainer,
    SmallLoading,
    Loading,
    Select,
    StripeCustomerFormSection
  }
})
export default class General extends Vue {
  @Prop() tenantDisplayName: string
  @Prop() tenantEmail: string
  @Prop() isTenantAdmin: boolean
  @Prop() isMasterTenantAdmin: boolean
  @Prop() isMultiTenant: boolean
  @Prop() isDataLoading: boolean
  @Prop() stripeCustomer: IStripeCustomerDto
  @Prop() secretKey: string

  private email: string = ''
  private displayName: string = ''

  private deleteInProgress = false

  @Watch('tenantDisplayName')
  changeName() {
    this.displayName = this.tenantDisplayName
  }

  mounted() {
    this.email = this.tenantEmail
    this.displayName = this.tenantDisplayName
  }

  @Watch('tenantEmail')
  changeEmail() {
    this.email = this.tenantEmail
  }

  submitChanges() {
    const data = {
      tenantSettings: {
        displayName: this.displayName,
        email: this.email
      },
      stripeCustomer: {
        ...this.stripeCustomer,
        country: Object.keys(countryNamesByCode).find(key => countryNamesByCode[key] === this.stripeCustomer.country),
        taxIdType: this.stripeCustomer.taxIdType?.split(' ')[0]
      }
    }

    this.$emit('submit', data)
  }

  async deleteAccount() {
    const answer = await popupService.showDialog('Delete your account', 'Are you sure you want to delete your account?', 'Cancel', 'Delete', false)
    if (!answer) {
      this.toggleDisableScreen(true)
      await tenantService
        .delete(undefined)
        .then(async res => {
          if (res.status == 200) {
            await authService.logout()
          }
          this.toggleDisableScreen(false)
          this.$router.replace('/')
        })
        .catch(() => {
          this.toggleDisableScreen(false)
          popupService.showError('Error', 'There was a problem while deleting your account!')
        })
    }
  }

  private toggleDisableScreen(toggle: boolean) {
    document.body.style.pointerEvents = toggle ? 'none' : 'auto'
    document.body.style.overflow = toggle ? 'hidden' : 'visible'
    this.deleteInProgress = toggle
  }

  async generateApiKey() {
    const key = await tenantService.generateSecretKey()
    this.secretKey = key
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';
th,
td {
  padding: 0 20px 10px 0;
  font-size: 15px;
  color: $default-dark;
  text-align: left;
}

h4 {
  margin-top: 40px;
}

th {
  padding: 0 100px 10px 0;
}

.delete-account-button {
  padding-top: 20px;
}

.secret-key {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;
  p {
    margin-bottom: 0px;
  }
}
</style>
