<template>
  <Modal title="Dialogflow Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form v-if="keys.projectId" @submit.prevent="$emit('delete-project')">
        <label for="button">GCP is configured for this bot</label>
        <div class="bottom-right-fix">
          <button
            v-if="customEngineContracts"
            class="btn btn--white red"
            type="submit"
          >Delete GCP</button>
        </div>
      </form>

      <form v-else>
        <label for="button">We will create a GCP for this bot. If you want to configure Dialogflow yourself, you will have to accept the Google APIs term of service <a for="googleTerms" rel="noopener noreferrer" href="https://console.developers.google.com/terms/universal" target="_blank">here</a></label>

        <div class="bottom-right-fix">
          <button
            class="btn btn--white"
            type="button"
            @click="$emit('create-project')"
          >Let us configure Dialogflow
          </button>

          <button
            v-if="customEngineContracts"
            class="btn btn--white"
            type="button"
            @click="$emit('create-project', true)"
          >Configure Dialogflow yourself
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '../../../../../helpers/Modal.vue'

@Component({
  components: { Modal }
})
export default class Dialogflow extends Vue {
  @Prop() keys: any;
  @Prop() readonly customEngineContracts: boolean;
}
</script>
