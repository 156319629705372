<template>
  <section id="chat-snippets">

    <div id="existing-snippets" v-if="snippets && snippets.length">
      <form
        v-for="(snippet, index) in snippets"
        :key="snippet.name + snippet.version"
        @submit.prevent="$emit('update-snippet', snippet)"
      >
        <div class="container-snippet">
                  <ComponentName
                  :value="snippet.name"
                  :name="snippet.name"
                  :key="snippet.name"
                  :botName="botName"
                  @input="(data)=>{$emit('rename-snippet', {...snippet, newName: data})}"
                  ></ComponentName>

              <div class="description-header">Description: </div>
              <div class="description-text" v-if="snippet.description"> {{snippet.description}}</div>
              <div class="description-text" v-else>This bubble has no description</div>
          <div class="row">
            <div class="col-6">
              <div class="description-header avatar">Bot Avatar </div>
              <div class="content_info">
                  <img
                  v-bind:src="snippet.settings.avatar || 'default'"
                  alt="avatar.png"
                />
              </div>

              <div class="form-group col-6">
                <!-- <label for="file-input">Change bot's avatar (max: 10kb)</label> -->
                <input
                  :accept="acceptedFiles"
                  id="file-input"
                  type="file"
                  class="file-input"
                  @change="handleFileUpload($event, index, 'avatar')"
                />
                <!-- <p class="avatar-link"><b>Current avatar:</b> {{ fileName[index] || snippet.settings.avatar || 'default' }}</p> -->
              </div>
            </div>

            <div class="col-6">
              <div class="description-header avatar left">Button Avatar </div>
              <div class="content_info">
                <div class="bubble" :style="{ background: snippet.settings.isMainColor ? undefined : snippet.settings.mainColor }">
                  <i :style="{ 'background-image': `url(${snippet.settings.bubbleIcon})`, 'background-size': '100%' }"></i>
                </div>
              </div>

              <div class="form-group col-6">
                <input
                  :accept="acceptedFiles"
                  id="file-input"
                  type="file"
                  class="file-input"
                  @change="handleFileUpload($event, index, 'bubble-icon')"
                />
              </div>
            </div>
          </div>

          <section>
            <h4>Snippet Settings</h4>
            <hr />

            <div class="row">
              <div class="form-group col-6">
                <label for="title">Title</label>
                <input type="text" id="title" v-model="snippet.settings.title" />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-6">
                <label for="defaultChatScreen">Chat bubble screen size</label>
                <div class="form-group--inside">
                  <select v-model="snippet.settings.defaultChatScreen" id="defaultChatScreen" class="white-select">
                    <option class="form-control" value="big">Big</option>
                    <option class="form-control" value="small">Small</option>>
                  </select>
                  <i class="dropdown-toggle x"></i>
                </div>
              </div>

              <div class="form-group col-6">
                <label for="defaultPosition">Chat bubble position</label>
                <div class="form-group--inside">
                  <select v-model="snippet.settings.position" id="defaultPosition" class="white-select">
                    <option class="form-control" value="top-center">Top-center</option>
                    <option class="form-control" value="top-left">Top-left</option>
                    <option class="form-control" value="top-right">Top-right</option>
                    <option class="form-control" value="center-center">Center-center</option>
                    <option class="form-control" value="bottom-left">Bottom-left</option>
                    <option class="form-control" value="bottom-right">Bottom-right</option>
                    <option class="form-control" value="bottom-center">Bottom-center</option>
                  </select>
                  <i class="dropdown-toggle x"></i>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Microphone" v-model="snippet.settings.hasMicButton"></Toggle>
                </div>
              </div>

              <div v-if="toggleBranding" class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Powered By Blits" v-model="snippet.settings.hasPoweredBy"></Toggle>
                </div>
              </div>

              <div class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Use default main color" v-model="snippet.settings.isMainColor"></Toggle>
                </div>
              </div>

              <div class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Use default text color" v-model="snippet.settings.isMainTextColor"></Toggle>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="form-group col-6"
                v-bind:class="{ 'blurred': snippet.settings.isMainColor }"
                v-show="!snippet.settings.isMainColor"
              >
                <label for="mainColor">Main color</label>
                <input type="text" id="mainColor" v-model="snippet.settings.mainColor" />
              </div>

              <div
                class="form-group col-6"
                v-bind:class="{ 'blurred': snippet.settings.isMainTextColor }"
                v-show="!snippet.settings.isMainTextColor"
              >
                <label for="mainTextColor">Text color</label>
                <input type="text" id="mainTextColor" v-model="snippet.settings.mainTextColor" />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Activated by button" v-model="snippet.settings.hasButton"></Toggle>
                </div>
              </div>

              <div class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Show attachment button" v-model="snippet.settings.hasUploadButton"></Toggle>
                </div>
              </div>

              <div class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Popup messages" v-model="snippet.settings.hasPopupMessages"></Toggle>
                </div>
              </div>

              <div class="form-group col-3">
                <div class="form-toggle--inside">
                  <Toggle label="Show notificaton message" v-model="snippet.settings.showNotificationMessage"></Toggle>
                </div>
              </div>
            </div>

            <div
              v-bind:class="{ 'blurred': !snippet.settings.hasPopupMessages }"
              v-if="snippet.settings.hasPopupMessages"
            >
              <ChatBubblePopupMessageInput
                :isFirstInput="true"
                :popupMessage="newPopupMessage"
                @submit="addPopupMessage(index)"
              ></ChatBubblePopupMessageInput>

              <ChatBubblePopupMessageInput
                :key="popupIndex"
                v-for="(popupMessage, popupIndex) in snippet.settings.popupMessages"
                :popupMessage="popupMessage"
                @delete="deletePopupMessage(index, popupIndex)"
              ></ChatBubblePopupMessageInput>
            </div>

            <!-- <div class="row">
              <div class="form-group col-12">
                <label for="cutom-css" >Write your own custom css here</label>
                <textarea class="code js-snippet darkvader" v-model="snippet.settings.css" name="cutomCss" id="cutom-css" rows="6" @keydown.tab.prevent="$tabber($event, `snippets[${index}].settings.css`)">
                </textarea>
              </div>
            </div> -->
          </section>

          <h4>Embedded Code</h4>
          <hr />
          <div class="row">
            <div class="form-group col-12" v-if="snippet.code">
              <label for="code">Unique Code</label>
              <input type="text" readonly id="code" class="code" v-model="snippet.code" />
              <div type=”button” class="copybutton" v-clipboard:copy="snippet.code">COPY</div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label for="code" >Embed the following Javascript code into a HTML website to display the chat bubble</label>
              <textarea readonly class="code js-snippet darkvader" name="snip" id="snip" rows="3">
                <script defer src="{{ snippetUrl }}"></script>
                <script>
                  window.addEventListener('load', function () {
                    window.BlitsBotChat.init({ botId: "{{ botId }}", code: "{{ snippet.code }}", userId: "YOUR_USER_ID" })
                  })
                </script>
              </textarea>
            </div>
          </div>

          <h4>Security</h4>
          <hr />

          <p>
            <label for="allowedOrigins">Authorized origins</label>
            <Tooltip title="If no origins are specified, all origins will be authorized."></Tooltip>
          </p>

          <div class="row">
            <div class="form-group col-6">
              <p class="error" v-show="newOriginError">{{ newOriginError }}</p>
              <input
                v-model="newOrigin"
                @keydown.enter.prevent="addOrigin(index)"
                placeholder="https://www.example.com"
              />
              <div class="copybutton dark" @click="addOrigin(index)">Add</div>
            </div>
          </div>
          <div
            :key="originIndex"
            v-for="(_, originIndex) in snippet.allowedOrigins"
            class="row"
          >
            <div class="form-group col-6">
              <input :value="snippet.allowedOrigins[originIndex]" readonly />
              <i class="icon-delete entity-delete" @click="deleteOrigin(index, originIndex)"></i>
            </div>
          </div>

          <div class="section--footer static">
            <button
              type="button"
              class="btn btn--white red"
              @click="$emit('delete-snippet', snippet)"
            ><i class="icon icon-delete"></i>
            Delete</button>
            <button class="btn btn--purple gradient" type="submit">
              <i class="icon icon-save"></i>
              Save settings
            </button>
          </div>
        </div>
      </form>
    </div>
    <p v-else>There are no external chat bubbles created for this bot yet.</p><br>

    <div class="add-button-widget">
      <button class="btn btn--white margin-bottom-20" v-if="!snippetForm.showForm" @click="snippetForm.showForm = !snippetForm.showForm">
        <i class="icon icon-run-code"></i>
        Add new chat bubble
      </button>
      </div>

    <div class="new-bubble-button">
      <form v-if="snippetForm.showForm" @submit.prevent="$emit('create-snippet')">
        <h4>Add new chat bubble</h4>
        <hr>

        <div class="form-group">
          <label for="name">Chat bubble name</label>
          <input
            type="text"
            id="name"
            v-model.lazy="snippetForm.snippet.name"
            placeholder="please give your chat bubble a unique name"
          />
        </div>

        <div class="form-group">
          <label for="description">Description</label>
          <input
            type="text"
            id="description"
            v-model.lazy="snippetForm.snippet.description"
            placeholder="please describe the purpose of this specific chat bubble (i.e. for our marketing website)"
          />
        </div>

        <br>
        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient margin-bottom-20">Add new bubble  <i class="icon icon-arrow-right"></i></button>
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Toggle from '../../../../helpers/Toggle.vue'
import ComponentName from '../../../../helpers/ComponentName.vue'
import { CreateSnippetForm } from '../interfaces/create.snippet.form'
import { ALLOWED_AVATAR_EXTENSIONS, ALLOWED_SIZE } from '../../../../../../../common/constants/file.constant'
import { getExtension } from '../../../../../../../common/helpers/file.helper'
import popupService from '../../../../../services/popup.service'
import { SnippetService } from '../../../../../services/bots/snippets.service'
import ChatBubblePopupMessageInput from './inputs/ChatBubblePopupMessageInput.vue'
import permissionsService from '../../../../../services/tenants/permissions.service'
import Tooltip from '../../../../helpers/Tooltip.vue'

@Component({
  components: { Toggle, ChatBubblePopupMessageInput, ComponentName, Tooltip }
})
export default class ChatBubble extends Vue {
  private fileName: string[] = [];
  private acceptedFiles = `.${ALLOWED_AVATAR_EXTENSIONS.join(',.')}`;

  private newPopupMessage: any = {};
  addPopupMessage (snippetIndex: number) {
    this.snippets[snippetIndex].settings.popupMessages.push(this.newPopupMessage)
    this.newPopupMessage = {}
  }

  deletePopupMessage (snippetIndex: number, popupMessageIndex: number) {
    this.snippets[snippetIndex].settings.popupMessages.splice(popupMessageIndex, 1)
  }

  newOrigin = '';
  newOriginError = '';
  addOrigin (snippetIndex: number) {
    try {
      const url = new URL(this.newOrigin)
      if (url.origin !== this.newOrigin) throw new Error('Url should contain only the origin part!')
      this.snippets[snippetIndex].allowedOrigins.push(this.newOrigin)
      this.newOrigin = this.newOriginError = ''
    } catch (err) {
      this.newOriginError = err.message
    }
  }

  deleteOrigin (snippetIndex: number, originIndex: number) {
    this.snippets[snippetIndex].allowedOrigins.splice(originIndex, 1)
  }

  @Prop() snippets: any[] = [];
  @Prop() snippetUrl: string;
  @Prop() snippetForm: CreateSnippetForm;
  @Prop() botName: string;
  @Prop() readonly toggleBranding: boolean;

  @Emit('get-snippets')
  beforeCreate () {}

  get botId () {
    return `${permissionsService.tenant.id}-${this.botName}`
  }

  handleFileUpload (event, index, type) {
    if (event.target.files.length !== 0) {
      const targetFile = event.target.files[0]
      const formData = new FormData()
      formData.append(targetFile.name, targetFile)
      formData.forEach(async file => {
        file = file as File
        const fileExtension = getExtension(file.name).toLocaleLowerCase()
        if (file.size > ALLOWED_SIZE) {
          popupService.showError(
            `Icon is too big. Accepted size is ${ALLOWED_SIZE /
              1000}KB`
          )
          this.fileName[index] = ''
        } else if (!ALLOWED_AVATAR_EXTENSIONS.some(e => e === fileExtension)) {
          popupService.showError(
            `Icon type is incorrect. Accepted types are ${this.acceptedFiles}`
          )
          this.fileName[index] = ''
        } else {
          const snippetService = new SnippetService(this.botName)
          this.fileName[index] = targetFile.name
          if (type === 'avatar') { this.snippets[index].settings.avatar = await snippetService.updateSnippetAvatar(this.snippets[index].name, formData) } else if (type === 'bubble-icon') { this.snippets[index].settings.bubbleIcon = await snippetService.updateSnippetBubbleIcon(this.snippets[index].name, formData) }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../settings.style";
@import "../../../../../assets/scss/components/toggle";
@import "../../../../../assets/scss/components/_chat.scss";

hr {
  border-top: 1px solid $light-blue;
}

.bubble {
  padding: 13px;
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: flex;
  background: $sent-background;
  border-radius: 50px;
  border: none;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 1px 10px $popup-shadow;
  -ms-box-shadow: 0px 1px 10px $popup-shadow;
  box-shadow: 0px 1px 10px $popup-shadow;
  i {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
p {
  font-size: 14px;
  font-weight: bold;
  color: #979797;
  line-height: 18px;
  margin: 0;
}
i.plus {
  transition: 0.5s all;
  &.expanded {
    transform: rotate(135deg);
  }
}
form {
  textarea.js-snippet {
    height: auto;
    &.code {
      background-color: $code;
      color: $blue;
      font-weight: bold;
      font-size: 15px;
      border-radius: 6px;
      border: 0px solid $code;
    }
  }

  .form-toggle--inside {
    margin: 15px 0 0;
  }
}

.blurred {
  opacity: 0.6;
}

.add-button-widget {
  margin-bottom: 20px;
}

.description-header {
  font-size: 12px;
  float: left;
  font-weight: bold;
  margin-right: 5px;

  &.avatar {
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: -5px;
    width: 100%;

    &.left {
      margin-left:7px;
    }
  }
}

.file-input {
  margin-left: -8px;
}

.description-text {
  font-size: 12px;
  color: $grey;
}

.btn-add {
  width: 100%;
  margin-left: 0;
}

.avatar-link {
  overflow-wrap: break-word;
}

.new-bubble-button {
  margin: 20px;
}

.file-input#file-input {
  padding: 0;
  margin-bottom: 10px;
  color: black;
  font-size: 11px;
}

.container-snippet {
  border: 1px solid $light-blue;
  border-radius: 6px;
  background: $default;
  padding: 15px;
  margin-bottom: 15px;
}

.content_info {
  width: 400px;
  padding: 15px 15px 15px 20px;
  overflow: hidden;
}

.content_info img {
  margin-right: 15px;
  float: left;
  max-height: 49px;
  max-width: 49px;
}

.content_info h3,
.content_info p {
  display: block;
  margin: 2px 0 0 0;
  color: $secondary-text;
  font-size: 14px;
}

.section--footer.static {
  margin-top: 10px;
  margin-bottom: 10px;
}

.error {
  color: red;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}
</style>
