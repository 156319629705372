import axios from 'axios'

export class RasaService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/rasa/${botName}/server`
  }

  async checkServer (): Promise<boolean> {
    const res = await axios.get(this.url)
    return res.data.hasServer
  }

  createServer () {
    return axios.post(this.url)
  }

  deleteServer () {
    return axios.delete(this.url)
  }
}
