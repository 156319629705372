import { Component, Vue } from 'vue-property-decorator'
import { GoogleHomeService } from '@/services/bots/settings/configuration/google-home.service'

@Component
export default class GoogleHomeManagementMixin extends Vue {
  googleHomeService: GoogleHomeService;
  processId = '';
  message = '';

  initGoogleHome (botName: string) {
    this.googleHomeService = new GoogleHomeService(botName)
  }

  async updateActions (data: any) {
    const res = await this.googleHomeService.updateActions(this.processId, data.projectId)
    this.processId = res.processId
    if (res.message) {
      this.message = res.message
      if (this.message.startsWith('Gactions needs access to your Google account.')) {
        const regex = /https.+state/gm
        const [url] = regex.exec(this.message)
        window.open(url)
      }
    }
  }

  async provideAuthCode (data: any) {
    this.message = await this.googleHomeService.provideAuthCode(this.processId, data.authCode, data.projectId)
    this.processId = undefined
  }
}
