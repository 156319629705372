<template>
  <div class="form-group form-group--options">
    <input
      type="radio"
      name="x-exists-radio"
      id="x-exists-radio-yes"
      @change="change(true)"
    />
    <label for="x-exists-radio-yes">User exists</label>
    <span style="margin: 0 6px"></span>

    <input
      type="radio"
      name="x-exists-radio"
      id="x-exists-radio-no"
      @change="change(false)"
    />
    <label for="x-exists-radio-no">User is new</label>
    <span style="margin: 0 6px"></span>

    <input
      type="radio"
      name="x-exists-radio"
      id="x-exists-radio-null"
      @change="change(null)"
    />
    <label for="x-exists-radio-null">No user present</label>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { ICheckUserBlock } from '../../../../../../../../../common/blocks/connector/check-user-block.interface'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component
export default class CheckUser extends Vue {
  @Prop({ default: () => ({}) }) readonly currentBlock!: ConditionalComponent<ICheckUserBlock>;

  @Emit()
  updateLabel () {
  }

  change (newVal: boolean | null) {
    this.currentBlock.customData.exists = newVal
    this.updateLabel()
  }

  mounted () {
    this.updateLabel()
  }
}
</script>
