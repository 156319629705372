import { IBlock } from '../../../../../../../../../common/blocks/block.interface'
import DialogBlockComponent from '../DialogBlockComponent'
import flowComponentService from '../../../flow-component.service'
import { FlowError } from '../../../../../../../../../common/errors/flow.error'

export default class SendAttachmentBlockComponent<T extends IBlock> extends DialogBlockComponent<T> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions () {
    this.updateSuggestionFieldCapabilities('field-input')
    flowComponentService.updateRestrictions(this.id, this.customData)
  }

  validateFiles () {
    /* eslint-disable */
    const file = this.customData['file']
    if (!this.customData['field-input']) {
      throw new FlowError('File name is missing!', this.id)
    } else if (!file) {
      throw new FlowError('File is missing!', this.id)
    }
    /* eslint-enable */
  }
}
