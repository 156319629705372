<template>
  <Modal title="Email Channel Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form @submit.prevent="connectChannel">
        <h4 v-if="!keys.isEnabled">Email channel setup <br><br> <strong>Please enter your Office 365 account credentials</strong></h4>
        <h4 v-else>Email channel is connected with Office 365 account <strong>{{keys.emailAddress}}</strong></h4>
        <div class="form-section">
        <div v-if="!keys.isEnabled">
          <FormGroup label="Email Address">
            <input v-model="credentials.emailAddress" type="text" class="form-control"  placeholder="address@email.com"/>
          </FormGroup>

          <FormGroup label="Email Password">
            <input v-model="credentials.password" type="password" class="form-control" placeholder="Password"/>
          </FormGroup>

        </div>
        <div v-else>
          <button class="btn btn--white red" type="button" @click="$emit('diconnect-channel'); props.isDisconnectionProccessRunning = true" v-if="!props.isDisconnectionProccessRunning">Disconnect mail channel</button>
          <SmallLoading v-else class="disconnect-loading-indicator"></SmallLoading>
        </div>
        </div>
        <div class="bottom-right-fix" v-if="!keys.isEnabled">
          <button class="btn btn--purple gradient" type="submit" v-if="!props.isConnectingProccessRunning">
            <i class="icon icon-save"></i> Connect
          </button>
          <SmallLoading v-else></SmallLoading>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import Modal from '../../../../../helpers/Modal.vue'
import SmallLoading from '../../../../../helpers/SmallLoading.vue'
import { MailChannelProperties } from '../../../../../../../../common/interfaces/mail-channel-properties'
import { MailChannelCredentials } from '../../../../../../../../common/interfaces/mail-channel-credentials'

@Component({
  components: { FormGroup, Toggle, Modal, SmallLoading }
})
export default class Mail extends Vue {
  @Prop() keys: MailChannelProperties;
  @Prop() props: any;
  private credentials: MailChannelCredentials = { emailAddress: '', password: '' }

  @Watch('keys', { deep: true })
  private onKeysChange () {
    this.credentials = { emailAddress: '', password: '' }
  }

  @Emit('connect-channel')
  private connectChannel () {
    this.props.isConnectingProccessRunning = true
    return this.credentials
  }
}
</script>
