import axios from 'axios'
import { IBotSettings } from '../../../../../common/interfaces/bot-settings.interface'

export class ConfigurationService {
  private readonly url: string
  constructor(botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}`
  }

  async getConfiguration(): Promise<{ channels: any[]; systems: any[] }> {
    const res = await axios.get(`${this.url}/configuration`)
    return res.data
  }

  async updateConfiguration(settings: IBotSettings, channels: any, systems: any) {
    // eslint-disable-next-line no-console
    const res = await axios.post(`${this.url}/configuration`, {
      settings,
      channels,
      systems
    })
    // eslint-disable-next-line no-console
    return res.data.requiresPublish
  }

  requestConnection(request: string, item: string) {
    const body = request === 'channel' ? { channel: item } : { system: item }
    return axios.post(`${this.url}/request/${request}`, body)
  }
}
