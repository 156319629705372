import { Component, Vue } from 'vue-property-decorator'
import { RasaService } from '@/services/bots/settings/configuration/rasa.service'
import popupService from '@/services/popup.service'

@Component
export default class RasaManagementMixin extends Vue {
  rasaService: RasaService;
  hasRasaServer = false;
  isOperationActive = false;

  initRasa (botName: string) {
    this.rasaService = new RasaService(botName)
    this.checkRasaServer()
  }

  async checkRasaServer () {
    this.hasRasaServer = await this.rasaService.checkServer()
  }

  async createRasaServer () {
    if (!this.isOperationActive) {
      this.isOperationActive = true
      try {
        await this.rasaService.createServer()
        this.hasRasaServer = true
        popupService.showInfo('Success!')
      } catch (err) {
        if (err.response && err.response.status === 403) {
          throw err
        }
        popupService.showInfo('Error creating Rasa server!', err.message)
      } finally {
        this.isOperationActive = false
      }
    }
  }

  async deleteRasaServer () {
    if (!this.isOperationActive) {
      this.isOperationActive = true
      try {
        await this.rasaService.deleteServer()
        this.hasRasaServer = false
        popupService.showInfo('Success!')
      } catch (err) {
        if (err.response && err.response.status === 403) {
          throw err
        }
        popupService.showInfo('Error deleting Rasa server!', err.message)
      } finally {
        this.isOperationActive = false
      }
    }
  }
}
