<template>
  <div class="loading" :style="style">
    <div class="loader"></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  padding: 0 15px;
  margin: auto;

  .loader {
    border: 5px solid $input; /* Light grey */
    border-top: 5px solid $main; /* Blue */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 2s linear infinite;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SmallLoading extends Vue {
  @Prop() placement?: 'left' | 'right';

  style = { }

  created () {
    if (this.placement == 'left') { this.style['margin-left'] = 0 } else if (this.placement == 'right') { this.style['margin-right'] = 0 }
  }
}
</script>
