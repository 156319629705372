<template>
  <div class="wrapper-inside general-settings active settings-wrapper">
    <div class="form--wrapper__inner">
      <Connections
        :isTenantAdmin="isTenantAdmin"
        name="Channels"
        dropdownText="Search for a channel"
        :connections="channels"
        :botName="botName"
        :tenantPlan="tenantPlan"
        :requestedConnectionName="requestedConnectionName"
        @open-settings="openSettings"
        @request-connection="requestConnection('channel', $event)"
      ></Connections>

      <Engines :engines="engines" :botName="botName" :tenantPlan="tenantPlan" @open-settings="openSettings"></Engines>

      <Connections
        :isTenantAdmin="isTenantAdmin"
        name="Connectors"
        dropdownText="Search for a connector"
        :connections="systems"
        :botName="botName"
        :tenantPlan="tenantPlan"
        :requestedConnectionName="requestedConnectionName"
        @open-settings="openSettings"
        @request-connection="requestConnection('system', $event)"
      ></Connections>

      <Luis
        v-if="currentTab == 'Microsoft LUIS'"
        :keys="keys"
        :language="language"
        :languages="getLuisLanguages()"
        :customEngineContracts="customEngineContracts"
        @close="closeSettings"
        @delete-luis="updateNlp('delete')"
        @configure-luis="updateNlp('configure')"
        @update-keys="updateNlp('update')"
      ></Luis>

      <WatsonAssistant
        v-if="currentTab == 'IBM Watson Assistant'"
        :keys="keys"
        :regions="regions"
        :isWatsonRunningTask="isRunningTask"
        :customEngineContracts="customEngineContracts"
        @close="closeSettings"
        @delete-watson="updateNlp('delete'), $emit('startTaskRunnig')"
        @configure-watson="updateNlp('configure'), $emit('startTaskRunnig')"
        @update-keys="updateNlp('update'), $emit('startTaskRunnig')"
      ></WatsonAssistant>

      <Dialogflow
        v-if="currentTab == 'Google Dialogflow'"
        :keys="keys"
        :customEngineContracts="customEngineContracts"
        @close="closeSettings"
        @create-project="createProject"
        @delete-project="deleteProject"
      ></Dialogflow>

      <Rasa
        v-if="currentTab == 'Rasa'"
        :hasServer="hasRasaServer"
        :isOperationActive="isOperationActive"
        @close="closeSettings"
        @create-server="updateNlp('create')"
        @delete-server="updateNlp('delete')"
      ></Rasa>

      <NuanceNlu v-if="currentTab === 'Nuance NLU'" :keys="keys" @close="closeSettings" @update-keys="updateNuanceKeys"></NuanceNlu>

      <Alexa
        v-if="currentTab == 'Amazon Alexa'"
        :keys="keys"
        @close="closeSettings"
        @update-keys="updateAlexaKeys"
        @create-skill="createAlexaSkill"
        @delete-skill="deleteAlexaSkill"
      ></Alexa>

      <Api
        v-if="currentTab == 'API'"
        :backendUrl="backendUrl"
        :keys="keys"
        :externalApiConversationIdLifeTime="externalApiConversationIdLifeTime / 60"
        @close="closeSettings"
        @generateNewExternalApiKey="generateNewExternalApiKey"
        @saveExternalApiKeysChages="saveExternalApiKeysChages"
        @deleteExternalApiKey="deleteExternalApiKey"
      ></Api>

      <GoogleHome
        v-if="currentTab == 'Google Assistant'"
        :message="message"
        :processId="processId"
        @close="closeSettings"
        @update-actions="updateActions"
        @provide-auth-code="provideAuthCode"
      ></GoogleHome>

      <TwilioVoice
        v-if="currentTab == 'Twilio Voice'"
        :keys="keys"
        :textToSpeechLanguages="textToSpeechLanguages"
        :speechToTextLanguages="speechToTextLanguages"
        :voices="voices"
        :gatherTypes="gatherTypes"
        @close="closeSettings"
        @generate-webhook="generateTwilioWebhook"
        @update-keys="updateTwilioKeys"
        @delete-webhook="deleteTwilioWebhook"
      ></TwilioVoice>

      <TwilioSms
        v-if="currentTab == BotConnectionEnum.TwilioMessages"
        :keys="keys"
        @close="closeSettings"
        @generate-webhook="generateTwilioWebhook"
        @update-keys="updateTwilioKeys"
        @delete-webhook="deleteTwilioWebhook"
      ></TwilioSms>

      <Facebook v-if="currentTab === 'Facebook'" :keys="keys" @close="closeSettings" @update-keys="updateFacebookKeys"></Facebook>

      <Teams v-if="currentTab === 'Microsoft Teams'" :keys="keys" @close="closeSettings" @update-keys="updateTeamsKeys" @add-to-teams="addBotToTeams"></Teams>

      <Mail
        v-if="currentTab === 'Office 365' || currentTab === 'Email'"
        :keys="keys"
        :props="mailChannelLoadingProps"
        @close="closeSettings"
        @connect-channel="connectMailChannel"
        @diconnect-channel="disconnectMailChannel"
      ></Mail>

      <Slack v-if="currentTab === 'Slack'" :keys="keys" @close="closeSettings" @update-keys="updateSlackKeys"></Slack>

      <Telegram v-if="currentTab === 'Telegram'" :keys="keys" @close="closeSettings" @update-keys="updateTelegramKeys"></Telegram>

      <Salesforce
        v-if="currentTab == 'Salesforce Agent'"
        :keys="keys"
        @close="closeSettings"
        @update-keys="updateSalesforceKeys"
        @change-private-key-file="changePrivateKeyFile"
      ></Salesforce>

      <TTSConfiguration
        v-if="tabsTTS.includes(currentTab)"
        :provider="ttsProvider"
        :languageCode="settings.textToSpeechLanguageCode"
        :voice="settings.textToSpeechPreferredVoiceType"
        :speed="settings.textToSpeechSpeed"
        :responseFormat="settings.textToSpeechResponseFormat"
        :settings="settings"
        @save="save"
        @close="closeSettings"
      ></TTSConfiguration>

      <STTConfiguration
        v-if="tabsSTT.includes(currentTab)"
        :provider="sttProvider"
        :sttLanguageCode="sttLanguageCode"
        @save="save"
        @close="closeSettings"
      ></STTConfiguration>
      <Default v-if="showDefault" @close="closeSettings"></Default>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import botConfigurationManagementMixin from '../_mixins/configuration-management.mixin'

import Connections from './subtabs/Connections.vue'
import Engines from './subtabs/Engines.vue'
import { Engine } from '../interfaces/engine'

import Luis from './modals/Luis.vue'
import WatsonAssistant from './modals/WatsonAssistant.vue'
import Dialogflow from './modals/Dialogflow.vue'
import Rasa from './modals/Rasa.vue'
import NuanceNlu from './modals/NuanceNlu.vue'

import Alexa from './modals/Alexa.vue'
import GoogleHome from './modals/GoogleHome.vue'
import TwilioVoice from './modals/TwilioVoice.vue'
import TwilioSms from './modals/TwilioSms.vue'
import Api from './modals/Api.vue'
import Facebook from './modals/Facebook.vue'
import Teams from './modals/Teams.vue'
import Mail from './modals/Mail.vue'
import Slack from './modals/Slack.vue'
import Telegram from './modals/Telegram.vue'
import Salesforce from './modals/Salesforce.vue'
import TTSConfiguration from './modals/TTSConfiguration.vue'
import Default from './modals/Default.vue'
import { ExternalProvider } from '../../../../../../../common/enums/external-provider.enum'
import { nluLanguages } from '../../../../../../../common/constants/nlu-languages.constant'
import { BotConnectionEnum } from '../../../../../../../common/enums/bot/bot-connection.enum'
import { TenantPlan } from '../../../../../../../common/interfaces/plans/tenant-plan.interface'
import { ttsNames } from '../../../../../../../common/constants/tts-names.constant'
import STTConfiguration from '@/components/admin/bots/settings/tabs/modals/STTConfiguration.vue'
import { sttNames } from '../../../../../../../common/constants/stt-names.constant'
import { IBotSettings } from '../../../../../../../common/interfaces/bot-settings.interface'

@Component({
  computed: {
    ExternalProvider() {
      return ExternalProvider
    },
    tabsTTS() {
      return Object.values(ttsNames)
    },
    tabsSTT() {
      return Object.values(sttNames)
    }
  },
  components: {
    Connections,
    Engines,
    Luis,
    WatsonAssistant,
    Dialogflow,
    Rasa,
    NuanceNlu,
    Alexa,
    GoogleHome,
    TwilioVoice,
    TwilioSms,
    Api,
    Facebook,
    Teams,
    Mail,
    Slack,
    Telegram,
    Salesforce,
    Default,
    TTSConfiguration,
    STTConfiguration
  }
})
export default class Configuration extends Mixins(botConfigurationManagementMixin) {
  @Prop() declare botName: string
  @Prop() language: string
  @Prop() isTenantAdmin: boolean
  @Prop() declare engines: Engine[]
  @Prop() declare channels: any[]
  @Prop() declare systems: any[]
  @Prop() isRunningTask: boolean
  @Prop() ttsProvider: ExternalProvider
  @Prop() sttProvider: ExternalProvider
  @Prop() sttLanguageCode: string
  @Prop() ttsLanguageCode: string
  @Prop() ttsPrefferedVoiceType: string
  @Prop() readonly customEngineContracts: boolean
  @Prop() tenantPlan: TenantPlan
  @Prop() settings: Partial<IBotSettings>

  BotConnectionEnum = BotConnectionEnum

  recognizerLanguages = nluLanguages

  getLuisLanguages() {
    return Object.entries(this.recognizerLanguages[ExternalProvider.Microsoft] || {})
  }

  save(data: Partial<IBotSettings>) {
    // eslint-disable-next-line no-console
    this.$emit('save', data) // --> Settings.saveSettings --> Configuration.onFormSubmit --> ConfigurationManagementMixin.updateConfiguration
  }
}
</script>
