import axios from 'axios'
import { FlowServiceBase } from './flow.service.base'

export class BotFunctionsService extends FlowServiceBase {
  /* eslint-disable */
  // Please remove ts-ignore when this method is implemented (and eslint ignore)
  // @ts-ignore
  uploadFile (containerName: string, formData: FormData): Promise<{ fileId: string; fileExtension: string; contentType: string }> {
    throw new Error('Method not implemented.')
  }

  // Please remove ts-ignore when this method is implemented (and eslint ignore)
  // @ts-ignore
  downloadFile (botName: string, fileId: string, fileName: string): Promise<Blob> {
    throw new Error('Method not implemented.')
  }

  // @ts-ignore
  async saveTestVersion (botName: string, functionName: string, xml: string, versionName: string, ignoreWarnings?: boolean) {
    throw new Error('Method not implemented.')
  }

  /* eslint-enable */

  async saveFlow (botName: string, functionName: string, xml: string, status = '') {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/functions/${functionName}/flow`, { xml: encodeURI(xml), status })).data
  }

  async saveAutoFlow (botName: string, functionName: string, xml: string) {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/functions/${functionName}/auto-save`, { xml: encodeURI(xml) })).data
  }

  async savePublishedVersion (botName: string, functionName: string, xml: string, versionName: string, ignoreWarnings?: boolean) {
    return (await axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/functions/${functionName}/published-version`, { xml: encodeURI(xml), ignoreWarnings, versionName: 'v' + versionName })).data
  }

  async export (botName: string, functionName: string) {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/functions/${functionName}/export`, { responseType: 'blob' })).data
  }

  async _setContent (botName, functionItem) {
    functionItem.content = (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/functions/${encodeURIComponent(functionItem.name)}/content`)).data
  }

  async validateFlow (botName: string, functionName: string, xml: string, ignoreWarnings?: boolean): Promise<any> {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/${botName}/functions/${functionName}/validate-flow-function`, { xml: encodeURI(xml), ignoreWarnings })).data
  }
}

export default new BotFunctionsService()
