import FlowComponent from '../FlowComponent'
import { IEndBlock } from '../../../../../../../common/blocks/end-block.interface'

export default class EndEventBlockComponent extends FlowComponent<IEndBlock> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
    if (!this.customData.outputs) { this.customData.outputs = [] }
  }

  updateRestrictions () {
    return null
  }

  public changeDirection (option: string, intents: any[], bots: any[]) {
    const customData: any = this.customData
    customData.redirectToOtherIntent = option === 'redirectToIntent'
    customData.restartIntent = option === 'restartFlow'
    customData.endFlow = option === 'endFlow'
    customData.redirectToBot = option === 'redirectToBot'

    if (option === 'redirectToIntent') {
      customData.intentTo = intents.length ? intents[0].name : undefined
    } else if (option === 'redirectToBot') {
      customData.redirectBotId = bots.length ? bots[0].id : undefined
    }
  }
}
