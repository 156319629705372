import Vue from 'vue'
import { SnippetService } from '@/services/bots/snippets.service'
import CustomButtons from './custom/CustomButtons.vue'
import CustomAside from './custom/CustomAside.vue'
import { getState, SharedState } from './custom/shared-state'
import { CombinedVueInstance } from 'vue/types/vue'
import { BcrPasswordTokenRequestType } from '../../../../../common/interfaces/bcr-token-request.interface'

declare global {
  interface Window {
    BlitsBotChat: any;
    WebChat: any;
  }
}

interface InitData {
  id?: string;
  password: string;
  passwordType: BcrPasswordTokenRequestType;
  userId?: string;
  isInternal: boolean;
  useStagingVersion: boolean;
  flowName?: string;
  title: string;
  startOpened?: boolean;
  hasButton?: boolean;
  closeWithoutButton?: boolean;
}

class ChatLoader {
  private getCustomButtonsElId (chatId: string) {
    return `#blits-chat-custom-buttons-${chatId}`
  }

  private getCustomAsideElId (chatId: string) {
    return `#blits-chat-custom-aside-${chatId}`
  }

  private scriptPromise: Promise<any>;
  async loadChatScript () {
    if (!this.scriptPromise) {
      this.scriptPromise = SnippetService.getSnippetUrl()
        .then(url => {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = url
          document.head.appendChild(script)
          return new Promise(res => script.onload = res)
        })
    }
    return this.scriptPromise
  }

  private chatStates: { [chatId: string]: {
    customButtons?: CombinedVueInstance<Vue, object, object, object, object>;
    customAside?: CombinedVueInstance<Vue, object, object, object, object>;
  }; } = {};

  async init ({ id, password, passwordType, userId, isInternal, useStagingVersion, flowName, title, startOpened, hasButton, closeWithoutButton }: InitData) {
    await this.loadChatScript()

    const data = await window.BlitsBotChat.init({
      id,
      password,
      passwordType,
      userId,
      useStagingVersion,
      flowName,
      settings: {
        title,
        defaultChatScreen: 'big',
        startOpened,
        hasButton,
        closeWithoutButton
      }
    })

    if (!this.chatStates[id]) {
      this.chatStates[id] = {}
    }
    const state = getState(data.botName, isInternal, data.providers)
    this.chatStates[id].customButtons = new Vue({ el: this.getCustomButtonsElId(id), render: h => h(CustomButtons, { props: { state } }) })
    this.chatStates[id].customAside = new Vue({ el: this.getCustomAsideElId(id), render: h => h(CustomAside, { props: { state } }) })
    window.BlitsBotChat.on(id, 'direct-line', directLine => Vue.set(state, 'directLine' as keyof SharedState, directLine))
  }

  destroy (id = '') {
    window.BlitsBotChat.destroy(id)
    if (this.chatStates[id]) {
      this.chatStates[id].customButtons.$destroy()
      this.chatStates[id].customAside.$destroy()
    }
  }
}

export default new ChatLoader()
