<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <div class="form-group--inside">
          <h4>General LLM settings</h4>
          <div class="description">Here, you can set the name and description of your LLM so you identify the model across all your projects and bots.</div>

          <div class="span" v-if="!this.newLLM">
            <h4 class="mt">Status</h4>
            <div class="form-group--inside">
              <div class="dot-wrapper">
                <div class="dot" :class="isComplete ? 'green' : 'red'" />
                <div class="description trained">LLM {{ isComplete ? '' : 'not' }} trained</div>
              </div>
            </div>

            <h4 class="mt">Provider</h4>
            <div class="form-group--inside">
              <div class="description">{{ getProvider }}</div>
            </div>
          </div>

          <h4 class="mt">Model</h4>
          <div class="form-group--inside">
            <Dropdown :items="openAiModels" :value="selectedModel" @input="setModel($event)" />
          </div>

          <h4 class="mt">Display name</h4>
          <Autocomplete :items="variables" id="botName" v-model="name" placeholder="Enter your LLM's name" :rows="1" spellcheck="false" />

          <h4 class="mt">Description</h4>
          <div class="form-group--inside">
            <Autocomplete :items="variables" name="desc" id="desc" v-model="description" :rows="1" placeholder="Enter a description for your LLM" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LLMObject, NewLLM } from '../../../../../../common/types/llm-type'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'
import { GPTModels } from '../../../../../../common/enums/open-ai-models'

@Component({ components: { Autocomplete, Dropdown } })
export default class LLMdetails extends Vue {
  @Prop() newLLM: NewLLM
  @Prop() LLM: LLMObject
  @Prop() variables: string[]

  openAiModels = GPTModels
  selectedModel = ''

  mounted() {
    if (this.LLM) {
      this.selectedModel = this.LLM.model
    }
  }

  get isComplete() {
    return this.newLLM ? false : this.LLM.isComplete
  }

  get name() {
    return this.newLLM ? this.newLLM.name : this.LLM.llmDisplayName
  }

  set name(value: string) {
    if (this.newLLM) {
      this.newLLM.name = value
    } else {
      this.LLM.llmDisplayName = value
    }
  }

  get description() {
    return this.newLLM ? this.newLLM.description : this.LLM.description
  }

  set description(value: string) {
    if (this.newLLM) {
      this.newLLM.description = value
    } else {
      this.LLM.description = value
    }
  }

  get getProvider() {
    return this.newLLM ? this.newLLM.provider : this.LLM.llmProvider
  }

  get getModel() {
    return this.newLLM ? this.newLLM.model : this.LLM.model
  }

  setModel(event) {
    if (!event) return
    this.selectedModel = event
    if (this.newLLM) {
      this.newLLM.model = event
    } else {
      this.LLM.model = event
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/variables';

.description {
  font-size: 14px;
  margin-bottom: 15px;
}

.mt {
  margin-top: 20px;
}

.dot-wrapper {
  display: flex;
  margin: 0px 0px -15px 0px;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.red {
      background-color: $red;
    }

    &.green {
      background-color: $green;
    }
  }
  .description {
    margin-left: 10px;
    margin-top: -5px;
  }
}
</style>
