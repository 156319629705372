export class CopyPasteService {
  private static readonly CLIPBOARD_DATA_KEY = 'bpmn-js-clipboard'

  constructor (private bpmn: any) {
    this.retreiveClipboardData()

    const oldCopyFunc = this.copyPaste.copy.bind(this.copyPaste)
    this.copyPaste.copy = (...args: any) => {
      oldCopyFunc(...args)
      this.persistClipboardData()
    }
  }

  private get copyPaste () {
    return this.bpmn.get('copyPaste')
  }

  private get clipboard () {
    return this.bpmn.get('clipboard')
  }

  private get moddle () {
    return this.bpmn.get('moddle')
  }

  private persistClipboardData () {
    if (!this.clipboard.isEmpty()) {
      window.localStorage.setItem(CopyPasteService.CLIPBOARD_DATA_KEY, JSON.stringify(this.clipboard.get()))
    }
  }

  private retreiveClipboardData () {
    const serialized = window.localStorage.getItem(CopyPasteService.CLIPBOARD_DATA_KEY)
    if (serialized) {
      try {
        const parsed = JSON.parse(serialized, this.createReviver(this.moddle))
        this.clipboard.set(parsed)
      } catch (_) {
        // . . .
      }
    }
  }

  private createReviver (moddle: any) {
    const elCache = {} as any

    /**
     * The actual reviewer that creates model instances
     * for elements with a $type attribute.
     *
     * Elements with ids will be re-used, if already
     * created.
     *
     * @param  {String} _key
     * @param  {Object} object
     *
     * @return {Object} actual element
     */
    return function (_key: string, object: any) {
      if (typeof object === 'object' && typeof object.$type === 'string') {
        const objectId = object.id

        if (objectId && elCache[objectId]) {
          return elCache[objectId]
        }

        const type = object.$type
        const attrs = Object.assign({}, object)

        delete attrs.$type

        const newEl = moddle.create(type, attrs)

        if (objectId) {
          elCache[objectId] = newEl
        }

        return newEl
      }

      return object
    }
  }
}
