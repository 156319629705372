import { Component, Vue } from 'vue-property-decorator'

import { FunctionsService } from '../../../../services/bots/functions.service'
import descriptionService from '../../../../services/bots/description.service'
import popupService from '../../../../services/popup.service'
import { saveAs } from 'file-saver'

@Component
export default class FunctionManagementMixin extends Vue {
  name = ''
  currentTab = ''
  allFunctions = []
  selectedFunction = ''
  activeFunction = 0
  functionsService: FunctionsService = null

  isSaving = false

  async setFunctions() {
    this.functionsService = new FunctionsService()
    this.allFunctions = await this.functionsService.getAll(this.name)
  }

  get functions() {
    return this.allFunctions.filter(f => f.type != 'flow')
  }

  get flowFunctions() {
    return this.allFunctions.filter(f => f.type == 'flow')
  }

  async createFunction(functionData) {
    const result = await this.functionsService.create(this.name, functionData)
    this.allFunctions.push(result)
    this.currentTab = functionData.type == 'flow' ? 'subs' : 'functions'
  }

  async importFunction(data) {
    const { file, funcName, isGlobal, overwrite } = data
    const func = await this.functionsService.importFunction(this.name, file, funcName, isGlobal)

    if (overwrite) {
      const index = this.allFunctions.find(f => f.name === func.name)
      this.allFunctions[index] = func
    } else {
      this.allFunctions.push(func)
    }

    this.currentTab = func.type == 'flow' ? 'subs' : 'functions'
  }

  async updateFunction(functionData) {
    try {
      this.isSaving = true
      await this.functionsService.update(this.name, functionData)
      popupService.showInfo('Saved!')
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      } else {
        popupService.showError(error.response.data.message)
      }
    } finally {
      this.isSaving = false
    }
  }

  async exportFunction(functionData) {
    const blob = await this.functionsService.exportFunction(this.name, functionData)
    const type = functionData.type == 'flow' ? 'block' : 'function'
    saveAs(blob, `${type}-${functionData.name}.enc`)
  }

  async deleteFunction(functionData) {
    if (await popupService.showDialog('Warning!', 'Are you sure you want to delete this function?', 'Delete')) {
      await this.functionsService.delete(this.name, functionData.name)
      this.allFunctions.splice(this.allFunctions.indexOf(functionData), 1)
    }
  }

  async updateFunctionName(data) {
    try {
      if (this.allFunctions.some(e => e.name.trim() === data.newName.trim())) {
        popupService.showError('Function with the same name already exists!')
        return
      }
      const functionResult = await descriptionService.updateFunctionName(this.name, data.name, data.newName)

      if (functionResult.data.type == 'flow') {
        const customIndex = this.flowFunctions.findIndex(x => x.name === data.name)
        this.$set(this.flowFunctions, customIndex, functionResult.data)
      } else {
        const customIndex = this.functions.findIndex(x => x.name === data.name)
        this.$set(this.functions, customIndex, functionResult.data)
      }

      const allIndex = this.allFunctions.findIndex(x => x.name === data.name)
      this.$set(this.allFunctions, allIndex, functionResult.data)

      popupService.showInfo('Function name updated')
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }
      popupService.showError('Failed to updated function name!')
    }
  }

  get isFunctionSaving() {
    return this.isSaving
  }

  async updateFunctionDescription(data) {
    try {
      await descriptionService.updateFunction(this.name, data.functionName, data.description)
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }
      popupService.showError('Failed to save function description!')
    }
  }
}
