<template>
  <div class="form-group form-group--options">
    <div class="form-group">
      <div class="label">Options</div>
      <Select
        id="math-operation"
        :elements="mathOperations"
        :key="currentBlock.customData.mathOperation"
        keyAttr="name"
        valueAttr="id"
        v-model="currentBlock.customData.mathOperation"
        @input="updateLabel()"
      ></Select>
    </div>

    <div class="form-group" v-if="currentBlock.customData.mathOperation == 1">
      x &lt;
      <autocomplete
        :items="variables"
        placeholder="Number or variable"
        id="number"
        v-model="currentBlock.customData.mathNumber1"
        :rows="1"
        spellcheck="false"
        @input="updateLabel()"
      />
    </div>

    <div class="form-group" v-if="currentBlock.customData.mathOperation == 2">
      x >
      <autocomplete
        :items="variables"
        placeholder="Number or variable"
        id="number"
        v-model="currentBlock.customData.mathNumber1"
        :rows="1"
        spellcheck="false"
        @input="updateLabel()"
      />
    </div>

    <div class="form-group" v-if="currentBlock.customData.mathOperation == 3">
      <autocomplete
        :items="variables"
        placeholder="Number or variable"
        id="number"
        v-model="currentBlock.customData.mathNumber1"
        :rows="1"
        spellcheck="false"
        @input="updateLabel()"
      />
      &lt; x &lt;
      <autocomplete
        :items="variables"
        placeholder="Number or variable"
        id="number"
        v-model="currentBlock.customData.mathNumber2"
        :rows="1"
        spellcheck="false"
        @input="updateLabel()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { mathOperations } from '../../../../modules/operations.config'
import Select from '../../../../../../helpers/Select.vue'
import { IMathComparatorBlock } from '../../../../../../../../../common/blocks/connector/math-comparator-block.interface'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'
import Autocomplete from '../../../../../../helpers/Autocomplete.vue'

@Component({
  components: {
    Select,
    Autocomplete
  }
})
export default class MathComparator extends Vue {
  private mathOperations = mathOperations;

  @Prop({
    default: () => {
      return {}
    }
  })
  readonly currentBlock!: ConditionalComponent<IMathComparatorBlock>;

  @Prop({ default: () => [] }) readonly variables!: any[];

  @Emit()
  updateLabel () {}

  mounted () {
    this.updateLabel()
  }

  changeOperation (operation: string) {
    this.$set(this.currentBlock.customData, 'mathOperation', operation)
    this.updateLabel()
  }
}
</script>
