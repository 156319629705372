import CustomModeler from '../../../../custom-modeler/index'
import FlowComponent from './FlowComponent'
import { IEqualsBlock } from '../../../../../../common/blocks/connector/equals-block.interface'
import { IExistsBlock } from '../../../../../../common/blocks/connector/exists-block.interface'
import { IMathComparatorBlock } from '../../../../../../common/blocks/connector/math-comparator-block.interface'
import { IIsOfTypeBlock } from '../../../../../../common/blocks/connector/is-of-type-block.interface'
import { IIsAnEntityBlock } from '../../../../../../common/blocks/connector/is-an-entity-block.interface'
import { IIsCanonicalBlock } from '../../../../../../common/blocks/connector/is-canonical-block.interface'
import { IBlock } from '../../../../../../common/blocks/block.interface'
import { IContainsBlock } from '../../../../../../common/blocks/connector/contains-block.interface'
import { IBlockConnector } from '../../../../../../common/blocks/block-connector.interface'
import { IWhichChannel } from '../../../../../../common/blocks/connector/which-channel-block.interface'
import { ICheckUserBlock } from '../../../../../../common/blocks/connector/check-user-block.interface'
import { IRegexBlock } from '../../../../../../common/blocks/connector/regex-block.interface'

export class LabelService {
  private readonly defaultLabel = 'Not Defined';

  generateLabelText (operationId: number, block: FlowComponent<IBlockConnector>): string {
    if (typeof operationId === 'string') { operationId = Number.parseInt(operationId) }

    const data = block.customData

    if (data.isDefault) { return 'DEFAULT' }

    switch (operationId) {
      case 1: return this.generateEqualsLabelText(data as IEqualsBlock)
      case 2: return this.generateExistsLabelText(data as IExistsBlock)
      case 3: return this.generateMathComparatorLabelText(data as IMathComparatorBlock)
      case 4: return this.generateInSetLabelText(block)
      case 5: return this.generateContainsLabelText(data as IContainsBlock)
      case 6: return this.generateIsOfTypeLabelText(data as IIsOfTypeBlock)
      case 7: return this.generateIsAnEntityLabelText(data as IIsAnEntityBlock)
      case 8: return this.generateIsCanonicalLabelText(data as IIsCanonicalBlock)
      case 9: return this.generateIsSimilarLabelText(block)
      case 10: return this.generateWhichChannelLabelText(data as IWhichChannel)
      case 11: return this.generateCheckUserLabelText(data as ICheckUserBlock)
      case 12: return this.generateRegexLabelText(data as IRegexBlock)
      default: return this.defaultLabel
    }
  }

  generateEqualsLabelText (data: IEqualsBlock): string {
    switch (data.equalsCmpType) {
      case 'var': return 'Match Var: ' + (data.equalsValueVar || 'empty')
      case 'raw': return 'Match: ' + (data.equalsValueRaw || 'empty')
      case 'opt': return 'Match: ' + (data.equalsValueOpt ? this.removeHtmlTags(data.equalsValueOpt) : 'empty')
      default: return this.defaultLabel
    }
  }

  generateExistsLabelText (data: IExistsBlock): string {
    if (data.checkExistance == 'yes') return 'EXISTS'
    else if (data.checkExistance == 'no') return 'DOES NOT EXIST'
    return this.defaultLabel
  }

  private mathSymbols = ['x < %', 'x > %', '% < x < %%'];
  generateMathComparatorLabelText (data: IMathComparatorBlock): string {
    if (this.mathSymbols[data.mathOperation - 1]) {
      return this.mathSymbols[data.mathOperation - 1]
        .replace('%%', (data.mathNumber2 || '').toString() || 'not given')
        .replace('%', (data.mathNumber1 || '').toString() || 'not given')
    }
    return this.defaultLabel
  }

  generateInSetLabelText (block: FlowComponent<IBlock>): string {
    return 'IN SET  ' + block.id
  }

  generateContainsLabelText (data: IContainsBlock): string {
    return 'Contains:  ' + (data.contains || 'empty')
  }

  generateIsOfTypeLabelText (data: IIsOfTypeBlock): string {
    return 'Is of type:  ' + (data.isOfType || 'empty')
  }

  generateIsAnEntityLabelText (data: IIsAnEntityBlock): string {
    return data.whichEntity ? data.whichEntity : this.defaultLabel
  }

  generateIsCanonicalLabelText (data: IIsCanonicalBlock): string {
    return 'Is canonical:  ' + (data.isCanonical || 'empty')
  }

  generateIsSimilarLabelText (block: FlowComponent<IBlock>) {
    return 'IS SIMILAR:  ' + block.id
  }

  generateWhichChannelLabelText (data: IWhichChannel) {
    return 'Channel: ' + data.channelName
  }

  generateCheckUserLabelText (data: ICheckUserBlock) {
    if (data.exists === undefined) return '-'
    else if (data.exists === null) return 'User is not present'
    return data.exists ? 'User exists' : 'User is new'
  }

  generateRegexLabelText (data: IRegexBlock) {
    if (!data.regex) return '-'
    const displayRegex = data.regex.length >= 8 ? data.regex.substr(0, 4) + ' ...' : data.regex
    return 'Regex: ' + displayRegex
  }

  removeHtmlTags (text: string) {
    return text.replace(/<.*?>/g, '')
  }

  updateArrowLabel (bpmn: CustomModeler, block: FlowComponent<IBlock>, text: string) {
    const modeling = bpmn.get('modeling')

    const startEventShape = block.element
    modeling.updateProperties(startEventShape, {
      name: text
    })
  }
}

export default new LabelService()
