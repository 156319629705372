import { Component, Vue } from 'vue-property-decorator'
import popupService from '@/services/popup.service'
import { TelegramChannelProperties } from '../../../../../../../../common/interfaces/telegram-channel-properties';
import { TelegramService } from '@/services/bots/settings/configuration/telegram.service';

@Component
export default class TelegramManagementMixin extends Vue {
  telegramService: TelegramService;
  keys: TelegramChannelProperties;

  initTelegram (botName: string) {
    this.telegramService = new TelegramService(botName)
    this.getTelegramKeys()
  }

  async getTelegramKeys () {
    this.keys = await this.telegramService.getKeys()
  }

  async updateTelegramKeys () {
    try {
      this.keys = await this.telegramService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }
}
