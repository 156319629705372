import axios from 'axios'

export class LuisService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/luis/${botName}`
  }

  deleteLuis () {
    return axios.delete(`${this.url}/app`)
  }

  configureLuis () {
    return axios.post(`${this.url}/app`)
  }

  async getKeys () {
    const res = await axios.get(`${this.url}/keys`)
    return res.data
  }

  updateKeys (keys: any) {
    return axios.put(`${this.url}/keys`, keys)
  }
}
