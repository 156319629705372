class Canvas {
  private static canvas: HTMLCanvasElement
  static getCanvas () {
    if (!Canvas.canvas) {
      Canvas.canvas = document.createElement('canvas')
    }
    return Canvas.canvas
  }
}

export const getTextWidth = (text: string, font?: string) => {
  const context = Canvas.getCanvas().getContext('2d')
  if (font) context.font = font
  return context.measureText(text).width
}
