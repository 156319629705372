import axios from 'axios'

export class HandoverService {
    private readonly url: string;
    constructor (botName: string) {
      this.url = `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/handover/settings`
    }

    async getHandover () {
      const res = await axios.get(this.url)
      return res.data
    }

    async updateHandover (handoverSettings: any) {
      return axios.put(this.url, handoverSettings)
    }
}
