export const neuralspacePrebuiltEntityMappings: any = {
    "Date": "date",
    "Dimension": "quantity",
    "Email": "email",
    "Location": "geo-political-entities",
    "Currency": "money",
    "Ordinal": "ordinal",
    "Percentage": "percent",
    "PersonalName": "person",
    "PhoneNumber": "phone",
    "Temperature": "temperature",
    "Url": "url"
}
