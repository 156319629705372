/** @description distinguishes the similar services that have different providers
 * (e.g. Luis, Watson Assistant and Dialogflow are all intent recognizers but they have
 * different providers: Microsoft, Ibm, Google)
 */
export enum ExternalProvider {
  Microsoft = "MICROSOFT",
  MicrosoftCLU = "MICROSOFT_CLU",
  Stanford = "STANFORD",
  IBM = "IBM",
  Google = "GOOGLE",
  Amazon = "AMAZON",
  Salesforce = "SALESFORCE",
  Rasa = "RASA",
  Wit = "WIT",
  Nuance = "NUANCE",
  NeuralSpace = "NEURALSPACE",
  OpenAI = "OPEN_AI",
}
