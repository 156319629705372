import BlockStorage from './BlockStorage'
import { BracketType, replacePlaceholdersWithValues } from '../../../../../../common/helpers/placeholder.helper'
import { IConditionalBlock } from '../../../../../../common/blocks/conditional-block.interface'
import { IMessageBlock } from '../../../../../../common/blocks/dialog/message-block.interface'
import { IEndBlock } from '../../../../../../common/blocks/end-block.interface'
import { IEqualsBlock } from '../../../../../../common/blocks/connector/equals-block.interface'
import { IDictionaryBlock } from '../../../../../../common/blocks/dialog/dictionary-block.interface'
import { IQuestionBlock } from '../../../../../../common/blocks/dialog/question-block.interface'
import { IGifBlock } from '../../../../../../common/blocks/dialog/gif-block.interface'
import { IShowOptionsBlock } from '../../../../../../common/blocks/dialog/show-options-block.interface'
import { IRatingBlock } from '../../../../../../common/blocks/dialog/rating-block.interface'
import { ISetVariableBlock } from '../../../../../../common/blocks/action/set-variable-block.interface'
import { ISetObjectBlock } from '../../../../../../common/blocks/action/set-object-block.interface'
import { IRecognizerBlock } from '../../../../../../common/blocks/action/recognizer-block.interface'
import { IStatisticsBlock } from '../../../../../../common/blocks/action/statistics-block.interface'
import { IReplaceInVariableBlock } from '../../../../../../common/blocks/action/replace-in-variable-block.interface'
import { IRedirectCallBlock } from '../../../../../../common/blocks/action/redirect-call-block.interface'
import { IGetTicketBlock } from '../../../../../../common/blocks/system/get-ticket-block.interface'
import { ICreateTicketBlock } from '../../../../../../common/blocks/system/create-ticket-block.interface'
import { IEditTicketBlock } from '../../../../../../common/blocks/system/edit-ticket-block.interface'
import { IGetAssetsOfUserBlock } from '../../../../../../common/blocks/system/get-assets-of-user-block.interface'
import { IGetAssetDetailsBlock } from '../../../../../../common/blocks/system/get-asset-details-block.interface'
import { IMultipleEntityCheck } from '../../../../../../common/blocks/dialog/multiple-entity-check-block.interface'

export class VariableService {
    bpmn: any;

    private _globalVariables: { name: string }[];
    private _subVariables: { name: string }[];

    private get globalVariables () {
      return (this._globalVariables || []).concat(this._subVariables || [])
    }

    constructor (public blockStorage: BlockStorage, globalVariables: any, globalObjects: any) {
      this.bpmn = blockStorage.bpmn
      this.blockStorage = blockStorage
      this._globalVariables = Object.keys(globalVariables).map(key => ({ name: key })).concat(Object.keys(globalObjects).map(key => ({ name: key })))
    }

    setSubVariables (item: any) {
      this._subVariables = (item.inputs || []).map(i => ({ name: i.name }))
    }

    getVariablesForElement (el: any) {
      if (!el) return
      if (el.type === 'label') return

      const id = el.type === 'bpmn:SequenceFlow'
        ? el.element.businessObject.sourceRef.id
        : el.id

      const precedingElements = this.blockStorage.getPrecedingElements(id!)
      // Current block is always included int the first index
      if (precedingElements.some(e => e.id == id)) {
        precedingElements.shift()
      }

      const variables: { name: string }[] = []
      precedingElements.forEach(e => {
        if (e.customData.fc && e.customData['fc-output']) {
          e.customData['fc-output'].forEach((o: any) => variables.push({ name: o }))
        }
        if (e.functionSettings && e.functionSettings.outputs) {
          variables.push(...e.functionSettings.outputs.map(x => ({ name: x.variableName })))
        }
      })

      return variables.concat(this.globalVariables)
    }

    /** Displays all variable lists, block-outputs and conditional inputs boxes */
    displayAll () {
      this.displayConditionalBlocksVariables()
      this.displayEntitiesList()
      this.displayOutputBoxes()
    }

    /** Hide all variable lists, block-outputs and conditional inputs boxes */
    hideAll () {
      this.hideConditionalBlocksVariables()
      this.hideEntitiesList()
      this.hideOutputBoxes()
    }

    hideEntitiesList () {
      Array.from(document.getElementsByClassName('entity-block')).forEach(bl => bl.remove())
      Array.from(document.getElementsByClassName('entity-text')).forEach(bl => bl.remove())
    }

    /**
     * Displays the list of entities at the beginning of the flow
     * If no variables are available, system will show "No entities"
     */
    displayEntitiesList () {
      const overlays = this.bpmn.get('elementRegistry')
      const [startBlock] = overlays.filter(e => e.type === 'bpmn:StartEvent')

      if (startBlock) {
        const startSvg = document.querySelector('[data-element-id="' + startBlock.id + '"]')!
        // If no global variables, show message
        const variables = this.globalVariables.length ? this.globalVariables : [{ name: 'No entities/variables' }]

        // Remove all old blocks
        this.hideEntitiesList()

        // Add text and rect for all globlal variables
        variables.forEach((variable: any, id: number) => {
          // Create the text layer
          const outputVariableText = this._createVariableText(variable, id)

          // Add the text to the svg
          startSvg.appendChild(outputVariableText)

          // Generate the box around the text
          const outputVariableRect = this._createVariableRectangle(outputVariableText, id)

          // Append the rect to the svg
          startSvg.appendChild(outputVariableRect)

          // Switch the places of the text and the rect, so that the text stays over the box
          if (outputVariableText.nextElementSibling) {
                    outputVariableText.parentNode!.insertBefore(outputVariableText.nextElementSibling, outputVariableText)
          }
        })
      }
    }

    hideConditionalBlocksVariables () {
      const overlays = this.bpmn.get('overlays')
      this.bpmn.get('elementRegistry').forEach((e: any) => overlays.remove({ element: e.id }))
    }

    /**
     * Adds boxes for each input variable that is being used by a conditional block
     */
    displayConditionalBlocksVariables () {
      const elementRegistry = this.bpmn.get('elementRegistry')
      const overlays = this.bpmn.get('overlays')

      const conditionalBlocks = elementRegistry.filter((e: any) => {
        overlays.remove({ element: e.id })
        return e.type === 'bpmn:ExclusiveGateway' && e.businessObject
      })

      conditionalBlocks.forEach((conditionalBlock: any) => {
        const objectData = this.blockStorage.findById(conditionalBlock.id).customData as IConditionalBlock

        const inputName = objectData.condition || 'No Variable'

        const overlayHtml = document.createElement('div')
        overlayHtml.classList.add('entity-entry-box')
        overlayHtml.style.cssText = 'background-color: #0d317d; color: ' + (objectData.condition ? 'white' : 'red') + '; border-radius: 10px; padding: 2px 8px; font-size: 11px; max-width: 90px;'
        overlayHtml.textContent = inputName

        if (conditionalBlock.incoming.length) {
          overlays.add(conditionalBlock.incoming[0].id, {
            position: {
              top: -40, right: 90
            },
            html: overlayHtml
          })
        }

        const left = overlayHtml.parentElement.style.left // e.g. -32px
        const leftValue = Number(left.substring(0, left.length - 2)) // e.g. -32 as number
        overlayHtml.parentElement.style.left = `${leftValue + 90 - overlayHtml.clientWidth}px`
      })
    }

    hideOutputBoxes () {
      Array.from(document.getElementsByClassName('outputindicating-block')).forEach(bl => bl.remove())
      Array.from(document.getElementsByClassName('outputindicating-text')).forEach(bl => bl.remove())
    }

    displayOutputBoxes () {
      // Add class 'with-output' to all elements, which have to show their output variables
      this.blockStorage.allComponents().forEach(c => {
        this.bpmn.get('canvas').removeMarker(c.element, 'with-output')

        if (c.customData && c.customData['fc-output'] && c.customData['fc-output'].length) {
          this.bpmn.get('canvas').addMarker(c.element, 'with-output')
        }
      })

      // Remove all old blocks
      this.hideOutputBoxes()

      // Append all new block indicators
      const svg = document.getElementsByClassName('with-output') // Get svg element

      for (const markedEl of svg) {
        let outputString = 'Output'

        const bpmnElement = this.bpmn
          .get('elementRegistry')
          .get(markedEl.getAttribute('data-element-id'))

        if (bpmnElement && bpmnElement.businessObject.customData) {
          const bpmnCustomData = this.blockStorage.findById(bpmnElement.id).customData

          if (bpmnCustomData['fc-output'] && bpmnCustomData['fc-output'].length) {
            outputString = bpmnCustomData['fc-output'][0]
          }
        }

        const outputVariableRect = this._createOutputRectangle()
        markedEl.appendChild(outputVariableRect)

        const outputVariableText = this._createOutputText(outputString)
        markedEl.appendChild(outputVariableText)
      }
    }

    renameVariable (oldName: string, newName: string, afterBlockId?: string) {
      const components = afterBlockId ? this.blockStorage.getElementsAfter(afterBlockId) : this.blockStorage.allComponents()

      for (const component of components) {
        const { type, customData, category } = component
        const { fc } = customData

        const replaceInText = text => replacePlaceholdersWithValues(text, { [oldName]: `[${newName}]` }, BracketType.Square)
        const replaceInField = <T>(data: T, field: keyof T) => data[field] = replaceInText(data[field]) as any

        if (type === 'bpmn:ExclusiveGateway') {
          const data = customData as IConditionalBlock
          if (data.condition === oldName) data.condition = newName
        } else if (type === 'bpmn:EndEvent') {
          const data = customData as IEndBlock
          if (data.intentTo === `__variable__${oldName}`) data.intentTo = `__variable__${newName}`
        } else if (type === 'bpmn:SequenceFlow') {
          const data = customData as IEqualsBlock
          if (data.equalsValueVar === oldName) data.equalsValueVar = newName
        } else if (fc === 'message') {
          const data = customData as IMessageBlock
          replaceInField(data, 'field-message')
        } else if (fc === 'dictionary') {
          const data = customData as IDictionaryBlock
          replaceInField(data, 'condition')
          for (const kvPair of data.dictionaryElements) {
            kvPair.value = replaceInText(kvPair.value)
          }
        } else if (fc === 'askQuestion') {
          const data = customData as IQuestionBlock
          replaceInField(data, 'field-question')
        } else if (fc === 'sendGif') {
          const data = customData as IGifBlock
          replaceInField(data, 'field-gif')
        } else if (fc === 'showOptions') {
          const data = customData as IShowOptionsBlock
          replaceInField(data, 'field-suggestion')
          replaceInField(data, 'field-image')
          data['field-choices'] = data['field-choices'].map(c => replaceInText(c))
        } else if (fc === 'multipleEntityCheck') {
          const data = customData as IMultipleEntityCheck
          replaceInField(data, 'field-entities')
          // data["field-choices"] = data["field-choices"].map(c => replaceInText(c));
        } else if (fc === 'showRating') {
          const data = customData as IRatingBlock
          replaceInField(data, 'field-suggestion')
        } else if (fc === 'setVariable') {
          const data = customData as ISetVariableBlock
          replaceInField(data, 'field-variable-value')
        } else if (fc === 'setObject') {
          const data = customData as ISetObjectBlock
          replaceInField(data, 'field-object-value')
        } else if (fc === 'triggerRecognizer') {
          const data = customData as IRecognizerBlock
          replaceInField(data, 'field-input')
          replaceInField(data, 'field-replace-entities')
        } else if (fc === 'userStatistics') {
          const data = customData as IStatisticsBlock
          replaceInField(data, 'field-var-name')
        } else if (fc === 'replaceInVariable') {
          const data = customData as IReplaceInVariableBlock
          if (data['field-replace-variable'] === oldName) data['field-replace-variable'] = newName
        } else if (fc === 'redirectCall') {
          const data = customData as IRedirectCallBlock
          replaceInField(data, 'field-phone-number')
          replaceInField(data, 'field-redirect-message')
        } else if (fc === 'GetTicket') {
          const data = customData as IGetTicketBlock
          replaceInField(data, 'field-ticketId')
        } else if (fc === 'CreateTicket') {
          const data = customData as ICreateTicketBlock
          replaceInField(data, 'field-name')
          replaceInField(data, 'field-description')
          replaceInField(data, 'field-contact-method')
          replaceInField(data, 'field-support-group')
          replaceInField(data, 'field-classification')
          replaceInField(data, 'field-primary-owner')
        } else if (fc === 'EditTicket') {
          const data = customData as IEditTicketBlock
          replaceInField(data, 'field-ticketId')
          replaceInField(data, 'field-name')
          replaceInField(data, 'field-description')
          replaceInField(data, 'field-primary-owner')
          replaceInField(data, 'field-status')
        } else if (fc === 'GetAssetsOfUser') {
          const data = customData as IGetAssetsOfUserBlock
          replaceInField(data, 'field-typeFilter')
          replaceInField(data, 'field-modelFilter')
        } else if (fc === 'GetAssetDetails') {
          const data = customData as IGetAssetDetailsBlock
          replaceInField(data, 'field-assetId')
        } else if (category === 'Function') {
          const data = customData as { [field: string]: any }
          const { fields } = component.functionSettings
          if (fields) {
            for (const field of fields) {
              replaceInField(data, `field-${field.name}`)
            }
          }
        }

        // Replace outputs for all type of blocks
        const outputs = customData['fc-output'] || []
        for (let i = 0; i < outputs.length; i++) {
          if (outputs[i] === oldName) { outputs[i] = newName }
        }
      }
    }

    private _createOutputRectangle () {
      const outputVariableRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect') // Create a path in SVG's namespace
      outputVariableRect.setAttribute('x', '0')
      outputVariableRect.setAttribute('y', '80')
      outputVariableRect.setAttribute('width', '100')
      outputVariableRect.setAttribute('height', '40')

      outputVariableRect.classList.add('outputindicating-block')

      outputVariableRect.style.stroke = '#979797'
      outputVariableRect.style.strokeWidth = '1px'
      outputVariableRect.style.fill = '#443a93'

      return outputVariableRect
    }

    private _createOutputText (outputString: string) {
      const outputVariableText = document.createElementNS('http://www.w3.org/2000/svg', 'text') // Create a path in SVG's namespace
      outputVariableText.setAttribute('lineheight', '1')
      outputVariableText.setAttribute('x', '5')
      outputVariableText.setAttribute('y', '95')
      outputVariableText.setAttribute('width', '100')
      outputVariableText.setAttribute('height', '30')

      outputVariableText.classList.add('outputindicating-text')

      outputVariableText.style.fontFamily = 'Arial'
      outputVariableText.style.fontSize = '11px'
      outputVariableText.style.fill = 'white'

      const outputs = outputString.split('-')

      for (let i = 0; i < outputs.length; i++) {
        const inSpan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
        inSpan.setAttribute('dy', (i * 13).toString())
        inSpan.setAttribute('x', '5')

        inSpan.innerHTML = outputs[i].trim()
        outputVariableText.append(inSpan)
      }

      return outputVariableText
    }

    private _createVariableRectangle (outputVariableText: SVGTextElement, id: number) {
      const outputVariableRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect') // Create a path in SVG's namespace
      outputVariableRect.setAttribute('y', (45 + id * 30).toString())

      // Set the width of the rectangle to be the same as the width of the text + 10px padding
      outputVariableRect.setAttribute('width', (outputVariableText.getBoundingClientRect().width + 10).toString())
      outputVariableRect.setAttribute('x', ((-1 * outputVariableText.getBoundingClientRect().width) / 2 + 13).toString())
      outputVariableRect.setAttribute('height', '20')
      outputVariableRect.setAttribute('rx', '5')
      outputVariableRect.setAttribute('ry', '5')

      outputVariableRect.classList.add('entity-block')

      // Set rect style
      outputVariableRect.style.stroke = '#979797'
      outputVariableRect.style.strokeWidth = '1px'
      outputVariableRect.style.fill = '#EFEFEF'

      return outputVariableRect
    }

    private _createVariableText (variable: any, id: number) {
      const outputVariableText = document.createElementNS('http://www.w3.org/2000/svg', 'text') // Create a path in SVG's namespace
      outputVariableText.setAttribute('lineheight', '1')
      outputVariableText.setAttribute('y', (60 + id * 30).toString())
      outputVariableText.setAttribute('height', '30')
      outputVariableText.classList.add('entity-text')

      outputVariableText.style.fontFamily = 'Arial'
      outputVariableText.style.fontSize = '11px'
      outputVariableText.style.fill = 'black'
      outputVariableText.style.textAnchor = 'middle'

      // Create the actual span
      const inSpan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
      inSpan.setAttribute('x', '17')

      inSpan.innerHTML = variable.name

      // Add the span to the text layer
      outputVariableText.append(inSpan)
      return outputVariableText
    }
}
