<template>
  <div class="string-array-input" :class="{ 'is-disabled': disabled }">
    <div class="input-row">
      <input type="text" v-model="newItem" :placeholder="placeholder" @keyup.enter="addItem" :disabled="disabled" />
      <button class="btn btn--purple" @click="addItem" :disabled="!newItem || disabled">Add</button>
    </div>
    <div class="items-list">
      <div v-for="(item, index) in localValue" :key="index" class="item">
        <span>{{ item }}</span>
        <button class="remove-btn" @click="removeItem(index)" :disabled="disabled">×</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class StringArrayInput extends Vue {
  @Prop({ required: true }) modelValue!: string[]
  @Prop({ default: 'Add item' }) placeholder!: string
  @Prop({ default: false }) disabled!: boolean

  newItem = ''
  localValue: string[] = []

  created() {
    this.localValue = [...(this.modelValue || [])]
  }

  @Watch('modelValue', { deep: true })
  onModelValueChange(newVal: string[]) {
    this.localValue = [...(newVal || [])]
  }

  addItem(): void {
    if (this.newItem.trim()) {
      this.localValue.push(this.newItem.trim())
      this.$emit('update:modelValue', [...this.localValue])
      this.newItem = ''
    }
  }

  removeItem(index: number): void {
    this.localValue.splice(index, 1)
    this.$emit('update:modelValue', [...this.localValue])
  }
}
</script>

<style lang="scss" scoped>
.string-array-input {
  &.is-disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  .input-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  .items-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .item {
      display: flex;
      align-items: center;
      background: #f5f5f5;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 14px;

      .remove-btn {
        margin-left: 8px;
        background: none;
        border: none;
        color: #666;
        cursor: pointer;
        padding: 0 4px;
        font-size: 16px;

        &:hover {
          color: #ff4444;
        }
      }
    }
  }
}
</style>
