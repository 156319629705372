import axios from 'axios'
import { saveAs } from 'file-saver'

export abstract class AnalyticsServiceBase {
    abstract arrangeModules(): Promise<any>;
    abstract getIntentNames(): Promise<any[]>;
    abstract getUnrecognizedUtterances(): Promise<any[]>;
    abstract getServiceStatus(): Promise<any>;
    abstract getMonth(month: string, year: string): Promise<any>;

    async getData (month: number, year: number) {
      let currMonth = month
        ? Number(month)
        : new Date().getMonth() + 1
      let currYear = year
        ? Number(year)
        : new Date().getFullYear()

      let prevMonth, prevYear

      if (currMonth === 1) {
        prevMonth = 12
        prevYear = currYear - 1
      } else {
        prevMonth = currMonth - 1
        prevYear = currYear
      }

      const stringCurrMonth = ('' + currMonth).slice(-2)
      const stringCurrYear = currYear.toString()

      const stringPrevMonth = ('' + prevMonth).slice(-2)
      const stringPrevYear = prevYear.toString()

      currMonth = Number(stringCurrMonth)
      currYear = Number(stringCurrYear)

      const currData = await this.getMonth(
        stringCurrMonth,
        stringCurrYear
      )
      const prevData = await this.getMonth(
        stringPrevMonth,
        stringPrevYear
      )

      return { currMonth, currYear, currData, prevData, prevMonth: stringPrevMonth, prevYear: stringPrevYear }
    }

    async downloadCsv () {
      const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_URL}/utterances`,
            { responseType: 'blob' }
      )
      saveAs(response.data, 'utterances.csv')
    }
}
