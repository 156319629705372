import axios from 'axios'

export class ConversationLogService {
    private url: string;
    constructor (botName: string) {
      this.url = `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/conversation/log`
    }

    async getChatHistory () {
      return (await axios.get(`${this.url}`)).data
    }

    async getChatHistoryRecent () {
      return (await axios.get(`${this.url}/latest`)).data
    }
}
