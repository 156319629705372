<template>
  <div class="form-group">
    <label for="function">
      In Call Options
    </label>

    <Toggle
      label="Capture DTMF input"
      v-model="currentBlock.customData['capture-dtmf']"
    ></Toggle>

    <div v-if="currentBlock.customData['capture-dtmf']">
      <input
        type="text"
        placeholder="Press 'key' to confirm"
        v-model="currentBlock.customData['dtmf-message']"
      />
      <Select
        :elements="dtmfKeys"
        v-model="currentBlock.customData['dtmf-confirm-key']"
      ></Select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Toggle from '../../../../../../helpers/Toggle.vue'
import Select from '../../../../../../helpers/Select.vue'
import AskQuestionBlockComponent from '../../../../modules/blocks/dialog/blocks/AskQuestionBlockComponent'
import { IQuestionBlock } from '../../../../../../../../../common/blocks/dialog/question-block.interface'
import { DTMF_KEYS } from '../../../../../../../../../common/constants/dtmf-keys.constant'

@Component({ components: { Toggle, Select } })
export default class RandomAnswerExtension extends Vue {
  private dtmfKeys = DTMF_KEYS;

  @Prop({ default: () => { return {} } }) readonly currentBlock!: AskQuestionBlockComponent<IQuestionBlock>;
  @Prop({ default: () => [] }) readonly variables: any[];
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  created () {
    if (!this.currentBlock.customData['dtmf-confirm-key']) { this.currentBlock.customData['dtmf-confirm-key'] = DTMF_KEYS[0] }
  }
}
</script>
