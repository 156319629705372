<template>
  <div>
    <AdaptiveCards :card="adaptiveCard" :data="template" :useTemplating="true" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdaptiveCards } from 'adaptivecards-vue'

@Component({
  components: {
    AdaptiveCards
  }
})
export default class ShowAttachment extends Vue {
  @Prop() fileUrl: string
  @Prop() fileName: string
  @Prop() url: string

  private adaptiveCard: any = {}

  created() {
    this.adaptiveCard = {
      type: 'AdaptiveCard',
      body: [
        {
          type: 'Container',
          items: [
            {
              type: 'Image',
              size: 'medium',
              url: this.fileUrl
            },
            {
              type: 'TextBlock',
              text: this.fileName
            }
          ],
          selectAction: {
            type: 'Action.OpenUrl',
            url: this.url
          }
        }
      ]
    }
  }

  private template = {}
}
</script>

<style></style>
