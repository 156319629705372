import { Component, Vue } from 'vue-property-decorator'
import { GoogleService } from '@/services/bots/google.service'

@Component
export default class DialogflowManagementMixin extends Vue {
  googleService: GoogleService;
  keys: any;

  initDialogflow (botName: string) {
    this.googleService = new GoogleService(botName)
    this.getDialogflowKeys()
  }

  async getDialogflowKeys () {
    this.keys = {
      projectId: await this.googleService.getProjectId()
    }
  }

  async createProject (forUser: boolean) {
    if (forUser) {
      const url = await this.googleService.getCreateProjectUrl()
      window.location.href = url
    } else {
      await this.googleService.createProjectForBlits()
      this.keys.projectId = 'some-proj-id'
    }
  }

  async deleteProject () {
    await this.googleService.deleteProject()
    this.$set(this.keys, 'projectId', '')
  }
}
