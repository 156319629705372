export enum LuisAppLanguage {
    'American English' = 'en-US',
    Chinese = 'zh-CN',
    Dutch = 'nl-NL',
    'French (France)' = 'fr-FR',
    'French (Canada)' = 'fr-CA',
    German = 'de-DE',
    Hindi = 'hi-IN',
    Italian = 'it-IT',
    Japanese = 'ja-JP',
    Korean = 'ko-KR',
    'Portuguese (Brazil)' = 'pt-BR',
    'Spanish (Spain)' = 'es-ES',
    'Spanish (Mexico)' = 'es-MX',
    Turkish = 'tr-TR'
}