import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { LlmsService } from '@/services/bots/llms.services'
import { NewLLM, LLMObject } from '../../../../../../common/types/llm-type'

@Component
export default class LlmManagementMixin extends Vue {
  llms = []
  activeLlm = 0
  name = ''
  currentTab = ''
  testResponse = ''
  llmService: LlmsService = undefined

  async setLlms() {
    this.llmService = new LlmsService()
    this.llms = await this.llmService.getAll()
  }

  async createLLM(llmData: NewLLM) {
    this.llmService = new LlmsService()
    const result = await this.llmService.create(llmData)

    for (const newDocument of llmData.documents) {
      if (newDocument.file) {
        await this.llmService.upload(result.llmId, newDocument.file)
      } else {
        await this.llmService.addDocument(result.llmId, newDocument)
      }
    }
    this.llms.push(result)
  }

  async uploadFile(llmId: string, file: File) {
    this.llmService = new LlmsService()
    return await this.llmService.upload(llmId, file)
  }

  async deleteLLM(llmData: LLMObject) {
    this.llmService = new LlmsService()
    await this.llmService.delete(llmData.llmId)
    this.llms = this.llms.filter((llm) => llm.llmId !== llmData.llmId)
    return true
  }

  async updateLLM(llmData: LLMObject) {
    this.llmService = new LlmsService()
    await this.llmService.update(llmData)
  }

  async trainLLM(llmData: LLMObject) {
    this.llmService = new LlmsService()
    await this.llmService.train(llmData.llmId)
  }

  async runLLM(llmData: LLMObject, text: string) {
    this.llmService = new LlmsService()
    return await this.llmService.runLLM(llmData.llmId, text)
  }
}
