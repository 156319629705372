<template>
  <div class="container">
    <div class="main-card logout">
      <div class="form-content">
        <div class="heading">
          <img src="/assets/img/Blits-logo-white.png" />
          <br><br>
         <h5>Successfully logged out! Redirecting you in a bit...</h5>
        </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import authService from '../../services/auth.service'

@Component
export default class LogOut extends Vue {
  async beforeMount () {
    if (this.$router.currentRoute.name === 'LogOut') {
      document.querySelector('body').classList.add('image-background')
    } else {
      document.querySelector('body').classList.remove('image-background')
    }
    await authService.logout()
  }

  mounted () {
    setTimeout(() => {
      this.$router.push('/')
    }, 3000)
  }
}
</script>

<style lang="scss" scoped>
@import './auth';
</style>
