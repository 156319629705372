/**
 * OpenAI Speech to Text supported languages https://platform.openai.com/docs/guides/speech-to-text/supported-languages
 * The language codes aren't mentioned there,
 * but the type 'TranscriptionCreateParams.language' mentions:
 *    * The language of the input audio. Supplying the input language in
 *    * [ISO-639-1](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes) format will
 *    * improve accuracy and latency.
 **/

export enum OpenAISTTLanguage {
    "Afrikaans"="af",
    "Arabic"="ar",
    "Armenian"="hy",
    "Azerbaijani"="az",
    "Belarusian"="be",
    "Bosnian"="bs",
    "Bulgarian"="bg",
    "Catalan"="ca",
    "Chinese"="zh",
    "Croatian"="hr",
    "Czech"="cs",
    "Danish"="da",
    "Dutch"="nl",
    "English"="en",
    "Estonian"="et",
    "Finnish"="fi",
    "French"="fr",
    "Galician"="gl",
    "German"="de",
    "Greek"="el",
    "Hebrew"="he",
    "Hindi"="hi",
    "Hungarian"="hu",
    "Icelandic"="is",
    "Indonesian"="id",
    "Italian"="it",
    "Japanese"="it",
    "Kannada"="kn",
    "Kazakh"="kk",
    "Korean"="ko",
    "Latvian"="ko",
    "Lithuanian"="ko",
    "Macedonian"="mk",
    "Malay"="ms",
    "Marathi"="mr",
    "Maori"="mi",
    "Nepali"="ne",
    "Norwegian"="no",
    "Persian"="fa",
    "Polish"="pl",
    "Portuguese"="pt",
    "Romanian"="ro",
    "Russian"="ru",
    "Serbian"="sr",
    "Slovak"="sk",
    "Slovenian"="sl",
    "Spanish"="es",
    "Swahili"="sw",
    "Swedish"="sv",
    "Tagalog"="tl",
    "Tamil"="ta",
    "Thai"="th",
    "Turkish"="tr",
    "Ukrainian"="uk",
    "Urdu"="ur",
    "Vietnamese"="vi",
    "Welsh"="cy"
}