export class BotHelper {
  /** Convert bot name into botId fragment */
  assembleBotIdFragment (botName: string) {
    return botName.trim().replace(/\s/g, '-').toLowerCase()
  }

  /** get bot name fragment from bot id */
  disassembleBotId (botId: string) {
    const botFragments = botId.split('-')
    botFragments.shift()
    return botFragments.join('-')
  }
}

export default new BotHelper()
