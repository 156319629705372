<template>
  <div v-if="acceptedFiles">
    <button class="btn btn--white dialogmanager" @click="trigger">
      Upload new file <i class="icon icon-export"></i>
      <i
        v-if="!!this.currentBlock.customData['file'] && hasSufficientPermission"
        class="icon icon-pencil"
      />
      <input
        hidden
        :accept="acceptedFiles"
        id="file-input"
        type="file"
        ref="file"
        @change="handleFileUpload()"
        :disabled="!hasSufficientPermission"
      />
    </button>
    <button
      class="btn btn--white dialogmanager margin-left-0"
      v-if="hasSufficientPermissionManageFlows"
      @click="handleFormData"
    >
      Download existing file <i class="icon icon-import"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { saveAs } from "file-saver";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ISendAttachmentBlock } from "../../../../../../../../../common/blocks/dialog/send-attachment-block.interface";
import botIntentsService from "../../../../../../../services/bots/bot-intents.service";
import popupService from "../../../../../../../services/popup.service";
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_SIZE
} from "../../../../../../../../../common/constants/file.constant";
import ReceiveUserAttachmentBlockComponent from "../../../../modules/blocks/dialog/blocks/ReceiveUserAttachmentBlockComponent";

@Component
export default class UploadFile extends Vue {
  private fileName = "Click to upload a file.";

  @Prop({ default: `.${ALLOWED_EXTENSIONS.join(",.")}` })
  readonly acceptedFiles: string;

  @Prop({
    default: () => {
      return {};
    }
  })
  currentBlock!: ReceiveUserAttachmentBlockComponent<ISendAttachmentBlock>;

  @Prop({ default: () => "" }) readonly containerName!: "";
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;
  @Prop({ required: false, default: true })
  hasSufficientPermissionManageFlows: boolean;

  @Prop({ default: false }) isGifBlock: boolean;

  mounted() {
    this.fileName = this.currentBlock.customData.file
      ? this.currentBlock.customData.file!.name ||
        `${this.currentBlock.customData["field-input"]}.${this.currentBlock.customData.fileExtension}`
      : this.fileName;
  }

  trigger() {
    (this.$refs.file as HTMLInputElement).click();
  }

  handleFileUpload() {
    this.$refs.file = this.$refs.file as Vue;
    if (this.$refs.file.files.length !== 0) {
      const formData = new FormData();
      formData.append(
        this.isGifBlock
          ? this.$refs.file.files[0].name
          : this.currentBlock.customData["field-input"],
        this.$refs.file.files[0]
      );
      formData.forEach(file => {
        if ((file as File).size > ALLOWED_SIZE) {
          popupService.showError(
            `File is too big. Accepted size is ${ALLOWED_SIZE / 1024 / 1024}MB`
          );

          (this.$refs.file as HTMLInputElement).value = "";
        } else {
          this.fileName = (this.$refs.file as Vue).files[0].name;
          this.currentBlock.customData.file = (this.$refs.file as Vue).files[0];

          if (this.isGifBlock) {
            this.currentBlock.customData["field-input"] = this.fileName;
          }
        }
      });
    }
  }

  async handleFormData() {
    const fileName = `${
      this.isGifBlock
        ? this.currentBlock.customData.fileId
        : this.currentBlock.customData["field-input"]
    }.${this.currentBlock.customData.fileExtension}`;
    try {
      saveAs(
        await botIntentsService.downloadFile(
          this.containerName,
          this.currentBlock.customData.fileId,
          fileName
        ),
        fileName
      );
    } catch (_) {
      popupService.showError(
        "Could not find uploaded file",
        "Your files are uploaded when you save the flow. Did you save?"
      );
    }
  }
}
</script>

<style lang="scss">
label[for="file-input"] {
  pointer-events: none;
}

.file-input {
  width: 100%;
}

label {
  cursor: pointer;
  margin-bottom: 0;
}

i.pencil {
  pointer-events: none;
  background-image: url("/public/assets/icons/pencil-link.svg");
  position: relative;
  top: 2px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 14px;
  height: 14px;
  margin-left: 10px;
}
</style>
