<template>
  <div>
    <div class="rect rect--users">
      <h2>Users</h2>
      <p>{{users | format}}</p>
    </div>
    <div class="rect rect--users">
      <h2>Conversations / Chats</h2>
      <p>{{chats | format}}</p>
    </div>
    <div class="rect rect--users">
      <h2>Average time spent</h2>
      <p>{{modifiedSeconds}}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from '../filters/number.filters'

@Component({
  filters: {
    format
  }
})
export default class Users extends Vue {
  @Prop({ default: '' }) readonly month: string;
  @Prop({ default: '' }) readonly users: string;
  @Prop({ default: 0 }) readonly chats: number;
  @Prop({ default: 0 }) readonly seconds: number;

  get modifiedSeconds () {
    return new Date(this.seconds * 1000).toISOString().substr(11, 8)
  }
}
</script>

<style lang="scss" scoped>
@import "analytics.style";

.line {
  margin-top: 10px;
}
.rect {
  &--users {
    &:not(:last-child) {
      margin-bottom: 22px;
    }
    h2, p {
      margin: 0;
    }
    h2 {
      font-size: 12px;
      font-weight: 600;
      color: $main;
      line-height: 15px;
      text-transform: uppercase;
    }
    p {
      font-size: 40px;
      font-weight: 100;
      color: #000;
      line-height: 50px;
    }
  }
}

</style>
