import axios from 'axios'

export class SlackService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/slack/${botName}`
  }

  async getKeys () {
    const res = await axios.get(`${this.url}/keys`)
    return res.data
  }

  async updateKeys (keys: any) {
    const res = await axios.put(`${this.url}/keys`, keys)
    return res.data
  }
}
