<template>
  <div class="wrapper-inside general-settings active settings-wrapper">
    <div class="form-section">
      <h4>Blits Automate</h4>
      <Toggle
        label="Enable Blits Automate"
        :tooltip="nluAutomateTooltip"
        v-model="settings.nluAutomate"
      ></Toggle>
    </div>
    <div class="form-section">
      <h4>Intent Detection Performance</h4>
      <Toggle
        label="Enable intent detection measuring"
        v-model="settings.intentDetectionMeasuring"
        :disabled="!hasPermissionNlpMeasuring || !hasNluPerformance"
      ></Toggle>

      <div v-if="settings.intentDetectionMeasuring" class="nlp-section">
        <label>Which engines need to be measured on recognition?</label>
        <Toggle
          v-for="(name, provider) in recognizers"
          :key="name"
          :label="name"
          :specialLabel="getLabel(provider)"
          :disabled="!isNlpConfigured(provider) || provider == primaryNlp || !hasPermissionNlpMeasuring || !hasNluPerformance"
          v-model="settings.measuringRecognizers[provider]"
        ></Toggle>
      </div>
    </div>

    <div class="form-section">
      <h4>NLU model performance <Tooltip title="NLP Performance interaction will count as normal interactions." placement="right"></Tooltip></h4>
      <button
        type="button"
        class="btn btn--white margin-correct"
        @click="$emit('run-nlp')"
        :disabled="!hasPermissionNlpTesting || isNlpTestRunning || isDisabled || !hasNluPerformance"
      ><i class="icon icon-test-sub"></i>Run NLU model performance test</button>

      <div class="nlp-section" v-if="settings.performanceRecognizers">
        <Toggle
          v-for="(name, provider) in recognizers"
          :key="name"
          :label="name"
          :specialLabel="getLabel(provider)"
          :disabled="!isNlpConfigured(provider) || provider == primaryNlp || !hasPermissionNlpTesting || !hasNluPerformance"
          v-model="settings.performanceRecognizers[provider]"
        ></Toggle>
      </div>

      <input
        type="file"
        ref="nlpDataset"
        accept=".csv"
        hidden
        @change="$emit('upload-nlp-dataset', $refs.nlpDataset.files[0])"
        :disabled="!hasPermissionNlpTesting || !hasNluPerformance"
      />
      <button
        type="button"
        class="btn btn--white margin-correct"
        @click="$refs.nlpDataset.click()"
        :disabled="!hasPermissionNlpTesting || !hasNluPerformance"
      ><i class="icon icon-export"></i>Upload untrained dataset (CSV)</button>
      <button
        type="button"
        class="btn btn--white"
        @click="$emit('download-nlp-dataset')"
        :disabled="!hasPermissionNlpTesting || !hasNluPerformance"
      ><i class="icon icon-import"></i>Download untrained dataset (CSV)</button>
      <button
        type="button"
        class="btn btn--white"
        @click="$emit('download-nlp-results')"
        :disabled="!hasPermissionNlpTesting || !hasNluPerformance"
      ><i class="icon icon-import"></i>Download NLU model performance results (CSV)</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import SmallLoading from '../../../../helpers/SmallLoading.vue'
import Toggle from '../../../../helpers/Toggle.vue'
import Tooltip from '../../../../helpers/Tooltip.vue'
import { Permissions } from '../../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../../services/tenants/permissions.service'
import { ExternalProvider } from '../../../../../../../common/enums/external-provider.enum'
import botNluService from '../../../../../services/bots/bot-nlu.service'
import tenantService from '../../../../../services/tenant.service'
import { nluNames } from '../../../../../../../common/constants/nlu-names.constant'
import { nluAutomateTooltip } from '../../../../../constants/nlu-automate-tooltip.constant'

@Component({ components: { SmallLoading, Toggle, Tooltip } })
export default class Data extends Vue {
  @Prop() isDeletionInProgress: boolean;
  @Prop() botName: string;
  @Prop({ default: () => { return {} } }) settings: any;
  @Prop() isNlpTestRunning: boolean;
  @Prop() readonly hasNluPerformance: boolean;

  private primaryNlp: ExternalProvider = null;
  private configuredNlps: ExternalProvider[] = [];
  private isDisabled = false;

  nluAutomateTooltip = nluAutomateTooltip

  async created () {
    await Promise.all([
      this.loadNlus(),
      this.loadIsDisabled()
    ])
  }

  async loadNlus () {
    [this.primaryNlp, this.configuredNlps] = await Promise.all([
      botNluService.getPrimaryNlp(this.botName),
      botNluService.getConfiguredNlps(this.botName)
    ])

    const measuring = this.settings.measuringRecognizers
    const performance = this.settings.performanceRecognizers

    for (const provider in measuring) {
      if (!this.isNlpConfigured(provider as ExternalProvider)) {
        measuring[provider] = performance[provider] = false
      }
    }

    if (this.isNlpConfigured(this.primaryNlp)) {
      measuring[this.primaryNlp] = performance[this.primaryNlp] = true
    }
  }

  async loadIsDisabled () {
    this.isDisabled = await tenantService.areInteractionExceeded()
  }

  isNlpConfigured (provider: ExternalProvider) {
    return this.configuredNlps.some(p => p == provider)
  }

  getLabel (provider: ExternalProvider) {
    return provider == this.primaryNlp
      ? this.configuredNlps.some(p => p == provider) ? 'Primary' : 'Primary (not configured)'
      : undefined
  }

  private hasPermissionNlpMeasuring = false;
  private hasPermissionNlpTesting = false;

  async beforeMount () {
    [
      this.hasPermissionNlpMeasuring,
      this.hasPermissionNlpTesting
    ] = await permissionsService.hasAllPermissions(
      this.botName,
      Permissions.NlpPerformanceMeasuring,
      Permissions.NlpPerformanceTesting
    )
  }

  recognizers = nluNames

  getSyncDate (time: number) {
    if (!time) return '-'
    return new Date(time).toLocaleString('en-NL')
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/variables";

table, p {
  margin-left: 20px;
}

th, td {
  padding: 0 30px 20px 0;
  font-size: 15px;
  color: $default-dark;
  text-align: center;
}

button[disabled] {
  cursor: not-allowed;
}

.action-button {
  margin: 10px 20px 10px 0;
  min-width: 340px;
  text-align: left;
}

.nlp-section {
  margin: 20px 0 0 20px;
}

.helper-text {
  font-size: 12px;
  color: $grey;
}

.width-300 {
  width: 300px;
  margin-right: 15px;
}
</style>
