import popupService from '@/services/popup.service'

export class FileHelper {
  async uploadFile (fn: (formData: FormData) => Promise<any>, files: any[], allowedSize: number) {
    if (files.length !== 0) {
      const formData = new FormData()
      formData.append(
        files[0].name,
        files[0]
      )
      for (const file of formData.values()) {
        if ((file as File).size > allowedSize) {
          popupService.showError(`File is too big. Accepted size is ${allowedSize / 1000}KB`)
          return ''
        } else {
          const result = await fn(formData)
          return result
        }
      }
    }
  }

  readBlob (data: Blob) {
    const reader = new FileReader()
    reader.readAsText(data)
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })
  }

  readBlobJson (data: Blob) {
    return this.readBlob(data).then(String).then(JSON.parse)
  }
}

export default new FileHelper()
