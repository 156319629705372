import axios from 'axios'

export class SalesforceService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/salesforce/${botName}`
  }

  async getKeys () {
    const res = await axios.get(`${this.url}/keys`)
    return res.data
  }

  updateKeys (keys: any) {
    return axios.put(`${this.url}/keys`, keys)
  }

  updatePrivateKey (file: File) {
    const formData = new FormData()
    formData.append('private-key', file, file.name)

    return axios.put(`${this.url}/private-key`, formData)
  }
}
