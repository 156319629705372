// import {OpenAI} from "openai"  todo: https://dev.azure.com/BlitsBV/Blits%20Platform/_workitems/edit/3909

export enum OpenAiModel {
  "GPT 4" = "GPT 4",
  "GPT_4o" = "gpt-4o", // satisfies OpenAI.Chat.ChatModel todo: https://dev.azure.com/BlitsBV/Blits%20Platform/_workitems/edit/3909
  "GPT 3.5" = "GPT 3.5",
  // 'GPT 3.0'= 'GPT 3.0'
}

export enum GPTModels {
  GPT_4O_LATEST = "gpt-4o-latest",
  GPT_4O_MINI_LATEST = "gpt-4o-mini-latest",
  GPT_4_TURBO_LATEST = "gpt-4-turbo-latest",
  GPT_4_LATEST = "gpt-4-latest",
  GPT_3_5_TURBO_LATEST = "gpt-3.5-turbo-latest",
}
