export enum Permissions {
  //Bot Management
  "ExportBot" = 1,
  "DeleteBot" = 2,
  "ManageBotPermissions" = 3,
  "UseBotDashboardWidgets" = 5, // etc delete from unexpcted answer/ DASHBOARD all
  "SeeConversationLog" = 6,
  "ExportLogs" = 7,

  //NLP Models
  "ManageIntents" = 8, // add, delete and edit intent + add edit delete utterancess
  "ManageEntities" = 9, // add delete edit entities and roles, close lists + examples
  "ExportIntents" = 10,
  "PublishIntentsAndEntities" = 11,

  //Functions
  "ManageFunctions" = 12, /// add delete edit configure functions
  "ManageSubflows" = 13, //save and publish sub flows

  "ManageVariablesObjects" = 15, // deleting adding and editing Bot Variables & Objects
  // "CreateIntentPlusFlowTemplates" = 16,

  //Bot Settings
  "ManageGeneralSettings" = 17,
  "ManageDashboardWidgets" = 18, // Custom Widgets edit, save, delete, add
  "ManageChatBubbles" = 19, // Chat Bubble  save all
  "ManageChannels" = 20, // Settings of all channels and their updating and disable channnel / Request connect of new ones
  "ManageEngines" = 21, // Engines editing and changing
  "ManageConnections" = 22, // Manage systems settings of all systems and their updating and disable channnel / Request connect of new ones
  "ManageGDPRSettings" = 23, // GDPR Settings update
  "ViewApiKeys" = 29,

  "ManageHandoverSettings" = 32,
  "NlpPerformanceMeasuring" = 33,
  "NlpPerformanceTesting" = 34,

  //Dialog Manager

  "ImportAndExportFlows" = 24, // Dialog manager last two buttons
  "ManageFlowsInDialogManager" = 25, // save and auto save of dialogflow

  "PublishFlowsTest" = 30,
  "PublishFlowsPrd" = 31,

  "TestBot" = 27,
  "ManageTestChatLink" = 28,

  "ExportEntities" = 40,
  "ExportSubs" = 41,
  "ExportFunctions" = 42,
  "ManageGlobalBotData" = 43,

  "ManageLLMs" = 50,
  "ExportLLMs" = 51,
}

export const PermissionsNames = {
  //Bot Management
  1: "Export bots",
  2: "Delete bot",
  3: "Manage bot permissions",
  5: "Use bot dashboard widgets",
  6: "See conversation log",
  7: "Export logs",

  //NLP Models
  8: "Manage intents",
  9: "Manage entities",
  10: "Export intents",
  11: "Publish intents & entities",

  //Functions
  12: "Manage functions",
  13: "Manage subflows",
  15: "Manage variables & objects",
  // 16: "Create intent + flow templates",
  33: "Manage NLU model performance measuing",
  34: "Run NLU model performance tests",

  //Bot Settings
  17: "Manage general settings",
  18: "Manage dashboard widgets",
  19: "Manage chat bubbles",
  20: "Manage channels",
  21: "Manage engines",
  22: "Manage connections",
  23: "Manage GDPR settings",
  29: "View API Keys",
  32: "Manage handover settings",

  //Dialog Manager
  24: "Import & export flows",
  25: "Manage flows",
  30: "Publish Flows to Test",
  31: "Publish Flows Production",
  27: "Test bot",
  28: "Manage test chat link",
  50: "Manage Bot LLMs",
  51: "Export Bot LLMs",

  [Permissions.ExportEntities]: "Export entities",
  [Permissions.ExportSubs]: "Export subs",
  [Permissions.ExportFunctions]: "Export functions",
  [Permissions.ManageGlobalBotData]: "Make functions/variables global",
}
