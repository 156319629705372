import axios from 'axios'

export class ChatService {
  async getIntChatPassword (botName: string) {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/int-chat/password`)
    return res.data
  }

  async getChatDetails (conversationId: string) {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/conversation/${conversationId}/state`)
    return res.data
  }

  async getExtChatPassword (botName: string) {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/ext-chat/password`)
    return res.data
  }

  async refreshExtChatPassword (botName: string) {
    const res = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/ext-chat/password`)
    return res.data
  }

  async isChatAvailable (botName: string) {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/is-chat-ready`).then(() => true).catch(() => false)
  }
}

export default new ChatService()
