import { Component, Vue } from 'vue-property-decorator'
import { TeamsService } from '@/services/bots/settings/configuration/teams.service'
import popupService from '@/services/popup.service'
import { TeamsChannelProperties } from '../../../../../../../../common/interfaces/teams-channel-properties'

@Component
export default class TeamsManagementMixin extends Vue {
  teamsService: TeamsService;
  keys: TeamsChannelProperties;

  initTeams (botName: string) {
    this.teamsService = new TeamsService(botName)
    this.getTeamsKeys()
  }

  async getTeamsKeys () {
    this.keys = await this.teamsService.getKeys()
  }

  async updateTeamsKeys () {
    try {
      this.keys = await this.teamsService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }

  async addBotToTeams () {
    await this.teamsService.addBotToTeams()
  }
}
