import axios from 'axios'
import { MailChannelCredentials } from '../../../../../../common/interfaces/mail-channel-credentials'

export class MailChannelService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/mail-channel/${botName}`
  }

  async getKeys () {
    const res = await axios.get(`${this.url}/keys`)
    return res.data
  }

  async connect (credentials: MailChannelCredentials) {
    await axios.post(`${this.url}/connect`, credentials)
  }

  async diconnect () {
    return await axios.post(`${this.url}/disconnect`)
  }
}
