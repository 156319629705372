<template>
  <Modal title="Facebook Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form @submit.prevent="$emit('update-keys')">
        <h4>Facebook channel setup (Read <a href="https://support.blits.ai/portal/en/kb/articles/how-to-set-up-facebook-messenger-channel" target="_blank" rel="noopener noreferrer">here</a> for additional help)</h4>
        <div class="form-section">
          <FormGroup label="Facebook App ID">
            <input v-model="keys.appId" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Facebook App Secret">
            <input v-model="keys.appSecret" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Page ID">
            <input v-model="keys.pages[0].id" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Page Access Token">
            <input v-model="keys.pages[0].accessToken" type="text" class="form-control" />
          </FormGroup>

          <FormGroup v-if="keys.callbackUrl" label="Callback URL (copy and paste in Facebook)">
            <input readonly :value="keys.callbackUrl" type="text" class="form-control" />
          </FormGroup>

          <FormGroup v-if="keys.verifyToken" label="Verify Token (copy and paste in Facebook)">
            <input readonly :value="keys.verifyToken" type="text" class="form-control" />
          </FormGroup>
        </div>
        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
           <i class="icon icon-save" ></i> Save keys
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import Modal from '../../../../../helpers/Modal.vue'
import { FacebookChannelProperties } from '../../../../../../../../common/interfaces/facebook-channel-properties'

@Component({
  components: { FormGroup, Toggle, Modal }
})
export default class Facebook extends Vue {
  @Prop() keys: FacebookChannelProperties;
  private readonly defaultPages = [{ id: '', accessToken: '' }];

  @Watch('keys', { immediate: true })
  onKeysChanged () {
    if (!this.keys.pages) {
      this.keys.pages = this.defaultPages
    }
  }
}
</script>
