export enum WatsonWorkspaceLanguage {
    English = 'en',
    Arabic = 'ar',
    'Chinese (Simplified)' = 'zh-CN',
    'Chinese (Traditional)' = 'zh-TW',
    Czech = 'cs',
    Dutch = 'nl',
    French = 'fr',
    German = 'de',
    Italian = 'it',
    Japanese = 'ja',
    Korean = 'ko',
    'Portuguese (Brazilian)' = 'pt-BR',
    Spanish = 'es'
}