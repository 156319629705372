import { IBlock } from '../../../../../../../../../common/blocks/block.interface'
import DialogBlockComponent from '../DialogBlockComponent'
import flowComponentService from '../../../flow-component.service'
import { FlowError } from '../../../../../../../../../common/errors/flow.error'

export default class SendGifBlockComponent<T extends IBlock> extends DialogBlockComponent<T> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions () {
    this.updateSuggestionFieldCapabilities('field-gif')
    flowComponentService.updateRestrictions(this.id, this.customData)
  }

  validateFiles () {
    /* eslint-disable */
    const file = this.customData['file']
    if (!file && !this.customData['field-gif']) {
      throw new FlowError('Pleace attach image url or image file!', this.id)
    }
    /* eslint-enable */
  }
}
