<template>
  <div class="conversation-log">
    <div class="sidebar">
      <div class="con-title">
        <h4>Conversation Log</h4>
      </div>
      <div class="form--wrapper">
      <div class="main-header ">
        <select v-model="timeBack" v-on:change="updateByTimeFilteredUsers" class="white-select">
          <option value="672">Last 4 weeks</option>
          <option value="336">Last 2 weeks</option>
          <option value="168">Last week</option>
          <option value="24">Last day</option>
          <option value="12">Last 12 hours</option>
          <option value="6">Last 6 hours</option>
          <option value="2">Last 2 hours</option>
        </select>
      </div>
      </div>
      <div class="content-intents__sidebar">
        <ul class="intents-list">
          <li v-for="(item, index) in filteredUsers.slice(1)" :key="index">
            <button class="user-button" @click="updateByUser(index + 1)">{{item}}</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-container">
    <div class="chat">
      <div class="messages" v-for="(item, key) in filteredMessages" :key="key">
       <div
          class="new-converstion"
          v-if="key === 0 || compareConversationIds(key)"
        >
          <span>New conversation - {{timestampToDate(item.timestamp)}}</span>
          <br />
        </div>
        <div class="from-user">
          <div class="content">
            <div class="recieved-txt-bbl" v-if="item.type == 'received'">{{item.message}}</div>
          </div>
        </div>
        <div class="content">
          <ShowImageGif
            v-if="item.message.contentType.startsWith('image')"
            :url="item.message.contentUrl"
          ></ShowImageGif>
          <ShowTitleAndChoices
            v-else-if="item.message.contentType == 'application/vnd.microsoft.card.hero'"
            :title="item.message.content.title"
            :buttons="item.message.content.buttons"
          ></ShowTitleAndChoices>
          <div
            class="css-djy7c2"
            v-else-if="item.message.contentType == 'application/vnd.microsoft.card.adaptive'"
          >
            <AdaptiveCards :card="item.message.content" :data="template" :useTemplating="true"></AdaptiveCards>
          </div>
          <div class="sent-txt-bbl" v-else-if="item.type == 'sent'">{{item.message}}</div>
        </div>
      </div>
      <div v-if="!filteredMessages.length" class="messages">
        <div class="no-data">
          <h4>No conversation data to display</h4>
          Select a different user or time filter.
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConversationLogService } from '../../services/conversation-log.service'
import ShowTitleAndChoices from './ShowTitleAndChoices.vue'
import ShowImageGif from './ShowImageGif.vue'
import ShowAttachment from './ShowAttachment.vue'
import { AdaptiveCards } from 'adaptivecards-vue'
import { PermissionError } from '../../../../common/errors/permission.error'
import popupService from '../../services/popup.service'

@Component({
  components: {
    ShowTitleAndChoices,
    ShowImageGif,
    ShowAttachment,
    AdaptiveCards
  }
})
export default class ConvLog extends Vue {
  @Prop() name: string;
  private allMessages: any[] = [];
  private filteredMessages: any[] = [];
  private timeBack = 672;
  private filteredUsers: any[] = [];
  private currentUserIndex = 0;
  private timeId;
  private template = {};
  private logService: ConversationLogService;
  private botName;

  private lastConversationId = '';
  async created () {
    this.botName = this.$route.params.botName
    this.logService = new ConversationLogService(this.botName)
    try {
      this.allMessages = await this.logService.getChatHistory()
    } catch (error) {
      if (error instanceof PermissionError) {
        popupService.showError('Error!', error.message)
      }
      this.$router.push('/bots')
    }
    await this.filterAllMessagesByUser()
    this.timeId = setInterval(await this.getChatHistoryRecent, 2 * 60000)
  }

  beforeDestroy () {
    clearInterval(this.timeId)
  }

  async getChatHistoryRecent () {
    const messagesRecent = await this.logService.getChatHistoryRecent()
    this.allMessages = this.allMessages.concat(messagesRecent)
    this.updateByUser(this.currentUserIndex)
  }

  updateByTime () {
    this.filteredMessages = this.allMessages.filter(
      m =>
        new Date(m.timestamp).getTime() >
        new Date().getTime() - this.timeBack * 3600000
    )
  }

  updateByTimeFilteredUsers () {
    this.filteredMessages = this.filteredMessages.filter(
      m =>
        new Date(m.timestamp).getTime() >
        new Date().getTime() - this.timeBack * 3600000
    )
  }

  updateByUser (index?: any) {
    if (index !== this.currentUserIndex) {
      this.currentUserIndex = index
      this.updateByTime()
      this.filteredMessages = this.filteredMessages.filter(m =>
        m.user === this.filteredUsers[this.currentUserIndex]
      )
    }
  }

  private compareConversationIds (key: number): boolean {
    return this.filteredMessages[key].conversationId !== this.filteredMessages[key - 1].conversationId
  }

  private timestampToDate (date: string) {
    return date.split('T')[0].split('-').reverse().map(e => e).join('-')
  }

  async filterAllMessagesByUser () {
    this.filteredUsers = this.allMessages
      .map(i => i.user)
      .filter((v, i, a) => a.indexOf(v) === i)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/_chat.scss";
@import "@/assets/scss/_variables.scss";

.conversation-log {
  .sidebar {
    float: left;
    margin-left: 85px;

    .user-button:active, :focus {
      border-left: 4px solid $main;

    }
    .white-select {
      width: 90%;
    }

    .con-title {
      background-color: $white;
      height: 100%;
      margin-bottom: 0px;
      padding-top: 10px;
      border-right: 1px solid $light-blue;

      h4 {
        color: $default-dark;
        font-weight: 700;
        font-size: 24px;
        padding: 10px;
        text-align: center;
        margin-bottom: 0px;
      }
    }

    .main-header {
      background-color: $white;
      height: 80px;
      padding-left: 35px;
      padding-top: 15px;
      display: flex;
      position: relative;
      border-bottom: 0px solid $light-blue;
      border-right: 1px solid $light-blue;
      margin-bottom: 0px;
    }

    .content-intents__sidebar {
      background-color: $sent-text;
      overflow: auto;
      max-height: calc(100vh - 165px);
      min-height: calc(100vh - 139px);
      width: 320px;

      .intents-list {
        height: 100%;

        li {
          height: 100%;
        }
      }
    }
  }

  .new-converstion {
    width: 100%;
    text-align: center;
    color: $grey;
    font-size: 14px;
    border-bottom: 1px solid $light-blue;
    line-height: 0.1em;
    margin: 50px 20px 50px 20px;

    span {
      background: #fff;
      padding: 0 10px;
    }
  }

  .chat-container {
    background-color: #F2F7FF;
  }

  .chat {
    position: absolute;
    display: inline-block;
    background-color: $white;
    overflow-y: auto;
    height: calc(100% - 60px);
    width: calc(100% - 500px);
    margin: 30px 50px 20px 50px;
    border-radius: 12px;

    .messages {
      position: relative;
      margin-right: 5%;
      margin-left: 5%;
      padding-top: 40px;
      padding-bottom: 30px;

      .no-data {
        margin-top: 40px;
        color: $grey;
        font-size: 12px;
        margin-left: 50px;
        h4 {
          color: $default-dark;
          font-weight: 500;
        }
      }

      .textBtn {
        color:$default-dark;
        font-weight: 500;
        font-size: 14px;
        background-color: $white;
        border-radius: 6px;
      }

      .title {
        color: $default-dark;
        font-size: 14px;
        padding: 0px;
      }

      .sent-txt-bbl,
      .recieved-txt-bbl {
        background-color: $received-background;
        border-radius: 8px 8px 8px 0;
        color: $chat-text;
        font-size: 14px;
        font-weight: 500;
        min-height: 20px;
        margin: 0px;
        padding: 10px 20px 10px 20px;
        display: inline-block;
      }
      .from-user {
        .recieved-txt-bbl {
          background-color: $main;
          border-radius: 8px 8px 0 8px;
          color: $white;
        }
        float: right;
      }
    }
    .ac-container,
    .ac-adaptiveCard {
      background-color: transparent !important;
    }
  }
}
</style>
