import axios from 'axios'

export class WidgetsService {
  private readonly url: string;
  constructor (private botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}`
  }

  async getVariables () {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/${this.botName}/statistics/variables`)
    return Object.keys(res.data)
  }

  async getDashboard () {
    const res = await axios.get(`${this.url}/dashboard`)
    return res.data
  }

  updateDashboard (customWidgets: any[]) {
    return axios.post(`${this.url}/dashboard`, customWidgets)
  }
}
