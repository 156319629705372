<template>
  <img
    v-tippy="{
      placement: placement,
      arrow: true,
      theme: 'light',
      maxWidth: '260px'
    }"
    src="/assets/icons/icon-i-purple.svg"
    class="tooltip-icon"
    :title="title"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Tooltip extends Vue {
  @Prop() title: string;
  @Prop({ type: String, default: () => 'right' }) placement: string;
}
</script>

<style lang="scss" scoped>
.tooltip-icon {
  position: relative;
  vertical-align: top;
  top: 2px;
  margin-left: 2px;
  width: 14px;
  height: 14px;
}
</style>
