import axios from 'axios'
import { LLMDocument, NewLLM, LLMObject } from '../../.../../../../common/types/llm-type'

export class LlmsService {
  llmUrl(llmId?: string) {
    if (llmId === '' || llmId === undefined || llmId === null) {
      return `${process.env.VUE_APP_BACKEND_URL}/llm`
    } else {
      return `${process.env.VUE_APP_BACKEND_URL}/llm/${llmId}`
    }
  }

  async getAll() {
    return await axios.get(this.llmUrl()).then((response) => response.data)
  }

  async get(llmId: string) {
    return await axios.get(this.llmUrl(llmId)).then((response) => response.data)
  }

  async create(llmData: NewLLM) {
    return await axios.post(`${this.llmUrl()}/create`, llmData).then((response) => response.data)
  }

  async update(llmData: LLMObject) {
    return await axios.post(`${this.llmUrl(llmData.llmId)}/update`, llmData).then((response) => response.data)
  }

  async upload(llmId: string, file: File) {
    const formData = new FormData()
    formData.append('file', file)
    return await axios.post(`${this.llmUrl(llmId)}/upload`, formData).then((response) => response.data)
  }

  async addDocument(llmId: string, document: LLMDocument) {
    return await axios.post(`${this.llmUrl(llmId)}/document`, document).then((response) => response.data)
  }

  async delete(llmId: string) {
    return await axios.delete(`${this.llmUrl(llmId)}/delete`).then((response) => response.data)
  }

  async train(llmId: string) {
    return await axios.post(`${this.llmUrl(llmId)}/train`).then((response) => response.data)
  }

  async runLLM(llmId: string, text: string) {
    return await axios.post(`${this.llmUrl(llmId)}/test`, text).then((response) => response.data)
  }
}
