<template>
  <div id="preloader">
    <div id="loader"></div>
    <div class="loader-text">{{title}}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Loading'
})
export default class Loading extends Vue {
  @Prop({ required: false, default: 'Loading...' }) title: string;
}
</script>
