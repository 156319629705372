<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <a href="https://support.blits.ai/portal/en/kb/knowledgebase" target="_blank">Go to the Blits Support Knowledge Base</a><br /><br />
    <a href="/" target="_blank">Go to the Blits home page</a><br /><br />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { RouterLink } from 'vue-router'

@Component
export default class NotFound extends Vue {
  // Logic for handling redirection or any other action can be placed here
}
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 2.5rem;
  color: #333;
}

.not-found p {
  font-size: 1.2rem;
  margin: 20px 0;
}

.not-found a {
  color: #3498db;
  text-decoration: none;
  font-size: 1.2rem;
}

.not-found a:hover {
  text-decoration: underline;
}
</style>
