<template>
  <div class="row">
    <div class="col-4 padding-right">
      <div class="form-group">
        <div class="form-group--inside">
          <input
            type="text"
            placeholder="Enter the widget name"
            v-model="customWidget.text"
            :class="{'first-input': isFirstInput }"
            @keydown.enter="submit"
            class="blue-card"
          />
        </div>
      </div>
    </div>

    <div class="col-8">
      <div class="form-group">
        <div class="form-group--inside">
          <autocomplete
            v-model="customWidget.formula"
            :items="variables"
            :class="{'first-input': isFirstInput }"
            placeholder="Enter the widget formula"
            @enter="submit"
            class="blue-card"
          />
          <div type="submit" class="copybutton dark" v-if="isFirstInput">Add</div>
          <i
            class="icon-delete inside-autocomplete"
            :class="{'first-input': isFirstInput }"
            @click.prevent="$emit('remove');"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'

@Component({ components: { Autocomplete } })
export default class CustomWidgetInput extends Vue {
  @Prop({ default: () => false }) readonly isFirstInput: boolean;
  @Prop({ default: function () { return {} } }) readonly customWidget!: any;
  @Prop({ default: () => [] }) readonly variables: string[];

  submit () {
    if (!(this.customWidget.text || '').trim() || !(this.customWidget.formula || '').trim()) { return }

    this.$emit('submit', { text: this.customWidget.text, formula: this.customWidget.formula })
    if (this.isFirstInput) { this.customWidget.text = this.customWidget.formula = '' }
  }
}
</script>

<style lang="scss" scoped>
@import "../../settings.style";
</style>
