<template>
  <div class="form-group form-group--options">
    <div class="label">Variable equals</div>
    <input
      type="radio"
      name="v-equals-radio"
      v-model="currentBlock.customData.equalsCmpType"
      :value="'raw'"
      id="x-equals-radio-raw"
      @change="updateLabel"
    />
    <label for="x-equals-radio-raw">Raw</label>
    <span class="margin20"></span>

    <input
      type="radio"
      name="v-equals-radio"
      v-model="currentBlock.customData.equalsCmpType"
      :value="'var'"
      id="x-equals-radio-var"
      @change="updateLabel"
    />
    <label for="x-equals-radio-var">Variable</label>
    <span class="margin20"></span>

    <input
      type="radio"
      name="v-equals-radio"
      v-model="currentBlock.customData.equalsCmpType"
      :value="'opt'"
      id="x-equals-radio-opt"
      @change="updateLabel"
    />
    <label for="x-equals-radio-opt">Option outcome</label>

    <div v-if="currentBlock.customData.equalsCmpType == 'raw'">
      <input
        type="text"
        placeholder="Enter value to compare"
        class="form-control"
        v-model="currentBlock.customData.equalsValueRaw"
        @input="updateLabel"
      />
    </div>
    <div v-if="currentBlock.customData.equalsCmpType == 'var'">
      <Select
        id="var-to-equal"
        :elements="variables"
        :key="currentBlock.customData.equalsValueVar"
        keyAttr="name"
        class="margin10"
        v-model="currentBlock.customData.equalsValueVar"
        @input="updateLabel()"
      ></Select>
    </div>
    <div v-if="currentBlock.customData.equalsCmpType == 'opt'">
      <Select
        id="opt-to-equal"
        :elements="options"
        :key="currentBlock.customData.equalsValueOpt"
        keyAttr="key"
        valueAttr="value"
        selectedAttr="value"
        class="margin10"
        v-model="currentBlock.customData.equalsValueOpt"
        @input="changeOption"
      ></Select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IEqualsBlock } from '../../../../../../../../../common/blocks/connector/equals-block.interface'
import { IConditionalBlock } from '../../../../../../../../../common/blocks/conditional-block.interface'
import labelService from '../../../../modules/label.service'

import BlockStorage from '../../../../modules/BlockStorage'
import { VariableService } from '../../../../modules/variable.service'
import Select from '../../../../../../helpers/Select.vue'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component({
  components: {
    Select
  }
})
export default class Equals extends Vue {
  private optionBlockKeys = ['showOptions', 'showRating'];

  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<IEqualsBlock>;
  @Prop({ default: () => null }) readonly condBlock: ConditionalComponent<IConditionalBlock>;
  @Prop({ default: () => { return {} } }) readonly blockStorage: BlockStorage;
  @Prop({ default: () => { return {} } }) readonly variableService!: VariableService;
  @Prop({ default: () => [] }) readonly variables: any[];

  get options () {
    // Conditional block before the arrow
    const conditionalBlock = this.condBlock || this.blockStorage.findById(
      this.currentBlock.element.businessObject.sourceRef.id
    ) as ConditionalComponent<IConditionalBlock>
    const condition = conditionalBlock.customData.condition
    // Blocks before the conditional block
    const precedingBlocks = this.blockStorage.getPrecedingElements(
      conditionalBlock.id
    )
    // Block that has options AND it's output is the condition from the conditional block
    const optionsBlock = precedingBlocks.find(
      e =>
        this.optionBlockKeys.includes(e.customData.fc) &&
        (e.customData['fc-output-default'] || []).some(o => o == condition)
    )

    const options = optionsBlock
      ? optionsBlock.customData['field-choices']
      : []
    // Remove HTML from editor
    return options.map(o => ({
      key: labelService.removeHtmlTags(o),
      value: o
    }))
  }

  mounted () {
    this.updateLabel()
  }

  @Emit()
  updateLabel () {}

  changeOption (optionValue: string) {
    let option = null
    let index = null
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].value == optionValue) {
        option = this.options[i]
        index = i
        break
      }
    }

    this.$set(
      this.currentBlock.customData,
      'equalsValueOpt',
      option ? option.value : null
    )
    this.$set(this.currentBlock.customData, 'equalsValueOptIndex', index)

    this.updateLabel()
  }
}
</script>

<style lang="scss" scoped>
.margin10 {
  margin-top: 10px;
}

.margin20 {
  margin-top: 20px;
}

.variables-panel .form-group input {
  margin-top: 10px;
}
</style>
