export enum DialogflowAgentLanguage {
    'Chinese - Cantonese' = 'zh-HK',
    'Chinese - Simplified' = 'zh-CN',
    'Chinese - Traditional' = 'zh-TW',
    Danish = 'da',
    Dutch = 'nl',
    English = 'en',
    'English - Australia' = 'en-AU',
    'English - Canada' = 'en-CA',
    'English - Great Britain' = 'en-GB',
    'English - India' = 'en-IN',
    'English - US' = 'en-US',
    French = 'fr',
    'French - Canada' = 'fr-CA',
    'French - France' = 'fr-FR',
    German = 'de',
    Hindi = 'hi',
    Indonesian = 'id',
    Italian = 'it',
    Japanese = 'ja',
    Korean = 'ko',
    Norwegian = 'no',
    Polish = 'pl',
    'Portuguese - Brazil' = 'pt-BR',
    'Portuguese - Portugal' = 'pt',
    Russian = 'ru',
    Spanish = 'es',
    'Spanish - Latin America' = 'es-419',
    'Spanish - Spain' = 'es-ES',
    Swedish = 'sv',
    Thai = 'th',
    Turkish = 'tr',
    Ukranian = 'uk',
}