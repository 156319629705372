<template>
  <div>
    <div class="row">
      <div class="col-5">
        <h1>{{ mode === 'master-admin' ? 'Tenant' : 'Account' }} Administration</h1>
      </div>
      <div class="col-7 text-right">
        <ConfigurationPageNavTabs
          :value="value"
          @input="$emit('input', $event)"
          :tabs="tabs"
          :mode="mode"
        ></ConfigurationPageNavTabs>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConfigurationMode } from './configuration-mode.type'
import ConfigurationPageNavTabs from './ConfigurationPageNavTabs.vue'

@Component({
  name: 'ConfigurationPageNav',
  components: {
    ConfigurationPageNavTabs
  }
})
export default class ConfigurationPageNav extends Vue {
  @Prop({ default: '' }) value: string;
  @Prop({ default: '' }) mode: ConfigurationMode;

  get tabs () {
    if (this.mode === 'master-admin') return ['Tenants', 'Users', 'Billing']
    if (this.mode === 'admin') return ['Bots', 'Users', 'Roles', 'General', 'Subscription']
    return ['Bots']
  }
}
</script>

<style lang="scss" scoped>
@import "./configuration-page-style.scss";
</style>
