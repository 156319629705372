import Modeler from 'bpmn-js/lib/Modeler'
import customModule from './custom'

/** @description initializes the Modeler with our custom bpmn module */
export default class CustomModeler extends Modeler {
  constructor (options) {
    super({
      ...options,
      additionalModules: [customModule],
      textRenderer: {
        defaultStyle: {
          fontFamily: '"Raleyway", sans-serif',
          fontWeight: '700'
        }
      }
    })
  }
}
