import { Permissions } from "../enums/tenant/user-permissions.enum";
import { BlitsError } from "./blits.error";

export class PermissionError extends BlitsError {

  static CODE = "permission_error";

  constructor(public requiredPermission?: Permissions, message?: string) {
    super(PermissionError.CODE, message
      || `Insufficient permissions!\nYou don't have the ${Permissions[Number(requiredPermission)] + " permission" || "right permission for this action"}. Please contact your administrator.`);
  }
}