<template>
  <Modal title="Create new user" :show="true" @close="$emit('close')" :smallModal="true">
    <div class="form--wrapper">
      <p>You can create new users for your account here</p>

      <div class="form-group">
        <div class="form-group--inside">
          <label for="botName">Name</label>
          <br />
          <input
            type="username"
            class="da-input"
            v-model="name"
            placeholder="Name"
          />

          <div
            v-if="nameInvalid"
            class="invalid"
          >Please provide a valid name. Name can only have the following characters: _, ., -, a-z, A-Z, 0-9</div>
        </div>
          <label for="botName">Email</label>
          <br />
          <input
            type="email"
            class="da-input"
            v-model="email"
            placeholder="Email"
          />

          <div
            v-if="emailInvalid"
            class="invalid"
          >Please provide a valid email.</div>
        </div>
      </div>

      <div class="bottom-right-fix">
        <SmallLoading isInverted="true" v-if="loadingIndicator"></SmallLoading>
        <button class="btn btn--purple gradient" type="button" @click="createUser()">
          {{createUserText}}<i class="icon icon-arrow-right" v-if="!loadingIndicator"></i>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import popupService from "../../../../services/popup.service";
import SmallLoading from "../../../helpers/SmallLoading.vue";
import Modal from "../../../helpers/Modal.vue";
import authManagmentMixin from "../../../auth/auth-managment.mixin";

@Component({ components: { SmallLoading, Modal } })
export default class CreateUser extends Mixins(authManagmentMixin) {
  private nameInvalid = false;
  private emailInvalid = false;
  private name = "";
  private email = "";
  private usernameRegex = /^[a-zA-Z0-9-.]{2,}$/;

  private loadingIndicator = false;
  private createUserText = "Create User";

  @Watch("name")
  onUsernameChanged() {
    if (
      this.usernameRegex.exec(
        this.name.toLowerCase().trim().replace(/\s/g, ".")
      ) ||
      this.name == ""
    ) {
      this.nameInvalid = false;
    } else {
      this.nameInvalid = true;
    }
  }

  @Watch("email")
  onEmailChanged() {
    this.emailInvalid = !this.matchEmail(this.email);
  }

  private createUser() {
    if (
      !this.nameInvalid &&
      this.name != "" &&
      !this.emailInvalid &&
      this.email !== ""
    ) {
      this.loadingIndicator = true;
      this.createUserText = "Creating...";
      this.$emit("create-user", this.name, this.email);
    } else {
      popupService.showWarning(
        "Oops! The name is invalid.",
        "Please provide a valid one."
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.da-input {
  margin-bottom: 10px;
}
</style>
