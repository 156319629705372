import { Component, Vue } from 'vue-property-decorator'
import { FacebookService } from '@/services/bots/settings/configuration/facebook.service'
import popupService from '@/services/popup.service'
import { FacebookChannelProperties } from '../../../../../../../../common/interfaces/facebook-channel-properties'

@Component
export default class FacebookManagementMixin extends Vue {
  facebookService: FacebookService;
  keys: FacebookChannelProperties;

  initFacebook (botName: string) {
    this.facebookService = new FacebookService(botName)
    this.getFacebookKeys()
  }

  async getFacebookKeys () {
    this.keys = await this.facebookService.getKeys()
  }

  async updateFacebookKeys () {
    try {
      this.keys = await this.facebookService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }
}
