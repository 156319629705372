<template>
  <div class="form-group">
    <label for="function">
      Fields
      <Tooltip title="Here is the math equation to be calculated" placement="bottom"></Tooltip>
    </label>

    <autocomplete
      @selections="updateSelections"
      style="margin-bottom: 15px"
      :items="variableNames"
      v-model="currentBlock.customData['equation']"
      :rows="1"
      :disabled="!hasSufficientPermission"
    />

    <div class="button-group">
      <button
        type="button"
        class="button"
        v-for="(elem, index) in mathSymbolsForButton"
        :key="index"
        @click="addSymbol(mathSymbolsForCalculations[index])"
        :disabled="!hasSufficientPermission"
      >{{elem}}</button>

      <button type="button" class="button" @click="addSymbol('^')" :disabled="!hasSufficientPermission">
        x
        <sup>y</sup>
      </button>
    </div>

    <div class="select-formating-style">
      <label for="function">
        Formating style
        <Tooltip
          title="This dropdown defines what is the formating style of the math equation"
          placement="bottom"
        ></Tooltip>
      </label>
    </div>

    <div class="form-group--inside">
      <div>
        <Select
          id="type"
          :elements="enumhh"
          keyAttr="functionName"
          valueAttr="key"
          selectedAttr="key"
          :showDefault="false"
          v-model="formatingStyle"
          @input="changeFormatingStyle()"
          :disabled="!hasSufficientPermission"
        ></Select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Autocomplete from '../../../../../../helpers/Autocomplete.vue'
import Select from '../../../../../../helpers/Select.vue'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import { ISimpleCalculationBlock } from '../../../../../../../../../common/blocks/action/simple-calculation-block.interface'
import { SimpleMathFormatting } from '../../../../../../../../../common/blocks/simple-math-formatting.enum'
import SimpleCalculationBlockComponent
  from '../../../../modules/blocks/action/components/SimpleCalculationBlockComponent'

@Component({ components: { Tooltip, Select, Autocomplete } })
export default class SimpleCalculationExtension extends Vue {
  private lastStartSelection = 0;
  private lastEndSelection = 0;
  private currentSelectionStart = 0;
  private currentSelectionEnd = 0;
  private readonly formatingStyles = [
    'No formatting',
    'Format with dot as decimal',
    'Format with comma as decimal'
  ];

  private readonly mathSymbolsForButton = ['+', '-', '×', '÷', '√'];
  private readonly mathSymbolsForCalculations = ['+', '-', '*', '/', '^(1/2)'];
  private readonly enumhh = Object.values(SimpleMathFormatting);

  @Prop({ default: () => { return {} } })
  readonly currentBlock!: SimpleCalculationBlockComponent<
    ISimpleCalculationBlock
  >;

  @Prop({ default: () => [] }) readonly variableNames!: any[];
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  private formatingStyle =
    this.currentBlock.customData.formatingStyle || SimpleMathFormatting.No;

  updateSelections (data: any) {
    this.currentSelectionStart = data.start
    this.currentSelectionEnd = data.end
  }

  addSymbol (symbol: string) {
    const inputValue = this.currentBlock.customData.equation

    const updatedInput =
      inputValue.substring(0, this.currentSelectionStart) +
      symbol +
      inputValue.substring(this.currentSelectionEnd, inputValue.length)
    this.currentBlock.customData.equation = updatedInput

    if (this.currentSelectionStart < this.currentSelectionEnd) {
      this.lastStartSelection = this.currentSelectionStart + symbol.length
      this.lastEndSelection = this.currentSelectionStart + symbol.length
    } else {
      this.lastStartSelection = this.currentSelectionEnd + symbol.length
      this.lastEndSelection = this.currentSelectionEnd + symbol.length
    }
  }

  changeFormatingStyle () {
    this.currentBlock.customData.formatingStyle = this.formatingStyle
  }
}
</script>

<style lang="scss">
.button {
  display: inline-block;
  width: 40px;
  height: 40px;
  outline: none;
  border: 0;
  background-color: #d7dadf;
  margin-right: 4px;
}

.button-group {
  width: 100%;
  margin-left: 15px;
}

.select-formating-style {
  margin-top: 10px;
}
</style>
