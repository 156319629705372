/**
 * Microsoft Text To Speech supported languages: https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=tts
 */
export enum MicrosoftTTSLanguage {
    'Arabic (Egypt)' = 'ar-EG',
    'Arabic (Saudi Arabia)' = 'ar-SA',
    Bulgarian = 'bg-BG',
    Catalan = 'ca-ES',
    Czech = 'cs-CZ',
    Danish = 'da-DK',
    'German (Austria)' = 'de-AT',
    'German (Switzerland)' = 'de-CH',
    'German (Germany)' = 'de-DE',
    Greek = 'el-GR',
    'English (Australia)' = 'en-AU',
    'English (Canada)' = 'en-CA',
    'English (UK)' = 'en-GB',
    'English (Ireland)' = 'en-IE',
    'English (India)' = 'en-IN',
    'English (US)' = 'en-US',
    'Spanish (Spain)' = 'es-ES',
    'Spanish (Mexico)' = 'es-MX',
    Finish = 'fi-FI',
    'French (Canada)' = 'fr-CA',
    'French (Switzerland)' = 'fr-CH',
    'French (France)' = 'fr-FR',
    'Hebrew (Israel)' = 'he-IL',
    'Hindi (India)' = 'hi-IN',
    Croatian = 'hr-HR',
    Hungarian = 'hu-HU',
    Indonesian = 'id-ID',
    Italian = 'it-IT',
    Japanese = 'ja-JP',
    Korean = 'ko-KR',
    Malay = 'ms-MY',
    Norwegian = 'nb-NO',
    Dutch = 'nl-NL',
    Polish = 'pl-PL',
    'Portuguese (Brazil)' = 'pt-BR',
    'Portuguese (Portugal)' = 'pt-PT',
    Romanian = 'ro-RO',
    Russian = 'ru-RU',
    Slovak = 'sk-SK',
    Slovenian = 'sl-SI',
    Swedish = 'sv-SE',
    'Tamil (India)' = 'ta-IN',
    'Telugu (India)' = 'te-IN',
    Thai = 'th-TH',
    'Turkish (Turkey)' = 'tr-TR',
    Vietnamese = 'vi-VN',
    'Chinese (Mandarin, Simplified)' = 'zh-CN',
    'Chinese (Cantonese, Traditional)' = 'zh-HK',
    'Chinese (Taiwanese Mandarin)' = 'zh-TW'
}