<template>
  <button
    class="btn btn--purple gradient margin-correct"
    type="button"
    @click="openPaymentPage"
  >
    <i class=""></i>
    Manage payment method & view invoices
  </button>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import usersService from '../../../../services/users.service'

@Component
export default class StripeBillingButton extends Vue {
  openPaymentPage () {
    usersService.openStripeCustomerPortal()
  }
}
</script>

<style>

</style>
