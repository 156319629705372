export enum WitAppLanguage {
    'Afrikaans' = 'af',
    'Akan' = 'ak',
    'Albanian' = 'sq',
    'Amharic' = 'am',
    'Arabic' = 'ar',
    'Armenian' = 'hy',
    'Assamese' = 'as',
    'Aymara' = 'ay',
    'Azerbaijani' = 'az',
    'Basque' = 'eu',
    'Belarusian' = 'be',
    'Bengali' = 'bn',
    'Bosnian' = 'bs',
    'Breton' = 'br',
    'Bulgarian' = 'bg',
    'Burmese' = 'my',
    'Catalan' = 'ca',
    'Cherokee' = 'ck',
    'Chichewa' = 'ny',
    'Chinese' = 'zh',
    'Croatian' = 'hr',
    'Czech' = 'cs',
    'Danish' = 'da',
    'Divehi' = 'dv',
    'Dutch' = 'nl',
    'English' = 'en',
    'Esperanto' = 'eo',
    'Estonian' = 'et',
    'Faroese' = 'fo',
    'Finnish' = 'fi',
    'French' = 'fr',
    'Fulah' = 'ff',
    'Galician' = 'gl',
    'Ganda' = 'lg',
    'Georgian' = 'ka',
    'German' = 'de',
    'Greek' = 'el',
    'Guarani' = 'gn',
    'Gujarati' = 'gu',
    'Haitian' = 'ht',
    'Hausa' = 'ha',
    'Hebrew' = 'he',
    'Hindi' = 'hi',
    'Hungarian' = 'hu',
    'Icelandic' = 'is',
    'Igbo' = 'ig',
    'Indonesian' = 'id',
    'Inuktitut' = 'iu',
    'Irish' = 'ga',
    'Italian' = 'it',
    'Japanese' = 'ja',
    'Javanese' = 'jv',
    'Kalaallisut' = 'kl',
    'Kannada' = 'kn',
    'Kashmiri' = 'ks',
    'Kazakh' = 'kk',
    'Khmer' = 'km',
    'Kinyarwanda' = 'rw',
    'Kirghiz' = 'ky',
    'Korean' = 'ko',
    'Kurdish' = 'ku',
    'Lao' = 'lo',
    'Latin' = 'la',
    'Latvian' = 'lv',
    'Limburgish' = 'li',
    'Lingala' = 'ln',
    'Lithuanian' = 'lt',
    'Macedonian' = 'mk',
    'Malagasy' = 'mg',
    'Malay' = 'ms',
    'Malayalam' = 'ml',
    'Maltese' = 'mt',
    'Maori' = 'mi',
    'Marathi' = 'mr',
    'Mongolian' = 'mn',
    'Nepali' = 'ne',
    'Northern Sami' = 'se',
    'Norwegian' = 'no',
    'Norwegian (NB)' = 'bokmal',
    'Oriya' = 'or',
    'Oromo' = 'om',
    'Pashto' = 'ps',
    'Persian' = 'fa',
    'Polish' = 'pl',
    'Portuguese' = 'pt',
    'Punjabi' = 'pa',
    'Quechua' = 'qu',
    'Raeto-Romance' = 'rm',
    'Romanian' = 'ro',
    'Russian' = 'ru',
    'Sanskrit' = 'sa',
    'Sardinian' = 'sc',
    'Scottish Gaelic' = 'gd',
    'Serbian' = 'sr',
    'Shona' = 'sn',
    'Sicilian' = 'ci',
    'Sindhi' = 'sd',
    'Sinhalese' = 'si',
    'Slovak' = 'sk',
    'Slovenian' = 'sl',
    'Somali' = 'so',
    'Sotho' = 'st',
    'South Ndebele' = 'nr',
    'Spanish' = 'es',
    'Sundanese' = 'su',
    'Swahili' = 'sw',
    'Swati' = 'ss',
    'Swedish' = 'sv',
    'Tagalog' = 'tl',
    'Tajik' = 'tg',
    'Tamil' = 'ta',
    'Tatar' = 'tt',
    'Telugu' = 'te',
    'Thai' = 'th',
    'Tibetan' = 'bo',
    'Tigrinya' = 'ti',
    'Tsonga' = 'ts',
    'Tswana' = 'tn',
    'Turkish' = 'tr',
    'Uighur' = 'ug',
    'Ukrainian' = 'uk',
    'Urdu' = 'ur',
    'Uzbek' = 'uz',
    'Venda' = 've',
    'Vietnamese' = 'vi',
    'Welsh' = 'cy',
    'Western Frisian' = 'fy',
    'Wolof' = 'wo',
    'Xhosa' = 'xh',
    'Yiddish' = 'yi',
    'Yoruba' = 'yo',
    'Zulu' = 'zu'
}