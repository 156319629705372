<template>
  <div>
    <AdaptiveCards :card="adaptiveCard" :data="template" :useTemplating="true" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdaptiveCards } from 'adaptivecards-vue'

@Component({
  components: {
    AdaptiveCards
  }
})
export default class ShowImageGif extends Vue {
  @Prop() url: string

  private adaptiveCard: any = {}

  private template = {}

  created() {
    this.adaptiveCard = {
      type: 'AdaptiveCard',
      body: [
        {
          type: 'Image',
          altText: '',
          url: this.url
        }
      ],
      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
      version: '1.0'
    }
  }
}
</script>

<style></style>
