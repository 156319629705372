import axios, { AxiosHeaders, AxiosRequestHeaders } from 'axios'
import Vue from 'vue'
import { TOKENLESS_URLS } from '../../../common/constants/route.constant'

class AuthService {
  private platformUser = null

  readonly AUTH_TOKEN_KEY = 'authtoken'
  readonly STRIPE_CUSTOMER_ID_KEY = 'stripe.customer.id'
  readonly PLATFORM_USER_KEY = 'user'

  constructor() {
    this.loadUser()
  }

  private get rootUrl() {
    return window.location.origin ? window.location.origin + '/' : window.location.protocol + '/' + window.location.host + '/'
  }

  private loadUser() {
    const user = localStorage.getItem(this.PLATFORM_USER_KEY)
    this.platformUser = user ? JSON.parse(user) : null
  }

  get user() {
    if (!this.platformUser) this.loadUser()
    return this.platformUser || this.adal.user
  }

  async getAdalConfig() {
    try {
      const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/app-registration`)
      return {
        tenant: res.data.tenant,
        clientId: res.data.clientId,
        redirectUri: this.rootUrl,
        cacheLocation: 'localStorage'
      }
    } catch (_) {
      throw new Error('There is no connection to the server!')
    }
  }

  // Microsoft forces reload and login after 1 hour. To prevent that we will keep the token cached and won't check expiry in the backend
  private _adalTokenPromise: Promise<string | null>
  private async getAdalToken() {
    if (!this._adalTokenPromise) {
      this._adalTokenPromise = new Promise((res) =>
        this.acquireADToken()
          .catch(() => null)
          .then(res)
      )
    }
    return this._adalTokenPromise
  }

  private get adal() {
    return Vue.prototype.$adal
  }

  async isAuthenticated() {
    return !!this.getPlatformToken() || !!(await this.getAdalToken())
  }

  private acquireADToken() {
    return new Promise<string>((resolve, reject) => this.adal.acquireToken(this.adal.config.clientId, (err, token) => (err ? reject(err) : resolve(token))))
  }

  async addTokenToAxiosRequests() {
    // Add the token to every request
    axios.interceptors.request.use(
      async (config) => {
        if (!TOKENLESS_URLS.some((url) => config.url.includes(url))) {
          const token = this.getPlatformToken() || (await this.getAdalToken())
          config.headers.set('AuthToken', token)
        }
        return config
      },
      (err) => Promise.reject(err)
    )
  }

  async login(userId: string, password: string) {
    const result = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/auth/login`, { userId, password })
    this.platformUser = result.data.user
    localStorage.setItem(this.PLATFORM_USER_KEY, JSON.stringify(this.platformUser))
    this.addToken(result.data.token)
    return true
  }

  async logout() {
    if (this.getPlatformToken()) {
      await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/auth/logout`).catch(() => undefined)
      this.removeToken()
    } else {
      this.adal.logout()
    }
    localStorage.removeItem(this.STRIPE_CUSTOMER_ID_KEY)
  }

  addToken(token) {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token)
  }

  getPlatformToken() {
    return localStorage.getItem(this.AUTH_TOKEN_KEY)
  }

  removeToken() {
    localStorage.removeItem(this.AUTH_TOKEN_KEY)
    localStorage.removeItem(this.PLATFORM_USER_KEY)
  }
}

export default new AuthService()
