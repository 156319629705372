import axios from 'axios'

export class VariablesService {
  variablesUrl (containerName) {
    return `${process.env.VUE_APP_BACKEND_URL}/${containerName}/variables`
  }

  /**
   * Get all varaibles and objects
   * @param {string} containerName - bot
   */
  async getAll (containerName) {
    return await axios
      .get(`${this.variablesUrl(containerName)}/all`)
      .then(response => response.data)
  }

  objectsUrl (containerName) {
    return `${process.env.VUE_APP_BACKEND_URL}/${containerName}/objects`
  }

  /**
   * Saves variables for given bot
   * @param {string} containerName - bot
   * @param {{[name:string]:string}} variables
   */
  async saveVariables (containerName, variables) {
    await axios.put(`${this.variablesUrl(containerName)}`, variables)
  }

  async saveObject (containerName, properties, name: string) {
    await axios.put(`${this.objectsUrl(containerName)}`, { properties, name })
  }

  async deleteObject (containerName, key, isGlobal) {
    await axios.delete(`${this.objectsUrl(containerName)}/${key}/${isGlobal}`)
  }
}

export default new VariablesService()
