<template>
  <div class="form-group form-group--options">
    <div>
      <div class="label">Set of elements</div>
      <div
        v-for="(type, index) in setElements"
        class="set-elements"
        :key="index"
      >
        <input
          type="radio"
          :id="'x-inset-radio-raw-' + index"
          :name="'v-inset-radio-' + index"
          :checked="currentBlock.customData['equals-set'][index].type == 'raw'"
          @change="changeType(index, 'raw')"
        />
        <label :for="'x-inset-radio-raw-' + index">Raw Value</label>
        <span style="margin: 0 6px"></span>

        <input
          type="radio"
          :id="'x-inset-radio-var-' + index"
          :name="'v-inset-radio-' + index"
          :checked="currentBlock.customData['equals-set'][index].type == 'var'"
          @change="changeType(index, 'var')"
        />
        <label :for="'x-inset-radio-var-' + index">Variable</label>
        <span style="margin: 0 6px"></span>

        <div>
          <input
            v-if="type == 'raw'"
            v-model="currentBlock.customData['equals-set'][index].value"
            type="text"
            :placeholder="'Option'"
            class="form-control"
          />

          <Select
            v-if="type == 'var'"
            id="set-to-equal"
            :elements="variables"
            :key="currentBlock.customData['equals-set'][index].value"
            keyAttr="name"
            v-model="currentBlock.customData['equals-set'][index].value"
          ></Select>
        </div>

        <i class="icon-delete" @click="removeEqualsFromSet(index)"></i>
      </div>
            <div class="option-button">
      <div class="btn btn--white dialogmanager" @click="addEqualsToSet">Add new set <i class="icon icon-add"></i></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IInSetBlock } from '../../../../../../../../../common/blocks/connector/in-set-block.interface'
import Select from '../../../../../../helpers/Select.vue'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component({
  components: {
    Select
  }
})
export default class InSet extends Vue {
  private setElements: Array<string> = null;

  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<IInSetBlock>;
  @Prop({ default: () => [] }) readonly variables: any[];

  @Emit()
  updateLabel () {
  }

  mounted () {
    this.updateLabel()

    if (!this.currentBlock.customData['equals-set']) {
      this.currentBlock.customData['equals-set'] = []
    }
    this.setElements = this.currentBlock.customData['equals-set'].map(e => e.type)
  }

  addEqualsToSet () {
    this.currentBlock.customData['equals-set'].push({
      type: 'raw',
      value: ''
    })
    this.setElements.push('raw')
  }

  removeEqualsFromSet (index: number) {
    this.currentBlock.customData['equals-set'].splice(index, 1)
    this.setElements.splice(index, 1)
  }

  changeType (index, to) {
    this.setElements[index] = to
    this.setElements = JSON.parse(JSON.stringify(this.setElements))
    this.currentBlock.customData['equals-set'][index].type = to
  }
}
</script>

<style lang="scss" scoped>
.icon-delete {
  position: absolute;
  top: 51px;
  right: 14px;
}

.variables-panel .form-group .form-control {
  margin-top: 10px;
}
</style>
