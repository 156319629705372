<template>
  <Modal title="Import a bot" :show="true" @close="$emit('close')">
    <ImportItem
      mode="bot"
      :file="file"
      :isTaken="isTaken"
      :isLanguageValid="isLanguageValid"
      :item="bot"
      :recognizers="recognizers"
      :languages="languages"
      :name.sync="newName"
      :region.sync="newRegion"
      :recognizer.sync="newRecognizer"
      :language.sync="newLanguage"
      :nluAutomate.sync="newNluAutomate"
      @importItem="importBot"
      @loadItem="loadBot"
    ></ImportItem>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import botService from '../../../services/bots/bot.service'
import ImportItem from './ImportItem.vue'
import popupService from '../../../services/popup.service'
import { nluNames } from '../../../../../common/constants/nlu-names.constant'
import { nluLanguages } from '../../../../../common/constants/nlu-languages.constant'

import Modal from '../../helpers/Modal.vue'
import { NluProvider } from '../../../../../common/types/nlu-provider'
import { LuisAppLanguage } from '../../../../../common/enums/microsoft/luis-app-language.enum'
import { BotRegion } from '../../../../../common/enums/bot/bot-region.enum'

@Component({
  components: {
    ImportItem,
    Modal
  }
})
export default class ImportBot extends Vue {
  @Prop() bots: Array<any>;
  @Prop() file: File;

  private bot = null;
  private newName = '';
  newRegion = BotRegion.EU_WEST;
  private newRecognizer: NluProvider = null;
  private newLanguage = '';
  private newNluAutomate = false;
  private isTaken = false;
  private isLanguageValid = true;
  private languages: any = [];

  @Watch('newRecognizer')
  onRecognizerChanged () {
    this.languages = Object.entries(this.recognizerLanguages[this.newRecognizer] || {})
  }

  @Watch('newNluAutomate')
  onNluAutomateChanged () {
    this.newNluAutomate
      ? this.languages = Object.entries(LuisAppLanguage)
      : this.onRecognizerChanged()
  }

  recognizers = nluNames
  recognizerLanguages = nluLanguages

  async loadBot () {
    this.bot = await botService.validateImport(this.file)
    this.newName = this.bot.botName
    this.newRegion = this.bot.region
    this.newRecognizer = this.bot.settings.intentRecognizerProvider
    this.newLanguage = this.bot.settings.language
  }

  nameIsValid (name) {
    return name && name.length > 0 && /^([a-zA-Z0-9\-\s\_])+$/g.test(name)
  }

  async importBot () {
    if (!this.nameIsValid(this.newName)) {
      return popupService.showWarning('Warning!', 'The name of your bot is incorrect.')
    }

    if (this.bots.some(b => b.botName === this.newName)) {
      this.isTaken = true
      return
    }
    this.isTaken = false

    const languagePack = this.recognizerLanguages[this.newRecognizer] || {}
    if (!Object.values(languagePack).some(v => v === this.newLanguage)) {
      this.isLanguageValid = false
      return
    }
    this.isLanguageValid = true

    const url = await botService.importBot(this.file, {
      name: this.newName,
      region: this.newRegion,
      recognizer: this.newRecognizer,
      language: this.newLanguage,
      nluAutomate: this.newNluAutomate
    })
    if (url) window.location.href = url
    else this.$router.push(`bots/${encodeURIComponent(this.newName)}`)
  }
}
</script>
