import { Component, Vue } from 'vue-property-decorator'
import { ExternalApiService } from '@/services/bots/settings/configuration/external-api.service'
import popupService from '@/services/popup.service'

@Component
export default class ExternalApiManagementMixin extends Vue {
  keys: any;
  private externalApiService: ExternalApiService = null;
  backendUrl: string = null;

  initExternalApi (botName: string) {
    this.externalApiService = new ExternalApiService(botName)
    this.externalApiService.getBackendUrl().then(url => this.backendUrl = url)
  }

  async saveExternalApiKeysChages (objectData: { editObject: any; conversationIdLifeTime: number }) {
    if (await this.externalApiService.updateKeys(objectData.editObject, objectData.conversationIdLifeTime)) {
      popupService.showInfo('Saved!')
    }
  }

  async deleteExternalApiKey (keyId: string) {
    if (await popupService.showDialog('Warning!', 'Are you sure you want to delete this Access Key?', 'Delete')) {
      if (await this.externalApiService.deleteKey(keyId)) {
        Object.keys(this.keys.externalApiKeys).forEach(k => this.keys.externalApiKeys[k].keyId == keyId ? this.$delete(this.keys.externalApiKeys, k) : null)
        popupService.showInfo('Deleted!')
      }
    }
  }

  async generateNewExternalApiKey () {
    const key = await this.externalApiService.generateNewKey()
    this.$set(this.keys.externalApiKeys, key.apiKey, key)
  }
}
