import { render, staticRenderFns } from "./CreateObject.vue?vue&type=template&id=56ee9531&scoped=true"
import script from "./CreateObject.vue?vue&type=script&lang=ts"
export * from "./CreateObject.vue?vue&type=script&lang=ts"
import style0 from "./CreateObject.vue?vue&type=style&index=0&id=56ee9531&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ee9531",
  null
  
)

export default component.exports