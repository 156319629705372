import popupService from "@/services/popup.service";
import tenantService from "@/services/tenant.service";
import usersService from "@/services/users.service";
import { Component, Vue } from "vue-property-decorator";
import {
  StripePaymentError,
} from "../../../../../../common/errors";
import { TenantPlan } from "../../../../../../common/interfaces/plans/tenant-plan.interface";
import { IStripeCustomerDto } from "../../../../../../common/interfaces/stripe-customer-dto.interface";
import { validateStripeCustomerBool } from "../../../../../../common/helpers/stripe-customer.helper";
import { TenantPlanPrice } from "../../../../../../common/interfaces/plans/tenant-plan-price.interface";
import { availableSettingsChannels } from "../../../../../../common/constants/availableSettingsChannels.constant";
import { premiumChannels } from "../../../../../../common/constants/premiumChannels.constant";

@Component
export default class SubscriptionMixin extends Vue {
  plan: TenantPlan;
  plans: TenantPlan[];
  getPlanName(plan: TenantPlan) {
    return plan.metadata.name;
  }

  getUsageAmount(amount: number, max: number, hasBilling: boolean) {
    if (max === 0 && hasBilling) max = Number.MAX_SAFE_INTEGER;
    return `${amount}/${max === Number.MAX_SAFE_INTEGER ? "∞" : max}`;
  }

  getPlanCost(plan: TenantPlan) {
    return plan.metadata.price ? "€" + plan.metadata.price : "Contact Us";
  }

  getInteractionsStartingCost(plan: TenantPlan) {
    const price = plan.prices.find(p =>
      p.nickname.toLowerCase().includes("interactions")
    );
    if (!price || !price.tiers[0]?.unitPrice) return undefined;
    const cost = price.tiers[price.tiers.length - 1]?.unitPrice;
    return cost ? "€" + cost : undefined;
  }

  isAppSumo(plan: TenantPlan) {
    return plan.name.toLowerCase()?.includes("appsumo");
  }

  isEnterprise(plan: TenantPlan) {
    return plan.name.toLowerCase()?.includes("enterprise");
  }

  isCustom(plan: TenantPlan) {
    return plan.name.toLowerCase()?.includes("custom");
  }

  isMasterTenant(plan: TenantPlan) {
    return plan.name.toLowerCase()?.includes("master tenant");
  }

  isDefault(plan: TenantPlan) {
    return plan.name.toLowerCase()?.includes("default");
  }

  isBasic(plan: TenantPlan) {
    return plan.name.toLocaleLowerCase()?.includes("basic");
  }

  changingToPlan = "";

  async changePlan(
    id: string,
    tenantId: string,
    stripeCustomer: IStripeCustomerDto
  ) {
    const newPlan = this.visiblePlans.find(p => p.id == id);

    if (!validateStripeCustomerBool(stripeCustomer)) {
      return popupService.showError(
        `Error selecting ${newPlan.name}`,
        "Please complete your billing details at Admin -> General -> Billing Details"
      );
    }

    if (newPlan.metadata.order > this.plan.metadata.order) {
      if (
        await popupService.showDialog(
          `Upgrading to ${newPlan.name}`,
          "Are you sure you want to select this plan."
        )
      ) {
        return await this.planChangeLogic(id, tenantId);
      }
    } else {
      if (
        await popupService.showDialog(
          "Plan downgrade warning",
          `Selecting this plan will be as soon as you confirm. You will be charged the full amount from the ${this.plan.name}, are you sure you want to select ${newPlan.name}`
        )
      ) {
        return await this.planChangeLogic(id, tenantId);
      }
    }
  }

  async planChangeLogic(id: string, tenantId: string) {
    try {
      this.changingToPlan = id;
      return await tenantService.changeStripePlan(id, tenantId);
    } catch (error) {
      if (error instanceof StripePaymentError) {
        popupService
          .showDialog(error.message, error.additionalMessage, "Add method")
          .then(answer =>
            answer ? usersService.openStripeCustomerPortal() : undefined
          );
      } else throw error;
    } finally {
      this.changingToPlan = "";
    }
  }

  get visiblePlans() {
    return this.plans
      .filter(
        p => !this.isCustom(p) && !this.isMasterTenant(p) && !this.isDefault(p)
      )
      .sort((x, y) => x.metadata.order - y.metadata.order);
  }

  isChangingToPlan(id: string) {
    return this.changingToPlan == id;
  }

  getBulletsFromMetadata(plan: TenantPlan) {
    return Object.keys(plan.metadata)
      .filter(key => key.includes("planFeature"))
      .sort(
        (a, b) =>
          Number(a.charAt(a.length - 1)) - Number(b.charAt(b.length - 1))
      );
  }

  getAvailableEngines(plan: TenantPlan) {
    return plan.metadata.allowedEngines
      ? plan.metadata.allowedEngines.toLowerCase().split("; ")
      : undefined;
  }

  getAllowedChannels(plan: TenantPlan) {
    return plan.metadata.allowedChannels
      ? plan.metadata.allowedChannels.toLowerCase().split("; ")
      : undefined;
  }

  getMaxChannels(plan: TenantPlan) {
    return plan.metadata.maxChannels;
  }

  isPriceNluPerformance(price: TenantPlanPrice) {
    return price.nickname.toLowerCase()?.includes("NLU Performance");
  }

  async changeNLU(
    plan: TenantPlan,
    stripeCustomer: IStripeCustomerDto,
    hasNluPerformance: boolean
  ) {
    if (hasNluPerformance) {
      if (!validateStripeCustomerBool(stripeCustomer)) {
        popupService.showError(
          `Error in enabling NLU Performance`,
          "Please complete your billing details at Admin -> General -> Billing Details"
        );
        return false;
      }
      if (
        await popupService.showDialog(
          plan.isInFreeTrial
            ? `Please note that your trial will end when you select NLU performance and you will be charged.`
            : "Please note you will be charged.",
          "Are you sure you want to proceed?"
        )
      )
        return await tenantService.enableNluPerformance();
      else return false;
    } else {
      return await tenantService.enableNluPerformance();
    }
  }

  async changePremiumChannels(
    stripeCustomer: IStripeCustomerDto,
    hasPremiumChannels: boolean
  ) {
    try {
      if (hasPremiumChannels) {
        if (!validateStripeCustomerBool(stripeCustomer)) {
          popupService.showError(
            `Error in enabling premium channels`,
            "Please complete your billing details at Admin -> General -> Billing Details"
          );
          return false;
        }
        return (await popupService.showDialog(
          "Please note you will be charged.",
          `If you switch on this toggle, you will be charged Euro 10 per month immediately for the premium channel bundle. <br>
          Once enabled, you can then select the following Premium Channels in your bot's Settings -> Configuration page:
          ${availableSettingsChannels
            .filter(ch => premiumChannels?.includes(ch.name))
            .map(ch => ` ${ch.name}`)}`
        ))
          ? await tenantService.enablePremiumChannels()
          : false;
      } else {
        return (await popupService.showDialog(
          "Disable premium channels",
          `Please note all premium channels will be switched off by the end of the month, unless you re-enable premium channels before that time.
        Please ensure your bot has at least one working non-premium channel.`
        ))
          ? await tenantService.enablePremiumChannels()
          : false;
      }
    } catch (error) {
      if (error instanceof StripePaymentError) {
        popupService
          .showDialog(error.message, error.additionalMessage, "Add method")
          .then(answer =>
            answer ? usersService.openStripeCustomerPortal() : undefined
          );
      } else throw error;
    }
  }

  async changePremiumEngines(
    stripeCustomer: IStripeCustomerDto,
    hasPremiumEngines: boolean
  ) {
    try {
      if (hasPremiumEngines) {
        if (!validateStripeCustomerBool(stripeCustomer)) {
          popupService.showError(
            `Error in enabling premium engines`,
            "Please complete your billing details at Admin -> General -> Billing Details"
          );
          return false;
        }
        return (await popupService.showDialog(
          "Please note you will be charged.",
          `If you switch on this toggle, you are then able to select Premium Engines like Microsoft Luis, Google DialogFlow and more in your bot's Settings -> Configuration page. <br>
          Please note that each premium Engine you select will be charged separately at Euro 29 per month per bot. <br>
          For example, if you select Google as Intent Recognizer and Microsoft as Entity Extractor for a bot, you will be charged Euro 2*29 = 58 per month for that bot. <br>
          If on the other hand you select Google as Intent Recognizer and as Entity Extractor for a bot, you will be charged Euro 1*29 = 29 per month for that bot.`,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        ))
          ? await tenantService.enablePremiumEngines()
          : false;
      } else {
        return (await popupService.showDialog(
          "Disable premium engines",
          `Please note your Engine will be switched automatically to Wit by the end of the month, unless you re-enble this toggle or manually deploy to Wit or Rasa yourself before that time.`
        ))
          ? await tenantService.enablePremiumEngines()
          : false;
      }
    } catch (error) {
      if (error instanceof StripePaymentError) {
        popupService
          .showDialog(error.message, error.additionalMessage, "Add method")
          .then(answer =>
            answer ? usersService.openStripeCustomerPortal() : undefined
          );
      } else throw error;
    }
  }
}
