<template>
  <div class="wrapper-inside general-settings active settings-wrapper">
    <div class="form-section">
      <h4>
        Handover Settings<Tooltip
          title="Only relevant if you configured a connector that provides live chat integration options in Settings -> Configuration -> Connectors"
          placement="right"
        ></Tooltip>
      </h4>

      <Toggle
        label="Handover activated"
        v-model="handoverSettings.isActive"
        :disabled="!hasSufficientPermission"
      ></Toggle>

      <FormGroup
        label="Opening and closing times"
        tooltip="Set the window when the bot is able to transfer the conversation to live-agents"
      >
        <input
          class="col-2"
          style="margin-right: 15px"
          type="time"
          v-model="handoverSettings.openingTime"
          :disabled="!hasSufficientPermission"
        />
        <input
          class="col-2"
          type="time"
          v-model="handoverSettings.closingTime"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <FormGroup
        label="Queue enter message"
        tooltip="Set the message once the user is in que"
      >
        <input
          type="text"
          v-model="handoverSettings.queueEnterMessage"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <FormGroup label="Live-agent chat start message">
        <input
          type="text"
          v-model="handoverSettings.chatStartMessage"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <FormGroup label="Live-agent end message">
        <input
          type="text"
          v-model="handoverSettings.chatEndMessage"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <FormGroup label="Live-agent not available message">
        <input
          type="text"
          v-model="handoverSettings.notAvailableMessage"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <!-- <FormGroup label="Idle timeout (seconds)">
            <input type="number" placeholder="90" min="10" v-model.number="handoverSettings.idleTimeout" :disabled="!hasSufficientPermission"/>
          </FormGroup> -->
    </div>

    <div class="form-section">
      <h4>Automatic Handover Settings</h4>

      <FormGroup label="Negative sentiment">
        <input
          type="number"
          placeholder="3"
          class="number-box"
          min="1"
          v-model.number="handoverSettings.negativeSentimentLimit"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <FormGroup label="Unrecognized intent">
        <input
          type="number"
          placeholder="3"
          class="number-box"
          min="1"
          v-model.number="handoverSettings.unrecognizedIntentLimit"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Toggle from "../../../../helpers/Toggle.vue";
import FormGroup from "../../../../helpers/FormGroup.vue";
import permissionsService from "../../../../../services/tenants/permissions.service";
import { Permissions } from "../../../../../../../common/enums/tenant/user-permissions.enum";
import Tooltip from "../../../../helpers/Tooltip.vue";

@Component({
  components: { Toggle, FormGroup, Tooltip },
})
export default class HandoverSettings extends Vue {
  @Prop() handoverSettings: any;
  @Prop() botName: string;

  private hasSufficientPermission = false;

  async beforeMount() {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageHandoverSettings
    );
  }
}
</script>

<style>
</style>
