import { render, staticRenderFns } from "./Bots.vue?vue&type=template&id=9549f96c&scoped=true"
import script from "./Bots.vue?vue&type=script&lang=ts"
export * from "./Bots.vue?vue&type=script&lang=ts"
import style0 from "./Bots.vue?vue&type=style&index=0&id=9549f96c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9549f96c",
  null
  
)

export default component.exports