<template>
  <div>
    <ConfigurationTable
      :type="'bot'"
      :data="botsData"
      :title="'Manage bots'"
      :description="'Manage which users can access which bots here'"
      :isDeleting="isDeleting"
      :isDataLoading="isDataLoading"
      @delete="deleteValue"
      @edit="$emit('edit', $event)"
    ></ConfigurationTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfigurationTable from '../../../helpers/ConfigurationTable.vue'

@Component({
  name: 'Bots',
  components: { ConfigurationTable }
})
export default class Bots extends Vue {
  @Prop({ default: () => [] }) botsData: any[];
  @Prop() isDeleting: boolean;
  @Prop() isDataLoading: boolean;

  deleteValue (eventArgs) {
    this.$emit('delete', eventArgs)
  }
}
</script>
