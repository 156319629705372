<template>
  <div v-if="show && hasBackDrop">
    <div class="backdrop" v-if="hasBackDrop"></div>
    <section class="modal" :class="{ 'small-modal': smallModal, [customClass]: customClass }" v-if="show">
      <header>
        <h1>{{ title }}</h1>
        <span class="close" @click="emitClose()"></span>
      </header>
      <section class="content">
        <slot></slot>
      </section>
      <footer v-if="hasFooter"></footer>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import preventBodyScrollMixin from '../_mixins/prevent-body-scroll'
import popupService from '@/services/popup.service'

@Component
export default class Modal extends Mixins(preventBodyScrollMixin) {
  @Prop() title: string
  @Prop({ type: Boolean, default: false }) show: boolean
  @Prop({ type: Boolean, default: false }) hasFooter: boolean
  @Prop({ type: Boolean, default: true }) hasBackDrop: boolean
  @Prop({ type: Boolean, default: false }) smallModal: boolean
  @Prop({ type: String, default: '' }) customClass: string

  created() {
    this.createEscapeEvent()
    this.toggleScroll(false)
  }

  destroyed() {
    this.toggleScroll(false)
  }

  async emitClose() {
    if (await popupService.showDialog('Confirmation', 'Closing this dialog will discard all changes. Are you sure you want to continue?', 'Yes', 'No')) {
      this.$emit('close')
    }
  }

  createEscapeEvent() {
    document.addEventListener('keyup', (evt) => {
      if (evt.keyCode === 27) {
        this.emitClose()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/setup/mixins';
@import '../../assets/scss/variables';
@import '../../assets/scss/components/containers.scss';

//Custom modals
.small-modal {
  @include center-absolute(500px, 45vh);
}

.modal-external-chat {
  @include center-absolute(500px, 55vh);
}

.modal-for-import {
  @include center-absolute(650px, 45vh);
}

.small-modal-for-import {
  @include center-absolute(460px, 33vh);
}

.import-intent-modal {
  @include center-absolute(900px, 85vh);
}

.salesforce-modal {
  @include center-absolute(75vw, 80vh);
}

.modal-create-bot {
  @include center-absolute(500px, 53vh);
}

.modal-create-bot-big {
  @include center-absolute(600px, 85vh);
}

.content {
  flex-grow: unset;
  overflow-x: hidden;
  max-height: 70vh;
  overflow-y: auto;
}

.dm-lock {
  max-height: 280px;
  overflow: visible;
}

.modal {
  height: unset;
}
</style>
