<template>
  <ConfigurationContainer>
    <div class="col-12">
      <div class="contents__main">
        <div class="main-header">
          <div class="main-header__inner">
            <h1>{{title}}</h1>
          </div>
          <p>{{description}}</p>
        </div>
        <SmallLoading v-if="isDataLoading"></SmallLoading>
        <div v-if="!isDataLoading">
        <div
          v-if="data && data.length > 0 && data[0].Name!='' && isTenantAdmin"
          class="table-overview"
        >
          <div class="table-overview__header">
            <div v-for="(col, index) in keys" :key="index">{{col}}</div>
          </div>
          <div fxLayout="row" v-for="(row, index) in data" :key="index">
             <div class="loading-parent">

               <SmallLoading v-if="isDeleting" :placement="'right'" v-show="row[keys[3]] == 'delete'"></SmallLoading>

               </div>
            <div class="table-rows">
              <div class="table-overview">
                <div class="table-overview__row">
                  <div class="table-text">{{row[keys[0]]}}</div>

                  <div
                    class="table-text__gray"
                    v-for="n in keys.length - 2"
                    :key="n"
                  >{{row[keys[n]]}}</div>
                  <div class="icons">
                    <i class="icon-edit" @click="edit(index)" :class="row['Action'] != 'myuser' ? 'table' : 'right'"></i>
                    <i
                      class="icon-delete table"
                      @click="deleteElement(index)"
                      :disabled="row['Action'] != '2'"
                      v-if="row['Action'] != 'myuser'"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="isTenantAdmin" class="no-data-text">No data to show</div>
        <h4
          v-else
        >You don't have the right permission for this action. Please contact your administrator.</h4>
      </div>
      <div v-if="isTenantAdmin && !isDataLoading && tableDataType == 'users' && showLogsButton" class="audit-logs">
        <SmallLoading v-if="areLogsDownloading" class="loader"></SmallLoading>
        <button :disabled="areLogsDownloading" type="button" class="btn btn--purple gradient btn-download" @click="downloadUsersAuditLogs">
            Download users audit logs
        </button>
      </div>
    </div>
    </div>
  </ConfigurationContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import popupService from '../../services/popup.service'
import rolesService from '../../services/roles.service'
import SmallLoading from './SmallLoading.vue'
import ConfigurationContainer from './ConfigurationContainer.vue'
import tenantService from '../../services/tenant.service'

@Component({ components: { SmallLoading, ConfigurationContainer } })
export default class ConfigurationTable extends Vue {
  @Prop() type: 'bot' | 'user' | 'tenant';
  @Prop({ default: [] }) readonly data: any[];
  @Prop({ default: '' }) readonly title: string;
  @Prop({ default: '' }) readonly description: string;
  @Prop({ default: '' }) readonly tableDataType: string;
  @Prop() isDataLoading: boolean;
  @Prop({}) isDeleting: boolean;
  @Prop() showLogsButton: boolean;
  private isTenantAdmin = false;

  private keys: string[] = [];

  async created () {
    this.loadKeys()

    if (this.type === 'bot') {
      this.isTenantAdmin = true
    } else {
      this.isTenantAdmin = await rolesService.isTenantAdmin()
    }
  }

  @Watch('data')
  loadKeys () {
    if (this.data && this.data.length > 0) { this.keys = Object.keys(this.data[0]) }
  }

  async deleteElement (index: number) {
    if (!this.isDeleting) {
      const answer = await popupService.showDialog(`Are you sure you want to delete this ${this.type}?`, '')

      if (answer) {
        this.data[index].Action = 'delete'
        this.$emit('delete', index)
      }
    }
  }

  edit (index: number) {
    if (!this.isDeleting) this.$emit('edit', index)
  }

  get areLogsDownloading () {
    return tenantService.areLogsDownloading
  }

  async downloadUsersAuditLogs () {
    await tenantService.downloadUsersAuditLogs()
  }
}
</script>

<style lang="scss" scoped>
@import "../admin/configuration/configuration-page-style.scss";

template {
  -webkit-font-smoothing: antialiased;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.icons {
  position: absolute;
  right: 1px;
}

.loading-parent{
  width: 90%;
  height: 43px;
  display: flex;
  position: absolute;
}

.table-rows {
  width: 100%;
  border: 1px solid $light-blue;
  background-color: $white;
  height: 43px;
  border-radius: 6px;
  padding-left: 10px;
  margin-top: 9px;
  font-size: 14px;
}

.table-text {
  color: $default-dark;
  font-weight: bold;

  &__gray {
    color: $default-dark;
    font-weight: 500;
    font-size: 13px;
  }
}

.no-data-text {
  font-size: 20px;
  font-weight: 600;
  color: $default-dark;
  text-align: center;
  padding-top: 300px;
}

.table-elements {
  &__row {
    display: flex;

    div {
      flex-shrink: 0;

      margin: 0;
    }
    button {
      flex-shrink: 0;
      min-width: 45px;
      font-size: 16px;
      color: black;
      margin: 0;
    }
  }
}
th {
  border-top: 0px solid $default;
}

table {
  table-layout: fixed;
  width: 100%;
}
td {
  width: 25%;
}

.contents {
  border-radius: 6px;
  overflow: hidden;
  padding: 30px 30px 110px;
  background-color: $default;

  .contents__main {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 270px);
    margin: 0;
    padding: 0;

    .main-header {
      position: relative;
      margin-bottom: 30px;

      &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      h1 {
        font-size: 27px;
        font-weight: 700;
        line-height: 50px;
        margin: 0;
      }
      p {
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        color: $secondary-text;
        line-height: 18px;
        margin: 8px 0 0;
      }
    }
  }

  .table-overview {
    &__header,
    &__row {
      display: flex;
      justify-content: flex-start;

      div {
        flex-shrink: 0;
        margin: 0;
      }
    }
    &__header {
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid $light-blue;

      div {
        font-weight: 700;
        line-height: 20px;
        &:not(:first-child):not(:last-child) {
          flex: 2.5;
          margin-left: 60px;
        }
        &:first-child {
          flex: 6;
          margin-right: 120px;
        }
        &:last-child {
          flex: 1;
        }
      }
    }
    &__row {
      margin-bottom: 20px;
      padding-bottom: 16px;
      padding-top: 1%;

      div {
        line-height: 20px;
        &:not(:first-child):not(:last-child) {
          flex: 2.5;
        }
        &:first-child {
          flex: 6;
          margin-right: 15px;
        }
      }
    }
  }
}

.audit-logs {
  margin-top: 20px;
  display: flex;

  .loader {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
