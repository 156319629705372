<template>
  <section class="content-intents content-intentsx" :key="currMonth">
    <div class="row">
      <div class="col col-9">
        <div class="rect rect--highlight">
          <div class="rect__title">
            <Controls
              :months="months"
              :currMonth="currMonth"
              :currYear="currYear"
              :isEnabled="isEnabled"
              :lastValues="lastValues"
              @currMonth="(month) => currMonth = month"
              @currYear="(year) => currYear = year"
              @currData="swapMonths"
              @getCurrMonth="handleCurrMonth"
              @getPrevMonth="handlePrevMonth"
              :key="currMonth"
            />
            <h1 v-if="isEnabled">
              <h1 v-if="isEnabled">{{ (months[currMonth - 1] + ' ' + currYear) }}</h1>
            </h1>
            <h1 v-else>Test Chat is not enabled.</h1>
          </div>
          <Interactions
            :month="months[currMonth - 1] + ' ' + currYear"
            :currMonth="{'interactions': currData.interactions, 'questionsAsked': currData.questionsAsked, 'questionsRecognized': currData.questionsRecognized, 'touchlessHandling': currData.touchlessHandling}"
            :prevMonth="{'interactions': prevData.interactions, 'questionsAsked': prevData.questionsAsked, 'questionsRecognized': prevData.questionsRecognized, 'touchlessHandling': prevData.touchlessHandling}"
            class="module interactions"
          />
        </div>
        <UserMetrics
          :sentiment="currData.sentiment"
          :satisfaction="currData.satisfaction"
          :retention="currData.retention"
          :month="months[currMonth - 1] + ' ' + currYear"
          class="module user-metrics"
        />
      </div>
      <div class="col col-3">
        <Users
          :users="currData.users"
          :chats="currData.chats"
          :seconds="currData.seconds"
          :month="months[currMonth - 1] + ' ' + currYear"
          class="module users"
        />
      </div>
    </div>
    <div v-if="hasNluPerformance" class="row">
      <div class="col col-6">
        <AnalyticsTable
          v-if="questionsPerNlu"
          :config="intentRecognitionConfig"
          :cells="questionsPerNlu"
        ></AnalyticsTable>
      </div>
      <div class="col col-6">
        <AnalyticsTable
          v-if="nlpPerformance"
          :config="nlpPerformanceConfig"
          :cells="nlpPerformance"
        ></AnalyticsTable>
      </div>
    </div>
    <div class="row fullwidth">
      <div class="col col-12">
        <UntrainedQuestions
          :utterances="utterances"
          :intentNames="intentNames"
          :month="months[currMonth - 1] + ' ' + currYear"
          :botName="botName"
          class="module untrained-questions"
          @addExample="addExample"
          @createIntent="createIntent"
          @delete-example="deleteExample"
        />
      </div>
    </div>
    <div class="row fullwidth">
      <div class="col col-12">
        <UnexpectedAnswer
          :data="unexpectedAnswerData"
          :intentNames="intentNames"
          :botName="botName"
          @deleteElement="deleteUnexpectedAnswerObject"
          @addExample="addExample"
          @createIntent="createIntent"
        />
      </div>
    </div>
    <div class="row">
      <div class="col col-6">
        <div class="module-left">
          <Channels
            :channels="currData.channels"
            :month="months[currMonth - 1] + ' ' + currYear"
            :snippets="snippets"
            class="module platform-status channels"
          />

          <Rating
            :all="currData.presentedRatings"
            :answered="currData.answeredRatingCards"
            :results="currData.ratingResults"
            class="module rating channels"
          />

          <Custom
            :examples="customWidget"
            :month="months[currMonth - 1] + ' ' + currYear"
            class="module custom-widgets"
          />

          <PlatformStatus
            v-if="serviceStatus"
            :data="serviceStatus"
            class="module platform-status"
          />

          <div class="col col-6"></div>
        </div>
      </div>
      <div class="col col-6">
        <AnalyticsTable :config="topIntentsConfig" :cells="topIntents"></AnalyticsTable>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'

import Interactions from '../../../analytics/Interactions.vue'
import PlatformStatus from '../../../analytics/PlatformStatus.vue'
import Rating from '../../../analytics/Rating.vue'
import UntrainedQuestions from '../../../analytics/UntrainedQuestions.vue'
import UserMetrics from '../../../analytics/UserMetrics.vue'
import Users from '../../../analytics/Users.vue'
import Channels from '../../../analytics/Channels.vue'
import Custom from '../../../analytics/Custom.vue'
import Controls from '../../../analytics/Controls.vue'
import UnexpectedAnswer from '../../../analytics/UnexpectedAnswer.vue'
import AnalyticsTable from '../../../analytics/AnalyticsTable.vue'

import { AnalyticsService } from '../../../../services/bots/analytics.service'
import { fix } from '../../../filters/number.filters'
import { months } from '../../../../../../common/constants/time.constant'
import statisticsService from '../../../../services/bots/statistics.service'
import { AnalyticsTableConfig } from '../../../../models/analytics-table.interface'
import { ExternalProvider } from '../../../../../../common/enums/external-provider.enum'
import { getKeyByValue } from '../../../../../../common/helpers/object.helper'

@Component({
  filters: {
    fix: fix
  },
  components: {
    Interactions,
    PlatformStatus,
    Rating,
    UntrainedQuestions,
    UserMetrics,
    Users,
    Channels,
    Custom,
    Controls,
    UnexpectedAnswer,
    AnalyticsTable
  }
})
export default class Analytics extends Vue {
  private currData: any = {};
  private prevData = {};
  private currMonth = 0;
  private months = months;
  private currYear = 0;
  private utterances = [];
  private unexpectedAnswerData = [];
  private intentNames = [];
  private serviceStatus = {};
  private isEnabled = true;
  private customWidget = [];
  private analyticsService: AnalyticsService;
  private lastValues = {};

  private intentRecognitionConfig: AnalyticsTableConfig = {
    name: 'Intent detection performance',
    headerCells: ['engine', 'amount', 'recognized %'],
    columnSettings: [
      { isStrong: true, sortType: 'string' },
      { sortType: 'number' },
      { isPercent: true, sortType: 'number' }
    ]
  };

  private nlpPerformanceConfig: AnalyticsTableConfig = {
    name: 'NLU model performance',
    headerCells: ['engine', 'trained %', 'untrained %'],
    columnSettings: [
      { isStrong: true, sortType: 'string' },
      { sortType: 'number', isPercent: true },
      { sortType: 'number', isPercent: true }
    ]
  };

  private topIntentsConfig: AnalyticsTableConfig = {
    name: 'Top Intents',
    headerCells: ['intent', 'triggered', 'percentage of total'],
    columnSettings: [
      { isStrong: true, sortType: 'string' },
      { sortType: 'number' },
      { isPercent: true }
    ],
    paginationSettings: { perPage: 9 }
  };

  @Prop({ default: '' }) readonly botName: string;
  @Prop() readonly hasNluPerformance: boolean;
  @Prop({ default: () => { return {} } }) readonly snippets: any;

  @Watch('botName')
  async onBotChange () {
    if (!this.botName) return
    this.$emit('loaded', false)

    try {
      this.analyticsService = new AnalyticsService(this.botName)
      const webWidget = await axios.get(
        `${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/web-widget`
      )
      this.isEnabled = webWidget.data.enabled

      if (this.isEnabled) await this.init()
      else this.$emit('loaded', true)
    } catch (err) {
      this.$emit('loaded', true)
    }
  }

  mounted () {
    return this.onBotChange()
  }

  async init () {
    await Promise.all([
      this.loadIntentNames(),
      this.loadUnrecognizedUtterances(),
      this.loadUnexpectedAnswers(),
      this.loadData(),
      this.loadCustomWidget(),
      this.loadServiceStatus(),
      this.loadLastValues()
    ])
  }

  async loadIntentNames () {
    this.intentNames = (await this.analyticsService.getIntentNames()).filter(name => name !== 'None')
  }

  async loadUnrecognizedUtterances () {
    this.utterances = await this.analyticsService.getUnrecognizedUtterances()
  }

  async loadUnexpectedAnswers () {
    this.unexpectedAnswerData = await statisticsService.getUnexpectedAnswerData(
      this.botName
    )
  }

  async loadData () {
    const data = await this.analyticsService.getData(
      this.currMonth,
      this.currYear
    )
    this.currMonth = data.currMonth
    this.currYear = data.currYear
    this.currData = data.currData

    await this.assignPrevMonthData(data.prevMonth, data.prevYear)
  }

  async loadCustomWidget () {
    this.customWidget = await this.analyticsService.arrangeModules()
  }

  async loadServiceStatus () {
    this.serviceStatus = await this.analyticsService.getServiceStatus()
  }

  async loadLastValues () {
    this.lastValues = await this.analyticsService.getBotLastStatisicPeriodValues()
  }

  get topIntents () {
    const intents = this.currData.popularIntents
    if (!intents) return null
    const count = intents.reduce((result, curr) => result + curr.count, 0)
    return intents.map(i => [i.name, i.count, (i.count / count) * 100])
  }

  get questionsPerNlu () {
    const perNlu = this.currData.questionsPerNlu
    if (!perNlu) return null
    this.intentRecognitionConfig.columnSettings[0].specialLabelRules = [
      {
        label: 'Primary',
        textToFind: getKeyByValue(
          ExternalProvider,
          this.currData.intentRecognizerProvider
        )
      }
    ]
    return Object.keys(perNlu).map(provider => [
      getKeyByValue(ExternalProvider, provider),
      perNlu[provider].asked,
      (perNlu[provider].recognized / perNlu[provider].asked) * 100
    ])
  }

  get nlpPerformance () {
    const trained = this.currData.trainedPerformance
    const untrained = this.currData.untrainedPerformance

    if (!trained || !untrained) return null
    this.nlpPerformanceConfig.columnSettings[0].specialLabelRules = [
      {
        label: 'Primary',
        textToFind: getKeyByValue(
          ExternalProvider,
          this.currData.intentRecognizerProvider
        )
      }
    ]
    return Object.keys(trained).map(provider => [
      getKeyByValue(ExternalProvider, provider),
      trained[provider],
      untrained[provider]
    ])
  }

  async deleteUnexpectedAnswerObject (
    data,
    currentPage: number,
    perPage: number,
    indexToRemove: number
  ) {
    await statisticsService.deleteUnexpectedAnswerObject(this.botName, data)
    this.unexpectedAnswerData.splice(
      (currentPage - 1) * perPage + indexToRemove,
      1
    )
  }

  swapMonths () {
    this.currData = this.prevData
  }

  async handleCurrMonth (params) {
    this.currData = await this.analyticsService.getMonth(params[0], params[1])
  }

  handlePrevMonth (params) {
    this.assignPrevMonthData(params[0], params[1])
  }

  addExample (data) {
    this.$emit('addExample', data)
  }

  deleteExample (id) {
    this.analyticsService.deleteUntrainedExample(id)
  }

  createIntent (data) {
    this.$emit('createIntentFromUntrained', data)
  }

  async assignPrevMonthData (month: string, year: string) {
    this.prevData = await this.analyticsService.getMonth(month, year)
    this.$emit('loaded', true)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../../../assets/scss/variables";
.content-intentsx {
  // padding: 30px;
  background-color: $blue-background;
  box-shadow: none;
}

.module-left {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.row + .row:not(.fullwidth) {
  margin-top: 30px;
}
.channels, .custom-widgets {
  margin-bottom: 30px;
}
.rect {
  &--highlight {
    color: white;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    padding: 30px 30px 50px;
    background-color: $default-dark;
    border-radius: 10px;
  }
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .container__date-wrapper + h1 {
      margin-left: 20px;
    }
    h1 {
      font-size: 28px;
      font-weight: 700;
      color: white;
      line-height: 28px;
      margin: 0;

      span {
        font-size: 14px;
      }
    }
  }
}
</style>
