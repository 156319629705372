<template>
  <Modal :title="`${provider} Text-to-speech Settings`" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <div class="form-group">
        <div class="form-group--inside">
          <label>Language</label>
          <Select :elements="languages" keyAttr="0" valueAttr="1" v-model="languageCode" class="white-select"></Select>
        </div>
        <div v-if="!languageCode" class="invalid">Please select a language</div>

        <div class="form-group--inside">
          <label>Preffered voice type</label>
          <Select :elements="voiceTypes" keyAttr="0" valueAttr="1" v-model="voice" class="white-select"></Select>
        </div>

        <div v-show="!!speed" class="form-group--inside">
          <label>Speed</label><br />
          <input type="range" :value="speed" :min="speedMin" :max="speedMax" :step="speedStep" @change="changeSpeed" />
          {{ speed }}
        </div>

        <div v-show="!!responseFormat" class="form-group--inside">
          <label>Format</label>
          <Select :elements="responseFormats" keyAttr="0" valueAttr="1" v-model="responseFormat" class="white-select"></Select>
        </div>

        <div class="form-group--inside">
          <label>Silence until idle speech.</label><br />
          <input type="range" :value="idleTimeout" :min="0.5" :max="10" :step="0.1" @change="changeIdleTimeout" />
          {{ idleTimeout }} seconds
        </div>

        <div class="form-group--inside">
          <label>Random idle phrases to fill silence. Separate with enter.</label><br />
          <textarea :value="idleText" @change="changeIdleText"></textarea>
        </div>

        <div v-show="provider === ExternalProvider.OpenAI" class="form-group--inside">
          <Toggle label="use HD" v-model="hd"></Toggle>
        </div>
      </div>
      <button class="btn btn--purple gradient" type="button" @click="save()">Save settings <i class="icon icon-save"></i></button>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import { ttsLanguages } from '../../../../../../../../common/constants/engines/tts-languages.constant'
import { ExternalProvider } from '../../../../../../../../common/enums/external-provider.enum'
import { IBotSettings } from '../../../../../../../../common/interfaces/bot-settings.interface'
import { OpenAIreponseFormats, OpenAIVoices } from '../../../../../../../../common/enums/openai/openai-tts.language.enum'
import { defaultBotSettings } from '../../../../../../../../common/constants/default-bot-settings'

@Component({
  computed: {
    ExternalProvider() {
      return ExternalProvider
    }
  },
  components: {
    FormGroup,
    Autocomplete,
    Select,
    Toggle,
    Modal
  }
})
export default class TTSConfiguration extends Vue {
  @Prop({ default: ExternalProvider.Microsoft }) provider: ExternalProvider
  @Prop({ default: '' }) languageCode: string
  @Prop({ default: '' }) voice: string
  @Prop({ default: null }) speed: number
  @Prop({ default: null }) responseFormat: string
  @Prop() settings: Partial<IBotSettings>

  voiceTypes = [
    ['Male', 'm'],
    ['Female', 'f']
  ]

  responseFormats = null

  speedMin: number
  speedMax: number
  speedStep: number

  idleTimeout: number
  idleText: string

  hd: boolean

  constructor() {
    super()
    if (this.provider === ExternalProvider.OpenAI) {
      // OpenAI has voicesTypes other than male/female. https://platform.openai.com/docs/api-reference/audio/createSpeech
      this.voiceTypes = OpenAIVoices

      // OpenAI can set speed
      if (!this.speed) {
        this.speed = 1
      }
      this.speedMin = 0.3
      this.speedMax = 4.0
      this.speedStep = 0.1

      // OpenAI can set Response format
      this.responseFormats = OpenAIreponseFormats
      if (!this.responseFormat) {
        this.responseFormat = 'mp3'
      }

      // See OpenAITextToSpeechService
      this.hd = this.settings.textToSpeechModel === 'tts-1-hd'
    } else {
      // Hide values when switching to a provider that doesn't support them.
      this.speed = null
      this.responseFormat = null
    }

    // Copy settings
    this.idleTimeout = this.settings.idleTimeout ?? defaultBotSettings.idleTimeout
    this.idleText = this.settings.idleText ?? defaultBotSettings.idleText
  }

  get languages() {
    return Object.entries(ttsLanguages[this.provider] || {})
  }

  save() {
    if (this.languageCode) {
      const data: Partial<IBotSettings> = {
        textToSpeechLanguageCode: this.languageCode,
        textToSpeechPreferredVoiceType: this.voice
      }
      if (this.speed) {
        data.textToSpeechSpeed = this.speed
      }
      if (this.responseFormat) {
        data.textToSpeechResponseFormat = this.responseFormat
      }
      data.idleTimeout = this.idleTimeout
      data.idleText = this.idleText

      if (this.provider === ExternalProvider.OpenAI) {
        // See OpenAITextToSpeechService. The only TTS that uses 'model'
        data.textToSpeechModel = this.hd ? 'tts-1-hd' : 'tts-1'
      }

      // eslint-disable-next-line no-console
      this.$emit('save', data) // --> Configuration.save --> Settings.saveSettings --> onFormSubmit --> ConfigurationManagementMixin.updateConfiguration
      this.$emit('close')
    }
  }

  changeSpeed(e: InputEvent) {
    this.speed = parseFloat((e.target as HTMLInputElement).value)
  }

  changeIdleTimeout(e: InputEvent) {
    this.idleTimeout = parseFloat((e.target as HTMLInputElement).value)
  }

  changeIdleText(e: InputEvent) {
    this.idleText = (e.target as HTMLInputElement).value
  }
}
</script>
