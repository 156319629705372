import { Component, Mixins } from 'vue-property-decorator'

import luisManagementMixin from './configuration/luis-management.mixin'
import watsonAssistantManagementMixin from './configuration/watson-assistant-management.mixin'
import dialogflowManagementMixin from './configuration/dialogflow-management.mixin'
import alexaManagementMixin from './configuration/alexa-management.mixin'
import googleHomeManagementMixin from './configuration/google-home-management.mixin'
import twilioManagementMixin from './configuration/twilio-management.mixin'
import bacrApiManagementMixin from './configuration/bcr-api-management.mixin'
import salesforceManagementMixin from './configuration/salesforce-management.mixin'
import rasaManagementMixin from './configuration/rasa-management.mixin'
import facebookManagementMixin from './configuration/facebook-management.mixin'
import teamsManagementMixin from './configuration/teams-management.mixin'
import slackManagementMixin from './configuration/slack-management.mixin'
import telegramManagementMixin from './configuration/telegram-management.mixin'

import { Engine } from '../interfaces/engine'
import { ConfigurationService } from '@/services/bots/settings/configuration.service'
import externalApiManagementMixin from './configuration/external-api-management.mixin'
import mailChannelManagementMixin from './configuration/mail-management.mixin'
import nuanceManagementMixin from './configuration/nuance-management.mixin'
import { BotConnectionEnum } from '../../../../../../../common/enums/bot/bot-connection.enum'
import popupService from '@/services/popup.service'
import { ttsNames } from '../../../../../../../common/constants/tts-names.constant'
import { ExternalProvider } from '../../../../../../../common/enums/external-provider.enum'
import { sttNames } from '../../../../../../../common/constants/stt-names.constant'
import { nluNames } from '../../../../../../../common/constants/nlu-names.constant'

/** The Mixins ctor supports a maximum of 5 arguments */
@Component
class MixinsA extends Mixins(luisManagementMixin, watsonAssistantManagementMixin, dialogflowManagementMixin, alexaManagementMixin, googleHomeManagementMixin) {}

@Component
class MixinsB extends Mixins(twilioManagementMixin, salesforceManagementMixin, rasaManagementMixin, bacrApiManagementMixin, externalApiManagementMixin) {}

@Component
class MixinsC extends Mixins(facebookManagementMixin, teamsManagementMixin, slackManagementMixin, mailChannelManagementMixin, nuanceManagementMixin) {}

@Component
class MixinsD extends Mixins(
  telegramManagementMixin
  // Add more mixins here
) {}

/** @description manages the different channels/engines/systems SETTINGS */
@Component
export default class BotConfigurationManagementMixin extends Mixins(MixinsA, MixinsB, MixinsC, MixinsD) {
  botName: string

  engines: Engine[]
  systems: any[]
  channels: any[]

  currentTab = ''
  // show default tab
  showDefault = false

  keys: any = {}

  externalApiConversationIdLifeTime: number = 0

  requestedConnectionName = ''

  openSettings(connectionName: string) {
    this.keys = {}
    switch (connectionName) {
      case nluNames.MICROSOFT:
        this.initLuis(this.botName)
        break
      case nluNames.IBM:
        this.initWatsonAssistant(this.botName)
        break
      case nluNames.GOOGLE:
        this.initDialogflow(this.botName)
        break
      case nluNames.RASA:
        this.initRasa(this.botName)
        break
      case nluNames.NUANCE:
        this.initNuance(this.botName)
        break
      case nluNames.AMAZON:
        this.initAlexa(this.botName)
        break

      // nluNames.MICROSOFT_CLU has no settings
      // nluNames.WIT has no settings
      // nluNames.NEURALSPACE has no settings

      case 'Google Assistant':
        this.initGoogleHome(this.botName)
        break
      case 'Twilio Voice':
      case BotConnectionEnum.TwilioMessages:
        this.initTwilio(this.botName)
        break
      case 'Facebook':
        this.initFacebook(this.botName)
        break
      case 'Microsoft Teams':
        this.initTeams(this.botName)
        break
      // edge case, Email channel renamed
      case 'Office 365':
        this.initMailChannel(this.botName)
        break
      case 'Email':
        this.initMailChannel(this.botName)
        break
      case 'Slack':
        this.initSlack(this.botName)
        break
      case 'Telegram':
        this.initTelegram(this.botName)
        break
      case 'Salesforce Agent':
        this.initSalesforce(this.botName)
        break
      case 'API':
        this.initBcrApi(this.botName)
        this.initExternalApi(this.botName)
        break
      case 'Chat Bubble':
        this.$router.push({
          name: 'BotSettings',
          params: {
            botName: this.botName,
            settingsTab: 'bubble'
          }
        })
        break
      case ttsNames.OPEN_AI:
      case ttsNames.MICROSOFT:
      case ttsNames.GOOGLE:
      case ttsNames.IBM:
      case ttsNames.AMAZON:
      case sttNames.OPEN_AI:
      case sttNames.GOOGLE:
      case sttNames.MICROSOFT:
        // These have their own modal. Don't show default.
        break
      default:
        this.showDefault = true
    }
    this.currentTab = connectionName
  }

  closeSettings() {
    this.currentTab = ''
    this.showDefault = false
  }

  async requestConnection(request: string, item: string) {
    const userWantsToConnect = await popupService.showDialog(
      `Request connection to ${item}`,
      request === 'channel'
        ? 'Connecting with this channel requires a custom integration and/or third-party services. Please request a connect for more information about the steps and pricing.'
        : 'Connecting with this connector requires creating a custom integration via the Blits support team or a higher subscription tier. Use our function builder to create your own integration or request a connect for more information about the steps and pricing.'
    )
    if (userWantsToConnect) {
      const configurationService = new ConfigurationService(this.botName)
      await configurationService.requestConnection(request, item)
      this.requestedConnectionName = item
      popupService.showInfo('Connection request sent successfully')
    }
  }

  async updateNlp(action?: string) {
    switch (this.currentTab) {
      case 'Microsoft LUIS':
        switch (action) {
          case 'delete':
            await this.deleteLuisApp()
            break
          case 'configure':
            await this.configureLuisApp()
            break
          case 'update':
            await this.updateLuisKeys()
            break
        }
        break
      case 'IBM Watson':
        switch (action) {
          case 'delete':
            await this.deleteWatson()
            break
          case 'configure':
            await this.configureWatson()
            break
          case 'update':
            await this.updateWatsonAssistantKeys()
            break
        }
        break
      case 'Rasa':
        switch (action) {
          case 'create':
            await this.createRasaServer()
            break
          case 'delete':
            await this.deleteRasaServer()
            break
        }
    }
    this.$emit('update')
  }
}
