import authService from '@/services/auth.service'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AuthManagementMixin extends Vue {
  private emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  async checkAuth() {
    if (await authService.isAuthenticated()) this.moveToBots()
  }

  routerCheck() {
    if (['ForgotPassword', 'LogIn', 'SignUp', 'PasswordReset'].includes(this.$router.currentRoute.name)) {
      document.querySelector('body').classList.add('image-background')
    } else {
      this.removeBackground()
    }
  }

  removeBackground() {
    document.querySelector('body').classList.remove('image-background')
  }

  moveToBots() {
    this.$router.push('bots')
  }

  matchEmail(email: string) {
    return this.emailRegex.test(email)
  }
}
