import axios from 'axios'
import { BpmnManagementService } from '@/components/admin/flow-editor/modules/bpmn-management.service'

export abstract class FlowServiceBase {
  decompressFlow(compressedFlow: any) {
    return axios.put(`${process.env.VUE_APP_BACKEND_URL}/flow/for-decompression`, compressedFlow, { responseType: 'text' }).then(r => r.data.xml as string)
  }

  compressFlow(xml: string) {
    return axios
      .put(
        `${process.env.VUE_APP_BACKEND_URL}/flow/for-compression`,
        { xml: encodeURI(xml) },
        { responseType: 'blob' } // download the json as file => blob response type
      )
      .then(r => r.data as string)
  }

  async validateImportFlow(bpmn: BpmnManagementService, xml: string) {
    await bpmn.loadBpmnXml(xml)

    const missingFunctions = bpmn.blockStorage
      .allComponents()
      .filter(block => block.type === 'bpmn:Task' && !block.functionSettings)
      .map(block => block.customData.fc)

    if (missingFunctions.length > 0) {
      throw new Error('Warning! Flow contains custom functions which need to be available:\n' + missingFunctions.map(f => ' - ' + f).join('\n'))
    }
  }

  abstract saveFlow(containerName: string, itemName: string, xml: string, status?: string): Promise<any>

  abstract saveAutoFlow(containerName: string, itemName: string, xml: string): Promise<any>

  abstract savePublishedVersion(containerName: string, itemName: string, xml: string, versionName: string, ignoreWarnings?: boolean): Promise<any>

  abstract saveTestVersion(containerName: string, itemName: string, xml: string, versionName: string, ignoreWarnings?: boolean): Promise<any>

  abstract validateFlow(botName: string, intentName: string, xml: string, ignoreWarnings?: boolean): Promise<any>

  async setContent(containerName: string, item: any) {
    await this._setContent(containerName, item)
    item.content.xmlArray = item.content.xmlArray.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime())
  }

  protected abstract _setContent(containerName: string, item: any): Promise<void>

  abstract uploadFile(containerName: string, formData: FormData): Promise<{ fileId: string; fileExtension: string; contentType: string }>

  abstract downloadFile(botName: string, fileId: string, fileName: string): Promise<Blob>
}
