<template>
  <div>
    <ConfigurationTable
      :type="'user'"
      :data="usersData"
      :title="'Manage users'"
      :description="'Manage individual users'"
      :isDeleting="isDeleting"
      :isDataLoading="isDataLoading"
      :tableDataType="'users'"
      :showLogsButton="showLogsButton"
      @delete="deleteValue"
      @edit="$emit('edit', $event)"
    ></ConfigurationTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfigurationTable from '../../../helpers/ConfigurationTable.vue'

@Component({
  name: 'Users',
  components: { ConfigurationTable }
})
export default class Users extends Vue {
  @Prop({ default: [] }) usersData: any[];
  @Prop() isDeleting: boolean;
  @Prop() isDataLoading: boolean;
  @Prop() showLogsButton: boolean;

  async deleteValue (eventArgs) {
    this.$emit('delete', eventArgs)
  }
}
</script>
