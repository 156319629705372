<template>
  <div>
    <div class="container">
      <div class="container--content">
        <div class="contents content-align">
          <div class="row">
            <div class="col-12 padlen">
              <div class="contents__main">
                <div class="main-header">
                  <div class="main-header__inner">
                    <h1>Manage roles & permissions</h1>
                  </div>
                  <p>Manage roles and permisisons for your account here</p>
                </div>
                <SmallLoading v-if="isDataLoading"></SmallLoading>
                <div v-if="!isDataLoading">
                  <div v-if="isTenantAdmin" class="conf-roles-wrapper">
                    <div class="conf-roles-scroller" ref="rolesScroller">
                      <table class="conf-roles-table" ref="table" v-if="roles.length > 0 && permissions.length > 0">
                        <thead>
                          <tr class="table-text">
                            <th class="conf-roles-sticky-col table-text__gray">Action</th>
                            <th v-for="role in roles" :key="role.roleId">
                              {{ role.roleName }}

                              <i class="icon-delete table roles" v-if="roleCanBeDeleted(role.roleName)" @click="deleteRole(role.roleId)"></i>
                            </th>

                            <th id="new-role">
                              <button class="dashed-line-button" v-if="!addNewRoleClicked" @click="addNewRole()">
                                <i class="plus"></i>
                              </button>

                              <form v-if="addNewRoleClicked" @submit.prevent="addNewRole()" style="display: flex; width:auto; min-width: 250px;">
                                <input id="new-role-input" type="text" class="form-control" placeholder="Role name" v-model="newRoleText" />

                                <button class="add-button">
                                  <i class="plus"></i>
                                </button>
                                <!-- <div
                                class="error-text"
                                v-if="roleTextError"
                              >Plese enter valid role name</div>-->
                              </form>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="permission in permissions" :key="permission.key">
                            <td class="conf-roles-sticky-col">{{ permission.value }}</td>

                            <td v-for="r in roles" :key="r.roleId">
                              <button
                                v-if="checkIfContains(permission.key, r.permissions)"
                                :disabled="Number(r.roleId) == 0"
                                class="button-with-tick"
                                @click="addPermissionToRole(r.roleId, permission.key, true)"
                              >
                                <i class="tick"></i>
                              </button>
                              <button
                                v-else
                                :disabled="Number(r.roleId) == 0"
                                class="button-with-tick"
                                @click="addPermissionToRole(r.roleId, permission.key, false)"
                              ></button>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div style="display: flex; float: right; padding-top: 40px;">
                      <button class="btn btn--purple gradient" type="button" @click="changesRolesState()"><i class="icon icon-save"></i>Save changes</button>
                    </div>
                  </div>
                  <h4 v-else>You don't have the right permission for this action. Please contact your administrator.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import rolesService from '../../../../services/roles.service'
import { RoleViewModel } from '../models/role.view.model'
import SmallLoading from '../../../helpers/SmallLoading.vue'

@Component({ name: 'UserRoles', components: { SmallLoading } })
export default class UserRoles extends Vue {
  @Prop({ default: [] }) readonly roles: RoleViewModel[]
  @Prop({ default: [] }) readonly permissions: { key: number; value: string }[]
  @Prop() isDataLoading: boolean

  private isTenantAdmin = false

  private addNewRoleClicked = false
  private newRoleText = ''
  private roleTextError = false
  private rolesToEdit: RoleViewModel[] = []

  private addPermissionToRole(roleId: string, permissionId: string, remove: boolean) {
    const role = this.roles.find(r => String(r.roleId) == roleId)
    if (remove) {
      role.permissions = role.permissions.filter(p => p != permissionId)
    } else {
      role.permissions.push(permissionId)
      this.rolesToEdit = this.roles
    }
  }

  async created() {
    try {
      this.isTenantAdmin = await rolesService.isTenantAdmin()
    } catch (e) {
      this.isTenantAdmin = false
    }
  }

  @Emit('changeRolesState')
  changesRolesState() {
    const result: { [roleId: number]: RoleViewModel } = {}
    for (const role of this.roles) {
      if (role.roleId != 0) {
        result[String(role.roleId)] = role
      }
    }

    return result
  }

  @Watch('newRoleText')
  onChange() {
    const regex = /^[^-\s](.*\s?)*\s*(\w+)$/gm
    if (String(this.newRoleText).trim() != '' && !regex.exec(this.newRoleText)) {
      this.roleTextError = true
      document.querySelector('#new-role-input').classList.add('red-border-input')
    } else {
      document.querySelector('#new-role-input').classList.remove('red-border-input')
      this.roleTextError = false
    }
  }

  @Emit('addNewRole')
  emitAddNewRole() {
    return {
      newRole: this.newRoleText
    }
  }

  @Emit('deleteRole')
  deleteRole(roleId: string) {
    return roleId
  }

  roleCanBeDeleted(roleName: string) {
    if (roleName != 'Designer' && roleName != 'Contributor' && roleName != 'Maintainer' && roleName != 'Administrator' && roleName != 'Account administrator') {
      return true
    } else {
      return false
    }
  }

  addNewRole() {
    if (!this.roleTextError) {
      this.emitAddNewRole()
    }
    this.newRoleText = ''
    this.addNewRoleClicked = !this.addNewRoleClicked
    // JS scrolling haks
    setTimeout(() => ((this.$refs.rolesScroller as HTMLElement).scrollLeft += 1000), 10)
  }

  // Check if permissions contains permission
  checkIfContains(permission: string, permissions: string[]) {
    if (permissions) return permissions.includes(permission)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/components/_roles.scss';
@import '../configuration-page-style.scss';
@import '../../../../assets/scss/variables';
.error-text {
  color: $danger;
  padding-top: 9px;
  padding-left: 9px;
}

.red-border-input {
  border: 2px solid $danger;
}

.padlen {
  margin-left: 0px;
}
</style>
