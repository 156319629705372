<template>
  <Modal :title="tenant.displayName" :show="true" @close="$emit('close')">
    <div class="form--wrapper">
      <h3>Subscription Plan</h3>
      <p>
        Your current plan: <b> {{ getPlanName(plan) }} </b>
      </p>
      <p>
        Total interactions {{ plan.hasAdditionalInteractionsCost ? 'incl.' : undefined }} this month:
        <b>{{ getUsageAmount(interactions, plan.maxFreeInteractions, plan.hasAdditionalInteractionsCost) }}</b>
      </p>
      <p>
        Total seats: <b>{{ getUsageAmount(users, plan.maxFreeUsers, plan.hasAdditionalUsersCost) }}</b>
      </p>
      <p>
        Total bots: <b>{{ getUsageAmount(bots, plan.maxFreeBots, plan.hasAdditionalBotsCost) }}</b>
      </p>

      <Select
        :showDefault="false"
        :showToggle="false"
        :disabled="isCustom(plan) || isMasterTenant(plan)"
        :elements="visiblePlans"
        :key="generateSelectorKey()"
        keyAttr="name"
        valueAttr="id"
        :value="tenant.planId"
        @input="selectPlan"
        class="white-select"
      ></Select>

      <div class="loading">
        <SmallLoading v-if="changingToPlan"></SmallLoading>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import subscriptionMixin from '../_mixins/subscription.mixin'
import Toggle from '../../../helpers/Toggle.vue'
import Modal from '../../../helpers/Modal.vue'
import Tooltip from '../../../helpers/Tooltip.vue'
import { ITenant } from '../../../../../../common/interfaces/tenant/tenant.interface'
import { TenantPlan } from '../../../../../../common/interfaces/plans/tenant-plan.interface'
import Select from '../../../helpers/Select.vue'
import SmallLoading from '../../../helpers/SmallLoading.vue'
import { IStripeCustomerDto } from '../../../../../../common/interfaces/stripe-customer-dto.interface'

@Component({
  components: {
    Toggle,
    Tooltip,
    Modal,
    Select,
    SmallLoading
  }
})
export default class MasterEditTenant extends Mixins(subscriptionMixin) {
  @Prop({ default: () => [] }) plans: TenantPlan[] = []
  @Prop({ default: () => ({}) }) tenant: ITenant & { usage: any }
  @Prop() users: number
  @Prop() bots: number
  @Prop() interactions: number
  @Prop() stripeCustomer: IStripeCustomerDto

  plan: TenantPlan = null
  @Watch('tenant.planId', { deep: true, immediate: true })
  onTenantPlanIdChanged() {
    this.plan = this.plans.find(p => p.id == this.tenant.planId)
  }

  planSelectorKey = 0
  generateSelectorKey() {
    return `${this.tenant.planId}-${this.planSelectorKey}`
  }

  async selectPlan(id: string) {
    const newPlan = await this.changePlan(id, this.tenant.id, this.stripeCustomer)
    if (newPlan) {
      this.$set(this.tenant, 'planId', newPlan.id)
    }
    this.planSelectorKey++
  }
}
</script>

<style lang="scss" scoped>
@import '../configuration-page-style.scss';

.loading {
  margin-top: 20px;
  float: right;
}
</style>
