import axios from 'axios'

export class FunctionsService {
  functionsUrl (containerName) {
    return `${process.env.VUE_APP_BACKEND_URL}/bots/${containerName}/functions`
  }

  /**
   * Get all functions
   * @param {string} containerName - bot
   */
  async getAll (containerName) {
    return await axios
      .get(this.functionsUrl(containerName))
      .then(response => response.data)
  }

  /**
   * Creates a function in a bot
   * @param {string} containerName - bot
   * @param {*} functionData
   */
  create (containerName, functionData) {
    return axios
      .post(this.functionsUrl(containerName), functionData.newFunction)
      .then(response => response.data)
  }

  /**
   * Updates a function in a bot
   * @param {string} containerName - bot
   * @param {*} functionData
   */
  async update (containerName, functionData) {
    await axios.put(
      `${this.functionsUrl(containerName)}/${functionData.name}`,
      functionData
    )
  }

  /**
   * Export a function in a bot
   * @param {string} containerName - bot
   * @param {*} functionData
   */
  async exportFunction (containerName, functionData) {
    const res = await axios
      .get(`${this.functionsUrl(containerName)}/${functionData.name}/export`,
        { responseType: 'blob' })

    return res.data
  }

  /**
  * Import a function in a bot
  * @param {File}
  * @param {string} containerName - bot
  */
  async importFunction (containerName, file, funcName, isGlobal) {
    const form = new FormData()
    form.append('function-file', file, file.name)

    const res = await axios
      .post(`${this.functionsUrl(containerName)}/${funcName}/import/${isGlobal}`, form)

    return res.data
  }

  /**
  * Gets the output of a function in a bot
  * @param {string} containerName - bot
  * @param {string} functionName
  */
  async getOutput (containerName, functionName, data) {
    const res = await axios
      .post(`${this.functionsUrl(containerName)}/${functionName}/output`, { flowFunction: data })

    return res.data
  }

  validateImport (file) {
    const formData = new FormData()
    formData.append('function-file', file, file.name)

    return axios
      .post(`${process.env.VUE_APP_BACKEND_URL}/functions/import/staging`, formData)
      .then(res => res.data)
  }

  /**
 * Deletes a function in a bot
 * @param {string} containerName - bot
 * @param {string} functionName
 */
  async delete (containerName, functionName) {
    await axios.delete(`${this.functionsUrl(containerName)}/${functionName}`)
  }

  async getFunctionsUsages (containerName, functionName: string) {
    return await axios.get(`${this.functionsUrl(containerName)}/function-usages/${functionName}`).then(response => response.data)
  }

  async getFunctionsUsagesAllBots (functionName: string) {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/functions/function-usages/global/${functionName}`).then(response => response.data)
  }
}

export default new FunctionsService()
