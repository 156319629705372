
/** Any error that is thrown by our own application code (not library or external code) */
export class BlitsError extends Error {
    constructor(
        /** Error code for determining the type of error */
        public code: string,
        /** The summarized message that can be returned/visualized */
        public message: string,
        /**
         * The internal (detailed) message - it may contain technical data that is only useful for internal debugging / logging
         * It's also returned/visualized. If we ever want to hide it, let's filter out internal messages containing 'secret'. See app.ts
         **/
        public internalMessage?: string
    ) {
        super(message);
    }

    /** Exports the non-internal/public information for the error as JSON */
    toJSON(defaultMessage?: string): any {
        const { stack, internalMessage, ...safeData } = this;
        return { ...safeData, message: this.message || defaultMessage };
    }

    /** Converts a standard error to an internal error - the aim of this is to hide error details behind a `publicMessage` */
    static fromError(err: any, publicMessage = "An error occured on the server side") {
        return this.createInternal(publicMessage, err.message || err.body?.code);
    }

    static createInternal(message: string, internalMessage?: string) {
        return new BlitsError("internal_error", message, internalMessage);
    }


}