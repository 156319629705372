<template>
  <Modal title="Create a new bot" :show="true" @close="$emit('close')" :customClass="!createType ? 'modal-create-bot' : 'modal-create-bot-big'">
    <div class="modal-description">
      {{ createType ? 'Please choose an example chatbot from one of the templates, and give your bot a name.' : 'Select how you want your new bot to start.' }}
    </div>
    <section class="content">
      <div class="form--wrapper">
        <form @submit.prevent="createBot">
          <div class="form-group">
            <div class="select-type" v-if="!createType">
              <div class="buttons">
                <button type="button" class="btn btn--white" @click="createType = 'scratch'">
                  <i class="icon icon-scratch"></i>
                  <h6>Start from scratch</h6>
                </button>
                <button type="button" class="btn btn--white" @click="createType = 'template'">
                  <i class="icon icon-template"></i>
                  <h6>Use starter Template</h6>
                </button>
              </div>
            </div>
            <div v-else>
              <div v-if="createType == 'template'" class="form-group">
                <div class="form-group--inside">
                  <label for="botTemplate">Bot Template</label>
                  <Select :elements="templateNames" v-model="template" class="white-select"></Select>
                </div>
                <div v-show="templateError" class="invalid">Please select a bot template</div>
              </div>

              <div class="form-group">
                <label for="botName">Name of your bot</label>
                <input type="text" placeholder="For instance: Finance bot - ENG" id="botName" v-model="botForm.name" />
                <div v-if="nameError" class="invalid">
                  Please provide a valid bot name. Bot name can only have the following characters: -, a-z, A-Z, 0-9, _ and whitespace.
                </div>
              </div>

              <div class="toggle-wrapper">
                <Toggle label="Change bot region" :tooltip="botRegionTooltip" v-model="showBotRegionSelector"></Toggle>

                <div class="form-group" v-if="showBotRegionSelector">
                  <div class="form-group--inside">
                    <label for="botTemplate">Bot Region</label>
                    <Select :elements="regions" :key="botForm.region" v-model="botForm.region" keyAttr="0" valueAttr="1" class="white-select"></Select>
                  </div>
                  <div v-show="regionError" class="invalid">Please select a bot region</div>
                </div>

                <Toggle label="Blits Automate" :tooltip="nluAutomateTooltip" v-model="botForm.nluAutomate"></Toggle>
              </div>

              <div v-if="createType === 'scratch' && recognizers" v-show="!botForm.nluAutomate" class="form-group">
                <div class="form-group--inside">
                  <label for="botName">Initial Intent &amp; Entity Recognizer</label>

                  <Select
                    :elements="recognizers.filter((r) => r[1] !== 'Microsoft LUIS')"
                    :key="botForm.recognizer"
                    keyAttr="1"
                    valueAttr="0"
                    v-model="botForm.recognizer"
                    class="white-select"
                  ></Select>
                </div>
                <div v-show="recognizerError" class="invalid">Please select an intent recognizer</div>
              </div>

              <div v-if="createType === 'scratch'" class="form-group">
                <div class="form-group--inside">
                  <label for="botName">Language</label>
                  <Select
                    :elements="languages"
                    :key="`${botForm.language}${langKey}`"
                    keyAttr="0"
                    valueAttr="1"
                    v-model="botForm.language"
                    class="white-select"
                  ></Select>
                </div>
                <div v-show="languageError" class="invalid">Please select a language</div>
              </div>

              <div class="bottom-right-fix">
                <SmallLoading v-show="creating"></SmallLoading>
                <button class="btn btn--purple gradient" type="submit">
                  {{ creating ? 'Creating...' : 'Create a bot' }}
                  <i class="icon icon-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator'
import SmallLoading from '../../helpers/SmallLoading.vue'
import Tooltip from '../../helpers/Tooltip.vue'
import Select from '../../helpers/Select.vue'
import Modal from '../../helpers/Modal.vue'
import Toggle from '../../helpers/Toggle.vue'

import axios from 'axios'
import popupService from '../../../services/popup.service'
import { nluNames } from '../../../../../common/constants/nlu-names.constant'
import { nluLanguages } from '../../../../../common/constants/nlu-languages.constant'
import { getRelativeLanguage } from '../../../../../common/helpers/nlu-language.helper'
import { BotCreateForm } from '../../../../../common/interfaces/bot-create-form.interface'
import { nluAutomateTooltip } from '../../../constants/nlu-automate-tooltip.constant'
import { LuisAppLanguage } from '../../../../../common/enums/microsoft/luis-app-language.enum'
import { botRegions, botRegionTooltip } from '../../../constants/bot-region.constants'
import { BotRegion } from '../../../../../common/enums/bot/bot-region.enum'
import tenantService from '../../../services/tenant.service'
import subscriptionMixin from '../configuration/_mixins/subscription.mixin'

@Component({ components: { SmallLoading, Tooltip, Select, Modal, Toggle } })
export default class CreateBot extends Mixins(subscriptionMixin) {
  @Prop({ default: [] }) readonly templates: string[]
  // Force update the language selector
  private langKey = false

  botForm: BotCreateForm = {
    name: '',
    recognizer: undefined,
    region: BotRegion.EU_WEST,
    language: '',
    nluAutomate: false,
    createType: null,
    template: ''
  }

  showBotRegionSelector = false
  nameError = false
  descError = false
  recognizerError = false
  languageError = false
  creating = false

  recognizers = []
  regions = botRegions
  recognizerLanguages = nluLanguages
  botRegionTooltip = botRegionTooltip
  nluAutomateTooltip = nluAutomateTooltip

  private createType = null
  private template = ''
  private templateError = false
  private regionError = false

  async beforeMount() {
    const plan = await tenantService.getPlan()
    const allowedEngines = this.getAvailableEngines(plan)
    if (allowedEngines) {
      const nluNamesArr = Object.entries(nluNames).filter(([_, value]) => allowedEngines.includes(value.toLowerCase()))
      this.recognizers = nluNamesArr
    } else {
      this.recognizers = Object.entries(nluNames)
    }
  }

  get langugeEnum() {
    if (this.botForm.nluAutomate) return LuisAppLanguage
    const langEnum = this.recognizerLanguages[this.botForm.recognizer] || {}
    if (this.botForm.language) {
      this.botForm.language = getRelativeLanguage(this.botForm.language, langEnum)
      this.langKey = !this.langKey
    }
    return langEnum
  }

  get languages() {
    return Object.entries(this.langugeEnum)
  }

  get templateNames() {
    return this.templates.map((t) => t.split('.')[0])
  }

  nameIsValid(name) {
    return name && name.length > 0 && /^([a-zA-Z0-9\-\s\_])+$/g.test(name)
  }

  createBot() {
    this.validateField()
    if (this.nameError || this.regionError || this.languageError || this.recognizerError || this.templateError) return

    this.creating = true
    this.botForm.createType = this.createType
    this.botForm.template = this.template + '.enc'
    axios
      .post(`${process.env.VUE_APP_BACKEND_URL}/bot`, this.botForm)
      .then((response) => {
        this.creating = false
        if (response.data.url) window.location.href = response.data.url
        else this.$router.push(`bots/${this.botForm.name}`)
      })
      .catch((err) => {
        popupService.showError('Error!', `There was a problem creating bot ${this.botForm.name}.\n${err}`)
        this.creating = false
      })
  }

  validateField() {
    this.nameError = !this.nameIsValid(this.botForm.name)
    this.regionError = !this.botForm.region
    if (this.createType === 'scratch') {
      this.recognizerError = !this.botForm.nluAutomate && !this.nameIsValid(this.botForm.recognizer)
      this.languageError = !this.nameIsValid(this.botForm.language)
    } else if (this.createType === 'template') {
      this.templateError = this.template.trim() == '' || !this.templates.includes(this.template + '.enc')
    }
  }

  close() {
    this.$emit('close')
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.toggle-wrapper {
  margin-top: 15px;
  margin-bottom: 10px;
}

.select-type {
  .buttons {
    display: flex;
    justify-content: space-between;

    .btn {
      height: auto;
      width: 160px;
      padding: 20px;
      background-color: $gray-button;

      i {
        transform: scale(3);
      }

      p {
        margin: 0;
      }
    }

    .btn:hover {
      border-color: $light-blue;
    }

    h6 {
      color: $dark-blue-text;
      padding-top: 15px;
    }
  }
}
</style>
