<template lang="html">
  <div>
    <div class="rect--wrapper">
      <div class="rect rect--usermetrics">
        <div class="rect__inner">
          <div>
            <p>{{ (sentiment || 0.0) | fix(1) }}%</p>
            <h3>Sentiment</h3>
          </div>
          <div class="progress">
            <svg viewBox="0 0 36 36">
              <defs><linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stop-color="#540cd4"/><stop offset="100%" stop-color="#8743FF"/>
              </linearGradient></defs>
              <path class="progress__circle" :style="'stroke-dasharray: ' + (sentiment || 0.0) + ', 100'"
              stroke="url(#linear)" d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831"
            /></svg>
          </div>
        </div>
      </div>
      <div class="rect rect--usermetrics">
        <div class="rect__inner">
          <div>
            <p>{{(satisfaction || 0) | fix(1)}}/5.0</p>
            <h3>Satisfaction</h3>
          </div>
          <div class="progress">
            <svg viewBox="0 0 36 36">
              <defs><linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stop-color="#540cd4"/><stop offset="100%" stop-color="#8743FF"/>
              </linearGradient></defs>
              <path class="progress__circle" :style="'stroke-dasharray: ' + (satisfaction * 20) + ', 100'"
              stroke="url(#linear)" d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831"
            /></svg>
          </div>
        </div>
      </div>
      <div class="rect rect--usermetrics">
        <div class="rect__inner">
          <div>
            <p>{{ (retention || 0.0) | fix(1) }}%</p>
            <h3>Retention</h3>
          </div>
          <div class="progress">
            <svg viewBox="0 0 36 36">
              <defs><linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stop-color="#540cd4"/><stop offset="100%" stop-color="#8743FF"/>
              </linearGradient></defs>
              <path class="progress__circle" :style="'stroke-dasharray: ' + (retention || 0.0) + ', 100'"
              stroke="url(#linear)" d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831"
            /></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { fix } from '../filters/number.filters'

@Component({
  filters: {
    fix: fix
  }
})
export default class UserMetrics extends Vue {
  @Prop({ default: '' }) readonly month: string;
  @Prop({ default: 0 }) readonly sentiment: number;
  @Prop({ default: 0 }) readonly retention: number;
  @Prop({ default: 0 }) readonly satisfaction: number;
}
</script>

<style lang="scss" scoped>
@import "analytics.style";

.row {
  margin-top: 25px;
}
.line {
  margin-bottom: 15px;
}
.rect {
  + .rect {
    margin-left: 20px;
  }
  &--wrapper {
    display: flex;
  }
  &__inner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 140px;
    height: 140px;
    margin: 0 auto;
  }
  &--usermetrics {
    padding-top: 24px;
    padding-bottom: 24px;

    h3, p {
      margin: 0;
      text-align: center;
    }
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: $main;
      line-height: 15px;
      text-transform: uppercase;
    }
    p {
      font-size: 26px;
      font-weight: 100;
      color: #000;
      line-height: 33px;
    }
    .progress {
      position: absolute;
      top: 0;
      left: 0;

      width: 140px;
      height: 140px;
      background: none;
      border-radius: 0;
      &:after {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 4px;
        right: 4px;
        border: 8px solid #f3f3f3;
        border-radius: 50%;
      }
      svg {
        position: absolute;
        width: 140px;
        height: 140px;
        z-index: 1;
      }
      &__circle {
        fill: none;
        stroke-width: 2;
      }
    }
  }
}
</style>
