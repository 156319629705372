/**
 * Google Text To Speech supported languages: https://cloud.google.com/text-to-speech/docs/voices
 *
 */
export enum GoogleTTSLanguage {
    'Arabic' = 'ar-XA',
    'Bengali (India)' = 'bn-IN',
    'Chinese (Hong Kong)' = 'yue-HK',
    'Czech (Czech Republic)' = 'cs-CZ',
    'Danish (Denmark)' = 'da-DK',
    'Dutch (Netherlands)' = 'nl-NL',
    'English (Australia)' = 'en-AU',
    'English (India)' = 'en-IN',
    'English (UK)' = 'en-GB',
    'English (US)' = 'en-US',
    'Filipino (Philippines)' = 'fil-PH',
    'Finnish (Finland)' = 'fi-FI',
    'French (Canada)' = 'fr-CA',
    'French (France)' = 'fr-FR',
    'German (Germany)' = 'de-DE',
    'Greek (Greece)' = 'el-GR',
    'Gujarati (India)' = 'gu-IN',
    'Hindi (India)' = 'hi-IN',
    'Hungarian (Hungary)' = 'hu-HU',
    'Indonesian (Indonesia)' = 'id-ID',
    'Italian (Italy)' = 'it-IT',
    'Japanese (Japan)' = 'ja-JP',
    'Kannada (India)' = 'kn-IN',
    'Korean (South Korea)' = 'ko-KR',
    'Malayalam (India)' = 'ml-IN',
    'Mandarin Chinese' = 'cmn-CN',
    'Norwegian (Norway)' = 'nb-NO',
    'Polish (Poland)' = 'pl-PL',
    'Portuguese (Brazil)' = 'pt-BR',
    'Portuguese (Portugal)' = 'pt-PT',
    'Russian (Russia)' = 'ru-RU',
    'Slovak (Slovakia)' = 'sk-SK',
    'Spanish (Spain)' = 'es-ES',
    'Swedish (Sweden)' = 'sv-SE',
    'Tamil (India)' = 'ta-IN',
    'Telugu (India)' = 'te-IN',
    'Thai (Thailand)' = 'th-TH',
    'Turkish (Turkey)' = 'tr-TR',
    'Ukrainian (Ukraine)' = 'uk-UA',
    'Vietnamese (Vietnam)' = 'vi-VN'
    
}