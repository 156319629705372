import { Component, Mixins } from 'vue-property-decorator'
import generalSettingsManagementMixin from './settings/general-settings-management.mixin'
import configurationManagementMixin from './settings/configuration-management.mixin'
import chatBubbleManagementMixin from './settings/chat-bubble-management.mixin'
import customWidgetsManagementMixin from './settings/custom-widgets-management.mixin'
import dataManagementMixin from './settings/data-management.mixin'
import gdprManagementMixin from './settings/gdpr-management.mixin'
import handoverManagementMixin from './settings/handover-management.mixin'
import popupService from '@/services/popup.service'
import { Permissions } from '../../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '@/services/tenants/permissions.service'
import { ExternalProvider } from '../../../../../../../common/enums/external-provider.enum'

/** The Mixins ctor supports a maximum of 5 arguments */
@Component
class MixinsA extends Mixins(
    generalSettingsManagementMixin,
    configurationManagementMixin,
    chatBubbleManagementMixin,
    customWidgetsManagementMixin,
    dataManagementMixin
  ) { }

@Component
class MixinsB extends Mixins(
    gdprManagementMixin,
    handoverManagementMixin
  // Add more mixins here
  ) { }

@Component
export default class BotSettingsManagementMixin extends Mixins(
  MixinsA,
  MixinsB
) {
  private tabs: string[] = ['General Settings', 'Configuration'];
  // Tabs that don't have the save button
  readonly customTabs = ['Chat Bubble', 'NLU Status'];
  currentTab = null;
  isConfigurationLoaded = false;

  async initSettings (botName: string) {
    this.tabs = await this.getTabs()
    this.initGeneralSettings(botName)
    this.initConfiguration(botName)
    this.initWidgets(botName)
    this.initChatBubble(botName)
    this.initData(botName)
    this.initGdpr(botName)
    this.initHandover(botName)
    this.getSnippets()
  }

  async getData () {
    await Promise.all([
      this.getConfiguration(),
      this.getGeneralSettings()
    ])
    this.prepareEngines()
    this.isConfigurationLoaded = true
  }

  prepareEngines () {
    this.engines.find(e => e.id == 'INTENT_RECOGNIZER').selectedProvider = this.settings.intentRecognizerProvider
    this.engines.find(e => e.id == 'ENTITY_EXTRACTOR').selectedProvider = this.settings.entityExtractorProvider
    this.engines.find(e => e.id == 'TTS').selectedProvider = this.settings.textToSpeechProvider
    this.engines.find(e => e.id == 'STT').selectedProvider = this.settings.speechToTextProvider
    this.engines.find(e => e.id == 'SENTIMENT_ANALYZER').selectedProvider = this.settings.sentimentAnalysisProvider
  }

  onTabChanged (newTab: string) {
    this.currentTab = newTab
  }

  get ttsProvider () {
    return this.engines.find(e => e.id == 'TTS').selectedProvider
  }

  get sttProvider () {
    return this.engines.find(e => e.id == 'STT').selectedProvider
  }

  async getTabs () {
    const tabs: string[] = ['General Settings', 'Configuration']

    if (await permissionsService.hasPermission(this.botName, Permissions.ManageDashboardWidgets)) {
      tabs.push('Custom Widgets')
    }

    if (await permissionsService.hasPermission(this.botName, Permissions.ManageChatBubbles)) {
      tabs.push('Chat Bubble')
    }

    tabs.push('NLU Status', 'NLU Performance', 'GDPR', 'Handover', 'Multi-Language')

    return tabs
  }

  get isCustomTab () {
    return this.customTabs.includes(this.currentTab)
  }

  async onFormSubmit () {
    try {
      switch (this.currentTab) {
        case 'General Settings': await this.updateSettings(); break
        case 'Configuration': await this.updateConfiguration(); break
        case 'Custom Widgets': await this.updateWidgets(); break
        case 'NLU Performance': await this.updateSettings(); break
        case 'GDPR': await this.updateGdpr(); break
        case 'Handover': await this.updateHandover(); break
        case 'Multi-Language': await this.updateSettings(); break
      }
      popupService.showInfo('Saved!')
    } catch (err) {
      const message = err.response ? err.response.data.message : err.message
      popupService.showError('Error!', message)
    }
  }
}
