import { Component, Vue } from 'vue-property-decorator'
import { LuisService } from '@/services/bots/settings/configuration/luis.service'
import popupService from '@/services/popup.service'

@Component
export default class LuisManagementMixin extends Vue {
  luisService: LuisService;
  keys: any;

  initLuis (botName: string) {
    this.luisService = new LuisService(botName)
    this.getLuisKeys()
  }

  async getLuisKeys () {
    this.keys = await this.luisService.getKeys()
  }

  taskInProgress = false;
  async deleteLuisApp () {
    if (this.taskInProgress) return
    else this.taskInProgress = true

    try {
      await this.luisService.deleteLuis()
      this.keys.weHostLuis = false
      popupService.showInfo('Success!')
    } catch (err) {
      popupService.showError('Error!', err.message)
    } finally {
      this.taskInProgress = false
    }
  }

  async configureLuisApp () {
    if (this.taskInProgress) return
    else this.taskInProgress = true

    try {
      await this.luisService.configureLuis()
      this.keys.weHostLuis = true
      popupService.showInfo('Success!')
    } catch (err) {
      popupService.showError('Error!', err.message)
    } finally {
      this.taskInProgress = false
    }
  }

  async updateLuisKeys () {
    try {
      await this.luisService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }
}
