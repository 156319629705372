import { Component, Vue } from 'vue-property-decorator'
import { BcrService } from '@/services/bots/settings/configuration/bcr.service'

@Component
export default class BcrManagementMixin extends Vue {
  bcrService: BcrService;
  keys: any;

  externalApiConversationIdLifeTime: number;

  initBcrApi (botName: string) {
    this.bcrService = new BcrService(botName)
    this.getBcrKeys()
  }

  async getBcrKeys () {
    const response = await this.bcrService.getKeys()
    this.keys = response.keys
    this.externalApiConversationIdLifeTime = response.externalApiConversationIdLifeTime as number
  }
}
