import { ValidationError } from "../../common/errors/validation.error";

export class BotConnection {
    name: string;
    connected: boolean;
    visible: boolean;
    enabled: boolean;
    requested: boolean;

    constructor(other: Partial<BotConnection>) {
        if (typeof other.name !== "string") throw new ValidationError("Bot Connection name must be a valid string");

        this.name = other.name;
        this.connected = other.connected || false;
        this.visible = other.visible || false;
        this.enabled = other.enabled || false;
        this.requested = other.requested || false;
    }
}