<template>
  <Modal title="Twilio Voice" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form v-if="!keys.webhookUrl" @submit.prevent="$emit('generate-webhook', type, true)">
        <label for="button">There is no webhook for this bot (yet)</label>

        <div class="section--footer static">
          <button class="btn btn--purple gradient" type="submit">
            <i class="icon icon-add"></i>
            Generate webhook
          </button>
        </div>
      </form>
      <form v-else>
        <div class="form-section">
          <label>Webhook settings</label>
          <FormGroup label='"A CALL COMES IN" webhook url'>
            <textarea readonly :value="keys.webhookUrl" class="form-control code"></textarea>
          </FormGroup>
        </div>

        <div class="form-section">
          <label class="label-margin">Voice Settings</label>
          <FormGroup label="Text to speech language">
            <Select
              :elements="textToSpeechLanguages"
              :key="keys.textToSpeechLanguage"
              keyAttr="0"
              valueAttr="1"
              v-model="keys.textToSpeechLanguage"
              class="white-select"
            ></Select>
          </FormGroup>

          <FormGroup label="Speech to text language">
            <Select
              :elements="speechToTextLanguages"
              :key="keys.speechToTextLanguage"
              keyAttr="0"
              valueAttr="1"
              v-model="keys.speechToTextLanguage"
              class="white-select"
            ></Select>
          </FormGroup>

          <FormGroup
            label="Voice"
            tooltip="Man and Woman are available for English (US, UK), Spanish (Spain), French (France) and German (Germany) only"
          >
            <Select
              :elements="voices"
              :key="keys.voice"
              keyAttr="0"
              valueAttr="1"
              v-model="keys.voice"
              class="white-select"
            ></Select>
          </FormGroup>

          <FormGroup label="Speech timeout (in seconds), or 0 for max timeout" tooltip="Max timeout can be 60 seconds">
            <input v-model.number="keys.speechTimeout" type="number" min="0" max="60" class="form-control" />
          </FormGroup>

          <FormGroup label="Button timeout (in seconds), or 0 for max timeout" tooltip="Max timeout can be 60 seconds">
            <input v-model.number="keys.buttonTimeout" type="number" min="0" max="60" class="form-control" />
          </FormGroup>

          <FormGroup label="Timeout message">
            <input v-model="keys.timeoutMessage" type="text" class="form-control" />
          </FormGroup>

          <FormGroup
            label="Options gather type"
            tooltip="Determines how to receive input after displaying an options block"
          >
            <Select
              :elements="gatherTypes"
              :key="keys.optionsGatherType"
              keyAttr="0"
              valueAttr="1"
              v-model="keys.optionsGatherType"
              class="white-select"
            ></Select>
          </FormGroup>

          <FormGroup
            label="Options message"
            tooltip="Message when displaying options"
          >
            <Autocomplete v-model="keys.optionsMessage" :items="optionsMessageItems" />
          </FormGroup>
        </div>

        <div class="bottom-right-fix">
          <button
            class="btn btn--white"
            type="button"
            @click="$emit('generate-webhook', type, false)"
          >Change webhook url</button>
          <button
            class="btn btn--white red"
            type="button"
            @click="$emit('delete-webhook', type)"
          >Delete webhook</button>
          <button class="btn btn--purple gradient" type="button" @click="$emit('update-keys')">
            <i class="icon icon-save"></i> Save settings
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'

@Component({
  components: { FormGroup, Autocomplete, Select, Modal }
})
export default class TwilioVoice extends Vue {
  type = 'voice'
  optionsMessageItems = ['digit', 'option'];

  @Prop() keys: any;
  @Prop() textToSpeechLanguages: string[][];
  @Prop() speechToTextLanguages: string[][];
  @Prop() voices: string[][];
  @Prop() gatherTypes: string[][];
}
</script>
