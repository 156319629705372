<template>
  <div v-if="fields" class="form-group">
    <label for="function">
      Fields
      <Tooltip
        title="This field serves as input for the function, e.g. which message to send with the message function."
        placement="bottom"
      ></Tooltip>
    </label>

    <div>
      <div v-for="inp in fields" :key="inp.name" style="margin-bottom: 15px">
        <div v-if="!isHidden(inp)">
          <autocomplete
            v-if="inp.type == 'text' && !inp.isEditor && hasSufficientPermission"
            v-model="customData[getKeyOrDefault(inp.id)]"
            :items="variableNames"
            :placeholder="inp.placeholder || inp.name"
            :rows="inp.rows || 3"
            :disabled="!hasSufficientPermission"
          />
          <editor
            :current-language="currentLanguage"
            :key="currentBlock.id"
            v-else-if="inp.type == 'text'"
            :inputId="inp.id"
            :disabled="!hasSufficientPermission"
            :items="variableNames"
            v-model="customData[getKeyOrDefault(inp.id)]"
            :name="inp.name"
            :placeholder="inp.placeholder || inp.name"
            @change="onEditorChange"
          />
          <input
            v-if="inp.type == 'input' && !inp.isEditor"
            type="text"
            v-model="customData[getKeyOrDefault(inp.id)]"
            :placeholder="inp.placeholder || inp.name"
            class="form-control"
            :disabled="(inp.activeIf && !customData[getKeyOrDefault(inp.activeIf)]) || !hasSufficientPermission"
          />

          <div
            class="form-group form-group--options form-group--checkbox"
            v-if="inp.type == 'checkbox'"
          >
            <input
              type="checkbox"
              :id="'fld-' + inp.id"
              class="form-check-input"
              v-model="customData[getKeyOrDefault(inp.id)]"
              :name="inp.id"
              :disabled="!hasSufficientPermission"
            />
            <label class="form-check-label" :for="'fld-' + inp.id">{{ inp.placeholder }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Tooltip from '../../../../../helpers/Tooltip.vue'
import autocomplete from '../../../../../helpers/Autocomplete.vue'
import FlowComponent from '../../../modules/FlowComponent'
import editor from '../../../Editor.vue'
import { IBlock } from '../../../../../../../../common/blocks/block.interface'

@Component({
  components: { Tooltip, autocomplete, editor },
  computed: {
    customData: {
      get () {
        return this.currentBlock.customData
      },
      set (value) {
        this.currentBlock.customData = value
      }
    },
    currentLanguage () {
      return this.$store.state.currentLanguage
    }
  }
})
export default class Fields extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: FlowComponent<IBlock>;
  @Prop({ default: () => [] }) readonly variableNames!: any[];
  @Prop({ default: () => [] }) readonly currentFunction!: any;
  @Prop({ default: () => [] }) readonly hasSufficientPermission!: boolean;

  get fields (): false | Array<any> {
    const fields = this.currentFunction.fields
    return fields && fields[0] ? fields : false
  }

  getKeyOrDefault (id: string) {
    if (this.fields) {
      const existingField = this.fields.find(f => f.id === id)
      const defaultField = this.fields[0]

      return 'field-' + (existingField ? existingField.id : defaultField.id)
    }
  }

  onEditorChange (event) {
    const { inputId, content } = event
    this.currentBlock.customData[this.getKeyOrDefault(inputId)] = content
    this.$parent.$emit('blockFieldChange')
  }

  isHidden (inp: any) {
    if (inp.hide) {
      return !!this.currentBlock.customData[this.getKeyOrDefault(inp.hide)]
    }
  }
}
</script>
