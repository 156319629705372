<template>
  <div class="form-group form-group--options">
    <div class="label no-margin">LLM</div>

    <Dropdown
      v-if="loadedllms.length > 1"
      :items="loadedllms"
      :value="currentBlock.functionSettings.functionName || 'No LLM Selected'"
      @input="selectLLM($event)"
      :disabled="!hasSufficientPermission"
    />

    <div v-if="llm">
      <div class="label">Description</div>

      <div class="text">{{ llm.description }}</div>

      <div class="label">Provider</div>

      <div class="text">{{ llm.llmProvider }}</div>

      <div class="label">Model</div>

      <div class="text">{{ llm.model ? llm.model : 'Default model' }}</div>

      <div class="label">Status</div>

      <div class="dot-wrapper">
        <div class="dot" :class="llm.isComplete ? 'green' : 'red'" />
        <div class="text trained">LLM {{ llm.isComplete ? '' : 'not' }} trained</div>
      </div>

      <div class="label">
        Input
        <Tooltip title="This field serves as input for the LLM, e.g. which message to send to the model" placement="bottom"></Tooltip>
      </div>

      <Autocomplete
        :items="autocompleteVariables"
        id="botName"
        v-model="currentBlock._customData.English.llmInput"
        placeholder="Enter the LLM's input"
        :rows="3"
        spellcheck="false"
        @input="updateLLM()"
        :disabled="!hasSufficientPermission"
      />

      <!-- <div class="label">
        Context
        <Tooltip title="This field serves as input for the context, e.g. if additional context needs to be send to the model" placement="bottom"></Tooltip>
      </div>

      <Autocomplete
        :items="autocompleteVariables"
        id="botName"
        v-model="currentBlock._customData.English.llmContext"
        placeholder="Enter the LLM's context"
        :rows="3"
        spellcheck="false"
        @input="updateLLM()"
        :disabled="!hasSufficientPermission"
      /> -->

      <div class="form-group form-group--options">
        <input
          type="checkbox"
          :disabled="!hasSufficientPermission"
          v-model="recognizerExit"
          @change="setRecognizerExit()"
          id="exampleCheck1"
          class="form-check-input"
        />
        <label for="exampleCheck1" class="form-check-label"
          >Exit on intent recognition
          <Tooltip title="If we detect a Intent for you bot, we exit the LLM and continue on your intent flow (defaults to yes)" placement="bottom"></Tooltip
        ></label>
      </div>

      <!-- <div class="form-group form-group--options">
        <input
          type="checkbox"
          :disabled="!hasSufficientPermission"
          v-model="exitInactivity"
          @change="setExitInactivity()"
          id="exampleCheck2"
          class="form-check-input"
        />
        <label for="exampleCheck2" class="form-check-label"
          >Exit for feedback <Tooltip title="Exit the LLM and send a feedback form to the user" placement="bottom"></Tooltip
        ></label>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'
import Tooltip from '@/components/helpers/Tooltip.vue'

@Component({
  components: {
    Autocomplete,
    Dropdown,
    Tooltip
  }
})
export default class Autocorrect extends Vue {
  @Prop({ default: () => ({}) }) currentBlock
  @Prop({ default: () => [] }) readonly allLLMs
  @Prop({ default: () => [] }) readonly variables!: any[]
  @Prop() readonly hasSufficientPermission: boolean

  @Emit()
  updateLabel() {}

  created() {
    this.updateLabel()
  }

  get recognizerExit() {
    return this.currentBlock._customData.English.recognizerExit
  }

  set recognizerExit(_value) {
    // console.log('value', value)
  }

  setRecognizerExit() {
    this.currentBlock._customData.English.recognizerExit = !this.currentBlock._customData.English.recognizerExit
  }

  get exitInactivity() {
    return this.currentBlock._customData.English.exitInactivity
  }

  set exitInactivity(_value) {
    // console.log('value', value)
  }

  setExitInactivity() {
    this.currentBlock._customData.English.exitInactivity = !this.currentBlock._customData.English.exitInactivity
  }

  get llm() {
    return this.allLLMs.find((llm) => llm.llmDisplayName === this.currentBlock.functionSettings.functionName)
  }

  get autocompleteVariables() {
    return this.variables
  }

  get loadedllms() {
    return this.allLLMs.map((llm: any) => llm.llmDisplayName)
  }

  selectLLM(event) {
    const selectedLLM = this.allLLMs.find((llm) => llm.llmDisplayName === event)
    this.currentBlock.functionSettings.functionName = selectedLLM.llmDisplayName
    this.updateLLM()
  }

  updateLLM() {
    const selectedLLM = this.allLLMs.find((llm) => llm.llmDisplayName === this.currentBlock.functionSettings.functionName)
    const options = {
      newFc: selectedLLM.llmDisplayName,
      llmId: selectedLLM.llmId,
      llmContext: this.currentBlock._customData.English.llmContext,
      llmInput: this.currentBlock._customData.English.llmInput,
      recognizerExit: this.currentBlock._customData.English.recognizerExit,
      exitInactivity: this.currentBlock._customData.English.exitInactivity
    }

    //TODO FIX FOR OTHER LANGUAGES
    this.$emit('changeFunction', options)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/variables';
.label {
  margin-top: 15px;

  &.no-margin {
    margin-top: 0;
  }
}

.text {
  font-size: 14px;
}

.dot-wrapper {
  display: flex;
  margin: 0px 0px 20px 0px;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.red {
      background-color: $red;
    }

    &.green {
      background-color: $green;
    }
  }

  .trained {
    margin-left: 10px;
    margin-top: -5px;
  }
}
</style>
