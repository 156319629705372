import axios from 'axios'

export class BcrService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}`
  }

  async getKeys () {
    const res = await axios.get(`${this.url}/bcr`)
    return res.data
  }
}
