/** Supported Twilio voices (Twilio uses Amazon Polly) */
export enum TwilioVoice {
    /** Default voice */
    Alice = 'alice',

    /** Only for some languages */
    Man = 'man',
    Woman = 'woman',

    /** Amazon Polly */
    Aditi = 'Polly.Aditi',
    Amy = 'Polly.Amy',
    Astrid = 'Polly.Astrid',
    Brian = 'Polly.Brian',
    Carla = 'Polly.Carla',
    Carmen = 'Polly.Carmen',
    Celine = 'Polly.Celine',
    Chantal = 'Polly.Chantal',
    Conchita = 'Polly.Conchita',
    Cristiano = 'Polly.Cristiano',
    Dora = 'Polly.Dora',
    Emma = 'Polly.Emma',
    Enrique = 'Polly.Enrique',
    Ewa = 'Polly.Ewa',
    Filiz = 'Polly.Filiz',
    Geraint = 'Polly.Geraint',
    Giorgio = 'Polly.Giorgio',
    Gwyneth = 'Polly.Gwyneth',
    Hans = 'Polly.Hans',
    Ines = 'Polly.Ines',
    Ivy = 'Polly.Ivy',
    Jacek = 'Polly.Jacek',
    Jan = 'Polly.Jan',
    Joanna = 'Polly.Joanna',
    Joey = 'Polly.Joey',
    Justin = 'Polly.Justin',
    Karl = 'Polly.Karl',
    Kendra = 'Polly.Kendra',
    Kimberly = 'Polly.Kimberly',
    Liv = 'Polly.Liv',
    Lotte = 'Polly.Lotte',
    Mads = 'Polly.Mads',
    Maja = 'Polly.Maja',
    Marlene = 'Polly.Marlene',
    Mathieu = 'Polly.Mathieu',
    Matthew = 'Polly.Matthew',
    Maxim = 'Polly.Maxim',
    Miguel = 'Polly.Miguel',
    Mizuki = 'Polly.Mizuki',
    Naja = 'Polly.Naja',
    Nicole = 'Polly.Nicole',
    Penelope = 'Polly.Penelope',
    Raveena = 'Polly.Raveena',
    Ricardo = 'Polly.Ricardo',
    Ruben = 'Polly.Ruben',
    Russell = 'Polly.Russell',
    Salli = 'Polly.Salli',
    Seoyeon = 'Polly.Seoyeon',
    Takumi = 'Polly.Takumi',
    Tatyana = 'Polly.Tatyana',
    Vicki = 'Polly.Vicki',
    Vitoria = 'Polly.Vitoria'
}