/**
 * Microsoft Speech To Text supported languages: https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=stt
 */
export enum MicrosoftSTTLanguage {
    'Afrikaans (South Africa)' = 'af-ZA',
    'Amharic (Ethiopia)' = 'am-ET',
    'Arabic (Algeria)' = 'ar-DZ',
    'Arabic (Bahrain)' = 'ar-BH',
    'Arabic (Egypt)' = 'ar-EG',
    'Arabic (Iraq)' = 'ar-IQ',
    'Arabic (Israel)' = 'ar-IL',
    'Arabic (Jordan)' = 'ar-JO',
    'Arabic (Kuwait)' = 'ar-KW',
    'Arabic (Lebanon)' = 'ar-LB',
    'Arabic (Libya)' = 'ar-LY',
    'Arabic (Morocco)' = 'ar-MA',
    'Arabic (Oman)' = 'ar-OM',
    'Arabic (Palestinian Authority)' = 'ar-PS',
    'Arabic (Qatar)' = 'ar-QA',
    'Arabic (Saudi Arabia)' = 'ar-SA',
    'Arabic (Syria)' = 'ar-SY',
    'Arabic (Tunisia)' = 'ar-TN',
    'Arabic (United Arab Emirates)' = 'ar-AE',
    'Arabic (Yemen)' = 'ar-YE',
    'Azerbaijani (Latin, Azerbaijan)' = 'az-AZ',
    'Bengali (India)' = 'bn-IN',
    'Bosnian (Bosnia and Herzegovina)' = 'bs-BA',
    'Bulgarian' = 'bg-BG',
    'Catalan' = 'ca-ES',
    'Chinese (Cantonese, Traditional)' = 'zh-HK',
    'Chinese (Mandarin, Simplified)' = 'zh-CN',
    'Chinese (Taiwanese Mandarin)' = 'zh-TW',
    'Croatian' = 'hr-HR',
    'Czech' = 'cs-CZ',
    'Danish' = 'da-DK',
    'Dutch' = 'nl-NL',
    'English (Australia)' = 'en-AU',
    'English (Canada)' = 'en-CA',
    'English (Ghana)' = 'en-GH',
    'English (Hong Kong SAR)' = 'en-HK',
    'English (India)' = 'en-IN',
    'English (Ireland)' = 'en-IE',
    'English (Kenya)' = 'en-KE',
    'English (New Zealand)' = 'en-NZ',
    'English (Nigeria)' = 'en-NG',
    'English (Philippines)' = 'en-PH',
    'English (Singapore)' = 'en-SG',
    'English (South Africa)' = 'en-ZA',
    'English (Tanzania)' = 'en-TZ',
    'English (United Kingdom)' = 'en-GB',
    'English (United States)' = 'en-US',
    'Finish' = 'fi-FI',
    'French (Canada)' = 'fr-CA',
    'French (France)' = 'fr-FR',
    'French (Switzerland)' = 'fr-CH',
    'German (Austria)' = 'de-AT',
    'German (Germany)' = 'de-DE',
    'German (Switzerland)' = 'de-CH',
    'Greek' = 'el-GR',
    'Hebrew (Israel)' = 'he-IL',
    'Hindi (India)' = 'hi-IN',
    'Hungarian' = 'hu-HU',
    'Indonesian' = 'id-ID',
    'Italian' = 'it-IT',
    'Japanese' = 'ja-JP',
    'Korean' = 'ko-KR',
    'Malay' = 'ms-MY',
    'Norwegian' = 'nb-NO',
    'Polish' = 'pl-PL',
    'Portuguese (Brazil)' = 'pt-BR',
    'Portuguese (Portugal)' = 'pt-PT',
    'Romanian' = 'ro-RO',
    'Russian' = 'ru-RU',
    'Slovak' = 'sk-SK',
    'Slovenian' = 'sl-SI',
    'Spanish (Argentina)' = 'es-AR',
    'Spanish (Mexico)' = 'es-MX',
    'Spanish (Spain)' = 'es-ES',
    'Swedish' = 'sv-SE',
    'Tamil (India)' = 'ta-IN',
    'Telugu (India)' = 'te-IN',
    'Thai' = 'th-TH',
    'Turkish (Turkey)' = 'tr-TR',
    'Vietnamese' = 'vi-VN',
    'Welsh (United Kingdom)' = 'cy-GB'
}