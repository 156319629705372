export class EventDispatcher {
    private _listeners: Array<any>;

    constructor () {
      this._listeners = []
    }

    hasEventListener (type: string, listener) {
      return this._listeners.some(item => item.type === type && item.listener === listener)
    }

    addEventListener (type: string, listener) {
      if (!this.hasEventListener(type, listener)) {
        this._listeners.push({ type, listener, options: { once: false } })
      }
      return this
    }

    removeEventListener (type: string, listener) {
      const index = this._listeners.findIndex(item => item.type === type && item.listener === listener)
      if (index >= 0) this._listeners.splice(index, 1)
      return this
    }

    removeEventListeners () {
      this._listeners = []
      return this
    }

    dispatchEvent (evt) {
      this._listeners
        .filter(item => item.type === evt.type)
        .forEach(item => {
          const { type, listener, options: { once } } = item
          listener.call(this, evt)
          if (once === true) this.removeEventListener(type, listener)
        })
      return this
    }
}
