export const foodl = {
  defaultFoodl: {
    key: 'defaultFoodl', functionName: 'Select a function', variables: [], category: 'Foodl', hide: true
  },
  addToBasket: {
    key: 'addToBasket',
    functionName: 'Add to basket',
    fields: [
      { id: 'product-name', type: 'text', name: 'Name', rows: 1 },
      { id: 'product-quantity', type: 'input', name: 'Quantity', rows: 1 },
      { id: 'product-category', type: 'input', name: 'Category', rows: 1 },
      { id: 'product-volume', type: 'input', name: 'Volume', rows: 1 }

    ],
    category: 'Foodl'
  }
}
