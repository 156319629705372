import axios from 'axios'
import { CluAppLanguage } from '../../../../../common/enums/microsoft/clu-app.language.enum'
import store from '@/store'

export class GeneralSettingsService {
  constructor(private botName: string) {}

  async getSettings(): Promise<{ displayName: string; settings: any; botAvatar: string }> {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/settings`)

    store.commit('setSupportedLanguages', res.data.settings.supportedLanguages)
    store.commit('setCurrentLanguage', Object.entries(CluAppLanguage).find((value) => (value[1] = res.data.settings.language))[0])
    return res.data
  }

  updateSettings(displayName: string, settings: any, botAvatar: string) {
    return axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/settings`, {
      settings,
      displayName,
      botAvatar
    })
  }
}
