<template>
  <Modal title="Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <label>No settings available for this element, please contact us for any help with setting up this channel.</label>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Modal from '../../../../../helpers/Modal.vue'

@Component({
  components: { Modal }
})
export default class Default extends Vue {
}
</script>
