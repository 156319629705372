import axios from 'axios'
import { IStripeCustomerDto } from '../../../common/interfaces/stripe-customer-dto.interface'
import { IUser } from '../../../common/interfaces/tenant/user.interface'

export class UsersService {
  async createNewUser (name: string, email: string, assignToMasterTenant = false) {
    return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/users`, { name, email, assignToMasterTenant })
  }

  async signUp (userData: any) {
    return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/users/signup`, { userData: userData })
  }

  async openStripeCustomerPortal () {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/users/stripe/customer-portal?url=${encodeURI(window.location.href)}`).then(d => window.open(d.data))
  }

  async loadStripeCustomerData (): Promise<IStripeCustomerDto> {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/users/stripe/customer`).then(d => d.data)
  }

  async updateStripeCustomerData (data: any) {
    return await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/users/stripe/customer`, data)
  }

  async findUserLocation () {
    return (await axios.get(`https://ipinfo.io/json?token=${process.env.VUE_APP_IP_INFO_ACCESS_TOKEN}`).then(d => d.data))
  }

  async requestPasswordReset (userId: string) {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/password/reset-email`, { userId }))
  }

  async isPasswordRestLinkValid (resetRequestId: string) {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/user/password/reset/${resetRequestId}`).catch(() => ({ status: 400 }))).status == 204
  }

  async resetPassword (resetRequestId: string, newPassword: string) {
    return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/password/reset/${resetRequestId}`, { newPassword }).then(() => true).catch(() => false)
  }

  async changePassword () {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/password`).then(d => window.open(d.data, '_self')).catch(() => ({ status: 400 }))).status == 200
  }

  async getFlowAutoPublish (): Promise<boolean> {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/users/auto-publish`).then(d => d.data.autoPublish)
  }

  async setFlowAutoPublish (val: boolean) {
    await axios.put(`${process.env.VUE_APP_BACKEND_URL}/users/auto-publish`, { autoPublish: val })
  }

  async getFlowAutoValidation (): Promise<boolean> {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/users/auto-validation`).then(d => d.data.autoValidation)
  }

  async setFlowAutoValidaiton (val: boolean) {
    await axios.put(`${process.env.VUE_APP_BACKEND_URL}/users/auto-validation`, { autoValidation: val })
  }

  async getUserInfo () {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/users/info`).then(res => res.data)
  }

  async changeSelectedTenant (tenantId: string) {
    return await axios.put(`${process.env.VUE_APP_BACKEND_URL}/users/selected-tenant`, { tenantId })
  }

  async updateTenantRoles (user: IUser) {
    return await axios.put(`${process.env.VUE_APP_BACKEND_URL}/users/tenant-roles`, user)
  }

  async sendGetMatchEmail (userId: string) {
    return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/users/get-match-email`, {userId}).then(data => data)
  }
}

export default new UsersService()
