<template>
  <div class="form-group--inside" >
    <select
      :id="id"
      class="form-control"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @change="$emit('input', $event.target.value)"
      :disabled="disabled"
    >
      <option value :selected="!value" v-if="showDefault" :disabled="disableDefault">{{ defaultText }}</option>
      <option
        v-for="(el, index) in elements"
        :selected="isNotDefault(el)"
        :key="el[keyAttr || valueAttr || show || selectedAttr] || index"
        :value="el[valueAttr || keyAttr || show || selectedAttr] || el"
      >{{ el[show || keyAttr || valueAttr || selectedAttr] || el }}</option>
    </select>
    <i v-show="showToggle" class="dropdown-toggle"></i>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'Select' })
export default class SelectComponent extends Vue {
  @Prop({ required: false })
  value: any;

  @Prop({ required: false })
  id: string;

  @Prop({ required: true })
  elements: Array<any> | Record<string, any>;

  @Prop({ required: false })
  keyAttr: string;

  @Prop({ required: false })
  valueAttr: string;

  @Prop({ required: false })
  selectedAttr: string;

  @Prop({ required: false })
  show: string;

  //
  @Prop({ required: false, default: true })
  showDefault: boolean;

  @Prop({ required: false, default: false })
  disableDefault: boolean;

  @Prop({ required: false, default: 'Select Option' })
  defaultText: string;

  @Prop({ required: false, default: false })
  disabled: boolean;

  @Prop({ default: true })
  showToggle: boolean;

  mounted () {
    // this.keyAttr = this.keyAttr || this.valueAttr || this.selectedAttr || this.show;
    // this.valueAttr = this.valueAttr || this.keyAttr || this.selectedAttr || this.show;
    // this.selectedAttr = this.selectedAttr || this.valueAttr || this.keyAttr || this.show;
    // this.show = this.show || this.valueAttr || this.selectedAttr || this.keyAttr;
  }

  isNotDefault (el) {
    return this.value
      ? (el[this.selectedAttr || this.keyAttr || this.valueAttr || this.show] ||
          el == this.value)
      : false
  }
}
</script>
