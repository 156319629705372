<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Creating a Google cloud project...</h1>
        <h3>You will be redirected shortly</h3>

        <div class="stage">
          <div class="dot-flashing"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { GoogleService } from '../../services/bots/google.service'
import popupService from '../../services/popup.service'

@Component
export default class GoogleSetup extends Vue {
  async mounted () {
    /** Checks for the state, that is sent from google oauth2 */
    let query = {} as any
    if (this.$route.query) {
      query = this.$route.query
    } else {
      const hash = window.location.hash.substring(1);
      (hash.split('&') || []).forEach(pair => {
        const [key, value] = pair.split('=')
        if (key.trim() && value.trim()) {
          query[decodeURIComponent(key)] = decodeURIComponent(value)
        }
      })
    }

    if (query.error || !query.code || !query.state) {
      this.$router.push('/')
    }

    const [botId, botName, configurationKey] = query.state.split(';')
    if (!botId.trim() || !configurationKey || !configurationKey.trim()) {
      this.$router.push('/')
    }

    try {
      await new GoogleService(botName).createProjectForUser(query.code, configurationKey)
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', err.response ? err.response.data.message : err.message)
    } finally {
      this.$router.push(`/bots/${botName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
h1, h3 {
  text-align: center;
}

.container {
  position: relative;
  background: #fff;
  padding: 2rem 5%;
  box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 32, 128, 0.1), 0 0 0 1px #f0f2f7;
  border-radius: 0.25rem;
}

.app {
  display: none;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>
