<template>
  <Modal id="create-function" :title="`Create ${functionType === FunctionType.Flow ? 'Sub flow' : 'Function'}`" :show="true" @close="close">
    <FunctionEditor
      :variables="variables"
      :isCreate="true"
      :data="newFunction"
      :isCurrentFunctionGlobal="isCurrentFunctionGlobal"
      :errors="errors"
      :showType="!functionType"
      @update="updateFunction"
      @createFunction="create"
    ></FunctionEditor>
  </Modal>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Modal from '../../../helpers/Modal.vue'
import FunctionEditor from './FunctionEditor.vue'
import validationsMixin from '../_mixins/validation-management.mixin'
import preventBodyScrollMixin from '../../../_mixins/prevent-body-scroll'
import { FunctionType } from '../../../../../../common/enums/bot/functions/function-type.enum'

@Component({
  components: { Modal, FunctionEditor }
})
export default class CreateFunction extends Mixins(validationsMixin, preventBodyScrollMixin) {
  @Prop({ type: Boolean, default: false }) isRedirect
  @Prop({ type: Array, default: () => [] }) variables
  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  settings
  @Prop() functionType: string

  FunctionType = FunctionType
  private newFunction: any = {}
  private type = 'sql'

  isCurrentFunctionGlobal = this.newFunction.isGlobal

  errors = {}

  created() {
    this.toggleScroll(true)
    this.createEscapeEvent()
    if (this.functionType) this.$data.type = this.functionType
  }

  updateFunction(data: any) {
    this.newFunction = data
  }

  create() {
    this.$set(this.errors, 'name', this.validateName(this.newFunction.name) ? 'Please provide a valid function name' : undefined)
    this.newFunction.outputs = this.newFunction.outputs ? this.newFunction.outputs.filter((o) => String(o.variableName).trim() !== '') : []
    this.newFunction.inputs = this.newFunction.inputs ? this.newFunction.inputs.filter((i) => String(i.name).trim() !== '') : []
    const hasError = Object.values(this.errors).some((err) => !!err)
    if (!hasError) this.$emit('create', this.$data)
    this.isCurrentFunctionGlobal = this.newFunction.isGlobal
    this.toggleScroll(false)
  }

  createEscapeEvent() {
    document.addEventListener('keyup', (evt) => {
      if (evt.keyCode === 27) {
        this.close()
      }
    })
  }

  close() {
    this.toggleScroll(false)
    this.$emit('close')
  }
}
</script>
