import { Component, Vue } from 'vue-property-decorator'
import { WidgetsService } from '@/services/bots/settings/widgets.service'

@Component
export default class CustomWidgetsManagementMixin extends Vue {
  widgetsService: WidgetsService;

  widgetLists = [];
  customWidgets = [];
  variables = [];

  initWidgets (botName: string) {
    this.widgetsService = new WidgetsService(botName)
  }

  async getWidgets () {
    this.widgetsService.getVariables()
      .then(variables => this.variables = variables)

    const dashboard = await this.widgetsService.getDashboard()

    this.widgetLists.push(dashboard.design.col1)
    this.widgetLists.push(dashboard.design.col2)
    this.customWidgets = (dashboard.customWidget || []).filter(w => !!w.text.trim() && !!w.formula.trim())
  }

  async updateWidgets () {
    if (this.customWidgets.some(w => !(w.text || '').trim() || !(w.formula || '').trim())) {
      throw new Error('Cannot save widgets with empty data')
    }
    await this.widgetsService.updateDashboard(this.customWidgets)
  }
}
