import axios from 'axios'
import { TenantPlan } from '../../../common/interfaces/plans/tenant-plan.interface'
import { saveAs } from 'file-saver'
import Vue from 'vue'

export class TenantsService {
  async getTenant () {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant`).then(r => r.data)
  }

  async areInteractionExceeded () {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/interactions-exceeded`)).data
  }

  async getPlan (): Promise<TenantPlan> {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/plan`)).data
  }

  async getPlans (): Promise<TenantPlan[]> {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/plans`)).data
  }

  // Checks if the tenant is already created as customer in stripe and if not creates it
  async stripeCheck () {
    return await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/tenant/stripe`).then(r => r.data)
  }

  async changeStripePlan (planId: string, tenantId: string | undefined) {
    return await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/tenant/stripe/subscription`, { planId, tenantId }).then(r => r.data)
  }

  async getUsage () {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/metered-usage`).then(r => r.data)
  }

  private _areLogsDownloading = Vue.observable({ value: false })
  get areLogsDownloading () {
    return this._areLogsDownloading.value
  }

  async downloadUsersAuditLogs () {
    if (this.areLogsDownloading) return

    this._areLogsDownloading.value = true
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/audit-log`, { responseType: 'blob' })
    saveAs(res.data, 'users-log.csv')
    this._areLogsDownloading.value = false
  }

  async isDisabled () {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/disabled`).then(r => r.data)
  }

  async delete (id: string | undefined) {
    return await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/tenant`, { params: { id } })
  }

  async getMasterTenantInfo () {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/master-tenant/info`).then(r => r.data)
  }

  async createTenant (name: string) {
    return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/tenants`, { name }).then(r => r.data)
  }

  async enableNluPerformance () {
    return await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/tenant/stripe/enable-nlu`, {}).then(r => r.data)
  }

  async enablePremiumChannels () {
    return await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/tenant/stripe/enable-premium-channels`, {}).then(r => r.data)
  }

  async generateSecretKey () {
    return await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/tenant/secret-key`, {}).then(r => r.data)
  }

  async enablePremiumEngines () {
    return await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/tenant/stripe/enable-premium-engines`, {}).then(r => r.data)
  }
}

export default new TenantsService()
