<template>
  <div class="form-group">
    <label>
      {{ label }}
      <Tooltip
        v-if="tooltip"
        :title="tooltip"
      ></Tooltip>
    </label>
    <div class="form-group--inside">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Tooltip from './Tooltip.vue'

@Component({
  components: {
    Tooltip
  }
})
export default class FormGroup extends Vue {
  @Prop() readonly label!: string;
  @Prop() readonly tooltip: string;
}
</script>

<style>

</style>
