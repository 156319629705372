export const availableSettingsChannels = [
  {
    name: "Test Chat",
    availableSettings: false,
  },
  {
    name: "Chat Bubble",
    availableSettings: true,
  },
  {
    name: "Amazon Alexa",
    availableSettings: true,
  },
  {
    name: "API",
    availableSettings: true,
  },
  {
    name: "Microsoft Teams",
    availableSettings: true,
  },
  {
    name: "Google Assistant",
    availableSettings: true,
  },
  {
    name: "Twilio Voice",
    availableSettings: true,
  },
  {
    name: "Twillio (WhatsApp & SMS)",
    availableSettings: true,
  },
  {
    name: "Apple Business Chat",
    availableSettings: false,
  },
  {
    name: "Facebook",
    availableSettings: true,
  },
  {
    name: "GroupMe",
    availableSettings: false,
  },
  {
    name: "Telegram",
    availableSettings: true,
  },
  {
    name: "Slack",
    availableSettings: true,
  },
  {
    name: "Office 365",
    availableSettings: true,
  },
  {
    name: "Email",
    availableSettings: true,
  },
  {
    name: "Intercom",
    availableSettings: false,
  },
  {
    name: "Kik",
    availableSettings: false,
  },
  {
    name: "WeChat",
    availableSettings: false,
  },
];
