<template>
  <div class="section--footer">
    <button class="btn btn--purple gradient" type="button" v-if="saveable && hasSufficientPermission" @click="onSave"
            :disabled="isSaving">
      <i class="icon icon-save"></i>
      Save {{ saveContent }}
    </button>

    <button
      class="btn btn--purple gradient"
      type="button"
      v-if="publishable && hasSufficientPermission"
      @click="$parent.$emit('publish')"
      :disabled="isSyncActive"
    ><i class="icon icon-paperplane"></i>
      Publish

    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../services/tenants/permissions.service'

@Component
export default class BotSectionFooter extends Vue {
  private botName = '';
  private exportContent = '';
  private saveContent = '';

  @Prop() readonly type!: string;
  @Prop({ default: false }) readonly exportable: boolean;
  @Prop({ default: true }) readonly saveable: boolean;
  @Prop({ default: null }) readonly exportText: string;
  @Prop({ default: null }) readonly saveText: string;
  @Prop({ default: true }) readonly publishable: boolean;
  @Prop({ default: false }) readonly isSyncActive: boolean;
  @Prop() isSaving: boolean;

  private hasSufficientPermissionExport = false;
  private hasSufficientPermission = false;

  async beforeMount () {
    this.botName = this.$router.history.current.params.botName
    this.exportContent = this.exportText || this.type
    this.saveContent = this.saveText || this.type
    await this.loadNeededPermissions()
  }

  get permissions () {
    return Permissions
  }

  onSave () {
    this.$emit('on-save')
  }

  async loadNeededPermissions () {
    if (this.type == 'Entity') {
      this.hasSufficientPermissionExport = await permissionsService.hasPermission(
        this.botName,
        this.permissions.ExportIntents
      )
      this.hasSufficientPermission =
        (await permissionsService.hasPermission(
          this.botName,
          this.permissions.PublishIntentsAndEntities
        )) ||
        (await permissionsService.hasPermission(
          this.botName,
          this.permissions.ManageEntities
        ))
    } else if (this.type == 'Intent') {
      this.hasSufficientPermissionExport = await permissionsService.hasPermission(
        this.botName,
        this.permissions.ExportIntents
      )
      this.hasSufficientPermission =
        (await permissionsService.hasPermission(
          this.botName,
          this.permissions.PublishIntentsAndEntities
        )) ||
        (await permissionsService.hasPermission(
          this.botName,
          this.permissions.ManageIntents
        ))
    } else if (this.type == 'Subs') {
      this.hasSufficientPermission = this.hasSufficientPermissionExport = await permissionsService.hasPermission(
        this.botName,
        this.permissions.ManageSubflows
      )
    } else if (this.type == 'LLM') {
      this.hasSufficientPermission = this.hasSufficientPermissionExport = await permissionsService.hasPermission(
        this.botName,
        this.permissions.ManageLLMs
      )
    } else {
      this.hasSufficientPermission = this.hasSufficientPermissionExport = await permissionsService.hasPermission(
        this.botName,
        this.permissions.ManageFunctions
      )
    }
  }
}
</script>
