import { render, staticRenderFns } from "./GoogleSetup.vue?vue&type=template&id=72bb1adb&scoped=true"
import script from "./GoogleSetup.vue?vue&type=script&lang=ts"
export * from "./GoogleSetup.vue?vue&type=script&lang=ts"
import style0 from "./GoogleSetup.vue?vue&type=style&index=0&id=72bb1adb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72bb1adb",
  null
  
)

export default component.exports