<template>
  <div class="channel-restrictions">
    <button type="button" @click="isOpen = !isOpen" ref="channel-restrictions">
      <img :src="iconLink" />
      {{ isDataIsReady ? status : 'We are getting the data ....' }}
    </button>

    <div class="dropdown" id="dropdown-custom" v-if="isOpen" v-click-outside="{ exclude: ['channel-restrictions'], handler: 'toggle' }">
      <div class="dropdown-item cursor-pointer" v-for="channel in channels" :key="channel.name">
        <div class="channel-item" @click="channel.isOpen = !channel.isOpen">
          <div class="channel-title">
            <img :src="channel.icon" class="channel-icon" />
            <div>{{ channel.name }}</div>
          </div>
          <img :src="iconLinkSpecific(channel.name)" class="channel-status" />
        </div>

        <div class="channel-title channel-errors" v-show="channel.isOpen" v-for="(error, index) in channel.errors" :key="error + index">
          <img :src="errorIcon" />
          <div>
            <b>Error</b>
            <br />
            {{ error }}
          </div>
        </div>

        <div class="channel-title channel-errors" v-show="channel.isOpen" v-for="(warning, index) in channel.warnings" :key="warning + index">
          <img :src="warningIcon" />
          <div>
            <b>Warning</b>
            <br />
            {{ warning }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ClickOutside } from '../../../directives/click-outside'
import { BotChannelRules } from '../../../../../../common/connections/bot-channel-rules.model'

@Component({
  directives: {
    ClickOutside
  }
})
export default class ChannelRestrictions extends Vue {
  private isOpen = false

  private errorIcon = '/assets/icons/red-error.png'
  private warningIcon = '/assets/icons/orange-warning.png'
  private successIcon = '/assets/icons/green-check.png'

  @Prop() channels: BotChannelRules[]
  @Prop() isDataIsReady: boolean

  get iconLink() {
    if (this.isErrors()) return this.errorIcon
    else if (this.isWarnings()) return this.warningIcon
    else return this.isDataIsReady ? this.successIcon : ''
  }

  private isErrors() {
    return this.channels.some((e) => e.errors.length > 0)
  }

  private isWarnings() {
    return this.channels.some((e) => e.warnings.length > 0)
  }

  private iconLinkSpecific(channel: string) {
    if (this.channels.some((e) => e.errors.length > 0 && e.name == channel)) {
      return this.errorIcon
    } else if (this.channels.some((e) => e.warnings.length > 0 && e.name == channel)) {
      return this.warningIcon
    } else return this.successIcon
  }

  get status() {
    return this.isErrors() || this.isWarnings() ? 'Channel restrictions' : 'Works on all channels'
  }

  toggle() {
    this.isOpen = !this.isOpen
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/components/_dropdowns';
@import '../../../../assets/scss/variables';

.channel-restrictions {
  position: absolute;
  bottom: 55px;
  right: 400px;

  .dropdown {
    background-color: white;
    border-radius: 5px;
    position: absolute;
    bottom: 35px;
    padding: 10px;
    box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);

    &:before {
      content: '';
      position: absolute;

      right: 18px;
      width: 8px;
      height: 6px;
      border-left: solid 6px transparent;
      border-right: solid 6px transparent;
      bottom: -6px;
      border-top: solid 6px $default;
    }

    .dropdown-item {
      padding: 5px;
      .channel-item,
      .channel-title {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }

        .channel-icon {
          width: 25px;
          height: 25px;
        }

        .channel-status {
          width: 15px;
          height: 15px;
        }

        &.channel-errors {
          background-color: #fde2da;
          border-radius: 5px;
          width: 100%;
          padding: 0 10px;
          font-size: 12px;
          margin-top: 5px;
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  .channel-item {
    justify-content: space-between;
  }

  .channel-title {
    margin-right: 15px;
  }

  button {
    background: none;
    border: 0;
    margin-left: 160px;
  }

  img {
    width: 15px;
    height: 15px;
  }
}
</style>
