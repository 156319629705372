import axios from 'axios'

export class DescriptionService {
  async updateIntent (botName, intentName, description) {
    await axios.post(
            `${process.env.VUE_APP_BACKEND_URL}/${botName}/intents/update-description`, {
              intentName: intentName,
              description: description
            })
  }

  async updateEntity (botName, entityName, description) {
    await axios.post(
            `${process.env.VUE_APP_BACKEND_URL}/${botName}/entities/update-description`, {
              name: entityName,
              description: description
            })
  }

  async updateFunction (botName, functionName, description) {
    await axios.post(
            `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/functions/update-description`, {
              name: functionName,
              description: description
            })
  }

  async updateFunctionName (botName, functionName, newFunctionName) {
    return await axios.put(
            `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/functions/name`, {
              name: functionName,
              newName: newFunctionName
            })
  }
}

export default new DescriptionService()
