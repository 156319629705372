import { foodl } from './categories/foodl'
import { dialog } from './categories/dialog'
import { action } from './categories/action'
import { functions } from './categories/function'
import { system } from './categories/system'
import { llm } from './categories/llm'

export const possibleFunctions = {
  ...llm,
  ...foodl,
  ...dialog,
  ...action,
  ...functions,
  ...system
}
