import axios from 'axios'
import { IUser } from '../../../common/interfaces/tenant/user.interface'

export class UserRolesService {
  async getUsersForBotAndTeant (botName: string, tenantIdPresented: boolean) {
    const result: [] = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenants/usersForBot/${botName}/${tenantIdPresented}`).then(r => r.data)
    return result
  }

  async toggleTenantAdmin (userEmail: string) {
    await axios.put(`${process.env.VUE_APP_BACKEND_URL}/users/tenant-admin`, { userEmail })
  }

  async addUserRole (userEmail: string, botName: string, role: string) {
    return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/role`, {
      userEmail, botName, role
    })
  }

  async removeUserRoleForBot (email: string, botName: string) {
    return await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/users/${email}/roles/for-bot/${botName}`)
  }

  async getUsersForTenant (): Promise<IUser[]> {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/users`).then(r => r.data)
  }

  async deleteUser (email: string) {
    return await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/users/${email}`)
  }

  async getUserForTenant (email: string) {
    const result: [] = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/users/${email}`).then(r => r.data)
    return result
  }
}

export default new UserRolesService()
