<template>
  <section class="function-params">
    <div v-if="display && props">
      <h4 class="no-border margin-bottom-20">{{tittle}} Variables</h4>

    <div class="row margin-10">
      <div class="col-4 padding-right">
          <div class="form-group">
            <div class="form-group--inside">
              <autocomplete
                :items="autocompleteVariables"
                v-model="newProp.name"
                :placeholder="inputPlaceholder"
                :rows="1"
                class="blue-card first-input"
                :disabled="isGlobal"
              />
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="form-group">
            <div class="form-group--inside">
              <autocomplete
                :items="autocompleteVariables"
                v-model="newProp.value"
                :placeholder="outputPlaceholder"
                :rows="1"
                class="blue-card first-input"
                @enter="add"
                :disabled="isGlobal"
              />
              <div
                type="button"
                class="copybutton dark"
                v-if="!isGlobal"
                @click="add"
              >
                Add
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(prop, index) of props"
        :key="index"
        class="row margin-10"
      >
        <div class="col-4 padding-right">
          <div class="form-group">
            <div class="form-group--inside">
              <autocomplete
                :items="autocompleteVariables"
                v-model="prop[namePath]"
                :placeholder="inputPlaceholder"
                :rows="1"
                class="blue-card"
                :disabled="isGlobal"
              />
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="form-group">
            <div class="form-group--inside">
              <autocomplete
                :items="autocompleteVariables"
                v-model="prop[valuePath]"
                :placeholder="outputPlaceholder"
                :rows="1"
                class="blue-card"
                :disabled="isGlobal"
              />
               <i
                class="icon-delete inside-autocomplete function-delete"
                @click="$emit('remove', index)"
                v-if="!isGlobal"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Autocomplete from '../../../helpers/Autocomplete.vue'

@Component({ components: { Autocomplete } })
export default class FunctionParameters extends Vue {
    @Prop() display: boolean;
    @Prop() isGlobal: boolean;
    @Prop() props: any;
    @Prop() tittle: string;
    @Prop() inputPlaceholder: string;
    @Prop() outputPlaceholder: string;
    @Prop() autocompleteVariables: any;
    @Prop() namePath: string;
    @Prop() valuePath: string;

    private newProp = { name: '', value: '' };

    @Emit('add')
    add () {
      const temp = this.newProp
      this.newProp = { name: '', value: '' }
      return temp
    }
}
</script>

<style lang="scss" scoped>
.function-params {
  margin-top: 10px;
}
</style>
