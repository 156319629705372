<template>
  <Modal title="Import Entity" :show="true" @close="$emit('close')" customClass="modal-for-import">
    <ImportItem
      :file="file"
      mode="entity"
      :item="entityData"
      v-bind:name.sync="entityName"
      :isTaken="isTaken"
      @importItem="importEntity"
      @loadItem="loadEntity"
    ></ImportItem>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import entititesService from '../../../../services/bots/entities.service'
import ImportItem from '../ImportItem.vue'
import Modal from '../../../helpers/Modal.vue'

@Component({
  components: {
    ImportItem,
    Modal
  }
})
export default class ImportEntity extends Vue {
  @Prop() entities;
  @Prop() file: File;

  private importCode = '';
  private entityName = '';
  private entityData = null;
  private isTaken = false;

  async loadEntity () {
    this.entityData = await entititesService.validateImport(this.file)

    this.entityName = this.entityData.name
  }

  importEntity () {
    if (this.entities.some(e => e.name === this.entityName)) {
      this.isTaken = true
      return
    }
    this.isTaken = false

    this.$emit('import', {
      file: this.file,
      entityName: this.entityName
    })
  }
}
</script>
