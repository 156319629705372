<template>
  <ConfigurationContainer>
    <div class="col-12">
      <div class="contents__main">
        <div class="main-header">
          <div class="main-header__inner">
            <h1>Billing</h1>
          </div>
          <p>Here you can manage your billing</p>
        </div>

        <div class="form--wrapper">
          <form @submit.prevent="$emit('submit')">
            <StripeCustomerFormSection
              :stripeCustomer="stripeCustomer"
            ></StripeCustomerFormSection>

            <div class="billing-button">
              <StripeBillingButton></StripeBillingButton>
            </div>
            <div class="settings-button">
              <button class="btn btn--purple gradient" type="submit">
                <i class="icon icon-save"></i>
                Save settings
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ConfigurationContainer>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IStripeCustomerDto } from '../../../../../../common/interfaces/stripe-customer-dto.interface'
import ConfigurationContainer from '../../../helpers/ConfigurationContainer.vue'
import StripeBillingButton from '../components/StripeBillingButton.vue'
import StripeCustomerFormSection from '../components/StripeCustomerFormSection.vue'

@Component({
  components: { ConfigurationContainer, StripeBillingButton, StripeCustomerFormSection }
})
export default class MasterBilling extends Vue {
  @Prop() stripeCustomer: IStripeCustomerDto;
}
</script>

<style lang="scss" scoped>
.billing-button {
  display: inline-block;
  margin-top: 20px;
}
</style>
