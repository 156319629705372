import axios from 'axios'
import { NluProvider } from '../../../../common/types/nlu-provider'

export class BotNluService {
  async getNluStatus (botName: string, provider: NluProvider) {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu-status/${provider}`)).data
  }

  async createNluResource (botName: string, provider: NluProvider) {
    await axios.post(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu-resource/${provider}`)
  }

  async createNlu (botName: string, provider: NluProvider) {
    await axios.post(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu/${provider}`)
  }

  async deleteNlu (botName: string, provider: NluProvider) {
    await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu/${provider}`)
  }

  async runNlpTest (botName: string) {
    await axios.post(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu-test`)
  }

  async waitTest (botName: string) {
    let isRunning = true
    do {
      isRunning = await this.isTestRunning(botName)
      await new Promise(res => setTimeout(res, 1000))
    } while (isRunning)
  }

  private async isTestRunning (botName: string): Promise<boolean> {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu-test`).then(res => res.data.isRunning)
  }

  async updateNlpDataset (botName: string, file: File) {
    const formData = new FormData()
    formData.append('nlp-dataset', file, file.name)
    await axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu-dataset`, formData)
  }

  async getNlpDataset (botName: string) {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu-dataset`, { responseType: 'blob' })).data
  }

  async getNlpResultCsv (botName: string) {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu-results`, { responseType: 'blob' })).data
  }

  async getPrimaryNlp (botName: string): Promise<NluProvider> {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlu`)).data
  }

  async getConfiguredNlps (botName: string): Promise<NluProvider[]> {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/nlus`)).data
  }
}

export default new BotNluService()
