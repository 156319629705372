import axios from 'axios'

export class ExternalApiService {
    private readonly url: string;
    constructor (botName: string) {
      this.url = `${process.env.VUE_APP_BACKEND_URL}/external/${botName}`
    }

    async getBackendUrl () {
      return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/url`).then(res => res.data)
    }

    async generateNewKey () {
      const res = await axios.post(`${this.url}/api-key/generate`, { expiresOnDate: false })
      return res.data
    }

    async updateKeys (keys: any, conversationIdLifeTime: number) {
      const res = await axios.put(`${this.url}/api-key/update`, { apiKeys: keys, conversationIdLifeTime: conversationIdLifeTime })
      return res.status == 204
    }

    async deleteKey (keyId: string) {
      const res = await axios.delete(`${this.url}/api-key/${keyId}`)
      return res.status == 204
    }
}
