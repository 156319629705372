<template>
  <div class="container__date-wrapper">
    <div class="configuration-tabs configuration-tabs--left container--date">
      <div class="configuration-tabs--inner">
        <button
          type="button"
          @click="prevMonth"
          class="btn"
          :class="{ disabled: !isEnabled || !hasPrevMonth() }"
        >
          <div class="arrow"></div>
          Prev
        </button>
        <button
          type="button"
          @click="nextMonth"
          class="btn"
          :class="{ disabled: !isEnabled || !hasNextMonth() }"
        >
          Next
          <div class="arrow next"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Controls extends Vue {
  private currentMonth = 0;
  private currentYear = 0;

  @Prop({
    default: () => {
      return [];
    }
  })
  readonly months: Array<string>;
  @Prop({ default: 0 }) readonly currMonth: number;
  @Prop({ default: 0 }) readonly currYear: number;
  @Prop({ default: false }) readonly isEnabled: boolean;
  @Prop({ default: () => ({}) }) readonly lastValues: any;

  mounted() {
    this.currentMonth = this.currMonth;
    this.currentYear = this.currYear;
  }

  hasNextMonth() {
    return (
      this.currentYear < this.getYearIndex() ||
      this.currentMonth < this.getMonthIndex()
    );
  }

  hasPrevMonth() {
    return (
      this.currentYear > this.lastValues.year ||
      this.currentMonth > this.lastValues.month
    );
  }

  getMonthIndex() {
    return new Date().getMonth() + 1;
  }

  getYearIndex() {
    return new Date().getFullYear();
  }

  prevMonth() {
    const currMonth = this.currMonth
      ? Number(this.currMonth)
      : new Date().getMonth() + 1;
    const currYear = this.currYear
      ? Number(this.currYear)
      : new Date().getFullYear();

    let prevMonth, prevYear, prevprevMonth, prevprevYear;
    if (currMonth === 1) {
      prevMonth = 12;
      prevYear = currYear - 1;
    } else {
      prevMonth = currMonth - 1;
      prevYear = currYear;
    }

    if (prevMonth === 1) {
      prevprevMonth = 12;
      prevprevYear = prevYear - 1;
    } else {
      prevprevMonth = prevMonth - 1;
      prevprevYear = prevYear;
    }

    this.currentMonth = prevMonth;
    this.currentYear = prevYear;
    this.$emit("currMonth", this.currentMonth);
    this.$emit("currYear", this.currentYear);

    this.$emit("getPrevMonth", [prevprevMonth, prevprevYear, true]);
    this.$emit("currData");
  }

  nextMonth() {
    if (!this.hasNextMonth()) {
      return;
    }
    const prevMonth = this.currMonth
      ? Number(this.currMonth)
      : new Date().getMonth() + 1;
    const prevYear = this.currYear
      ? Number(this.currYear)
      : new Date().getFullYear();

    let currMonth, currYear;
    if (prevMonth === 12) {
      currMonth = 1;
      currYear = prevYear + 1;
    } else {
      currMonth = prevMonth + 1;
      currYear = prevYear;
    }

    this.currentYear = currYear;
    this.currentMonth = currMonth;
    this.$emit("currMonth", this.currentMonth);
    this.$emit("currYear", this.currentYear);
    this.$emit("getCurrMonth", [currMonth, currYear]);
    this.$emit("getPrevMonth", [prevMonth, prevYear]);
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.container {
  &__date-wrapper,
  &--date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .btn {
    &.disabled {
      pointer-events: none;
      opacity: 0.35;
    }
  }
  .arrow {
    display: inline-block;
    background: url("/public/assets/icons/arrow.svg") no-repeat;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    &.next {
      transform: rotate(180deg);
      margin-left: 6px;
      margin-right: 0;
    }
  }
  .current-month {
    font-size: 18px;
    font-weight: $normal;
    color: $default;
    margin-left: 19px;
  }
}
</style>
