<template>
  <Modal title="Nuance NLU" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form @submit.prevent="$emit('update-keys')">
        <h4>Nuance NLU</h4>
        <div class="form-section">
          <FormGroup label="Client Id">
            <input v-model="keys.clientId" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Client Secret">
            <input v-model="keys.clientSecret" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Model URN">
            <input v-model="keys.nluModelUrn" type="text" class="form-control" />
          </FormGroup>
        </div>
        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
           <i class="icon icon-save" ></i> Save keys
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Modal from '../../../../../helpers/Modal.vue'

@Component({ components: { FormGroup, Modal } })
export default class NuanceNLU extends Vue {
  @Prop() keys: any;
}
</script>
