export const system = {
  defaultSystem: {
    key: 'defaultSystem', functionName: 'Select a function', variables: [], category: 'System', hide: true
  },
  GetTicket: {
    key: 'GetTicket',
    functionName: 'Get Ticket',
    fields: [
      { id: 'ticketId', type: 'text', name: 'Get Ticket Id', placeholder: 'Ticket ID', rows: 1 }
    ],
    variables: ['SCSM'],
    category: 'System'
  },
  CreateTicket: {
    key: 'CreateTicket',
    functionName: 'Create Ticket',
    fields: [
      { id: 'name', type: 'text', name: 'Create Ticket Name', placeholder: 'Ticket Name', rows: 1 },
      { id: 'description', type: 'text', name: 'Create Ticket Description', placeholder: 'Ticket Description' },
      { id: 'status', type: 'text', name: 'Create Ticket Status', placeholder: 'Ticket Status', rows: 1 },
      { id: 'contact-method', type: 'text', name: 'Contact Method', placeholder: 'Preferred Contact Method', rows: 1 },
      { id: 'support-group', type: 'text', name: 'Support Group', placeholder: 'Support Group', rows: 1 },
      { id: 'classification', type: 'text', name: 'Classification', placeholder: 'ClassificationName', rows: 1 },
      { id: 'primary-owner', type: 'text', name: 'Primary Owner', placeholder: 'Primary Owner', rows: 1 },
      { id: 'urgent', type: 'checkbox', name: 'urgent', placeholder: 'Urgent', rows: 1 }
    ],
    variables: ['SCSM'],
    category: 'System'
  },
  EditTicket: {
    key: 'EditTicket',
    functionName: 'Edit Ticket',
    fields: [
      { id: 'ticketId', type: 'text', name: 'Edit Ticket Id', placeholder: 'Ticket ID', rows: 1 },
      { id: 'name', type: 'text', name: 'Edit Ticket Name', placeholder: 'Ticket Name', rows: 1 },
      { id: 'description', type: 'text', name: 'Edit Ticket Description', placeholder: 'Ticket Description' },
      { id: 'status', type: 'text', name: 'Edit Ticket Status', placeholder: 'Ticket Status', rows: 1 },
      { id: 'primary-owner', type: 'text', name: 'Primary Owner', placeholder: 'Primary Owner', rows: 1 }
      // { id: 'urgent', type: 'checkbox', name: 'urgent', placeholder: "Urgent" }
    ],
    variables: ['SCSM'],
    category: 'System'
  },
  GetTicketsOfUser: {
    key: 'GetTicketsOfUser',
    functionName: 'Get tickets of user',
    fields: [
    ],
    variables: ['SCSM'],
    category: 'System'
  },
  GetAssetsOfUser: {
    key: 'GetAssetsOfUser',
    functionName: 'Get assets of user',
    fields: [
      { id: 'typeFilterEnabled', type: 'checkbox', name: 'typeFilterEnabled', placeholder: 'Type Filter Enabled' },
      { id: 'typeFilter', type: 'text', name: 'Type Filter', placeholder: 'Type Filter', activeIf: 'typeFilterEnabled', rows: 1 },
      { id: 'modelFilterEnabled', type: 'checkbox', name: 'modelFilterEnabled', placeholder: 'Model Filter Enabled', rows: 1 },
      { id: 'modelFilter', type: 'text', name: 'Model Filter', placeholder: 'Model Filter', activeIf: 'modelFilterEnabled', rows: 1 }
    ],
    variables: ['SCSM'],
    category: 'System'
  },
  GetAssetDetails: {
    key: 'GetAssetDetails',
    functionName: 'Get asset details',
    fields: [
      { id: 'assetId', type: 'text', name: 'AssetDetails AssetId', placeholder: 'Asset ID', rows: 1 }
    ],
    variables: ['SCSM'],
    category: 'System'
  }
}
