export const availableSettingsSystems = [
  {
    name: "SAP",
    availableSettings: false,
  },
  {
    name: "Oracle",
    availableSettings: false,
  },
  {
    name: "Microsoft Dynamics",
    availableSettings: false,
  },
  {
    name: "UIPath",
    availableSettings: false,
  },
  {
    name: "Service Now",
    availableSettings: false,
  },
  {
    name: "Workday",
    availableSettings: false,
  },
  {
    name: "Power BI",
    availableSettings: false,
  },
  {
    name: "Salesforce",
    availableSettings: false,
  },
  {
    name: "HubSpot",
    availableSettings: false,
  },
  {
    name: "Automation Anywhere",
    availableSettings: false,
  },
  {
    name: "Blue Prism",
    availableSettings: false,
  },
  {
    name: "JIRA",
    availableSettings: false,
  },
  {
    name: "Zoho",
    availableSettings: false,
  },
  {
    name: "Office 365",
    availableSettings: false,
  },
  {
    name: "Microsoft SCSM",
    availableSettings: false,
  },
  {
    name: "Magento",
    availableSettings: true,
  },
  {
    name: "Salesforce Agent",
    availableSettings: true,
  },
];
