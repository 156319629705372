import { IStripeCustomerDto } from "../interfaces/stripe-customer-dto.interface"
import { ValidationError } from '../errors/validation.error'
import { countryKeys } from '../constants/countries.constant'

export const validateStripeCustomer = (customer: IStripeCustomerDto) => {
    if (!customer.customerName.trim()) throw new ValidationError('Invalid customer name!')
    if (!customer.address.trim()) throw new ValidationError('Invalid address!')
    if (!countryKeys.some(name => name === customer.country)) throw new ValidationError('Invalid country!')
    if (!customer.zipCode.trim()) throw new ValidationError('Invalid zip code!')
    if (!customer.city.trim()) throw new ValidationError('Invalid city!')
    if (!customer.phoneNumber.trim()) throw new ValidationError('Invalid phone number!')
}

export const validateStripeCustomerBool = (customer: IStripeCustomerDto) => {
    try {
        validateStripeCustomer(customer)
        return true
    } catch (_) {
        return false
    }
}