import FlowComponent from '../../FlowComponent'
import { IBlock } from '../../../../../../../../common/blocks/block.interface'

export default class LLMBlockComponent<T extends IBlock> extends FlowComponent<T> {
  constructor(element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions() {
    return null
  }
}
