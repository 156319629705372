<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }" @hide="hideLinkMenu()" v-if="!disabled">
      <div class="menubar__wrapper">
        <div class="say">{{ name }}:</div>

        <div class="menubar">
          <button class="menubar__button left" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
            <div class="letter">B</div>
          </button>
          <button class="menubar__button middle" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
            <div class="letter">
              <i>i</i>
            </div>
          </button>
          <button
            class="menubar__button right"
            @click="showLinkMenu(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }"
            :disabled="isLinkButtonDisabled() && !isActive.link()"
          >
            <i v-if="!isActive.link()" class="icon icon-link"></i>
            {{ isActive.link() ? 'Edit Link' : '' }}
          </button>
        </div>
        <div v-if="linkMenuIsActive" class="form--wrapper" @keypress.esc="hideLinkMenu()">
          <form id="create-function" class="modal h-40vh" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
            <header>
              <h1>Link editor</h1>
              <span class="close" @click="hideLinkMenu()"></span>
              <div class="modal-description">
                Use this form to add/links links
              </div>
            </header>
            <section class="content">
              <div class="form-group">
                <div class="form-group--inside">
                  <input type="text" v-model="linkUrl" placeholder="https://examlpe.com" ref="linkInput" @keydown.esc="hideLinkMenu()" />
                </div>
              </div>
              <footer class="modal-footer-button">
                <button class="btn btn--purple gradient" @click="setLinkUrl(commands.link, linkUrl)" type="button">
                  Save
                  <i class="icon icon-arrow-right"></i>
                </button>
                <button class="mr-10 btn btn--white red" type="button" @click="setLinkUrl(commands.link, null)">
                  Remove link
                </button>
              </footer>
            </section>
          </form>
        </div>
      </div>
    </editor-menu-bar>

    <editor-content class="da-input editor__content" style="height: auto; min-height: 80px; padding: 10px 20px; outline: none" :editor="editor" />

    <div class="autocomplete">
      <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
        <li class="loading" v-if="isLoading">Loading results...</li>
        <li v-else-if="results.length == 0">No items found</li>
        <li v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">
          {{ result }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, History, Italic, Link, Placeholder } from 'tiptap-extensions'

@Component({
  components: {
    EditorMenuBar,
    EditorContent
  }
})
export default class EditorComp extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  inputId

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  items

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  disabled

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  isLoading

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  placeholder

  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  rows

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  value

  @Prop({
    type: String,
    required: false,
    default: 'Your bot will say'
  })
  name

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  currentLanguage

  private isOpen = false
  private results = []
  private editor = null
  private search = ''
  private arrowCounter = 0
  private unclosedBracketRegex = /(?:^|[\/\\]{0,})\[([^\]]*)($)/
  private linkUrl = null
  private linkMenuIsActive = false

  @Watch('items')
  itemsWatch(val, oldValue) {
    if (val.length !== oldValue.length) {
      this.results = val
    }
  }

  @Watch('currentLanguage')
  currentLanguageWatch(val, oldValue) {
    if (val !== oldValue) {
      this.editor.setContent(this.value)
    }
  }

  mounted() {
    this.search = this.value
    document.addEventListener('click', this.handleClickOutside)
    this.search = this.value
    const self = this
    document.addEventListener('keydown', function(e) {
      const key = e.which || e.keyCode
      if (key === 38) {
        self.onArrowUp(e)
      } else if (key === 40) {
        self.onArrowDown(e)
      } else if (key === 13) {
        self.onEnter(e)
      }
    })
  }

  filterResults() {
    // [Entity][index]
    // [Entity][property]
    // [click](link)
    // first uncapitalize all the things
    const match = this.unclosedBracketRegex.exec(this.search)
    if (!!match && match[0].charAt(0) != '/' && match[0].charAt(0) != '\\') {
      this.isOpen = !!match

      if (this.isOpen) {
        const [, keyword] = match

        this.results = this.items.filter(item => item.toLowerCase().includes(keyword.toLowerCase()))
      }
    }
  }

  setResult(result) {
    if (result && result[0].charAt(0) != '/' && result[0].charAt(0) != '\\') {
      this.search = this.value.replace(this.unclosedBracketRegex, `[${result}]`)
      this.editor.setContent(this.search)
      this.isOpen = false
      this.$emit('input', this.search)
    }
  }

  onArrowDown(evt) {
    if (this.isOpen) {
      evt.preventDefault()
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter++
      }
    }
  }

  onArrowUp(evt) {
    if (this.isOpen) {
      evt.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter--
      }
    }
  }

  onEnter(event) {
    if (this.isOpen) {
      event.preventDefault()
      this.setResult(this.results[this.arrowCounter])
      this.isOpen = false
      this.arrowCounter = -1
    }
  }

  handleClickOutside(evt) {
    if (!this.$el.contains(evt.target)) {
      this.isOpen = false
      this.arrowCounter = -1
    }
  }

  created() {
    this.editor = new Editor({
      extensions: [
        new Bold(),
        new Italic(),
        new History(),
        new Link(),
        new Placeholder({
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.placeholder,
          showOnlyWhenEditable: false
        })
      ],
      content: this.value,
      onUpdate: ({ getHTML }) => {
        const data = {
          inputId: this.inputId,
          content: getHTML()
        }

        this.search = data.content.replace(/<\/?[^>]+(>|$)/g, '')
        data.content = this.search
        this.filterResults()
        this.$emit('change', data)
      }
    })
  }

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
    document.removeEventListener('keydown', this.onArrowDown)
    document.removeEventListener('keydown', this.onArrowUp)
    document.removeEventListener('keydown', this.onEnter)
  }

  isLinkButtonDisabled() {
    const { selection, state } = this.editor
    const { from, to } = selection
    const selectedText = state.doc.textBetween(from, to, ' ')

    return selectedText.trim() == ''
  }

  beforeDestroy() {
    this.editor.destroy()
  }

  showLinkMenu(attrs) {
    this.linkUrl = attrs.href
    this.linkMenuIsActive = true
    this.$nextTick(() => {
      const tmp: any = this.$refs.linkInput
      tmp.focus()
    })
  }

  hideLinkMenu() {
    this.linkUrl = null
    this.linkMenuIsActive = false
  }

  setLinkUrl(command, url) {
    command({ href: url })
    this.hideLinkMenu()
  }
}
</script>

<style lang="scss">
@import '../../helpers/autocomplete';

.editor {
  height: 100%;

  .say {
    font-size: 12px;
    float: left;
    color: $default-dark;
    font-weight: bold;
    padding-top: 10px;
  }

  .menubar {
    display: flex;
    background: $white;
    float: right;
    margin-bottom: 5px;

    .icon {
      display: inherit;
      height: 16px;
      margin: 2px 0 0 5px;
    }

    .letter {
      color: $default-dark;
      font-family: 'Rubik', sans-serif;
    }

    .menubar__button {
      margin: 0px;
      background-color: white;
      box-shadow: none;
      min-width: 40px;
      height: 28px;
      border: 1px solid $light-blue;

      &:active,
      &:focus,
      &:hover {
        background-color: $blue;

        .icon-link {
          background: url('/public/assets/icons/icon-dm-link-active.svg') no-repeat;
        }
        .letter {
          font-weight: bold;
        }
      }

      &.left {
        border-radius: 6px 0px 0px 6px;
      }
      &.middle {
        border-radius: 0px 0px 0px 0px;
      }
      &.right {
        border-radius: 0px 6px 6px 0px;
      }
    }
  }

  p {
    margin-bottom: 0;
  }
}

.editor p.is-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: $button;
  pointer-events: none;
  height: 0;
}

.mr-10 {
  margin-right: 10px;
}
</style>
