import { NameValuePair } from './placeholder.helper';
import { ValidationError } from '../errors/validation.error';

export function parseAdaptiveCardJson(json: string, data: NameValuePair) {
    try {
        const cardRaw = JSON.parse(json);
        if (!cardRaw.$data) cardRaw.$data = {};
        Object.keys(data).forEach(key => {
            const val = data[key];
            let result = val;

            const valAsNumber = Number(val)
            if (!Number.isNaN(valAsNumber)) {
                result = valAsNumber;
            } else {
                try {
                    const valAsObj = JSON.parse(val)
                    result = valAsObj
                } catch (_) { }
            }

            cardRaw.$data[key] = result;
        })
        return cardRaw;
    } catch (_) {
        throw new ValidationError("The JSON you entered is not a valid adaptive card!");
    }
}