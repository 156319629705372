import axios from 'axios'

export class ActivityTrackerService {
  private readonly maxIdleTime = 5 * 60 * 1000;

  private forceExitTimeout?: number;

  private pingInterval: number | number = null;

  public onUserIdle: () => void;

  private isActive: boolean;

  private activityEvents = ['mousedown', 'keypress', 'scroll', 'touchstart'];

  private resetFunctionReference = this.resetTimer.bind(this);

  startIdleTimer (onUserIdle: () => void) {
    this.isActive = true
    this.onUserIdle = onUserIdle
    this.timerElapsed()
    this.activityEvents.forEach(evnt => document.addEventListener(evnt, this.resetFunctionReference, { passive: true }))
  }

  timerElapsed () {
    if (!this.isActive) { this.onUserIdle() } else {
      this.isActive = false
      this.forceExitTimeout = Number(setTimeout(() => this.timerElapsed(), this.maxIdleTime))
    }
  }

  resetTimer () {
    this.isActive = true
  }

  cleanup (containerId: string) {
    clearTimeout(this.forceExitTimeout)
    clearInterval(this.pingInterval)
    this.activityEvents.forEach(evnt => document.removeEventListener(evnt, this.resetFunctionReference))
    this.unlock(containerId)
  }

  getLockStatus (containerId: string) {
    return axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${containerId == '' ? 'none' : containerId}/editor`)
      .then(() => false)
      .catch(() => true)
  }

  async initHeartBeat (containerId: string, onLockChange: (isLocked: boolean) => any) {
    await this.ping(containerId, onLockChange)
    this.pingInterval = Number(setInterval(() => this.ping(containerId, onLockChange), 3 * 1000))
  }

  private ping (containerId: string, onLockChange: (isLocked: boolean) => any) {
    return this.getLockStatus(containerId).then(onLockChange)
  }

  private async unlock (containerId: string) {
    return axios.delete(`${process.env.VUE_APP_BACKEND_URL}/bots/${containerId == '' ? 'none' : containerId}/editor`)
  }
}
