<template>
<div class="box">
  <div class="title">
  {{title}}
  </div>
  <div v-for="(item, index) in buttons" :key="index">
    <button>
      <div class="textBtn">
      {{item.value}}
      </div>
      </button>
</div>
</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ShowTitleAndChoices extends Vue {
  @Prop() title: string;
  @Prop() buttons: string[];
}
</script>

<style lang='scss' scoped>
@import "@/assets/scss/components/_chat.scss";

.box {
  width: fit-content;
  height: 100%;
  background-color: $received-background;
  border-radius: 8px 8px 8px 0;
  border: 0;
  padding: 10px 15px 8px;

  .title {
    text-align: left;
    font-size: 17px;
    line-height: 22.61px;
    color: rgb(0, 0, 0);
    font-weight: 600;
  }

  button {
    width: 100%;
    color: $sent-background;
    padding: 0;
    margin-top: 7px;
    border: 0;
    border-radius: 4px;

    .textBtn {
      font-size: 14px;
      color: #425B76;
      padding: 12px;
    }
  }
}

</style>
