<template>
  <div class="form-group form-group--options">
    <div>
      <div class="label">Select entity</div>
      <Select
        id="is-of-type-selector"
        :elements="closedListEntities"
        :key="currentBlock.customData.isCanonicalEntity"
        keyAttr="name"
        v-model="currentBlock.customData.isCanonicalEntity"
        @input="updateLabel()"
      ></Select>
    </div>
    <div>
     <div class="label margin"> and Canonical form </div>
      <Select
        id="is-of-type-selector"
        :elements="canonicals"
        :key="currentBlock.customData.isCanonical"
        keyAttr="canonicalForm"
        v-model="currentBlock.customData.isCanonical"
        @input="updateLabel()"
      ></Select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IIsCanonicalBlock } from '../../../../../../../../../common/blocks/connector/is-canonical-block.interface'
import Select from '../../../../../../helpers/Select.vue'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component({
  components: {
    Select
  }
})
export default class IsCanonical extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<IIsCanonicalBlock>;
  @Prop({ default: () => [] }) readonly entities!: any[];

  mounted () {
    this.updateLabel()
  }

  get closedListEntities () {
    return this.entities.filter(e => e.type == 'closedlists')
  }

  get canonicals () {
    const entity = this.closedListEntities.find(e => e.name == this.currentBlock.customData.isCanonicalEntity)
    return entity ? entity.sublists : []
  }

  @Emit()
  updateLabel () {
  }
}
</script>
