<template>
  <div>
    <h6>This function will not work with simple entities. Please read our user-manual for more information.</h6>
    <div class="form-group options">
      <label for="function">
        First entity (max. 10)
        <Tooltip
          title="These are the entities and the error messages this block expects from the user."
          placement="bottom"
        ></Tooltip>
      </label>

      <div
        v-for="(v, index) in choices"
        :key="currentBlock.customData['field-entities'][index].entity"
      >
        <div class="form-group--inside">
          <Select
            :id="'condition'+index"
            :elements="getAvailableEntities(index)"
            selectedAttr="name"
            keyAttr="name"
            valueAttr="name"
            v-model="currentBlock.customData['field-entities'][index].entity"
            class="dropdown-entities"
            :key="availableEntitiesKey"
            @input="() => availableEntitiesKey = !availableEntitiesKey"
            :disabled="!hasSufficientPermission"
          ></Select>

          <i v-if="hasSufficientPermission" class="icon-delete" @click="removeOption(index)"></i>
        </div>

        <div class="form-group--inside">
          <editor
            :items="variableNames"
            :placeholder="getMessagePlaceholder(index)"
            :value="currentBlock.customData['field-entities'][index].message"
            @change="onEditorChange($event, index)"
            :key="availableEntitiesKey"
            :disabled="!hasSufficientPermission"
          />
        </div>
        <div class="function" v-if="index+1 < currentBlock.customData['field-entities'].length">
          <hr>
            <div v-if="index+1 == 1">Second entity</div>
            <div v-else-if="index+1 == 2">Third entity</div>
            <div v-else>{{index+2}}th entity</div>
          </div>
      </div>

    <div class="option-button">
      <div
          class="btn btn--white dialogmanager"
          @click="addOption()"
          v-if="choices.length < entitiesFromExamples.length && choices.length < 10 && hasSufficientPermission">
        Add new
        <i class="icon icon-add"></i>
      </div>
    </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FlowComponent from '../../../../modules/FlowComponent'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import Select from '../../../../../../helpers/Select.vue'
import Editor from '../../../../Editor.vue'
import { IMultipleEntityCheck } from '../../../../../../../../../common/blocks/dialog/multiple-entity-check-block.interface'
import { EntityChecker } from '../../../../../../../../../common/blocks/dialog/entity-checker.interface'

@Component({
  components: {
    Select,
    Tooltip,
    Editor
  }
})
export default class MultipleEntityCheckExtension extends Vue {
  private choices: Array<EntityChecker> = null;
  private availableEntitiesKey = false;
  @Prop({ default: () => { return {} } }) readonly currentBlock!: FlowComponent<
    IMultipleEntityCheck
  >;

  @Prop({ default: () => [] }) readonly variableNames!: Array<string>;
  @Prop({ default: () => [] }) readonly entitiesFromExamples: Array<string>;
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  getAvailableEntities (index) {
    const entity = this.currentBlock.customData['field-entities'][index].entity
    const usedEntities = this.currentBlock.customData['field-entities'].map(
      e => e.entity
    )
    return this.entitiesFromExamples.filter(
      e => !usedEntities.includes(e) || e == entity
    )
  }

  created () {
    if (!this.currentBlock.customData['field-entities']) { this.currentBlock.customData['field-entities'] = [] }

    this.choices = new Array(
      this.currentBlock.customData['field-entities'].length
    )
  }

  onEditorChange (event, index) {
    /* eslint-disable */
    const { _, content } = event
    /* eslint-enable */
    this.currentBlock.customData['field-entities'][index].message = content
  }

  addOption () {
    if (
      this.choices.length >= this.entitiesFromExamples.length &&
      this.choices.length >= 10
    ) { return }
    this.choices.push(null)
    this.currentBlock.customData['field-entities'].push({
      entity: '',
      message: ''
    })
  }

  removeOption (index: number) {
    this.choices.splice(index, 1)
    this.currentBlock.customData['field-entities'].splice(index, 1)
  }

  getMessagePlaceholder (index) {
    return `Error message when ${this.currentBlock.customData['field-entities'][
      index
    ].entity || 'this entity'} is not present`
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../../assets/scss/variables";

.form-group--inside {
  margin-bottom: 15px;
  .dropdown-entities {
    width: 85%;
  }
}

h6 {
  font-size: 12px;
  color: $grey;
}

.function{
    font-size: 14px;
    font-weight: 400;
    color: #979797;
    line-height: 18px;
    margin: 0 0 8px;
}

.icon-delete {
  position: absolute;
  top: 17px;
  right: 14px;
}
</style>
