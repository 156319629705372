<template>
  <div>
    <FlowEditor
      v-if="subFlowName"
      mode="functions"
      :entryItemName="subFlowName"
      :container="container"
      :funcs="funcs"
      :variables="variables"
      :objects="objects"
      @close="closeSubFlow()"
    />
    <Loading v-if="isLoadingFlow"></Loading>
    <div class="content-overlay" v-show="!subFlowName">
      <Modal v-if="isLocked === true" title="Locked" :show="true" customClass="dm-lock" @close="$emit('close')">
        <p>
          Someone in your organisation is currently editing the conversation for this bot. Please try again in a few minutes.
        </p>
        <div class="bottom-right-fix">
          <button @click="$emit('close')" class="btn btn--white"><i class="icon icon-back"></i>Go back</button>
        </div>
      </Modal>

      <div class="flow-modal" v-else-if="isLocked === false">
        <section class="flow-editor">
          <div class="bpmn-editor">
            <div class="top-panel">
              <div class="selection-bar">
                <SelectionBar
                  :items="items"
                  :entryItem="entryItem"
                  :mode="mode"
                  @open-item="loadFlow"
                  @showVariables="bpmnManagement.showVariables = $event"
                  @show-statistics="showStatistcsFlag = $event"
                  @refresh-statistics="refreshStatistics"
                ></SelectionBar>
              </div>
              <div class="functions-bar">
                <FunctionsBar
                  v-if="currentItem.content && currentItem.content.xmlArray"
                  :currentIntent="currentItem"
                  :botName="container.botName"
                  :mode="mode"
                  :isSaving="isSaving"
                  :autoPublishStatus="autoPublishStatus"
                  :componentKey="functionsBarKey"
                  @open-version="openVersion"
                  @change-version="version => (currentVersion = version)"
                  @reset="reset"
                  @clear="clear"
                  @export="exportItem"
                  @import="importItem"
                  @save="saveItem"
                  @publish="publishItem"
                  @publish-test="publishTestItem"
                  @publish-all="publishAllItems"
                  @publish-test-all="publishAllTestItems"
                  @update:autoPublish="setAutoPublish"
                ></FunctionsBar>
              </div>
            </div>
            <div :id="bpmnManagement.canvasId" class="canvas"></div>
          </div>
          <aside class="right-bar">
            <BlockConfiguration
              :currentBlock="currentBlock"
              :container="container"
              :bots="bots"
              :bpmnManagement="bpmnManagement"
              :currentItem="currentItem"
              :functions="functions"
              :isWelcomeIntent="mode == 'intents' && currentItem.name == 'Welcome Intent'"
              :mode="mode"
              :channels="channels"
              :snippets="snippets"
              :objects="objects"
              :settings="settings"
              @blockChanged="blockChanged"
              @close="close()"
              @updateRestrictions="updateChannels"
              @updateFunctionsRestrictions="updateFunctionsRestrictions"
            ></BlockConfiguration>
          </aside>

          <footer>
            <ZoomButtons :bpmn="bpmnManagement.bpmn"></ZoomButtons>
            <AutoValidation
              :autoValidation="autoValidationStatus.autoValidation"
              :isAutoValidationDisabled="autoValidationStatus.isUpdating"
              @update:autoValidation="setAutoValidation"
            ></AutoValidation>
            <ChannelRestrictions :channels="channels" :isDataIsReady="isChannelsLoaded"></ChannelRestrictions>
          </footer>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ZoomButtons from './bottom-panels/ZoomButtons.vue'
import AutoValidation from './bottom-panels/AutoValidation.vue'
import ChannelRestrictions from './bottom-panels/ChannelRestrictions.vue'
import FunctionsBar from './top-panels/FunctionsBar.vue'
import SelectionBar from './top-panels/SelectionBar.vue'
import BlockConfiguration from './block-config/BlockConfiguration.vue'
import { BpmnManagementService } from './modules/bpmn-management.service'
import preventBodyScroll from '../../_mixins/prevent-body-scroll'
import errorHandling from './modules/error-handling.service'
import fileService from '../../../services/file.service'
import popupService from '../../../services/popup.service'
import botIntentsService from '../../../services/bots/bot-intents.service'
import botFunctionsService from '../../../services/bots/bot-functions.service'
import { Permissions } from '../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../services/tenants/permissions.service'
import { saveAs } from 'file-saver'

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { FlowServiceBase } from '../../../services/bots/flow.service.base'
import { AUTO_SAVED_FLOW_HISTORY, UNPUBLISHED_FLOW_HISTORY } from '../../../../../common/constants/bpmn.constant'
import { BotChannelRules } from '../../../../../common/connections/bot-channel-rules.model'
import { ConfigurationService } from '../../../services/bots/settings/configuration.service'
import { PublishedType } from '../../../../../common/types/flow.type'
import botService from '../../../services/bots/bot.service'
import FlowComponent from './modules/FlowComponent'
import { IBlock } from '../../../../../common/blocks/block.interface'
import flowComponentService from './modules/flow-component.service'
import { ChannelRestrictionsType } from '../../../../../common/types/restrictions.type'
import { FlowError } from '../../../../../common/errors'
import { IFileBlock } from '../../../../../common/blocks/file-block.interface'
import { SnippetService } from '../../../services/bots/snippets.service'
import { GeneralSettingsService } from '../../../services/bots/settings/general-settings.service'
import Modal from '../../helpers/Modal.vue'
import Loading from '../../helpers/Loading.vue'
import usersService from '../../../services/users.service'
import { FlowStatisticsService } from './modules/flow-statistics.service'

@Component({
  name: 'FlowEditor',
  components: {
    ZoomButtons,
    AutoValidation,
    FunctionsBar,
    SelectionBar,
    BlockConfiguration,
    ChannelRestrictions,
    Modal,
    Loading
  }
})
export default class FlowEditor extends Mixins(preventBodyScroll) {
  private bpmnManagement = new BpmnManagementService()
  private configurationService: ConfigurationService
  private flowStatisticsService?: FlowStatisticsService
  private currentBlock: FlowComponent<IBlock> = null
  private currentItem = null
  private currentVersion = null
  private bots = []
  private autoPublishStatus = {
    autoPublish: false,
    isUpdating: false
  }

  autoValidationStatus = {
    autoValidation: false,
    isUpdating: false
  }

  isLocked = null

  private errors = []

  private isChannelsLoaded = false

  private channels: BotChannelRules[] = []
  private snippets: any[] = []
  private settings: any = {}

  private subFlowName = ''

  private isLoadingFlow = false

  private isSaving = false

  functionsBarKey = 0

  @Prop({ default: () => ({}) }) readonly container: any
  @Prop({ default: () => ({}) }) readonly variables: any
  @Prop({ default: () => ({}) }) readonly objects: any
  @Prop({ default: () => [] }) readonly funcs: any[]
  @Prop({ default: 'intents' }) readonly mode: 'intents' | 'functions'
  @Prop({ default: 'Welcome Intent' }) readonly entryItemName: string

  created() {
    this.toggleScroll(true)
    const self = this
    window.addEventListener(
      'beforeunload',
      function(event) {
        if (self.bpmnManagement.isFlowEdited()) {
          event.preventDefault()
          event.returnValue = true
        }
      },
      false
    )
    this.currentItem = this.entryItem
  }

  get items() {
    return this.mode === 'intents'
      ? [this.container.entryIntent].concat(this.container.intents).filter(x => !!x && x.type === 'normal')
      : this.funcs.filter(f => f.type === 'flow')
  }

  get functions() {
    return this.mode === 'functions'
      ? this.funcs.filter(f => f.name != this.currentItem.name)
      : this.funcs.filter(f => {
          f.outputs = f.outputs.filter(o => o.propertyPath != '' || o.variableName != '')
          f.inputs = f.inputs.filter(i => i.defaultValue != '' || i.name != '')
          return true
        })
  }

  get entryItem() {
    return this.items.find(f => f.name === this.entryItemName) || this.items[0]
  }

  get flowService(): FlowServiceBase {
    return this.mode === 'intents' ? botIntentsService : botFunctionsService
  }

  private showStatistcsFlag = false
  @Watch('showStatistcsFlag')
  onShowStatisticsChanged(newVal: boolean) {
    if (newVal) this.showStatistics()
    else this.flowStatisticsService?.hideFlowStatistics()
  }

  private showStatistics() {
    this.flowStatisticsService?.showFlowStatistics(this.mode === 'intents' ? 'intent' : 'function', this.currentItem.name, this.currentVersion)
  }

  private refreshStatistics() {
    this.flowStatisticsService?.refreshFlowStatistics(this.mode === 'intents' ? 'intent' : 'function', this.currentItem.name, this.currentVersion)
  }

  async mounted() {
    return await this.init()
  }

  async init() {
    this.isLocked = await this.bpmnManagement.activityTrackerService.getLockStatus(this.container.id)
    if (this.isLocked) return

    await this.$nextTick()
    this.bpmnManagement.initialize(
      this.container,
      this.variables,
      this.objects,
      this.onUserIdle,
      newLockState => (this.isLocked = newLockState),
      subFlowName => (this.subFlowName = subFlowName),
      () => this.onFlowLoaded()
    )

    // Build available custom functions (exclude current function)
    this.bpmnManagement.blockStorage.buildCustomFunctions(this.functions)
    // Add inputs and outputs to variables if current item is Sub
    if (this.mode == 'functions') {
      this.bpmnManagement.variableService.setSubVariables(this.currentItem)
    }

    this.bpmnManagement.onElementSelected(selected => (this.currentBlock = selected))

    this.configurationService = new ConfigurationService(this.container.botName)
    this.flowStatisticsService = new FlowStatisticsService(this.container.botName, this.bpmnManagement.blockStorage)
    await Promise.all([
      this.loadLatestXml(this.entryItem),
      this.getConfiguration(),
      this.getSnippets(),
      this.getSettngs(),
      this.loadBots(),
      this.loadAutoPublish(),
      this.loadAutoValidation()
    ])
  }

  private checkForFlowErrorsInterval: number
  private handleFlowAutoValidation() {
    clearInterval(this.checkForFlowErrorsInterval)
    if (this.autoValidationStatus.autoValidation) {
      this.checkForFlowErrorsInterval = setInterval(() => {
        this.validateFlow()
      }, 60 * 1000)
    }
  }

  onFlowLoaded() {
    this.showStatistcsFlag ? this.showStatistics() : undefined
    this.validateFlow()
    this.handleFlowAutoValidation()
  }

  async confirmUnsavedChanges() {
    if (
      (await permissionsService.hasPermission(this.container.botName, Permissions.ManageIntents)) ||
      (await permissionsService.hasPermission(this.container.botName, Permissions.ManageFlowsInDialogManager))
    ) {
      return !this.bpmnManagement.isFlowEdited() || this.warn('Warning!', 'You have unsaved changes!', 'save', 'Save')
    }
    return true
  }

  async loadBots() {
    this.bots = await botService.getBotNameAndId()
  }

  async loadAutoPublish() {
    this.autoPublishStatus.autoPublish = await usersService.getFlowAutoPublish()
  }

  async setAutoPublish(val: boolean) {
    try {
      this.autoPublishStatus.isUpdating = true
      this.autoPublishStatus.autoPublish = val
      await usersService.setFlowAutoPublish(val)
    } catch (err) {
      this.autoPublishStatus.autoPublish = !val
      throw err
    } finally {
      this.autoPublishStatus.isUpdating = false
    }
  }

  async loadAutoValidation() {
    this.autoValidationStatus.autoValidation = await usersService.getFlowAutoValidation()
  }

  async setAutoValidation(val: boolean) {
    try {
      this.autoValidationStatus.isUpdating = true
      this.autoValidationStatus.autoValidation = val
      await usersService.setFlowAutoValidaiton(val)
    } catch (err) {
      this.autoValidationStatus.autoValidation = !val
      throw err
    } finally {
      this.autoValidationStatus.isUpdating = false
      this.handleFlowAutoValidation()
    }
  }

  async getConfiguration() {
    const data = await this.configurationService.getConfiguration()
    this.channels = data.channels.map(e => new BotChannelRules(e)).filter(ch => ch.connected == true)
  }

  async getSnippets() {
    this.snippets = await new SnippetService(this.container.botName).getSnippets()
  }

  async getSettngs() {
    this.settings = (await new GeneralSettingsService(this.container.botName).getSettings()).settings
  }

  private loadBlocksRestrictions() {
    this.bpmnManagement.blockStorage.loadAllFunctionsRestrictions(this.container.functions)
    this.bpmnManagement.blockStorage.loadAllRestrictions()
    this.updateChannels()
  }

  private async loadFlow($event) {
    await this.loadLatestXml($event, false)
  }

  private toggleIsLoadingFlow() {
    this.isLoadingFlow = !this.isLoadingFlow
  }

  @Watch('currentBlock.customData', { deep: true })
  updateBlock() {
    this.updateChannels()
  }

  @Watch('isLoadingFlow')
  private toggleScreenInteractions() {
    document.body.style.pointerEvents = this.isLoadingFlow ? 'none' : 'auto'
    document.body.style.overflow = this.isLoadingFlow ? 'hidden' : 'visible'
  }

  private updateChannels() {
    const restrictions: ChannelRestrictionsType = flowComponentService.getRestrictionsArray()
    this.channels.forEach(c => {
      c.errors = restrictions[c.name] ? restrictions[c.name].errors : []
      c.warnings = restrictions[c.name] ? restrictions[c.name].warnings : []
    })
    this.isChannelsLoaded = true
  }

  private blockChanged(block: FlowComponent<IBlock>) {
    if (this.currentBlock && block.category != 'Function') {
      flowComponentService.deleteRestrictions(this.currentBlock.id, this.currentBlock.customData.fc)
      this.currentBlock = block
      this.currentBlock.updateRestrictions()
      this.updateChannels()
    }
  }

  private updateFunctionsRestrictions(customFunction: any) {
    if (customFunction) {
      flowComponentService.deleteRestrictions(this.currentBlock.id, this.currentBlock.customData.fc)
      flowComponentService.updateFunctionsRestrictions(customFunction)
      this.updateChannels()
    }
  }

  async warn(heading: string, description: string, mode: string, button = 'Publish', cancelButton?: string) {
    const answer = await popupService.showDialog(heading, description, button, cancelButton)
    if (answer) {
      if (mode === 'publish') this.publishItem()
      else if (mode === 'save') {
        this.saveItem()
      }
      return false
    }

    return true
  }

  async confirmUnpublishedChanges(isClosing = true) {
    if (this.currentVersion && isClosing && this.currentItem!.content.labeledVersions && this.currentItem!.content.labeledVersions[0]) {
      return (
        this.isVersionPublished() ||
        this.warn('Warning!', 'Your latest intent flow is saved, but you have not activated it to channels yet.', 'publish', 'Activate', 'Do later')
      )
    }
    return true
  }

  isVersionPublished() {
    return [
      this.currentItem!.content.labeledVersions[0] ? this.replaceVersionName(this.currentItem!.content.labeledVersions[0].name) : '',
      this.currentItem!.content.testVersions[0] ? this.replaceVersionName(this.currentItem!.content.testVersions[0].name) : ''
    ].includes(this.replaceVersionName(this.currentVersion))
  }

  replaceVersionName(version) {
    return version ? version.replace('v', '').replace('*', '') : ''
  }

  resetErrors() {
    errorHandling.markAsSuccess()
  }

  reset() {
    this.resetErrors()
    this.bpmnManagement.resetFlow(this.currentItem.name)
  }

  clear() {
    this.resetErrors()
    this.bpmnManagement.clearFlow()
  }

  async close() {
    this.bpmnManagement.showVariables = false
    if ((await this.confirmUnsavedChanges()) && (await this.confirmUnpublishedChanges(true))) {
      this.$emit('close')
    }
  }

  async loadLatestXml(item, isInitial = false) {
    this.resetErrors()
    if ((await this.confirmUnsavedChanges()) && (await this.confirmUnpublishedChanges(isInitial))) {
      this.toggleIsLoadingFlow()
      await this.bpmnManagement.clearFlow(false)
      await this.flowService.setContent(this.container.botName, item)
      this.currentItem = item

      // Build available custom functions (exclude current function)
      this.bpmnManagement.blockStorage.buildCustomFunctions(this.functions)
      // Add inputs and outputs to variables if current item is Sub
      if (this.mode == 'functions') {
        this.bpmnManagement.variableService.setSubVariables(this.currentItem)
      }

      if (!item.content) this.bpmnManagement.clearFlow()
      else if (item.content.xmlArray.length > 0) {
        await this.bpmnManagement.loadBpmnXml(item.content.xmlArray[0].xml)
      } else await this.bpmnManagement.loadBpmnXml(item.content.xml)

      this.loadBlocksRestrictions()
      this.toggleIsLoadingFlow()
      this.functionsBarKey++
    }
  }

  async openVersion(xml: string) {
    await this.bpmnManagement.loadBpmnXml(xml)
  }

  async exportItem() {
    const isWelcomeItem = this.currentItem.name === 'Welcome Intent'
    const currentXml = await this.bpmnManagement.getXml()
    const response = await this.flowService.compressFlow(currentXml)

    saveAs(response, `${isWelcomeItem ? 'welcome_intent' : this.currentItem.name}.json`)
  }

  async importItem(file) {
    this.resetErrors()
    try {
      const contents = await fileService.readJsonFile(file)
      const decompressed = await this.flowService.decompressFlow(contents)
      try {
        await this.flowService.validateImportFlow(this.bpmnManagement, decompressed)
      } catch (missingFunctions) {
        const message = missingFunctions
          .toString()
          .split('-')
          .filter((f, index, all) => index == all.indexOf(f))
          .join(',')
          .replace('Error: ', '')
        popupService.showWarning(`${message}`)
      }
      await this.saveItem()
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }
      popupService.showError('Failed to load the file ' + error.message)
    }
  }

  async saveItem() {
    try {
      this.isSaving = true
      await this.uploadFiles()
      const xml = await this.bpmnManagement.getXml()
      const response = await this.flowService.saveFlow(this.container.botName, this.currentItem.name, xml)
      if (response.xml) this.currentItem.content.xmlArray.unshift(response)
      this.bpmnManagement.snapshotInitialState()
      this.resetErrors()
      popupService.showInfo('Flow saved!')
      await this.validateFlow()
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }

      if (error instanceof FlowError) {
        popupService.showError(error.message)
        errorHandling.markAsError([{ blockId: error.blockId, message: error.message }], this.bpmnManagement.blockStorage)
      } else {
        popupService.showError('Error with the flow! Could you check if everything alright')
      }
    } finally {
      this.isSaving = false

      await this.$nextTick()
      if (this.autoPublishStatus.autoPublish) {
        this.mode === 'functions' ? this.publishItem(true) : this.publishTestItem(true)
      }
    }
  }

  async validateFlow() {
    const response = await this.flowService.validateFlow(this.container.botName, this.currentItem.name, await this.bpmnManagement.getXml(), true)
    if (response && response[0] && response[0].message) {
      this.handleErrors(response)
    } else {
      this.resetErrors()
    }
  }

  async uploadFiles() {
    const fileUploads = this.bpmnManagement.blockStorage
      .allComponents()
      .filter(c => c.customData.fc === 'sendAttachment' || c.customData.fc === 'sendGif') as FlowComponent<IFileBlock>[]

    // Validate new files (and remove components that don't need file uploading)
    for (let i = 0; i < fileUploads.length; i++) {
      const component = fileUploads[i]
      component.validateFiles()

      /* eslint-disable */
      const file = component.customData.file
      /* eslint-enable */

      // File remains unchanged (no new file is selected)
      if (file && Object.keys(file).length === 0 && file.constructor === Object) {
        fileUploads.splice(i--, 1)
      } // Remove this component from the list
    }

    // Upload files
    const responses = await Promise.all(
      fileUploads.map(component => {
        /* eslint-disable */
        const file = component.customData.file
        if (file) {
          const formData = new FormData()
          formData.append(component.customData['field-input'], file)
          return botIntentsService.uploadFile(this.container.botName, formData)
        } else {
          return [] as any
        }
      })
    )

    // Add references to the uploaded files
    responses.forEach((res, index) => {
      const component = fileUploads[index]
      this.$set(component.customData, 'fileId', res.fileId)
      this.$set(component.customData, 'fileExtension', res.fileExtension)
      this.$set(component.customData, 'contentType', res.contentType)
      component.serializeData()
    })
  }

  publishItem(autoPublish = false) {
    this.publish('labeledVersions', this.currentItem, this.currentVersion, { autoPublish, showPopup: true })
  }

  publishAllItems() {
    this.allItemsPublish('labeledVersions')
  }

  publishTestItem(autoPublish = false) {
    this.publish('testVersions', this.currentItem, this.currentVersion, { autoPublish, showPopup: true })
  }

  publishAllTestItems() {
    this.allItemsPublish('testVersions')
  }

  allItemsPublish(label: PublishedType) {
    Promise.all(
      this.items.map(async workflow => {
        await this.flowService.setContent(this.container.botName, workflow)
        for (let i = 0; i < workflow.content.xmlArray.length; i++) {
          const item = workflow.content.xmlArray[i]
          if (!item.status || item.status == UNPUBLISHED_FLOW_HISTORY) {
            return await this.publish(label, workflow, item.version, { silent: true })
          }
        }
      })
    ).then(() => popupService.showInfo(`All flows are activated!`))
  }

  private async publish(
    publishType: PublishedType,
    workflow: any,
    version: number | string,
    { autoPublish, showPopup, silent }: { autoPublish?: boolean; showPopup?: boolean; silent?: boolean } = {}
  ) {
    try {
      const fn = publishType === 'labeledVersions' || this.mode === 'functions' ? this.flowService.savePublishedVersion : this.flowService.saveTestVersion
      const item = workflow.content.xmlArray.find(i => i.version == version) || workflow.content
      let xml = ''

      if (item && (item.version || item.xml)) {
        xml = decodeURI(item.xml)
      } else if (!item) throw new Error('No version like this')

      if (item && item.status === AUTO_SAVED_FLOW_HISTORY) {
        if (autoPublish) {
          return
        } else {
          throw new Error('Saved')
        }
      }

      const response = await fn(this.container.botName, workflow.name, xml, item.version || 0, true)
      if (response && response[0] && response[0].message) {
        this.handleErrors(response)
      } else {
        workflow.content[publishType] = [response]
        this.resetErrors()
        if (showPopup && !autoPublish) {
          popupService.showInfo(`Flow ${workflow.name} activated!`)
        }
        this.functionsBarKey--
      }
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }
      if (!silent) popupService.showError(error.message || 'Error with activating the flow')
    }
  }

  async onUserIdle() {
    const xml = await this.bpmnManagement.getXml()
    const response = await this.flowService.saveAutoFlow(this.container.botName, this.currentItem.name, xml)
    if (response.xml) this.currentItem.content.xmlArray.unshift(response)

    this.$emit('close')
  }

  handleErrors(response: any[]) {
    errorHandling.markAsError(
      response.filter(element => element.type === 'error'),
      this.bpmnManagement.blockStorage
    )
  }

  beforeDestroy() {
    this.toggleScroll(false)
    this.bpmnManagement.activityTrackerService.cleanup(this.container.id)
    clearInterval(this.checkForFlowErrorsInterval)
  }

  closeSubFlow() {
    this.subFlowName = ''
    this.bpmnManagement.pallete.refresh()
  }
}
</script>

<style lang="scss">
@import '../../../../public/bpmn/bpmn.css';
@import '../../../../public/bpmn/default-diagramjs.css';
@import '../../../../public/bpmn/diagramjs.css';
@import '../../../assets/scss/setup/_mixins';
@import '../../../assets/scss/components/_chat.scss';
@import '../../../assets/scss/components/main-bubble.scss';

$functions-bar-width: 320px;
$selection-bar-width: 80px;
$default-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.08);
$default: white;

.flow-modal {
  @include center-absolute(100%, 100%);
  position: initial;
  background-color: white;
  z-index: 100;
  border-radius: 6px;
  overflow: hidden;

  .outputs {
    z-index: 4;
  }

  .options {
    z-index: 4;
  }

  .image {
    z-index: 3;
  }
}

.content-overlay .modal {
  @include center-absolute(690px, 40vh);
}

.flow-editor {
  height: 100%;
  display: flex;

  .bpmn-editor {
    flex-grow: 1;
    background-color: #f4fbff;

    .canvas {
      height: 100%;

      .djs-search-container {
        display: none;
      }

      .djs-container .djs-palette {
        position: absolute;
        background: #fff;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: $default-shadow;
        box-shadow: $default-shadow;
        height: 100%;
        width: $selection-bar-width;
        top: 0;
        left: 0;
        overflow: auto;
      }
    }

    .top-panel {
      position: absolute;
      left: calc(#{$selection-bar-width} + 30px);
      width: calc(100% - #{$selection-bar-width} - #{$functions-bar-width} - 60px);
      padding: 30px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 9;
      // width: auto;
      // margin: 30px 290px 0 110px;
      pointer-events: none;
      @media only screen and (min-width: 1440px) {
        margin-right: 350px;
      }

      > * {
        pointer-events: auto;
      }

      .selection-bar {
        height: 50px;
      }

      .functions-bar {
        height: 50px;
        min-width: 400px;
      }
    }

    .errors-list {
      position: fixed;
      top: 210px;
      left: 140px;
      width: 100%;
      max-width: 540px;
      z-index: 15;
      margin-bottom: 15px;

      &.hidden {
        display: none;
      }

      .errors-list__wrapper {
        position: relative;

        &.warnings--wrapper {
          .ignore {
            i {
              background-image: url('/public/assets/icons/orange-close-icon.svg');
            }
          }
        }

        &.errors--wrapper {
          .ignore {
            i {
              background-image: url('/public/assets/icons/orange-close-icon.svg');
            }
          }
        }

        &.hidden {
          display: none;
        }

        .ignore {
          display: block;
          position: absolute;
          top: 10px;
          right: 5px;
          border: none;
          background: none;
          margin: 0;
          padding: 0;
          width: 20px;
          height: 20px;
          cursor: pointer;

          &.hidden {
            display: none;
          }

          i {
            position: relative;
            display: inline-block;
            vertical-align: top;
            top: 5px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 10px;
            height: 10px;
          }
        }
      }

      .warnings-header {
        background-color: #f98a13;
        color: white;
        padding: 3px;

        .ignore-warnings {
          float: right;
          background-color: #ad5e0a;
          color: white;
          outline: 0;
          border: 1px solid #774004;
          cursor: pointer;
        }
      }

      .errors-header {
        background-color: #f2212c;
        color: white;
        padding: 3px;
      }

      .warnings {
        border: 1px solid #f68d2e;
        padding: 18px 20px;
        background-color: #fcdcc0;
        // border-top-left-radius: 6px;
        // border-top-right-radius: 6px;
        border-radius: 6px;
        margin-bottom: 20px;
        // border-bottom: 0;
        &.blurred * {
          opacity: 0.5;
        }

        .error-box {
          border-color: #f68d2e;

          .error-element,
          .error-text {
            color: #cf6709;
          }
        }
      }

      .errors {
        border: 1px solid #bc204b;
        padding: 12px 20px;
        background-color: #f2d2db;
        // border-bottom-left-radius: 6px;
        // border-bottom-right-radius: 6px;
        // border-top: 0;
        border-radius: 6px;

        .error-box {
          .error-element,
          .error-text {
            color: #bc204b;
          }
        }
      }

      .error-box {
        border-bottom: 1px solid #bc204b;
        margin-bottom: 8px;
        padding: 0 0 12px;

        .error-element {
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          margin-bottom: 2px;
        }

        .error-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }

        &:last-child {
          border: 0;
          margin: 0;
          padding: 0;
        }
      }

      .ignore-warnings-button {
        position: absolute;
        right: 30px;
        top: 5px;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        border-radius: 6px;
        background: white;
        color: black;
      }
    }
  }

  .right-bar {
    width: $functions-bar-width;
    box-shadow: $default-shadow;
  }

  #chat {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;

    .bot-chat__overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(black, 0.4);
    }
  }
}

.form-group {
  .set-elements {
    position: relative;
    margin: 10px 0;
  }

  .form-group--checkbox:first-child {
    margin-top: 15px;
  }
}
</style>
