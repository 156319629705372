<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import TextPill from '@/components/helpers/TextPill.vue'
import FormGroup from '@/components/helpers/FormGroup.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'
import { CluAppLanguage } from '../../../../../../../common/enums/microsoft/clu-app.language.enum'
import permissionsService from '@/services/tenants/permissions.service'
import { Permissions } from '../../../../../../../common/enums/tenant/user-permissions.enum'

@Component({
  components: { Dropdown, FormGroup, TextPill },
  computed: {
    selectedLanguage: {
      get () {
        return 'Select a Language'
      },
      set (value: string) {
        if (value !== 'Select a Language') {
          this.addLanguage(value)
        }
      }
    }

  }
})
export default class MultiLanguageSettings extends Vue {
  @Prop({ required: true }) readonly settings: any;

  private hasSufficientPermission = false;

  async beforeMount () {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.settings.botName,
      this.permissions.ManageGeneralSettings
    )
  }

  get permissions () {
    return Permissions
  }

  private providerSupportedLanguages () {
    return Object.keys(CluAppLanguage).filter((key) => !this.languages().includes(key)).filter((key) => key !== this.defaultLanguage())
  }

  private languages () {
    return (this.settings.supportedLanguages || []).filter((language: string) => language !== this.defaultLanguage())
    // .map((language: CluAppLanguage) => Object.keys(CluAppLanguage).find((key) => CluAppLanguage[key] === language) || '')
  }

  private defaultLanguage () {
    return Object.keys(CluAppLanguage).find((key) => CluAppLanguage[key] === this.settings.language) || ''
  }

  addLanguage (language: string) {
    if (!this.settings.supportedLanguages) {
      this.settings.supportedLanguages = []
    }
    if (this.settings.supportedLanguages.includes(language)) return
    this.settings.supportedLanguages.push(language)
  }

  deleteLanguage (language: string) {
    if (CluAppLanguage[language] === this.settings.language) return
    this.settings.supportedLanguages = this.settings.supportedLanguages.filter((lang: CluAppLanguage) => lang !== language)
  }
}
</script>

<template>
  <div class="wrapper-inside multi-language-settings active">
    <div class="settings-wrapper">
      <FormGroup label="Supported Languages" tooltip="Here you can select the supported languages for your bot</br>
    </br>
    After selecting your supported languages please go to the Dialog Manager to change the responses based on the detected language</br>
    </br>
    This feature has only works with Microsoft CLU</br>
    The default bot language cannot be disabled">
        <select v-model="selectedLanguage" @change="addLanguage($event.target.value)" :disabled="!hasSufficientPermission">
          <option value="Select a Language" disabled>Select a Language</option>
          <option v-for="(language, key) in providerSupportedLanguages()" :key="key" :value="language">
            {{ language }}
          </option>
        </select>
        <section class="selected-languages">
          <TextPill class="default-language" v-show="hasSufficientPermission"
                    :text="defaultLanguage()"
                    :id="defaultLanguage()"
                    @delete="deleteLanguage($event)"
                    :disabledDelete="true"
          />
          <TextPill v-for="(language) in languages()" :key="language"
                    v-show="hasSufficientPermission"
                    :text="language"
                    :id="language"
                    @delete="deleteLanguage($event)"
          />
        </section>
      </FormGroup>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../../../../src/assets/scss/setup/colors';

.selected-languages {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.default-language {
  background-color: $main;
  color: $white;
  font-weight: bold;
}

.settings-wrapper {
  height: 100%;
}
</style>
