import { Component, Vue } from 'vue-property-decorator'
import { SnippetService } from '@/services/bots/snippets.service'
import { CreateSnippetForm } from '../../interfaces/create.snippet.form'
import popupService from '@/services/popup.service'

@Component
export default class ChatBubbleManagementMixin extends Vue {
  snippetsService: SnippetService;
  snippets = [];
  snippetUrl = '';
  snippetForm: CreateSnippetForm = {
    showForm: false,
    snippet: { name: '', version: '', description: '' }
  };

  initChatBubble (botName: string) {
    this.snippetsService = new SnippetService(botName)
  }

  getSnippets () {
    this.snippetsService.getSnippets()
      .then(snippets => {
        this.snippets = snippets
      })
    SnippetService.getSnippetUrl()
      .then(url => this.snippetUrl = url)
  }

  async createSnippet () {
    try {
      const createdSnippet = await this.snippetsService.createSnippet(this.snippetForm.snippet)

      this.snippetForm.showForm = false
      this.snippetForm.snippet = { name: '', version: '', description: '' }
      this.snippets.push(createdSnippet)
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Failed to create snippet!')
    }
  }

  async updateSnippet (snippet: any) {
    await this.snippetsService.updateSnippet(snippet)
    popupService.showInfo('Saved!')
  }

  async renameSnipet (data: any) {
    await this.snippetsService.renameSnippet(data, data.newName)
    popupService.showInfo('Saved!')
  }

  async deleteSnippet (snippet: any) {
    try {
      if (await popupService.showDialog('Warning!', 'Are you sure you want to delete this chat bubble?', 'Delete')) {
        await this.snippetsService.deleteSnippet(snippet.name)
        this.snippets = this.snippets.filter(s => s !== snippet)
      }
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }
      popupService.showError('Failed to delete snippet!')
    }
  }
}
