import axios from 'axios'

export class StatisticsService {
  async batchDeleteUntrainedExamples (botName: string, untrainedExamples: any) {
    await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/${botName}/statistics/unrecognized`, { data: { examples: untrainedExamples } })
  }

  async downloadCsv (botName: string) {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/message-history`, { responseType: 'blob' })
    return res.data
  }

  async downloadConsentCsv (botName: string) {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/consent`, { responseType: 'blob' })
    return res.data
  }

  async getUnexpectedAnswerData (botName: string) {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/${botName}/statistics/unexpectedAnswer/data`)
    return res.data
  }

  async deleteUnexpectedAnswerObject (botName: string, objectId: string) {
    const res = await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/${botName}/statistics/unexpectedAnswer/${objectId}`)
    return res.status
  }

  async getFlowStatistics (botName: string, flowOrigin: string, flowName: string, flowVersion: string) {
    const res = await axios.get(
      `${process.env.VUE_APP_BACKEND_URL}/${botName}/flow-statistics`,
      { params: { flowOrigin, flowName, flowVersion } }
    )
    return res.data
  }
}

export default new StatisticsService()
