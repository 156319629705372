import { parseNumber } from "./number.helper";

export function getFieldValue(key: string, obj: Object | Array<any>) {
    if (typeof obj !== "object" || !key) return obj;

    return key.split('.').reduce((innerObj, subKey) => {
        // If we have JSON
        if (typeof innerObj === 'string') {
            try {
                innerObj = JSON.parse(innerObj)
            } catch (_) { }
        }
        // If we have an array
        if (Array.isArray(innerObj)) {
            let index = parseNumber(subKey);

            if (index !== null && Number.isInteger(index)) {
                // if index is negative, we start from the last element
                if (index < 0) index = innerObj.length + index;
                return innerObj[index];
            }
        }
        return innerObj && (innerObj as any)[subKey];
    }, obj);
}

/** @description transforms object values
 * @param tFunc - transforming function
 */
export function transformObjectValues<T, K>(obj: { [key: string]: T }, tFunc: (value: T) => K): { [key: string]: K } {
    // @ts-ignore
    Object.keys(obj).forEach(k => obj[k] = tFunc(obj[k]));
    // @ts-ignore
    return obj;
}

/** picks the given properties (as strings) from the object (obj) and returns the new (obj) with the selected properties */
export function pick<T, TProp extends keyof T>(obj: T, ...props: TProp[]){
    return props.reduce((result, prop) => ({ ...result, [prop]: obj[prop] }), {}) as Pick<T, TProp>;
}

export function getKeyByValue(obj: any, value: any) { 
    return Object.keys(obj).find(key => Array.isArray(obj[key])? obj[key].find((e: any) => e == value) : obj[key] == value);
}