export const loadingTexts = [
  'Locating the required gigapixels to render...',
  'Warming up the flux capacitor...',
  'Shovelling coal into the server...',
  'Spinning up the hamster...',
  'Brewing some colombian coffee...',
  'Waking up the chatbot goeroes....',
  'Busy beating the turing test...',
  'Aligning neural networks...',
  'Calculating the meaning of life...',
  'Generating witty loading text...',
  'Summoning the internet gods...',
  'Polishing the algorithms...',
  'Unleashing AI magic...',
  'Preparing for data influx...',
  'Reticulating splines...',
  'Organizing the data soup...',
  'Synthesizing quantum algorithms...',
  'Simulating parallel universes...',
  'Compressing time and space...',
  'Calibrating virtual sensors...',
  'Mapping the neural pathways...',
  'Harvesting digital resources...',
  'Decrypting cosmic patterns...',
  'Decoding the matrix...',
  'Channeling digital spirits...',
  'Conjuring digital fairies...',
  'Syncing interdimensional data...',
  'Feeding the data monster...',
  'Activating machine learning modules...',
  'Boosting algorithmic performance...',
  'Infusing AI with wisdom...',
  'Setting up the data pipeline...',
  'Igniting computational engines...',
  'Balancing binary equations...',
  'Loading quantum bits...',
  'Calculating pi to the last digit...',
  'Harmonizing data frequencies...',
  'Wiring up the data circuits...',
  'Initializing virtual servers...',
  'Engaging data thrusters...',
  'Reprogramming the matrix...',
  'Executing the mainframe protocols...',
  'Tuning the data strings...',
  'Reinforcing digital boundaries...',
  'Scrambling data packets...',
  'Activating subroutine scripts...',
  'Cross-referencing algorithms...',
  'Integrating cognitive models...',
  'Distilling data essence...',
  'Unlocking hidden patterns...',
  'Mobilizing AI resources...',
  'Reinforcing neural nets...',
  'Firing up the data engines...',
  'Combining data dimensions...',
  'Compiling digital knowledge...',
  'Splicing data streams...',
  'Honing algorithmic precision...',
  'Navigating the data ocean...',
  'Refining computational models...',
  'Orchestrating data symphony...',
  'Evolving the neural net...',
  'Scanning the data frontier...',
  'Deploying virtual assistants...',
  'Accelerating data particles...',
  'Running deep learning routines...',
  'Crunching the numbers...',
  'Rendering infinite possibilities...',
  'Downloading the digital genome...',
  'Sifting through data haystacks...',
  'Predicting the unpredictable...',
  'Analyzing cosmic data...',
  'Reconstructing the digital realm...',
  'Upgrading neural pathways...',
  'Fusing data fragments...',
  'Processing quantum information...',
  'Instantiating the digital landscape...',
  'Composing the algorithmic symphony...',
  'Connecting the digital dots...',
  'Engaging in data alchemy...',
  'Transforming data to wisdom...',
  'Expanding the digital horizon...',
  'Breaching the data continuum...',
  'Calibrating virtual realities...',
  'Blending data dimensions...'
]
