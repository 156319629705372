import { Component, Vue } from 'vue-property-decorator'
import popupService from '@/services/popup.service'
import botService from '@/services/bots/bot.service'
import statisticsService from '@/services/bots/statistics.service'
import { saveAs } from 'file-saver'
import botNlpPerformanceService from '@/services/bots/bot-nlu.service'

@Component
export default class DataManagementMixin extends Vue {
  botName: string
  isDeletionInProgress = false
  isNlpTestRunning = false

  initData(name: string) {
    this.botName = name
  }

  async runNlp() {
    try {
      await botNlpPerformanceService.runNlpTest(this.botName)
      this.isNlpTestRunning = true
      popupService.showInfo('NLU model performance test started', 'We will notify you when the test finishes')
      botNlpPerformanceService.waitTest(this.botName).then(_ => {
        popupService.showInfo('NLU model performance tests finished')
        this.isNlpTestRunning = false
      })
    } catch (_) {
      popupService.showError('Already running')
    }
  }

  async uploadNlpDataset(file: File) {
    await botNlpPerformanceService.updateNlpDataset(this.botName, file)
    popupService.showInfo('Updated!')
  }

  async downloadNlpDataset() {
    const blob = await botNlpPerformanceService.getNlpDataset(this.botName)
    saveAs(blob, 'nlp-dataset.csv')
  }

  async downloadNlpResults() {
    const blob = await botNlpPerformanceService.getNlpResultCsv(this.botName)
    saveAs(blob, 'nlp-results.csv')
  }

  async downloadCsv(botName = this.botName) {
    const blob = await statisticsService.downloadCsv(botName)
    saveAs(blob, `bot-${botName}-history.csv`)
  }

  async downloadConsentCsv() {
    const blob = await statisticsService.downloadConsentCsv(this.botName)
    saveAs(blob, `bot-${this.botName}-gdpr-consent.csv`)
  }

  async exportBot(botName = this.botName) {
    const blob = await botService.exportBot(botName)
    saveAs(blob, `bot-${botName}.enc`)
  }

  async deleteBot(botName = this.botName): Promise<boolean> {
    this.isDeletionInProgress = true
    try {
      await botService.deleteBot(botName)
      this.isDeletionInProgress = false
      return true
    } catch (error) {
      this.isDeletionInProgress = false
      throw error
    }
  }
}
