export enum AmazonTTSLanguage {
    'Arabic' = 'arb',
    'Chinese, Mandarin' = 'cmn-CN',
    Danish = 'da-DK',
    Dutch = 'nl-NL',
    'English, Australian' = 'en-AU',
    'English, British' = 'en-GB',
    'English, Indian' = 'en-IN',
    'English, US' = 'en-US',
    'English, Welsh' = 'en-GB-WLS',
    French = 'fr-FR',
    'French, Canadian' = 'fr-CA',
    Hindi = 'hi-IN',
    German = 'de-DE',
    Icelandic = 'is-IS',
    Italian = 'it-IT',
    Japanese = 'ja-JP',
    Korean = 'ko-KR',
    Norwegian = 'nb-NO',
    Polish = 'pl-PL',
    'Portuguese, Brazilian' = 'pt-BR',
    'Portuguese, European' = 'pt-PT',
    Romanian = 'ro-RO',
    Russian = 'ru-RU',
    'Spanish, European' = 'es-ES',
    'Spanish, Mexican' = 'es-MX',
    'Spanish, US' = 'es-US',
    Swedish = 'sv-SE',
    Turkish = 'tr-TR',
    Welsh = 'cy-GB'
}