/** Supported Twilio text-to-speech languages */
export enum TwilioTextToSpeech {
    'Catalan, Spain' = 'ca-ES',
    'Chinese (Cantonese)' = 'zh-HK',
    'Chinese (Mandarin)' = 'zh-CN',
    'Chinese (Taiwanese Mandarin)' = 'zh-TW',
    'Danish, Denmark' = 'da-DK',
    'Dutch, Netherlands' = 'nl-NL',
    'English, Australia' = 'en-AU',
    'English, Canada' = 'en-CA',
    'English, India' = 'en-IN',
    'English, UK' = 'en-GB',
    'English, United States' = 'en-US',
    'Finnish, Finland' = 'fi-FI',
    'French, Canada' = 'fr-CA',
    'French, France' = 'fr-FR',
    'German, Germany' = 'de-DE',
    'Italian, Italy' = 'it-IT',
    'Japanese, Japan' = 'ja-JP',
    'Korean, Korea' = 'ko-KR',
    'Norwegian, Norway' = 'nb-NO',
    'Polish-Poland' = 'pl-PL',
    'Portuguese, Brazil' = 'pt-BR',
    'Portuguese, Portugal' = 'pt-PT',
    'Russian, Russia' = 'ru-RU',
    'Spanish, Mexico' = 'es-MX',
    'Spanish, Spain' = 'es-ES',
    'Swedish, Sweden' = 'sv-SE' 
}