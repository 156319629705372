import {
    PermissionError, ValidationError, NotFoundError, InvalidAttachmentException,
    ConfigurationError, CohesionError, FlowError, PrebuiltEntityError
} from '.';
import { BlitsError } from './blits.error';
import { FunctionError } from './function.error';
import { UsersLimitError } from './users-limit.error';
import { SynchronizationError } from './sync.error';
import { SynchronizationWarning } from './sync.warning';
import { StripeError } from './stripe.error';
import { StripePaymentError } from './stripe.payment.error';
import { UserNotFoundError } from './user-not-found.error';
import { InvalidJwtError } from './invalid-jwt.error';
import { UserNotConfirmedError } from './user-not-confirmed.error';
import { UnauthorizedError } from './unauthorized.error';
import { ExpiredJwtError } from './expired-jwt.error';
import { UserNotAssignedToTenantError } from './user-not-assigned-to-tenant.error';

export function parseError(error: any) {
    if (error instanceof Object && 'code' in error) {

        switch (error.code) {
            case PermissionError.CODE: return new PermissionError(error.requiredPermission, error.message);
            case ValidationError.CODE: return new ValidationError(error.message, error.fieldName);
            case NotFoundError.CODE: return new NotFoundError(error.message, error.details);
            case ConfigurationError.CODE: return new ConfigurationError(error.message);
            case FlowError.CODE: return new FlowError(error.message);
            case CohesionError.CODE: return new CohesionError(error.message);
            case PrebuiltEntityError.CODE: return new PrebuiltEntityError(error.message);
            case InvalidAttachmentException.CODE: return new InvalidAttachmentException(error.message);
            case FunctionError.CODE: return new FunctionError(error.message, error.functionType, error.details);
            case UsersLimitError.CODE: return new UsersLimitError(error.message);
            case SynchronizationError.CODE: return new SynchronizationError(error.message, error.provider, error.internalMessage);
            case SynchronizationWarning.CODE: return new SynchronizationWarning(error.message, error.provider, error.internalMessage);
            case StripeError.CODE: return new StripeError(error.message, error.additionalMessage);
            case StripePaymentError.CODE: return new StripePaymentError(error.message, error.additionalMessage);
            case UserNotFoundError.CODE: return new UserNotFoundError(error.message);
            case InvalidJwtError.CODE: return new InvalidJwtError(error.message);
            case ExpiredJwtError.CODE: return new ExpiredJwtError(error.message);
            case UserNotAssignedToTenantError.CODE: return new UserNotAssignedToTenantError(error.message);
            case UserNotConfirmedError.CODE: return new UserNotConfirmedError(error.message);
            case UnauthorizedError.CODE: return new UnauthorizedError(error.message);
            default: return BlitsError.createInternal(error.message, error.internalMessage);
        }
    }

    if (error instanceof Error)
        return error;

    return new Error(JSON.stringify(error));
}