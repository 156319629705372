<template>
  <Modal title="Microsoft Luis Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form v-if="keys.weHostLuis" @submit.prevent="$emit('delete-luis')">
        <h4>Currently, Blits is hosting the Cognitive Service resource and Luis app.</h4>

        <div v-if="customEngineContracts" class="section--footer static">
          <button class="btn btn--white red" type="submit">
            Delete Luis Configuration
          </button>
        </div>
      </form>

      <form v-else @submit.prevent="$emit('update-keys')">
        <div v-if="customEngineContracts" class="form-section">
          <h4>Luis Authorization</h4>

          <FormGroup label="Authoring Key">
            <input v-model="keys.authoringKey" type="text" class="form-control" />
          </FormGroup>
        </div>

        <div class="bottom-right-fix">
          <button v-if="customEngineContracts" class="btn btn--purple gradient" type="submit">
           <i class="icon icon-save" ></i>  Save settings
          </button>
          <button class="btn btn--white" type="button" @click="$emit('configure-luis')">
            Let us configure Luis
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'

@Component({
  components: { FormGroup, Select, Modal }
})
export default class Luis extends Vue {
  @Prop() keys: any;
  @Prop({ default: () => [] }) readonly languages: any
  @Prop() readonly customEngineContracts: boolean;
}
</script>

<style>
.inv-main-button {
  margin-left: 15px;
}
</style>
