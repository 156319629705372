<template>
  <div class="row">
    <div class="form-group col-8">
      <label v-if="isFirstInput" for="popupMessages">Popup message</label>
      <input
        type="text"
        v-model="popupMessage.message"
        @keydown.enter.prevent="$emit('submit')"
        :placeholder="isFirstInput ? 'Type new message here and press Enter' : undefined"
      />
    </div>
    <div class="form-group col-4">
      <label v-if="isFirstInput" for="delayPopupMessage">Delay for popup message (in seconds)</label>
      <input
        type="number"
        min="0"
        v-model="popupMessage.delay"
        @keydown.enter.prevent="$emit('submit')"
        :placeholder="isFirstInput ? 'Type new delay and press Enter' : undefined"
      />
      <i v-if="!isFirstInput" class="icon-delete entity-delete" @click="$emit('delete')"></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ChatBubblePopupMessageInput extends Vue {
  @Prop() popupMessage: any;
  @Prop({ default: false }) isFirstInput: boolean;
}
</script>

<style>

</style>
