<template>
  <Modal title="Import Function" :show="true" @close="$emit('close')" customClass="modal-for-import">
    <ImportItem
      :file="file"
      mode="function"
      :item="funcData"
      v-bind:name.sync="funcName"
      :isTaken="isTaken"
      :botName="botName"
      @importItem="importFunc"
      @loadItem="loadFunc"
    ></ImportItem>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import functionsService from '../../../../services/bots/functions.service'
import ImportItem from '../ImportItem.vue'
import Modal from '../../../helpers/Modal.vue'

@Component({
  components: {
    ImportItem,
    Modal
  }
})
export default class ImportFunction extends Vue {
  @Prop() functions = [];
  @Prop() file: File;
  @Prop() botName: string;

  private importCode = '';
  private funcName = '';
  private funcData = null;
  private isTaken = false;

  async loadFunc () {
    this.funcData = await functionsService.validateImport(this.file)
    this.funcName = this.funcData.name
  }

  importFunc () {
    if (this.functions.some(f => f.name === this.funcName)) {
      this.isTaken = true
      return
    }
    this.isTaken = false

    this.$emit('import', {
      file: this.file,
      funcName: this.funcName,
      isGlobal: false
    })
  }
}
</script>
