import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider'
import { createDefaultEntry } from './create-action'

/**
 * @description manages the menu next to the blocks (once you click on the block)
 * if you want do stuff inside the constructor (I strongly advise you to not do so),
 * you should refactor this class (make it like `CustomPalette`)
 */
export default class CustomContextPadProvider extends ContextPadProvider {
  getContextPadEntries (element) {
    const create = createDefaultEntry.bind(this)

    const initial = super.getContextPadEntries(element)
    const custom = {}
    // add the custom elements only on when is not EndEvent
    if (initial['append.append-task']) {
      custom['create.dialog-task'] = create('bpmn:Task', 'model', 'bpmn-icon-dialog', 'Dialog', element)
      custom['create.action-task'] = create('bpmn:Task', 'model', 'bpmn-icon-action', 'Action', element)
      custom['create.function-task'] = create('bpmn:Task', 'model', 'bpmn-icon-function', 'Function', element)
      custom['create.subs-task'] = create('bpmn:Task', 'model', 'bpmn-icon-subs', 'Subs', element)
    } else {
      delete initial.connect
    }

    // group connect with trash
    if (initial.connect) initial.connect.group = 'edit'

    // Remove un-needed actions
    delete initial['append.intermediate-event']
    delete initial['append.append-task']
    delete initial['append.text-annotation']
    delete initial.replace

    return { ...custom, ...initial }
  }
}
