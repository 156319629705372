export class FileService {
  validateExtension (fileName, allowedExtensions) {
    const extension = fileName.split('.').pop()
    if (!allowedExtensions.includes(extension)) { throw new Error(`File has invalid extension ${extension} allowed extensions are: ${allowedExtensions.join(', ')}`) }
  }

  readJsonFile (file) {
    this.validateExtension(file.name, ['json'])
    return this.readTextFile(file).then(text => JSON.parse(text))
  }

  readTextFile (file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => resolve(event.target.result as string)
      reader.onerror = event => reject(event.target.error)
      reader.onabort = () => reject(new Error('Aborted reading file ' + file.name))
      reader.readAsText(file)
    })
  }
}

export default new FileService()
