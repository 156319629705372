import axios from 'axios'

export class NuanceService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/nuance/${botName}`
  }

  async getKeys () {
    return await axios.get(`${this.url}/keys`).then(res => res.data)
  }

  updateKeys (keys: any) {
    return axios.put(`${this.url}/keys`, keys)
  }
}
