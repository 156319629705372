<template>
  <Modal title="Create new tenant" :show="true" @close="$emit('close')" :smallModal="true">
    <div class="form--wrapper">
      <div class="form-group">
        <div class="form-group--inside">
          <label for="botName">Name</label>
          <br />
          <input
            type="name"
            class="da-input"
            v-model="name"
          />
        </div>
      </div>

      <div class="bottom-right-fix">
        <SmallLoading isInverted="true" v-if="loadingIndicator"></SmallLoading>
        <button class="btn btn--purple gradient" type="button" @click="createTenant()">
          {{createTenantText}}<i class="icon icon-arrow-right" v-if="!loadingIndicator"></i>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import popupService from '../../../../services/popup.service'
import SmallLoading from '../../../helpers/SmallLoading.vue'
import Modal from '../../../helpers/Modal.vue'

@Component({ components: { SmallLoading, Modal } })
export default class CreateTenant extends Vue {
  private name = '';

  private loadingIndicator = false;
  private createTenantText = 'Create Tenant'

  private createTenant () {
    if (this.name != '') {
      this.loadingIndicator = true
      this.createTenantText = 'Creating...'
      this.$emit('create-tenant', this.name)
    } else {
      popupService.showWarning(
        'Oops! The name is invalid.',
        'Please provide a valid one.'
      )
    }
  }
}
</script>
