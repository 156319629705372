import { ExternalProvider } from '../../enums/external-provider.enum';
import { MicrosoftSTTLanguage } from '../../enums/microsoft/microsoft-stt.language.enum';
import { GoogleSTTLanguage } from '../../enums/google/google-stt.language.enum';
import { OpenAISTTLanguage } from '../../enums/openai/openai-stt.language.enum';
import {SttMap} from "../../types/stt-map.type";

export const sttLanguages: SttMap<any> = {
    [ExternalProvider.Microsoft]: MicrosoftSTTLanguage,
    [ExternalProvider.Google]: GoogleSTTLanguage,
    [ExternalProvider.OpenAI]: OpenAISTTLanguage,
}