<template>
  <div class="chat-details" v-show="state.areDetailsOpened">
    <div class="section--wrapper">
      <div class="chat-details__section">
        <div class="chat-label float">{{ state.providers.recognizer }}</div>
        <h4>Triggered intent</h4>
        <p>{{ details.triggeredIntentName || details.flowName }}</p>
        <span class="number">{{ details.triggeredIntentScore | percentage }}</span>
      </div>
      <div class="chat-details__section">
        <div v-if="state.providers" class="chat-label float">{{ state.providers.sentiment }}</div>
        <h4>Sentiment</h4>
        <div v-if="details.sentiment">
          <p>{{ details.sentiment.message }}</p>
          <span class="number">{{ details.sentiment.score | percentage }}</span>
        </div>
      </div>
      <div class="chat-details__section">
        <div v-if="state.providers" class="chat-label float">{{ state.providers.entities }}</div>
        <h4>Entities</h4>
        <p v-for="(key, index) in details.entities" :key="index" class="description">
          <br>
          <span>--> {{ key.entity }} ({{key.value}})</span>
        </p>
      </div>
      <div class="chat-details__section">
        <h4>Conversation state variables</h4>
        <p v-for="(key, index) of Object.keys(details.variables || {})" :key="index" class="description">
          <br>
          <span>--> {{ key }}: {{parseVariableValue(details.variables[key])}} = {{details.variables[key]}}</span>
        </p>
      </div>
      <div class="chat-details__section">
        <h4>All intent recognitions</h4>
        <div v-for="(result, index) in details.recognizerResults" :key="index" class="recognizer-results">
          <p class="description">Utterance: {{ result.utterance }}</p>
          <p class="description">Intent: {{ result.intent }}</p>
          <p class="description">Score: {{ result.score }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import chatService from '../../../../services/bots/chat.service'
import { SharedState } from './shared-state'

@Component({
  filters: {
    percentage: function (value: number) {
      if (!value) return
      value *= 100
      value = Math.round(value)
      value = Math.min(value, 100)
      return value + '%'
    }
  }
})
export default class extends Vue {
  @Prop() state: SharedState;

  details: any = {};
  activitySubscription: any;

  @Watch('state.directLine')
  onDirectLineChanged () {
    this.unsubscribe()
    this.subscribe()
  }

  created () {
    this.subscribe()
  }

  beforeDestroy () {
    this.unsubscribe()
  }

  subscribe () {
    if (this.state.directLine) {
      this.activitySubscription = this.state.directLine.activity$.subscribe(async event => {
        if (event.from.role !== 'user' && event.type === 'message') {
          const newDetails = await chatService.getChatDetails(event.conversation.id)
          this.details = newDetails || this.details
        }
      }, () => undefined)
    }
  }

  unsubscribe () {
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe()
    }
  }

  parseVariableValue (value: any) {
    const valType = typeof value
    return valType === 'object'
      ? Array.isArray(value) ? 'Array' : 'Object'
      : valType
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/_chat.scss";

.chat-details {
  border-bottom-right-radius: inherit;
  color: black;
  height: 100%;
  width: 360px;
  background-color: $default-dark;
  overflow-y: auto;
  font-family: Raleway, sans-serif;

  .chat-label {
    color: $white;
    background: transparent linear-gradient(111deg, #5516C6 0%, #8743FF 100%) 0% 0% no-repeat padding-box;
    padding: 2px 10px 2px 10px;
    border-radius: 6px;

    &.float {
      float: right;
    }
  }

  &__section {
    padding: 20px;
    border-bottom: 1px solid $details-border;

    &:last-of-type {
      border-bottom: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 400;
      color: rgba(#fff, 0.6);
      line-height: 18px;
      margin: 0;
    }

    p {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      line-height: 18px;
      margin: 0;
    }

    .number {
      font-size: 40px;
      font-weight: 500;
      color: #fff;
      line-height: 42px;
      margin: 0;
    }

    .recognizer-results {
      margin-top: 10px;
    }

    .description {
      font-weight: 400;
      color: #fff;
    }
  }
}
</style>
