<template>
  <Modal :title="`${provider} Speech-to-text Settings`" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <div class="form-group">
        <div class="form-group--inside">
          <label>Language</label>
          <Select :elements="languages" keyAttr="0" valueAttr="1" v-model="sttLanguageCode" class="white-select"></Select>
        </div>
        <div v-if="!sttLanguageCode" class="invalid">Please select a language</div>
      </div>
      <button class="btn btn--purple gradient" type="button" @click="save()">Save settings <i class="icon icon-save"></i></button>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import { sttLanguages } from '../../../../../../../../common/constants/engines/stt-languages.constant'
import { ExternalProvider } from '../../../../../../../../common/enums/external-provider.enum'
import { IBotSettings } from '../../../../../../../../common/interfaces/bot-settings.interface'

@Component({
  components: {
    FormGroup,
    Autocomplete,
    Select,
    Toggle,
    Modal
  }
})
export default class STTConfiguration extends Vue {
  @Prop({ default: ExternalProvider.Microsoft }) provider: ExternalProvider
  @Prop({ default: '' }) sttLanguageCode: string

  get languages() {
    return Object.entries(sttLanguages[this.provider] || {})
  }

  save() {
    if (this.sttLanguageCode) {
      const data: Partial<IBotSettings> = {
        speechToTextLanguageCode: this.sttLanguageCode
      }
      // eslint-disable-next-line no-console
      this.$emit('save', data)
      this.$emit('close')
    }
  }
}
</script>
