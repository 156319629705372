<template>
  <Modal title="Telegram Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form @submit.prevent="$emit('update-keys')">
        <h4>Telegram channel setup (Read <a href="https://support.blits.ai/portal/en/kb/articles/how-to-add-the-telegram-channel" target="_blank" rel="noopener noreferrer">here</a> for additional help)</h4>
        <p>Status: <strong>{{ channelStatus }}</strong></p>
        <div class="form-section">
          <FormGroup label="Access Token">
            <input v-model="keys.accessToken" type="text" class="form-control" />
          </FormGroup>
        </div>
        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
           <i class="icon icon-save" ></i> Save keys
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Modal from '../../../../../helpers/Modal.vue'
import { TelegramChannelProperties } from '../../../../../../../../common/interfaces/telegram-channel-properties'

@Component({
  components: { FormGroup, Modal }
})
export default class Telegram extends Vue {
  @Prop() keys: TelegramChannelProperties;

  get channelStatus(): string {
    return this.keys.isValidated ? 'connected' : 'disconnected'
  }
}
</script>
