import { AxiosError } from 'axios'
import { parseError } from '../../common/errors/error.factory'
import { PermissionError } from '../../common/errors/permission.error'
import { ValidationError } from '../../common/errors/validation.error'
import popupService from './services/popup.service'
import { NotFoundError } from '../../common/errors/not-found.error'
import { ConfigurationError } from '../../common/errors/configuration.error'
import {
  BlitsError,
  CohesionError,
  ExpiredJwtError,
  FlowError,
  InvalidJwtError,
  UserNotAssignedToTenantError,
  UserNotConfirmedError,
  UserNotFoundError
} from '../../common/errors'
import fileHelper from './helpers/file.helper'
import { UsersLimitError } from '../../common/errors/users-limit.error'
import { StripeError } from '../../common/errors/stripe.error'
import { UnauthorizedError } from '../../common/errors/unauthorized.error'
import { CustomError } from '../../common/errors/custom.error'
import router from './router/index.js'
import authService from './services/auth.service'

export async function handleAxiosError(error: AxiosError) {
  const { response } = error

  if (!response || (error.response.status === 500 && !response.data)) {
    throw error
  }

  const parsedError = parseError(response.data instanceof Blob ? await fileHelper.readBlobJson(response.data) : response.data)
  parsedError.stack = `${parsedError.message} \n\t at ${response.config.method.toUpperCase()} ${response.config.url}`
  throw parsedError
}

export async function handleError(error: Error) {
  if (error instanceof PermissionError) {
    popupService.showError('Access Denied', error.message)
  } else if (error instanceof ValidationError) {
    popupService.showError('Invalid data provided', error.message)
  } else if (error instanceof CustomError) {
    popupService.showError('Error!', error.message)
  } else if (error instanceof StripeError) {
    popupService.showError(error.message, error.additionalMessage)
  } else if (error instanceof NotFoundError) {
    popupService.showError('404 - Not found', `${error.message}. \r\n Details: ${error.details}`)
  } else if (error instanceof CohesionError) {
    popupService.showWarning('Data conflict', error.message)
  } else if (error instanceof ConfigurationError) {
    popupService.showWarning('Configuration error', error.message)
  } else if (error instanceof FlowError) {
    popupService.showWarning('Flow design error', error.message)
  } else if (error instanceof UsersLimitError) {
    popupService.showError('Maximum users reached', error.message)
  } else if (error instanceof UserNotFoundError) {
    popupService
      .showDialog('Access Denied', error.message + '\r\n Please make sure that you are registered in the platform.', null, 'Logout', false)
      .then(() => router.push('/logout'))
  } else if (error instanceof UserNotConfirmedError) {
    popupService
      .showDialog(
        'Your account is not confirmed!',
        error.message + '\r\n Please make sure that you accepted the invitation in your mailbox.',
        null,
        'Logout',
        false
      )
      .then(() => router.push('/logout'))
  } else if (error instanceof UnauthorizedError) {
    popupService.showError('Error', error.message)
  } else if (error instanceof InvalidJwtError || error instanceof ExpiredJwtError) {
    await authService.logout()
    window.open('login', '_self')
  } else if (error instanceof UserNotAssignedToTenantError) {
    popupService.showError('Error!', error.message)
  } else if (error instanceof BlitsError) {
    popupService.showError('Server error occured', error.message + '\r\n Please contact the system administrator')
  } else {
    // 'Got an unknown error'
    if (process.env.NODE_ENV === 'development') {
      if (error) {
        console.error(error)
      }
    }
  }
}
