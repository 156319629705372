<template>
  <div class="configuration-tabs no-overflow full line">
    <div class="configuration-tabs--inner">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        type="button"
        class="tab"
        :class="{ 'tab--active': selectedTab == tab, 'tab--disabled':  isDisabled(tab)}"

        @click="onTabClick(tab)"
      >
        {{ tab }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class SettingsNew extends Vue {
  private routeKeys = {
    general: 'General Settings',
    configuration: 'Configuration',
    widgets: 'Custom Widgets',
    bubble: 'Chat Bubble',
    performance: 'NLU Performance',
    nluStatus: 'NLU Status',
    gdpr: 'GDPR',
    handover: 'Handover',
    multiLanguage: 'Multi-Language'
  };

  private selectedTab = '';

  @Prop() readonly botName!: string;
  @Prop({
    default: () => {
      return {}
    }
  })
  readonly tabs!: string[];

  @Prop() settingsTab: string;
  @Prop() channels: any[];

  async beforeMount () {
    this.onRouteChange()
  }

  @Watch('settingsTab')
  @Emit('change')
  onRouteChange () {
    const tab = this.routeToTab(this.settingsTab)
    this.selectedTab = tab
    return tab
  }

  onTabClick (tab: string) {
    this.$router
      .push({
        name: 'BotSettings',
        params: {
          botName: this.botName,
          settingsTab: this.tabToRoute(tab)
        }
      })
      .catch((_) => {})
  }

  routeToTab (route: string) {
    return this.routeKeys[route] || 'General Settings'
  }

  tabToRoute (tab: string) {
    return (
      Object.keys(this.routeKeys).find((e) => this.routeKeys[e] == tab) ||
      'general'
    )
  }

  isDisabled (tab: string) {
    if (!this.channels.find(ch => ch.name === 'Chat Bubble')?.enabled && tab === 'Chat Bubble') {
      return true
    }
    return false
  }
}
</script>
