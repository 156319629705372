import { PopupType } from './popup.type'

export class PopupState {
  constructor (type?: PopupType) {
    this.type = type
    this.actionBtnText = 'OK'
    this.id = Math.floor(100000 + Math.random() * 900000)
    this.hasCloseButton = true
  }

    // This is the type of the popup 'error' 'warning' 'info'
    type: PopupType;
    id: number;
    heading: string;
    description?: string;
    cancelBtnText?: string;
    // Button which takes action: Saves, Publishes...å
    actionBtnText?: string;

    hasCloseButton?: boolean;
    disableClose?: boolean;
    removeOnConfirm?: boolean;
    isLarge?: boolean
}
