<template>
  <div class="modal">
    <header>
      <h1>Slack Settings</h1>
      <span class="close" @click="$emit('close')"></span>
    </header>
    <div class="content form--wrapper general">
      <form @submit.prevent="$emit('update-keys')">
        <h4>Slack channel setup (Read <a href="https://support.blits.ai/portal/en/kb/articles/how-to-connect-the-slack-channel" target="_blank" rel="noopener noreferrer">here</a> for additional help)</h4>
        <div class="form-section">
          <FormGroup label="Client ID">
            <input v-model="keys.clientId" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Client Secret">
            <input v-model="keys.clientSecret" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Signing Secret">
            <input v-model="keys.signingSecret" type="text" class="form-control" />
          </FormGroup>

          <FormGroup v-if="keys.redirectUrl" label="OAuth & Permissions Redirect URL (copy and paste in Slack)">
            <input readonly :value="keys.redirectUrl" type="text" class="form-control" />
          </FormGroup>

          <FormGroup v-if="keys.eventSubscriptionUrl" label="Event Subscription Request URL (copy and paste in Slack)">
            <input readonly :value="keys.eventSubscriptionUrl" type="text" class="form-control" />
          </FormGroup>
        </div>
        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
           <i class="icon icon-save" ></i> Save keys
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import { SlackChannelProperties } from '../../../../../../../../common/interfaces/slack-channel-properties'

@Component({
  components: { FormGroup, Toggle }
})
export default class Slack extends Vue {
  @Prop() keys: SlackChannelProperties;
}
</script>
