<template>
  <Modal title="Microsoft Teams Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
        <h4>Microsoft Teams channel setup <Tooltip title="This requires your microsoft organisation to allow 'Upload custom apps' in their policies"/> </h4>
        <div class="form-section">
            <button class="btn btn--purple gradient" type="button" v-if="!toggleTeamsOn" @click="toggleTeamsChannel()" :disabled="keys.isEnabled">
                Enable teams
            </button>
            <SmallLoading v-if="toggleTeamsOn"></SmallLoading>

            <button class="btn btn--white" type="button" v-if="keys.isEnabled" @click="$emit('add-to-teams')">
                Add to Teams
            </button>
            <br><br>
            <label class="label small" v-if="keys.appId" for="appId">App Id</label>
            <input v-if="keys.appId" v-model="keys.appId" name="appId" type="text" readonly class="code"/>
            <div v-if="keys.appId" type=”button” class="copybutton" v-clipboard:copy="keys.appId">COPY</div>
        </div>
        <div class="form-section" v-if="keys.isEnabled">
          <label>Messaging Settings</label>
          <FormGroup label="Conversation timeout (in seconds), or 0 for not timeout" tooltip="If a conversation times out, the next user message will restart the conversation">
            <input v-model.number="keys.messagesTimeout" type="number" min="0" class="form-control"/>
          </FormGroup>
          <!-- <FormGroup
            label="Options message"
            tooltip="Message when displaying options"
          >
            <Autocomplete v-model="keys.messagesOptionsMessage" :items="optionsMessageItems" />
          </FormGroup> -->

          <div class="bottom-right-fix">
            <button class="btn btn--purple gradient" type="button" @click="$emit('update-keys')">
              <i class="icon icon-save"></i> Save settings
            </button>
          </div>
        </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import Modal from '../../../../../helpers/Modal.vue'
import { TeamsChannelProperties } from '../../../../../../../../common/interfaces/teams-channel-properties'
import SmallLoading from '../../../../../helpers/SmallLoading.vue'
import Tooltip from '@/components/helpers/Tooltip.vue'

@Component({
  components: { Tooltip, FormGroup, Toggle, SmallLoading, Modal }
})
export default class Teams extends Vue {
  @Prop() keys: TeamsChannelProperties;

  private toggleTeamsOn = false

  @Watch('keys', { deep: true })
  onKeysChanged () {
    this.toggleTeamsOn = false
  }

  toggleTeamsChannel () {
    this.toggleTeamsOn = true
    this.$emit('update-keys')
  }
}
</script>
