<template>
  <div class="form-group">
    <label for="function">
      Answers
      <Tooltip title="These are the possible answers." placement="bottom"></Tooltip>
    </label>

    <div v-for="(v, index) in possibleAnswers" :key="index" style="position: relative;">
      <input
        v-model="currentBlock.customData['field-possible-answers'][index]"
        type="text"
        :placeholder="'Answer'"
        class="form-control"
        :disabled="!hasSufficientPermission"
      />

      <i class="icon-delete" @click="removeAnswer(index)" v-show="hasSufficientPermission"></i>

    </div>
   <div class="option-button">
    <div class="btn btn--white dialogmanager" @click="addAnswer">Add new answer  <i class="icon icon-add"></i></div>
   </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import { IRandomAnswerBlock } from '../../../../../../../../../common/blocks/dialog/random-answer-block.interface'
import RandomAnswerBlockComponent from '../../../../modules/blocks/dialog/blocks/RandomAnswerBlockComponent'

@Component({ components: { Tooltip } })
export default class RandomAnswerExtension extends Vue {
  private possibleAnswers: Array<null> = null;

  @Prop({ default: () => { return {} } })
  readonly currentBlock!: RandomAnswerBlockComponent<IRandomAnswerBlock>;

  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  created () {
    if (!this.currentBlock.customData['field-possible-answers']) {
      this.currentBlock.customData['field-possible-answers'] = []
    }
    this.possibleAnswers = new Array(
      this.currentBlock.customData['field-possible-answers'].length
    )
  }

  addAnswer () {
    this.possibleAnswers.push(null)
    this.currentBlock.customData['field-possible-answers'].push('')
  }

  removeAnswer (index: number) {
    this.possibleAnswers.splice(index, 1)
    this.currentBlock.customData['field-possible-answers'].splice(index, 1)
  }
}
</script>

<style lang="scss" scoped>
.icon-delete {
  position: absolute;
  top: 17px;
  right: 14px;
}
</style>
