<template>
  <Modal title="Google Settings" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form v-if="!processId" @submit.prevent="updateActions">
        <h4>Google Assistant Settings</h4>
        <label for="projectId">Project Id</label>
        <input id="projectId" placeholder="Project Id" v-model="projectId" />

        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
          Connect to project
            <i class="icon icon-add"></i>
          </button>
        </div>
      </form>

      <form v-if="processId" @submit.prevent="provideAuthCode">
        <h4>Connect me to Google Assistant</h4>
        <label for="authCode">Authorization Code</label>
        <input id="authCode" placeholder="Authorization Code" v-model="authCode" />

        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
          <i class="icon icon-save"></i>
          Save Authentication Code
          </button>
        </div>
      </form>
      <p>{{ message }}</p>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '../../../../../helpers/Modal.vue'

@Component({
  components: { Modal }
})
export default class GoogleHome extends Vue {
  projectId = '';
  authCode = '';

  @Prop() message: string;
  @Prop() processId: string;

  updateActions () {
    this.$emit('update-actions', { projectId: this.projectId })
    this.authCode = ''
  }

  provideAuthCode () {
    this.$emit('provide-auth-code', { projectId: this.projectId, authCode: this.authCode })
    this.projectId = ''
  }
}
</script>
