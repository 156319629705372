<template>
  <div class="wrapper-inside general-settings active">
    <div class="settings-wrapper">
      <div class="left">
        <GeneralBotSettings
          :settings="settings"
          :displayName="displayName"
          :botName="botName"
          :botAvatar="botAvatar"
        ></GeneralBotSettings>
      </div>
      <div class="right">
        <BotIntentRecognizerSettings
          :settings="settings"
          :botName="botName"
        ></BotIntentRecognizerSettings>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import GeneralBotSettings from './subtabs/GeneralBotSettings.vue'
import BotIntentRecognizerSettings from './subtabs/BotIntentRecognizerSettings.vue'

@Component({
  components: {
    GeneralBotSettings,
    BotIntentRecognizerSettings
  }
})
export default class GeneralSettings extends Vue {
  @Prop() readonly settings: any;
  @Prop() readonly displayName: string;
  @Prop() readonly botName: string;
  @Prop() readonly botAvatar: string;
}
</script>
