import axios from 'axios'

export class ExamplesService {
  baseUrl (container, intent) {
    return `${process.env.VUE_APP_BACKEND_URL}/${container}/intents/${intent}`
  }

  /**
   * Adds an example to an intent
   * @param {string} containerName - bot
   * @param {string} intentName
   * @param {string} text
   */
  addExample (containerName, intentName, text) {
    return axios
      .post(`${this.baseUrl(containerName, intentName)}/examples`, { text })
      .then(response => response.data)
  }

  /**
   * Edits an example of an intent
   * @param {string} containerName - bot
   * @param {string} intentName
   * @param {*} example
   */
  editExample (containerName, intentName, example) {
    return axios
      .put(`${this.baseUrl(containerName, intentName)}/examples`, example)
      .then(response => response.data)
  }

  /**
  * Deletes a pattern of an intent
  * @param {string} containerName - bot
  * @param {string} intentName
  */
  async deletePattern (containerName, intentName, example) {
    const id = example.ids.internal
    await axios.delete(`${this.baseUrl(containerName, intentName)}/patterns/${id}`)
  }

  /**
  * Deletes an utterance of an intent
  * @param {string} containerName - bot
  * @param {string} intentName
  */
  async deleteUtterance (containerName, intentName, example) {
    const id = example.ids.internal
    await axios.delete(`${this.baseUrl(containerName, intentName)}/utterances/${id}`)
  }

  /**
   * Deletes an example of an intent
   * @param {string} containerName - bot
   * @param {string} intentName
   */
  async deleteExample (containerName, intentName, example, allEntities) {
    const id = example.ids.internal
    const isPattern = await this.isPattern(example, allEntities)

    await axios.delete(`${this.baseUrl(containerName, intentName)}/${isPattern ? 'patterns' : 'utterances'}/${id}`)
  }

  async isPattern (example, entities) {
    const result = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/examples/is-pattern`, { example, entities })
    return result.data.isPattern
  }
}

export default new ExamplesService()
