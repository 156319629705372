import axios from 'axios'

export class SnippetService {
  constructor (private botName: string) { }

  static async getSnippetUrl (): Promise<string> {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/snippet-url`)
    return res.data
  }

  async getSnippets (): Promise<any[]> {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/snippets`)
    return res.data
  }

  async createSnippet (snippet: any) {
    this.validate(snippet)
    const res = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/snippets`, snippet)
    return res.data
  }

  updateSnippet (snippet: any) {
    this.validate(snippet)
    const { name } = snippet
    return axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/snippets/${name}`, snippet)
  }

  renameSnippet (snippet: any, newName: string) {
    this.validate(snippet)
    return axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/snippets/name`, { snippet: snippet, newName: newName })
  }

  updateSnippetAvatar (name: string, file: any) {
    return axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/snippets/icon/${name}/avatars`, file).then(r => r.data)
  }

  updateSnippetBubbleIcon (name: string, file: any) {
    return axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/snippets/icon/${name}/bubble-icons`, file).then(r => r.data)
  }

  deleteSnippet (name: string) {
    return axios.delete(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/snippets/${name}`)
  }

  validate (snippet: any) {
    if (typeof snippet.name !== 'string') throw new Error('Snippet must have a valid name')
    if (typeof snippet.version !== 'string') throw new Error('Snippet must have a valid version')
  }
}
