<template>
  <Modal title="Salesforce Settings" :show="true" @close="$emit('close')" customClass="salesforce-modal">
    <div class="form--wrapper general">
      <form @submit.prevent="$emit('update-keys')">
        <h4>OAuth 2.0 JWT Bearer Flow for Server-to-Server Integration</h4>
        <div class="form-section">
          <FormGroup label="Consumer Key">
            <input v-model="keys.consumerKey" type="text" class="form-control" />
          </FormGroup>

          <FormGroup label="Private Key">
            <input
              type="file"
              ref="privateKeyFile"
              @change="$emit('change-private-key-file', $event.target.files[0])"
            />
            <label v-if="keys.privateKeyFileName">Last uploaded file: {{ keys.privateKeyFileName }}</label>
          </FormGroup>

          <FormGroup label="Instance URL">
            <input
              placeholder="https://XXXX.salesforce.com"
              v-model="keys.instanceUrl"
              type="text"
              class="form-control"
            />
          </FormGroup>

          <FormGroup label="Username">
            <input v-model="keys.username" type="email" class="form-control" />
          </FormGroup>

          <Toggle
            label="Environment is Sandbox"
            tooltip="Check this if yout Salesforce environment is Sandbox"
            v-model="keys.isSandbox"
          ></Toggle>
        </div>

        <h4>You can obtain these from your Embedded Service Code Snippet (service setup - channels - embedded service)</h4>
        <div class="form-section">
          <FormGroup label="Live Agent Api Endpoint">
            <input
              placeholder="https://XXXXXX.salesforceliveagent.com/chat"
              v-model="keys.liveAgentApiEndpoint"
              type="text"
              class="form-control"
            />
          </FormGroup>

          <FormGroup label="Organization Id">
            <input
              placeholder="00DXXXXXXXXXXXX"
              v-model="keys.organizationId"
              type="text"
              class="form-control"
            />
          </FormGroup>

          <FormGroup label="Deployment Id">
            <input
              placeholder="572XXXXXXXXXXXX"
              v-model="keys.deploymentId"
              type="text"
              class="form-control"
            />
          </FormGroup>

          <FormGroup label="Button Id">
            <input
              placeholder="573XXXXXXXXXXXX"
              v-model="keys.buttonId"
              type="text"
              class="form-control"
            />
          </FormGroup>
        </div>

        <h4>Case field mappings</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Label</th>
              <th scope="col">Value</th>
              <th scope="col">Field Name</th>
              <th scope="col">Display to agent</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(m, index) in keys.caseMappings" :key="index">
              <th scope="row">
                <input v-model="m.label" />
              </th>
              <th scope="row">
                <input v-model="m.value" />
              </th>
              <th scope="row">
                <input v-model="m.fieldName" />
              </th>
              <th scope="row">
                <div>
                  <Toggle class="toggle" v-model="m.displayToAgent"></Toggle>
                  <img
                    src="/assets/icons/trash.svg"
                    class="trash cursor-pointer"
                    @click="removeMapping(index)"
                    alt="Trash icon"
                  >
                </div>
              </th>
            </tr>
          </tbody>
        </table>
        <div class="add-new" @click="addMapping">Add new</div>

      <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
           <i class="icon icon-save" ></i> Save keys
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import Modal from '../../../../../helpers/Modal.vue'
import { SalesforceDetailEntityMapping } from '../../../../../../../../common/interfaces/salesforce-detail-entity-mapping'

@Component({
  components: { FormGroup, Toggle, Modal }
})
export default class Salesforce extends Vue {
  @Prop() keys: {
    caseMappings: SalesforceDetailEntityMapping[];
    [key: string]: any;
  };

  addMapping () {
    this.keys.caseMappings.push({
      label: '',
      value: ''
    })
  }

  removeMapping (index: number) {
    this.keys.caseMappings.splice(index, 1)
  }
}
</script>

<style lang="scss" scoped>

.row > input {
  padding-right: 20px !important;
}

.add-new {
  max-width: 150px;
}

.table {
  th {
    vertical-align: middle;

    img {
      float: right;
    }

    .toggle {
      margin: 0;
      float: left;
    }
  }
}
</style>
