<template>
  <div></div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import chatService from '../../../../services/bots/chat.service'
import authService from '../../../../services/auth.service'
import chatLoader from '../chat-loader'

@Component
export default class InternalTestChat extends Vue {
  @Prop() useStagingVersion: boolean;
  @Prop() botName: string;
  @Prop() tenantId: string;
  @Prop() flowName: string;
  @Prop() openedKey: number;

  get id () {
    return this.flowName ? 'flow' : 'bot'
  }

  @Watch('flowName')
  onFlowNameChanged () {
    chatLoader.destroy(this.id)
    this.init()
  }

  @Watch('openedKey')
  onOpenedKeyChanged () {
    window.BlitsBotChat.open(this.id)
  }

  async init () {
    const user = authService.user

    const password = await chatService.getIntChatPassword(this.botName)

    await chatLoader.init({
      id: this.id,
      password,
      passwordType: 'int',
      isInternal: !this.flowName,
      userId: user?.userName,
      useStagingVersion: this.useStagingVersion,
      flowName: this.flowName,
      title: `Test ${this.flowName || 'Your Bot'}`,
      startOpened: !!this.flowName,
      hasButton: !this.flowName,
      closeWithoutButton: !!this.flowName
    })
  }

  async mounted () {
    this.init()
  }

  destroyed () {
    chatLoader.destroy(this.id)
  }
}
</script>
