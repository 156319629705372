<template>
  <Modal :title="BotConnectionEnum.TwilioMessages" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <form v-if="!keys.messagesWebhookUrl" @submit.prevent="$emit('generate-webhook', type, true)">
        <label for="button">There is no webhook for this bot (yet)</label>

        <div class="section--footer static">
          <button class="btn btn--purple gradient" type="submit">
            <i class="icon icon-add"></i>
            Generate webhook
          </button>
        </div>
      </form>
      <form v-else>
        <div class="form-section">
          <label>Webhook settings</label>
          <FormGroup label='"A MESSAGE COMES IN" webhook url' tooltip="Can also be used for WhatsApp messages">
            <textarea readonly :value="keys.messagesWebhookUrl" class="form-control code"></textarea>
          </FormGroup>
        </div>

        <div class="form-section">
          <label>Messaging Settings</label>
          <FormGroup label="Conversation timeout (in seconds), or 0 for not timeout" tooltip="If a conversation times out, the next user message will restart the conversation">
            <input v-model.number="keys.messagesTimeout" type="number" min="0" class="form-control"/>
          </FormGroup>
          <!-- <FormGroup
            label="Options message"
            tooltip="Message when displaying options"
          >
            <Autocomplete v-model="keys.messagesOptionsMessage" :items="optionsMessageItems" />
          </FormGroup> -->
        </div>

        <div class="form-section">
          <label>Handover Settings</label>

          <FormGroup label="Account SID">
            <input v-model.trim="keys.accountSid" class="form-control"/>
          </FormGroup>
          <FormGroup label="Auth Token">
            <input v-model.trim="keys.authToken" class="form-control"/>
          </FormGroup>

          <FormGroup label="SMS number">
            <input v-model.trim="keys.smsNumber" placeholder="+1234..." class="form-control"/>
          </FormGroup>
          <FormGroup label="WhatsApp number">
            <input v-model.trim="keys.whatsAppNumber" placeholder="+1234..." class="form-control"/>
          </FormGroup>
        </div>

        <div class="bottom-right-fix">
          <button
            class="btn btn--white"
            type="button"
            @click="$emit('generate-webhook', type, false)"
          >Change webhook url</button>
          <button
            class="btn btn--white red"
            type="button"
            @click="$emit('delete-webhook', type)"
          >Delete webhook</button>
          <button class="btn btn--purple gradient" type="button" @click="$emit('update-keys')">
            <i class="icon icon-save"></i> Save settings
          </button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'
import { BotConnectionEnum } from '../../../../../../../../common/enums/bot/bot-connection.enum'

@Component({
  components: { FormGroup, Autocomplete, Select, Modal }
})
export default class TwilioSms extends Vue {
  BotConnectionEnum = BotConnectionEnum;

  type = 'messages'
  optionsMessageItems = ['digit', 'option'];

  @Prop() keys: any;
}
</script>
