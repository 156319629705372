import { BlockType } from '../../../../../../../common/enums/block/block-type.enum'

export const action = {
  defaultAction: {
    key: 'defaultAction',
    functionName: 'Select an action',
    variables: [],
    category: 'Action',
    hide: true
  },
  setVariable: {
    key: 'setVariable',
    functionName: 'Set Variable',
    fields: [
      { id: 'reset-value', type: 'checkbox', name: 'Reset Value', placeholder: 'Reset value' },
      { id: 'variable-value', type: 'text', name: 'Value', rows: 3, hide: 'reset-value' }
    ],
    variables: ['Answer'],
    category: 'Action'
  },
  setObject: {
    key: 'setObject',
    functionName: 'Set Object',
    fields: [
      { id: 'reset-value', type: 'checkbox', name: 'Reset Value', placeholder: 'Reset value' },
      { id: 'object-value', type: 'text', name: 'Value', hide: 'reset-value' }
    ],
    variables: [],
    category: 'Action'
  },
  triggerRecognizer: {
    key: 'triggerRecognizer',
    functionName: 'Recognize intent',
    fields: [{ id: 'input', type: 'text', name: 'Input', rows: 1 }],
    variables: ['Intent', 'Entities'],
    category: 'Action'
  },
  userStatistics: {
    key: 'userStatistics',
    functionName: 'User Statistics',
    fields: [{ id: 'var-name', type: 'input', name: 'var-name', placeholder: "Variable's name" }],
    variables: [],
    category: 'Action'
  },
  passCounter: {
    key: 'passCounter',
    functionName: 'Counter',
    fields: [],
    variables: ['Counter'],
    category: 'Action'
  },
  replaceInVariable: {
    key: 'replaceInVariable',
    functionName: 'Replace in variable',
    fields: [],
    variables: [],
    category: 'Action'
  },
  redirectCall: {
    key: 'redirectCall',
    functionName: 'Redirect Call',
    fields: [
      { id: 'phone-number', type: 'text', name: 'phone-number', placeholder: 'Phone number', rows: 1 },
      { id: 'redirect-message', type: 'text', name: 'redirect-message', placeholder: 'Redirect message', rows: 1 }
    ],
    variables: [],
    category: 'Action'
  },
  simpleCalculationBlock: {
    key: 'simpleCalculationBlock',
    functionName: 'Simple Calculation',
    fields: [],
    variables: ['Math'],
    category: 'Action'
  },
  sendConversationHistory: {
    key: 'sendConversationHistory',
    functionName: 'Send Conversation History',
    fields: [{ id: 'email', type: 'text', name: 'email', placeholder: 'Email', rows: 1 }],
    variables: [],
    category: 'Action'
  },
  handover: {
    key: 'handover',
    functionName: 'Agent Handover',
    functionDescription: 'This will allow you to connect to a real person when the bot is not able to help',
    fields: [],
    variables: [],
    category: 'Action'
  },
  sendEmail: {
    key: 'sendEmail',
    functionName: 'Send Email',
    functionDescription: 'Send an email to a chosen recipient',
    fields: [
      { id: 'receiver', type: 'text', name: 'receiver', placeholder: 'Receiver', rows: 1 },
      { id: 'subject', type: 'text', name: 'subject', placeholder: 'Subject', rows: 1 },
      { id: 'text', type: 'text', name: 'text', placeholder: 'Content', rows: 5 }
    ],
    variables: [],
    category: 'Action'
  },
  languageTranslation: {
    key: 'languageTranslation',
    functionName: 'Language Translate',
    functionDescription: 'Translate your text to a different language',
    fields: [
      { id: 'text', type: 'text', name: 'text', placeholder: 'Text', rows: 3 },
      { id: 'from', type: 'text', name: 'from', placeholder: 'Lanuage Code of your text', rows: 1 },
      { id: 'to', type: 'text', name: 'to', placeholder: 'Language Code of the language you want to translate to', rows: 1 }
    ],
    variables: ['Translation'],
    category: 'Action'
  },
  languageDetection: {
    key: 'languageDetection',
    functionName: 'Language Detection',
    functionDescription: 'Detect the language of given text',
    fields: [{ id: 'text', type: 'text', name: 'text', placeholder: 'Content', rows: 3 }],
    variables: ['Language'],
    category: 'Action'
  },
  [BlockType.ValidationBlock]: {
    key: BlockType.ValidationBlock,
    functionName: 'Validation',
    // functionDescription: 'Validate a variable',
    fields: [],
    variables: [],
    category: 'Action'
  }
}
