<template>
  <div class="form-group form-group--options">
    <div class="label">Regex</div>
    <input
      type="text"
      placeholder="Regex"
      class="form-control"
      v-model="currentBlock.customData.regex"
      @input="updateLabel"
    />

    <div class="label">Flags</div>
    <input
      type="text"
      placeholder="Flags"
      class="form-control"
      v-model="currentBlock.customData.flags"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IRegexBlock } from '../../../../../../../../../common/blocks/connector/regex-block.interface'
import autocomplete from '../../../../../../helpers/Autocomplete.vue'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component({
  components: {
    autocomplete
  }
})
export default class Autocorrect extends Vue {
  @Prop({ default: () => ({}) }) readonly currentBlock!: ConditionalComponent<IRegexBlock>;

  @Emit()
  updateLabel () {}

  mounted () {
    this.updateLabel()
  }
}
</script>

<style lang="scss" scoped>

</style>
