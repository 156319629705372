<template>
  <div class="container">
    <div class="container--content">
      <ConfigurationPageNav
        v-model="chosenPage"
        @create-user="openCreateUserModal"
        @create-tenant="openCreateTenantModal"
        :mode="mode"
      ></ConfigurationPageNav>
    </div>

    <CreateUser
      v-if="createUserOpened"
      @close="createUserOpened = false"
      @create-user="createUser"
    ></CreateUser>

    <CreateTenant
      v-if="createTenantOpened"
      @close="createTenantOpened = false"
      @create-tenant="createTenant"
    ></CreateTenant>

    <slot v-bind:chosenPage="chosenPage"></slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConfigurationMode } from './configuration-mode.type'
import { TenantPlan } from '../../../../../common/interfaces/plans/tenant-plan.interface'
import popupService from '../../../services/popup.service'
import usersService from '../../../services/users.service'

import ConfigurationPageNav from './ConfigurationPageNav.vue'
import CreateUser from './edit-pages/CreateUser.vue'
import CreateTenant from './edit-pages/CreateTenant.vue'
import tenantService from '../../../services/tenant.service'

@Component({
  components: {
    ConfigurationPageNav,
    CreateUser,
    CreateTenant
  }
})
export default class ConfigurationPageBase extends Vue {
  @Prop({ required: true }) mode: ConfigurationMode;
  @Prop({ required: true, default: () => ({}) }) plan: TenantPlan;
  @Prop({ required: true, default: 0 }) usersCount: number

  chosenPage = '';
  createUserOpened = false;
  createTenantOpened = false;

  created () {
    this.getRouterPath()
  }

  async openCreateUserModal () {
    if (this.usersCount >= this.plan.maxFreeUsers) {
      if (this.plan.hasAdditionalUsersCost) {
        if (!await popupService.showDialog('Warning!', 'You are adding more seats outside your current plan. This will add additional cost.', 'Continue')) return
      } else {
        if (await popupService.showDialog('Error!', 'You have reached your plan\'s max seats. Upgrade your plan to add more seats.', 'Upgrade')) this.chosenPage = 'Subscription'
        return
      }
    }
    this.createUserOpened = true
  }

  private async createUser (name, email) {
    try {
      const result = await usersService.createNewUser(name, email, this.mode === 'master-admin')
      if (result.status == 200) {
        popupService.showDialog(
          'User created successfully!',
          `Remember or copy the password.<br>Email: ${result.data.id} <br>Password: ${result.data.password}`,
          'OK',
          ''
        )
        this.$emit('user-created', result.data)
      }
    } finally {
      this.createUserOpened = false
    }
  }

  openCreateTenantModal () {
    this.createTenantOpened = true
  }

  private async createTenant (name: string) {
    try {
      const tenant = await tenantService.createTenant(name)
      popupService.showDialog(
        'Tenant created successfully!',
        '',
        'OK',
        ''
      )
      this.$emit('tenant-created', tenant)
    } finally {
      this.createTenantOpened = false
    }
  }

  @Watch('chosenPage')
  onChosenPageChanged () {
    const page = this.chosenPage.toLowerCase().replace(' ', '-')
    const routePath = this.$route.path
    const routePage = this.$route.params.page

    if (page === routePage) return

    if (!routePage) {
      this.$router.replace({ path: `${routePath}/${page}` })
    } else {
      this.$router.push({ path: routePath.replace(routePage, page) })
    }
  }

  private getRouterPath () {
    switch (this.$route.params.page) {
      case 'bots':
        this.chosenPage = 'Bots'
        break
      case 'users':
        this.chosenPage = 'Users'
        break
      case 'roles':
        this.chosenPage = 'Roles'
        break
      case 'general':
        this.chosenPage = 'General'
        break
      case 'subscription':
        this.chosenPage = 'Subscription'
        break
      case 'tenants':
        this.chosenPage = 'Tenants'
        break
      case 'billing':
        this.chosenPage = 'Billing'
        break
      default:
        this.chosenPage = this.mode === 'master-admin' ? 'Tenants' : 'Bots'
        break
    }
  }
}
</script>
