<template>
  <div class="background">
    <div class="banner">
      {{ description }}
      <div>
        <a
          class="info-link"
          href="https://support.blits.ai/portal/en/kb/articles/publish"
          target="_blank"
          >What does publishing actually do?</a
        >
        <button
          v-if="hasButton"
          class="btn btn--purple gradient narrow"
          type="button"
          :disabled="disabled"
          @click="$emit('buttonAction')"
        >
          <i v-if="publishIcon" class="icon icon-paperplane"></i
          >{{ buttonValue }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang = "ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WarningBanner extends Vue {
  @Prop({ default: "" }) readonly description: string;
  @Prop({ default: "" }) readonly buttonValue: string;
  @Prop({ default: false }) readonly hasButton: boolean;
  @Prop({ default: false }) readonly publishIcon: boolean;
  @Prop({ default: false }) readonly disabled: boolean;
}
</script>
