<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import popupService from '@/services/popup.service'

@Component({})
export default class TextPill extends Vue {
  @Prop() readonly text: string
  @Prop() readonly id: string
  @Prop({ default: false }) readonly disabledDelete: boolean

  deleteEntry(id: string) {
    popupService.deletePopupEvent(id, () => {
      this.$emit('delete', id)
    })
  }
}
</script>

<template>
  <li class="pill">
    <div :id="text + '-show'" class="description">
      <div v-html="text"></div>
      <i v-if="!disabledDelete" class="remove" @click="deleteEntry(id)"></i>
    </div>
  </li>
</template>

<style scoped lang="scss">
.pill {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  background-color: #f2f5fd;
  font-size: 14px;
  font-weight: 500;
  color: #061551;
  line-height: 19px;
  margin: 0 7px 10px 0;
  padding: 11px 20px;

  .description {
    display: flex;

    .remove {
      background-image: url('/public/assets/icons/icon-remove-blue.svg');
      width: 7px;
      height: 7px;
      margin-top: 5px;
      pointer-events: all;
      position: relative;
      top: 2px;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
</style>
