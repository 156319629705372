import { getTextWidth } from '@/helpers/text.helper'
import statisticsService from '@/services/bots/statistics.service'
import { FlowOrigin } from '../../../../../../common/types/flow-origin'
import BlockStorage from './BlockStorage'

export class FlowStatisticsService {
  private static readonly STATISTIC_OUTPUT_CLASS_NAME = 'block-statistic-output'
  private static readonly STATISTIC_TEXT_CLASS_NAME = 'block-statistic-text'

  private static readonly FONT_FAMILY = 'Arial'
  private static readonly FONT_SIZE = '11px'
  private static readonly FONT = `${FlowStatisticsService.FONT_FAMILY} ${FlowStatisticsService.FONT_SIZE}`

  private cache: { [id: string]: any } = {}
  constructor (
    private botName: string,
    public blockStorage: BlockStorage
  ) { }

  private assembleId (flowOrigin: string, flowName: string, flowVersion: string) {
    return `${flowOrigin}-${flowName}-${flowVersion}`
  }

  private async getStatistics (flowOrigin: string, flowName: string, flowVersion: string, refresh: boolean) {
    const id = this.assembleId(flowOrigin, flowName, flowVersion)
    if (refresh || !this.cache[id]) {
      if (!flowVersion.startsWith('v')) flowVersion = 'v' + flowVersion
      this.cache[id] = await statisticsService.getFlowStatistics(this.botName, flowOrigin, flowName, flowVersion)
    }
    return this.cache[id]
  }

  async showFlowStatistics (flowOrigin: FlowOrigin, flowName: string, flowVersion: string) {
    const statistics = await this.getStatistics(flowOrigin, flowName, flowVersion, false)

    // Hide all old statistics
    this.hideFlowStatistics()

    const { bpmn } = this.blockStorage
    const className = 'with-statistic'
    // Add class to all blocks that need to show statistic
    this.blockStorage.allComponents().forEach(c => {
      bpmn.get('canvas').removeMarker(c.element, className)

      if (c.element.type !== 'bpmn:SequenceFlow') {
        bpmn.get('canvas').addMarker(c.element, className)
      }
    })

    const svgs = document.getElementsByClassName(className)
    for (const svg of svgs) {
      const bpmnElement = bpmn.get('elementRegistry').get(svg.getAttribute('data-element-id') || svg.getAttribute('data-container-id'))

      const flowTriggers = statistics.flowTriggers
      const blockHits = bpmnElement.type === 'bpmn:StartEvent' ? flowTriggers : statistics.blockHits[bpmnElement.id] || 0
      const hitPercent = Math.floor((100 * blockHits) / flowTriggers * 100) / 100
      const text = blockHits === 0 ? '0' : `${blockHits} times (${hitPercent}%)`

      const rectWidth = this._getRectangleWidth(text)

      const outputVariableRect = this._createOutputRectangle(bpmnElement.type, rectWidth)
      svg.appendChild(outputVariableRect)

      const outputVariableText = this._createOutputText(bpmnElement.type, rectWidth, text)
      svg.appendChild(outputVariableText)
    }
  }

  hideFlowStatistics () {
    Array.from(document.getElementsByClassName(FlowStatisticsService.STATISTIC_OUTPUT_CLASS_NAME)).forEach(bl => bl.remove())
    Array.from(document.getElementsByClassName(FlowStatisticsService.STATISTIC_TEXT_CLASS_NAME)).forEach(bl => bl.remove())
  }

  async refreshFlowStatistics (flowOrigin: FlowOrigin, flowName: string, flowVersion: string) {
    await this.getStatistics(flowOrigin, flowName, flowVersion, true)
    await this.showFlowStatistics(flowOrigin, flowName, flowVersion)
  }

  private _getRectangleWidth (text: string) {
    return getTextWidth(text, FlowStatisticsService.FONT) + 9 + text.length / 3
  }

  private _getRectangleXVal (blockType: string, rectWidth: number) {
    switch (blockType) {
      case 'bpmn:Task': return (100 - rectWidth) / 2
      case 'bpmn:ExclusiveGateway': return 32
      case 'bpmn:StartEvent': return -rectWidth
      case 'bpmn:EndEvent': return 37
    }
  }

  private _getRectangleYVal (blockType: string) {
    switch (blockType) {
      case 'bpmn:Task': return -30
      case 'bpmn:ExclusiveGateway': return -18
      case 'bpmn:StartEvent':
      case 'bpmn:EndEvent': return 3
    }
  }

  private _createOutputRectangle (type: string, rectWidth: number) {
    const xVal = this._getRectangleXVal(type, rectWidth).toString()
    const yVal = this._getRectangleYVal(type).toString()

    const outputVariableRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect') // Create a path in SVG's namespace
    outputVariableRect.setAttribute('x', xVal.toString())
    outputVariableRect.setAttribute('y', yVal.toString())
    outputVariableRect.setAttribute('width', rectWidth.toString())
    outputVariableRect.setAttribute('height', '30')
    outputVariableRect.setAttribute('rx', '10')

    outputVariableRect.classList.add(FlowStatisticsService.STATISTIC_OUTPUT_CLASS_NAME)

    outputVariableRect.style.stroke = '#979797'
    outputVariableRect.style.strokeWidth = '1px'
    outputVariableRect.style.fill = '#C71585'

    return outputVariableRect
  }

  private _createOutputText (type: string, rectWidth: number, text: string) {
    const xVal = (this._getRectangleXVal(type, rectWidth) + 4).toString()
    const yVal = (this._getRectangleYVal(type) + 19).toString()

    const outputVariableText = document.createElementNS('http://www.w3.org/2000/svg', 'text') // Create a path in SVG's namespace
    outputVariableText.setAttribute('lineheight', '1')
    outputVariableText.setAttribute('x', xVal.toString())
    outputVariableText.setAttribute('y', yVal.toString())
    outputVariableText.setAttribute('width', '100')
    outputVariableText.setAttribute('height', '30')

    outputVariableText.classList.add(FlowStatisticsService.STATISTIC_TEXT_CLASS_NAME)

    outputVariableText.style.fontFamily = FlowStatisticsService.FONT_FAMILY
    outputVariableText.style.fontSize = FlowStatisticsService.FONT_SIZE
    outputVariableText.style.fill = 'white'
    outputVariableText.innerHTML = text

    return outputVariableText
  }
}
