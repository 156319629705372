<template>
  <div class="rect rect--custom" v-if="examples && examples[0] && examples[0].text !== ''">
    <h1 style="text-align: left">
      Custom widget
      <!-- <span class="brackets">({{ month }}) </span> -->
    </h1>
    <div>
      <div class="row" v-for="example of examples" :key="example.text">
        <div class="col-8">
          <h3>{{example.text}}</h3>
        </div>
        <div class="col-4 text-right">
          <p>{{example.formula | fix(1)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { fix } from '../filters/number.filters'

@Component({
  filters: {
    fix: fix
  }
})
export default class Custom extends Vue {
  @Prop({ default: [] }) readonly examples: Array<any>;
  @Prop({ default: '' }) readonly month: string;
}
</script>

<style lang="scss" scoped>
@import "analytics.style";

.rect {
  &--custom {
    position: relative;
    min-height: 180px;
    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2,
    h3,
    p {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: $secondary-text;
      line-height: 15px;
      text-transform: uppercase;
    }
    p {
      position: relative;
      top: 1px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
    &__border {
      display: inline-block;
      border-top: 1px solid #d8d8d8;
      width: 100%;
      margin: 8px 15px 12px;
    }
  }
}
</style>
