import axios from 'axios'

export class GoogleHomeService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/google/${botName}`
  }

  async updateActions (processId: string, projectId: string) {
    const res = await axios.post(`${this.url}/update_actions`, { processId, projectId })
    return res.data
  }

  async provideAuthCode (processId: string, authCode: string, projectId: string) {
    const res = await axios.post(`${this.url}/auth_code`, { processId, authCode, projectId })
    return res.data.message
  }
}
