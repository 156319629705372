export enum NuanceLanguage {
    Arabic = 'ar-WW',
    Catalan = 'ca-ES',
    Danish = 'da-DK',
    Dutch = 'nl-NL',
    'English (AU)' = 'en-AU',
    'English (US)' = 'en-US',
    'English (IN)' = 'en-IN',
    'English (GB)' = 'en-GB',
    Finnish = 'fi-FI',
    'French (CA)' = 'fr-CA',
    'French (FR)' = 'fr-FR',
    German = 'de-DE',
    Hindi = 'hi-IN',
    Indonesian = 'in-ID',
    Italian = 'it-IT',
    Japanese = 'ja-JP',
    Korean = 'ko-KR',
    Malay = 'ms-MY',
    Polish = 'pl-PL',
    'Portuguese (BR)' = 'pt-BR',
    'Portuguese (PT)' = 'pt-PT',
    Russian = 'ru-RU',
    'Simplified Mandarin (CN)' = 'zh-CN',
    'Spanish (ES)' = 'es-ES',
    'Spanish (US)' = 'es-US',
    Swedish = 'sv-SE',
    'Traditional Mandarin (TW)' = 'zh-TW',
    Vietnamese = 'vi-VN'
  }