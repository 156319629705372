<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getTextWidth } from '../../helpers/text.helper'

@Component
export default class Autocomplete extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  items

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  isLoading

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  disabled

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  placeholder

  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  rows

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  value

  @Prop({
    type: String,
    required: false,
    default: '0 20px'
  })
  padding

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  opened

  @Prop({ default: false }) autoSizing: boolean

  @Prop({ default: false }) hasAddButton: boolean

  private isOpen = false
  private results = []
  private search = ''
  private arrowCounter = 0
  // Does not match /[ ... ] or \[ ... ]
  private regex = /([a-zA-Z0-9\-\_\.]{1,})$/
  private replaceBrackets = /(^(?!\/)(\[|\]))/

  get style() {
    return {
      padding: this.opened ? '0 40px 0 20px' : this.padding === '0 60px 0 20px' ? this.padding : '0 24px 0 14px',
      width: this.autoSizing ? 'auto' : undefined
    }
  }

  private font = ''
  get inputSize() {
    let result = getTextWidth(this.value, this.font) / 7
    if (this.hasAddButton) result += 2.5
    return result
  }

  emitSelectionStartAndEnd(val) {
    this.$emit('selections', {
      start: val.target.selectionStart,
      end: val.target.selectionEnd
    })
  }

  onChange(val) {
    this.$emit('input', this.search)
    this.emitSelectionStartAndEnd(val)
    this.filterResults()
  }

  moveWithArrows(val) {
    if (val.key == 'ArrowLeft' || val.key == 'ArrowRight') {
      this.$emit('selections', {
        start: val.target.selectionStart,
        end: val.target.selectionEnd
      })
    }
  }

  filterResults() {
    const match = this.regex.exec(this.search)
    this.isOpen = !!match

    if (this.isOpen) {
      const [, keyword] = match

      this.results = this.items.filter(item => item.toLowerCase().includes(keyword.toLowerCase()))
    }
    if (this.results.length == 0) this.isOpen = false
  }

  setResult(result) {
    result = result.replace(this.replaceBrackets, '')
    // this.search = this.search.replace(this.replaceBrackets, '')

    if (result) {
      const { index } = this.regex.exec(this.search)
      const prefix = this.search[index - 1] === '[' ? '' : '['
      const replaceValue = `${prefix}${result}]`
      this.search = this.search.replace(this.regex, replaceValue)
    }

    this.isOpen = false
    this.$emit('input', this.search)
  }

  onArrowDown() {
    if (this.arrowCounter < this.results.length) {
      this.arrowCounter++
    }
  }

  onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter--
    }
  }

  onEnter() {
    if (this.isOpen) {
      this.setResult(this.results[this.arrowCounter])
      this.isOpen = false
      this.arrowCounter = -1
    } else {
      this.$emit('enter')
    }
  }

  handleClickOutside(evt) {
    if (!this.$el.contains(evt.target)) {
      this.isOpen = false
      this.arrowCounter = -1
    }
  }

  @Watch('items')
  newResultsValue(val, oldValue) {
    if (val.length !== oldValue.length) {
      this.results = val
    }
  }

  @Watch('value')
  newSearchValue(_) {
    this.search = this.value
  }

  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    this.search = this.value
    // JS hax to get input font style
    this.font =
      window.getComputedStyle(this.$refs.input as HTMLElement, null).getPropertyValue('font-size') +
      ' ' +
      window.getComputedStyle(this.$refs.input as HTMLElement, null).getPropertyValue('font-family')
  }

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<template>
  <div class="autocomplete">
    <input
      ref="input"
      @select="emitSelectionStartAndEnd"
      @click="
        emitSelectionStartAndEnd
        $emit('click')
      "
      @keyup="moveWithArrows"
      v-if="rows == 1"
      type="text"
      @input="onChange"
      v-model="search"
      class="form-control"
      :placeholder="placeholder"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @focusout="$emit('focusout')"
      @keydown.enter="onEnter"
      :style="style"
      :disabled="disabled"
      :size="inputSize"
    />
    <textarea
      v-else-if="rows > 1"
      type="text"
      :rows="rows"
      @input="onChange"
      @click="$emit('click')"
      v-model="search"
      class="form-control"
      :placeholder="placeholder"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      @focusout="$emit('focusout')"
      :disabled="disabled"
      @keydown.tab.prevent="$tabber(event, 'search')"
    ></textarea>
    <ul id="autocomplete-results" v-show="isOpen && results.length > 0" class="autocomplete-results" :disabled="disabled">
      <li class="loading" v-if="isLoading">Loading results...</li>
      <li v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">
        {{ result }}
      </li>
    </ul>
  </div>
</template>
