import { BlitsError } from "./blits.error";

export class SynchronizationWarning extends BlitsError {

    static CODE = "sync_warning";

    constructor(message: string, public provider: string, internalMessage: string) {
        super(SynchronizationWarning.CODE, message, internalMessage);
    }

    toJSON(defaultMessage?: string) {
        return { ...super.toJSON(defaultMessage), internalMessage: this.internalMessage }
    }
}