import { NluMap } from "../types/nlu-map.type"
import { ExternalProvider } from "../enums/external-provider.enum"

export const nluNames: NluMap<string> = {
  [ExternalProvider.Microsoft]: "Microsoft LUIS",
  [ExternalProvider.MicrosoftCLU]: "Microsoft CLU",
  [ExternalProvider.IBM]: "IBM Watson Assistant",
  [ExternalProvider.Google]: "Google Dialogflow",
  [ExternalProvider.Rasa]: "Rasa",
  [ExternalProvider.Amazon]: "Amazon Lex",
  [ExternalProvider.Wit]: "Wit",
  [ExternalProvider.Nuance]: "Nuance NLU",
  [ExternalProvider.NeuralSpace]: "NeuralSpace",
}
