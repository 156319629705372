export const luisPrebuiltEntityMappings: any = {
    "Age": "age",
    "Date": "datetimeV2",
    "Dimension": "dimension",
    "Email": "email",
    "Location": "geographyV2",
    "Currency": "money",
    "Number": "number",
    "Ordinal": "ordinalV2",
    "Percentage": "percentage",
    "PersonalName": "personName",
    "PhoneNumber": "phonenumber",
    "Temperature": "temperature",
    "Url": "url"
}
