<template>
  <section class="content-intents">
    <div class="row sidenav">
      <div class="col-3">
        <BotSideNav
          :type="type"
          :hasImportExport="hasImportExport && current"
          :items="filteredFunctions.map(f => f.name)"
          :itemsEditable="false"
          :filterOptions="functionsFilters"
          :selected-filter.sync="selectedFilter"
          :selected-index.sync="selectedIndex"
          :botName="botName"
          @add="$emit('add')"
          @import="$emit('import')"
          @export="$emit('export', current)"
          @delete="deleteFunction"
        ></BotSideNav>
      </div>
      <div class="col-9">
        <div class="content-intents__main" v-if="functions && filteredFunctions[0]">
          <div class="main-header" v-if="current">
            <ComponentName
              :value="current.name"
              :name="current.name"
              :key="current.name"
              :botName="botName"
              @input="
                data => {
                  $emit('edit-name', { ...this.current, newName: data })
                }
              "
            ></ComponentName>
            <Description
              v-model="current.description"
              :type="current.type == FunctionType.Flow ? 'sub' : 'function'"
              @input="
                data => {
                  $emit('description', { functionName: current.name, description: data })
                }
              "
            ></Description>
            <span v-if="current.type === FunctionType.REST" class="type-card">REST Function</span>
            <span v-if="current.type === FunctionType.SQL" class="type-card">SQL Function</span>
            <span v-if="current.type === FunctionType.Code" class="type-card">Custom Code Function</span>
            <span v-if="current.type === FunctionType.AdaptiveCard" class="type-card">Adaptive Card</span>
            <span v-if="current.type === FunctionType.CaptureStorage" class="type-card">Capture Storage (BETA)</span>
            <span v-if="current.type === FunctionType.SendEvent" class="type-card">Send Event (BETA)</span>
            <span v-if="current.isGlobal" class="type-card">Global</span>
            <span v-if="!current.botId" class="type-card">Prebuilt</span>
          </div>
          <section id="output">
            <button class="btn btn--white top-bar" v-if="current.type !== FunctionType.Flow && current.type !== FunctionType.AdaptiveCard" @click="getOutput">
              <i class="icon icon-run-code"></i>Run Function
            </button>
            <button class="btn btn--white top-bar" v-if="current.type == FunctionType.Flow" @click="openDialogManager">
              <i class="icon icon-edit-sub"></i>Edit Sub Flow
            </button>
            <button id="btn btn--white top-bar" class="btn btn--white top-bar" v-if="current.type == FunctionType.Flow" @click="openFlowForTesting">
              <i class="icon icon-test-sub"></i>Test Sub Flow
            </button>
            <button
              class="btn btn--white top-bar"
              v-if="current.type == FunctionType.AdaptiveCard"
              @click="
                prepareAdaptiveCardPreview()
                showFunctionResult = true
              "
            >
              <i class="icon icon-ad-card"></i>Show Adaptive Card
            </button>
            <button class="btn btn--white top-bar" @click="showFunctionUsage">
              Show usage
            </button>

            <FunctionUsage
              v-if="showUsageOpened"
              :currentFunctionName="current.name"
              :botName="botName"
              :isGlobal="current.isGlobal"
              :type="current.type"
              @close="showUsageOpened = !showUsageOpened"
            ></FunctionUsage>
            <div class="alert alert-danger" role="alert" style="margin-top: 20px; display: none">Error getting output!</div>

            <Modal v-if="showFunctionResult" title="Output" :show="true" @close="showFunctionResult = false">
              <div class="modal-description">Preview the output from your function or your adaptive card example.</div>
              <div v-if="current.type == FunctionType.AdaptiveCard && !adaptiveCardShowError" class="adaptive-card">
                <AdaptiveCards :card="adaptiveCardPreview" :data="adaptiveCardPreview.$data || {}" :useTemplating="true"></AdaptiveCards>
              </div>
              <div v-else id="output-body">
                <h4>JSON Body</h4>
                <textarea
                  class="da-input"
                  :class="{ code: !adaptiveCardShowError }"
                  readonly
                  :value="adaptiveCardShowError ? adaptiveCardErrorMessage : functionResult"
                  rows="30"
                  cols="20"
                ></textarea>
              </div>
            </Modal>
          </section>

          <div v-if="current.type == FunctionType.Flow">
            <img :src="'/assets/icons/template-sub.png'" style="height:300px; margin-bottom:20px;" alt="Flow preview image" />
          </div>

          <div class="form--wrapper">
            <FunctionEditor
              :variables="variables"
              :isCreate="false"
              :data="current"
              :isCurrentFunctionGlobal="isCurrentFunctionGlobal"
              :key="current.name"
              :showType="false"
              :botName="botName"
            ></FunctionEditor>
          </div>

          <BotSectionFooter
            :type="current.type == FunctionType.Flow ? 'Sub' : 'Function'"
            :exportText="current.type == FunctionType.Flow ? 'Sub' : 'Function'"
            :key="current.type"
            :isSaving="isSaving"
            ref="botSectionFooter"
            @on-save="saveFunction"
            :publishable="false"
            :exportable="true"
            @on-export="exportFunction"
            v-if="!showFunctionResult"
          ></BotSectionFooter>
        </div>
        <div v-else class="content-intents__empty">
          <div class="content">
            <div class="space-ship"></div>
            <h3>Start building!</h3>
          </div>
        </div>
      </div>
    </div>

    <InternalTestChat
      v-if="flowForTesting"
      :useStagingVersion="true"
      :botName="botName"
      :tenantId="tenantId"
      :flowName="flowForTesting"
      :openedKey="flowChatOpenedKey"
    ></InternalTestChat>
  </section>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import BotSectionFooter from '../footer/BotSectionFooter.vue'
import BotSideNav from '../sidenav/BotSideNav.vue'
import functionsService from '../../../../services/bots/functions.service'
import ComponentName from '../../../helpers/ComponentName.vue'
import Description from '../../../helpers/Description.vue'
import Modal from '../../../helpers/Modal.vue'
import FunctionEditor from '../functions/FunctionEditor.vue'
import InternalTestChat from '../../../client/chat/internal/InternalTestChat.vue'
import { AdaptiveCards } from 'adaptivecards-vue'
import preventBodyScrollMixin from '../../../_mixins/prevent-body-scroll'
import FunctionUsage from './FunctionUsage.vue'
import { parseAdaptiveCardJson } from '../../../../../../common/helpers/adaptive-cards.helper'
import { Template as AdaptiveCardTemplate } from 'adaptivecards-templating'
import { FunctionType } from '../../../../../../common/enums/bot/functions/function-type.enum'
import popupService from '@/services/popup.service'

@Component({
  components: {
    BotSectionFooter,
    BotSideNav,
    Description,
    FunctionEditor,
    Modal,
    InternalTestChat,
    AdaptiveCards,
    ComponentName,
    FunctionUsage
  }
})
export default class Functions extends Mixins(preventBodyScrollMixin) {
  @Prop({ default: () => [] }) functions: any[]
  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  settings

  @Prop({ type: Boolean, default: true }) showGroups
  @Prop({ type: String }) botName: string
  @Prop({ type: Number, default: () => 0 }) active
  @Prop({ type: String, default: 'Function' }) type
  @Prop({ default: () => [] }) readonly variables!: string[]
  @Prop() tenantId: string
  @Prop() readonly hasImportExport: boolean
  @Prop() isSaving: boolean

  FunctionType = FunctionType

  private current = {
    description: '',
    type: 'sql',
    name: 'Function',
    outputs: [
      {
        propertyPath: 'name1',
        variableName: 'object1'
      }
    ],
    inputs: [
      {
        name: '',
        defaultValue: ''
      }
    ],
    connectionString: '',
    sqlCommand: '',
    method: 'GET',
    url: '',
    body: '',
    isGlobal: false,
    botId: ''
  }

  private isCurrentFunctionGlobal = this.current.isGlobal

  private _selectedSubsIndex = 0
  private _selectedFuncIndex = 0

  private _selectedFilter = 'All'
  private functionsFilters: string[] = ['All', 'SQL', 'REST', 'Adaptive Card', 'Code', 'Capture Storage', 'Send Event', 'Global Functions', 'Prebuilt']
  private functionResult = ''
  private showFunctionResult = false
  private showError = false

  flowChatOpenedKey = 0
  flowForTesting = ''

  private adaptiveCardShowError = false
  private adaptiveCardErrorMessage = ''
  private showUsageOpened = false

  get filteredFunctions() {
    return this.functions.filter(f => {
      if (!this.selectedFilter || this.selectedFilter === 'All') return f.botId
      if (this.selectedFilter === 'Global Functions') return f.botId && f.isGlobal
      if (this.selectedFilter === 'Prebuilt') return !f.botId
      if (this.selectedFilter !== 'All') return f.botId && f.type.toLowerCase() === this.selectedFilter.toLowerCase().replace(/\s/, '')
    })
  }

  private static lastFunctionsType: string | null = null
  private static lastFunctionsLength = -1

  created() {
    this._selectedSubsIndex = this.active
    this._selectedFuncIndex = this.active
  }

  @Watch('functions', { immediate: true })
  function(newFunctions: any[]) {
    this.updateFunctions(
      Functions.lastFunctionsType != this.type
        ? 'first'
        : Functions.lastFunctionsLength === newFunctions.length
        ? 'current'
        : Functions.lastFunctionsLength != -1 && newFunctions.length > Functions.lastFunctionsLength
        ? 'last'
        : 'first'
    )
    Functions.lastFunctionsType = this.type
    Functions.lastFunctionsLength = newFunctions.length
  }

  openDialogManager() {
    this.$emit('show-dialog-manager')
  }

  showFunctionUsage() {
    this.showUsageOpened = true
  }

  private adaptiveCardPreview: any = ''
  prepareAdaptiveCardPreview() {
    try {
      const json = (this.current as any).json
      this.adaptiveCardShowError = false

      const variables = this.current.inputs
        .filter(i => !!i.name)
        .reduce((result, i) => {
          result[i.name] = i.defaultValue
          return result
        }, {} as any)

      const cardRaw = parseAdaptiveCardJson(json, variables)
      const template = new AdaptiveCardTemplate(cardRaw)
      this.adaptiveCardPreview = template.expand({ $root: {} })
    } catch (err) {
      this.adaptiveCardErrorMessage = `${err.message}\n`
      this.adaptiveCardShowError = true
      this.adaptiveCardPreview = ''
    }
  }

  updateFunctions(select: 'first' | 'current' | 'last' = 'last') {
    if (select != 'current') this.updateSelectedIndex(select === 'last')
    this.current = this.filteredFunctions[this.selectedIndex]
    this.isCurrentFunctionGlobal = this.current.isGlobal
  }

  deleteFunction(index: number) {
    popupService.deletePopupEvent('function ' + this.filteredFunctions[index].name, () => {
      this.$emit('delete', this.filteredFunctions[index])
    })
  }

  saveFunction(data) {
    if (data) this.current = data

    const result = { ...this.current }

    result.outputs = this.current.outputs.filter(o => String(o.variableName).trim() != '')
    result.inputs = this.current.inputs.filter(i => String(i.name).trim() != '')

    this.$emit('update', result)

    this.isCurrentFunctionGlobal = this.current.isGlobal

    this._selectedFilter = 'All'
  }

  exportFunction() {
    this.$emit('export', this.current)
  }

  async getOutput() {
    try {
      const func = JSON.parse(JSON.stringify(this.current))
      func.outputs = func.outputs.filter(o => String(o.variableName).trim() != '')
      func.inputs = func.inputs.filter(i => String(i.name).trim() != '')

      this.adaptiveCardShowError = false
      const response = await functionsService.getOutput(this.botName, func.name, func)

      this.functionResult = response
      if (typeof this.functionResult === 'object') {
        this.functionResult = JSON.stringify(this.functionResult, null, 2)
      }
    } catch (err) {
      this.functionResult = `${err.message}\n Details: ${err.details}`
    }
    this.showFunctionResult = true
  }

  get isSub() {
    return this.type.toLowerCase() == 'sub'
  }

  get selectedIndex() {
    if (this.isSub) return this.$data._selectedSubsIndex
    return this.$data._selectedFuncIndex
  }

  set selectedIndex(newValue) {
    if (this.isSub) this.$data._selectedSubsIndex = newValue
    else this.$data._selectedFuncIndex = newValue

    this.current = this.filteredFunctions[newValue]
    this.$emit('selected', this.current)

    this.$router
      .push({
        name: 'BotTab',
        params: {
          botName: this.botName,
          tab: this.type.toLowerCase() + 's',
          element: this.filteredFunctions[this.selectedIndex] ? this.filteredFunctions[this.selectedIndex].name : this.functions[this.selectedIndex].name
        }
      })
      .catch(_ => {})

    this.isCurrentFunctionGlobal = this.current?.isGlobal || false

    this.$emit('updatedSelectedElement', {
      elementTab: this.type.toLowerCase() + 's',
      newName: this.functions[this.selectedIndex].name
    })
  }

  private updateSelectedIndex(selectNew: boolean) {
    const index = selectNew ? this.filteredFunctions.length - 1 : this.filteredFunctions.findIndex(i => i.name == this.$router.currentRoute.params.element)
    this.selectedIndex = index >= 0 ? index : 0
  }

  get selectedFilter() {
    return this.$data._selectedFilter
  }

  set selectedFilter(newFilter) {
    this.$data._selectedFilter = newFilter
    this.$nextTick(() => {
      const selectedIndex = this.filteredFunctions.findIndex(f => f.name === this.current?.name)
      this.selectedIndex = selectedIndex === -1 ? 0 : selectedIndex
      this.current = this.filteredFunctions[this.selectedIndex] || {}
    })
  }

  allCategories() {
    if (!this.settings || !this.showGroups) return []
    return Object.values(this.settings.categories || {})
      .concat(this.settings.groups ? this.settings.groups.functions : [])
      .concat('All')
  }

  openFlowForTesting() {
    this.flowChatOpenedKey++
    this.flowForTesting = this.current.name
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/_chat.scss';

.ac-pushButton {
  font-size: 14px;
  font-weight: normal;
  color: $received-text;
  background-color: $sent-text;
  border-radius: 5px;
  padding: 12px;
  border: 0;
  box-shadow: 0px 1px 10px #0000000d;
  cursor: pointer;
  outline: none;
  width: 100%;
  &:hover {
    background-color: $sent-background;
    color: $sent-text;
  }
}

#test-sub-btn {
  margin-left: 10px;
}

.adaptive-card {
  background-color: $received-background;
}
.ac-container,
.ac-adaptiveCard {
  background-color: transparent !important;
}

#output-body {
  textarea {
    width: 100%;
    height: 400px;
    line-height: 30px;
  }
}
.main-fill-color-button {
  margin-right: 15px;
}
</style>
