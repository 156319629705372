<template>
  <ConfigurationPageBase
    :mode="isTenantAdmin ? 'admin' : 'user'"
    :plan="tenantPlan"
    :usersCount="tenantUsers.length"
    @user-created="addUser"
    v-slot:default="{ chosenPage }"
  >
    <Bots
      v-if="chosenPage == 'Bots'"
      :botsData="botsData"
      :isDeleting="isDeleting"
      :isDataLoading="isDataLoading"
      @edit="openOrCloseEditComponentBot"
      @delete="deleteBot"
    ></Bots>

    <Users
      v-if="chosenPage == 'Users'"
      :usersData="usersData"
      :isDeleting="isDeleting"
      :isDataLoading="isDataLoading"
      :showLogsButton="true"
      @edit="openOrCloseEditComponentUser"
      @delete="deleteUser"
    ></Users>

    <UserRoles
      v-if="chosenPage == 'Roles'"
      :roles="roles"
      :permissions="permissions"
      :isDataLoading="isDataLoading"
      @changeRolesState="changeRolesState"
      @addNewRole="addNewRole"
      @deleteRole="deleteRole"
    ></UserRoles>

    <General
      v-if="chosenPage == 'General'"
      :tenantDisplayName="tenantDisplayName"
      :tenantEmail="tenantEmail"
      :isTenantAdmin="isTenantAdmin"
      :isMasterTenantAdmin="isMasterTenantAdmin"
      :isMultiTenant="isMultiTenant"
      :stripeCustomer="stripeCustomer"
      :isDataLoading="isDataLoading"
      :secretKey="secretKey"
      @submit="updateTenantSettings"
    ></General>

    <Subscription
      v-if="chosenPage == 'Subscription' && tenantPlan"
      :isTenantAdmin="isTenantAdmin"
      :isMasterTenantAdmin="isMasterTenantAdmin"
      :isMultiTenant="isMultiTenant"
      :plan="tenantPlan"
      :isDataLoading="isDataLoading"
      :plans="tenantPlans"
      :tenantUsage="tenantUsage"
      :masterTenant="masterTenant"
      :stripeCustomer="stripeCustomer"
    ></Subscription>

    <EditBot
      v-if="editComponentBotOpened"
      :tenantUsers="tenantUsers"
      :tenantRoles="roles.filter((r) => r.roleName != 'Account administrator')"
      :botName="botName"
      :botDisplayName="currentBotDisplayName"
      :botId="botId"
      @close="openOrCloseEditComponentBot"
      @saveEditBotChanges="saveEditBotChanges"
    ></EditBot>

    <EditUser
      v-if="editComponentUserOpened"
      :userData="selectedUser"
      :tenantBots="tenantBots"
      :tenantRoles="roles.filter((r) => r.roleName != 'Account administrator')"
      @update-user="saveEditUserChanges"
      @close="openOrCloseEditComponentUser"
    ></EditUser>
  </ConfigurationPageBase>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ConfigurationPageBase from "./ConfigurationPageBase.vue";
import Bots from "./tabs/Bots.vue";
import Users from "./tabs/Users.vue";
import UserRoles from "./tabs/UserRoles.vue";
import General from "./tabs/General.vue";
import userRolesService from "../../../services/user-roles.service";
import EditBot from "./edit-pages/EditBot.vue";
import Subscription from "./tabs/Subscription.vue";
import EditUser from "./edit-pages/EditUser.vue";
import botService from "../../../services/bots/bot.service";

import rolesService from "../../../services/roles.service";
import {
  Permissions,
  PermissionsNames,
} from "../../../../../common/enums/tenant/user-permissions.enum";

import tenantSettingsService from "../../../services/tenants/settings.service";
import popupService from "../../../services/popup.service";
import tenantService from "../../../services/tenant.service";
import usersService from "../../../services/users.service";
import { RoleViewModel } from "./models/role.view.model";
import { UpdateUserViewModel } from "./models/update-user.view.model";
import permissionsService from "../../../services/tenants/permissions.service";
import { TenantPlan } from "../../../../../common/interfaces/plans/tenant-plan.interface";
import { IStripeCustomerDto } from "../../../../../common/interfaces/stripe-customer-dto.interface";
import { IUser } from "../../../../../common/interfaces/tenant/user.interface";
import { ITenant } from "../../../../../common/interfaces/tenant/tenant.interface";
import { ITenantRole } from "../../../../../common/interfaces/tenant/tenant-role.interface";
import authService from "../../../services/auth.service";
import { isTenantAdmin } from "../../../../../common/helpers/tenant-role.helper";
import {
  countryNames,
  countryNamesByCode,
} from "../../../../../common/constants/countries.constant";

@Component({
  components: {
    ConfigurationPageBase,
    Bots,
    Users,
    UserRoles,
    General,
    EditBot,
    EditUser,
    Subscription,
  },
})
export default class ConfigurationPage extends Vue {
  private tenant: ITenant = null;
  private editComponentBotOpened = false;
  private editComponentUserOpened = false;
  private currentBotDisplayName = "";
  private botName = "";
  private currentUserEmail = "";
  private botId = "";
  private currentUserTenantAdmin = false;
  private isDeleting = false;

  private tenantDisplayName = "";
  private tenantEmail = "";

  private isTenantAdmin = false;
  private isMasterTenantAdmin = false;
  private masterTenant: Pick<ITenant, "id" | "displayName"> | undefined = null;
  private isMultiTenant = false;

  private stripeCustomer: IStripeCustomerDto = null;

  private selectedUser: IUser;

  private tenantBots: { id: string; displayName: string; botName: string }[] =
    [];
  private tenantUsers: IUser[] = [];

  // Permissions page
  private roles: RoleViewModel[] = [];
  private permissions: { key: number; value: string }[] = [];

  // Tenant plan
  private tenantPlan: TenantPlan = null;
  private tenantPlans: TenantPlan[] = [];

  // Tenant usage
  private tenantUsage: any = {};

  private isDataLoading = true;

  secretKey = "";

  addUser(user: any) {
    this.tenantUsers.push(user);
  }

  async created() {
    await this.loadData();
    this.isDataLoading = false;
  }

  async updateTenantSettings(data) {
    try {
      await tenantSettingsService.updateTenantSettings(data.tenantSettings);
      await usersService.updateStripeCustomerData(data.stripeCustomer);

      popupService.showInfo("Saved!");
    } catch (err) {
      popupService.showError("Error!", err);
    }
  }

  private loadTenantSettings() {
    this.tenantDisplayName = this.tenant.displayName;
    this.tenantEmail = this.tenant.email;
    this.secretKey = this.tenant.secretKey;
  }

  private async loadData() {
    await permissionsService.setup();
    this.isTenantAdmin = await permissionsService.isUserAdmin();
    this.isMasterTenantAdmin = await permissionsService.isMasterTenantAdmin();
    this.masterTenant = permissionsService.masterTenant;
    this.isMultiTenant = await permissionsService.isMultiTenant();

    const promiseArray = [this.loadTenant(), this.loadBots(), this.loadUsers()];
    if (this.isTenantAdmin) {
      promiseArray.push(
        ...[
          this.loadTenantPlan(),
          this.loadTenantPlans(),
          this.loadStripeCustomer(),
          this.loadTenantUsage(),
        ]
      );
    }
    await Promise.all(promiseArray);
    this.loadTenantSettings();
  }

  private async openOrCloseEditComponentBot(index: number) {
    if (index >= 0) {
      this.botName = this.tenantBots[Number(index)].botName || "";
      this.currentBotDisplayName =
        this.tenantBots[Number(index)].displayName || "";
    }
    if (!this.editComponentBotOpened) {
      this.botId = this.tenantBots[index].id;
      this.editComponentBotOpened = !this.editComponentBotOpened;
    } else {
      this.editComponentBotOpened = !this.editComponentBotOpened;
      this.tenant = await tenantService.getTenant();
    }
  }

  private async openOrCloseEditComponentUser(index: number) {
    if (index >= 0) {
      this.currentUserEmail = this.usersData[index].Name || "";
    }
    if (!this.editComponentBotOpened) {
      this.selectedUser = this.tenantUsers.find(
        (u) => u.id === this.currentUserEmail
      );
      this.currentUserTenantAdmin =
        !!this.selectedUser &&
        isTenantAdmin(
          this.selectedUser,
          permissionsService.tenant,
          permissionsService.masterTenant
        );
      this.editComponentUserOpened = !this.editComponentUserOpened;
    } else {
      this.editComponentUserOpened = !this.editComponentUserOpened;
      this.tenant = await tenantService.getTenant();
    }
  }

  private async addNewRole(roleData) {
    if (String(roleData.newRole).trim() != "") {
      const result = await rolesService.addNewTenantRole(roleData.newRole);
      if (result.status == 201) {
        this.roles.push({
          roleId: result.data,
          roleName: roleData.newRole,
          permissions: [],
        });
      }
    }
  }

  private async deleteRole(roleId: string) {
    const answer = await popupService.showDialog(
      "Are you sure you want to delete this role?",
      ""
    );
    if (answer) {
      const result = await rolesService.deleteRole(roleId);
      if (result.status != 204) {
        await popupService.showError("Faild to delete role?", "");
      } else {
        this.roles = this.roles.filter((r) => String(r.roleId) != roleId);
      }
    }
  }

  private async deleteUser(index: number) {
    this.isDeleting = true;
    const userEmail = this.usersData[index].Name;

    const response = await userRolesService.deleteUser(userEmail).catch((e) => {
      this.isDeleting = false;
      this.usersData[index].Action = "2";
      popupService.showError("Deletion error", e.message);
    });

    if (response) {
      this.usersData.splice(index, 1);
      this.isDeleting = false;
    }
  }

  private async deleteBot(index: number) {
    try {
      this.isDeleting = true;
      const result = await botService.deleteBot(this.tenantBots[index].botName);
      if (result.status == 204) {
        this.tenantBots.splice(index, 1);
      }
      this.isDeleting = false;
    } catch (err) {
      this.isDeleting = false;
      popupService.showError("", err);
    }
  }

  private async changeRolesState(roles: ITenantRole[]) {
    const request = await rolesService.changeRoles(roles);

    if (request.status == 201) popupService.showInfo("Saved!");
    else popupService.showWarning("Error occured while saving the data!");
  }

  private async loadTenant() {
    this.tenant = await tenantService.getTenant();
    this.roles = Object.values(this.tenant.roles);
    this.permissions = Object.keys(PermissionsNames).map((p) => ({
      key: Number(p),
      value: String(PermissionsNames[p]),
    }));

    this.roles.unshift({
      roleId: 0,
      roleName: "Account administrator",
      permissions: this.permissions.map((p) => p.key),
    });
  }

  private async loadUsers() {
    this.tenantUsers = await userRolesService.getUsersForTenant();
  }

  private async loadBots() {
    this.tenantBots = await botService.getBotNameAndId();
  }

  private async loadTenantPlan() {
    this.tenantPlan = await tenantService.getPlan();
  }

  private async loadTenantPlans() {
    this.tenantPlans = await tenantService.getPlans();
  }

  private async loadTenantUsage() {
    this.tenantUsage = await tenantService.getUsage();
  }

  private async loadStripeCustomer() {
    this.stripeCustomer = await usersService.loadStripeCustomerData();
  }

  get usersData() {
    return this.tenantUsers.map((u) => {
      const isAdmin = isTenantAdmin(
        u,
        permissionsService.tenant,
        permissionsService.masterTenant
      );
      const botsCount = isAdmin ? undefined : Object.keys(u.botRoles).length;
      return {
        Name: u.id,
        Bots: isAdmin
          ? "All bots"
          : `${botsCount} Bot${botsCount != 1 ? "s" : ""}`,
        Roles: isAdmin
          ? "Account Administrator"
          : `${botsCount} Role${botsCount != 1 ? "s" : ""}`,
        Action: u.id == authService.user.id ? "myuser" : "2",
      };
    });
  }

  get botsData() {
    return this.tenantBots.map((b) => {
      const botUsers = this.tenantUsers.filter(
        (u) =>
          !!u.botRoles[b.id] ||
          isTenantAdmin(
            u,
            permissionsService.tenant,
            permissionsService.masterTenant
          )
      );
      const userCount = botUsers.length;
      const rolesCount = this.getTheDifferentBotRoles(botUsers, b.id);
      return {
        Name: b.displayName,
        Users: `${userCount} User${userCount != 1 ? "s" : ""}`,
        Roles: `${rolesCount} Role${rolesCount != 1 ? "s" : ""}`,
        Action: permissionsService.hasPermissionSync(
          b.botName,
          Permissions.DeleteBot
        )
          ? "2"
          : "1",
      };
    });
  }

  private async saveEditBotChanges(usersToUpdate: UpdateUserViewModel[]) {
    const usersFaildToSave: string[] = [];

    if (usersToUpdate.length > 0) {
      for (const user of usersToUpdate) {
        if (user.roleId != "-1") {
          const result = await userRolesService.addUserRole(
            user.email,
            user.botName,
            user.role
          );
          if (result.status != 200) {
            usersFaildToSave.push(user.email);
          }
        } else {
          const result = await userRolesService.removeUserRoleForBot(
            user.email,
            this.botName
          );
          if (result.status != 204) {
            usersFaildToSave.push(user.email);
          }
        }
      }

      if (usersFaildToSave.length > 0) {
        usersToUpdate = usersToUpdate.filter(
          (u) => !usersFaildToSave.includes(u.email)
        );
      }
      popupService.showInfo("Saved!");
      await this.loadData();
    } else {
      popupService.showInfo("There is no data to save");
    }
  }

  private async saveEditUserChanges(user: IUser) {
    const tenantUser = this.tenantUsers.find((u) => u.id === user.id);
    const oldFlag = isTenantAdmin(
      tenantUser,
      permissionsService.tenant,
      permissionsService.masterTenant
    );
    const newFlag = isTenantAdmin(
      user,
      permissionsService.tenant,
      permissionsService.masterTenant
    );

    if (oldFlag !== newFlag) {
      await userRolesService.toggleTenantAdmin(user.id);
    }

    if (newFlag === false) {
      for (const role of Object.values(user.botRoles)) {
        await userRolesService.addUserRole(
          user.id,
          this.tenantBots.find((b) => b.id === role.botId).botName,
          role.role
        );
      }
    }

    this.$set(
      this.tenantUsers,
      this.tenantUsers.findIndex((u) => u.id === user.id),
      user
    );
    popupService.showInfo("Saved!");
  }

  private getTheDifferentBotRoles(users: IUser[], botId: string) {
    const roles = new Set();

    for (const user of users.filter(
      (u) =>
        !isTenantAdmin(
          u,
          permissionsService.tenant,
          permissionsService.masterTenant
        )
    )) {
      if (user.botRoles[botId]) roles.add(user.botRoles[botId].roleId);
    }

    return roles.size;
  }
}
</script>
