<template>
  <div class="form-group form-group--options">
    <div>
      <div class="label">Is of type</div>
      <input
      type="radio"
      name="x-is-of-type-radio"
      v-model="currentBlock.customData.isOfType"
      value="number"
      id="x-exists-radio-yes"
      @change="updateLabel"
    />
    <label for="x-exists-radio-yes">Number</label>
    <span style="margin: 0 6px"></span>

    <input
      type="radio"
      name="x-is-of-type-radio"
      v-model="currentBlock.customData.isOfType"
      value="text"
      id="x-exists-radio-no"
      @change="updateLabel"
    />
    <label for="x-exists-radio-no">Text</label>

    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IIsOfTypeBlock } from '../../../../../../../../../common/blocks/connector/is-of-type-block.interface'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component
export default class IsOfType extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<IIsOfTypeBlock>;

  @Emit()
  updateLabel () {
  }

  mounted () {
    this.updateLabel()
  }

  changeType (type: string) {
    this.$set(this.currentBlock.customData, 'isOfType', type)
    this.updateLabel()
  }
}
</script>
