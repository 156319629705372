export const dialog = {
  defaultDialog: {
    key: 'defaultDialog', functionName: 'Select a dialog type', variables: [], category: 'Dialog', hide: true
  },
  message: {
    key: 'message',
    functionName: 'Message',
    fields: [{ id: 'message', type: 'text', name: 'Message', isEditor: true }],
    variables: [],
    category: 'Dialog'
  },
  dictionary: {
    key: 'dictionary',
    functionName: 'Dictionary',
    variables: [],
    category: 'Dialog'
  },
  sendGif: {
    key: 'sendGif',
    functionName: 'Send Image',
    fields: [{ id: 'gif', type: 'text', name: 'Image', rows: 3 }],
    variables: [],
    category: 'Dialog'
  },
  askQuestion: {
    key: 'askQuestion',
    functionName: 'Ask question',
    fields: [
      { id: 'question', type: 'text', name: 'Question', isEditor: true },
      { id: 'is-sensitive', type: 'checkbox', name: 'is-sensitive', placeholder: 'Answer might contain personal or sensitive data' }
    ],
    variables: ['Answer'],
    category: 'Dialog'
  },
  showOptions: {
    key: 'showOptions',
    functionName: 'Show options',
    fields: [
      { id: 'is-sensitive', type: 'checkbox', name: 'is-sensitive', placeholder: 'Answer might contain personal or sensitive data' },
      { id: 'handle-unexpected-input', type: 'checkbox', name: 'handle-unexpected-input', placeholder: 'Disable unexpected user input' }
    ],
    variables: ['ClickedButton'],
    category: 'Dialog'
  },
  showRating: {
    key: 'showRating',
    functionName: 'Rating',
    fields: [
      { id: 'handle-unexpected-input', type: 'checkbox', name: 'handle-unexpected-input', placeholder: 'Handle the unexpected user input' }
    ],
    variables: ['Rating'],
    category: 'Dialog'
  },
  randomAnswer: {
    key: 'randomAnswer', functionName: 'Random Answer', variables: [], category: 'Dialog'
  },
  sendAttachment: {
    key: 'sendAttachment',
    functionName: 'Send attachment',
    fields: [
      { id: 'input', type: 'text', name: "File's name", rows: 1 }
    ],
    category: 'Dialog'
  },
  receiveUserAttachment: {
    key: 'receiveUserAttachment',
    functionName: 'Receive attachment',
    fields: [
      { id: 'message', type: 'text', name: 'Message before user uploads a file', isEditor: true },
      { id: 'is-optional', type: 'checkbox', placeholder: 'Make upload optional.' },
      { id: 'error', type: 'text', name: 'Error message if the user did not upload files', hide: 'is-optional', isEditor: true }
    ],
    variables: ['FileUrls', 'FileContents'],
    category: 'Dialog'
  },
  unexpectedAnswer: {
    key: 'unexpectedAnswer',
    functionName: 'Unexpected Answer',
    fields: [
      { id: 'handle-unexpected-input', type: 'checkbox', name: 'handle-unexpected-input', placeholder: 'Handle the unexpected user input' }
    ],
    variables: ['ClickedAnswerButton'],
    category: 'Dialog',
    hideInWelcome: true
  },
  multipleEntityCheck: {
    key: 'multipleEntityCheck',
    functionName: 'Multiple Entity check',
    category: 'Dialog',
    hideInWelcome: true
  }
}
