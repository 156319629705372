<template>
  <Modal title="Edit Object" :show="true" @close="$emit('close')">
      <div class="form--wrapper">
        <form>
          <div
            class="modal-description"
          >Create or edit your object's properties here. An object is a collection of values your bot can access or use.</div>

          <div class="form-group">
            <div class="form-group--inside">
              <label for="objectName">Object Name</label>
              <input
                :text="name"
                v-model="name"
                type="text"
                id="objectName"
                disabled
                class="disabled"
                placeholder="Name of the object"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="form-group--inside">
              <label for="objectProperties">Properties</label>
              <div class="row spacing">
                <div class="col-4 padding-right">
                  <input
                    :text="newObjectKey"
                    v-model="newObjectKey"
                    id="objectProperties"
                    type="text"
                    placeholder="Enter the key"
                  />
                </div>
                <div class="col-8">
                  <input
                    :text="newObjectValue"
                    v-model="newObjectValue"
                    type="text"
                    placeholder="Enter the value of the property"
                    @keyup.enter="addProperty()"
                  />
                  <div type="button" class="copybutton dark" @click="addProperty()">Add</div>
                </div>
              </div>
              <div class="row spacing" v-for="(value, key) in properties.variables" :key="key">
                <div class="col-4 padding-right">
                  <input
                    :text="properties.variables[key].key"
                    v-model="properties.variables[key].key"
                    type="text"
                    class="form-control blue-card margins2"
                    placeholder="Enter the key."
                  />
                </div>
                <div class="col-8">
                  <input
                    :text="properties.variables[key].value"
                    v-model="properties.variables[key].value"
                    type="text"
                    class="form-control blue-card margins2"
                    placeholder="Enter the value."
                  />
                  <i class="icon-delete delete-object" @click="deleteProperty(key)"></i>
                </div>
              </div>
            </div>
          </div>

          <div v-if="errorMessage" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </form>
      </div>
      <div style="display: flex; float: right; margin-top: 20px;">
        <button class="btn btn--purple gradient" @click="addObject">
          Save
          <i class="icon icon-arrow-right"></i>
        </button>
      </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '../../../helpers/Modal.vue'

@Component({ components: { Modal } })
export default class CreateObject extends Vue {
  @Prop({ default: () => { return {} } }) objectProperties: { variables: any; isGlobal: boolean };
  @Prop({ default: () => { return {} } }) name: string;
  @Prop({ default: () => { return {} } }) botName: string;
  @Prop({
    type: Object,
    required: true,
    default: () => { return {} }
  })
  botValidator;

  private errorMessage = '';
  private nameError = false;
  private properties: { variables?: any; isGlobal?: boolean } = { variables: {}, isGlobal: false };
  private keys = [];
  private newObjectKey = '';
  private newObjectValue = '';

  mounted () {
    if (this.objectProperties) { this.properties = this.objectProperties }
  }

  prepareObjects (properties: { [key: string]: any }) {
    return Object.keys(properties).map(key => ({
      key: key,
      value: properties[key]
    }))
  }

  addProperty () {
    if (this.newObjectKey.trim() === '') { return }
    if (!this.properties) this.properties = {}

    this.properties.variables[this.newObjectKey] = {
      key: this.newObjectKey,
      value: this.newObjectValue,
      botId: this.botName,
      isGlobal: false
    }

    this.newObjectValue = this.newObjectKey = ''
  }

  deleteProperty (key) {
    if (!this.properties.variables[key].isSystem) {
      this.$delete(this.properties.variables, key)
    }
  }

  checkIfDuplicateExists (array) {
    return new Set(array).size !== array.length
  }

  addObject () {
    if (Object.keys(this.properties.variables).length === 0) {
      this.errorMessage = 'Please provide at least one property'
      return
    }
    if (this.checkIfDuplicateExists(Object.keys(this.properties.variables))) { this.errorMessage = 'Warning: No duplicate keys!' } else {
      this.errorMessage = ''
      this.$emit('create', this.properties, this.name)
    }
  }
}
</script>

<style scoped lang="scss">
.form--wrapper form input:not([type="file"]) + i {
  top: -10px;
  right: 10px;
}
</style>
