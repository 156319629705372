<template>
  <div class="app-dropdown show">
    <button
      type="button"
      data-toggle="dropdown"
      :disabled="items.length === 0 || (disabled !== undefined && disabled)"
      aria-haspopup="true"
      aria-expanded="false"
      class="btn intents dropdown-toggle dropdown-toggle-split"
    >
      {{ displayTitle }}
    </button>
    <div class="dropdown-menu dropdown-menu--options">
      <a
        v-for="(item, index) in items"
        :key="index"
        href="#"
        class="dropdown-item"
        :class="{ active: item === displayTitle }"
        @click.prevent="$emit('input', item)"
        >{{ item ? item.value || item : 'None' }}</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Dropdown extends Vue {
  @Prop({ default: 'Choose an option' }) readonly title: string
  @Prop({ default: () => [] }) readonly items: (string | { [key: string]: string })[]
  @Prop() readonly value: string | { [key: string]: string }
  @Prop() disabled: boolean

  get displayTitle() {
    return this.value || this.title
  }
}
</script>
