import { IBlock } from '../../../../../../common/blocks/block.interface'
import store from '@/store'

export default abstract class FlowComponent<T extends IBlock> {
    id = null;

    type: string;

    category: string;

    _customData: { [name: string]: T } = {};

    public get customData (): T {
      return this._customData[store.state.currentLanguage] || this._customData[Object.keys(this._customData)[0]]
    }

    public set customData (value: T) {
      this._customData[store.state.currentLanguage] = value
    }

    constructor (public element: any, public gfx: any, public functionSettings: any = null, customData: any) {
      if (typeof customData === 'string') { customData = JSON.parse(customData) }

      if (Object.keys(customData).filter(k => store.state.supportedLanguages.includes(k)).length == 0) {
        const oldCustomData = { ...customData }
        const newCustomData = {}
        store.state.supportedLanguages.forEach(l => {
          newCustomData[l] = { ...oldCustomData }
        })
        customData = newCustomData
      } else if (Object.keys(customData).filter(k => !store.state.supportedLanguages.includes(k)).length > 0) {
        const newCustomData = {}
        store.state.supportedLanguages.forEach(l => {
          newCustomData[l] = { ...(customData[l] || customData[Object.keys(customData)[0]]) }
        })
        customData = newCustomData
      }

      if (!customData) { customData = element.businessObject._customData ? JSON.parse(element.businessObject._customData) : { [store.state.currentLanguage]: { variables: [], fc: 'default' } } }

      this.id = element.id
      this.type = element.businessObject.$type
      this.category = functionSettings ? functionSettings.category : undefined
      this.element = element

      this.gfx = gfx
      this.functionSettings = functionSettings
      this._customData = customData
    }

    abstract updateRestrictions();

    get isConnector () {
      return this.element.businessObject.$type === 'bpmn:SequenceFlow'
    }

    get isStartBlock () {
      return this.element.businessObject.$type === 'bpmn:StartEvent'
    }

    get getCustomData () {
      return this._customData
    }

    validateFiles () {}

    /** Stores the custom data inside the flow xml */
    serializeData () {
      this.element.businessObject.customData = JSON.stringify(this.getCustomData)

      if (this.isConnector) { this.element.businessObject.$attrs['qa:customData'] = JSON.stringify(this.getCustomData) }
    }

    private static elementNumber: { [name: string]: number } = {};
    static getElementNumber (element: FlowComponent<any>, output = ''): number {
      const name = element.constructor.name + output
      if (!this.elementNumber[name]) { this.elementNumber[name] = 0 }
      return ++this.elementNumber[name]
    }

    static resetElementNumbers () {
      this.elementNumber = {}
    }
}
