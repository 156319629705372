import axios from 'axios'

export class EntitiesService {
  _prepareSublists (entity) {
    for (let sublist = 0; sublist < entity.sublists.length; sublist++) {
      entity.sublists[sublist].canonicalForm = entity.sublists[sublist].canonicalForm.trim()

      if (entity.sublists[sublist].canonicalForm === '') {
        throw new Error('The canonical form cannot be empty')
      }
      if (entity.sublists[sublist].list && entity.sublists[sublist].list.constructor !== Array) {
        entity.sublists[sublist].list = entity.sublists[sublist].list.match(/[^,\s][^,]*[^,\s]*/g)
      }
    }

    for (let i = 0; i < entity.roles.length; i++) {
      entity.roles[i].name = entity.roles[i].name.trim()

      if (entity.roles[i].name === '') {
        throw new Error('The role cannot be empty')
      }
    }
  }

  _prepareSimpleEntity (entity) {
    entity.name = entity.name.trim()

    // for (let i = 0; i < entity.roles.length; i++) {
    //   entity.roles[i].name = entity.roles[i].name.trim()

    //   if (entity.roles[i].name === '') {
    //     throw new Error('The role cannot be empty')
    //   }
    // }
  }

  /**
   * @param {string} containerName - Bot name
   */
  entitiesUrl (containerName) {
    return `${process.env.VUE_APP_BACKEND_URL}/${containerName}/entities`
  }

  /**
   * Creates an entity for a bot
   * @param {string} containerName - Bot name
   * @param {Object} entity
   */
  createEntity (containerName, entity) {
    return entity.sublists
      ? this.createListEntity(containerName, entity)
      : this.createSimpleEntity(containerName, entity)
  }

  /**
   * Creates a list entity in a bot
   * @param {string} containerName - Bot name
   * @param {Object} entity
   */
  createListEntity (containerName, entity) {
    this._prepareSublists(entity)

    return axios
      .post(this.entitiesUrl(containerName), entity)
      .then(response => response.data)
  }

  /**
   * Creates a simple entity in a bot
   * @param {string} containerName - Bot name
   * @param {Object} entity
   */
  createSimpleEntity (containerName, entity) {
    this._prepareSimpleEntity(entity)

    return axios
      .post(this.entitiesUrl(containerName), entity)
      .then(response => response.data)
  }

  /**
   * Updates an entity in a bot
   * @param {string} containerName - Bot name
   * @param {Object} entity
   */
  async updateEntity (containerName, entity) {
    await axios.put(
      this.entitiesUrl(containerName), {
        ...entity,
        sublists: entity.type === 'closedlists' ? entity.sublists : undefined,
        name: entity.name.trim()
      })
  }

  /**
   * Deletes an entity in a bot
   * @param {string} containerName - Bot name
   * @param {Object} entity
   */
  async deleteEntity (containerName, entityName) {
    const result = await axios.delete(`${this.entitiesUrl(containerName)}/${entityName}`)

    if (result.data.error) { throw result.data.error }
  }

  async export (containerName: string, entityName: string) {
    return (await axios.get(`${this.entitiesUrl(containerName)}/${encodeURIComponent(entityName)}/export`,
      { responseType: 'blob' })).data
  }

  validateImport (file) {
    const formData = new FormData()
    formData.append('entity-file', file, file.name)

    return axios
      .post(`${process.env.VUE_APP_BACKEND_URL}/entities/import/staging`, formData)
      .then(res => res.data)
  }

  async import (file: File, containerName: string, entityName: string) {
    const form = new FormData()
    form.append('entity-file', file, file.name)

    return (await axios.post(`${this.entitiesUrl(containerName)}/${entityName}/import`, form)).data
  }

  /**
   * Creates an intent in a given bot
   * @param {string} containerName - Bot name
   * @param {Object} extendedEntity - Entity object, including newName
   */
  editName (containerName, extendedEntity) {
    return axios.put(`${this.entitiesUrl(containerName)}/change-name`, extendedEntity)
  }
}

export default new EntitiesService()
