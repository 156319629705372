<template>
  <Modal :title="user.id" :show="true" @close="$emit('close')">
    <div class="form--wrapper">
      <Toggle
        :value="isMasterTenantAdmin"
        label="Master Tenant Administrator"
        tooltip="Master tenant admins are admins of the master tenant, every child tenant and every account."
        @input="toggleTenantAdmin(masterTenant.id)"
      ></Toggle>

      <p v-if="isMasterTenantAdmin">This user is a master tenant admin. It is an admin of the master tenant and every child tenant.</p>
      <div v-else>
        <div>
          <h4>Assigned tenants</h4>
          <hr />
          <p v-if="assignedTenants.length === 0">This user is not assigned to any tenant.</p>
          <ul v-else>
            <li
              :key="`${index}-a`"
              v-for="(t, index) in assignedTenants"
              class="list-row"
            >
              <p>{{ t.displayName }}</p>
              <Toggle
                class="toggle"
                :value="isTenantAdmin(t.id)"
                label="Account Administrator"
                @input="toggleTenantAdmin(t.id)"
                :noMargin="true"
              ></Toggle>
              <i class="icon-delete entity-delete add" @click="removeTenant(t.id)"></i>
            </li>
          </ul>
        </div>
        <div v-if="unassignedTenants.length > 0" class="unassigned-section">
          <h4>Unassigned Tenants</h4>
          <hr />
          <ul>
            <li
              :key="`${index}-u`"
              v-for="(t, index) in unassignedTenants"
              class="list-row"
            >
              {{ t.displayName }}
              <span type="button" class="copybutton dark" @click="addTenant(t.id)">Add</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="bottom-right-fix">
        <button
          class="btn btn--purple gradient"
          type="button"
          @click="save"
        >
          <i class="icon icon-save" ></i> Save
        </button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Toggle from '../../../helpers/Toggle.vue'
import Modal from '../../../helpers/Modal.vue'
import Tooltip from '../../../helpers/Tooltip.vue'
import { ITenant } from '../../../../../../common/interfaces/tenant/tenant.interface'
import { IUser } from '../../../../../../common/interfaces/tenant/user.interface'
import { isMasterTenantAdmin, isTenantAdmin, toggleTenantRole } from '../../../../../../common/helpers/tenant-role.helper'
import usersService from '../../../../services/users.service'
import popupService from '../../../../services/popup.service'
import { UserTenantRole } from '../../../../../../common/types/users/user-tenant-role.type'

@Component({ components: { Toggle, Tooltip, Modal } })
export default class MasterEditUser extends Vue {
  @Prop({ required: true, default: () => ({}) }) userData: IUser;
  @Prop({ default: () => ({}) }) masterTenant: ITenant;
  @Prop({ default: () => [] }) tenants: ITenant[];

  user: IUser = null
  created () {
    this.user = JSON.parse(JSON.stringify(this.userData))
  }

  get isMasterTenantAdmin () {
    return isMasterTenantAdmin(this.user, this.masterTenant)
  }

  isTenantAdmin (tenantId: string) {
    return isTenantAdmin(this.user, this.tenants.find(t => t.id === tenantId), undefined)
  }

  toggleTenantAdmin (tenantId: string) {
    toggleTenantRole(this.user, tenantId === this.masterTenant.id ? this.masterTenant : this.tenants.find(t => t.id === tenantId))
  }

  get assignedTenants () {
    return this.tenants.filter(t => !!this.user.tenantRoles[t.id])
  }

  get unassignedTenants () {
    return this.tenants.filter(t => !this.user.tenantRoles[t.id])
  }

  addTenant (id: string) {
    this.$set(this.user.tenantRoles, id, 'user' as UserTenantRole)
  }

  removeTenant (id: string) {
    this.$delete(this.user.tenantRoles, id)
  }

  async save () {
    await usersService.updateTenantRoles(this.user)
    popupService.showInfo('Saved!')
    this.$emit('update', this.user)
  }
}
</script>

<style lang="scss" scoped>
@import "../configuration-page-style.scss";

h4 {
  margin-bottom: 10px;
}
hr {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;

  .list-row {
    display: flex;
    align-items: center;

    p {
      width: 33.3%;
    }

    .toggle {
      margin-left: auto;
      margin-bottom: 15px;
    }

    i {
      margin-left: auto;
    }

    .copybutton {
      margin-left: 25px;
    }

    .copybutton {
      margin-bottom: 2px;
      position: initial;
      float: initial;
    }
  }
}

.unassigned-section {
  margin-top: 100px;
}

.control {
  cursor: pointer;
  font-weight: 700;
}
</style>
