<template lang="html">
  <div class="rect rect--untrained-questions">
    <h1>Untrained Questions</h1>
    <div v-if="paginatedItems && paginatedItems[0]" class="extend-height">
      <div class="untrained-question" v-for="(utt, index) in paginatedItems" :key="utt.id">
        <div class="col-6">
          <p>{{ utt.message }}</p>
        </div>
        <div class="col-6 flex-end" style="justify-content: flex-end; align-items: center;">
          <button class="create-intent" @click="createIntent(utt)">Create new intent</button>
          <div class="dropdown" style="margin-left: 10px;">
            <button class="btn btn-secondary dropdown-toggle add-to-intent" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add to intent
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div class="dropdown-item" v-for="(intent, i) in filteredInentNames" :key="`${intent}-${i}`" @click="addUtterance(intent, utt, index)">{{intent}}</div>
            </div>
          </div>
          <span @click="deleteExample (utt.id, index)" class="icon-delete"></span>
        </div>
      </div>
      <b-pagination @change="onPageChanged" :total-rows="rows()" :per-page="perPage" v-model="currentPage"
        aria-controls="my-table" class="page-controls">
        <span slot="first-text">First</span>
        <span slot="prev-text">Prev</span>
        <span slot="next-text">Next</span>
        <span slot="last-text">Last</span>
      </b-pagination>
    </div>
    <div v-else>
      <p class="middle">No data to display</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { fix } from '../filters/number.filters'
import permissionsService from '../../services/tenants/permissions.service'
import { Permissions } from '../../../../common/enums/tenant/user-permissions.enum'

@Component({
  filters: {
    fix: fix
  }
})
export default class UntrainedQuestions extends Vue {
  private perPage = 5;
  private currentPage = 1;
  private paginatedItems = [];

  @Prop({ default: '' }) readonly month: string;
  @Prop({ default: [] }) readonly utterances: Array<any>;
  @Prop({ default: [] }) readonly intentNames: Array<string>;
  @Prop({ default: () => '' }) readonly botName!: string;

  private hasSufficientPermission = false;

  get filteredInentNames () {
    return this.intentNames.filter(i => i != 'None')
  }

  async mounted () {
    this.onUtterancesChange(this.utterances)
    this.hasSufficientPermission = await permissionsService.hasPermission(this.botName, Permissions.UseBotDashboardWidgets)
  }

  @Watch('utterances')
  private onUtterancesChange (newVal) {
    // watch it
    let pageIndex = this.currentPage - 1

    // Check if we deleted all utterances on the current page
    if (newVal.length % this.perPage === 0 && pageIndex !== 0) pageIndex--

    this.paginate(this.perPage, pageIndex)
  }

  async deleteExample (id, indexToRemove) {
    this.$emit('delete-example', id)
    if (this.hasSufficientPermission) {
      this.utterances.splice(
        (this.currentPage - 1) * this.perPage + indexToRemove,
        1
      )
    }
  }

  rows () {
    return this.utterances ? this.utterances.length : 0
  }

  paginate (pageSize, pageIndex) {
    const itemsToParse = this.utterances
    this.paginatedItems = itemsToParse.slice(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    )
  }

  onPageChanged (page) {
    this.currentPage = page
    this.paginate(this.perPage, page - 1)
  }

  createIntent (utt) {
    this.$emit('createIntent', {
      utterance: utt,
      allUntrainedUtterances: this.paginatedItems.map(e => ({
        id: e.id,
        message: e.message
      }))
    })
  }

  addUtterance (intent, utt, index) {
    this.$emit('addExample', {
      intentName: intent,
      exampleText: utt.message
    })

    this.deleteExample(utt.id, index)
  }
}
</script>

<style lang="scss" scoped>
@import "analytics.style";
@import "../../assets/scss/variables";

.icon-delete {
  margin: 0 10px 0;
  top: 0;
}

.rect {
  &--untrained-questions {
    margin-top: 30px;
    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2,
    .untrained-question p {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
    .extend-height {
      padding-bottom: 100px;
    }
    .untrained-question {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #d8d8d8;
      &:first-child {
        border-top: 1px solid #d8d8d8;
      }
      [class^="col-"] {
        padding: 0;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #000;
        line-height: 18px;
      }
    }
    p.middle {
      margin: auto;
      width: 50%;
      text-align: center;
    }
  }
}
.create-intent,
.add-to-intent {
  font-size: 12px;
  font-weight: $bold;
  color: $default;
  line-height: 30px;
  border-radius: 6px;
  padding: 0 12px;
  border: none;
  height: 30px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.create-intent {
  background: none;
  border: 1px solid $main;
  color: $main;
}
.add-to-intent {
  background: $main;
  &:not(:disabled):not(.disabled) {
    &:focus,
    &:active {
      background: $main;
      box-shadow: none;
    }
  }
  &:after {
    position: relative;
    top: 2px;
  }
}

p {
  margin-bottom: 0;
  margin-top: 5px;
}

.card-body {
  display: flex;
}

.dropdown-menu {
  overflow-y: auto;
  max-height: 400px;
}
</style>
