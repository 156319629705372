<template>
  <div>
    Go to
    <a href="https://support.blits.ai/portal/en/kb/knowledgebase"> https://support.blits.ai/portal/en/kb/knowledgebase </a>
  </div>
  <!-- <div>
    <div>
      <Loading v-if="loads" title="Dusting off Blits's archives..."></Loading>
    </div>
    <div class="container wiki" :class="{'no-login': !isLoggedIn }" v-if="!loads">
      <div class="container--content">
        <section class="content-intents content-colored">
          <div class="row">
            <div class="col-2 wiki-sidenav">
              <Sidenav :page="page" @update="updateSection"></Sidenav>
            </div>
            <div class="col-10">
              <div class="content-intents__main">
                <ManualSearch @update="updateSearchMode" ref="searchComponent"></ManualSearch>
                <div v-if="!pageNotFound && visualPage && !searchMode">
                  <div class="wiki-pages-preview">
                    <div v-if="isBasePage" class="wiki-page-preview">
                      <div class="rect rect-colored">
                        <h4>{{ getPageUrl(page) !== '/user-manual/' ? visualPage.displayName : 'Welcome to the Blits platform documentation. Please select a topic you want to know more about.' }}</h4>
                        <div v-if="!editMode" v-html="visualPage.description"></div>
                      </div>
                    </div>

                    <div
                      class="wiki-page-preview"
                      v-if="isBasePage && visualPage.example && visualPage.example.value.replace(/<[^>]+>/g, '').trim()"
                    >
                      <div class="rect rect-colored example">
                        <h4>Example</h4>
                        <div v-if="!editMode" v-html="visualPage.example.value"></div>
                      </div>
                    </div>

                    <div
                      class="wiki-page-preview"
                      v-if="isBasePage && visualPage.video && visualPage.video.value"
                    >
                      <div class="rect rect-colored video">
                        <iframe
                          width="100%"
                          height="400px"
                          v-if="!editVideoMode && visualPage.video.value"
                          :src="visualPage.video.value"
                          allowfullscreen
                        ></iframe>
                        <div v-html="visualPage.video.title"></div>
                      </div>
                    </div>

                    <div class="wiki-edit-page-screen" v-if="editMode">
                      <div class="col-12">
                        <ManualEditor :value="editText" @change="editText = $event"></ManualEditor>
                        <button
                          class="btn btn--white narrow"
                          @click="onEditTextEnd(visualPage)"
                        >Save</button>
                        <button class="btn btn--white red narrow" @click="editMode = false">Cancel</button>
                      </div>
                      <br>
                      <h3 style="text-align: center;">Preview</h3>
                      <div class="preview-content col-12">
                        <div v-html="editText"></div>
                      </div>
                    </div>

                    <div class="row wiki-edit-page-screen" v-if="editExampleMode">
                      <div class="col-6">
                        <ManualEditor :value="editExampleText" @change="editExampleText = $event"></ManualEditor>
                        <button
                          class="btn btn--white narrow"
                          @click="onEditExampleTextEnd(visualPage)"
                        >Save</button>
                        <button class="btn btn--white red narrow" @click="editExampleMode = false">Cancel</button>
                      </div>
                      <div class="col-6">
                        <div v-html="editExampleText"></div>
                      </div>
                    </div>

                    <div class="row wiki-edit-page-screen" v-if="editVideoMode">
                      <div class="col-6">
                        <input
                          class="form-control"
                          v-model="editVideoUrl"
                          placeholder="Video's embed url"
                        />
                        <ManualEditor :value="editVideoText" @change="editVideoText = $event"></ManualEditor>
                        <button
                          class="btn btn--white narrow"
                          @click="onEditVideoTextEnd(visualPage)"
                        >Save</button>
                        <button class="btn btn--white red narrow" @click="editVideoMode = false">Cancel</button>
                      </div>
                    </div>

                    <div class="row wiki-edit-page-screen" v-if="editTitleMode">
                      <div class="col-6">
                        <input
                          class="form-control"
                          v-model="editTitle"
                          placeholder="Title's display name"
                        />
                        <button
                          class="btn btn--white narrow"
                          @click="onEditTitleEnd(visualPage)"
                        >Save</button>
                        <button class="btn btn--white red narrow" @click="editTitleMode = false">Cancel</button>
                      </div>
                    </div>

                    <div class="row wiki-edit-page-screen" v-if="editAvatarMode">
                      <div class="col-6">
                        <div>
                          <div class="add-new clickable" @click="trigger">
                            <label for="file-input">Change your avatar</label>
                            <input
                              hidden
                              :accept="acceptedAvatarFiles"
                              id="file-input"
                              type="file"
                              ref="file"
                              class="file-input"
                              @change="handleAvatarFileUpload()"
                            />
                          </div>
                          <img class="avatar" v-if="editAvatar" :src="editAvatar" />
                        </div>
                        <button
                          class="btn btn--white narrow"
                          @click="onEditAvatarEnd(visualPage)"
                        >Save</button>
                        <button class="btn btn--white red narrow" @click="editAvatarMode = false">Cancel</button>
                      </div>
                    </div>

                    <div class="buttons">
                      <button
                        v-if="isBasePage && isEditMode"
                        class="btn btn--white narrow"
                        @click="onEditText(visualPage)"
                      >Edit text</button>

                      <button
                        v-if="isBasePage && isEditMode"
                        class="btn btn--white narrow"
                        @click="onEditExampleText(visualPage)"
                      >Edit example</button>

                      <button
                        v-if="isBasePage && isEditMode"
                        class="btn btn--white narrow"
                        @click="onEditVideoText(visualPage)"
                      >Edit video</button>

                      <button
                        v-if="isEditMode"
                        class="btn btn--white narrow"
                        @click="onEditTitle(visualPage)"
                      >Edit title</button>

                      <button
                        v-if="isEditMode"
                        class="btn btn--white narrow"
                        @click="onEditAvatar(visualPage)"
                      >Edit Avatar</button>

                      <button
                        v-if="isEditMode"
                        class="btn btn--white narrow"
                        @click="move(visualPage, -1)"
                      >Move up</button>

                      <button
                        v-if="isEditMode"
                        class="btn btn--white narrow"
                        @click="move(visualPage, 1)"
                      >Move down</button>
                    </div>

                    <div class="row wiki-boxes">
                      <div
                        class="wiki-page-preview col-md-4"
                        v-for="(page, i) in visualPage.children"
                        :key="i"
                      >
                        <router-link :to="getPageUrl(page)" class="no-style-href">
                          <div class="rect rect-colored">
                            <div class="title-avatar">
                              <img class="avatar" v-if="page.avatarUrl" :src="page.avatarUrl" />
                              <h3>{{ page.displayName }}</h3>
                            </div>
                            <div class="desc" v-html="page.description"></div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="pageNotFound">
                  <h4>The page was not found</h4>
                  <router-link :to="'/user-manual'">
                    <div>Go to the home page of the User Manual</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CreateManualPage
        v-if="addSectionMode"
        @finish="addSectionExecute"
        @close="addSectionClose"
        :depth="depth"
      ></CreateManualPage>
    </div>
  </div> -->
</template>

<script lang="ts">
// import Sidenav from './sidenav/Sidenav.vue'
// import manualService from '../../../services/user-manual.service'
// import popupService from '../../../services/popup.service'
import {
  Component,
  Vue
  // Watch
} from 'vue-property-decorator'
import { set } from 'vue/types/umd'
// import ManualSearch from './search/ManualSearch.vue'
// import ManualEditor from './editor/ManualEditor.vue'
// import Loading from '../../../components/helpers/Loading.vue'

// import {
//   exampleDefault,
//   IUserManualPage,
//   videoDefault
// } from '../../../../../common/interfaces/user-manual-page.interface'
// import CreateManualPage from './editor/CreateManualPage.vue'
// import { ALLOWED_AVATAR_EXTENSIONS, ALLOWED_SIZE } from '../../../../../common/constants/file.constant'
// import fileHelper from '../../../helpers/file.helper'
// import authService from '../../../services/auth.service'

/**
 * Wikipedia component
 * Core of the wikipedia page
 * Contains all components related to the wiki page, including 2 subcomponents
 *  - Left navigation
 *  - Search field
 */
@Component({
  components: {
    // ManualEditor,
    // Sidenav,
    // ManualSearch,
    // CreateManualPage,
    // Loading
  }
})
export default class Wikipedia extends Vue {
  /** Array of all data about all pages in the wiki page */
  // private page: IUserManualPage = {
  //   title: '',
  //   displayName: '',
  //   children: []
  // };

  // private acceptedAvatarFiles = `.${ALLOWED_AVATAR_EXTENSIONS.join(',.')}`;

  // /** Currently visualized pages. By default, all main pages are shown at the beginning */
  // private visualPage: IUserManualPage = this.page;

  // /** Current url of the wiki (sub)page. Updated every time the URL is changed */
  // private currentUrl = '';

  // /**  Text currently stored in the Rich Text editor */
  // private editText = '';

  // private editVideoText = '';
  // private editVideoUrl = '';

  // private editExampleText = '';

  // private editTitle = '';

  // private editAvatar = '';

  // private loads = true;

  // /**
  //  * - Shows whether the user is allowed to modify the content
  //  * - Shows or hides the edit buttons
  //  */
  // private isEditable = false;

  // /* True if the text of the current page is being edited
  //  * If true, the rich text editor will be visible */
  // private editMode = false;

  // /**  True if the current page is not available. The "Not found" text will be shown instead of the pages */
  // private pageNotFound = false;

  // /**  True if the user is currently searching for something.
  //  * If true, the content will be hidden in favour of the search results */
  // private searchMode = false;

  // /** True if currently the user is creating a new section
  //  * Controls the dialog modal visibility */
  // private addSectionMode = false;

  // /** When creating a new section, these 2 variables will be filled with the correct values */
  // private addSectionIndex = 0;

  // private editExampleMode = false;

  // private editVideoMode = false;

  // private editTitleMode = false;

  // private editAvatarMode = false;

  // private depth = 0;

  // private addSectionContainer: IUserManualPage[];

  // private isLoggedIn = false;

  // get isBasePage () {
  //   return this.visualPage.children.length === 0
  // }

  beforeCreate() {
    const newWindow = window.open('https://support.blits.ai/portal/en/kb/knowledgebase', '_blank')
    if (newWindow) newWindow.opener = null

    setTimeout(() => {
      history.back()
    }, 1000)
  }

  // /**
  //  * Listens for changes in the url address
  //  * updates property accordingly
  //  * Changes the currently visible sections
  //  * @param newVal  the new URL address
  //  */
  // @Watch('$route', { immediate: true, deep: true })
  // onUrlChange (newVal: any) {
  //   this.currentUrl = newVal.path.replace('/user-manual', '')

  //   this.updateVisiblePage()
  // }

  // created () {
  //   this.init()
  // }

  // get isEditMode () {
  //   return (
  //     !this.editMode &&
  //     !this.editExampleMode &&
  //     !this.editVideoMode &&
  //     !this.editTitleMode &&
  //     !this.editAvatarMode &&
  //     this.isEditable &&
  //     this.isPageEditable()
  //   )
  // }

  // async move (section: IUserManualPage, value: 1 | -1) {
  //   this.editMode = false

  //   // Get parent section
  //   const path = this.currentUrl.split('/').filter(e => e)
  //   const parentPath = path.slice(0, path.length - 1)
  //   const parent = parentPath.reduce((page, title) => page.children.find(ch => ch.title.toLowerCase() === title.toLowerCase()), this.page)

  //   // Get current index and new index
  //   const index = parent.children.findIndex(ch => ch.title === section.title)
  //   let newIndex = index + value
  //   newIndex = Math.max(0, newIndex)
  //   newIndex = Math.min(parent.children.length - 1, newIndex)

  //   if (index === newIndex) {
  //     return
  //   }

  //   // Change order
  //   parent.children.splice(index, 1)
  //   parent.children.splice(newIndex, 0, section)

  //   parent.children.forEach((ch, i) => (ch as IUserManualPage).sortingIndex = i + 1)
  //   await this.savePage(parent == this.page)
  // }

  // /**
  //  * Init method
  //  *
  //  * Retrieves the wiki pages from the database
  //  * Also sets the current visible page depending on the current URL
  //  * Also checks if the wiki page is editable or not
  //  */
  // async init () {
  //   authService.isAuthenticated().then(isLoggedIn => this.isLoggedIn = isLoggedIn)

  //   this.page = {
  //     title: '',
  //     displayName: '',
  //     children: await manualService.getPages()
  //   }

  //   this.loads = false

  //   this.updateVisiblePage()

  //   this.isEditable = await manualService.isEditAllowed()

  //   this.$root.$on('wikiAddSection', this.addSectionShow)
  //   this.$root.$on('wikiDeleteSection', this.updateSection)
  // }

  // trigger () {
  //   (this.$refs.file as HTMLInputElement).click()
  // }

  // async handleAvatarFileUpload () {
  //   this.editAvatar = await fileHelper.uploadFile(
  //     manualService.uploadAvatar.bind(manualService),
  //     (this.$refs.file as Vue).files,
  //     ALLOWED_SIZE
  //   )
  // }

  // /**
  //  * Add section show method
  //  * Executed when the 'Add section button' is pressed
  //  * Shows a modal to the user asking for the section name and sets a default text for that section
  //  */
  // async addSectionShow (index, parent, depth) {
  //   this.addSectionMode = true

  //   this.addSectionIndex = index
  //   this.addSectionContainer = parent
  //   this.depth = depth
  // }

  // /**
  //  * addSectionExecute method
  //  * This method is called when the 'Create' button from the 'add section' popup is clicked
  //  * a new section is consturcted with the given name
  //  * The new sections is sent to the server when constructed
  //  *
  //  * @param title  the title given from the user for the new section
  //  */
  // async addSectionExecute (pageTitle, avatarUrl) {
  //   this.addSectionMode = false

  //   const parent = this.addSectionContainer
  //   const index = this.addSectionIndex

  //   const newPage: IUserManualPage = { title: '', displayName: '' }

  //   newPage.children = []
  //   newPage.title = newPage.displayName = pageTitle
  //   newPage.avatarUrl = avatarUrl
  //   newPage.sortingIndex = newPage.maxSortingIndex = parent.length
  //   newPage.description =
  //     'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, commodi consectetur consequuntur eos laudantium possimus reiciendis vitae. Est ipsam ut voluptas? At deserunt dolores enim laboriosam non recusandae repudiandae voluptates? '

  //   newPage.example = exampleDefault
  //   newPage.video = videoDefault

  //   if (newPage.title) {
  //     parent.push(newPage)
  //     this.updateSection(index)
  //   }
  // }

  // /**
  //  * Add section close method
  //  * used to close the popup for creating new sections
  //  */
  // addSectionClose () {
  //   this.addSectionMode = false
  // }

  // /**
  //  * Update visible page
  //  * Finds the correct sub section (n-size depth)
  //  * Depending on the current URL address
  //  */
  // async updateVisiblePage () {
  //   // Reset pageNotFound to false
  //   this.pageNotFound = false

  //   if (this.currentUrl) {
  //     try {
  //       let tempVPage: IUserManualPage = this.page

  //       this.currentUrl
  //         .split('/')
  //         .filter(e => e !== '')
  //         .forEach(urlSegment => {
  //           tempVPage = tempVPage.children.find(
  //             e => e.title.toLowerCase() === urlSegment
  //           )
  //         })

  //       // At this point, tempVPage will be the correct sub-section

  //       this.visualPage = await tempVPage

  //       this.searchMode = false
  //       this.editMode = false;

  //       // Clean the search every time the view is changed
  //       (this.$refs.searchComponent as any).clearSearch()
  //     } catch (e) {
  //       // If no page is found, URL is wrong. Show 404
  //       this.visualPage = this.page
  //       this.pageNotFound = true
  //     }
  //   } else {
  //     // We are at the default url here (meaning no sub-sections)
  //     // Show all sections in this case

  //     this.visualPage = this.page
  //     this.searchMode = false
  //     this.editMode = false;
  //     (this.$refs.searchComponent as any).clearSearch()
  //   }
  // }

  // /**
  //  * Get current URL
  //  * returns the url for a given element
  //  *
  //  * @param element  The element for which we need to get the URL
  //  */
  // getPageUrl (element: IUserManualPage) {
  //   return (
  //     element.url || this.$route.path + '/' + element.title.toLowerCase()
  //   )
  // }

  // /**
  //  * Called when the edit text button is clicked
  //  *
  //  * @param section  The section which we want to edit
  //  */
  // onEditText (section: IUserManualPage) {
  //   this.editMode = true
  //   this.editExampleMode = false
  //   this.editTitleMode = false
  //   this.editVideoMode = false
  //   this.editAvatarMode = false
  //   this.editText = section.description
  // }

  // onEditExampleText (section: IUserManualPage) {
  //   this.editExampleMode = true
  //   this.editMode = false
  //   this.editTitleMode = false
  //   this.editVideoMode = false
  //   this.editAvatarMode = false
  //   this.editExampleText = section.example.value
  // }

  // onEditVideoText (section: IUserManualPage) {
  //   this.editVideoMode = true
  //   this.editAvatarMode = false
  //   this.editMode = false
  //   this.editTitleMode = false
  //   this.editExampleMode = false
  //   this.editVideoText = section.video.title
  //   this.editVideoUrl = section.video.value
  // }

  // onEditTitle (section: IUserManualPage) {
  //   this.editAvatarMode = false
  //   this.editTitleMode = true
  //   this.editVideoMode = false
  //   this.editMode = false
  //   this.editExampleMode = false
  //   this.editTitle = section.displayName
  // }

  // onEditAvatar (section: IUserManualPage) {
  //   this.editAvatarMode = true
  //   this.editTitleMode = false
  //   this.editVideoMode = false
  //   this.editMode = false
  //   this.editExampleMode = false
  //   this.editTitle = section.displayName
  // }

  // /**
  //  * Called when the "Save" button of the text edit is called
  //  * Gets the edited text and replaces the section's text with the new one
  //  * Also, calls the savePage from manualService to store the changes
  //  * @param section  The section which we are editing
  //  */
  // async onEditTextEnd (section: IUserManualPage) {
  //   // Update section text and exit edit mode
  //   section.description = this.editText
  //   this.editMode = false

  //   await this.savePage()
  // }

  // async onEditTitleEnd (section: IUserManualPage) {
  //   // Update section text and exit edit mode
  //   section.displayName = this.editTitle
  //   this.editTitleMode = false

  //   await this.savePage()
  // }

  // async onEditExampleTextEnd (section: IUserManualPage) {
  //   // Update section text and exit edit mode
  //   section.example.value = this.editExampleText
  //   this.editExampleMode = false

  //   await this.savePage()
  // }

  // async onEditVideoTextEnd (section: IUserManualPage) {
  //   // Update section text and exit edit mode
  //   section.video.title = this.editVideoText
  //   section.video.value = this.editVideoUrl
  //   this.editVideoMode = false

  //   await this.savePage()
  // }

  // async onEditAvatarEnd (section: IUserManualPage) {
  //   // Update section text and exit edit mode
  //   section.avatarUrl = this.editAvatar
  //   this.editAvatarMode = false

  //   await this.savePage()
  //   this.editAvatar = ''
  // }

  // async savePage (isBasePageMoved = false) {
  //   // Retrieves all segments from the url address
  //   const parts = this.currentUrl.split('/').filter(e => e !== '')

  //   if (parts.length) {
  //     const rootComponentKey = parts[0]

  //     // Find the top-level section to which this sub-section belongs
  //     const segment = this.page.children.findIndex(
  //       e => e.title.toLocaleLowerCase() === rootComponentKey
  //     )

  //     // Save the changes for only that top-level secion
  //     if (isBasePageMoved) {
  //       await Promise.all([
  //         manualService.savePage(this.page.children[segment]),
  //         manualService.savePage(this.page.children[segment - 1]),
  //         manualService.savePage(this.page.children[segment + 1])
  //       ])
  //     } else {
  //       await manualService.savePage(this.page.children[segment])
  //     }
  //   }
  // }

  // updateSearchMode (searchMode) {
  //   this.searchMode = searchMode
  // }

  // /**
  //  * Called when a new subsection is called from any of the subsection children from the sidebar
  //  *
  //  * @param index  the index of the main section
  //  */
  // async updateSection (index) {
  //   try {
  //     const res = await manualService.savePage(await this.page.children[index])

  //     if (res.id && this.page.children[index]) {
  //       this.page.children[index].id = res.id
  //     }
  //   } catch (e) {
  //     if (e.response && e.response.status === 403) {
  //       throw e
  //     }
  //     popupService.showError(
  //       'Something went wrong with updating the server. Your changes were NOT saved. ',
  //       e.message
  //     )
  //   }
  // }

  // /**
  //  * isPageEditable method
  //  * Returns true if the current page can be edited
  //  * For now, it only returns false if the page is the home page
  //  *
  //  */
  // isPageEditable () {
  //   return !(this.$route.path == '/user-manual')
  // }
}
</script>

<style lang="scss">
@import '../../../assets/scss/components/_contents';
@import '../../../assets/scss/components/_sidebar';
@import '../../../assets/scss/components/_sidebar-type2';

body #app .container.wiki .container--content {
  margin: 0px;
}

.container {
  &.wiki {
    &.no-login {
      margin-left: 0px !important;
    }
  }
}

.content-intents.content-colored {
  width: 100%;
}

.wiki-pages-preview {
  table,
  td,
  tr,
  th {
    border: 1px solid #e1e1e1;
  }

  td,
  th {
    padding: 2px 10px;
    min-width: 10px;
  }

  .rect-colored {
    -webkit-box-shadow: 0px 6px 30px #33333314;
    -ms-box-shadow: 0px 6px 30px #33333314;
    box-shadow: 0px 6px 30px #33333314;
    font-size: 14px;
    color: $default-dark;
    font-weight: 400;

    h4,
    h3 {
      color: $default-dark;
      font-size: 16px;
      font-weight: 700;
    }
  }

  code,
  pre {
    background-color: #242144;
    border-radius: 1%;
    font-family: monospace;
    color: white;
    padding: 0px 0 5px 2px;
  }
}

.wiki-sidenav {
  min-height: 100vh;
  background-color: $white;
  border-right: 1px solid $light-blue;
}

.wiki-page-preview {
  .desc {
    word-break: break-word;
    font-size: 14px;
    color: $default-dark;
    line-height: 20px;
    height: 100px;
    overflow-y: hidden;
  }

  button {
    text-decoration: none !important;
  }

  .rect {
    transition: all 0.2s ease;
    margin-bottom: 30px;
    overflow: hidden;

    h3 {
      margin-bottom: 25px;
    }
    &.video {
      max-height: unset;
    }
    img {
      max-width: 100%;
    }
  }

  .rect.example {
    background-color: $default-dark;
    color: $white;
    a {
      color: $white;
    }
    h4 {
      color: $white;
    }
  }
}

.preview-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
}

.wiki-edit-page-screen {
  margin-bottom: 100px;

  .btn .btn--white .narrow {
    margin-right: 15px;
  }
}

.buttons {
  margin-bottom: 20px;
}

img.avatar {
  width: 25px;
  height: 25px;
}
</style>
