<template>
  <div class="row">
    <div class="form-group col-4">
      <label v-if="isFirstInput" for="delayPopupMessage">Var name</label>
      <input
        type="text"
        v-model="variable.name"
        @keydown.enter.prevent="$emit('submit')"
        :placeholder="isFirstInput ? 'Type and press Enter' : undefined"
      />
    </div>
    <div class="form-group col-4">
      <label v-if="isFirstInput" for="popupMessages">Storage type</label>
      <Select
        :key="variable.storageType"
        v-model="variable.storageType"
        :elements="Object.entries(storagesPreview)"
        keyAttr="1"
        valueAttr="0"
        :defaultText="'-'"
        :showToggle="false"
      ></Select>
    </div>
    <div class="form-group col-4">
      <label v-if="isFirstInput" for="delayPopupMessage">Var key</label>
      <input
        type="text"
        v-model="variable.key"
        @keydown.enter.prevent="$emit('submit')"
        :placeholder="isFirstInput ? 'Type and press Enter' : undefined"
      />
      <i v-if="!isFirstInput" class="icon-delete entity-delete" @click="$emit('delete')"></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Select from '../../../helpers/Select.vue'
import { CaptureStorageFunctionVariable, StorageType } from '../../../../../../common/interfaces/capture-storage-function-variable.interface.ts'

@Component({ components: { Select } })
export default class CaptureStorageVariableInput extends Vue {
  @Prop({ default: () => ({}) }) variable: CaptureStorageFunctionVariable;
  @Prop({ default: false }) isFirstInput: boolean;

  private storagesPreview: { [key in StorageType]: string } = {
    local: 'Local Storage',
    session: 'Session Storage',
    cookie: 'Cookies'
  }
}
</script>
