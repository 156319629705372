<template>
  <Modal id="create-llm" :title="`Create a new Large Language Model`" :show="true" @close="close">
    <newLLM :isCreate="true" @createLLM="create" />
  </Modal>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import Modal from '@/components/helpers/Modal.vue'
import validationsMixin from '@/components/admin/bots/_mixins/validation-management.mixin'
import preventBodyScrollMixin from '@/components/_mixins/prevent-body-scroll'
import newLLM from './newLLM.vue'
import { NewLLM } from '../../../../../common/types/llm-type'
import popupService from '../../../services/popup.service'

@Component({
  components: { newLLM, Modal }
})
export default class CreateLLM extends Mixins(validationsMixin, preventBodyScrollMixin) {
  created() {
    this.toggleScroll(true)
    this.createEscapeEvent()
  }

  createEscapeEvent() {
    document.addEventListener('keyup', evt => {
      if (evt.keyCode === 27) {
        this.close()
      }
    })
  }

  close() {
    this.toggleScroll(false)
    this.$emit('close')
  }

  create(newLLM: NewLLM) {
    if (this.validateNameSpacesLLMAllowed(newLLM.name)) {
      popupService.showDialog('Incorrect name', 'Please provide a valid LLM name (Special characters are not allowed)', 'ok', '')
      return
    }

    if (newLLM.model === '') {
      popupService.showDialog('Model not selected', 'Please select a model', 'ok', '')
      return
    }

    this.$emit('create', newLLM)
    this.toggleScroll(false)
    this.close()
  }
}
</script>
