<template>
  <div>
    <div v-if="!showChat">
      <Modal title="Public test chat" :show="true" @close="$emit('close')" customClass="modal-external-chat">
        <section class="form--wrapper general">
          <form @submit.prevent="initChat">
            <p>In order to test the bot, please provide the password provided</p>
            <label for="password">Password</label>
            <input v-model.trim="password" />

            <div class="section--footer static">
              <button class="main-fill-color-button" type="submit">Start chat</button>
            </div>
          </form>
        </section>
      </Modal>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Modal from '../../../helpers/Modal.vue'
import popupService from '../../../../services/popup.service'
import chatLoader from '../chat-loader'

@Component({
  components: { Modal }
})
export default class ExternalTestChat extends Vue {
  password = '';
  showChat = false;

  botName = '';
  providers = {};
  botAvatarImage = '';

  async initChat () {
    try {
      await chatLoader.init({
        id: 'test',
        password: this.password,
        passwordType: 'ext',
        isInternal: false,
        useStagingVersion: true,
        title: 'Test your bot',
        startOpened: true
      })

      this.showChat = true
    } catch (err) {
      popupService.showError('Invalid password')
    }
  }

  destroyed () {
    chatLoader.destroy()
  }
}
</script>
