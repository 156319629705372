<template>
  <div id="sync-container" class="sync">
    <SynchronizationProcess
      :key="index"
      v-for="(provider, index) in providers"
      v-show="openProcesses[index]"
      :botName="botName"
      :syncId="syncId"
      :provider="provider"
      :isPublished="isPublished"
      @start="setStarted(index)"
      @end="setEnded(index)"
      @close="close(index)"
    ></SynchronizationProcess>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import botNluService from '../../../../services/bots/bot-nlu.service'
import { ExternalProvider } from '../../../../../../common/enums/external-provider.enum'
import SynchronizationProcess from './SynchronizationProcess.vue'

@Component({
  components: { SynchronizationProcess }
})
export default class SynchronizationPage extends Vue {
  @Prop() readonly botName!: string;
  @Prop() isSyncActive!: boolean;
  @Prop() isPublished!: boolean;

  private providers: ExternalProvider[] = [];
  private openProcesses: boolean[] = [];
  private activeProcesses: boolean[] = [];

  mounted () {
    this.generateSyncId()
  }

  get syncId () {
    return this.$route.query.syncId
  }

  private generateSyncId () {
    const copy = JSON.parse(JSON.stringify(this.$route.query))
    copy.syncId = `${this.botName}|${Date.now()}`
    this.$router.replace({ query: copy })
  }

  private removeSyncId () {
    const copy = JSON.parse(JSON.stringify(this.$route.query))
    delete copy.syncId
    this.$router.replace({ query: copy })
  }

  /** @description returns the providers that support synchronization */
  async getProviders () {
    const allProviders = await botNluService.getConfiguredNlps(this.botName)
    return allProviders.filter(p => p != ExternalProvider.Nuance)
  }

  async created () {
    this.providers = await this.getProviders()
    this.openProcesses = this.providers.map(_ => true)
  }

  setStarted (index) {
    this.activeProcesses[index] = true
    this.$emit('update:isSyncActive', true)
  }

  setEnded (index) {
    this.activeProcesses[index] = false
    if (this.activeProcesses.every(p => p === false)) {
      this.$emit('update:isSyncActive', false)
      this.$emit('update:isPublished', true)
      this.removeSyncId()
    }
  }

  close (index) {
    this.$set(this.openProcesses, index, false)
    if (this.openProcesses.every(p => p === false)) {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.sync {
  margin-top: 15px;
}

</style>
