import { TtsMap } from "../types/tts-map.type"
import { ExternalProvider } from "../enums/external-provider.enum"

export const ttsNames: TtsMap<string> = {
  [ExternalProvider.Microsoft]: "Microsoft Text-to-Speech",
  [ExternalProvider.IBM]: "IBM Watson Text-to-Speech",
  [ExternalProvider.Google]: "Google Text-to-Speech",
  [ExternalProvider.Amazon]: "Amazon Polly",
  [ExternalProvider.OpenAI]: "Open AI Text-to-Speech",
}
