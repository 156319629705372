import Vue from 'vue'

export interface SharedState {
  botName: string;
  isInternal: boolean;
  providers: any;
  directLine: any;
  areDetailsOpened: boolean;
}

const defaultState: SharedState = {
  botName: '',
  providers: {},
  isInternal: false,
  directLine: undefined,
  areDetailsOpened: false
}

export const getState = (botName: string, isInternal: boolean, providers: any): SharedState =>
  Vue.observable(Object.assign(JSON.parse(JSON.stringify(defaultState)), { botName, isInternal, providers } as Partial<SharedState>))
