import { Component, Vue } from 'vue-property-decorator'

import { ExamplesService } from '../../../../services/bots/examples.service'
import popupService from '../../../../services/popup.service'

@Component
export default class ExampleManagementMixin extends Vue {
    name = ''
    normalIntents: any[] = [];
    redirectintents: any[] = [];
    entities = []
    examplesService = undefined;
    public hasEditFinished: boolean = undefined;
    public hasAddFinished: boolean = undefined;
    bot: any

    setExamples () {
      this.examplesService = new ExamplesService()
    }

    async addExample (data) {
      const intent = this.normalIntents.find(i => i.name === data.intentnameExamples) ||
            this.redirectintents.find(i => i.name === data.intentnameExamples) || this.normalIntents.find(i => i.name === data.intentName)

      await this.createExample({
        intent,
        exampleText: data.exampleText
      })
    }

    async createExample (data) {
      const { intent, exampleText } = data

      let example
      try {
        example = await this.examplesService.addExample(this.name, intent.name, exampleText)
        if (await this.examplesService.isPattern(example, this.entities)) { intent.patterns.unshift(example) } else { intent.utterances.unshift(example) }
        this.bot.isPublished = false
      } catch (error) {
        if (error.code === 'permission_error') {
          throw error
        } else {
          popupService.showError('Invalid data provided', error.message)
        }
      } finally {
        this.hasAddFinished = !this.hasAddFinished
      }
    }

    async updateExample (data) {
      const { intent, example } = data
      try {
        await this.examplesService.editExample(this.name, intent.name, example)
      } catch (error) {
        if (error.code === 'permission_error') {
          throw error
        } else {
          popupService.showError('Could not change example!', 'Try creating a new example instead.')
        }
      } finally {
        this.hasEditFinished = !this.hasEditFinished
      }
      this.bot.isPublished = false
    }

    async deleteExample (data) {
      const { intent, example } = data

      if (intent.utterances.length + intent.patterns.length === 1) {
        popupService.showError('You cannot delete the last example')
        return
      }

      // The example may contain entities => it could be pattern or utterance, so try to remove it from both places
      const patternIndex = intent.patterns.findIndex(p => p === example)

      if (patternIndex !== -1) {
        await this.examplesService.deletePattern(this.name, intent.name, example, this.entities)
        intent.patterns.splice(patternIndex, 1)
      } else {
        await this.examplesService.deleteUtterance(this.name, intent.name, example, this.entities)
        intent.utterances = intent.utterances.filter(u => u !== example)
      }
      this.bot.isPublished = false
    }
}
