import { Component, Vue } from 'vue-property-decorator'
import { HandoverService } from '@/services/bots/settings/handover.service'

@Component
export default class HandoverManagementMixin extends Vue {
    handoverService: HandoverService;
    handoverSettings: any = {};

    async initHandover (botName: string) {
      this.handoverService = new HandoverService(botName)
      this.handoverSettings = await this.handoverService.getHandover()
    }

    updateHandover () {
      return this.handoverService.updateHandover(this.handoverSettings)
    }
}
