
export class BlockAppearanceService {
  fixIndentaion (gfx: SVGGElement) {
    Array.from(gfx.children)
      .filter(e => e.nodeName.toLowerCase() === 'g')
      .map(n => {
        const defaultWidth = 100

        Array.from(n.children)
          .filter(c => c.nodeName === 'text')
          .map(t =>
            Array.from(t.children as unknown as IterableIterator<SVGTSpanElement>)
              .filter(e => !!e.getComputedTextLength())
              .forEach(e => e.setAttribute('x', ((defaultWidth - e.getComputedTextLength()) / 2).toString()))
          )
      })
  }
}
