import BlockStorage from './BlockStorage'
import { BlockAppearanceService } from './block-appearence.service'
import { ActivityTrackerService } from './activity-tracker.service'
import { VariableService } from './variable.service'
import CustomModeler from '../../../../custom-modeler/index'
import PaletteProvider from '../../../../custom-modeler/custom/CustomPalette'

/*eslint-disable*/
// @ts-ignore
import newDiagramXML from '!raw-loader!../../../../assets/misc/newDiagram.bpmn'
// @ts-ignore
import clearDiagramXML from '!raw-loader!../../../../assets/misc/clearDiagram.bpmn'
/* eslint-enable */
import FlowComponent from './FlowComponent'
import { IBlock } from '../../../../../../common/blocks/block.interface'
import popupService from '@/services/popup.service'
import { CopyPasteService } from './copy-paste.service'

/** This is the main/facade service for managing the BPMN stuff */
export class BpmnManagementService {
  readonly canvasId: string

  private _variablesShown = false

  private _flowLoaded = false

  private initialBlocks = {}

  private initialData: Partial<FlowComponent<any>>[] = []

  objects: any

  entities: any

  bpmn: any

  copyPasteService: CopyPasteService

  blockStorage?: BlockStorage

  blockService?: BlockAppearanceService

  variableService?: VariableService

  activityTrackerService = new ActivityTrackerService()

  onFlowLoaded?: () => any

  constructor() {
    this.canvasId = `bpmnCanvas${Math.floor(Math.random() * 100000)}`
  }

  get eventBus() {
    return this.bpmn.get('eventBus')
  }

  get pallete(): PaletteProvider {
    return this.bpmn.get('paletteProvider')
  }

  public snapshotInitialState() {
    this.initialBlocks = this.blockStorage!.allBlocks().getAll()
    this.initialData = JSON.parse(JSON.stringify(this.blockStorage!.allComponents()))
  }

  isFlowEdited() {
    const currentBlocks = this.blockStorage!.allBlocks().getAll()

    const currentData = this.blockStorage!.allComponents()
    const initialBlockCount = Object.keys(this.initialBlocks).length
    const currentBlockCount = Object.keys(currentBlocks).length

    if (initialBlockCount === 0 && currentBlockCount === 0) {
      return false
    }

    if (initialBlockCount != currentBlockCount) {
      return true
    }

    // check for added or removed elements
    for (const initial of this.initialData) {
      const current = currentData.find(c => c.id === initial.id)
      if (!current || JSON.stringify(initial._customData) !== JSON.stringify(current._customData)) {
        return true
      }
    }

    return false
  }

  initialize(
    container: any,
    flowVariables: any,
    flowObjects: any,
    onUserIdle: () => any = undefined,
    onLockChange?: (isLocked: boolean) => any,
    onSubOpen?: (subFlowName: string) => any,
    onFlowLoaded?: () => any
  ) {
    const { settings, functions, entities } = container
    this.bpmn = new CustomModeler({
      container: `#${this.canvasId}`,
      keyboard: {
        bindTo: window
      },
      moddleExtensions: {
        camunda: require('camunda-bpmn-moddle/resources/camunda'),
        qa: require('../resources/qa.json')
      }
    })

    this.registerCustomGroups(settings || {})

    this.copyPasteService = new CopyPasteService(this.bpmn)

    this.blockStorage = new BlockStorage(this.bpmn, functions)
    this.blockService = new BlockAppearanceService()
    this.variableService = new VariableService(this.blockStorage, flowVariables, flowObjects)

    this.objects = flowObjects
    this.entities = entities
    this.initialBlocks = {}

    if (onUserIdle) {
      this.activityTrackerService.startIdleTimer(onUserIdle)
    }

    if (onLockChange) {
      this.activityTrackerService.initHeartBeat(container.id, onLockChange)
    }

    this.handleEvents(onSubOpen)

    this.onFlowLoaded = onFlowLoaded
  }

  handleEvents(onSubOpen: (...args: any) => any) {
    // Prevent changing labels of connectors after conditional
    this.eventBus.on('element.dblclick', 10000, (event: any) => {
      const { element } = event
      if (BlockStorage.isConditionalConnector(element) || BlockStorage.isLabelOfConditionalConnector(element)) {
        return false
      }
    })
    // Track newly added shapes
    this.eventBus.on(['shape.added', 'connection.added'], (event: any) => {
      this.blockStorage!.addComponent(event.element, event.gfx)
      // if (event.element.type === 'bpmn:StartEvent') this.setStartEnabled(false)
    })
    // Untrack removed shapes
    this.eventBus.on(['shape.removed', 'connection.removed'], (event: any) => {
      this.blockStorage!.removeComponent(event.element)
      // if (event.element.type === 'bpmn:StartEvent') this.setStartEnabled(true)
    })
    // Track Subs (+)
    this.eventBus.on('open.sub', (element: any) => {
      const block = this.blockStorage!.findById(element.id)
      if (block.category == 'Subs') {
        onSubOpen(block.customData.fc)
      }
    })
  }

  setStartEnabled(enabled: boolean) {
    const icon = document.querySelector('[class="entry bpmn-icon-start-event-none"]') as HTMLElement
    const text = icon.nextSibling as HTMLElement

    icon.style.opacity = text.style.opacity = enabled ? '1' : '0.5'
    icon.style.cursor = text.style.cursor = enabled ? 'pointer' : 'default'
    icon.setAttribute('draggable', enabled.toString())
    icon.setAttribute('data-action', enabled ? 'create.start-event' : '')
  }

  /** Updates the BPMN block pallete on the left  */
  registerCustomGroups(settings: any) {
    if (settings.categories && settings.categories['Microsoft SCSM']) {
      this.pallete.addAction('custom-scsm', 'bpmn:Task', 'activity', 'icon icon-custom-scsm', 'System')
    }
    if (settings.categories && settings.categories.Foodl) {
      this.pallete.addAction('custom-foodl', 'bpmn:Task', 'activity', 'icon icon-custom-message', 'Foodl')
    }
    this.pallete.addAction('custom-llm', 'bpmn:Task', 'activity', 'icon icon-custom-llm', 'llm')

    this.pallete.refresh()
  }

  /** register a callback to be fired once a tracked element is clicked */
  onElementSelected(callback: (component: FlowComponent<IBlock> | null) => void) {
    const changeFunction = (event: any) => {
      if (!event.newSelection.length || event.newSelection[0].type == 'label') {
        callback(null)
      } else {
        const block = this.blockStorage!.findById(event.newSelection[0].businessObject.id)
        callback(block)
      }
    }

    this.eventBus.off('selection.changed', changeFunction)
    this.eventBus.on('selection.changed', changeFunction)
  }

  get showVariables() {
    return this._variablesShown
  }

  set showVariables(show: boolean) {
    this._variablesShown = show

    if (this._flowLoaded && show) this.variableService!.displayAll()
    if (this._flowLoaded && !show) this.variableService!.hideAll()
  }

  loadBpmnXml(xml: string, flowName = '', isClear = false) {
    xml = decodeURI(xml).replace('[intent]', flowName)
    return new Promise((resolve, reject) => {
      this.bpmn.importXML(xml, (err: any) => {
        if (err) return reject(new Error('Failed to load BPMN!'))

        this._flowLoaded = true
        if (this._variablesShown) {
          this.variableService!.displayAll()
        }
        if (this.onFlowLoaded && !isClear) this.onFlowLoaded()

        this.bpmn.get('canvas').zoom('fit-viewport', 'auto')
        this.snapshotInitialState()
        FlowComponent.resetElementNumbers()
        resolve(undefined)
      })
    })
  }

  updateOutputBoxes() {
    if (this._flowLoaded && this._variablesShown) this.variableService!.displayOutputBoxes()
  }

  getXml() {
    this.blockStorage!.serializeElements()

    return new Promise<string>((resolve, reject) => {
      this.bpmn.saveXML({ format: false }, (err: any, xml: string) => {
        if (err) reject(err)
        else resolve(xml)
      })
    })
  }

  async resetFlow(name: string, prompt = true) {
    if (!prompt || (await popupService.showDialog('Warning!', 'Are you sure that you want to reset this flow?', 'Reset'))) {
      await this.loadBpmnXml(newDiagramXML, name)
    }
  }

  async clearFlow(prompt = true) {
    if (!prompt || (await popupService.showDialog('Warning!', 'Are you sure that you want to clear this flow?', 'Clear'))) {
      await this.loadBpmnXml(clearDiagramXML, '', true)
    }
  }

  isObject(condition: string): boolean {
    return this.objects[condition]
  }

  isEntity(condition: string): boolean {
    return this.entities[condition]
  }

  getObjectKeys(condition: string) {
    return Object.keys(this.objects[condition].variables)
  }

  getEntityExampleOrCanonical(condition: string) {
    for (const [name, items] of Object.entries(this.entities) as any) {
      if (name === condition) {
        if (items.type === 'closedlists') {
          return items.sublists.map(c => c.canonicalForm)
        } else if (items.type === 'entities') {
          return items.examples
        }
      }
    }
  }

  getCanonicalUtterances(condition: string) {
    for (const [name, items] of Object.entries(this.entities) as any) {
      if (name === condition) {
        if (items.type === 'closedlists') {
          return items.sublists.map(c => c.list)
        }
      }
    }
  }

  isClosedListEntity(condition: string) {
    for (const [name, items] of Object.entries(this.entities) as any) {
      if (name === condition) {
        if (items.type === 'closedlists') {
          return true
        } else return false
      }
    }
  }
}
