import { Component, Vue } from 'vue-property-decorator'
import { MailChannelProperties } from '../../../../../../../../common/interfaces/mail-channel-properties'
import { MailChannelService } from '@/services/bots/settings/configuration/mail-channel.service'
import popupService from '@/services/popup.service'
import { MailChannelCredentials } from '../../../../../../../../common/interfaces/mail-channel-credentials'

@Component
export default class MailChannelManagementMixin extends Vue {
  mailChannelService: MailChannelService;
  keys: MailChannelProperties;
  mailChannelLoadingProps: any = {};

  initMailChannel (botName: string) {
    this.mailChannelService = new MailChannelService(botName)
    this.getEmailKeys()
    this.mailChannelLoadingProps = { isConnectingProccessRunning: false, isDisconnectionProccessRunning: false }
  }

  async getEmailKeys () {
    this.keys = await this.mailChannelService.getKeys()
  }

  async connectMailChannel (credentials: MailChannelCredentials) {
    try {
      await this.mailChannelService.connect(credentials)
      popupService.showInfo('Connected!')
      this.$set(this.keys, 'isEnabled', true)
      this.$set(this.mailChannelLoadingProps, 'isConnectingProccessRunning', false)
    } catch (err) {
      this.$set(this.mailChannelLoadingProps, 'isConnectingProccessRunning', false)
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Connection failed!')
    }
  }

  async disconnectMailChannel () {
    try {
      await this.mailChannelService.diconnect()
      popupService.showInfo('Disconnected!')
      this.$set(this.keys, 'isEnabled', false)
      this.$set(this.mailChannelLoadingProps, 'isDisconnectionProccessRunning', false)
    } catch (err) {
      this.$set(this.mailChannelLoadingProps, 'isDisconnectionProccessRunning', false)
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Disconnection failed!')
    }
  }
}
