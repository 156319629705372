<template>
  <div class="form-group">

    <label for="function">
      Allowed file extensions
      <Tooltip title="These are the possible file extensions which the user can upload." placement="bottom"></Tooltip>
    </label>

    <div
      v-for="(v, index) in outputFormats"
      :key="index"
    >

      <label>
        <input type="checkbox" v-model="currentBlock.customData['field-possible-extensions']" :value="v" :disabled="!hasSufficientPermission">
        {{ v }}
      </label>
    </div>

  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import { ALLOWED_EXTENSIONS_UPLOAD } from '../../../../../../../../../common/constants/file.constant'
import { IReceiveUserAttachmentBlock } from '../../../../../../../../../common/blocks/dialog/receive-user-attachment-block.interface'
import ReceiveUserAttachmentBlockComponent
  from '../../../../modules/blocks/dialog/blocks/ReceiveUserAttachmentBlockComponent'

@Component({ components: { Tooltip } })
export default class RandomAnswerExtension extends Vue {
    private outputFormats: Array<string> = [];

    @Prop({ default: () => { return {} } }) readonly currentBlock!: ReceiveUserAttachmentBlockComponent<IReceiveUserAttachmentBlock>;
     @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

     created () {
       this.outputFormats = ALLOWED_EXTENSIONS_UPLOAD

       if (!this.currentBlock.customData['field-possible-extensions']) {
         this.currentBlock.customData['field-possible-extensions'] = [...this.outputFormats]
       }
     }
}
</script>

<style lang="scss" scoped>
.form-group {
  label {
    display: inherit
  }

  div {
    display: inline;
    margin-right: 5px;

    input[type="checkbox"] {
      display: inline;
      min-height: 0px;
    }
  }
}
</style>
