<template>
  <div class="options--zoom cursor-pointer">
    <button type="button" class="btn zoom zoom-out" @click="doZoom(-step)">
      <div></div>
    </button>
    <button type="button" class="btn zoom zoom-in" @click="doZoom(+step)">
      <div></div>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ZoomButtons extends Vue {
  private currentZoom = 1.0;

  @Prop({ default: 0.2 }) step: number;
  @Prop({ default: () => {} }) bpmn: any;

  doZoom (amount: number) {
    const canvas = this.bpmn.get('canvas')
    const viewbox = canvas.viewbox()

    const newWidth = viewbox.width * (1 - amount)
    const newHeight = viewbox.height * (1 - amount)
    const newX = viewbox.x + (viewbox.width - newWidth) / 2
    const newY = viewbox.y + (viewbox.height - newHeight) / 2

    canvas.viewbox({ x: newX, y: newY, width: newWidth, height: newHeight })
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables";
@import "../styles/zoom-top";

.options--zoom {
  position: absolute;
  bottom: 55px;
  left: 140px;
  overflow: hidden;
}
</style>
