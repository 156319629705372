<template>
  <div class="content-intents">
    <div class="row">
      <div class="col-12">
        <div class="content-intents__main-inner">
          <div class="form--wrapper settings">
            <div class="settings-title">
              <div class="title">Bot Variables & Objects</div>
            </div>
            <!-- <p v-if="!hasVariables">See all the global variables that are shared with all dialogs.</p> -->
            <div class="analytics">
              <div class="header">
                <h4>Variables <span class="right">Global<Tooltip title="Global Variables are available for all bots in your account." placement="right"></Tooltip></span></h4>
              </div>
              <div class="form--wrapper">
                <form action="#">
                  <div class="variables-analytics__row">
                    <div class="row-number"></div>

                    <div class="row spacing">
                      <div class="col-4 padding-right">
                        <input
                          id="new-variable-name"
                          class="form-control blue-card first-input"
                          v-model.trim="newKey"
                          placeholder="Type a new variable name"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          id="new-variable-value"
                          class="form-control blue-card first-input"
                          placeholder="Type the new variable value"
                          v-model="newValue"
                          @keyup.enter="saveVariable()"
                        />
                        <div type="button" class="copybutton dark" @click="saveVariable()">Add</div>
                      </div>
                    </div>

                    <div
                      class="row spacing"
                      v-for="(value, key, index) in componentVariables"
                      :key="index"
                    >
                      <div class="col-4 padding-right">
                        <input
                          :text="key"
                          id="new-variable-name"
                          class="form-control blue-card"
                          disabled
                          :value="key"
                          placeholder="Type variable name"
                          @keyup.enter="editVariable(key)"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          :text="componentVariables[key].value"
                          id="new-variable-value"
                          class="form-control blue-card"
                          placeholder="Type a variable value"
                          v-model="componentVariables[key].value"
                          @keyup.enter="editVariable(key, $event)"
                          @click="editingVar[key] = true"
                        />
                        <div type="button" class="copybutton dark" v-if="hasSufficientPermissions && editingVar[key]" @click="editVariable(key, $event)">Update</div>
                        <i class="icon-delete entity-delete add" v-else-if="hasSufficientPermissions" @click="deleteVariable(key)"></i>
                      </div>

                      <div class="col-2 t-10">
                        <Toggle v-if="!componentVariables[key].isGlobal && hasPermissionForGlobalToggle" class="toogle toogle-div" @input="changePropBotId(componentVariables[key], key)" v-model="componentVariables[key].isGlobal"></Toggle>
                        <span v-if="componentVariables[key].isGlobal" class="type-card">Global</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="analytics" v-show="jsons">
              <div class="header">
                <h4>Objects <span class="right">Global<Tooltip title="Global Objects are available for all bots in your account." placement="right"></Tooltip></span></h4>
              </div>
              <div class="form--wrapper">
                <div class="row spacing">
                  <div class="col-10">
                    <input
                      :text="newObjectName"
                      id="new-object-name"
                      class="form-control blue-card first-input"
                      v-model="newObjectName"
                      placeholder="Type a new object name"
                      @keyup.enter="addObject()"
                    />
                    <div type="button" class="copybutton dark" @click="addObject()">Add</div>
                  </div>

                </div>
                <div class="row spacing" v-for="(value, key, index) in jsons"
                      :key="index">
                  <div class="col-10">
                    <input
                      :text="key"
                      id="new-object-name"
                      class="form-control blue-card"
                      :value="key"
                      @click="$emit('edit', key)"
                    />
                    <i class="icon-delete entity-delete add" v-if="hasSufficientPermissions" @click="deleteObject(key)"></i>
                  </div>
                   <div class="col-2 t-10">
                      <Toggle v-if="!jsons[key].isGlobal && hasPermissionForGlobalToggle" class="toogle toogle-div" @input="changePropBotId(jsons[key], key)" v-model="jsons[key].isGlobal"></Toggle>
                      <span v-if="jsons[key].isGlobal" e class="type-card">Global</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Toggle from '../../../helpers/Toggle.vue'
import BotSectionFooter from '../footer/BotSectionFooter.vue'
import popupService from '../../../../services/popup.service'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../services/tenants/permissions.service'
import { ValidationError } from '../../../../../../common/errors'
import Tooltip from '../../../helpers/Tooltip.vue'

@Component({
  components: { BotSectionFooter, Toggle, Tooltip }
})
export default class Variables extends Vue {
  @Prop({ type: Object, default: () => { return {} } }) variables;
  @Prop({ type: Object, default: () => { return {} } }) objects;
  @Prop({}) botName: string;
  @Prop({
    type: Object,
    required: true,
    default: () => { return {} }
  })
  botValidator;

  private newKey = '';
  private newValue = '';
  private newObjectKey = '';
  private newObjectValue = '';
  private newObjectName = '';
  private errors = [];
  private jsons = [];
  private componentVariables = [];
  private hasSufficientPermissions = false;
  private hasPermissionForGlobalToggle = false;
  private test = 'test';
  private isGlobal = false;
  private isGlobalObject = false;

  private editingVar: { [id: string]: boolean } = {}

  // private changeBotIdModal = false;
  // private propObjRef = null
  // private bots = [];
  // private propBotId = ''
  // private modalObjectKey = ''

  @Watch('objects')
  objectsAdd (newValue) {
    this.jsons = newValue
  }

  @Watch('variables')
  variablesAdd (newValue) {
    this.componentVariables = newValue
  }

  async beforeMount () {
    this.jsons = { ...this.objects }
    this.componentVariables = { ...this.variables }
    await this.loadNeededPermissions()
  }

  async loadNeededPermissions () {
    this.hasSufficientPermissions = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageVariablesObjects
    )
    this.hasPermissionForGlobalToggle = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageGlobalBotData
    )
  }

  get hasVariables () {
    return this.variables && Object.values(this.variables).some(v => !!v)
  }

  stringify (object) {
    const result = { ...object }
    Object.keys(result).forEach(key => {
      result[key] =
        typeof result[key] !== 'string'
          ? JSON.stringify(result[key])
          : result[key]
    })
    return result
  }

  unstringify (object) {
    const self = this
    const result = { ...object }
    Object.keys(result).forEach(key => {
      result[key] = self.isJson(result[key])
        ? JSON.parse(result[key])
        : result[key]
    })
    return result
  }

  deleteVariable (key) {
    popupService.deletePopupEvent('variable ' + key, () => {
      if (!this.hasSufficientPermissions) {
        popupService.showWarning('You do not have permission to do that!')
        return
      }
      this.$delete(this.variables, key)
      this.$emit('deleteVariable', this.variables)
    })
  }

  editVariable (key, event) {
    if (!this.hasSufficientPermissions) {
      popupService.showWarning('You do not have permission to do that!')
      return
    }
    if (event.target) {
      event.target.blur()
    }
    this.editingVar[key] = false
    this.$emit('editVariable', this.componentVariables[key])
  }

  addObject () {
    if (!this.hasSufficientPermissions) {
      popupService.showWarning('You do not have permission to do that!')
      return
    }

    if (this.newObjectName.trim() == '') return
    this.validateName(this.newObjectName)
    this.$emit('add', this.newObjectName, this.isGlobalObject)
  }

  deleteObject (key) {
    popupService.deletePopupEvent('object ' + key, () => {
      if (!this.hasSufficientPermissions) {
        popupService.showWarning('You do not have permission to do that!')
        return
      }
      this.$forceUpdate()
      this.$emit('delete', key)
    })
  }

  isJson (item) {
    item = typeof item !== 'string' ? JSON.stringify(item) : item

    try {
      item = JSON.parse(item)
    } catch (e) {
      return false
    }

    if (typeof item === 'object' && item !== null) {
      return true
    }

    return false
  }

  saveVariable () {
    if (!this.hasSufficientPermissions) {
      popupService.showWarning('You do not have permission to do that!')
      return
    }

    if (this.newKey.trim() === '' || this.newValue.trim() === '') {
      return
    }
    this.validateName(this.newKey)

    this.componentVariables = Object.assign({ [this.newKey]: { key: this.newKey, value: this.newValue, botId: this.botName, isGlobal: this.isGlobal } }, this.variables)
    // this.$set(this.variables, this.newKey, { key: this.newKey, value: this.newValue, botId: this.botName, isGlobal: this.isGlobal })
    this.newKey = ''
    this.newValue = ''

    this.isGlobal = false
    this.$emit('saveVariables', this.componentVariables)
  }

  private async changePropBotId (object, key) {
    if (object.variables) {
      this.$emit('create', object, key)
    } else {
      this.editVariable(key, {})
    }
  }

  // private changeOwner () {
  //   this.propObjRef.botId = this.propBotId
  //   this.changeBotIdModal = false
  //   if (this.propObjRef.variables) {
  //     this.$emit('create', this.propObjRef, this.modalObjectKey)
  //   } else {
  //     this.editVariable(this.modalObjectKey, {})
  //   }
  // }

  // private closeDialog () {
  //   if (this.propObjRef.variables) {
  //     this.objects[this.propObjRef.key].isGlobal = true
  //   } else {
  //     this.componentVariables[this.propObjRef.key].isGlobal = true
  //   }
  // }

  validateName (name: string) {
    if (Object.keys(this.componentVariables).concat(Object.keys(this.jsons)).some(n => n === name)) {
      throw new ValidationError(`Object/Variable with name '${name}' already exists`)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-intents__main-inner {
  min-height: 800px;
  padding-bottom: 40px;
}

.t-10 {
  position: relative;
  top: 10px;
}

.right {
  float: right;
  margin-right: 120px;
}

</style>
