import { Component, Vue } from 'vue-property-decorator'
import { WatsonAssistantService } from '@/services/bots/settings/configuration/watson-assistant.service'
import { IbmRegion } from '../../../../../../../../common/enums/ibm/ibm-region.enum'
import popupService from '@/services/popup.service'

@Component
export default class WatsonAssistantManagementMixin extends Vue {
  watsonAssistantService: WatsonAssistantService;
  keys: any;
  regions = Object.entries(IbmRegion);

  initWatsonAssistant (botName: string) {
    this.watsonAssistantService = new WatsonAssistantService(botName)
    this.getWatsonAssistantKeys()
  }

  async getWatsonAssistantKeys () {
    this.keys = await this.watsonAssistantService.getKeys()
  }

  taskInProgress = false;
  async deleteWatson () {
    if (this.taskInProgress) { return } else { this.taskInProgress = true }

    try {
      await this.watsonAssistantService.deleteWatson()
      this.keys.weHostWatson = false
      popupService.showInfo('Success!')
    } catch (err) {
      popupService.showError('Error!', err.message)
    } finally {
      this.taskInProgress = false
    }
  }

  async configureWatson () {
    if (this.taskInProgress) { return } else { this.taskInProgress = true }

    try {
      await this.watsonAssistantService.configureWatson()
      this.keys.weHostWatson = true
      popupService.showInfo('Success!')
    } catch (err) {
      popupService.showError('Error!', err.message)
    } finally {
      this.taskInProgress = false
    }
  }

  async updateWatsonAssistantKeys () {
    try {
      await this.watsonAssistantService.updateKeys(this.keys)
      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }
}
