/**
 * These models have been replaced on January 2024. https://openai.com/index/gpt-4-api-general-availability/#deprecation-of-older-models-in-the-completions-api
 * Older model          New model
 *  text-davinci-003     gpt-3.5-turbo-instruct
 *  text-davinci-002     gpt-3.5-turbo-instruct
 *
 */

export enum OpenAiEngine3_5 {
    "Text-Davinci-3" = 'text-davinci-003',
    "Text-Davinci-2" = 'text-davinci-002',
     "Turbo" = 'gpt-3.5-turbo' // See https://platform.openai.com/docs/models/gpt-3-5-turbo
};
