<template>
  <Modal
    id="create-intent"
    title="Create Intent"
    :show="true"
    @close="$emit('close', { isRedirect, fromDashboard })"
    customClass="import-intent-modal"
  >
    <div class="modal-description">
      Use this form to create a new intent for your bot. Start with some example
      utterances to train the machine learning model. Select a flow template to
      start the intent with example reponse.
    </div>
    <div class="content">
      <div class="form--wrapper">
        <div class="row">
          <div class="col-7" :class="{ 'col-12': faqIntent }">
            <div class="faq-intent">
              <Toggle label="FAQ Intent" v-model="faqIntent"></Toggle>
              <Tooltip
                title="FAQ Intents don't trigger a dialog flow. Once a user reaches this intent, the FAQ answer set below is shown. FAQ Intents can be upgraded to normal Intents."
                placement="right"
              ></Tooltip>
            </div>
            <div class="form-group">
              <div class="form-group--inside">
                <label>Intent Name</label>
                <input
                  type="text"
                  v-model="intentName"
                  placeholder="For instance: Finance intent"
                />
                <div v-if="nameError" class="invalid">
                  Please provide a valid intent name. Intent name can contain
                  only the following characters: "A-Z a-z 0-9 _"
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="form-group--inside">
                <label>Description</label>
                <input
                  v-model="description"
                  placeholder="Enter a description for your intent"
                />
                <div v-if="descError" class="invalid">
                  Please provide a valid intent description
                </div>
              </div>
            </div>
            <div class="margin-bottom-20"></div>

            <div class="form-group">
              <div class="form-group--inside">
                <label>Utterances</label>
                <Tooltip
                  title="An utterance is a short sentence that expresses the intent of the user. Add at least 3 to create a base for your intent."
                  placement="right"
                ></Tooltip>
                <div class="border"></div>

                <Replacer
                  v-model="exampleInput"
                  :items="getEntities()"
                  :placeholder="
                    'Add multiple questions that capture the intent'
                  "
                  @enter="addExample()"
                />
                <div
                  type="button"
                  class="copybutton dark"
                  @click="addExample()"
                >
                  Add
                </div>
                <div v-if="uttError" class="invalid">{{ uttError }}</div>

                <div class="new-utterrances">
                  <div
                    v-for="(example, index) in examples"
                    :key="index"
                    :id="'examples-' + index"
                    class="new-utterrance"
                  >
                    <div class="">
                      {{ example }}
                      <i class="remove" @click="deleteExample(index)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" v-if="faqIntent">
              <div class="form-group--inside">
                <label>FAQ Answer</label>
                <div class="border"></div>

                <Replacer
                  v-model="exampleAnswerTemp"
                  v-if="!exampleAnswer"
                  @enter="addAnswer()"
                  :items="getEntities()"
                  placeholder="Add an answer that your bot will send when the intent is called"
                />
                <div
                  type="button"
                  v-if="!exampleAnswer"
                  class="copybutton dark"
                  @click="addAnswer()"
                >
                  Add
                </div>

                <div class="new-utterrance" v-if="exampleAnswer">
                  {{ exampleAnswer }}
                  <i class="remove" @click="deleteAnswer()"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5 border-left" v-if="!faqIntent">
            <div class="templates">
              <aside
                id="current-template-description"
                @click.stop="select(undefined)"
              >
                <label>Template flows</label>
                <div class="da-input">
                  <section id="template-list">
                    <div v-if="!templates && !templates[0]">
                      No template flows available
                    </div>
                    <div
                      v-for="template in templates"
                      :key="template.id"
                      class="box"
                      @click.stop="select(template)"
                      :class="{ selected: selectedTemplate == template.id }"
                    >
                      <h2>{{ template.name }}</h2>
                      <p>{{ template.description }}</p>
                    </div>
                  </section>
                </div>
              </aside>
            </div>
          </div>
        </div>

        <div class="bottom-right-fix">
          <button
            class="btn btn--purple gradient"
            :disabled="
              intentName === '' ||
                this.examples.length === 0 ||
                (faqIntent && !exampleAnswer)
            "
            @click="create()"
          >
            Create Intent
            <i class="icon icon-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";

import examplesService from "../../../../services/bots/examples.service";
import Replacer from "../../../helpers/Replacer.vue";
import Modal from "../../../helpers/Modal.vue";
import validationMixin from "../_mixins/validation-management.mixin";
import Toggle from "../../../helpers/Toggle.vue";
import popupService from "../../../../services/popup.service";
import Tooltip from "../../../helpers/Tooltip.vue";

@Component({
  components: { Replacer, Toggle, Tooltip, Modal }
})
export default class CreateIntent extends Mixins(validationMixin) {
  @Prop({ type: Boolean, default: false }) fromDashboard;
  @Prop({ type: Array, default: () => [] }) entities;
  @Prop({ type: Boolean, default: false }) isRedirect;
  @Prop({ type: Array, default: () => [] }) templates;
  @Prop({ type: Array, default: () => [] }) untrainedExamples;

  private intentName = "";
  private description = "";
  private selectedTemplate = null;
  private nameError = false;
  private descError = false;
  private uttError: boolean | string = false;
  private examples = [];
  private exampleInput = "";
  private faqIntent = false;
  private exampleAnswerTemp = "";
  private exampleAnswer = "";

  created() {
    this.examples =
      this.untrainedExamples.length > 0
        ? this.untrainedExamples.map(e => e.message)
        : [];
  }

  async create() {
    if (this.validateName(this.intentName)) {
      this.nameError = true;
      return;
    } else {
      this.nameError = false;
    }

    const nonEmptyExamples = this.examples.filter(e => !!e && e.trim());

    if (nonEmptyExamples.length === 0 && !this.isRedirect) {
      this.uttError = "Please provide at least one utterance";
      return;
    }

    if (this.faqIntent && this.exampleAnswer.trim().length == 0) {
      popupService.showError(
        "Error with creating intent",
        "Please provide a FAQ answer"
      );
      return;
    }

    if (this.entities.some(e => e.name === this.intentName)) {
      popupService.showError("Entity with that name alredy exist");
    } else {
      const utterances = [];
      const patterns = [];
      const examplesMap = {};
      for (const example of nonEmptyExamples) {
        const check = { text: example.trim() };

        const lowerCase = check.text.toLowerCase();
        if (examplesMap[lowerCase]) {
          this.uttError = `Examples "${examplesMap[lowerCase]}" and "${check.text}" are identical`;
          return;
        }
        examplesMap[lowerCase] = check.text;

        if (await examplesService.isPattern(check, this.entities)) {
          patterns.push(check);
        } else {
          utterances.push(check);
        }
      }
      this.uttError = false;

      this.$emit("create", {
        name: this.intentName.trim(),
        description: this.description,
        isRedirect: this.isRedirect,
        template: this.faqIntent ? undefined : this.selectedTemplate,
        patterns: patterns,
        utterances: utterances,
        type: this.faqIntent ? "faq" : "normal",
        answer: this.exampleAnswer
      });
    }
  }

  addExample() {
    if (this.exampleInput.trim() == "") return;
    this.examples.unshift(this.exampleInput);
    this.exampleInput = "";
  }

  getEntities() {
    const result = [];

    for (const entity of this.entities) {
      result.push(entity.name);
      if (entity.roles) {
        result.push(...entity.roles.map(e => entity.name + ":" + e.name));
      }
    }
    return result;
  }

  deleteExample(index) {
    popupService.deletePopupEvent("utterance " + this.examples[index], () => {
      this.examples.splice(index, 1);
    });
  }

  select(template) {
    this.selectedTemplate =
      template && this.selectedTemplate !== template.id
        ? template.id
        : undefined;
  }

  addAnswer() {
    this.exampleAnswer = this.exampleAnswerTemp;
    this.exampleAnswerTemp = "";
  }

  deleteAnswer() {
    popupService.deletePopupEvent("answer " + this.exampleAnswer, () => {
      this.exampleAnswer = "";
    });
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/setup/_mixins.scss";
@import "../../../../assets/scss/components/_dropdowns.scss";
@import "../../../../assets/scss/variables";

.faq-intent {
  display: flex;
}

.tooltip-icon {
  margin-left: 5px;
}

.border {
  height: 15px;
  border-bottom: 1px solid $light-blue;
  margin-bottom: 20px;
}

.new-utterrances {
  margin-top: 10px;
}

.new-utterrance {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  background-color: $light-blue;
  font-size: 15px;
  font-weight: 500;
  color: $default-dark;
  line-height: 19px;
  margin: 0 7px 10px 0;
  padding: 11px 20px;
  i {
    pointer-events: all;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 10px;
  }
  .remove {
    background-image: url("/public/assets/icons/icon-remove-blue.svg");
    width: 7px;
    height: 7px;
    margin-top: 5px;
  }
}

#create-intent {
  .templates {
    display: inline;
    max-width: 360px;
    button {
      margin-top: 15px;
    }
    .da-input {
      height: 500px;
      overflow: scroll;
      margin-top: 0px;
      padding: 0px 15px 0px 15px;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $light-blue;
      overflow-x: hidden;
      border-radius: 10px;
    }
    aside {
      display: inline-flexbox;
      position: relative;
      padding: 0px 0px 30px 15px;
      overflow: auto;
      max-width: 360px;
      width: 100%;
      .box {
        z-index: -2;
        height: auto;
        overflow: auto;
        width: 100%;
        padding: 10px 20px;
        background-color: $white;
        border: 1px solid $light-blue;
        cursor: pointer;
        margin-top: 20px;
        + .box {
          margin-top: 15px;
        }
        h2 {
          font-size: 16px;
          font-weight: bold;
          padding: 0;
          margin-bottom: 8px;
          color: $default-dark;
        }
        p {
          margin: 0;
          color: $grey;
          font-size: 12px;
          line-height: 18px;
        }
        &.selected {
          border: 2px solid $main;
          h2 {
            color: $main;
          }
        }
      }
    }
  }
}
</style>
