import { Component, Vue } from 'vue-property-decorator'
import popupService from '@/services/popup.service'
import { SlackService } from '@/services/bots/settings/configuration/slack.service'
import { SlackChannelProperties } from '../../../../../../../../common/interfaces/slack-channel-properties'

@Component
export default class SlackManagementMixin extends Vue {
  slackService: SlackService;
  keys: SlackChannelProperties;

  initSlack (botName: string) {
    this.slackService = new SlackService(botName)
    this.getSlackKeys()
  }

  async getSlackKeys () {
    this.keys = await this.slackService.getKeys()
  }

  async updateSlackKeys () {
    try {
      this.keys = await this.slackService.updateKeys(this.keys)
      if (this.keys.redirectAction) {
        window.open(this.keys.redirectAction)
      }

      popupService.showInfo('Saved!')
    } catch (err) {
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', 'Could not update keys')
    }
  }
}
