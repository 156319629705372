<template>
  <div>
    <div class="form-group image-dropdown">
      <label for="function">
        Question / Suggestion
        <Tooltip
          title="This field gives the possibility to add Question / Suggestion to the option-card."
          placement="bottom"
        ></Tooltip>
      </label>

      <editor
        :items="variableNames"
        v-model="currentBlock.customData['field-suggestion']"
        :placeholder="'Question / Suggestion'"
        @change="changeQuestion($event)"
        :disabled="!hasSufficientPermission"
      />
    </div>

    <div class="form-group options">
      <label for="function">
        Options
        <Tooltip
          title="These are the proposed options which are shown to the user as a card."
          placement="bottom"
        ></Tooltip>
      </label>

      <div v-for="(v, index) in choices" :key="index" style="position: relative;">
        <autocomplete
          style="margin-bottom: 10px"
          class="show-options"
          :items="variableNames"
          v-model="currentBlock.customData['field-choices'][index]"
          :placeholder="'Enter your option'"
          @change="changeOption($event, index)"
          :rows="1"
          data-v-87b29de2
          :disabled="!hasSufficientPermission"
        />

        <i class="icon-delete" @click="removeOption(index)" v-show="hasSufficientPermission"></i>
      </div>

      <div class="option-button">
        <div class="btn btn--white dialogmanager" @click="addOption()" v-if="hasSufficientPermission">
          Add new option
          <i class="icon icon-add"></i>
        </div>
      </div>
    </div>

    <div class="form-group image-dropdown image">
      <label for="function">
        Image url
        <Tooltip
          title="This field gives the possibility to add an image to the option-card by embedding link."
          placement="bottom"
        ></Tooltip>
      </label>

      <input
        v-model="currentBlock.customData['field-image']"
        type="text"
        :placeholder="'Optional'"
        class="form-control"
        :disabled="!hasSufficientPermission"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import Autocomplete from '../../../../../../helpers/Autocomplete.vue'
import editor from '../../../../Editor.vue'
import { IShowOptionsBlock } from '../../../../../../../../../common/blocks/dialog/show-options-block.interface'
import ShowOptionsBlockComponent from '../../../../modules/blocks/dialog/blocks/ShowOptionsBlockComponent'

@Component({ components: { Tooltip, editor, Autocomplete } })
export default class ShowOptionsExtension extends Vue {
  private choices: Array<null> = null;

  @Prop({ default: () => { return {} } })
  readonly currentBlock!: ShowOptionsBlockComponent<IShowOptionsBlock>;

  @Prop({ default: () => [] }) readonly variableNames!: any[];
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  @Watch('choices')
  choicesChanged () {
    this.$parent.$emit('blockFieldChange')
  }

  created () {
    if (!this.currentBlock.customData['field-choices']) { this.currentBlock.customData['field-choices'] = [] }

    if (typeof this.currentBlock.customData['field-choices'] === 'string') {
      this.currentBlock.customData['field-choices'] = (this.currentBlock
        .customData['field-choices'] as string).split(',')
    }
    this.choices = new Array(
      this.currentBlock.customData['field-choices'].length
    )
  }

  changeQuestion (field: { content: string }) {
    this.currentBlock.customData['field-suggestion'] = field.content
  }

  changeOption (field: { content: string }, index: number) {
    this.currentBlock.customData['field-choices'][index] = field.content
  }

  addOption () {
    this.choices.push(null)
    this.currentBlock.customData['field-choices'].push('')
  }

  removeOption (index: number) {
    this.choices.splice(index, 1)
    this.currentBlock.customData['field-choices'].splice(index, 1)
  }
}
</script>

<style scoped >
.icon-delete {
  position: absolute;
  top: 17px;
  right: 14px;
}

</style>
