import { Component, Vue } from 'vue-property-decorator'

import popupService from '../../../../services/popup.service'
import intentsService, { IntentsService } from '../../../../services/bots/intents.service'
import statisticsService from '../../../../services/bots/statistics.service'
import descriptionService from '../../../../services/bots/description.service'
import { saveAs } from 'file-saver'

@Component
export default class IntentManagementMixin extends Vue {
  intents = [];
  redirectintents = [];
  normalIntents = [];
  activeIntent = 0;
  name = '';
  currentTab = '';
  prebuildEntities: any
  customEntities: any
  entities: any
  originalEntities: any
  /*  Holds all untrained utt from the unexpected amnswer widget or from untrained questions widget, demands on from where you opened it */
  createIntentUntrainedExamples = [];
  allUntrainedExamples = [];
  isFromUntrained = false;
  intentsService = undefined;
  bot: any;

  setIntents (data) {
    this.intents = data.intents
    this.redirectintents = (data.intents || []).filter(i => i.isRedirect)
    this.normalIntents = (data.intents || []).filter(i => !i.isRedirect)
    this.intentsService = new IntentsService()

    // Move None intent a the end
    const noneIndex = this.normalIntents.findIndex(i => i.name == 'None')
    this.normalIntents.push(...this.normalIntents.splice(noneIndex, 1))
  }

  async updateAnswer (intent) {
    await intentsService.patchIntent(this.name, intent.name, { answer: intent.answer })
  }

  async changeType (intent) {
    await intentsService.patchIntent(this.name, intent.name, { type: intent.type })
  }

  async createIntent (intent) {
    if (intent.utterances.length + intent.patterns.length < 2) { return popupService.showError('Error', "Intent doesn't have enough examples") }

    const created = await this.intentsService.create(this.name, intent)

    if (created.error) { return popupService.showError('Error with the creating of the intent', created.error) }

    this.bot.isPublished = false
    if (this.createIntentUntrainedExamples.length > 0) {
      const usedExamples = this.allUntrainedExamples.filter(e =>
        intent.utterances.some(u => u.text === e.message) ||
        intent.patterns.some(p => p.text === e.message))

      if (usedExamples.length > 0) { await statisticsService.batchDeleteUntrainedExamples(this.bot.botName, usedExamples) }

      this.createIntentUntrainedExamples = []
    }

    if (intent.isRedirect) {
      this.redirectintents.push(created)
      this.currentTab = 'redirect'
    } else {
      this.normalIntents.splice(this.normalIntents.length - 1, 0, created)
      this.currentTab = 'intents'
    }
    this.intents.push(created)
  }

  async deleteIntent (intent) {
    await this.intentsService.delete(this.name, intent.name)
    if (intent.isRedirect) {
      // this.activeRedirect = this.redirectintents.indexOf(intent) ? this.redirectintents.indexOf(intent) - 1 : this.redirectintents.indexOf(intent)
      this.redirectintents = this.redirectintents.filter(item => item !== intent)
    } else {
      this.activeIntent = this.normalIntents.indexOf(intent) ? this.normalIntents.indexOf(intent) - 1 : this.normalIntents.indexOf(intent)
      this.normalIntents = this.normalIntents.filter(item => item !== intent)
    }
    this.bot.isPublished = false
    this.intents.splice(this.intents.indexOf(intent), 1)
  }

  async importIntent (data) {
    try {
      const { file, intentName } = data

      const { intent, entities } = await intentsService.import(file, this.name, intentName)

      this.normalIntents.splice(this.normalIntents.length - 1, 0, intent)
      this.currentTab = 'intents'
      this.intents.push(intent)

      /** DON'T DO THIS AGAIN */
      /** Seems to work tho. Vue mixins are strange */
      /** Adds the entities from the new intent to the frontend */
      for (const entity of entities) {
        if (entity.type === 'prebuilts') {
          // Update prebuilt entity
          const current = this.prebuildEntities.find(e => e.name === entity.name)
          current.description = entity.description
          current.roles = entity.roles
        } else {
          // Add new entity
          this.customEntities.push(entity)
        }
        this.entities.push(entity)
        this.originalEntities[entity.name] = entity
      }
    } catch (err) {
      // Ugly but does the job. TODO: refactori ImportItem component in the future
      (this as any).reset('file-intent', 'intents')

      if (err.response && err.response.status === 403) {
        throw err
      }
      const message = err.response ? err.response.data.message : err.message
      popupService.showError(message)
    }
  }

  async exportIntent (intent: any) {
    if (intent.name === 'None') {
      popupService.showError('Cannot export the None intent!')
      return
    }

    try {
      const blob = await intentsService.export(this.name, intent.name)
      saveAs(blob, `${this.name}-${intent.name}.enc`)
    } catch (err) {
      if (err.response && err.response.status === 403) {
        throw err
      }
      const response = err.response ? await err.response.data.text() : '{}'
      popupService.showError(JSON.parse(response).message || err.message)
    }
  }

  createIntentFromUntrained (data) {
    this.createIntentUntrainedExamples = [data.utterance]
    this.allUntrainedExamples = data.allUntrainedUtterances
    this.isFromUntrained = true

    this.currentTab = 'create-intent'
  }

  closeCreateIntent (data) {
    const { fromDashboard, isRedirect } = data
    if (fromDashboard) { this.currentTab = 'analytics' } else { this.currentTab = isRedirect ? 'redirect' : 'intents' }

    this.createIntentUntrainedExamples = []
    this.isFromUntrained = false
  }

  async updateIntentDescription (data) {
    try {
      await descriptionService.updateIntent(this.name, data.intentName, data.description)
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }
      popupService.showError('Failed to save intent description!')
    }
  }

  async updateIntentName (data) {
    try {
      if (this.intents.some(i => i.name.trim() === data.newName.trim())) {
        popupService.showError('Intent name already exists!')
        return
      }
      const intent = await this.intentsService.editName(this.name, data)
      this.bot.isPublished = false

      const normalIndex = this.normalIntents.findIndex(x => x.name === data.name)
      this.$set(this.normalIntents, normalIndex, intent.data)

      const allIndex = this.intents.findIndex(x => x.name === data.name)
      this.$set(this.intents, allIndex, intent.data)
      popupService.showInfo('Intent name saved')
    } catch (error) {
      if (error.code == 'permission_error') {
        throw error
      }
      popupService.showError('Failed to save intent name!')
    }
  }

  addIntents (intents: any[]) {
    this.intents.push(...intents)
    this.normalIntents.splice(this.normalIntents.length - 1, 0, ...intents)
    this.bot.isPublished = false
  }
}
