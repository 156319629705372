<template>
  <div>
    <div class="form-section">
      <h4>Bot Threshold Settings</h4>

      <FormGroup
        label="Intent confidence threshold (top level)"
        tooltip="This number determines the top threshold below which the bot asks for clarification of the intent."
      >
        <select v-model.number="settings.confidence" :disabled="!hasSufficientPermission" class="white-select">
          <option
            v-for="option in allTopOptions"
            id="conf"
            :key="option"
            class="form-control"
          >{{ option }}</option>
        </select>
        <i class="dropdown-toggle x"></i>
      </FormGroup>

      <FormGroup
        label="Message when the bot is not sure"
        tooltip="This text will be sent when the confidence level is between the high & low treshold of the intent."
      >
        <textarea
          rows="3"
          v-model="settings.messageBetweenThreshold"
          placeholder="Some things to say"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <FormGroup
        label="Intent confidence threshold (low level)"
        tooltip="This number determines the low threshold below which the bot asks for clarification of the intent."
      >
        <select v-model.number="settings.lowConfidence" :disabled="!hasSufficientPermission" class="white-select">
          <option
            v-for="option in lowOptions"
            id="conf"
            :key="option"
            class="form-control"
          >{{ option }}</option>
        </select>
        <i class="dropdown-toggle x"></i>
      </FormGroup>

      <FormGroup
        label="Message when no intent is recognized"
        tooltip="This text will be sent when no intent is recognized."
      >
        <textarea
          rows="3"
          v-model="settings.messageBelowThreshold"
          placeholder="I don't understand"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <Toggle
        label="Chit-Chat"
        tooltip="Instead of automatic redirection to the None intent you can enable chit-chat."
        v-model="settings.chitChatEnabled"
      ></Toggle>

      <FormGroup
        :label="belowThresholdLabel"
        v-if="settings.chitChatEnabled"
      >
        <input
          type="number"
          min="1"
          v-model="settings.belowThresholdLimit"
          :disabled="!hasSufficientPermission"
        />
      </FormGroup>

      <FormGroup
        v-if="settings.chitChatEnabled"
        label="Model and Engine"
      >
      <Select
          :elements="Object.entries(OpenAiModel)"
          :key="settings.chitChatEngine"
          keyAttr="0"
          valueAttr="1"
          v-model="settings.chitChatModel"
          @change="change()"
          class="white-select"
        ></Select>
      </FormGroup>
      <!-- ........................................................................ -->
      <FormGroup
        v-if="whichGptModel()==3.5 && settings.chitChatEnabled"
      >
        <Select
          :elements="Object.entries(OpenAiEngine3_5)"
          :key="settings.chitChatEngine"
          keyAttr="0"
          valueAttr="1"
          v-model="settings.chitChatEngine"
          class="white-select"
          style="margin-left: 40px;margin-top: -20px;"
        ></Select>
      </FormGroup>
      <FormGroup
        v-if="whichGptModel()==3 && settings.chitChatEnabled"
      >
        <Select
          :elements="Object.entries(OpenAiEngine3_0)"
          :key="settings.chitChatEngine"
          keyAttr="0"
          valueAttr="1"
          v-model="settings.chitChatEngine"
          class="white-select"
          style="margin-left: 40px;margin-top: -20px;"
        ></Select>
      </FormGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import { Permissions } from '../../../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../../../services/tenants/permissions.service'
import Select from '../../../../../helpers/Select.vue'
import { OpenAiEngine3_0 } from '../../../../../../../../common/enums/open-ai-engine3_0'
import { OpenAiEngine3_5 } from '../../../../../../../../common/enums/open-ai-engine3_5'
import { OpenAiEngine4 } from '../../../../../../../../common/enums/open-ai-engine4'
import { OpenAiModel } from '../../../../../../../../common/enums/open-ai-models'

@Component({
  components: { FormGroup, Toggle, Select }
})
export default class BotIntentRecognizerSettings extends Vue {
  OpenAiEngine3_0 = OpenAiEngine3_0;
  OpenAiEngine3_5 = OpenAiEngine3_5;
  OpenAiEngine4 = OpenAiEngine4
  OpenAiModel = OpenAiModel;
  mounted () {
    if (this.settings.chitChatModel === OpenAiModel['GPT 3.5']) {
      if (this.settings.chitChatEngine === OpenAiEngine3_0.Ada || this.settings.chitChatEngine === OpenAiEngine3_0.Babbage || this.settings.chitChatEngine === OpenAiEngine3_0.Curie || this.settings.chitChatEngine === OpenAiEngine3_0.Davinci) {
        this.settings.chitChatModel = OpenAiModel['GPT 3.0']
      }
    } else if (this.settings.chitChatModel === OpenAiModel['GPT 4']) {
      if (this.settings.chitChatEngine === OpenAiEngine3_0.Ada || this.settings.chitChatEngine === OpenAiEngine3_0.Babbage || this.settings.chitChatEngine === OpenAiEngine3_0.Curie || this.settings.chitChatEngine === OpenAiEngine3_0.Davinci) {
        this.settings.chitChatModel = OpenAiModel['GPT 4']
      }
    } else if (this.settings.chitChatModel === OpenAiModel.GPT_4o) {
      if (this.settings.chitChatEngine === OpenAiEngine3_0.Ada || this.settings.chitChatEngine === OpenAiEngine3_0.Babbage || this.settings.chitChatEngine === OpenAiEngine3_0.Curie || this.settings.chitChatEngine === OpenAiEngine3_0.Davinci) {
        this.settings.chitChatModel = OpenAiModel.GPT_4o
      }
    }
    this.$watch(
      'settings.chitChatModel',
      () => this.change()
    )
  }

  @Prop({ default: () => { return {} } }) readonly settings: any;
  @Prop() readonly botName: string;

  private hasSufficientPermission = false;

  async created () {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageGeneralSettings
    )
  }

  get belowThresholdLabel () {
    const msg = this.settings.chitChatEnabled
      ? 'Initiate chit-chat'
      : 'Automatic redirection to the None intent'
    return msg + ' when no intent is recognized X amount of times'
  }

  readonly allTopOptions = [0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0];
  readonly allLowOptions = [
    0.2,
    0.25,
    0.3,
    0.35,
    0.4,
    0.45,
    0.5,
    0.55,
    0.6,
    0.65,
    0.7,
    0.75,
    0.8,
    0.85,
    0.9
  ];

  whichGptModel () {
    if (this.settings.chitChatModel === OpenAiModel['GPT 3.5']) {
      this.settings.chitChatModel = OpenAiModel['GPT 3.5']
      return 3.5
    } else if (this.settings.chitChatModel === OpenAiModel['GPT 3.0']) {
      this.settings.chitChatModel = OpenAiModel['GPT 3.0']
      return 3
    } else if (this.settings.chitChatModel === OpenAiModel['GPT 4']) {
      this.settings.chitChatModel = OpenAiModel['GPT 4']
      return 4
    } else if (this.settings.chitChatModel === OpenAiModel.GPT_4o) {
      this.settings.chitChatModel = OpenAiModel.GPT_4o
      return OpenAiModel.GPT_4o
    }
  }

  change () {
    if (this.settings.chitChatModel === OpenAiModel['GPT 3.5']) {
      this.settings.chitChatModel = OpenAiModel['GPT 3.5']
      this.settings.chitChatEngine = OpenAiEngine3_5.Turbo
    } else if (this.settings.chitChatModel === OpenAiModel['GPT 3.0']) {
      this.settings.chitChatModel = OpenAiModel['GPT 3.0']
      this.settings.chitChatEngine = OpenAiEngine3_0.Davinci
    } else if (this.settings.chitChatModel === OpenAiModel['GPT 4']) {
      this.settings.chitChatModel = OpenAiModel['GPT 4']
      this.settings.chitChatEngine = OpenAiEngine4['GPT-4']
    } else if (this.settings.chitChatModel === OpenAiModel.GPT_4o) {
      this.settings.chitChatModel = OpenAiModel.GPT_4o
      this.settings.chitChatEngine = OpenAiEngine4.GPT_4o
    }
  }

  get lowOptions () {
    const top = Number(this.settings.confidence || 0.7)
    const availableLowOptions = this.allLowOptions.filter(e => e < top)
    if (!availableLowOptions.includes(this.settings.lowConfidence)) {
      this.settings.lowConfidence =
        availableLowOptions[availableLowOptions.length - 1]
    }
    return availableLowOptions
  }
}
</script>

<style lang="scss" scoped>
.nlp-section {
  margin: 10px 0 0 20px;
}

.nlp-button {
  margin: 10px 20px 10px 0;
}
</style>
