import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ValidationManagement extends Vue {
  bot

  getBotValidator() {
    return this
  }

  setValidator(bot) {
    this.bot = bot
  }

  getNameAlreadyInUseViolations(name) {
    const violations = []

    const properties = [
      { type: 'variables', values: this.bot.flowVariables },
      { type: 'objects', values: this.bot.flowObjects },
      { type: 'entities', values: this.bot.entities }
    ]

    properties.forEach(property => {
      if (Object.keys(property.values).some(value => value.toLowerCase() == name.toLowerCase())) {
        violations.push(property.type)
      }
    })

    return violations.map(e => 'The given name is already used in ' + e)
  }

  validatorForCreateObject(newObject) {
    return this.getNameAlreadyInUseViolations(Object.keys(newObject).pop())
  }

  validatorForCreateVariable(newVarName) {
    return this.getNameAlreadyInUseViolations(newVarName)
  }

  validatorForCreateEntity(newEntityName) {
    return this.getNameAlreadyInUseViolations(newEntityName)
  }

  validateName(name: string) {
    return typeof name !== 'string' || name.trim() === '' || !/^\w{2,}$/g.test(name)
  }

  validateNameSpacesAllowed(name: string) {
    return !/^[a-zA-Z\s]{2,}$/g.test(name)
  }

  validateNameSpacesLLMAllowed(name: string) {
    return !/^[a-zA-Z0-9\s]{2,}$/g.test(name)
  }

  validateDesc(desc: string) {
    return typeof desc !== 'string' || desc.trim() === ''
  }
}
