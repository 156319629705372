<template>
  <ConfigurationPageBase
    :mode="'master-admin'"
    :plan="masterTenantPlan"
    :usersCount="users.length"
    @user-created="addUser"
    @tenant-created="addTenant"
    v-slot:default="{ chosenPage }"
  >
    <Loading v-if="deletingTenant" :title="`Deleting tenant ${deletingTenant}...`"></Loading>

    <Tenants
      v-if="chosenPage === 'Tenants'"
      :tenantsData="tenantsData"
      :isDeleting="isDeleting"
      @edit="editTenantIndex = $event"
      @delete="deleteTenant"
    ></Tenants>

    <Users
      v-if="chosenPage === 'Users'"
      :usersData="usersData"
      :isDeleting="isDeleting"
      :showLogsButton="false"
      @edit="editUserIndex = $event"
      @delete="deleteUser"
    ></Users>

    <MasterBilling
      v-if="chosenPage === 'Billing'"
      :stripeCustomer="stripeCustomer"
      @submit="updateStripeCustomer"
    ></MasterBilling>

    <MasterEditTenant
      v-if="editTenantIndex !== -1"
      :tenant="tenants[editTenantIndex]"
      :plans="plans"
      :users="tenantsData[editTenantIndex].Users"
      :bots="tenantsData[editTenantIndex].Bots"
      :interactions="interactions[tenants[editTenantIndex].id] || 0"
      :stripeCustomer="stripeCustomer"
      @close="editTenantIndex = -1"
    ></MasterEditTenant>

    <MasterEditUser
      v-if="editUserIndex !== -1"
      :userData="users[editUserIndex]"
      :masterTenant="masterTenant"
      :tenants="tenants"
      @update="updateUser"
      @close="editUserIndex = -1"
    ></MasterEditUser>
  </ConfigurationPageBase>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import tenantService from '../../../services/tenant.service'
import userRolesService from '../../../services/user-roles.service'

import ConfigurationPageBase from './ConfigurationPageBase.vue'
import Tenants from './tabs/Tenants.vue'
import Users from './tabs/Users.vue'
import MasterBilling from './tabs/MasterBilling.vue'
import MasterEditTenant from './edit-pages/MasterEditTenant.vue'
import MasterEditUser from './edit-pages/MasterEditUser.vue'
import { TenantPlan } from '../../../../../common/interfaces/plans/tenant-plan.interface'
import { IUser } from '../../../../../common/interfaces/tenant/user.interface'
import { ITenant } from '../../../../../common/interfaces/tenant/tenant.interface'
import { getVisibleTenantsIds, isMasterTenantAdmin } from '../../../../../common/helpers/tenant-role.helper'
import EventBus from '../../../services/EventBus'
import { IStripeCustomerDto } from '../../../../../common/interfaces/stripe-customer-dto.interface'
import usersService from '../../../services/users.service'
import popupService from '../../../services/popup.service'
import Loading from '../../helpers/Loading.vue'

@Component({
  components: {
    ConfigurationPageBase,
    Tenants,
    Users,
    MasterEditTenant,
    MasterEditUser,
    MasterBilling,
    Loading
  }
})
export default class MasterConfigurationPage extends Vue {
  masterTenantPlan: TenantPlan = null;

  user: IUser = null;
  masterTenant: ITenant = null;
  tenants: ITenant[] = [];
  plans: TenantPlan[] = [];
  users: IUser[] = [];
  stripeCustomer: IStripeCustomerDto = null;

  // Metered
  bots: { [tenantId: string]: number } = {};
  interactions: { [tenantId: string]: number } = {};

  deletingTenant = '';
  isDeleting = false;
  editTenantIndex = -1;
  editUserIndex = -1;

  async loadStripeCustomer () {
    this.stripeCustomer = await usersService.loadStripeCustomerData()
  }

  async updateStripeCustomer () {
    await usersService.updateStripeCustomerData(this.stripeCustomer)
    popupService.showInfo('Saved!')
  }

  async created () {
    try {
      const data = await tenantService.getMasterTenantInfo()
      this.user = data.user
      this.masterTenant = data.masterTenant
      if (!isMasterTenantAdmin(this.user, this.masterTenant)) return this.$router.push('/')
      await this.loadStripeCustomer()
      this.plans = data.plans
      this.masterTenantPlan = this.plans.find(p => p.id === data.masterTenant.planId)
      this.tenants = data.tenants
      this.users = data.users

      this.bots = data.bots
      this.interactions = data.interactions
    } catch (err) {
      this.$router.push('/')
    }
  }

  addUser (user: any) {
    this.users.push(user)
  }

  updateUser (user: IUser) {
    this.$set(this.users, this.editUserIndex, user)
  }

  addTenant (tenant: any) {
    this.tenants.push(tenant)
    EventBus.dispatchEvent({ type: 'tenant-created', tenant })
  }

  get tenantsData () {
    return this.tenants.map(t => ({
      Name: t.displayName,
      Plan: this.plans.find(p => p.id === t.planId)?.name,
      Bots: this.bots[t.id] || 0,
      Users: this.users.reduce((result, u) => getVisibleTenantsIds(u, this.masterTenant.id).some(id => id === t.id) ? result + 1 : result, 0),
      Action: '2'
    }))
  }

  get usersData () {
    return this.users.map(u => {
      const isMta = isMasterTenantAdmin(u, this.masterTenant)
      const visibleTenantsIds = getVisibleTenantsIds(u, this.masterTenant.id)
      return {
        Name: u.id,
        Role: isMta ? 'Master Tenant Admin' : 'User',
        Tenants: isMta || visibleTenantsIds.length === this.tenants.length ? 'All tenants' : `${visibleTenantsIds.length} tenant${visibleTenantsIds.length != 1 ? 's' : ''}`,
        Action: u.id == this.user.id ? 'myuser' : '2'
      }
    })
  }

  async deleteTenant (index: number) {
    try {
      this.isDeleting = true
      const tenant = this.tenants[index]
      this.deletingTenant = tenant.displayName
      await tenantService.delete(tenant.id)
      this.tenants.splice(index, 1)
      delete this.bots[tenant.id]
      EventBus.dispatchEvent({ type: 'tenant-deleted', id: tenant.id })
    } finally {
      this.isDeleting = false
      this.deletingTenant = ''
    }
  }

  async deleteUser (index: number) {
    try {
      this.isDeleting = true
      await userRolesService.deleteUser(this.users[index].id)
      this.users.splice(index, 1)
    } finally {
      this.isDeleting = false
    }
  }
}
</script>
