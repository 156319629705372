var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form--wrapper__column"},[_c('div',{staticClass:"column-header"},[_c('h2',[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"dropdown list-option--actions"},[(_vm.hasSufficientPermission)?_c('button',{staticClass:"dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown"}},[_c('i',{staticClass:"plus"})]):_vm._e(),_c('div',{staticClass:"dropdown-menu"},[_c('div',{staticClass:"dropdown-menu__top"},[_c('p',[_vm._v(_vm._s(_vm.dropdownText))]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.getSearchPlaceholder()},on:{"input":_vm.searchFilter}})]),_vm._l((_vm.hiddenConnections),function(connection,index){return _c('div',{key:index},[_c('a',{staticClass:"dropdown-item",class:{
              disabled: _vm.allowedChannels
                ? !_vm.allowedChannels.includes(connection.name.toLowerCase())
                : false,
            },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addConnection(index)}}},[_c('div',[_c('img',{attrs:{"src":connection.icon}}),_vm._v(" "+_vm._s(connection.name)+" ")]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addConnection(index)}}},[_c('i',{staticClass:"plus"})])])])})],2)])]),_c('div',{staticClass:"form--wrapper__list"},_vm._l((_vm.visibleConnections),function(connection,index){return _c('div',{key:index,staticClass:"list-option",class:{
        'list-option--selected': connection.connected && connection.enabled,
        disabled: _vm.allowedChannels
          ? !_vm.allowedChannels.includes(connection.name.toLowerCase())
          : false,
      }},[_c('div',{staticClass:"list-option--image"},[_c('img',{attrs:{"src":connection.icon}})]),_c('div',{staticClass:"list-option--information"},[_c('h4',[_vm._v(" "+_vm._s(connection.name === "Email" ? "Office 365" : connection.name)+" ")]),(connection.connected && connection.enabled)?_c('p',[_vm._v(" Connected, Enabled ")]):(connection.connected && !connection.enabled)?_c('p',[_vm._v(" Connected, Disabled ")]):(
            !connection.connected &&
            connection.enabled &&
            !connection.requested
          )?_c('p',[_vm._v(" Not connected, Enabled ")]):(!connection.connected && connection.requested)?_c('p',[_vm._v(" Connection requested ")]):_c('p',[_vm._v("Not connected, Disabled")])]),(!connection.requested && _vm.hasSufficientPermission)?_c('div',{staticClass:"dropdown list-option--actions"},[_vm._m(0,true),_c('div',{staticClass:"dropdown-menu"},[(_vm.areSettingsAvailable(connection.name))?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('open-settings', connection.name)}}},[_c('i',{staticClass:"icon icon-settings"}),_vm._v("Settings ")]):_vm._e(),(connection.enabled)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.disableConnection(index)}}},[_c('i',{staticClass:"icon icon-disable"}),_vm._v("Disable ")]):_vm._e(),(!connection.enabled)?_c('a',{staticClass:"dropdown-item enable",class:{
              disabled: _vm.maxChannelsReached && connection.name !== 'Test Chat',
            },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.enableConnection(index)}}},[_c('i',{staticClass:"icon icon-enable"}),_vm._v("Enable ")]):_vm._e(),(
              !connection.connected &&
              !connection.requested &&
              !_vm.areSettingsAvailable(connection.name)
            )?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('request-connection', connection.name)}}},[_c('i',{staticClass:"icon icon-request-connect"}),_vm._v("Request connect ")]):_vm._e(),(
              (!connection.connected || !connection.enabled) &&
              !_vm.areSettingsAvailable(connection.name)
            )?_c('a',{staticClass:"dropdown-item remove",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeConnection(index)}}},[_c('i',{staticClass:"icon icon-delete"}),_vm._v("Remove ")]):_vm._e()])]):_vm._e()])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown"}},[_c('span'),_c('span'),_c('span')])
}]

export { render, staticRenderFns }