<template lang="html">
  <div class="rect rect--platform">
    <h1>Platform Status</h1>
    <div class="row">
      <div class="col-6" v-for="(value, key) in statusData" :key="key">
        <div class="rect__data">
          <div class="status">
            <h4>{{key}}</h4>
          </div>
          <div class="data">
            <h2>{{value || 100 | fix(1)}}%</h2>
            <p>Last<br>30 days</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { fix } from '../filters/number.filters'

@Component({
  filters: {
    fix: fix
  }
})
export default class PlatformStatus extends Vue {
  @Prop({ default: {} }) readonly data: any;

  get statusData () {
    return {
      'Platform API': this.data.platform,
      Bot: this.data.webchat
    }
  }
}
</script>

<style lang="scss" scoped>
@import "analytics.style";

.line .col-4 {
  display: flex;
  align-items: baseline;
}
.rect {
  &--platform {
    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2,
    h4,
    p {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
  &__data {
    .status,
    .data {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 7px;

      &--green {
        background: #43b02a;
      }
      &--red {
        background: #bc204b;
      }
      &--gray {
        background: $secondary-text;
      }
    }
    h4 {
      font-size: 14px;
      font-weight: 600;
      color: $secondary-text;
      line-height: 18px;
      text-transform: uppercase;
    }
    .data {
      h2 {
        font-size: 40px;
        font-weight: 300;
        line-height: 50px;
        margin-bottom: 0;
        margin-right: 20px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: $secondary-text;
        line-height: 18px;
      }
    }
  }
}
</style>
