<template>
  <div class="form-group form-group--options">
    <div>
      <div class="label">Entity</div>
      <Select
        id="is-of-type-selector"
        :elements="entities"
        :key="currentBlock.customData.whichEntity"
        keyAttr="name"
        v-model="currentBlock.customData.whichEntity"
        @input="updateLabel()"
      ></Select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Select from '../../../../../../helpers/Select.vue'
import { IIsAnEntityBlock } from '../../../../../../../../../common/blocks/connector/is-an-entity-block.interface'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component({
  components: {
    Select
  }
})
export default class IsAnEntity extends Vue {
  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<IIsAnEntityBlock>;
  @Prop({ default: () => [] }) readonly entities!: any[];

  @Emit()
  updateLabel () {}

  mounted () {
    this.updateLabel()
  }
}
</script>
