import axios from 'axios'
import { RoleViewModel } from '@/components/admin/configuration/models/role.view.model'
import { ITenant } from '../../../common/interfaces/tenant/tenant.interface'

export class RolesService {
    private userRoles: {
        botRoles: {
            botId: string;
            role: string;
        }[];
        isTenantAdmin: boolean;
    };

    private uniqueRoles: string[];

    private botRoles: {roleId: string; botId: string; role: string}[];

    private isTenantAdminProperty: boolean;

    async setPermissions () {
      this.userRoles = await this.getUserRoles()
    }

    async setUniqueRoles () {
      this.uniqueRoles = await this.getRawUniqueRoles()
    }

    async setBotRoles () {
      this.botRoles = await this.getRawBotRoles()
    }

    async getUserRoles () {
      const r = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/roles`)
      return r.data
    }

    async getTenantRoles () {
      const r = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/roles`)
      return r.data
    }

    async addNewTenantRole (roleName: string) {
      return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/tenant/roles`,
        { roleName })
    }

    async changeRolePermissions (roleId: string, permissionId: string, remove: boolean) {
      if (!remove) {
        return await axios.post(`${process.env.VUE_APP_BACKEND_URL}/tenant/roles/${roleId}/permissions`,
          { roleId, permissionId })
      } else {
        return await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/tenant/roles/${roleId}/permissions/${permissionId}`)
      }
    }

    async changeRoles (roles: RoleViewModel[]) {
      return await axios.put(`${process.env.VUE_APP_BACKEND_URL}/tenant/roles`,
        { roles })
    }

    async deleteRole (roleId: string) {
      return await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/tenant/${roleId}`)
    }

    async getRawUniqueRoles () {
      const r = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/user/unique-roles`)
      return r.data
    }

    async getRawBotRoles () {
      const r = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/user/unique/bot/roles`)
      return r.data
    }

    async getUniqueRoles () {
      if (!this.uniqueRoles) { await this.setUniqueRoles() }
      return this.uniqueRoles
    }

    async getBotsRoles () {
      if (!this.botRoles) { await this.setBotRoles() }
      return this.botRoles
    }

    /** Checks if user has role for given bot */
    async hasPermission (_: string[], __: string) {
      // let botIdFragment = botHelper.assembleBotIdFragment(botName)
      // if (!this.userRoles)
      //     await this.setPermissions();
      // return this.userRoles.isTenantAdmin ?
      //     true :
      //     this.userRoles.botRoles && this.userRoles.botRoles.some(ur => botHelper.disassembleBotId(ur.botId) === botIdFragment && roles.includes(ur.role))
      return false
    }

    async isTenantAdmin () {
      if (typeof this.isTenantAdminProperty !== 'boolean') {
        try {
          this.isTenantAdminProperty = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/users/is-tenant-admin`).then(r => r.data)
        } catch (err) {
          this.isTenantAdminProperty = false
        }
      }

      return this.isTenantAdminProperty
    }

    async loadUserPermissions (): Promise<{ tenant: Pick<ITenant, 'id' | 'displayName'>; masterTenant?: Pick<ITenant, 'id' | 'displayName'>; userTenantRoles: any; botRoles: {}; tenantRoles: {} }> {
      return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/user/permissions/data`).then(r => r.data)
    }
}

export default new RolesService()
