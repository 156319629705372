import axios from 'axios'

export class GdprService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/gdpr/settings`
  }

  async getGdpr () {
    const res = await axios.get(this.url)
    return res.data
  }

  async updateGdpr (gdprSettings: any) {
    return axios.put(this.url, gdprSettings)
  }
}
