<template>
  <div class="main-header__desc-name">
    <form @submit.prevent="updateName">
      <div class="form-group" v-show="editMode">
        <input
          v-model="valueCopy"
          type="text"
          class="form-control description-edit large"
          name="componentName"
          ref="input"
          @blur="blur"
          @keyup.enter="submit"
        />
        <!-- <button
          :disabled="!valueCopy.trim()"
          class="btn btn-success desc button-submit"
          style="float: right"
          type="submit"
        >Update</button> -->
      </div>
      <h1 class="desc-info" v-if="!editMode" @click="toggle">{{ valueCopy.trim() }}</h1>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ComponentName extends Vue {
  $refs: {
    input: HTMLFormElement
  }

  @Prop() value: string
  @Prop({ type: Boolean, default: true }) isEditable: boolean

  private valueCopy = ''
  private editMode = false

  updateName(event) {
    this.$emit('input', event.target.elements.componentName.value)
    this.editMode = false
  }

  created() {
    this.valueCopy = this.value || ''
  }

  toggle() {
    if (this.isEditable) {
      this.editMode = true
      this.$nextTick(() => this.$refs.input.focus())
    }
  }

  blur(event) {
    if (!this.valueCopy.trim()) this.valueCopy = this.value
    if (!event.relatedTarget || event.relatedTarget.type !== 'submit') {
      this.editMode = false
    }
  }
}
</script>

<style lang="scss" scoped>
.description-edit {
  border: 0px solid transparent !important;

  &.large {
    height: 50px;
    font-size: 30px;
    font-weight: 500;
    padding: 0px;
    margin-bottom: -15px;
  }
}
</style>
