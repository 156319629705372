<template>
  <div>
    <div class="form-group image-dropdown">
      <label for="function">
        Variable to compare
        <Tooltip
          title="This field gives the possibility to add Question / Suggestion to the option-card."
          placement="bottom"
        ></Tooltip>
      </label>
      <Select
        id="dictionaryCondition"
        :elements="variables"
        :key="currentBlock.customData.condition"
        keyAttr="name"
        v-model="currentBlock.customData.condition"
        :disabled="!hasSufficientPermission"
      ></Select>
    </div>
    <div class="form-group image-dropdown">
      <label for="function">
        Dictionary elements
        <Tooltip
          title="This field gives the possibility to add Dictionary elements to the option-card."
          placement="bottom"
        ></Tooltip>
      </label>

      <div v-for="(v, index) in dictionaryElements" style="position: relative;" :key="index">
        <input
          v-model="currentBlock.customData.dictionaryElements[index].key"
          type="text"
          :placeholder="'Equals'"
          class="form-control"
          :disabled="!hasSufficientPermission"
        />

      <i class="icon-delete" @click="removeDictionaryElement(index)" v-show="hasSufficientPermission"></i>

        <autocomplete
          style="margin-left: 15px;"
          v-model="currentBlock.customData.dictionaryElements[index].value"
          :items="variableNames"
          :placeholder="'Enter the dictionary elements'"
          :disabled="!hasSufficientPermission"
        />
      </div>
      <div class="option-button">
        <div class="btn btn--white dialogmanager" @click="addDictionaryElement()" v-if="hasSufficientPermission">
          Add new
          <i class="icon icon-add"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Select from '../../../../../../helpers/Select.vue'
import Tooltip from '../../../../../../helpers/Tooltip.vue'
import Autocomplete from '../../../../../../helpers/Autocomplete.vue'
import { IDictionaryBlock } from '../../../../../../../../../common/blocks/dialog/dictionary-block.interface'
import DictionaryBlockComponent from '../../../../modules/blocks/dialog/blocks/DictionaryBlockComponent'

@Component({
  components: {
    Select,
    Tooltip,
    Autocomplete
  }
})
export default class DictionaryExtension extends Vue {
  private dictionaryElements: Array<null> = null;

  @Prop({ default: () => { return {} } }) readonly currentBlock!: DictionaryBlockComponent<
    IDictionaryBlock
  >;

  @Prop({ default: () => [] }) readonly variables!: any[];
  @Prop({ default: () => [] }) readonly variableNames!: any[];
  @Prop({ required: false, default: true }) hasSufficientPermission: boolean;

  created () {
    if (!this.currentBlock.customData.dictionaryElements) {
      this.currentBlock.customData.dictionaryElements = []
    }
    this.dictionaryElements = new Array(
      this.currentBlock.customData.dictionaryElements.length
    )
  }

  addDictionaryElement () {
    this.dictionaryElements.push(null)
    this.currentBlock.addNewData()
  }

  removeDictionaryElement (index: number) {
    this.dictionaryElements.splice(index, 1)
    this.currentBlock.customData.dictionaryElements.splice(index, 1)
  }
}
</script>

<style scoped >

.icon-delete {
  position: absolute;
  top: 17px;
  right: 14px;
}
</style>
