<template>
  <div v-if="current" class="form--wrapper">
    <form>
      <section id="common">
        <div id="type" class="row" v-if="showType">
          <div class="col-12">
            <div class="configuration-tabs">
              <div class="configuration-tabs--inner">
                <button
                  type="button"
                  class="tab"
                  :class="{ 'tab--active' : current.type === FunctionType.SQL }"
                  @click="current.type = FunctionType.SQL"
                >SQL</button>
                <button
                  type="button"
                  class="tab"
                  :class="{ 'tab--active' : current.type === FunctionType.REST }"
                  @click="current.type = FunctionType.REST"
                >REST</button>
                <button
                  type="button"
                  class="tab"
                  :class="{ 'tab--active' : current.type === FunctionType.AdaptiveCard }"
                  @click="current.type = FunctionType.AdaptiveCard"
                >Adaptive Card</button>
                <button
                  type="button"
                  class="tab"
                  :class="{ 'tab--active' : current.type === FunctionType.Code }"
                  @click="current.type = FunctionType.Code"
                >Custom Code</button>
              </div>
              <div class="configuration-tabs--inner">
                <button
                  type="button"
                  class="tab"
                  :class="{ 'tab--active' : current.type === FunctionType.CaptureStorage }"
                  @click="current.type = FunctionType.CaptureStorage"
                >Capture Storage (BETA)</button>
                <button
                  type="button"
                  class="tab"
                  :class="{ 'tab--active' : current.type === FunctionType.SendEvent }"
                  @click="current.type = FunctionType.SendEvent"
                >Send Event (BETA)</button>
                <button
                  type="button"
                  class="tab"
                  :class="{ 'tab--active' : current.type === FunctionType['GPT-3'] }"
                  @click="current.type = FunctionType['GPT-3']"
                >GPT</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="isCreate">
          <div class="col-6">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>Name</h4>
                <autocomplete
                  :items="variables"
                  id="botName"
                  v-model="current.name"
                  placeholder="Enter your function's name"
                  :rows="1"
                  spellcheck="false"
                />
                <div v-if="errors['name']" class="invalid">{{ errors['name'] }}</div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="da-input-group">
              <div class="form-group">
                <h4>Description</h4>
                <div class="form-group--inside">
                  <autocomplete
                    :items="variables"
                    name="desc"
                    id="desc"
                    v-model="current.description"
                    :rows="1"
                    placeholder="Enter a description for your function"
                  />
                  <div v-if="errors['description']" class="invalid">{{ errors['description'] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="global-box" v-if="current.type != FunctionType.Flow && !isCurrentFunctionGlobal && hasPermissionForGlobalToggle">
        <Toggle label="Global" class="toogle toogle-div" v-model="current.isGlobal"></Toggle>
        <Tooltip title="Global Functions are available for all bots in your account." placement="right"></Tooltip>
      </div>

      <section id="sql" v-if="current.type == FunctionType.SQL">
        <div class="row">
          <div class="col-12">
            <h4 class>SQL Connection String</h4>
            <div class="form-group">
              <div class="form-group--inside">
                <autocomplete
                  :items="autocompleteVariables"
                  v-model="current.connectionString"
                  placeholder="Enter your SQL Database connection string"
                  :rows="2"
                  class="code"
                  spellcheck="false"
                />
                <div type=”button” class="copybutton" v-clipboard:copy="current.connectionString">COPY</div>
              </div>
            </div>
          </div>

          <div id="command" class="col-12">
            <div class="form-group">
              <h4 class>SQL Command</h4>
              <div class="form-group--inside">
                <autocomplete
                  :items="autocompleteVariables"
                  name="Connection"
                  id="connection"
                  v-model="current.sqlCommand"
                  placeholder="Enter your SQL query"
                  :rows="6"
                  class="code"
                  spellcheck="false"
                />
                <!-- <div v-if="commandError" class="invalid">
                            Please provide SQL command
                </div>-->
                <div type=”button” class="copybutton" v-clipboard:copy="current.sqlCommand">COPY</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="rest" v-if="current.type == FunctionType.REST">
        <div class="row">
          <div :class="`col-${isCreate ? 9 : 10}`">
            <h4 class>API Path</h4>
            <div class="form-group">
              <div class="form-group--inside">
                <autocomplete
                  :items="autocompleteVariables"
                  placeholder="for example: /api/request/{id}"
                  v-model="current.url"
                  :rows="1"
                  class="code padding-input"
                  spellcheck="false"
                />
                <div type=”button” class="copybutton" v-clipboard:copy="current.url">COPY</div>
                <div v-if="urlError" class="invalid">Please provide API path</div>
              </div>
            </div>
          </div>

          <div :class="`col-${isCreate ? 3 : 2}`">
            <h4 class>Method</h4>
            <div class="form-group">
              <div class="form-group--inside">
                <select v-model="current.method" class="white-select">
                  <option class="form-control" value="get">GET</option>
                  <option class="form-control" value="post">POST</option>
                  <option class="form-control" value="put">PUT</option>
                  <option class="form-control" value="patch">PATCH</option>
                  <option class="form-control" value="delete">DELETE</option>
                </select>
                <i class="dropdown-toggle x"></i>
              </div>
            </div>
          </div>
        </div>

        <div id="headers" class="row">
          <div class="col-12">
            <div class="form-group">
              <h4 class>Request Headers</h4>
              <div class="form-group--inside">
                <autocomplete
                  :items="autocompleteVariables"
                  name="Connection"
                  id="headers"
                  v-model="current.headers"
                  :rows="4"
                  placeholder="key: value"
                  class="code"
                  spellcheck="false"
                />
                <div type=”button” class="copybutton" v-clipboard:copy="current.headers">COPY</div>
              </div>
            </div>
          </div>
        </div>

        <div id="body" class="row">
          <div class="col-12" v-if="current.method != 'get' && current.method != 'delete'">
            <div class="form-group col-3" style="padding-left: 0">
              <h4>Body Type</h4>
              <div class="form-group--inside">
                <select v-model="current.bodyType" class="white-select">
                  <option
                    :key="`rfbt-${index}`"
                    v-for="(bodyType, index) in Object.entries(RestFunctionBodyType)"
                    :value="bodyType[1]"
                    class="form-control"
                  >
                    {{ bodyType[0] }}
                  </option>
                </select>
                <i class="dropdown-toggle x"></i>
              </div>
            </div>

            <div v-if="current.bodyType === RestFunctionBodyType.Raw" class="form-group">
              <div class="form-group--inside">
                <autocomplete
                  :items="autocompleteVariables"
                  name="Connection"
                  id="body"
                  v-model="current.body"
                  :rows="8"
                  class="code"
                  spellcheck="false"
                />
              </div>
              <div type=”button” class="copybutton" v-clipboard:copy="current.body">COPY</div>
            </div>
            <div v-else-if="current.bodyType === RestFunctionBodyType.FormData || current.bodyType === RestFunctionBodyType.FormUrlEncoded">
              <FunctionParameters
                :display="true"
                :props="current.formData"
                tittle="FormData"
                inputPlaceholder="Add key"
                outputPlaceholder="Enter value"
                :autocompleteVariables="autocompleteVariables"
                namePath="key"
                valuePath="value"
                @add="addFormData"
                @remove="removeFormData"
              ></FunctionParameters>
            </div>
          </div>
        </div>
      </section>

      <section id="adaptiveCard" v-if="current.type == FunctionType.AdaptiveCard">
        <div class="row">
          <div id="adaptive-card-json-box" class="col-12">
            <div class="form-group">
              <h4 class>JSON</h4>
              <div class="form-group--inside">
                <autocomplete
                  :items="autocompleteVariables"
                  name="adaptive-card-json"
                  id="adaptive-card-json"
                  v-model="current.json"
                  :rows="16"
                  class="code"
                  spellcheck="false"
                  placeholder="Enter your Adaptive Card's JSON"
                />
                <div type=”button” class="copybutton" v-clipboard:copy="current.json">COPY</div>
              </div>
            </div>
            <Toggle
              label="Skip user input"
              tooltip="If selected, the function will not wait for user input"
              v-model="current.skipUserInput"
            ></Toggle>
          </div>
        </div>
      </section>

      <section id="code" v-if="current.type == FunctionType.Code">
        <div class="row">
          <div class="col-12">
            <h4 class>Language</h4>
            <div class="form-group">
              <div class="form-group--inside">
                <select v-model="current.language" class="white-select" :disabled="!current.botId && !isCreate">
                  <option class="form-control" value="JS">Javascript (NodeJS)</option>
                </select>
                <i class="dropdown-toggle x"></i>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="current.language == 'JS'">
            <div class="form-group--inside">
              <h4>Code</h4>
              <autocomplete
                :items="autocompleteVariables"
                required
                name="Code"
                id="code"
                v-model="current.code"
                :rows="16"
                class="code"
                placeholder="Enter your custom code"
                :disabled="!current.botId && !isCreate"
              />
              <div type=”button” class="copybutton" v-clipboard:copy="current.code">COPY</div>
            </div>
          </div>
        </div>
      </section>

      <section id="captureStorage" v-if="current.type === FunctionType.CaptureStorage">
        <CaptureStorageVariableInput
          :isFirstInput="true"
          :variable="newCaptureStorageVariable"
          @submit="addCaptureStorageVariable"
        ></CaptureStorageVariableInput>

        <CaptureStorageVariableInput
          :key="index"
          v-for="(variable, index) in current.variables"
          :variable="variable"
          @delete="deleteCaptureStorageVariable(index)"
        ></CaptureStorageVariableInput>
      </section>

      <section id="sendEvent" v-if="current.type === FunctionType.SendEvent">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <h4>
                Event
                <Tooltip title="TODO" placement="right"></Tooltip>
              </h4>
              <input type="text" placeholder="Event" v-model="current.event" />
            </div>
          </div>
        </div>
      </section>

      <section id="gpt3" v-if="current.type === FunctionType['GPT-3']">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>
                Model and Engine
                  <!-- <Tooltip title="The engine, or model, which will generate the completion. Some engines are suitable for natural language tasks, others specialize in code." placement="right"></Tooltip> -->
                </h4>

                <Select
                  :elements="Object.entries(OpenAiModel)"
                  :key="current.model"
                  keyAttr="0"
                  valueAttr="1"
                  v-model="current.model"
                  @input="onModelChange()"
                  class="white-select"
                ></Select>
                <FormGroup
                v-if="whichGptModel()==3.5"
              >
                <Select
                  :elements="Object.entries(OpenAiEngine3_5)"
                  :key="current.engine"
                  keyAttr="0"
                  valueAttr="1"
                  v-model="current.engine"
                  class="white-select"
                  style="margin-left: 40px;margin-top: -20px;"
                ></Select>
              </FormGroup>
              <!-- <FormGroup
                v-if="whichGptModel()==3"
              >
                <Select
                  :elements="Object.entries(OpenAiEngine3_0)"
                  :key="current.engine"
                  keyAttr="0"
                  valueAttr="1"
                  v-model="current.engine"
                  class="white-select"
                  style="margin-left: 40px;margin-top: -20px;"
                ></Select>
              </FormGroup> -->
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group--inside">
              <h4>Prompt</h4>
              <autocomplete
                :items="autocompleteVariables"
                placeholder=""
                v-model="current.completionsData.prompt"
                :rows="16"
                class="code padding-input"
                spellcheck="false"
              />
              <div type=”button” class="copybutton" v-clipboard:copy="current.code">COPY</div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>Temperature</h4>
                <input type="number" v-model.number="current.completionsData.temperature" min="0" max="1" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>Response length</h4>
                <input type="number" v-model.number="current.completionsData.max_tokens" min="1" max="1024" />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>Stop sequences</h4>

                <div class="row">
                  <div class="col-3" v-if="current.completionsData.stop.length < 4">
                    <input
                      v-model="newStopSequence"
                      @keyup.enter="addStopSequence"
                    />
                    <div
                      type="button"
                      class="copybutton dark"
                      @click="addStopSequence"
                    >Add</div>
                  </div>

                  <div
                    class="col-3"
                    :key="index"
                    v-for="(sequence, index) in current.completionsData.stop"
                  >
                    <input v-model="current.completionsData.stop[index]" />
                    <i
                      class="icon-delete icon-delete-stop"
                      @click="removeStopSequence(index)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>Top P</h4>
                <input type="number" v-model.number="current.completionsData.top_p" min="0" max="1" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>Frequency penalty</h4>
                <input type="number" v-model.number="current.completionsData.frequency_penalty" min="0" max="1" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <div class="form-group--inside">
                <h4>Presence penalty</h4>
                <input type="number" v-model.number="current.completionsData.presence_penalty" min="0" max="1" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="variables-title">
        <h4 class>Variables</h4>
      </div>
      <!-- start -->
      <button
        class="inputs btn btn--white"
        type="button"
        @click="showInputVar = !showInputVar"
        :class="{'show-input': showInputVar}"
      >
        <i class="icon icon-input"></i>Input Variables
      </button>
      <button
        v-if="current.type !== FunctionType.SendEvent"
        type="button"
        class="inputs btn btn--white"
        @click="showOutputVar = !showOutputVar"
        :class="{'show-input': showOutputVar}"
      >
        <i class="icon icon-output"></i>Output Variables
      </button>

      <FunctionParameters
        :display="showInputVar"
        :isGlobal="current.isGlobal"
        :props="current.inputs"
        tittle="Input Variables"
        inputPlaceholder="Add a new input name"
        outputPlaceholder="Enter a default value"
        :autocompleteVariables="autocompleteVariables"
        namePath="name"
        valuePath="defaultValue"
        @add="addInput"
        @remove="removeInput"
      ></FunctionParameters>

      <FunctionParameters
        :display="showOutputVar"
        :isGlobal="false"
        :props="current.outputs"
        tittle="Output Variables"
        inputPlaceholder="Add a new output name"
        outputPlaceholder="Enter object path or a default value"
        :autocompleteVariables="autocompleteVariables"
        namePath="variableName"
        valuePath="propertyPath"
        @add="addOutput"
        @remove="removeOutput"
      ></FunctionParameters>

      <!-- end -->

      <footer class="modal-footer-button" v-show="isCreate">
        <button class="btn btn--purple gradient" @click="createFunction" type="button">
          Create {{current.type === FunctionType.Flow ? 'Sub' : 'Function'}}
          <i class="icon icon-arrow-right"></i>
        </button>
      </footer>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Autocomplete from '../../../helpers/Autocomplete.vue'
import Toggle from '../../../helpers/Toggle.vue'
import permissionsService from '../../../../services/tenants/permissions.service'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import Tooltip from '../../../helpers/Tooltip.vue'
import FunctionParameters from './FunctionParameters.vue'
import CaptureStorageVariableInput from './CaptureStorageVariableInput.vue'
import {
  CaptureStorageFunctionVariable
} from '../../../../../../common/interfaces/capture-storage-function-variable.interface.ts'
import { FunctionType } from '../../../../../../common/enums/bot/functions/function-type.enum'
import { RestFunctionBodyType } from '../../../../../../common/enums/bot/functions/rest-function-body-type'
import Select from '../../../helpers/Select.vue'
import { OpenAiEngine3_0 } from '../../../../../../common/enums/open-ai-engine3_0'
import { OpenAiEngine3_5 } from '../../../../../../common/enums/open-ai-engine3_5'
import { OpenAiEngine4 } from '../../../../../../common/enums/open-ai-engine4'
import { OpenAiModel } from '../../../../../../common/enums/open-ai-models'
import FormGroup from '@/components/helpers/FormGroup.vue'
import popupService from '@/services/popup.service'
// import { threadId } from 'worker_threads'

@Component({
  components: { FormGroup, Toggle, Autocomplete, Tooltip, FunctionParameters, CaptureStorageVariableInput, Select }
})
export default class FunctionEditor extends Vue {
  @Prop({ type: Array, default: () => [] }) variables;
  @Prop({ type: Object, default: () => { return {} } }) data;
  @Prop({ type: Boolean, default: () => { return false } }) isCurrentFunctionGlobal;
  @Prop({ type: Boolean, default: true }) isCreate;
  @Prop({ type: Object, default: () => { return {} } }) errors;
  @Prop({ type: String, default: () => { return '' } }) botName;
  @Prop({ type: String }) selectedFilter;
  @Prop({ type: Boolean, default: true }) showType;
  private showInputVar = false;
  private showOutputVar = false;

  private hasPermissionForGlobalToggle = false;
  FunctionType = FunctionType;
  RestFunctionBodyType = RestFunctionBodyType;

  /* eslint-disable */
  private current = {
    type: 'sql',
    name: '',
    group: '',
    description: '',
    model: '',
    engine: '',
    outputs: [
      {
        propertyPath: '',
        variableName: ''
      }
    ],
    inputs: [
      {
        name: '',
        defaultValue: ''
      }
    ],
    connectionString: '',
    sqlCommand: '',
    method: 'get',
    language: 'JS',
    url: '',
    headers: '',
    code: '',
    bodyType: RestFunctionBodyType.Raw,
    body: '',
    formData: [],
    isGlobal: false,
    botId: '',
    variables: [],
    event: '',
    completionsData: { stop: [] }
  };

  OpenAiEngine3_0 = OpenAiEngine3_0;
  OpenAiEngine3_5 = OpenAiEngine3_5;
  OpenAiEngine4 = OpenAiEngine4
  newStopSequence = ''
  OpenAiModel = OpenAiModel;
  whichGptModel (){
      if(this.current.model == OpenAiModel['GPT 3.5']){
        this.current.model=OpenAiModel['GPT 3.5']
        return 3.5;
      }else if(this.current.model == OpenAiModel['GPT 3.0']){
        this.current.model=OpenAiModel['GPT 3.0']
        return 3;
     }else if(this.current.model == OpenAiModel['GPT 4']){
        this.current.model=OpenAiModel['GPT 4']
        return 4;
     }else if(this.current.model == OpenAiModel.GPT_4o){
        this.current.model=OpenAiModel.GPT_4o
        return OpenAiModel.GPT_4o;
     }}

  onModelChange() {
    if (this.current.model == OpenAiModel['GPT 3.5']) {
      this.current.model = OpenAiModel['GPT 3.5'];
      this.current.engine = OpenAiEngine3_5.Turbo;
    } else if (this.current.model == OpenAiModel['GPT 4']) {
      this.current.model = OpenAiModel['GPT 4'];
      this.current.engine = OpenAiEngine4['GPT-4'];
    } else if (this.current.model == OpenAiModel['GPT 3.0']) {
      this.current.model = OpenAiModel['GPT 3.0'];
      this.current.engine = OpenAiEngine3_0.Davinci;
    } else if (this.current.model == OpenAiModel.GPT_4o) {
      this.current.model = OpenAiModel.GPT_4o;
      this.current.engine = OpenAiEngine4.GPT_4o;
    }
  }

      figureOutGPTModel() {
      if(this.current.engine===OpenAiEngine3_0.Ada ||this.current.engine===OpenAiEngine3_0.Davinci ||this.current.engine===OpenAiEngine3_0.Curie||this.current.engine===OpenAiEngine3_0.Babbage){
        return OpenAiModel['GPT 4']
      }else if(this.current.engine===OpenAiEngine3_5.Turbo||this.current.engine===OpenAiEngine3_5['Text-Davinci-2']||this.current.engine===OpenAiEngine3_5['Text-Davinci-3']){
        return OpenAiModel['GPT 3.5']
      }else if(this.current.engine==OpenAiEngine4['GPT-4']){
        return OpenAiModel['GPT 4']
      }else if(this.current.engine==OpenAiEngine4.GPT_4o){
        return OpenAiEngine4.GPT_4o
      }
    }


  addStopSequence () {
    this.current.completionsData.stop.unshift(this.newStopSequence)
    this.newStopSequence = ''
  }
  removeStopSequence (index: number) {
    this.current.completionsData.stop.splice(index, 1)
  }

  // @ts-ignore
  newCaptureStorageVariable: CaptureStorageFunctionVariable = {}

  addCaptureStorageVariable () {
    this.current.variables.push(this.newCaptureStorageVariable)
    // @ts-ignore
    this.newCaptureStorageVariable = {}
  }

  deleteCaptureStorageVariable (index: number) {
    popupService.deletePopupEvent(
      'variable ' + this.current.variables[index], () => {
        this.current.variables.splice(index, 1)
      })
  }

  private urlError = false;
  @Watch('data')
  newData (_) {
    this.$emit('update', this.current)
  }

  get autocompleteVariables () {
    return this.variables.concat(this.current.inputs.filter(i => !!i.name.trim()).map(c => c.name))
  }

  async mounted () {
    this.initialize()
    this.checkForInputsAndOutputs()
    this.hasPermissionForGlobalToggle = await permissionsService.hasPermission(this.botName, Permissions.ManageGlobalBotData)
  }

  initialize () {
    this.current = this.data
    this.current.model=this.figureOutGPTModel()// because of uknown reason the GPT is not being saved so I just figure it out based on the engine
    if (!this.current || !this.current.type) {
      if (this.$route.params.tab == 'subs') this.current.type = FunctionType.Flow
      this.current = {
        type: this.current.type || FunctionType.SQL,
        name: '',
        group: '',
        description: '',
        model: OpenAiModel['GPT 4'],
        engine: OpenAiEngine4['GPT-4'],
        outputs: [],
        inputs: [],
        connectionString: '',
        sqlCommand: '',
        method: this.current.method || 'get',
        language: 'JS',
        url: '',
        headers: '',
        code: '',
        bodyType: RestFunctionBodyType.Raw,
        body: '',
        formData: [],
        isGlobal: false,
        botId: '',
        variables: [],
        event: '',
        completionsData: { stop: [] }
      }
    }
    this.$emit('update', this.current)
  }

  private createFunction () {
    this.$emit('createFunction')
  }

  private checkForInputsAndOutputs () {
    this.showInputVar = false
    this.showOutputVar = false

    if (this.current.outputs.length > 1) {
      this.showOutputVar = true
    }

    if (this.current.inputs.length > 1) {
      this.showInputVar = true
    }
  }

  addInput (prop) {
    if (!this.current.inputs) this.current.inputs = []
    if (this.current.inputs.some(i => i.name == prop.name)) return

    this.current.inputs.push({ name: prop.name, defaultValue: prop.value })
  }

  removeInput (index) {
    popupService.deletePopupEvent('input variable ' + this.current.inputs[index].name, () => {
      this.current.inputs.splice(index, 1)
    });
  }

  addOutput (prop) {
    if (!this.current.outputs) this.current.outputs = []

    const tempOutput = { variableName: prop.name || '', propertyPath: prop.value || '' }
    const uniqueIdentifier = `${this.current.name} - `
    const tempOutputWithUniqueIdentifier = `${uniqueIdentifier}${tempOutput.variableName}`

    if (this.current.outputs.filter(o => o.variableName == tempOutputWithUniqueIdentifier || o.variableName == tempOutput.variableName).length > 1) return

    if (!tempOutput.variableName.includes(uniqueIdentifier)) {
      this.current.outputs.push({ propertyPath: tempOutput.propertyPath, variableName: tempOutputWithUniqueIdentifier })
    }
  }

  removeOutput (index) {
    popupService.deletePopupEvent('output variable ' + this.current.outputs[index].variableName, () => {
      this.current.outputs.splice(index, 1)
    })
  }

  addFormData (prop) {
    if (!this.current.formData) this.current.formData = []
    if (this.current.formData.some(o => o.key == prop.name)) return

    this.current.formData.push({ key: prop.name, value: prop.value })
  }

  removeFormData (index) {
    this.current.inputs.splice(index, 1)
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
.variables-title {
  width: 100%;
  margin-bottom: 15px;
  border-bottom: 1px solid $light-blue;
}

footer button {
  margin-top: 15px;
  float: right;
}

.content-intents .content-intents__main form textarea {
  resize: auto;
}

.icon-delete {
  position: relative;
}

.main-fill-color-button {
  margin-bottom: 15px;
}

.variables {
  display: inline-flex;
  margin-bottom: 20px;
  .variable {
    background-color: $default-dark;
    color: $default;
    width: 120px;
    border-radius: 15px;
    padding: 5px;
    text-align: center;
    margin-right: 10px;
  }
}

.row {
  &.margin-10 {
    margin-bottom: -5px;
  }
}

#type {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 10px;
}

.modal-footer-button {
  margin-top: 20px;
}

.show-input {
  display: none;
}

.h-40vh {
  height: 40vh !important;
}

.global-box {
  display: flex;

  .tooltip-icon {
    margin-left: 5px;
  }
}

.padding-input {
  input {
    padding-right: 55px !important;
  }
}

.icon-delete-stop {
  top: -21px !important;
  right: 10px !important;
}
</style>
