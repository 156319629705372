export const availableOperations = [
  {
    id: 1,
    name: 'Equals'
  },
  {
    id: 2,
    name: 'Exists (Not Empty)',
    hasDefault: false,
    hasCaseSensitivity: false
  },
  {
    id: 3,
    name: 'Math Comparator',
    hasCaseSensitivity: false
  },
  {
    id: 4,
    name: 'In Set'
  },
  {
    id: 5,
    name: 'Contains'
  },
  {
    id: 6,
    name: 'Is of type'
  },
  {
    id: 7,
    name: 'Is an entity'
  },
  {
    id: 9,
    name: 'Is similar'
  },
  {
    id: 10,
    name: 'Which channel',
    hasCaseSensitivity: false,
    hasCondition: false
  },
  {
    id: 11,
    name: 'Check user',
    hasCaseSensitivity: false,
    hasCondition: false,
    hasDefault: false
  },
  {
    id: 12,
    name: 'RegEx',
    hasCaseSensitivity: false
  }
]

export const mathOperations = [
  {
    id: 1,
    name: 'Smaller Than'
  },
  {
    id: 2,
    name: 'Bigger Than'
  },
  {
    id: 3,
    name: 'In range'
  }
]
