<template>
  <div class="form-group form-group--options">
    <div>
      <div class="label">of elements</div>
      <div v-for="(type, index) in setElements" class="set-elements" :key="index">
        <div>
          <autocomplete
            :items="getVariables()"
            v-model="currentBlock.customData.similarsSet[index].value"
            type="text"
            :placeholder="'Input'"
          ></autocomplete>
        </div>
        <i class="icon-delete" @click="removeSimilarFromSet(index)"></i>

        <div class="row">
          <div class="col-5">
            <input
              id="form-control-similarity-left"
              type="number"
              min="20"
              max="100"
              class="form-control"
              v-model.number="currentBlock.customData.similarsSet[index].similarity"
              @change="checkSimilarity(index)"
            />
          </div>
          <div class="label similarity">% Similarity</div>
        </div>
      </div>
      <div class="option-button">
        <div class="btn btn--white dialogmanager" @click="addSimilarToSet">
          Add new element
          <i class="icon icon-add"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IIsSimilarBlock } from '../../../../../../../../../common/blocks/connector/is-similar-block.interface'
import autocomplete from '../../../../../../helpers/Autocomplete.vue'
import ConditionalComponent from '../../../../modules/blocks/ConditionalComponent'

@Component({
  components: {
    autocomplete
  }
})
export default class Autocorrect extends Vue {
  private setElements: Array<string> = null;

  @Prop({ default: () => { return {} } }) readonly currentBlock!: ConditionalComponent<
    IIsSimilarBlock
  >;

  @Prop({ default: () => [] }) readonly variables: any[];

  @Emit()
  updateLabel () {}

  mounted () {
    this.updateLabel()

    if (!this.currentBlock.customData.similarsSet) {
      this.currentBlock.customData.similarsSet = []
    }
    this.setElements = this.currentBlock.customData.similarsSet.map(
      e => e.type
    )
  }

  addSimilarToSet () {
    this.currentBlock.customData.similarsSet.push({
      type: 'raw',
      value: '',
      similarity: 70
    })
    this.setElements.push('raw')
  }

  removeSimilarFromSet (index: number) {
    this.currentBlock.customData.similarsSet.splice(index, 1)
    this.setElements.splice(index, 1)
  }

  checkSimilarity (index: number) {
    let similarity = this.currentBlock.customData.similarsSet[index].similarity

    if (similarity < 20) similarity = 20
    if (similarity > 100) similarity = 100
    this.currentBlock.customData.similarsSet[index].similarity = similarity
  }

  getVariables () {
    const result = []

    for (const entity of this.variables) {
      result.push(entity.name)
      if (entity.roles) { result.push(...entity.roles.map(e => entity.name + ':' + e.name)) }
    }

    return result
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../../assets/scss/variables";

#set-to-equal {
  margin-bottom: 10px;
}

.label {
  &.similarity {
    font-size: 16px;
    padding: 12px 12px 12px 0;
    color: $default-dark;
    float: left;
  }
}

.icon-delete {
  position: absolute;
  top: 17px;
  right: 14px;
}
</style>
