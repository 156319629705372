import BlockStorage from './BlockStorage'

export class ErrorHandlingService {
    private redBlocks = [];
    private expandedErrorMessages: {[blockId: string]: boolean} = {}

    private readonly strokeColor = '#bc204b'
    private readonly fillColor = '#f2d2db'

    private orangeColor = '#CF6709'

    private longestErrorMessage = ''

    private errorsObject: {[blockId: string]: any[]} = {}

    private readonly dropdownArrowOpened = '2 10, 10 6, 2 2'

    private readonly dropdownArrowClosed = '6 10, 10 2, 2 2'

    markAsError (errors: any[], blockStorage: BlockStorage) {
      errors = errors.filter(e => !!e.blockId)
      this.redBlocks = []
      this.expandedErrorMessages = {}
      this.errorsObject = {}
      this.setErrorBorder(errors, blockStorage)
      this.longestErrorMessage = errors.map(e => e.message).reduce((a, b) => a.length < b.length ? b : a, '')
      this.parseErrors(errors)
      this.displayErrors(blockStorage)
    }

    private setErrorBorder (errors: any[], blockStorage: BlockStorage) {
      for (const error of errors) {
        try {
          const block = blockStorage.findById(error.blockId).gfx
          const childElement = block.firstChild.firstChild as HTMLElement
          this.redBlocks.push(childElement)
          childElement.style.setProperty('stroke', this.orangeColor, 'important')
        } catch (_) { /* */ }
      }
    }

    private parseErrors (errors: any[]) {
      errors.forEach(e => {
        if (this.errorsObject[e.blockId]) {
          this.errorsObject[e.blockId].push(e)
        } else {
          this.errorsObject[e.blockId] = [e]
        }
      })
    }

    markAsSuccess () {
      for (const redBlock of this.redBlocks) {
        redBlock.style.setProperty('stroke', '#979797', 'important')
      }
      this.redBlocks = []
      this.hideErrorBoxes()
    }

    private displayErrors (blockStorage: BlockStorage) {
      blockStorage.allComponents().forEach(c => {
        const canvas = blockStorage.bpmn.get('canvas')
        if (this.errorsObject[c.id]) {
          canvas.addMarker(c.element, 'flowerror')
        } else {
          canvas.removeMarker(c.element, 'flowerror')
        }
      })

      // Remove all old errors
      this.hideErrorBoxes()

      const svg = document.getElementsByClassName('flowerror')

      for (const markedEl of svg) {
        const bpmnElement = blockStorage.bpmn
          .get('elementRegistry')
          .get(markedEl.getAttribute('data-element-id'))
        if (bpmnElement && Object.keys(this.errorsObject).length != 0) {
          markedEl.appendChild(this._createError(bpmnElement.height, bpmnElement.id))
          markedEl.appendChild(this._createPolygon(bpmnElement.height, bpmnElement.width, bpmnElement.id))
          markedEl.appendChild(this._createErrorDropdown(bpmnElement.height, bpmnElement.id))
          markedEl.appendChild(this._createCloseButton(bpmnElement.height, bpmnElement.id))

          this.showOneError(bpmnElement.id, bpmnElement.height)
        }
      }
    }

    private _createError (blockHeight: number, blockId: string) {
      const errorRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
      errorRect.setAttribute('x', '0')
      errorRect.setAttribute('y', `${20 + blockHeight}`)
      errorRect.setAttribute('width', `${this.longestErrorMessage.length * 8}`)
      errorRect.setAttribute('height', '63')
      errorRect.setAttribute('rx', '5')

      errorRect.classList.add('errorindicating-block')
      errorRect.id = `error-${blockId}`

      errorRect.style.stroke = this.strokeColor
      errorRect.style.strokeWidth = '1px'
      errorRect.style.fill = this.fillColor
      errorRect.style.marginTop = '20px'

      return errorRect
    }

    private _createPolygon (blockHeight: number, blockWidth: number, blockId: string) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      svg.setAttribute('x', `${-195 + Math.abs(blockWidth / 3)}`)
      svg.setAttribute('y', `${blockHeight + 5.62}`)
      svg.setAttribute('width', '400')
      svg.setAttribute('height', '15')
      svg.id = `polygon-${blockId}`
      const polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
      polygon.setAttribute('points', '200,10 300,60 10,110')

      svg.classList.add('errorindicating-polygon')

      polygon.style.stroke = this.strokeColor
      polygon.style.strokeWidth = '1px'
      polygon.style.fill = this.fillColor

      svg.appendChild(polygon)

      return svg
    }

    private _createCloseButton (blockHeight: number, blockId: string) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      svg.setAttribute('width', '10')
      svg.setAttribute('height', '10')
      svg.setAttribute('x', `${this.longestErrorMessage.length * 8 - 20}`)
      svg.setAttribute('y', `${35 + blockHeight}`)
      svg.id = `close-${blockId}`

      const x = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      x.setAttribute('d', 'M10 8.412l-3.465-3.42L9.952 1.54 8.412 0 4.99 3.466 1.528.048 0 1.575 3.467 5.01.047 8.472 1.576 10l3.433-3.466L8.46 9.952z')
      x.style.fill = this.orangeColor

      svg.classList.add('errorindicating-close-button')

      const clickHandler = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
      clickHandler.setAttribute('width', '100%')
      clickHandler.setAttribute('height', '100%')
      clickHandler.setAttribute('fill-opacity', '0.0')
      clickHandler.style.cursor = 'pointer'

      svg.appendChild(x)
      svg.appendChild(clickHandler)

      svg.addEventListener('click', () => this.hideErrorBox(blockId))

      return svg
    }

    private _createErrorDropdown (blockHeight: number, blockId: string) {
      const dropdown = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      dropdown.id = `dropdown-${blockId}`
      dropdown.classList.add('errorindicating-dropdown')

      const header = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      header.setAttribute('width', '200')
      header.setAttribute('height', '200')
      header.setAttribute('x', '25')
      header.setAttribute('y', `${50 + blockHeight}`)
      header.innerHTML = 'Errors'
      header.style.fontWeight = '900'
      header.style.fontSize = '15px'
      header.style.fill = this.strokeColor

      if (this.errorsObject[blockId]?.length > 1) {
        const arrow = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        const arrowBody = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
        arrow.setAttribute('width', '10')
        arrow.setAttribute('height', '10')
        arrow.setAttribute('x', '10')
        arrow.setAttribute('y', `${39 + blockHeight}`)
        arrowBody.setAttribute('points', this.dropdownArrowClosed)
        arrowBody.style.fill = this.strokeColor
        arrow.appendChild(arrowBody)
        arrowBody.id = `toggle-arrow-${blockId}`

        const clickHandler = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        clickHandler.setAttribute('width', '100%')
        clickHandler.setAttribute('height', '100%')
        clickHandler.setAttribute('fill-opacity', '0.0')
        clickHandler.style.cursor = 'pointer'
        clickHandler.addEventListener('click', () => this.toggleErrorMessages(blockId, blockHeight))

        arrow.appendChild(clickHandler)

        dropdown.appendChild(arrow)
      } else {
        header.setAttribute('x', '14')
      }
      dropdown.appendChild(header)

      const itemsDropdown = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      itemsDropdown.id = `items-dropdown-${blockId}`

      dropdown.appendChild(itemsDropdown)
      return dropdown
    }

    private _createErrorMessage (errorText: string, lastItemToTopWidth: number, blockHeight: number, additionalText?: string) {
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      text.setAttribute('x', '14')
      text.setAttribute('y', `${lastItemToTopWidth + blockHeight}`)
      text.style.fill = this.strokeColor
      text.style.fontSize = '13px'
      text.innerHTML = errorText

      if (additionalText) {
        const additionalTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
        additionalTextElement.style.fill = this.orangeColor
        additionalTextElement.style.fontSize = '13px'
        additionalTextElement.innerHTML = ' ' + additionalText
        text.appendChild(additionalTextElement)
      }

      return text
    }

    private hideErrorBoxes () {
      Object.keys(this.errorsObject).forEach(key => this.hideErrorBox(key))
    }

    private hideErrorBox (blockId: string) {
      this.clearOldMessages(blockId)
      const elements: HTMLElement[] = [document.getElementById(`dropdown-${blockId}`), document.getElementById(`close-${blockId}`), document.getElementById(`polygon-${blockId}`), document.getElementById(`error-${blockId}`)]
      elements.forEach(e => { if (e) e.remove() })
    }

    private toggleErrorMessages (blockId: string, blockHeight: number) {
      this.clearOldMessages(blockId)
      document.getElementById(`toggle-arrow-${blockId}`).setAttribute('points', this.expandedErrorMessages[blockId] ? this.dropdownArrowClosed : this.dropdownArrowOpened)
      this.expandedErrorMessages[blockId] = !this.expandedErrorMessages[blockId]

      if (this.expandedErrorMessages[blockId]) {
        this.showAllErrors(blockId, blockHeight)
      } else {
        this.showOneError(blockId, blockHeight)
      }
    }

    private clearOldMessages (blockId: string) {
      const el = document.getElementById(`items-dropdown-${blockId}`)
      if (el) el.innerHTML = ''
    }

    private showOneError (blockId: string, blockHeight: number) {
      this.clearOldMessages(blockId)
      document.getElementById(`items-dropdown-${blockId}`).appendChild(this._createErrorMessage(this.errorsObject[blockId][0].message, 70, blockHeight, this.errorsObject[blockId]?.length > 1 ? `+ ${this.errorsObject[blockId].length - 1} error` : undefined))
      document.getElementById(`error-${blockId}`).setAttribute('height', '63')
    }

    private showAllErrors (blockId: string, blockHeight: number) {
      const errors = this.errorsObject[blockId]
      let y = 50
      errors.forEach(e => {
        const message = this._createErrorMessage(e.message, y += 20, blockHeight)
        document.getElementById(`items-dropdown-${blockId}`).appendChild(message)
      })
      document.getElementById(`error-${blockId}`).setAttribute('height', `${50 + errors.length * 20}`)
    }
}

export default new ErrorHandlingService()
