<template>
  <Modal title="Import Intent" :show="true" @close="$emit('close')" :smallModal="true">
    <ImportItem
      :file="file"
      mode="intent"
      :item="intentData"
      v-bind:name.sync="newName"
      :isTaken="isTaken"
      @importItem="importIntent"
      @loadItem="loadIntent"
    ></ImportItem>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import ImportItem from '../ImportItem.vue'
import intentService from '../../../../services/bots/intents.service'
import Modal from '../../../helpers/Modal.vue'

@Component({
  components: {
    ImportItem,
    Modal
  }
})
export default class ImportIntent extends Vue {
  @Prop() readonly file: File;
  @Prop({ default: () => [] }) readonly intents: any[];
  @Prop({ default: () => [] }) readonly entities: any[];

  private intentData = null;
  private newName = '';
  private isTaken = false;

  async loadIntent () {
    this.intentData = await intentService.validateImport(this.file)
    this.newName = this.intentData.intent.name
  }

  importIntent () {
    if (this.intents.some(i => i.name === this.newName)) {
      this.isTaken = true
      return
    }
    this.isTaken = false
    this.$emit('import', {
      file: this.file,
      intentName: this.newName
    })
  }
}
</script>
