/**
 * OpenAI Text To Speech supported languages https=//platform.openai.com/docs/guides/text-to-speech/supported-languages
 * ...follows Whisper https=//github.com/openai/whisper?tab=readme-ov-file#available-models-and-languages
 * ...which has this list https=//github.com/openai/whisper/blob/main/whisper/tokenizer.py
 **/

export enum OpenAITTSLanguage {
    "Afrikaans"="Afrikaans",
    "Arabic"="Arabic",
    "Armenian"="Armenian",
    "Azerbaijani"="Azerbaijani",
    "Belarusian"="Belarusian",
    "Bosnian"="Bosnian",
    "Bulgarian"="Bulgarian",
    "Catalan"="Catalan",
    "Chinese"="Chinese",
    "Croatian"="Croatian",
    "Czech"="Czech",
    "Danish"="Danish",
    "Dutch"="Dutch",
    "English"="English",
    "Estonian"="Estonian",
    "Finnish"="Finnish",
    "French"="French",
    "Galician"="Galician",
    "German"="German",
    "Greek"="Greek",
    "Hebrew"="Hebrew",
    "Hindi"="Hindi",
    "Hungarian"="Hungarian",
    "Icelandic"="Icelandic",
    "Indonesian"="Indonesian",
    "Italian"="Italian",
    "Japanese"="Japanese",
    "Kannada"="Kannada",
    "Kazakh"="Kazakh",
    "Korean"="Korean",
    "Latvian"="Latvian",
    "Lithuanian"="Lithuanian",
    "Macedonian"="Macedonian",
    "Malay"="Malay",
    "Marathi"="Marathi",
    "Maori"="Maori",
    "Nepali"="Nepali",
    "Norwegian"="Norwegian",
    "Persian"="Persian",
    "Polish"="Polish",
    "Portuguese"="Portuguese",
    "Romanian"="Romanian",
    "Russian"="Russian",
    "Serbian"="Serbian",
    "Slovak"="Slovak",
    "Slovenian"="Slovenian",
    "Spanish"="Spanish",
    "Swahili"="Swahili",
    "Swedish"="Swedish",
    "Tagalog"="Tagalog",
    "Tamil"="Tamil",
    "Thai"="Thai",
    "Turkish"="Turkish",
    "Ukrainian"="Ukrainian",
    "Urdu"="Urdu",
    "Vietnamese"="Vietnamese",
    "Welsh"="Welsh"
}

// See backend node_modules OpenAI.Audio.SpeechCreateParams
interface SpeechCreateParams {
    /**
     * The text to generate audio for. The maximum length is 4096 characters.
     */
    input: string;

    /**
     * One of the available [TTS models](https://platform.openai.com/docs/models/tts):
     * `tts-1` or `tts-1-hd`
     */
    model: (string & {})// | SpeechModel;

    /**
     * The voice to use when generating the audio. Supported voices are `alloy`,
     * `echo`, `fable`, `onyx`, `nova`, and `shimmer`. Previews of the voices are
     * available in the
     * [Text to speech guide](https://platform.openai.com/docs/guides/text-to-speech/voice-options).
     */
    voice: 'alloy' | 'echo' | 'fable' | 'onyx' | 'nova' | 'shimmer';

    /**
     * The format to audio in. Supported formats are `mp3`, `opus`, `aac`, `flac`,
     * `wav`, and `pcm`.
     */
    response_format?: 'mp3' | 'opus' | 'aac' | 'flac' | 'wav' | 'pcm';

    /**
     * The speed of the generated audio. Select a value from `0.25` to `4.0`. We use .3 to 4
     * `1.0` is the default.
     */
    speed?: number;
}

// See https://platform.openai.com/docs/guides/text-to-speech/quickstart
export const OpenAIVoices:Array<[string, SpeechCreateParams['voice']]> = [
    ["Alloy (male)", "alloy"],
    ["Echo (male)", "echo"],
    ["Fable (male)", "fable"],
    ["Onyx (male)", "onyx"],
    ["Nova (female)", "nova"],
    ["Shimmer (female)", "shimmer"]
]

export const OpenAIreponseFormats:Array<[string, SpeechCreateParams['response_format']]> = [
    // [0: --Label on screen--, 1: --value in database-- ]
    ["mp3", "mp3"],
    ["opus", "opus"],
    ["aac", "aac"],
    ["flac", "flac"],
    ["wav", "wav"],
    ["pcm", "pcm"]
]