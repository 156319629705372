import axios from 'axios'

export class GoogleService {
  private readonly url: string;
  constructor (botName: string) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/google/${botName}`
  }

  async getProjectId () {
    const res = await axios.get(`${this.url}/projectId`)
    return res.data.projectId
  }

  async getCreateProjectUrl (): Promise<string> {
    const res = await axios.get(`${this.url}/configuration/url`)
    return res.data.url
  }

  createProjectForUser (authCode: string, configurationKey: string) {
    return axios.post(`${this.url}/project/user`, { authCode, configurationKey })
  }

  createProjectForBlits () {
    return axios.post(`${this.url}/project`)
  }

  deleteProject () {
    return axios.delete(`${this.url}/project`)
  }
}
