import { IValidationBlock } from '../../../../../../../../../common/blocks/action/validation-block.interface'
import { IBlockConnector } from '../../../../../../../../../common/blocks/block-connector.interface'
import ConditionalComponent from '../../ConditionalComponent'
import UndefinedBlockComponent from '../../UndefinedBlockComponent'

export default class ValidationBlockComponent extends ConditionalComponent<IValidationBlock> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)

    this.connector = new UndefinedBlockComponent({ businessObject: { } }, null, null, this.customData.connector || {})
  }

  serializeData () {
    this.customData.connector = this.connector.customData
    super.serializeData()
  }

  connector: UndefinedBlockComponent<IBlockConnector>
}
