import { getFieldValue } from "./object.helper";
import { FlowEntity } from "../interfaces/flow-entity.interface";
import { IComputedVariable } from '../interfaces/computed-variable.interface'

export type NameValuePair = { [name: string]: any };

export enum BracketType {

    /** The braces (curly brackets) - {...} */
    Curly,

    /** The square (box brackets) - [...] */
    Square,

    /** The parentheses (round brackets) - (...) */
    Round,

    /** The chevrons (angled brackets) - <...> */
    Angled
}

/** Builds the regex recognizing variable placeholders
 *
 * [Cities][0]
 * The full match will be the full match ex: "[Cities][0]"
 * The 1-th group will be only the text within the brackets ex: "[Cities]"
 * The 2-th group will be only the text within the brackets ex: "Cities"
 * The 3-th group will be only the text within the brackets if transformation is present ex. "canonical" or "role"
 * The 4-th group will be only the text within the brackets if transformation is present ex. "canonical" or "role"
 */
export function* getPlaceholderCouples(text: string, bracketType = BracketType.Square) {
    const bracketPattern = chooseBracket(bracketType);
    const linkPattern = '\\((.*)\\)';
    const escapePattern = '(?:[\\\\])?';

    const fullPattern = `${escapePattern}${bracketPattern}(${bracketPattern}|${chooseBracket(BracketType.Round)}|${linkPattern})*`;

    const regex = new RegExp(fullPattern, "g");

    let nextMatch = regex.exec(text);

    while (nextMatch != null) {
        let [fullMatch, varName, args, property, transformName, link] = nextMatch;

        yield { fullMatch, varName, args, property, transformName, link };

        nextMatch = regex.exec(text);
    }
}

/**
 * 
 * Currently only canonical function to replace the synonym with a canonical form
 */
export function handleTransformation(entityName: string, transformationName: string, entities: FlowEntity[], value?: string, entityIndex = 0) {
    let found = [];
    if (value) {
        found = entities.filter(e => (e.entity === entityName && e.value == value) || e.entity.includes('builtin.' + entityName));
    } else {
        found = entities.filter(e => e.entity === entityName || e.entity.includes('builtin.' + entityName));
    }

    if (!found || found.length < 1) return undefined;

    switch (transformationName) {
        case "canonical": return found[entityIndex].resolution!.values[0];
        case "role": return (found[entityIndex] as any).dbRoles[0];
    }
}

export function symplifyVariablesAndObjects(values: NameValuePair): NameValuePair {
    let simplyfied: NameValuePair = {};
    for (let key of Object.keys(values)) {
        if (values[key] && values[key].variables) {
            simplyfied[key] = {};
            Object.keys(values[key].variables).forEach(v => {
                simplyfied[key][v] = values[key].variables[v].value;
            });
        } else {
            if (values[key] || values[key] != null) {
                simplyfied[key] = values[key] instanceof Number ? String(values[key]) : values[key].value || values[key];
            }

        }
    }

    return simplyfied;
}

export function replaceCouplesWithValues(text: string, values: NameValuePair, entities: FlowEntity[] = [], bracketType = BracketType.Square) {
    const matches = getPlaceholderCouples(text, bracketType);
    let result: string | any = text;

    values = symplifyVariablesAndObjects(values);

    for (const match of matches) {
        let newValue: any;
        const { fullMatch, varName, property, transformName, link } = match;

        // Check if the variable is escaped. If so, remove the escaping character
        if (fullMatch.startsWith('\\')) {
            newValue = fullMatch.substring(1);
        } else if (link) {
            const replacedLink = replaceCouplesWithValues(link, values, entities, bracketType);
            newValue = fullMatch.replace(link, replacedLink);
        } else if (!property && transformName) {
            const indexMatch = /\[([\d]+)\]/g.exec(fullMatch);  // Check if there is an index in the middle: [Cities][1](canonical)
            const index = indexMatch ? Number(indexMatch[1]) : 0;
            newValue = handleTransformation(varName, transformName, entities, undefined, index);
        } else {
            // Check if string is array
            try {
                let arr = values[varName];
                if (!Array.isArray(arr))
                    arr = JSON.parse(values[varName]);
                if (!Array.isArray(arr))
                    throw 'Not an array';

                newValue = getFieldValue(property, arr);
                
                // Workaround for computed variables (should be refactored)
                if (newValue && typeof newValue['__computed_type__' as keyof IComputedVariable] == 'string' && typeof newValue['getContent' as keyof IComputedVariable] == 'function') {
                    return newValue as IComputedVariable;
                }
                    
                newValue = JSON.stringify(newValue);
                newValue = entities.find(e => e.entity === varName)!.value || newValue;
            } catch (err) {
                let entity = entities.find(e => e.entity.startsWith('builtin.') && e.entity.includes(varName))

                let obj: any;
                try {
                    obj = JSON.parse(values[varName]);
                } catch (err) { }

                newValue = getFieldValue(property || "", obj ?? values[varName] ?? (entity && entity.value ? entity.value : undefined));                
                
                // Workaround for computed variables (should be refactored)
                if (newValue && typeof newValue['__computed_type__' as keyof IComputedVariable] == 'string' && typeof newValue['getContent' as keyof IComputedVariable] == 'function') {
                    return newValue as IComputedVariable;
                }
                
                newValue = typeof newValue === 'string' || newValue instanceof String ? newValue : JSON.stringify(newValue);

                if (newValue == undefined) {
                    let entity = entities.find(e => e.entity == varName);
                    newValue = entity ? entity.value : undefined;
                }
            }
        }

        newValue = newValue instanceof Array && newValue.length ? newValue[0] : newValue;
        result = result.replace(fullMatch, newValue);
    }

    return result;
}

export function isJson(item: any) {
    let str = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(str);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}


/** Returns an array of regex matches for all placeholders.
 *
 * The 0-th group will be the full match ex: "{variable}"
 * 
 * The 1-th group will be only the text within the brackets ex: "variable"
 */
export function* getPlaceholdersMatches(text: string, bracketType = BracketType.Curly) {
    // Does not match \[ ... ] or /[ ... ]
    const escapePattern = '(?<![\\\/\\\\])';

    let pattern: RegExp;
    // ex: [Cities][0]
    pattern = new RegExp(escapePattern + chooseBracket(bracketType), 'g');

    let nextMatch = pattern.exec(text);

    while (nextMatch != null) {
        yield nextMatch;
        nextMatch = pattern.exec(text);
    }
}

function chooseBracket(bracketType: BracketType): string {
    switch (bracketType) {
        case BracketType.Curly:
            return `{([\\w.:\\s-_]+)}`;
        case BracketType.Square:
            return `\\[([^+[{]+?)\\]`;
        case BracketType.Round:
            return `\\(([\\w.:\\s-_]+)\\)`;
        case BracketType.Angled:
            return `<([\\w.:\\s-_]+)>`;
        default: throw new Error(`Invalid bracket type ${bracketType}`);
    }
}

/** Braces a word inside brackets of a given type. ex: word -> {word} */
export function brace(word: string, bracketType: BracketType) {
    switch (bracketType) {
        case BracketType.Angled: return `<${word}>`;
        case BracketType.Curly: return `{${word}}`;
        case BracketType.Round: return `(${word})`;
        case BracketType.Square: return `[${word}]`;
        default: throw new Error('Invald bracket type');
    }
}

/** Gets all the names of all bracketed placeholders inside a text */
export function* getPlaceholderNames(text: string, bracketType = BracketType.Curly) {
    const matches = getPlaceholdersMatches(text, bracketType);
    for (const match of matches) yield match[1];
}

/** Change all placeholders braced with one type of braces to another, ex: [word] -> {word} */
export function changePlaceholderBraces(text: string, oldBracketType: BracketType, newBracketType: BracketType) {
    const matches = getPlaceholdersMatches(text, oldBracketType);
    let newText = text;

    for (const match of matches) {
        const oldPlaceholder = match[0];
        const newPlaceholder = brace(match[1], newBracketType);

        newText = newText.replace(oldPlaceholder, newPlaceholder);
    }

    return newText;
}

/** Replaces placeholders by given name-value object.
 *  Ex: replacePlaceholdersWithValues("hello {name}", { name: 'john'}) => "hello john" */
export function replacePlaceholdersWithValues(text: string, values: { [name: string]: any }, bracketType = BracketType.Curly, isNumbers = false) {
    const matches = getPlaceholdersMatches(text, bracketType);
    let newText = text;

    for (const match of matches) {
        const [placeholder, placeholderName] = match;
        let placeholderValue = values[placeholderName];

        if (isNumbers && !placeholderValue) { placeholderValue = 0 }
        if (placeholderValue)
            newText = newText.replace(placeholder, placeholderValue);
    }

    return newText;
}

