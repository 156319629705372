<template>
  <div class="container">
    <div class="container--content">
      <div class="contents content-align">
        <div class="row">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ConfigurationContainer extends Vue {

}
</script>
<style lang="scss">
@import "../../assets/scss/components/_roles.scss";
@import "../admin/configuration/configuration-page-style.scss";
</style>
