<template>
  <form class="form-group outputs" @submit.prevent="submitEdit">
    <label for="outputs">
      Output
      <Tooltip
        title="This dropdown determines in which variable the result will be stored."
        placement="bottom"
      ></Tooltip>
    </label>
    <button
      :class="'edit-variable-name-icon btn btn-xs ' + (editMode ? 'btn-success' : 'btn-primary') + (variableEditingAvailable() ? '' : ' disabled')"
      :disabled="disabled"
    >
      <img
        v-tippy="{
          placement: 'bottom',
          arrow: true,
          theme: 'light',
          maxWidth: '260px'
        }"
        :src="editMode ? '/assets/icons/icon-save-purple.svg' : '/assets/icons/icon-pen-grey.svg'"
        :title="variableEditingAvailable() ? 'Edit variable name.' : 'You can only edit the name of the original variable'"
        style="width: 10px; height: 10px"
      />
    </button>
    <div
      class="custom-output"
      v-for="output in outputs"
      :key="output.variableName"
    >{{output.variableName}}, ({{output.propertyPath}})</div>

    <div
      :key="index"
      v-for="(_, index) in currentBlock.customData['fc-output-default']"
      class="varlist"
    >
      <input
        v-if="editMode"
        type="text"
        class="form-control"
        placeholder="Variable name"
        v-model="newVariableNames[index]"
        :disabled="disabled"
      />
      <select
        v-else
        class="form-control output-variables-style"
        v-model="currentBlock.customData['fc-output'][index]"
        :disabled="disabled"
      >
        <option
          v-for="defaultOutput in newOutputs"
          :key="defaultOutput"
          :value="defaultOutput"
          :selected="defaultOutput == currentBlock.customData['fc-output'][index]"
          :disabled="disabled"
        >{{ defaultOutput }} (New)</option>
        <option
          v-for="entity in existingOutputs"
          :key="entity.name"
          :value="entity.name"
          :selected="entity.name == currentBlock.customData['fc-output'][index]"
          :disabled="disabled"
        >{{ entity.name || entity }}</option>
      </select>
      <i class="dropdown-toggle white-offset" v-if="!editMode"></i>
      <div
        class="form-control output-variables-style error"
        v-if="nameTakenIndex == index"
      >This name already taken or invalid!</div>
    </div>
  </form>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import FlowComponent from '../../../modules/FlowComponent'
import { BpmnManagementService } from '../../../modules/bpmn-management.service'
import { IBlock } from '../../../../../../../../common/blocks/block.interface'
import Tooltip from '../../../../../helpers/Tooltip.vue'

@Component({ components: { Tooltip } })
export default class Outputs extends Vue {
  private editMode = false;
  private nameTakenIndex = -1;
  private newVariableNames = [];

  private newOutputs: string[] = [];

  @Prop({ default: () => { return {} } }) readonly currentBlock!: FlowComponent<IBlock>;
  @Prop({ default: () => [] }) readonly variables!: any[];
  @Prop({ default: () => { return {} } }) readonly bpmnManagement!: BpmnManagementService;
  @Prop({ default: () => [] }) readonly outputs: any[];
  @Prop({ default: () => { return {} } }) readonly currentFunction: any;
  @Prop({ default: () => false }) readonly disabled: boolean;

  private getAvailableOutputName (variable: string) {
    let name, exists
    do {
      if(variable=="gpt3")
        variable="gpt"
      name = variable + ' - ' + FlowComponent.getElementNumber(this.currentBlock, variable)
      exists = this.bpmnManagement.blockStorage.allComponents()
        .some(c =>
          (c.customData['fc-output'] || []).some(o => o == name) ||
          (c.customData['fc-output-default'] || []).some(o => o == name))
    } while (exists)
    return name
  }

  @Watch('currentFunction')
  onCurrentFunctionChange () {
    const { customData } = this.currentBlock

    if (!customData['fc-output'] || customData['fc-output'].length === 0) {
      customData['fc-output'] = this.currentFunction.variables
        .map(v => this.getAvailableOutputName(v))
    }

    customData['fc-output-default'] = [...customData['fc-output']]
    this.setNewOutputs()
  }

  beforeMount () {
    this.onCurrentFunctionChange()
  }

  submitEdit () {
    if (this.editMode) {
      this.nameTakenIndex = this.validateNewVariableNames(this.newVariableNames)
      if (this.nameTakenIndex != -1) { return }

      this.newVariableNames.forEach((n, i) => this.changeVariableName(n, i))
    } else {
      this.newVariableNames = this.currentBlock.customData['fc-output-default'].map(v => v)
    }
    this.editMode = !this.editMode
  }

  get existingOutputs () {
    return this.variables
  }

  setNewOutputs () {
    this.newOutputs = this.currentBlock.customData['fc-output-default'].filter(
      o => !this.variables.some(v => v.name === o)
    )
  }

  variableEditingAvailable () {
    return this.newOutputs.some(
      v => v === this.currentBlock.customData['fc-output'][0]
    )
  }

  validateNewVariableNames (names: string[]): number {
    for (let index = 0; index < names.length; index++) {
      const name = names[index]
      if (name.trim() == '' || names.some((n, i) => n == name && index != i) || this.variables.some(e => e.name === name)) { return index }
    }
    return -1
  }

  changeVariableName (name: string, index: number) {
    const oldName = this.currentBlock.customData['fc-output'][index]
    if (name == oldName) { return }

    this.currentBlock.customData['fc-output'][index] = name
    this.$set(this.currentBlock.customData['fc-output-default'], index, name)

    this.bpmnManagement.variableService.renameVariable(
      oldName,
      name,
      this.currentBlock.id
    )

    this.bpmnManagement.updateOutputBoxes()
    this.setNewOutputs()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../assets/scss/variables";

.btn-xs {
  padding: 0px 6px;
  margin-left: 5px;
}

.edit-variable-name-icon {
  float: right;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: none;
}

.edit-variable-name-icon:focus {
  box-shadow: none;
}

.custom-output {
  background-color: $default-dark;
  color: $white;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  height: 43px;
  line-height: 13px;
}

.varlist {
  margin-bottom: 0;
  padding: 0;
  position: relative;

  i {
    color: white;
  }
}

/*custom output */
.output-variables-style {
  background-color: $default-dark !important;
  color: white !important;
  border: 1px solid $default-dark !important;
  margin-bottom: 10px;
  font-weight: 400 !important;

}
.variables-panel .form-group select + i {
  color: $white !important;
}

.output-variables-style.error {
  margin-top: 5px;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
  color: #721c24 !important;
}
</style>
