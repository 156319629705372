import popupService from "@/services/popup.service";
import tenantService from "@/services/tenant.service";
import usersService from "@/services/users.service";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IStripeCustomerDto } from "../../../../common/interfaces/stripe-customer-dto.interface";

@Component
export default class OnLoggedInMixin extends Vue {
  stripeCustomer: IStripeCustomerDto;

  async onLoggedIn() {
    localStorage.setItem(
      "stripe.customer.id",
      (await tenantService.stripeCheck()).stripeCustomerId
    );

    const { isTenantAdmin } = await usersService.getUserInfo();

    if (isTenantAdmin) {
      const [plan, stripeCustomer] = await Promise.all([
        tenantService.getPlan(),
        usersService.loadStripeCustomerData()
      ]);

      this.stripeCustomer = stripeCustomer;

      if (!stripeCustomer.hasPaymentMethod && !plan.isAppsumoCustomer) {
        popupService
          .showDialog(
            "Payment method required",
            "If you have already added the payment method and still see this message, try refreshing the page.",
            "Add method",
            "",
            false,
            true
          )
          .then(answer =>
            answer ? usersService.openStripeCustomerPortal() : undefined
          );
      }
    }
  }
}
