<template>
  <div class="main-header__desc-name" style="margin: 0">
    <p>
      <strong>Description:</strong>
    </p>
    <form class="description-form" @submit.prevent="updateDesc">
      <div class="form-group" v-show="editMode">
        <input
          :value="value"
          type="text"
          class="form-control description-edit"
          name="description"
          ref="input"
          placeholder="please type your description"
          @blur="blur"
          @keyup.enter="submit"
        >
      </div>
      <p class="desc-info" v-if="!editMode">{{ value || `This ${type} has no description` }}</p>
      <div class="prl-button" v-if="isEditable && !editMode" @click="toggle">
        <i class="icon"></i>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Description extends Vue {
  $refs: {
    input: HTMLFormElement;
  }

  @Prop() value: string;
  @Prop() type: string;
  @Prop({ type: Boolean, default: true }) isEditable: boolean;

  private editMode = false;

  updateDesc (event) {
    this.$emit('input', event.target.elements.description.value)
    this.editMode = false
  }

  toggle () {
    this.editMode = true
    this.$nextTick(() => this.$refs.input.focus())
  }

  blur (event) {
    if (!event.relatedTarget || event.relatedTarget.type !== 'submit') { this.editMode = false }
  }
}
</script>
