// Allowed extensions for Send attachment block
export const ALLOWED_EXTENSIONS = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'txt', 'pdf', 'png', 'jpeg', 'gif', 'jpg'];

// Allowed extensions for receive user attachment block
const _ALLOWED_EXTENSIONS_UPLOAD = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'png', 'jpeg', 'gif', 'jpg'] as const;

export const ALLOWED_EXTENSIONS_UPLOAD = _ALLOWED_EXTENSIONS_UPLOAD as unknown as Array<string>;
export type AllowedExtensionUpload = typeof _ALLOWED_EXTENSIONS_UPLOAD[number];
export const ALLOWED_SIZE = 30 * 1024 * 1024; // 30MB in Bytes (5 MB before august 2024)
export const ALLOWED_AVATAR_EXTENSIONS = ['png', 'svg'];
export const BCR_ALLOWED_AVATAR_EXTENSIONS = ['png'];
export const BCR_MAX_AVATAR_SIZE = 30 * 1024;
