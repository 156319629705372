/** @description creates an element entry */
export function createEntry (group, className, blockCategory, onClick, onDrag) {
  return {
    group,
    className,
    title: blockCategory,
    action: {
      click: onClick,
      dragstart: onDrag
    }
  }
}

/** @description creates a shape with the given type and title */
function createListener (elementFactory, create, event, type, blockCategory, element) {
  const shape = elementFactory.createShape({ type })
  shape.businessObject.name = shape.businessObject.category = blockCategory
  create.start(event, shape, element)
}

/** @description creates element entry with default behaviour (create new element when entry is clicked/dragged) */
export function createDefaultEntry (type, group, className, blockCategory, element) {
  const { _elementFactory, _create } = this
  const listener = event => createListener(_elementFactory, _create, event, type, blockCategory, element)
  return createEntry(group, className, blockCategory, listener, listener)
}
