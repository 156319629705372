import {IBotSettings} from "../interfaces/bot-settings.interface";
import {ExternalProvider} from "../enums/external-provider.enum";
import {MicrosoftSTTLanguage} from "../enums/microsoft/microsoft-stt.language.enum";

export const defaultBotSettings: Partial<IBotSettings> = {
    idleText: '',
    idleTimeout: 1,
    intentRecognizerProvider: ExternalProvider.Microsoft,
    language: "en",
    languageDetectionProvider: ExternalProvider.Microsoft,
    languageTranslationProvider: ExternalProvider.Microsoft,
    sentimentAnalysisProvider: ExternalProvider.Microsoft,
    speechToTextLanguageCode: MicrosoftSTTLanguage['English (United States)'],
    speechToTextProvider: ExternalProvider.Microsoft,
    textToSpeechLanguageCode: MicrosoftSTTLanguage['English (United States)'],
    textToSpeechPreferredVoiceType: "f",
    textToSpeechProvider: ExternalProvider.Microsoft,

    // No default for:
    // textToSpeechSpeed
    // textToSpeechResponseFormat
    // entityExtractorProvider
}