import axios from 'axios'
import { FlowServiceBase } from './flow.service.base'
import popupService from '../popup.service'

export class BotIntentsService extends FlowServiceBase {
  async uploadFile (containerName: string, formData: FormData): Promise<{ fileId: string; fileExtension: string; contentType: string }> {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/${containerName}/block-upload-file`, formData)).data
  }

  async downloadFile (botName: string, fileId: string, fileName: string): Promise<Blob> {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/test-download-file/${botName}/${fileId}/${fileName}`, { responseType: 'blob' })).data
  }

  async saveFlow (botName: string, intentName: string, xml: string, status = '') {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/${botName}/intents/${intentName}`, { xml: encodeURI(xml), status, _date: new Date() })).data
  }

  async saveAutoFlow (botName: string, intentName: string, xml: string) {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/${botName}/intents/${intentName}/auto-save`, { xml: encodeURI(xml) })).data
  }

  async savePublishedVersion (botName: string, intentName: string, xml: string, versionName: string, ignoreWarnings?: boolean) {
    return (await axios.put(`${process.env.VUE_APP_BACKEND_URL}/${botName}/intents/${intentName}/published-version`, { xml: encodeURI(xml), ignoreWarnings, versionName: 'v' + versionName })).data
  }

  async saveTestVersion (botName: string, intentName: string, xml: string, versionName: string, ignoreWarnings?: boolean) {
    return (await axios.put(`${process.env.VUE_APP_BACKEND_URL}/${botName}/intents/${intentName}/test-version`, { xml: encodeURI(xml), ignoreWarnings, versionName: 'v' + versionName })).data
  }

  async validateFlow (botName: string, intentName: string, xml: string, ignoreWarnings?: boolean) {
    return (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/${botName}/intents/${intentName}/validate-flow`, { xml: encodeURI(xml), ignoreWarnings })).data
  }

  async _setContent (botName: string, intent: any) {
    try {
      intent.content = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/${botName}/intents/${encodeURIComponent(intent.name)}/content`).then(res => res.data)
    } catch (error) {
      if (error.code === 'permission_error') {
        throw error
      }
      popupService.showError(`Could not load the flow for intent ${intent.name} of bot ${botName}.
              ${error.response.status === 403 ? 'Insufficient permissions' : ''} `)
    }
  }
}

export default new BotIntentsService()
