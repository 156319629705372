<template>
  <Modal title="Edit bot permissions" :show="true" @close="$emit('close')">
      <div class="form--wrapper">
       <div class="permission-text">
        All users that have <i>'No role assigned'</i> below don't have permissions to <b>{{botDisplayName}}</b>. Add a role to this user to give them rights over the chosen bot.
      </div>

      <table>
        <thead>
          <tr>
            <td><h4>User email</h4></td>
            <td><h4>Role</h4></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in tenantUsers" :key="index">
            <td class="username">{{user.id}}</td>

            <td>
              <div class="dropdown-toggle form-group form-group--inside select" v-if="!isTenantAdmin(user)">
                <select
                  class="select form-control white-select select-margin"
                  @change="changeUserRole(user.id, $event.target.value)"
                  :disabled="!hasSufficcientPermission"
                >
                  <option
                    v-for="(el, index) in tenantRoles"
                    :key="index"
                    :selected="checkIfBotRolesContains(user.botRoles, el.roleName) ? 'selected' : ''"
                    :value="el.roleId"
                  >{{ el.roleName }}</option>
                </select>
              </div>

              <div v-else class="form-group--inside"><b class="">Account Administrator</b></div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <br />

      <div class="fluid-container" style="display: flex; float: right;">
        <button
          class="btn btn--purple gradient"
          type="button"
          @click="saveChanges()"
          v-if="hasSufficcientPermission"
        >
         <i class="icon icon-save"></i>Save changes
        </button>
      </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Dropdown from '../../../helpers/Dropdown.vue'
import Modal from '../../../helpers/Modal.vue'
import { UpdateUserViewModel } from '../models/update-user.view.model'
import { RoleViewModel } from '../models/role.view.model'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../services/tenants/permissions.service'
import { IUser } from '../../../../../../common/interfaces/tenant/user.interface'
import { IUserBotRole } from '../../../../../../common/interfaces/tenant/user-bot-role'
import { isTenantAdmin } from '../../../../../../common/helpers/tenant-role.helper'

@Component({
  name: 'EditBot',
  components: { Dropdown, Modal }
})
export default class EditBot extends Vue {
  @Prop({ default: '' }) botDisplayName: string;
  @Prop({}) botName: string;
  @Prop({ default: [] }) readonly tenantRoles: RoleViewModel[];
  @Prop({ default: [] }) readonly tenantUsers: IUser[];
  @Prop({ default: '' }) readonly botId: string;

  private usersToUpdate: UpdateUserViewModel[] = [];
  private hasSufficcientPermission = false;

  async mounted () {
    this.hasSufficcientPermission = await permissionsService.hasPermission(
      this.botName,
      this.permissions.ManageBotPermissions
    )
  }

  isTenantAdmin (user: IUser) {
    return isTenantAdmin(user, permissionsService.tenant, permissionsService.masterTenant)
  }

  created () {
    this.usersToUpdate = []
    !this.tenantRoles.some(tr => tr.roleName == 'No role selected')
      ? this.tenantRoles.unshift({ roleName: 'No role selected' })
      : null

    !this.tenantRoles.some(tr => tr.roleName == 'Remove role for this user')
      ? this.tenantRoles.unshift({
        roleName: 'Remove role for this user',
        roleId: -1
      })
      : null
  }

  get permissions () {
    return Permissions
  }

  private checkIfBotRolesContains (botRoles: { [botId: string]: IUserBotRole }, role: string) {
    return (
      Object.values(botRoles).some(
        br => String(br.role) == role && this.botId == br.botId
      ) || role == 'No role selected'
    )
  }

  private changeUserRole (email: string, roleId: string) {
    let roleName = ''
    this.usersToUpdate = this.usersToUpdate.filter(u => u.email != email)

    if (roleId && Number(roleId) != -1 && String(roleId).trim() != '') {
      roleName = this.tenantRoles.find(tr => String(tr.roleId) == roleId)
        .roleName

      this.usersToUpdate.push({
        email: email,
        roleId: roleId,
        botName: this.botName,
        role: roleName
      })
    } else {
      if (roleId == '-1') {
        this.usersToUpdate.push({
          email: email,
          roleId: '-1',
          botName: this.botName,
          role: 'Remove role for this user'
        })
      }
    }
  }

  private saveChanges () {
    this.$emit('saveEditBotChanges', this.usersToUpdate)
    this.$emit('close')
  }
}
</script>

<style lang="scss" scoped>
@import "../configuration-page-style.scss";

.select-margin {
  padding-right:12px
}

.permission-text {
  font-size: 16px;
  margin-left: 0px;
  margin-bottom: 40px;
}

.modal {
  min-height: 50vh;
  height: unset;
  overflow: hidden;

  .form--wrapper {
    padding-left: 20px;
  }

  .content {
    overflow: hidden;
  }
}

.username {
  font-weight: bold;
  font-size: 15px;
}

h4 {
  font-size: 18px;
  margin-bottom: 0px;
}

</style>
