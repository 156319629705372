import { BotConnectionComplete } from './bot-connection-complete.model';
export class BotChannelRules extends BotConnectionComplete {
    errors: Array<string>;
    warnings: Array<string>;
    isOpen: boolean;

    constructor(data: Partial<BotChannelRules>) {
        super(data);

        this.errors = data.errors || [];
        this.warnings = data.warnings || [];
        this.isOpen = data.isOpen || false;
    }
}