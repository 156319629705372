<template>
  <div :class="isInner ? undefined : 'variables-panel variables-panel-1'">
    <div class="form-group" v-if="hasCondition">
      <label for="condition">
        {{ conditionLabel }}
        <Tooltip
          :title="conditionTooltipText"
          placement="bottom"
        ></Tooltip>
      </label>

      <div class="form-group--inside">
        <Select
          id="condition"
          :elements="conditionElements"
          :key="currentBlock.customData.condition"
          v-model="currentBlock.customData.condition"
          :disabled="!hasSufficientPermission"
          class="white-select"
          @input="objectConditionKey++"
        ></Select>
      </div>
    </div>

    <div class="form-group" v-if="hasCondition && conditionIsObject">
      <label for="condition">
        Property
        <Tooltip
          title="This field determines the property of the object to be checked."
          placement="bottom"
        ></Tooltip>
      </label>
      <div class="form-group--inside">
        <Select
          id="conditionEntitySelector"
          :elements="bpmnManagement.getObjectKeys(currentBlock.customData.condition)"
          :key="currentBlock.customData.property"
          v-model="currentBlock.customData.property"
          :disabled="!hasSufficientPermission"
        ></Select>
      </div>
    </div>

    <div class="form-group" v-if="currentBlock.customData.operation == 7">
      <label for="condition">
        Example/Canonical
        <Tooltip
          title="This field determines the Example/Canonical of all entities to be checked."
          placement="bottom"
        ></Tooltip>
      </label>
      <div class="form-group--inside">
        <Select
          id="conditionEntitySelector"
          :elements="variables"
          keyAttr="name"
          selectedAttr="name"
          :key="currentBlock.customData.exampleOrCanonical"
          v-model="currentBlock.customData.exampleOrCanonical"
          :disabled="!hasSufficientPermission"
        ></Select>
      </div>
    </div>

    <div class="form-group" v-if="currentBlock.customData.operation == 7">
      <label for="condition">
        Utterance
        <Tooltip
          title="This field determines the utterances of the all closed list entities to be checked."
          placement="bottom"
        ></Tooltip>
      </label>
      <div class="form-group--inside">
        <Select
          id="conditionEntitySelector"
          :elements="variables"
          keyAttr="name"
          selectedAttr="name"
          :key="currentBlock.customData.canonicalUtt"
          v-model="currentBlock.customData.canonicalUtt"
          :disabled="!hasSufficientPermission"
        ></Select>
      </div>
    </div>

    <div class="form-group">
      <label for="operation">
        Operation
        <Tooltip
          title="This dropdown defines what kind of condition is applied."
          placement="bottom"
        ></Tooltip>
      </label>
      <div class="form-group--inside">
      <div class="square-button-container" :key="opearionSelectorKey">
        <div v-for="(type, index) in availableOperations" :key="index">
          <div class="button-box">
            <button class="select-button"
              :disabled="!hasSufficientPermission"
              v-on:click="changeOperation(type.id)"
              :class="{'is-active': currentBlock.customData.operation == type.id}"
            >
              <div v-if="type.name === 'Equals'" class="image icon-equals"></div>
              <div v-if="type.name === 'Exists (Not Empty)'" class="image icon-exists"></div>
              <div v-if="type.name === 'Math Comparator'" class="image icon-calc"></div>
              <div v-if="type.name === 'In Set'" class="image icon-set"></div>
              <div v-if="type.name === 'Contains'" class="image icon-contains"></div>
              <div v-if="type.name === 'Is of type'" class="image icon-type"></div>
              <div v-if="type.name === 'Is an entity'" class="image icon-entity2"></div>
              <div v-if="type.name === 'Is similar'" class="image icon-similar"></div>
              <div v-if="type.name === 'Which channel'" class="image icon-channel"></div>
              <div v-if="type.name === 'Check user'" class="image icon-user"></div>
              <div v-if="type.name === 'RegEx'" class="image icon-regex"></div>
              <div class="text">{{type.name}}</div>
            </button>
          </div>
        </div>
      </div>

      </div>
    </div>

    <div v-if="hasCaseSensitivity" class="form-group form-group--options">
      <input
        type="checkbox"
        class="form-check-input"
        id="exampleCheck1"
        v-model="currentBlock.customData.caseSensitivity"
        :disabled="!hasSufficientPermission"
      />
      <label class="form-check-label" for="exampleCheck1">Case-sensitive</label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { availableOperations } from '../../modules/operations.config'
import Select from '../../../../helpers/Select.vue'
import Tooltip from '../../../../helpers/Tooltip.vue'
import labelService from '../../modules/label.service'
import { BpmnManagementService } from '../../modules/bpmn-management.service'
import { IConditionalBlock } from '../../../../../../../common/blocks/conditional-block.interface'
import { IBlockConnector } from '../../../../../../../common/blocks/block-connector.interface'
import ConditionalComponent from '../../modules/blocks/ConditionalComponent'
import permissionsService from '../../../../../services/tenants/permissions.service'
import { Permissions } from '../../../../../../../common/enums/tenant/user-permissions.enum'

@Component({
  components: {
    Select,
    Tooltip
  }
})
export default class ConditionalBlock extends Vue {
  @Prop({ default: false }) readonly isInner: boolean;
  @Prop({ default: () => {} }) readonly currentBlock!: ConditionalComponent<IConditionalBlock>;
  @Prop({ default: () => {} }) readonly bpmnManagement!: BpmnManagementService;
  @Prop({ default: () => [] }) readonly variables!: any[];
  @Prop({ default: () => [] }) readonly variablesAndEntities!: any[];
  @Prop({ default: () => [] }) readonly variablesAndEntitiesRoles!: any[];
  @Prop({ default: () => [] }) readonly botName!: string;

  private hasSufficientPermission = false;
  private availableOperations = availableOperations;
  private isClosedList = false;
  opearionSelectorKey = 0;

  get conditionLabel () {
    return this.currentBlock.customData.operation == 7 ? 'Entity name' : 'Condition'
  }

  get conditionTooltipText () {
    return this.currentBlock.customData.operation == 7 ? 'This field determines the entity name on which the decision will be taken' : 'This field determines the variable on which the decision will be taken'
  }

  get conditionElements () {
    return this.currentBlock.customData.operation == 7
      ? this.variablesAndEntities
      : this.variablesAndEntitiesRoles
  }

  get hasCondition () {
    const operation = this.availableOperations.find(o => o.id === this.currentBlock.customData.operation) || {} as any
    return operation.hasCondition === undefined ? true : operation.hasCondition
  }

  get hasCaseSensitivity () {
    const operation = this.availableOperations.find(o => o.id === this.currentBlock.customData.operation) || {} as any
    return operation.hasCaseSensitivity === undefined ? true : operation.hasCaseSensitivity
  }

  objectConditionKey = 0;
  get conditionIsObject () {
    this.objectConditionKey
    return this.bpmnManagement.isObject(this.currentBlock.customData.condition)
  }

  async mounted () {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageFlowsInDialogManager
    )
    this.currentBlock.customData.condition = this.conditionElements.some(
      e => e === this.currentBlock.customData.condition
    )
      ? this.currentBlock.customData.condition
      : ''
    this.updateArrowLabels()
  }

  changeOperation (operation: number) {
    this.updateLabel(this.currentBlock.id, this.currentBlock.customData.operation, operation)
    this.currentBlock.changeOperation(operation)
    this.opearionSelectorKey++
    this.updateArrowLabels()
    this.$emit('update-operation')
  }

  updateArrowLabels () {
    if (this.isInner) return
    if (!this.currentBlock.element.businessObject.outgoing) return
    for (const arrowElement of this.currentBlock.element.businessObject.outgoing) {
      const block = this.bpmnManagement!.blockStorage.findById(arrowElement.id)

      const text = labelService.generateLabelText(
        this.currentBlock.customData.operation,
        block as ConditionalComponent<IBlockConnector>
      )

      labelService.updateArrowLabel(this.bpmnManagement.bpmn, block, text)
    }
  }

  @Emit()
  updateLabel (id: string, oldOperation: number, newOperation: number) {
    if (this.isInner) return
    const elementTextContent = this.currentBlock.gfx.firstChild.textContent

    const text = oldOperation === newOperation
      ? elementTextContent
      : this.availableOperations.find(o => o.id === newOperation).name

    return { id, text }
  }
}
</script>
