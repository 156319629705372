import {NluMap} from "../types/nlu-map.type";
import {ExternalProvider} from "../enums/external-provider.enum";
import {LuisAppLanguage} from '../enums/microsoft/luis-app-language.enum';
import {WatsonWorkspaceLanguage} from '../enums/ibm/watson-workspace-language.enum';
import {DialogflowAgentLanguage} from '../enums/google/dialogflow-agent-language.enum';
import {RasaLanguage} from '../enums/rasa/rasa-language.enum';
import {AmazonLexLanguage} from '../enums/amazon/amazon-lex-language.enum';
import {WitAppLanguage} from '../enums/wit/wit-app-language.enum';
import {NuanceLanguage} from "../enums/nuance/nuance-language";
import {NeuralspaceLanguage} from "../enums/neuralspace/neuralspace.language";
import {CluAppLanguage} from "../enums/microsoft/clu-app.language.enum";

export const nluLanguages: NluMap<any> = {
    [ExternalProvider.Microsoft]: LuisAppLanguage,
    [ExternalProvider.IBM]: WatsonWorkspaceLanguage,
    [ExternalProvider.Google]: DialogflowAgentLanguage,
    [ExternalProvider.Rasa]: RasaLanguage,
    [ExternalProvider.Amazon]: AmazonLexLanguage,
    [ExternalProvider.Wit]: WitAppLanguage,
    [ExternalProvider.Nuance]: NuanceLanguage,
    [ExternalProvider.NeuralSpace]: NeuralspaceLanguage,
    [ExternalProvider.MicrosoftCLU]: CluAppLanguage,
}
