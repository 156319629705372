<template>
  <div class="variables-panel variables-panel-3" :key="hasUpdated">
    <div class="form-group form-group--options" v-if="mode=='intents'">
      <input
        id="redirectToOtherItem"
        :checked="currentBlock.customData.redirectToOtherIntent"
        type="radio"
        name="end-flow-outcome"
        @change="changeEndFlowDirection('redirectToIntent')"
        :disabled="!hasSufficientPermission"
      />
      <label for="redirectToOtherItem">Redirect to other intent</label>
    </div>

    <div v-if="currentBlock.customData.redirectToOtherIntent && mode=='intents'" class="form-group">
      <label for="toItem">
        To intent
        <Tooltip
          title="This dropdown determines to which intent the user will be redirected once the block is reached."
          placement="bottom"
        ></Tooltip>
      </label>

      <div class="form-group--inside">
        <select
          id="toItem"
          class="form-control"
          v-model="currentBlock.customData.intentTo"
          :disabled="!hasSufficientPermission"
        >
          <option
            v-for="variable in redirectVariables"
            :key="variable.key"
            :value="variable.key"
            :selected="variable.key == currentBlock.customData.intentTo"
          >{{ variable.value }}</option>
          <option disabled v-show="redirectVariables.length > 0 && intents.length > 0"></option>
          <option
            v-for="item in intents"
            :key="item.name"
            :value="item.name"
            :selected="item.name == currentBlock.customData.intentTo"
          >{{ item.name }}</option>
        </select>
        <i class="dropdown-toggle"></i>
      </div>
    </div>

    <div class="form-group form-group--options" v-if="mode=='intents'">
      <input
        id="restartItem"
        type="radio"
        name="end-flow-outcome"
        @change="changeEndFlowDirection('restartFlow')"
        :checked="currentBlock.customData.restartIntent"
        :disabled="!hasSufficientPermission"
      />
      <label for="restartItem">Restart flow</label>
    </div>

    <div class="form-group form-group--options">
      <input
        id="endFlow"
        type="radio"
        name="end-flow-outcome"
        @change="changeEndFlowDirection('endFlow')"
        :checked="currentBlock.customData.endFlow"
        :disabled="!hasSufficientPermission"
      />
      <label for="endFlow">End Flow</label>
    </div>

    <div class="form-group form-group--options" v-if="mode=='intents'">
      <input
        id="redirect-to-other-bot"
        type="radio"
        name="end-flow-outcome"
        @change="changeEndFlowDirection('redirectToBot')"
        :checked="currentBlock.customData.redirectToBot"
        :disabled="!hasSufficientPermission"
      />
      <label for="redirect-to-other-bot">Redirect to other bot</label>
    </div>

    <div v-if="currentBlock.customData.redirectToBot" class="form-group">
      <label for="toItem">
        To bot
        <Tooltip
          title="This dropdown determines to which bot the user will be redirected once the block is reached."
          placement="bottom"
        ></Tooltip>
      </label>

      <div class="form-group--inside">
        <select
          id="toItem"
          class="form-control"
          v-model="currentBlock.customData.redirectBotId"
          :disabled="!hasSufficientPermission"
        >
          <option
            v-for="(bot, index) in bots"
            :key="index"
            :value="bot.id"
            :selected="bot.id == currentBlock.customData.redirectBotId"
          >{{ bot.displayName }}</option>
        </select>
        <i class="dropdown-toggle"></i>
      </div>
    </div>
    <div v-if="mode=='intents'">
      <hr />
      <div class="form-group form-group--options">
        <input
          id="endflow-clear-entities"
          type="checkbox"
          v-model="currentBlock.customData.clearEntities"
          :disabled="!hasSufficientPermission"
        />
        <label for="endflow-clear-entities">Clear entity values</label>
      </div>

      <div class="form-group form-group--options">
        <input
          id="endflow-count-touchless"
          type="checkbox"
          v-model="currentBlock.customData.countTouchless"
          :disabled="!hasSufficientPermission"
        />
        <label for="endflow-count-touchless">Count conversation as touchless</label>
      </div>

      <hr />
      <div class="form-group form-group--options">
        <input
          id="endflow-end-call"
          type="checkbox"
          v-model="currentBlock.customData.endCall"
          :disabled="!hasSufficientPermission"
        />
        <label for="endflow-end-call">End call</label>
      </div>
    </div>
    <div class="form-group" v-if="mode == 'functions' && outputs.length > 0">
      <label for="toItem">
        Outputs
        <Tooltip
          title="These outputs will be the output of this sub."
          placement="bottom"
        ></Tooltip>
      </label>
      <div
        class="form-group--inside"
        :key="index"
        v-for="(output, index) in outputs"
      >
        <Autocomplete
          v-model="currentBlock.customData.outputs[index]"
          :placeholder="output.variableName"
          :items="variables.map(v => v.name)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Tooltip from '../../../../helpers/Tooltip.vue'
import EndEventBlockComponent from '../../modules/blocks/EndEventBlockComponent'
import permissionsService from '../../../../../services/tenants/permissions.service'
import { Permissions } from '../../../../../../../common/enums/tenant/user-permissions.enum'
import Autocomplete from '../../../../helpers/Autocomplete.vue'

@Component({
  components: {
    Tooltip,
    Autocomplete
  }
})
export default class EndBlock extends Vue {
  private redirectVariables = [];
  private hasUpdated = false;

  @Prop({ default: () => { return {} } }) readonly currentBlock!: EndEventBlockComponent;
  @Prop({ default: () => [] }) readonly intents!: any[];
  @Prop({ default: () => [] }) readonly variables!: any[];
  @Prop({ default: () => [] }) readonly bots!: any[];
  @Prop({ default: () => [] }) readonly botName!: string;
  @Prop({ default: 'intents' }) readonly mode: 'intents' | 'functions';
  @Prop({ default: () => [] }) readonly outputs: string[];

  private hasSufficientPermission = false;

  async mounted () {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageFlowsInDialogManager
    )
    this.redirectVariables = this.variables.map(v => {
      return { key: `__variable__${v.name}`, value: `[${v.name}]` }
    })
  }

  changeEndFlowDirection (option) {
    this.currentBlock.changeDirection(option, this.intents, this.bots)
    this.hasUpdated = !this.hasUpdated
  }
}
</script>
